import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { _verifyCertificateName } from "../../../../config/services/api/profile";
import { Icon } from "../../image";

export default function ModalCheckIdentity({ setModal, isRating }) {
  const { push } = useHistory();
  const { courseId } = useParams();
  const { userData, token } = useSelector((state) => state.authReducer);
  const [form, setForm] = useState({ name: userData.name });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verify = async () => {
    setLoading(true);
    try {
      const res = await _verifyCertificateName(
        token,
        courseId,
        form.name.toUpperCase()
      );
      if (!res.status) return setErrorMessage(res.message);

      if (isRating) {
        push("/student/certificate");
      } else {
        setModal("rating");
      }
    } catch (error) {
      console.log("error post verify certifcate name", error);
    } finally {
      setLoading(false);
    }
  };

  return errorMessage ? (
    <div className="w-[436px] rounded-lg bg-white p-4 mx-auto">
      <div className="flex justify-end">
        <button type="button" onClick={() => setModal("")}>
          <Icon name="close-netral-10" className="w-6 h-6" />
        </button>
      </div>
      <Icon
        name="Cancel-primary2-500"
        className="w-[130px] h-[130px] mx-auto"
      />
      {/* <h4 className="text-xl font-semibold text-netral-10 mt-6 text-center">
        Nama tidak Sesuai
      </h4> */}
      <p className="text-sm text-secondary3-500 mt-3 text-center">
        {errorMessage}
      </p>
    </div>
  ) : (
    <div className="w-[438px] bg-white p-4 rounded-lg mx-auto">
      <div className="pb-2 border-b-2 border-b-netral-4 space-y-2">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">
            Cek Indentitas Anda
          </h5>
          <button type="button" onClick={() => setModal("")}>
            <Icon name="close-netral-10" className="w-6 h-6" />
          </button>
        </div>
        <p className="text-sm text-netral-9">
          Silakan cek kembali identitas Anda untuk ditampilkan di sertifikat
          Anda
        </p>
      </div>
      <div className="mt-6 space-y-4">
        <div className="space-y-2">
          <label htmlFor="name" className="text-xs font-semibold text-netral-8">
            Nama Akun Skills.id
          </label>

          <input
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            value={form.name}
            type="text"
            className="py-[13px] px-2 border border-neutral-700 rounded text-sm text-netral-10 block w-full"
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button
          disabled={loading}
          onClick={verify}
          type="button"
          className="border border-secondary-button-600 rounded px-3 py-2 font-semibold text-white bg-secondary-button-600"
        >
          Simpan
        </button>
      </div>
    </div>
  );
}

