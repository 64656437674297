import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EditOrRemoveButton from "../../../../../components/dependent/new-course/edit-or-remove-button";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import Question from "../../../../../components/dependent/new-course/question";
import ButtonPlus from "../../../../../components/independent/button/button-plus";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
  SET_WEBINAR_GROUP_OF_QUESTION,
  SET_WEBINAR_FINISHED_NAV,
} from "../../../../../config/redux/types";

const WebinarGroupOfQuestion = () => {
  const [isSaved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const questions = useSelector(
    (state) => state.newWebinarReducer.groupOfQuestion
  );
  const participantType = useSelector(
    (state) => state.newWebinarReducer.participantType
  );

  const setQuestions = (value) => {
    dispatch({ type: SET_WEBINAR_GROUP_OF_QUESTION, payload: value });
  };

  const addQuestion = () => {
    const defaultQuestion = {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    };
    questions.push(defaultQuestion);
    setQuestions([...questions]);
  };

  const setChoiceValue = (questionIndex, targetIndex, value) => {
    questions[questionIndex].multipleChoice[targetIndex] = value;
    setQuestions([...questions]);
  };

  const setInputValue = (questionIndex, key, value) => {
    questions[questionIndex][key] = value;
    setQuestions([...questions]);
  };

  const deleteQuestion = (questionIndex) => {
    questions.splice(questionIndex, 1);
    setQuestions([...questions]);
  };

  return (
    <LayoutNewWebinarStep4
      title="Bank Soal"
      subTitle="Tambahkan soal untuk Post test dan Pretest"
    >
      {!questions.length ? (
        <ButtonPlus type="button" onClick={addQuestion}>
          Tambahkan Soal
        </ButtonPlus>
      ) : !isSaved ? (
        <div className="py-6 flex flex-col items-center bg-netral-2/60">
          <div className="w-[711px] px-6 py-4 bg-white border border-netral-6 rounded space-y-6">
            <h5 className="text-lg text-netral-10 font-semibold">
              Bank Soal{" "}
              {participantType === "WEBINAR PRAKERJA" && (
                <span className="text-sm font-normal text-red-500">
                  Minimal 60 pertanyaan*
                </span>
              )}
            </h5>
            <div className="space-y-3">
              {questions.map((question, questionIndex) => (
                <Question
                  key={questionIndex}
                  canItBeDeleted={true}
                  question={question}
                  questionIndex={questionIndex}
                  setInputValue={(key, value) =>
                    setInputValue(questionIndex, key, value)
                  }
                  setChoiceValue={(targetIndex, value) =>
                    setChoiceValue(questionIndex, targetIndex, value)
                  }
                  deleteQuestion={() => deleteQuestion(questionIndex)}
                />
              ))}
              <div className="space-y-1 text-center w-max ">
                <p className="text-xs text-netral-9">Total Soal</p>
                <p className="font-semibold text-netral-10">
                  {questions.length}
                  {participantType === "WEBINAR PRAKERJA" && (
                    <span className="font-normal text-xs text-netral-8">
                      / 60
                    </span>
                  )}
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={addQuestion}
                  className="font-semibold text-netral-1 px-3 py-2 rounded bg-primary-500 border border-transparent interactive-button-color"
                >
                  Tambah Pertanyaan
                </button>
              </div>
            </div>
          </div>
          <div className="w-[711px] mt-8 flex space-x-6 items-center justify-end">
            {/* <button type="button" className="text-secondary2-500 font-semibold">
              Cancel
            </button> */}
            <button
              // disabled={participantType === "WEBINAR PRAKERJA" && questions.length < 60}
              type="button"
              onClick={() => setSaved(true)}
              className={`${
                // participantType === "WEBINAR PRAKERJA" && questions.length < 60
                //   ? "bg-netral-5 text-netral-6 cursor-not-allowed"
                //   : 
                  "bg-secondary2-500 text-white interactive-button-color"
              } font-semibold rounded py-2 px-3 border border-transparent`}
            >
              Simpan
            </button>
          </div>
        </div>
      ) : (
        <div className="space-y-5">
          <div className="flex justify-end">
            <EditOrRemoveButton
              edit={() => setSaved(false)}
              remove={() => {
                setSaved(false);
                setQuestions([]);
              }}
            />
          </div>
          <table className="w-full">
            <thead>
              <tr className="flex px-2 py-3 bg-netral-2">
                <th>
                  <p className="w-10 text-sm font-semibold text-netral-10 text-left">
                    No
                  </p>
                </th>
                <th className="w-full">
                  <p className="text-sm font-semibold text-netral-10 text-left">
                    Soal
                  </p>
                </th>
                <th className="w-full">
                  <p className="text-sm font-semibold text-netral-10 text-left">
                    Pilihan
                  </p>
                </th>
                <th>
                  <p className="w-[120px] text-sm font-semibold text-netral-10 text-left">
                    Jawaban
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, questionIndex) => (
                <tr key={questionIndex} className="flex px-2 py-3">
                  <td>
                    <p className="w-10 text-sm text-netral-10">
                      {questionIndex + 1}
                    </p>
                  </td>
                  <td className="w-full">
                    <p className="text-sm text-netral-10">{question.name}</p>
                  </td>
                  <td className="w-full">
                    {question.multipleChoice.map((choice, choiceIndex) => (
                      <p
                        key={choiceIndex}
                        className="text-sm text-netral-10"
                      >{`${choiceIndex + 1}. ${choice}`}</p>
                    ))}
                  </td>
                  <td>
                    <p className="w-[120px] text-sm text-netral-10">
                      {question.answer}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ChildComponent isSaved={isSaved} questions={questions} />
    </LayoutNewWebinarStep4>
  );
};

export default WebinarGroupOfQuestion;

const ChildComponent = ({ isSaved, questions }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { push } = useHistory();

  return (
    <NextOrLaterButton
      active={isSaved}
      page={"groupOfQuestion"}
      next={() => {
        dispatch({ type: SET_WEBINAR_GROUP_OF_QUESTION, payload: questions });
        dispatch({
          type: SET_WEBINAR_FINISHED_NAV,
          payload: "groupOfQuestion",
        });
        push(`/new/webinar/${id}/step5/skill-test`);
      }}
    />
  );
};
