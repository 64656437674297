import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

function AuthenticatedRoute({ component: Component, ...restOfProps }) {
  const { pathname, search } = useLocation();

  const { isLogin } = useSelector((state) => state.authReducer);

  let visit = encodeURIComponent(pathname);

  if (search) visit += encodeURIComponent(search);

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (!isLogin) return <Redirect to={`/login?continue=${visit}`} />;

        return <Component {...props} />;
      }}
    />
  );
}

export default AuthenticatedRoute;
