import { APIBaseURL } from "../../environment";

export const _getCourses = () => {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/publish/all`)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _getCourseByCategory = (category) => {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/publish/getByCategory/${category}`)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getPrakerjaCourseByCategory = (category) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(
      `${APIBaseURL}/api/class/publish/prakerja/${category}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getCourse = (id) => {
  if (!id) return;
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/id/${id}`)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _getCoursesInstructor = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/getAll`, {
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _getCourseInstructor = (id, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/byId/${id}`, {
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _createCourse = (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", `application/json`);
  myHeaders.append("Content-Type", `application/json`);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/create`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _uploadFileNewCourse = (file, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const data = new FormData();
  data.append("file", file);

  const requestOptions = {
    body: data,
    method: "POST",
    headers: myHeaders,
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/uploadToLocal`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result.status) result.data.storage = "local";
        resolve(result);
      })
      .catch((e) => reject(e));
  });
};

export const _getStreamLocalFile = (token, filename) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/streamlocal/${filename}`, {
      headers: myHeaders,
    })
      .then((response) => response.blob())
      .then((res) => resolve(window.URL.createObjectURL(res)))
      .catch((e) => reject(e));
  });
};

export const _deleteFileFromCloud = (token, filename) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/deleteFromCloud/${filename}`, requestOptions)
      .then((res) => res.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _updateCourse = (token, id, data) => {
  const raw = JSON.stringify(data);
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/update/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getCoursesStudent = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/myclass`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};
export const _checkCoursesHasVideo = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`https://api-live.skills.id/v2/user-course/check-video`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _getCourseStudent = (token, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/idForStudent/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _createCourseProgress = (token, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/progress/create/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _getCourseByVoucher = (voucher) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/ByVoucherCode/${voucher}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getCourseByVoucherNew = (voucher) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`https://api-live.skills.id/v2/voucher/class/${voucher}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _searchCourse = (query = "") => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/search${query}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getCertificate = (token, courseId) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(
      `${APIBaseURL}/api/user/generateCertificate/${courseId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

// /certificateLink?userId=63c1bf57b163acf73c6e3f37&classId=63cf8f83d7809a03af96c77d

export const _getCertificateForPublic = (courseId, studentId) => {
  var myHeaders = new Headers();
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(
      `${APIBaseURL}/api/user/certificateLink?userId=${studentId}&classId=${courseId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
