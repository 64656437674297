// import { Icon } from "../../image";

export default function Overview({ data }) {

  return (
    <>
      <div className="space-y-4 lg:space-y-6">
        <div className="pb-2 lg:pb-4 border-b lg:border-b-2 border-[#EDEDED]">
          <h5 className="text-sm lg:text-xl text-netral-9 font-semibold">
            Tentang kursus ini
          </h5>
        </div>
        <div className="space-y-2 lg:space-y-4 pb-3 lg:pb-4 border-b lg:border-b-2 border-[#EDEDED]">
          <h5 className="lg:text-xl text-netral-9 font-semibold">Menurut angka</h5>
          <div className="grid grid-flow-col grid-rows-4 gap-2">
            <p className="text-netral-9 text-xs lg:text-base">Tingkat keahlian: {data.level}</p>
            <p className="text-netral-9 text-xs lg:text-base">Peserta: {data.student}</p>
            <p className="text-netral-9 text-xs lg:text-base">Bahasa: {data.language}</p>
            <p className="text-netral-9 text-xs lg:text-base">Caption: {data.caption}</p>
            <p className="text-netral-9 text-xs lg:text-base">Pelajaran: {data.totalChapters}</p>
            {/* <p className="text-netral-9 text-xs lg:text-base">Video: {data.totalDuration}</p> */}
          </div>
        </div>
        <div className="space-y-2 lg:space-y-4 lg:pb-[46px] lg:border-b-2 border-[#EDEDED]">
          <h5 className="lg:text-xl text-netral-9 font-semibold">Deskripsi</h5>
          {/* <p className="text-netral-9 text-xs lg:text-base">{data.description}</p> */}
          <p className="text-netral-9 whitespace-pre-wrap text-xs lg:text-base">
          {data.description}
        </p>
        </div>
        {/* <div className="space-y-4 pb-[46px] border-b-2 border-[#EDEDED]">
            <h5 className="text-xl text-netral-9 font-semibold">Instruktur</h5>
            <div className="flex items-center space-x-3">
              <div className="w-[52px] h-[52px] rounded-full bg-pink-600"></div>
              <div>
                <h6 className="text-lg font-semibold text-netral-10">
                  Ravi Marvunda
                </h6>
                <p className="mt-2 text-netral-8">UI/UX Designer Tokopedia</p>
              </div>
            </div>
          </div> */}
      </div>
      {/* <div className="mt-8 mb-[calc(112px-32px)]">
        <button className="p-1 flex items-center space-x-2">
          <span className="font-semibold text-primary-500">
            Tampilkan lebih sedikit
          </span>
          <Icon
            name="arrow_forward_ios-primary-500"
            className="w-6 h-6 rotate-[270deg]"
          />
        </button>
      </div> */}
    </>
  );
}
