import React from 'react'

export default function LineSeperator({
    headerTagLine,
    required
}) {
  return (
    <div className='mb-[20px]'>
        <hr className="my-[32px] bg-[#E0E0E0]" />
          <h1 className="font-semibold"> {headerTagLine} 
            <span className=" text-[#D1421A]"> {required ? "*" : null} </span> 
          </h1>
    </div>
  )
}
