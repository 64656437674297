import {
  SET_NEW_VIDEO,
  SET_NEW_WEBINAR,
  SET_VIDEO_CURRICULUM,
  SET_VIDEO_GENERAL,
} from "../types";

export const initialNewVideoState = {
  questionBank: [{ label: "", option: [], answer: "" }],
  generalInformation: {
    title: "",
    category: "",
    language: "",
    isPrakerja: "",
    level: "",
    synopsis: "",
    description: "",
    goals: "",
    particularGoals: "",
    tools: [""],
    THUMBNAIL: "",
    INTRO: "",
  },
  gallery: [],
  curriculum: [],
  homepage: {
    title: "",
    description: "",

    language: "",
    level: "",
    category: "",
    mainLesson: "",
    thumbnail: {
      // name: "label prakerja.png",
      // link: "http://122.50.7.190:5000/api/class/stream/label prakerja.png",
      // type: "image/png",
    },
    intro: {
      // duration: 86,
      // name: "videoplayback.mp4",
      // link: "http://122.50.7.190:5000/api/class/stream/videoplayback.mp4",
      // type: "video/mp4",
    },
    instructor: "",
  },
  skillTest: {},

  pricing: { price: "", profit: 0.6 },
  deleteFiles: [],
};

export default function newVideoReducer(state = initialNewVideoState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_NEW_VIDEO:
      return payload;
    case SET_VIDEO_CURRICULUM:
      return { ...state, curriculum: { sessions: payload } };
    case SET_VIDEO_GENERAL:
      return { ...state, detail: payload };
    default:
      return state;
  }
}
