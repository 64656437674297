import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import ButtonPlus from "../../../../../components/independent/button/button-plus";
import { Icon } from "../../../../../components/independent/image";
import InputDropdownLabel2 from "../../../../../components/independent/input/input-dropdown-label2";
import InputFilePreview from "../../../../../components/independent/input/input-file-preview";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import InputTextareaLabel from "../../../../../components/independent/input/input-textarea-label";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import {
  SET_COURSE_CATEGORY,
  SET_COURSE_HOMPAGE,
  SET_COURSE_TITLE,
  SET_DELETE_NEW_COURSE_FILES,
  SET_FINISHED_NAV,
} from "../../../../../config/redux/types";
import {
  _getStreamLocalFile,
  _uploadFileNewCourse,
} from "../../../../../config/services/api/course";
import getLink from "../../../../../constants/getLink";
import getVideoDuration from "../../../../../constants/getVideoDuration";

const Hompage = () => {
  const { token } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);
  const newCourse = useSelector((state) => state.newCourseReducer);

  const data = newCourse.homepage;

  const setData = (value) =>
    dispatch({ type: SET_COURSE_HOMPAGE, payload: value });

  const [loading, setLoading] = useState({ thumbnail: false, intro: false });

  const [fileSrc, setFileSrc] = useState({ thumbnail: "", intro: "" });

  const setInputValue = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const changeLoading = (key, value) =>
    setLoading((crr) => ({ ...crr, [key]: value }));

  const uploadFile = async (key, file) => {
    let uploadedData = {};

    const acceptType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "video/mp4";

    if (!acceptType) return alert("format file tidak didukung");

    changeLoading(key, true);

    if (file?.type === "video/mp4") {
      const duration = await getVideoDuration(file);

      if (duration) uploadedData = { duration };
    }

    try {
      const uploadFileRes = await _uploadFileNewCourse(file, token);

      if (!uploadFileRes.status) alert(uploadFileRes.message);

      uploadedData = { ...uploadedData, ...uploadFileRes.data };

      setInputValue(key, uploadedData);

      // add to deleted file list
      if (data[key].storage === "cloud")
        dispatch({
          type: SET_DELETE_NEW_COURSE_FILES,
          payload: data[key].name,
        });
    } catch (error) {
      console.log("something went wrong", error);
    } finally {
      changeLoading(key, false);
    }
  };

  const changeToolValue = (targetIndex, value) => {
    data.tools[targetIndex] = value;
    dispatch({ type: SET_COURSE_HOMPAGE, payload: { ...data } });
  };

  const addNewTool = () => {
    data.tools.push("");
    dispatch({ type: SET_COURSE_HOMPAGE, payload: { ...data } });
  };

  const deleteTool = (targetIndex) => {
    data.tools.splice(targetIndex, 1);
    dispatch({ type: SET_COURSE_HOMPAGE, payload: { ...data } });
  };

  useEffect(() => {
    if (data.intro?.storage === "cloud") {
      setFileSrc((crr) => ({ ...crr, intro: data.intro.link }));
    } else if (data.intro?.storage === "local") {
      changeLoading("intro", true);
      _getStreamLocalFile(token, data.intro?.name)
        .then((src) => setFileSrc((crr) => ({ ...crr, intro: src })))
        .catch((e) => console.log("error get src intro", e))
        .finally(() => changeLoading("intro", false));
    }
  }, [token, data.intro?.storage, data.intro?.link, data.intro?.name]);

  useEffect(() => {
    if (data.thumbnail?.storage === "cloud") {
      setFileSrc((crr) => ({ ...crr, thumbnail: data.thumbnail.link }));
    } else if (data.thumbnail?.storage === "local") {
      changeLoading("thumbnail", true);
      _getStreamLocalFile(token, data.thumbnail?.name)
        .then((src) => setFileSrc((crr) => ({ ...crr, thumbnail: src })))
        .catch((e) => console.log("error get src thumbnail", e))
        .finally(() => changeLoading("thumbnail", false));
    }
  }, [
    token,
    data.thumbnail?.storage,
    data.thumbnail?.link,
    data.thumbnail?.name,
  ]);

  const setTitle = (title) => {
    dispatch({ type: SET_COURSE_TITLE, payload: title });
  };
  const setCategory = (category) => {
    dispatch({ type: SET_COURSE_CATEGORY, payload: category });
  };

  const handleChangeTitle = (e) => {
    if (e) {
      setTitle(e);
    } else {
      setTitle("");
    }
    // console.log(`${e}`)
  };

  // console.log(data.thumbnail)


  return (
    <LayoutNewCourseStep4
      title="Halaman Awal Kursus"
      subTitle="Ini akan ditampilkan pada halaman saat user melihat detail kursus Anda"
    >
      <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
        <InputTextLabel
          text="Judul Kursus"
          id="title"
          placeholder="Masukkan Judul Kursus Anda"
          value={newCourse.courseTitle}
          onChange={(e) => handleChangeTitle(e.target.value)}
        />
        <InputTextareaLabel
          text="Deskripsi kursus"
          id="description"
          placeholder="Jelaskan mengenai kursus Anda"
          value={data.description}
          onChange={(e) => setInputValue("description", e.target.value)}
        />
        <div className="w-[493px] space-y-6">
          <div className="space-y-2">
            {data.tools.map((tool, i) => (
              <div className="relative" key={i}>
                <InputTextLabel
                  text={"tool " + (i + 1)}
                  placeholder="Figma"
                  value={tool}
                  onChange={(e) => changeToolValue(i, e.target.valuevalue)}
                />
                <button
                  type="button"
                  onClick={() => deleteTool(i)}
                  className="absolute -right-14 top-4 h-full flex items-center"
                >
                  <Icon
                    name="delete_outline-secondary-3-500"
                    className="w-[30px] h-[30px]"
                  />
                </button>
              </div>
            ))}
            <ButtonPlus type="button" onClick={addNewTool}>
              Tambahkan Tools
            </ButtonPlus>
          </div>
        </div>
        <div className="flex items-center space-x-6">
          <InputDropdownLabel2
            text="Bahasa"
            values={["--Pilih Bahasa--", "Bahasa Indonesia"]}
            value={data.language}
            setValue={(value) => setInputValue("language", value)}
          />
          <InputDropdownLabel2
            text="Level"
            values={["--Pilih Level--", "Beginner", "Intermediete", "Expert"]}
            value={data.level}
            setValue={(value) => setInputValue("level", value)}
          />
          <InputDropdownLabel2
            text="Kategori"
            values={[
              "--Pilih Kategori--",
              "Sales",
              "IT/Sofware",
              "Jurnalistik",
              "Manajemen",
              "Bisnis",
              "Communication",
              "Marketing",
              "Design",
              "Language",
            ]}
            value={newCourse.courseCategory}
            setValue={(value) => setCategory(value)}
          />
        </div>
        <div className="w-[493px]">
          <InputTextLabel
            text="Pelajaran Utama"
            id="main-lesson"
            placeholder="Apa yang paling utama diajarkan dalam kursus ini"
            value={data.mainLesson}
            onChange={(e) => setInputValue("mainLesson", e.target.value)}
          />
        </div>
        <div className="space-y-3">
          <div className="space-y-2">
            <p className="text-sm font-semibold text-netral-8">
              Profile Instruktur
            </p>
            <div className="flex items-center space-x-3">
              <div className="w-[52px] h-[52px] bg-pink-700 rounded-full"></div>
              <div className="space-y-2">
                <h6 className="text-netral-10 text-lg font-medium">
                  {userData.name}
                </h6>
                <p className="text-netral-8">UI/UX Designer Tokopedia</p>
              </div>
            </div>
            <button className="p-1 flex items-center space-x-2">
              <span className="font-semibold text-primary-500">
                Perbarui Profile
              </span>
              <Icon name="arrow_right_alt-primary-500" className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-sm font-semibold text-netral-8">Thumbnail</p>
          <InputFilePreview
            loading={loading.thumbnail}
            value={data.thumbnail}
            setValue={(value) => uploadFile("thumbnail", value)}
            type="image"
            src={fileSrc?.thumbnail?.includes("blob") ? fileSrc.thumbnail : getLink(fileSrc.thumbnail)}
          />
        </div>
        <div className="space-y-2">
          <p className="text-sm font-semibold text-netral-8">
            Video Pengenalan Kelas secara Umum
          </p>
          <InputFilePreview
            loading={loading.intro}
            value={data.intro}
            setValue={(value) => uploadFile("intro", value)}
            type="video"
            src={fileSrc.intro}
          />
        </div>
      </form>
      <ChildComponent data={data} />
    </LayoutNewCourseStep4>
  );
};

export default Hompage;

const ChildComponent = ({ data }) => {
  const newCourse = useSelector((state) => state.newCourseReducer);
  const { userData } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  data.title = data.title || newCourse.courseTitle;

  data.instructor = userData.name;

  const isEveryInputFilled = () => {
    if (Object.values(data).every((input) => input)) return true;
    return false;
  };
  return (
    <NextOrLaterButton
      active={isEveryInputFilled()}
      page={"homepage"}
      next={() => {
        dispatch({ type: SET_COURSE_HOMPAGE, payload: data });
        dispatch({ type: SET_FINISHED_NAV, payload: "homepage" });
        push(`/new/course/${id}/step6/pricing`);
      }}
    />
  );
};
