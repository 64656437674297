import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./config/redux/store";
import "video-react/dist/video-react.css";
import "swiper/css";
import "./style/index.css";
import "./style/swiper-pagination.css";
import "./style/swiper-navigation.css";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

ReactDOM.render(
  <BrowserRouter
    // getUserConfirmation={(message, callback) => {
    //   // this is the default behavior
    //   const allowTransition = window.confirm(message);
    //   callback(allowTransition);
    // }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
