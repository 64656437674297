import { useSelector } from "react-redux";
import { Icon } from "../../../independent/image";
import CheckoutVirtualAccountInfo from "../checkout-virtual-account-info";
import VerifiedPhoneNumber from "../verified-phone-number";

export default function Verfication({ isVerified, setModal, payment }) {
  const { userData } = useSelector((state) => state.authReducer);

  return (
    <>
      <div className="lg:flex items-end lg:space-x-6 space-y-4 lg:space-y-0 text-sm lg:text-base">
        <div className="w-full lg:max-w-[341px] space-y-2">
          <p className="text-netral-8 text-sm font-semibold">
            {payment === "virtual-account" && "Pilih Bank Virtual Account"}
            {payment === "bank" && "Bank Transfer BCA (Verifikasi Manual)"}
          </p>
          <div className="bg-white rounded border border-[#757575] p-3 flex items-center justify-between">
            <p className="text-netral-10">
              {/* {payment === "virtual-account" && "ATM Permata Indonesia "} */}
              {/* {payment === "bank" && "Bank BCA"} */}
              Bank BCA
            </p>
            <Icon
              name="keyboard_arrow_down-netral-10"
              className="w-[22px] h-[22px]"
            />
          </div>
        </div>
        {userData.phoneNumber && isVerified ? (
          <VerifiedPhoneNumber />
        ) : (
          <div className="flex space-x-4 items-end">
            <div className="space-y-2 whitespace-nowrap w-full">
              <p className="text-netral-8 text-sm font-semibold">
                Masukkan nomor Handphone
              </p>
              <div className="flex items-center space-x-6">
                <div className="w-[79px] bg-white rounded border border-[#757575] p-3 flex items-center justify-between">
                  <p className="text-netral-10">+62</p>
                  <Icon
                    name="keyboard_arrow_down-netral-10"
                    className="w-[22px] h-[22px]"
                  />
                </div>
                <input className="w-full lg:w-[183px] rounded border border-[#757575] p-3" />
              </div>
            </div>
            {!isVerified ? (
              <div>
                <button
                  onClick={() => setModal("otp")}
                  className="font-semibold text-white p-3 rounded bg-secondary2-900 border border-transparent interactive-button-color"
                >
                  Verifikasi
                </button>
              </div>
            ) : (
              <div>
                <div className="flex items-center h-12">
                  <Icon
                    name="check circle-primary-500"
                    className="w-[25px] h-[25px]"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isVerified && <CheckoutVirtualAccountInfo payment={payment} />}
    </>
  );
}
