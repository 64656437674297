import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import { _getPrakerjaCourseByCategory } from "../../../../config/services/api/course";
import CardCourse from "../../../independent/card/card-course";
import { Icon } from "../../../independent/image";
import TabsCourse from "../../../independent/tabs/tabs-course";

SwiperCore.use([Navigation]);

export default function PrakerjaCourses() {
  const [courses, setCourses] = useState([]);
  const [activeTab, setActiveTab] = useState("Semua");

  useEffect(() => {
    _getPrakerjaCourseByCategory(activeTab).then((res) => {
      if (res.status) setCourses(res.class);
      else console.log("cannot get course", res);
      // else alert(res.message);
    });
  }, [activeTab]);

  return (
    <section className="my-6 lg:my-28">
      <div className="text-netral-10 pl-4 pr-4 lg:pl-3 lg:pr-[51px]">
        <div className="mt-2 lg:mt-3 flex items-center justify-between">
        <h2 className="text-lg lg:text-2xl font-semibold lg:leading-[40px]">
          Kelas Prakerja
        </h2>
          {/* <p className="text-xs lg:text-base">
            Kursus paling banyak dibeli pengguna kartu Prakerja
          </p> */}
          <Link
            to="/course?type=Prakerja&"
            className="text-xs lg:text-base text-primary2-500 font-semibold space-x-1 lg:space-x-[10px] flex items-center"
          >
            <span>Lihat semua</span>
            <Icon name="arrow_right_alt-primary-2-500" />
          </Link>
        </div>
      </div>
      <TabsCourse
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      <div className="m-4 lg:m-0 flex ">
        <div className="w-full ">
          <Swiper {...settings} className="mySwiper h-full">
            {courses.map((course) => course.classType === "Offline Class" && (
              <SwiperSlide
                key={course._id}
                className="lg:p-8 odd:w-[137px] even:w-[137px] lg:odd:w-[270px] lg:even:w-[270px]"
              >
                <CardCourse course={course} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

const settings = {
  slidesPerView: "auto",
  spaceBetween: 16,
  pagination: false,
  navigation: true,
  breakpoints: {
    1024: {
      spaceBetween: 40,
    },
  },
};

const tabs = [
  { text: "Semua" },
  { text: "Design" },
  { text: "Pengembangan" },
  { text: "IT dan Pengembangan" },
  { text: "Bisnis" },
  { text: "Marketing" },
];
