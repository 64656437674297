import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function RegisterOrLoginButton() {
  const { pathname } = useLocation();

  return (
    <div className="mt-6 lg:mt-8 flex items-center space-x-5 font-semibold">
      <span className="text-sm lg:text-base">
        {pathname === "/login" ? "Belum" : "Sudah"} punya akun?
      </span>
      <Link
        to={`/${pathname === "/login" ? "register" : "login"}`}
        className="flex items-center space-x-1 text-primary2-500"
      >
        <span className="text-sm lg:text-base">
          {pathname === "/login" ? "Daftar" : "Login"} sekarang
        </span>
        <img
          src="/assets/icon/arrow_right_alt-primary-2-500.svg"
          className="h-[20px] w-[20px] mt-px"
          alt="arrow-right"
        />
      </Link>
    </div>
  );
}
