import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";

import { Icon } from "../../image";

const getRangeDate = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate);
  currentDate.setUTCDate(currentDate.getUTCDate());
  end.setUTCDate(end.getUTCDate() + 2);

  while (currentDate <= new Date(end)) {
    dateArray.push(new Date(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return dateArray;
};

const reduceHour = (hour, reduce) => {
  let time = new Date();
  const hourArr = hour.split(":");
  time.setHours(+hourArr[0], +hourArr[1], 0);

  time.setHours(time.getHours() - reduce);
  let formattedTime = time.toTimeString().slice(0, 5);

  return formattedTime;
};
const AddHour = (hour, reduce) => {
  let time = new Date();
  const hourArr = hour.split(":");
  time.setHours(+hourArr[0], +hourArr[1], 0);

  time.setHours(time.getHours() - reduce);
  let formattedTime = time.toTimeString().slice(0, 5);

  return formattedTime;
};

export default function SideNavLearnMenuWebinar({
  enrollment,
  isSideNavOpened,
  type,
}) {
  const { participantProgress, participantType, individualTest } = useSelector(
    (state) => state.courseReducer
  );

  const sortedParticipantProgress = useMemo(() => {
    if (participantProgress?.chapters) {
      return participantProgress?.chapters.sort((x, y) => {
        if (x.chapterIndex === y.chapterIndex) {
          return x.materialIndex < y.materialIndex ? -1 : 1;
        } else {
          return x.chapterIndex < y.chapterIndex ? -1 : 1;
        }
      });
    } else {
      return undefined;
    }
  }, [participantProgress]);

  const course = useSelector((state) => state.courseReducer);
  const { userData } = useSelector((state) => state.authReducer);

  const { pathname } = useLocation();
  const { push } = useHistory();
  const { courseId } = useParams();
  const isWebinar = course?.participant_type?.includes("WEBINAR");

  const [navigations, setNavigations] = useState([]);
  const [informationState, setInformationState] = useState({
    title: `Informasi Utama`,
    link: `/learn/${courseId}/info`,
    active: true,
  });

  const date = getRangeDate(
    moment(course?.learner_schedule?.startDate).format("YYYY-MM-DD"),
    course?.learner_schedule?.endDate
  );

  const handleClick = (nav, targetIndex) => {
    if (!nav.active && nav.link) return;
    if (nav.link) push(nav.link);
    else {
      navigations[targetIndex].isShown = !navigations[targetIndex].isShown;
      setNavigations([...navigations]);
    }
  };

  // const subTabs = () => {
  //   const temp = []
  //   for (let i = 0; i < enrollment.chapters.length; i++) {
  //     const chapters = []
  //     for (let j = 0; j < enrollment.chapters[i].materials.length; j++) {
  //       if(enrollment.chapters[i].materials[j].name === enrollment.chapters[i].materials[j-1].name){
  //         chapters.push(enrollment.chapters[i].materials[j]?.name)
  //         chapters.push(enrollment.chapters[i].materials[j-1]?.name)
  //       }
  //     }
  //     temp.push(chapters)
  //   }
  //   return temp
  // };

  // type kuis = "Pilihan Ganda"

  useEffect(() => {
    const findTargetindex = (chapterIndex, materialIndex) => {
      const targetIndex = sortedParticipantProgress?.findIndex((el) => {
        return (
          el.chapterIndex === chapterIndex && el.materialIndex === materialIndex
        );
      });
      return targetIndex;
    };

    const activeMaterial = (chapterIndex, materialIndex) => {
      if (!participantProgress?.chapters.length) return;
      const materialLength =
        course?.enrollment?.chapters[chapterIndex]?.materials.length;

      const targetIndex = sortedParticipantProgress?.findIndex((el) => {
        return (
          el.chapterIndex === chapterIndex && el.materialIndex === materialIndex
        );
      });

      if (userData?.role?.includes("Assesor")) {
        return true;
      }

      if (participantProgress?.pretest.length < 1) {
        return false;
      }

      if (sortedParticipantProgress[targetIndex]?.isCompleted) {
        return true;
      }

      if (sortedParticipantProgress[targetIndex - 1]?.isCompleted === false) {
        return false;
      }

      if (
        sortedParticipantProgress[targetIndex - 1]?.type === "Pilihan Ganda" &&
        sortedParticipantProgress[targetIndex - 1].score < 80
      ) {
        return false;
      }

      if (course?.attendances.length > 0) {
        if (
          course?.attendances.find((el) => el.day - 1 === chapterIndex)
            ?.isPresent
        ) {
          return true;
        } else {
          return false;
        }
      }

      // if (
      //   chapterIndex === 0 &&
      //   participantProgress?.pretest?.some((pretest) => pretest.isCompleted) &&
      //   moment().format("YYYY-MM-DD") ===
      //     moment(date[chapterIndex]).format("YYYY-MM-DD")
      // ) {
      //   if (
      //     moment().format("HH:MM") >
      //     reduceHour(course.learner_schedule.endHour, 1)
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }

      if (
        materialLength + 1 === materialIndex &&
        sortedParticipantProgress[findTargetindex(chapterIndex, 1)]
          ?.isCompleted === false
      ) {
        return false;
      }
      if (
        materialLength + 1 === materialIndex &&
        sortedParticipantProgress[findTargetindex(chapterIndex, 1)]?.score < 80
      ) {
        return false;
      }


      if (
        materialLength + 1 === materialIndex &&
        moment().format("YYYY-MM-DD") >=
          moment(date[chapterIndex]).format("YYYY-MM-DD")
      ) {
        return true;
      } else if (
        moment().format("YYYY-MM-DD") ===
        moment(date[chapterIndex]).format("YYYY-MM-DD")
      ) {
        if (
          moment().format("HH:mm") >
          reduceHour(course.learner_schedule.startHour, 1)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        moment().format("YYYY-MM-DD") >
        moment(date[chapterIndex]).format("YYYY-MM-DD")
      ) {
        return true;
      }

      if (targetIndex > 0) {
        console.log(materialIndex, chapterIndex);
      }

      // if (
      //   moment().format("YYYY-MM-DD") ===
      //   moment(date[chapterIndex]).format("YYYY-MM-DD")
      // ) {
      //   if (
      //     moment().format("HH:MM") >
      //     reduceHour(course.learner_schedule.endHour, 1)
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else if (
      //   moment().format("YYYY-MM-DD") ===
      //   moment(date[chapterIndex + 1]).format("YYYY-MM-DD")
      // ) {
      //   if (
      //     moment().format("HH:MM") <
      //     reduceHour(course.learner_schedule.startHour, 2)
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else if (
      //   moment().format("YYYY-MM-DD") ===
      //   moment(date[chapterIndex + 1]).format("YYYY-MM-DD")
      // ) {
      //   return false;
      // }

      if (
        sortedParticipantProgress[targetIndex]?.type !== "Pilihan Ganda" &&
        sortedParticipantProgress[targetIndex]?.isCompleted
      )
        return true;

      // if (userData?.role?.includes("Assesor")) {
      //   return true;
      // }

      // if (sortedParticipantProgress?[targetIndex]?.isCompleted) return true;

      if (targetIndex === -1 || targetIndex === 0) return;
      return false;
    };

    const activeSkillTest =
      userData?.role?.includes("Assesor") ||
      (participantProgress?.posttest?.some(
        (posttest) => posttest.isCompleted
      ) &&
        Math.max.apply(
          0,
          participantProgress?.posttest?.map((el) => +el.score)
        ) > 59);

    if (enrollment) {
      const enroll = enrollment.chapters.map((chapter, chapterIndex) => ({
        ...chapter,
        title: `Sesi ${chapterIndex + 1}`,
        isShown: false,
        links: participantType.includes("WEBINAR")
          ? [
              ...chapter.materials.map((material, materialIndex) => ({
                ...material,
                title:
                  material.type === "application/pdf" ? "Materi " : "Kuis ",
                link: `/learn/${courseId}/chapter${chapterIndex + 1}-material${
                  materialIndex + 1
                }`,
                active: activeMaterial(chapterIndex, materialIndex),
                isCompleted: sortedParticipantProgress?.find(
                  (el) =>
                    el.chapterIndex === chapterIndex &&
                    el.materialIndex === materialIndex
                )?.isCompleted,
              })),
              {
                name: "Tugas Praktik Mandiri",
                title: "Tugas Praktik Mandiri",
                link: `/learn/${courseId}/chapter${chapterIndex + 1}-material${
                  chapter.materials.length + 1
                }`,
                active: activeMaterial(
                  chapterIndex,
                  chapter.materials.length + 1
                ),
                isCompleted: individualTest?.find(
                  (el) => +el.chapterIndex === chapterIndex
                )?.isCompleted,
              },
            ]
          : [
              ...chapter.materials.map((material, materialIndex) => ({
                ...material,
                title:
                  material.type === "application/pdf" ? "Materi " : "Kuis ",
                link: `/learn/${courseId}/chapter${chapterIndex + 1}-material${
                  materialIndex + 1
                }`,
                active: activeMaterial(chapterIndex, materialIndex),
                isCompleted: sortedParticipantProgress?.find(
                  (el) =>
                    el.chapterIndex === chapterIndex &&
                    el.materialIndex === materialIndex
                )?.isCompleted,
              })),
            ],
      }));
      if (isWebinar) {
        setNavigations([
          {
            title: "Pretest",
            link: `/learn/${courseId}/pre-test`,
            active: true,
          },
          {
            title: `Jadwal dan Link Zoom`,
            link: `/learn/${courseId}/schedule`,
            active: true,
          },
          ...enroll,
          {
            title: "Post Test",
            link: `/learn/${courseId}/post-test`,
            active:
              participantProgress?.chapters[
                participantProgress.chapters.length - 1
              ]?.isCompleted || userData?.role?.includes("Assesor"),
          },
          {
            title: "Uji Keterampilan",
            link: `/learn/${courseId}/skill-test`,
            active: activeSkillTest,
          },
          {
            title: "Reflective Journal",
            link: `/learn/${courseId}/reflective-journal`,
            active: true,
            isCompleted:course?.reflectiveJournal
          },
        ]);
      } else {
        setNavigations([
          {
            title: `Jadwal Kursus`,
            link: `/learn/${courseId}/schedule`,
            active: true,
          },
          {
            title: "Pretest",
            link: `/learn/${courseId}/pre-test`,
            active: course.attendances.some((el) => el.isPresent),
          },
          ...enroll,
          {
            title: "Post Test",
            link: `/learn/${courseId}/post-test`,
            active:
              participantProgress?.chapters[
                participantProgress.chapters.length - 1
              ]?.isCompleted || userData?.role?.includes("Assesor"),
          },
          {
            title: "Uji Keterampilan",
            link: `/learn/${courseId}/skill-test`,
            active: activeSkillTest,
          },
          {
            title: "Reflective Journal",
            link: `/learn/${courseId}/reflective-journal`,
            active: true,
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollment, courseId, participantProgress]);

  // const findIndexLink = (link) => {``
  //   const chapterStr = link.split("-")[0].split("/").pop();
  //   const materialStr = link.split("-")[1];

  //   return {
  //     chapter: Number(chapterStr?.split("chapter")[1]) - 1,
  //     material: Number(materialStr?.split("material")[1]) - 1,
  //   };
  // };

  return (
    <aside
      className={`${
        isSideNavOpened
          ? "translate-x-0"
          : "translate-x-[-100vw] lg:translate-x-0"
      }  fixed top-0 left-0 lg:static lg:bg-white lg:block z-10 h-screen lg:h-full w-5/6 lg:w-max duration-500`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="p-6 lg:p-8 pr-0 w-full lg:w-[318px] h-full bg-[#F5F5F5] lg:bg-primary-100/30 overflow-y-auto overflow-x-hidden"
      >
        <Link to="/student" className="flex items-center space-x-2">
          <Icon
            name="arrow_back-secondary-2-800"
            className="w-5 h-5 lg:w-auto lg:h-auto"
          />
          <span className="text-sm lg:text-base font-semibold lg:font-DM-Sans text-primary-700 leading-[22.4px]">
            Kembali ke Dashboard
          </span>
        </Link>
        <nav className="mt-5 w-full ml-1">
          <ul>
            {isWebinar && (
              <li className="lg:py-3 border-b border-[#E0E0E0] space-y-2 lg:space-y-4">
                <button
                  type="button"
                  onClick={() => handleClick(informationState, 0)}
                  className={`${
                    informationState.link &&
                    pathname === informationState.link &&
                    "bg-primary-200/40"
                  } p-3 lg:p-4 w-full gap-[8px] text-netral-10 font-semibold rounded-l flex justify-between items-center text-left relative`}
                >
                  <Icon name={"info"} />
                  <p className="lg:w-[214px] text-sm lg:text-base">
                    {informationState.title}
                  </p>
                </button>
              </li>
            )}
            {navigations.map((nav, i) => (
              <li
                key={i}
                className="lg:py-3 border-b border-[#E0E0E0] space-y-2 lg:space-y-4"
              >
                <button
                  type="button"
                  onClick={() => handleClick(nav, i)}
                  className={`${
                    nav.link && pathname === nav.link && "bg-primary-200/40"
                  } p-3 lg:p-4 w-full text-netral-10 font-semibold rounded-l flex justify-between items-center text-left relative`}
                >
                  {nav.title === "Pretest" &&
                    participantProgress?.pretest?.some(
                      (posttest) => posttest.isCompleted
                    ) && (
                      <Icon
                        name="check circle-primary-500"
                        className="w-[15px] h-[15px] absolute right-1 mr-5"
                      />
                    )}
                  {nav.title === "Post Test" &&
                    participantProgress?.posttest?.some(
                      (posttest) => posttest.isCompleted
                    ) && (
                      <Icon
                        name="check circle-primary-500"
                        className="w-[15px] h-[15px] absolute right-1 mr-5"
                      />
                    )}
                  {nav.title === "Uji Keterampilan" &&
                    participantProgress?.skilltest.length !== 0 && (
                      <Icon
                        name="check circle-primary-500"
                        className="w-[15px] h-[15px] absolute right-1 mr-5"
                      />
                    )}
                  {nav.isCompleted && (
                      <Icon
                        name="check circle-primary-500"
                        className="w-[15px] h-[15px] absolute right-1 mr-5"
                      />
                    )}

                  <p className="lg:w-[214px] text-sm lg:text-base">
                    {nav.title}
                  </p>
                  {!nav.link ? (
                    <Icon
                      name="arrow_back_ios-netral-10"
                      className={`w-[18px] h-[18px] lg:w-6 lg:h-6 duration-100 ${
                        nav.isShown && "rotate-180"
                      }`}
                    />
                  ) : (
                    <></>
                  )}
                </button>
                <div
                  className={`${
                    nav.isShown ? "py-px" : "h-0"
                  } flex flex-col space-y-2 px-4 overflow-hidden duration-100`}
                >
                  {nav.links?.map((link, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        if (!link?.active) return;
                        push(link.link);
                      }}
                      className={`${
                        link.link === pathname && "bg-primary-200/40"
                      } text-left py-1 pl-2 space-y-2 rounded ${
                        !link.active && "cursor-not-allowed"
                      }
                      relative
                      `}
                    >
                      {!participantType.includes("WEBINAR") && (
                        <p
                          className={`${
                            link.active ? "text-netral-10" : "text-netral-5"
                          } text-sm w-[80%]`}
                        >
                          {link.name}
                        </p>
                      )}
                      {link.isCompleted && (
                        <div className="absolute right-2 top-[24%]">
                          <Icon
                            name="check circle-primary-500"
                            className="w-[15px] h-[15px] "
                          />
                        </div>
                      )}
                      <div className="flex items-center relative">
                        <Icon
                          name={
                            (link.type === "video/mp4" &&
                              "play_circle_outline-netral-10") ||
                            (link.type === "application/pdf" &&
                              "folder_open-netral-10") ||
                            "format_list_numbered-netral-10"
                          }
                          className="w-[20px] h-[20px]"
                        />
                        <p
                          className={`${
                            link.active ? "text-netral-10" : "text-netral-5"
                          } text-sm ml-2`}
                        >
                          {/* {link.description} */}
                          {/* {link.type === "application/pdf"
                            ? "Materi "
                            : "Kuis "} */}
                          {link.title}
                          {/* {link.chapterIndex + 1} */}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
