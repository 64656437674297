import moment from "moment";
// import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
// import Quiz from "../../../components/dependent/learn-course/quiz";
// import Finish from "../../../components/dependent/learn-course/quiz/Finish";
// import First from "../../../components/dependent/learn-course/quiz/First";
// import PrevOrNextController from "../../../components/dependent/learn-course/quiz/PrevOrNextController";
import { Icon } from "../../../components/independent/image";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import PinMaps from "../../../components/independent/maps";
// import { postPretestProgress } from "../../../config/redux/actions/courseAction";
import getRangeDate from "../../../constants/getRangeDate";
// import { SET_PRETEST_PROGRESS } from "../../../config/redux/types";

const LearnSchedule = () => {
  const { push } = useHistory();
  const { courseId } = useParams();
  const course = useSelector((state) => state.courseReducer);
  const { participantProgress } = useSelector((state) => state.courseReducer);

  const date = getRangeDate(
    moment(course?.learner_schedule?.startDate).format("YYYY-MM-DD"),
    course?.learner_schedule?.endDate
  );

  const isWebinar = course?.participant_type?.includes("WEBINAR");

  // const copyToClipboard = (e) => {
  //   this.textArea.select();
  //   document.execCommand("copy");
  //   e.target.focus();
  // };

  const isFinishedClass = (dateIdx) => {
    return participantProgress?.chapters
      ?.filter((el) => el.chapterIndex === dateIdx)
      .some((el) => el.isCompleted);
  };

  return (
    <LayoutLearnCourse>
      <div
        style={{
          background:
            "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
        }}
        className=" border border-primary-300 rounded-lg p-[25px]"
      >
        <div className="space-y-3 mb-[24px]">
          {!isWebinar && (
            <h3 className="text-netral-10 text-lg">Lokasi Kursus</h3>
          )}
          {!isWebinar && (
            <div className="bg-netral-1 border border-netral-3 w-full p-[16px]">
              <div className="flex items-center space-x-3 ">
                <Icon name={"map"} className="w-[20px] h-[20px]" />
                <p className="break-all">{course?.location?.address}</p>
              </div>
            </div>
          )}
          {!isWebinar && (
            <div className="bg-netral-1 border border-netral-3 w-full p-[16px]">
              <PinMaps
                lat={+course?.location?.latitude || 0}
                lng={+course?.location?.longitude || 0}
                mapHeight={"220px"}
                mapWidth={"90vw"}
              />
            </div>
          )}
        </div>
        <div className="space-y-3">
          <h3 className="text-netral-10 text-lg">
            Jadwal {isWebinar ? "Webinar" : "Kursus"}
          </h3>
          {date.length === 0 && (
            <div className="bg-netral-1 border border-netral-3 w-full p-[16px] flex justify-between items-center">
              <div className="space-y-2">
                <p className="text-lg text-secondary-button-800 font-bold">
                  Pertemuan 1
                </p>
                <div className="flex">
                  <p className="border-r pr-[5px] mr-[5px] text-sm font-medium">
                    {moment(course?.learner_schedule?.startDate).format("LL")}
                  </p>
                  <p className="text-sm font-medium">
                    {course?.learner_schedule?.startHour} -
                    {course?.learner_schedule?.endHour}
                  </p>
                </div>
              </div>
              {isFinishedClass(0) && (
                <div className="bg-secondary-button-800 text-netral-1 py-[6px] px-[16px] rounded-full flex items-center space-x-[10px]">
                  <Icon name="check-netral-1" className="w-[14px] h-[14px]" />
                  <p>selesai</p>
                </div>
              )}
            </div>
          )}
          {date.map((el, idx) => (
            <div className="bg-netral-1 border border-netral-3 w-full p-[16px] flex justify-between items-center">
              <div key={idx} className="space-y-2">
                <p className="text-lg text-secondary-button-800 font-bold">
                  Pertemuan {idx + 1}
                </p>
                <div className="flex">
                  <p className="border-r pr-[5px] mr-[5px] text-sm font-medium">
                    {moment(el).format("LL")}
                  </p>
                  <p className="text-sm font-medium">
                    {course?.learner_schedule?.startHour} -
                    {course?.learner_schedule?.endHour}
                  </p>
                </div>
              </div>
              {isFinishedClass(idx) && (
                <div className="bg-secondary-button-800 text-netral-1 py-[6px] px-[16px] rounded-full flex items-center space-x-[10px]">
                  <Icon name="check-netral-1" className="w-[14px] h-[14px]" />
                  <p>selesai</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* <PrevOrNextButton
        prevActive={false}
        // nextActive={moment(date[0]).format("LL") === moment(course?.learner_schedule?.startDate).format("LL")}
        nextActive={true}
        next={() => push(`/learn/${courseId}/chapter1-material1`)}
      /> */}
    </LayoutLearnCourse>
  );
};

export default LearnSchedule;
