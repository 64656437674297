import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AlertTraditional from "../../components/independent/alert/alert-traditional";
import ButtonPrimary from "../../components/independent/button/button-primary";
import { Icon } from "../../components/independent/image";
import InputTextLabel from "../../components/independent/input/input-text-label";
import LayoutAuth from "../../components/independent/layout/layout-auth";
import { _resetPassword } from "../../config/services/api/auth";

const CreateNewPassword = () => {
  const { push } = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const { token } = useParams();

  const resetPassword = async () => {
    setLoading(true);
    try {
      const res = await _resetPassword(token, password, confirmPassword);
      setStatus(res.status);
      setAlert(res.message);
      if (res.status)
        setTimeout(() => {
          push("/reset-password/success");
        }, 1500);
    } catch (error) {
      setStatus(false);
      setAlert("internal server error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LayoutAuth>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          resetPassword();
        }}
        className="space-y-8"
      >
        {alert && (
          <AlertTraditional status={status} className="mb-4">
            {alert}
          </AlertTraditional>
        )}

        <div className="space-y-3">
          <h4 className="text-xl font-semibold text-netral-10">
            Set New Password
          </h4>
          <p className="text-netral-7">
            Silakan ketik email kamu dan kami akan mengirimkan link untuk
            me-reset password mu
          </p>
        </div>
        <div className="space-y-6">
          <div className="space-y-3">
            <InputTextLabel
              id="password"
              text="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="text-red-500">* Minimal 8 digit, huruf besar dan angka</p>

            <InputTextLabel
              id="confirmPassword"
              text="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <ButtonPrimary disable={loading} type="submit">
            Reset password
          </ButtonPrimary>
          <div className="flex justify-center">
            <Link to="/login" className="flex items-center space-x-1">
              <span className="text-primary2-500 font-semibold">
                Login sekarang
              </span>
              <Icon name="arrow_right_alt-primary-2-500" className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </form>
    </LayoutAuth>
  );
};

export default CreateNewPassword;
