import { useRef, useState } from "react";
// import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Icon } from "../../../../components/independent/image";
// import InputDetailClassField from "../../../../components/Molecule/InputDetailClassField";
import InputField from "../../../../components/Molecule/InputField";
import TextAreaField from "../../../../components/Molecule/textAreaFiled";
import React from "react";
import useMultiSelectInput from "../../../../hooks/useMultipleValue";
import LayoutNewVideo from "../../../../components/independent/layout/layout-new-video";
import UploadFile from "../../../../components/Molecule/UploadFile";
import Dropdown from "../../../../components/Molecule/Dropdown";
import OptionalInput2 from "../../../../hooks/useOptionalInput2";
// import SaveToDraft from "../../../../components/Molecule/_video/SaveToDraftButton";
import { SET_VIDEO_GENERAL } from "../../../../config/redux/types";
import { useDispatch, useSelector } from "react-redux";
import OptionalInput from "../../../../hooks/useOptionalInput";
import MultipleInput from "../../../../components/Molecule/MultipleInput";
import { ModalPreview } from "../../../../components/Molecule/ModalPreview";
import CheckBox from "../../../../components/Molecule/CheckBox";
import LineSeperator from "../../../../components/Molecule/LineSeperator";
import SaveToDraft from "../../../../components/Molecule/SaveDraft";
import BackButton from "../../../../components/Molecule/BackButton";
import NextButton from "../../../../components/Molecule/NextButton";
import Syllabus from "../../../../components/dependent/course-detail/syllabus";
import getVideoDuration from "../../../../constants/getVideoDuration";
import formatVideoDuration from "../../../../constants/FormatVideoDuration";
// import InputFormClassDetail from "../../../../components/atom/input-detail-class";
// import LabelDetailClass from "../../../../components/atom/label-detail-class-webinar";

const Step1 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.newVideoReducer);
  // const [title, setTitle] = useState(courseTitle);

  const [dataToSend, setDataToSend] = useState(detail);
  // generalInformation: {
  //   title: "",
  //   category: "",
  //   language: "",
  //   isPrakerja: "",
  //   level: "",
  //   synopsis: "",
  //   description: "",
  //   goals: "",
  //   particularGoals: "",
  //   tools: [""],
  //   THUMBNAIL: "",
  //   INTRO: "",
  // },

  const handleChangeGeneralInfo = (name, value) => {
    setDataToSend((prev) => ({
      ...prev,
      generalInformation: { ...prev.generalInformation, [name]: value },
    }));
  };

  const handleChangeDetail = (name, value) => {
    setDataToSend((prev) => ({ ...prev, [name]: value }));
  };

  const dropdownItemsLanguage = [
    "Language A",
    "Language B",
    "Language C",
    "Language D",
  ];

  // const { handleAddInputOption, optionalInputs } = useOptionalInput(); // hooks optional input

  const handleDeleteParticularGoals = (index) => {
    handleChangeGeneralInfo(
      "particularGoals",
      dataToSend.generalInformation.particularGoals.filter(
        (_, i) => i !== index
      )
    );
  };
  const handleChangeParticularGoals = (index, value) => {
    handleChangeGeneralInfo(
      "particularGoals",
      dataToSend.generalInformation.particularGoals.map((el, idx) =>
        idx === index ? value : el
      )
    );
  };

  const handleAddParticularGoals = () => {
    handleChangeGeneralInfo("particularGoals", [
      ...dataToSend.generalInformation.particularGoals,
      "",
    ]);
  };

  const handleDeleteRegulations = (index) => {
    handleChangeDetail(
      "regulations",
      dataToSend.regulations.filter(
        (_, i) => i !== index
      )
    );
  };
  const handleChangeRegulations = (index, value) => {
    handleChangeDetail(
      "regulations",
      dataToSend.regulations.map((el, idx) =>
        idx === index ? value : el
      )
    );
  };

  const handleAddRegulations = () => {
    handleChangeDetail("regulations", [
      ...dataToSend.regulations,
      "",
    ]);
  };

  const handleChangeCompetencyUnit = (index, name, value) => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyUnit: prev.unitCompetency.competencyUnit.map((el, idx) =>
          idx === index ? { ...el, [name]: value } : el
        ),
      },
    }));
  };

  const handleDeleteCompetencyUnit = (index) => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyUnit: prev.unitCompetency.competencyUnit.filter(
          (_, idx) => index !== idx
        ),
      },
    }));
  };

  const handleAddCompetencyUnit = () => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyUnit: [
          ...prev.unitCompetency.competencyUnit,
          { code: "", value: "" },
        ],
      },
    }));
  };
  const handleChangeCompetencyGoals = (id, value) => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyGoals: prev.unitCompetency.competencyGoals.map((el, idx) =>
          el.id === id ? { ...el, value } : el
        ),
      },
    }));
  };

  const handleDeleteCompetencyGoals = (id) => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyGoals: prev.unitCompetency.competencyGoals.filter(
          (el, idx) => id !== el.id
        ),
      },
    }));
  };

  const handleAddCompetencyGoals = (type) => {
    setDataToSend((prev) => ({
      ...prev,
      unitCompetency: {
        ...prev.unitCompetency,
        competencyGoals: [
          ...prev.unitCompetency.competencyGoals,
          { type, value: "", id: new Date().getTime() },
        ],
      },
    }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [preview, setPreview] = useState();

  const handleImageChange = (event) => {
    const selectedPreview = event.target.files && event.target.files[0];
    if (selectedPreview && selectedPreview instanceof File) {
      setPreview(URL.createObjectURL(selectedPreview));
    } else {
      setPreview(null);
    }
  };
  const [isChecked, setIsChecked] = useState(false);
  // upload video

  //end upload video
  return (
    <LayoutNewVideo activeTab={1}>
      <SaveToDraft />
      <div className="w-full flex pb-5 gap-8 justify-center px-10 md:px-0 md:mt-8  lg:-mt-0 md:bg-transparent">
        <div className="max-w-[596px] max-[360px]:min-w-[360px] min-[800px]:mt-2 min-[1280px]:-mt-16 max-[430px]:min-w-[420px] max-[376px]:min-w-[340px] max-[430px]:px-[26px] max-[390px]:px-[26px] -mt-16">
          <div className="max-[430px]:flex max-[430px]:flex-col max-[430px]:gap-[8px]">
            <h1 className="text-[24px] max-[430px]:text-[20px] font-semibold">
              Detail Kelas
            </h1>
            <p className="text-[16px] font-medium text-[#757575]">
              Isi dan lengkapi data informasi kelas yang akan dibuat
            </p>
          </div>

          <LineSeperator
            required={false}
            headerTagLine={"Thumbnail dan Video Pengenalan Kelas"}
          />

          {/* start form */}
          <form action="" className="flex flex-col">
            <UploadFile
              htmlFor={"file"}
              labelValue={"Thumbnail"}
              required={true}
              inputId={"file"}
              placeholderInput={"Pilih file untuk thumbnail kelas"}
              handleChange={(value) => handleChangeDetail("THUMBNAIL", value)}
              selectedFile={dataToSend.THUMBNAIL}
              onClick={handleOpenModal}
              type={"image"}
              accept={".jpg,.png,.jpeg,"}
            />
            <UploadFile
              htmlFor={"video-pengenalan"}
              labelValue={"Video Pengenalan"}
              required={true}
              inputId={"video-pengenalan"}
              placeholderInput={"Pilih file untuk video pengenalan kelas"}
              handleChange={(value) => handleChangeDetail("INTRO", value)}
              selectedFile={dataToSend.INTRO}
              onClick={handleOpenModal}
              type={"video"}
              accept={"video/*"}
            />

            <LineSeperator required={false} headerTagLine={"Informasi Umum"} />

            <div className="flex flex-col gap-[20px]">
              <InputField
                addLabel={true}
                required={true}
                labelValue={"Judul Kelas"}
                classNameLabel={"text-[#616161] font-medium text-[14px]"}
                htmlFor={"judul-kelas"}
                inputId={"judul-kelas"}
                classNameInput={
                  "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                }
                placeholder={"Judul Kelas"}
                type={"text"}
                value={dataToSend.generalInformation.title}
                onChange={(e) =>
                  handleChangeGeneralInfo("title", e.target.value)
                }
              />
              <div className="grid grid-cols-2 gap-x-4 gap-y-[20px]">
                <Dropdown
                  dropdownItems={dropdownItemsLanguage}
                  labelValue={"Kategori Kelas"}
                  placeholder={"Kategori Kelas"}
                  handleChange={(value) =>
                    handleChangeGeneralInfo("category", value)
                  }
                  value={dataToSend.generalInformation.category}
                />

                <Dropdown
                  dropdownItems={dropdownItemsLanguage}
                  labelValue={"Bahasa"}
                  placeholder={"Bahasa"}
                  handleChange={(value) =>
                    handleChangeGeneralInfo("language", value)
                  }
                  value={dataToSend.generalInformation.language}
                />

                <Dropdown
                  dropdownItems={dropdownItemsLanguage}
                  labelValue={"Jenis Peserta"}
                  placeholder={"Jenis Peserta"}
                  handleChange={(value) =>
                    handleChangeGeneralInfo("isPrakerja", value)
                  }
                  value={dataToSend.generalInformation.isPrakerja}
                />

                <Dropdown
                  dropdownItems={dropdownItemsLanguage}
                  labelValue={"Level"}
                  placeholder={"Level"}
                  handleChange={(value) =>
                    handleChangeGeneralInfo("level", value)
                  }
                  value={dataToSend.generalInformation.level}
                />
              </div>

              <div className="flex flex-col gap-[12px]">
                <InputField
                  addLabel={true}
                  required={true}
                  labelValue={"Pembelajaran Utama"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                  htmlFor={"Pembelajaran Utama"}
                  inputId={"Pembelajaran Utama"}
                  classNameInput={
                    "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                  }
                  placeholder={"Pembelajaran Utama Kelas"}
                  type={"text"}
                  value={dataToSend.generalInformation.synopsis}
                  onChange={(e) =>
                    handleChangeGeneralInfo("synopsis", e.target.value)
                  }
                />
                <TextAreaField
                  isRequired={true}
                  labelValue={"Deskripsi"}
                  classNameTextArea={
                    "resize-none rounded-lg border-[#E0E0E0] placeholder:text-[#C2C2C2] focus:ring-[#9360A8]"
                  }
                  placeholder={"Deskripsi"}
                  htmlFor={"deskripsi"}
                  idTextArea={"deskripsi"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                  value={dataToSend.generalInformation.description}
                  onChange={(e) =>
                    handleChangeGeneralInfo("description", e.target.value)
                  }
                />
                <TextAreaField
                  isRequired={true}
                  labelValue={"Tujuan Umum"}
                  classNameTextArea={
                    "resize-none rounded-lg border-[#E0E0E0] placeholder:text-[#C2C2C2] focus:ring-[#9360A8]"
                  }
                  placeholder={"Tujuan Umum"}
                  htmlFor={"tujuan-umum"}
                  idTextArea={"tujuan-umum"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                  value={dataToSend.generalInformation.goals}
                  onChange={(e) =>
                    handleChangeGeneralInfo("goals", e.target.value)
                  }
                />
              </div>
              {/* start */}

              {dataToSend.generalInformation.particularGoals.map((val, index) =>
                index !== 0 ? (
                  <OptionalInput
                    placeholder={"Tujuan Khusus"}
                    onClick={() => handleDeleteParticularGoals(index)}
                    value={val}
                    onChange={(e) =>
                      handleChangeParticularGoals(index, e.target.value)
                    }
                  />
                ) : (
                  <InputField
                    addLabel={true}
                    required={true}
                    labelValue={"Tujuan Khusus"}
                    classNameLabel={"text-[#616161] font-medium text-[14px]"}
                    htmlFor={"tujuan-khusus"}
                    inputId={"tujuan-khusus"}
                    classNameInput={
                      "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                    }
                    placeholder={"Tujuan Khusus"}
                    type={"text"}
                    value={val}
                    onChange={(e) =>
                      handleChangeParticularGoals(index, e.target.value)
                    }
                  />
                )
              )}
              <div className="flex gap-1 items-center cursor-pointer">
                <span
                  style={{
                    background:
                      "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                  onClick={handleAddParticularGoals}
                >
                  + Tambah Tujuan Khusus
                </span>
              </div>
              {/* end */}
              <MultipleInput
                label={"Tools Pembelajaran"}
                htmlFor={"Tools Pembelajaran"}
                id={"Tools Pembelajaran"}
                items={dataToSend.generalInformation.tools}
                placeholder={"Tools Pembelajaran"}
                handleChange={(value) =>
                  handleChangeGeneralInfo("tools", value)
                }
              />
            </div>

            <LineSeperator required={false} headerTagLine={"Unit Kompetensi"} />

            <div className="grid grid-cols-1 gap-[12px] pr-[5%] relative">
              {dataToSend.unitCompetency.competencyUnit.map((val, index) =>
                index === 0 ? (
                  <div
                    key={index}
                    className="grid grid-cols-2 gap-[12px] pr-[5%] relative"
                  >
                    <InputField
                      addLabel={true}
                      required={true}
                      htmlFor={"kode-unit"}
                      inputId={"kode-unit"}
                      placeholder={"Kode Unit"}
                      labelValue={"Kode Unit"}
                      classNameLabel={"text-[#616161] font-medium text-[14px]"}
                      classNameInput={
                        "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                      }
                      value={val.code}
                      onChange={(e) =>
                        handleChangeCompetencyUnit(
                          index,
                          "code",
                          e.target.value
                        )
                      }
                    />
                    <InputField
                      addLabel={true}
                      required={true}
                      placeholder={"Judul Kompetensi"}
                      labelValue={"Judul Kompetensi"}
                      htmlFor={"judul-kompetensi"}
                      inputId={"judul-kompetensi"}
                      classNameLabel={"text-[#616161] font-medium text-[14px]"}
                      classNameInput={
                        "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                      }
                      value={val.value}
                      onChange={(e) =>
                        handleChangeCompetencyUnit(
                          index,
                          "value",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ) : (
                  <div
                    div
                    key={index}
                    className="grid grid-cols-2 gap-[12px] pr-[5%] relative"
                  >
                    <InputField
                      addLabel={false}
                      required={false}
                      placeholder={"Kode Unit"}
                      labelValue={"Kode Unit"}
                      classNameLabel={"text-[#616161] font-medium text-[14px]"}
                      classNameInput={
                        "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                      }
                      value={val.code}
                      onChange={(e) =>
                        handleChangeCompetencyUnit(
                          index,
                          "code",
                          e.target.value
                        )
                      }
                    />
                    <InputField
                      addLabel={false}
                      required={false}
                      placeholder={"Judul Kompetensi"}
                      labelValue={"Judul Kompetensi"}
                      classNameLabel={"text-[#616161] font-medium text-[14px]"}
                      classNameInput={
                        "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                      }
                      value={val.value}
                      onChange={(e) =>
                        handleChangeCompetencyUnit(
                          index,
                          "value",
                          e.target.value
                        )
                      }
                    />

                    <Icon
                      name="Optional-input"
                      w={20}
                      className={
                        "absolute max-[430px]:-right-2  right-0 bottom-0 cursor-pointer"
                      }
                      h={20}
                      onClick={() => handleDeleteCompetencyUnit(index)}
                    />
                  </div>
                )
              )}
            </div>

            <div className="flex gap-1 mt-3 pt-[12pxx] items-center cursor-pointer">
              <span
                style={{
                  background:
                    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                onClick={handleAddCompetencyUnit}
              >
                + Tambah Unit Kompetensi
              </span>
            </div>
            <div className="flex flex-col gap-5 mt-5">
              <div>
                <div className="grid grid-cols-1 gap-[12px] mb-4">
                  {dataToSend.unitCompetency.competencyGoals
                    .filter((el) => el.type === "KOGNITIF")
                    .map((val, index) =>
                      index !== 0 ? (
                        <OptionalInput
                          placeholder={"Aspek kompetensi Kognitif"}
                          onClick={() => handleDeleteCompetencyGoals(val.id)}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      ) : (
                        <InputField
                          addLabel={true}
                          required={true}
                          labelValue={"Aspek Kompetensi Kognitif"}
                          classNameLabel={
                            "text-[#616161] font-medium text-[14px]"
                          }
                          htmlFor={"tujuan-khusus"}
                          inputId={"tujuan-khusus"}
                          classNameInput={
                            "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                          }
                          placeholder={"Aspek kompetensi Kognitif"}
                          type={"text"}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      )
                    )}
                </div>
                <div className="flex gap-1 items-center cursor-pointer">
                  <span
                    style={{
                      background:
                        "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                    onClick={() => handleAddCompetencyGoals("KOGNITIF")}
                  >
                    + Tambah Aspek Kompetensi Kognitif
                  </span>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-1 gap-[12px] mb-4">
                  {dataToSend.unitCompetency.competencyGoals
                    .filter((el) => el.type === "AFEKTIF")
                    .map((val, index) =>
                      index !== 0 ? (
                        <OptionalInput
                          placeholder={"Aspek kompetensi Afektif"}
                          onClick={() => handleDeleteCompetencyGoals(val.id)}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      ) : (
                        <InputField
                          addLabel={true}
                          required={true}
                          labelValue={"Aspek kompetensi Afektif"}
                          classNameLabel={
                            "text-[#616161] font-medium text-[14px]"
                          }
                          htmlFor={"tujuan-khusus"}
                          inputId={"tujuan-khusus"}
                          classNameInput={
                            "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                          }
                          placeholder={"Aspek kompetensi Afektif"}
                          type={"text"}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      )
                    )}
                </div>
                <div className="flex gap-1 items-center cursor-pointer">
                  <span
                    style={{
                      background:
                        "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                    onClick={() => handleAddCompetencyGoals("AFEKTIF")}
                  >
                    + Tambah Aspek Kompetensi Afektif
                  </span>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-1 gap-[12px] mb-4">
                  {dataToSend.unitCompetency.competencyGoals
                    .filter((el) => el.type === "PSIKOMOTOR")
                    .map((val, index) =>
                      index !== 0 ? (
                        <OptionalInput
                          placeholder={"Aspek kompetensi Psikomotor"}
                          onClick={() => handleDeleteCompetencyGoals(val.id)}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      ) : (
                        <InputField
                          addLabel={true}
                          required={true}
                          labelValue={"Aspek kompetensi Psikomotor"}
                          classNameLabel={
                            "text-[#616161] font-medium text-[14px]"
                          }
                          htmlFor={"tujuan-khusus"}
                          inputId={"tujuan-khusus"}
                          classNameInput={
                            "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                          }
                          placeholder={"Aspek kompetensi Psikomotor"}
                          type={"text"}
                          value={val.value}
                          onChange={(e) =>
                            handleChangeCompetencyGoals(val.id, e.target.value)
                          }
                        />
                      )
                    )}
                </div>
                <div className="flex gap-1 items-center cursor-pointer">
                  <span
                    style={{
                      background:
                        "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                    onClick={() => handleAddCompetencyGoals("PSIKOMOTOR")}
                  >
                    + Tambah Aspek Kompetensi Psikomotor
                  </span>
                </div>
              </div>
            </div>

            <hr className="my-[32px] bg-[#E0E0E0]" />
            <div className="flex flex-col gap-[8px]">
              <p className="font-semibold">
                Persyaratan atau Prasyarat Kelas Video
              </p>
              <p className="text-[16px] font-normal text-[#757575]">
                Cantumkan persyaratan atau prasyarat seperti keterampilan,
                pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta
                sebelum mengikuti kelas video ini.
              </p>

              <div className="flex flex-col gap-[16px] mt-[20px]">
                {/* <InputField
                  addLabel={true}
                  required={true}
                  labelValue={"Persyaratan atau prasyarat"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                  htmlFor={"persyaratan"}
                  inputId={"persyaratan"}
                  classNameInput={
                    "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                  }
                  placeholder={"Persyaratan atau Prasyarat"}
                  type={"text"}
                /> */}
                {dataToSend.regulations.map(
                  (val, index) =>
                    index !== 0 ? (
                      <OptionalInput
                        placeholder={"Persyaratan atau prasyarat"}
                        onClick={() => handleDeleteRegulations(index)}
                        value={val}
                        onChange={(e) =>
                          handleChangeRegulations(index, e.target.value)
                        }
                      />
                    ) : (
                      <InputField
                        addLabel={true}
                        required={true}
                        labelValue={"Persyaratan atau prasyarat"}
                        classNameLabel={
                          "text-[#616161] font-medium text-[14px]"
                        }
                        htmlFor={"persyaratan"}
                        inputId={"persyaratan"}
                        classNameInput={
                          "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                        }
                        placeholder={"Persyaratan atau Prasyarat"}
                        type={"text"}
                        value={val}
                        onChange={(e) =>
                          handleChangeRegulations(index, e.target.value)
                        }
                      />
                    )
                )}
                <div className="flex gap-1 items-center cursor-pointer">
                  <span
                    style={{
                      background:
                        "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                    onClick={handleAddRegulations}
                  >
                    + Tambah Persyaratan atau Prasyarat
                  </span>
                </div>
                {/* {optionalInputs.map((_, index) => (
                  <OptionalInput
                    placeholder={"Persyaratan atau Prasyarat"}
                    onClick={() => handleDeleteFormClick(index)}
                  />
                ))} */}
                {/* <div className="flex gap-1 items-center cursor-pointer">
                  <span
                    style={{
                      background:
                        "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                    onClick={handleAddInputOption}
                    className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
                  >
                    + Tambah Persyaratan atau Prasyarat
                  </span>
                </div> */}
              </div>
              <hr className="my-[32px] bg-[#E0E0E0]" />
            </div>

            <div className="flex flex-col gap-[8px]">
              <p className="font-semibold">Sasaran Peserta</p>

              <div className="flex flex-col gap-[20px]">
                <p className="text-[16px] font-normal text-[#757575]">
                  Tuliskan deskripsi yang jelas dari sasaran peserta untuk kelas
                  video ini.
                </p>
                <MultipleInput
                  label={"Sasaran Peserta"}
                  htmlFor={"Sasaran Peserta"}
                  id={"Sasaran Peserta"}
                  items={dataToSend.audienceTargets}
                  placeholder={"Sasaran Peserta"}
                  handleChange={(value) => handleChangeDetail("audienceTargets", value)}
                />
              </div>
            </div>
            <LineSeperator required={false} headerTagLine={"Aturan Kelas"} />

            <div className="grid grid-cols-1 gap-[20px]">
              <CheckBox
                checked={isChecked}
                onClickCheckbox={() => setIsChecked(true)}
                onClickChecked={() => setIsChecked(false)}
                description={"lorem ipsum dolor sit amet"}
              />
              <CheckBox
                checked={isChecked}
                onClickCheckbox={() => setIsChecked(true)}
                onClickChecked={() => setIsChecked(false)}
                description={"lorem ipsum dolor sit amet"}
              />
              <CheckBox
                checked={isChecked}
                onClickCheckbox={() => setIsChecked(true)}
                onClickChecked={() => setIsChecked(false)}
                description={"lorem ipsum dolor sit amet"}
              />
              <CheckBox
                checked={isChecked}
                onClickCheckbox={() => setIsChecked(true)}
                onClickChecked={() => setIsChecked(false)}
                description={"lorem ipsum dolor sit amet"}
              />
            </div>
            <hr className="my-[32px] bg-[#E0E0E0]" />
          </form>
          {/* end form */}
          <div className="w-full flex items-center justify-between">
            <BackButton onClick={() => push("/new")} />
            <NextButton
              title={"Lanjutkan"}
              onClick={() => {
                dispatch({ type: SET_VIDEO_GENERAL, payload: dataToSend });
                push(`/new/video/${id}/step2`);
              }}
            />
          </div>
        </div>
      </div>

      <ModalPreview
        type={"image"}
        isOpen={isModalOpen}
        setIsOpen={handleCloseModal}
        className={"w-64 h-60"}
        alt={preview}
        name={preview}
      />

      {/* <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">Judul Webinar</h3>
          <p className="text-netral-6">Tetapkan judul webinar Anda</p>
        </div>
        <div className="space-y-2">
          <label
            htmlFor="title"
            className="inline-block text-sm text-netral-8 font-semibold"
          >
            Judul Webinar
          </label>
          <input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="p-3 rounded border border-[#757575] w-full placeholder:text-netral-5"
            placeholder="Masukkan Judul Webinar Anda"
          />
        </div>
        <PrevOrNextButton
          prev={() => push("/new")}
          next={() => {
            dispatch({ type: SET_WEBINAR_TITLE, payload: title });
            push(`/new/webinar/${id}/step2`);
          }}
          active={title.length}
        />
      </div> */}
    </LayoutNewVideo>
  );
};

export default Step1;
