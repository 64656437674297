import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ClipArt, Icon } from "../../../../components/independent/image";
import InputDateLabel from "../../../../components/independent/input/input-date-label";
import InputDropdownLabel2 from "../../../../components/independent/input/input-dropdown-label2";
import LayoutDashboard from "../../../../components/independent/layout/layout-dashboard";
import TablePagination from "../../../../components/independent/Pagination/pagination";
// import Modal from "../../../../components/independent/modal";
// import ModalSkilltest from "../../../../components/independent/modal/modal-skilltest";
import {
  _detailDataStudentByClassId,
  // _detailSkilltest,
} from "../../../../config/services/api/instructor";
import getLink from "../../../../constants/getLink";
import useQuery from "../../../../hooks/lib/useQuery";

const DetailCourse = () => {
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { courseId } = useParams();
  const [activeTab, setActiveTab] = useState("Not Attend");
  const { token } = useSelector((state) => state.authReducer);
  const [datas, setDatas] = useState([]);
  const query = useQuery();
  const [individualIndex, setIndividualIndex] = useState(0);

  const courseTitle = query.get("course") || "";
  const dateFromQuery = query.get("dateFrom") || "";
  const dateToQuery = query.get("dateTo") || "";
  const participantTypeQuery = query.get("participantType") || "";
  const sortQuery = query.get("sort") || "";
  const nameQuery = query.get("name") || "";
  const pageNumberQuery = query.get("page_number") || 1;
  const sessionArray = Array.from(
    { length: datas[1]?.result?.totalChapters },
    (_, index) => index + 1
  );

  const [participantType, setParticipantType] = useState(participantTypeQuery);

  let queryParam = {
    courseTitle,
    dateFromQuery,
    dateToQuery,
    participantTypeQuery,
    sortQuery,
    pageNumberQuery,
    nameQuery,
  };

  const tabs = [
    {
      name: "Not Attend",
      value: datas[0]?.totalData || 0,
    },
    {
      name: "Praktik Mandiri",
      value: datas[1]?.totalData || 0,
    },
    {
      name: "Uji Keterampilan",
      value: datas[2]?.totalData || 0,
    },
    {
      name: "Finished",
      value: datas[3]?.totalData || 0,
    },
    {
      name: "Unfinished",
      value: datas[4]?.totalData || 0,
    },
    { name: "Ulasan", value: datas[5]?.totalData || 0 },
  ];

  useEffect(() => {
    const getData = async () => {
      const allStatus = [
        "not_attend",
        "individual",
        "skilltest",
        "finished",
        "unfinished",
        "review",
      ];

      const startIndex = search.indexOf("&dateFrom");
      const searchParam = search.substring(startIndex);

      const fetchAllByStatus = allStatus.map((status) =>
        _detailDataStudentByClassId(
          token,
          courseId,
          status,
          `&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&=participantType=${participantTypeQuery}&sort=${sortQuery}&page_number=${pageNumberQuery}&page_size=10&name=${nameQuery}`
        )
      );

      const res = await Promise.all(fetchAllByStatus);

      if (res.some((el) => !el.status)) return;

      setDatas(res.map((el) => el));
    };
    getData();
  }, [
    token,
    courseId,
    search,
    dateFromQuery,
    dateToQuery,
    participantTypeQuery,
    sortQuery,
    pageNumberQuery,
    nameQuery,
  ]);

  const onQueryParamChange = (e) => {
    queryParam = { ...queryParam, [e.target.name]: e.target.value };
    const {
      courseTitle,
      dateFromQuery,
      dateToQuery,
      participantTypeQuery,
      pageNumberQuery,
      sortQuery,
      nameQuery,
    } = queryParam;
    push(
      `/instructor/dashboard/${courseId}?course=${courseTitle}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&=participantType=${participantTypeQuery}&sort=${sortQuery}&page_number=${pageNumberQuery}&page_size=10&name=${nameQuery}`
    );
  };


  return (
    <>
      {/* <Modal modal={modal}>
        {modal === "skilltest" && (
          <ModalSkilltest
            setModal={setModal}
            data={detailSkilltest}
            setData={setDetailSkilltest}
          />
        )}
      </Modal> */}
      <LayoutDashboard>
        <div className="flex items-center space-x-2">
          <Link to="/instructor/dashboard" className="text-netral-6">
            Dashboard
          </Link>
          <p className="text-netral-6">/</p>
          <p className="text-netral-10">{courseTitle}</p>
        </div>
        <div className="mt-4 flex items-center space-x-6">
          <h3 className="text-2xl text-netral-10">{courseTitle}</h3>
          <ClipArt
            name="label prakerja"
            className="w-[73px] h-[30px] object-contain"
          />
        </div>
        <div className="mt-8 overflow-scroll flex gap-x-5 lg:hidden">
          {tabs.map((tab, i) => (
            <button
              key={i}
              type="button"
              onClick={() => {
                const e = { target: { name: "pageNumberQuery", value: 1 } };
                setActiveTab(tab.name);
                onQueryParamChange(e);
                onQueryParamChange({ target: { name: "name", value: "" } });
              }}
              className={`${
                tab.name === activeTab
                  ? "border-b-netral-10"
                  : "border-b-transparent"
              } flex items-center space-x-2 pb-3 border-b-2 w-full gap-x-2 whitespace-nowrap`}
            >
              <p
                className={`${
                  tab.name === activeTab
                    ? "text-netral-10 font-semibold"
                    : "text-netral-6"
                } text-lg break-normal`}
              >
                {tab.name}
              </p>
              <span
                className={`text-xs text-netral-1 px-2 py-1 rounded bg-primary-600`}
              >
                {tab.value}
              </span>
            </button>
          ))}
        </div>
        <div className="mt-8 p-6 rounded-xl bg-[#F8F9FA]">
          <div className="flex items-center space-x-10 hidden lg:flex">
            {tabs.map((tab, i) => (
              <button
                key={i}
                type="button"
                onClick={() => {
                  const e = { target: { name: "pageNumberQuery", value: 1 } };
                  setActiveTab(tab.name);
                  onQueryParamChange(e);
                  onQueryParamChange({ target: { name: "name", value: "" } });
                }}
                className={`${
                  tab.name === activeTab
                    ? "border-b-netral-10"
                    : "border-b-transparent"
                } flex items-center space-x-2 pb-3 border-b-2`}
              >
                <p
                  className={`${
                    tab.name === activeTab
                      ? "text-netral-10 font-semibold"
                      : "text-netral-6"
                  } text-lg`}
                >
                  {tab.name}
                </p>
                <span
                  className={`text-xs text-netral-1 px-2 py-1 rounded bg-primary-600`}
                >
                  {tab.value}
                </span>
              </button>
            ))}
          </div>
          <div className="lg:mt-8 rounded-lg border border-netral-5 p-3 flex items-end justify-between">
            <div className="grid grid-cols-2 gap-2 lg:flex items-center lg:gap-x-6 w-full ">
              <InputDateLabel
                text="Tanggal Awal"
                name="dateFromQuery"
                value={dateFromQuery}
                onChange={onQueryParamChange}
              />
              <InputDateLabel
                text="Tanggal Akhir"
                name="dateToQuery"
                value={dateToQuery}
                onChange={onQueryParamChange}
              />
              <InputDropdownLabel2
                text="Jenis Peserta"
                name="participantTypeQuery"
                setValue={(value) => {
                  const e = { target: { name: "participantTypeQuery", value } };
                  setParticipantType(value);
                  onQueryParamChange(e);
                }}
                values={[
                  "Jenis Peserta",
                  "Prakerja",
                  "Umum",
                  "Umum dan prakerja",
                ]}
                value={participantType}
              />
            </div>
          </div>
          {/* {activeTab === "Praktik Mandiri" && sessionArray.length > 0 && (
            <div className="my-[20px] flex flex-wrap space-x-[16px] ">
              {sessionArray.map((el, idx) => (
                <div
                  className={`border ${
                    idx === individualIndex
                      ? "  border-primary-600 text-netral-10"
                      : "text-netral-6  border-[#F8F9FA]"
                  } py-2 px-5 rounded-md font-semibold text-base bg-netral-1 cursor-pointer`}
                  key={idx}
                  onClick={() => setIndividualIndex(idx)}
                >
                  <p className="">Sesi {el}</p>
                </div>
              ))}
            </div>
          )} */}

          <div className="mt-6 rounded-xl lg:bg-white py-2 lg:p-6 ">
            <div className="flex items-center justify-between">
              <div className="relative w-2/3">
                <div className="absolute top-0 left-2 h-full flex items-center">
                  <Icon name="search-netral-6" />
                </div>
                <input
                  type="search"
                  placeholder="Cari nama"
                  className="pl-[42px] px-3 py-[9px] rounded-xl border border-netral-5 w-full lg:w-[342px]"
                  value={nameQuery}
                  name="nameQuery"
                  onChange={(value) => {
                    const e = {
                      target: { name: "nameQuery", value: value.target.value },
                    };
                    onQueryParamChange(e);
                    onQueryParamChange({
                      target: { name: "pageNumberQuery", value: 1 },
                    });
                  }}
                />
              </div>
              <div className="flex items-center space-x-6">
                <div className="relative">
                  <div className="absolute top-0 left-10 lg:left-2  h-full flex items-center">
                    <Icon name="filter_list-netral-7" className="w-6 h-6" />
                  </div>
                  <select
                    name="sortQuery"
                    value={sortQuery || "default"}
                    onChange={onQueryParamChange}
                    className="lg:w-[180px] w-[1px] opacity-5 lg:opacity-100 rounded-xl border border-netral-5 py-[9px] pl-[36px] text-netral-5"
                  >
                    <option value="default" disabled>
                      Filter
                    </option>
                    <option value="">Semua</option>
                    <option value="scored">Dinilai</option>
                    <option value="no_scored">Belum dinilai</option>
                  </select>
                </div>
              </div>
            </div>
            {activeTab === "Not Attend" && (
              <TableNotAttend data={datas[0]?.result} />
            )}
            {activeTab === "Praktik Mandiri" && (
              <div className="overflow-scroll">
                <TableIndividualTest
                  data={datas[1]?.result}
                  navigateToDetail={(studentId) =>
                    push(`${pathname}/${studentId}/individual-test`)
                  }
                />
              </div>
            )}
            {activeTab === "Uji Keterampilan" && (
              <TableSkillTest
                data={datas[2]?.result}
                navigateToDetail={(studentId) =>
                  push(`${pathname}/${studentId}`)
                }
              />
            )}
            {activeTab === "Finished" && (
              <TableFinished data={datas[3]?.result} />
            )}
            {activeTab === "Unfinished" && (
              <TableUnfinished data={datas[4]?.result} />
            )}
            {activeTab === "Ulasan" && <TableReview data={datas[5]?.result} />}
          </div>
        </div>
        <div className="mb-[60px] mt-[20px]">
          <TablePagination
            changePage={(page) => {
              const e = {
                target: { name: "pageNumberQuery", value: page },
              };
              onQueryParamChange(e);
            }}
            page={Number(pageNumberQuery)}
            totalPage={
              datas[tabs.findIndex((el) => el.name === activeTab)]?.totalPage
            }
          />
        </div>
      </LayoutDashboard>
    </>
  );
};

export default DetailCourse;

const ResponsiveDataList = ({
  data = [],
  skillTest = false,
  navigateToDetail,
}) => {
  return (
    <div className="space-y-2 mt-3 block lg:hidden">
      {data.map((el, idx) => (
        <div
          key={idx}
          className="bg-netral-1 p-2 space-y-[6px]"
          onClick={() => skillTest && navigateToDetail(el.progress_id)}
        >
          <div className="flex justify-between items-center gap-x-2">
            <div className="space-y-[8px]">
              <p className="font-semibold">{el.name}</p>
              <p className="text-xs text-netral-6 ">
                {el.email || el.phoneNumber}
              </p>
            </div>
            {skillTest ? (
              <div className="">
                <p
                  className={`${
                    !el.status_evaluation ? "invisible" : ""
                  } text-right text-primary-600 text-lg font-semibold`}
                >
                  {el.score}
                </p>
                {el.status_evaluation ? (
                  <div className="bg-primary-100 py-[2px] px-[4px] rounded-[4px]">
                    <p className="text-right text-2xs text-primary-900">
                      Sudah Dinilai
                    </p>
                  </div>
                ) : (
                  <div className="bg-secondary3-100 py-[2px] px-[4px] rounded-[4px]">
                    <p className="text-right text-2xs text-secondary3-900">
                      Belum Dinilai
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <p className="text-lg font-semibold">{el.percentage}%</p>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center gap-x-2">
            <p className="text-xs w-1/2">{el.voucher_code}</p>
            {skillTest && <p className="text-xs">1 File</p>}
            <p className="text-xs">{moment().format("LL")}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const TableNotAttend = ({ data = [] }) => {
  return (
    <div className="w-full">
      <table className="mt-[36px] w-full hidden lg:table">
        <thead>
          <tr className="text-left">
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              No.
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nama Peserta
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Email
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Kode Voucher
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Tanggal Pembelian
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Progress
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {i + 1}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.name}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.email || el.phoneNumber}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.payment_method}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.purchase_date}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.percentage}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResponsiveDataList data={data} />
    </div>
  );
};

const TableUnfinished = ({ data = [] }) => {
  return (
    <div className="w-full">
      <table className="mt-[36px] w-full hidden lg:table">
        <thead>
          <tr className="text-left">
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              No.
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nama Peserta
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Email
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Kode Voucher
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Terakhir Masuk Kelas
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Progress
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {i + 1}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.name}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.email || el.phoneNumber}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.voucher_code}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.last_update}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.percentage}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResponsiveDataList data={data} />
    </div>
  );
};

const TableFinished = ({ data = [] }) => {
  return (
    <div className="w-full">
      <table className="mt-[36px] w-full hidden lg:table">
        <thead>
          <tr className="text-left">
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              No.
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nama Peserta
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Email
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Kode Voucher
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Terakhir Masuk Kelas
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Progress
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {i + 1}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.name}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.email || el.phoneNumber}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.voucher_code}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.last_update}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.percentage}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResponsiveDataList data={data} />
    </div>
  );
};

const TableSkillTest = ({ data = [], navigateToDetail }) => {
  return (
    <div className="w-full">
      <table className="mt-[36px] w-full hidden lg:table">
        <thead>
          <tr className="text-left">
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              No.
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nama Peserta
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Email
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Kode Voucher
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Upload File
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nilai
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Update Terbaru
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Status Penilaian
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {i + 1}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.name}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.email || el.phoneNumber}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.voucher_code}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.upload_file}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.score}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {new Date(el.last_update).toLocaleDateString()}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                <span
                  className={`py-[2px] px-1 rounded font-semibold ${
                    !el.status_evaluation
                      ? "bg-secondary3-100 text-secondary3-700"
                      : "bg-primary-100 text-primary-900"
                  }`}
                >
                  {el.status_evaluation ? "Sudah dinilai" : "Belum dinilai"}
                </span>
              </td>
              <td className="text-sm text-secondary-button-600 py-3 border-b border-b-netral-4 font-semibold">
                <button
                  type="button"
                  onClick={() => navigateToDetail(el.progress_id)}
                >
                  Lihat Detail
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResponsiveDataList
        data={data}
        skillTest={true}
        navigateToDetail={navigateToDetail}
      />
    </div>
  );
};

const TableIndividualTest = ({ data = [], navigateToDetail }) => {
  return (
    <table className="mt-[36px] w-[150%]">
      <thead>
        <tr className="text-left">
          <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
            Nama Peserta
          </th>
          {Array.from(Array(data.totalChapters).keys()).map((el, idx) => (
            <th
              className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8"
              key={idx}
            >
              Sesi {idx + 1}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.data.map((el, i) => (
          <tr key={i} onClick={() => navigateToDetail(el.user_id)} className="cursor-pointer">
            <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
              {el.name}
            </td>
            {el.individual.map((each, i) =>
              each.name ? (
                <td
                  className="text-sm text-netral-9 py-3 border-b border-b-netral-4 w-[250px]"
                  key={i}
                >
                  <div className="flex gap-1  items-center">
                    <div
                      className={`w-2 h-2 rounded-full ${
                        each?.feedback ? "bg-[#60AA15]" : "bg-[#D1421A]"
                      }`}
                    />
                    <div>
                      <p>
                        {each?.feedback ? "Sudah dinilai" : "Belum dinilai"}
                      </p>
                    </div>
                  </div>
                  {/* <div
                    className="flex items-center space-x-[6px] mb-[4px] cursor-pointer"
                    onClick={() => window.open(getLink(each.link))}
                  >
                    <Icon name="Docs-icon" className="w-[14px] h-[14px] m-0" />
                    <p className="w-[200px] truncate underline text-secondary-button-600 text-sm">
                      {each?.name.split("_").pop()}
                    </p>
                  </div>
                  <p className="text-netral-6">
                    {moment(each?.uploadAt).format("LL")}
                  </p> */}
                </td>
              ) : (
                <td
                  className="text-sm text-netral-9 py-3 border-b border-b-netral-4"
                  key={i}
                >
                  <div className="flex gap-1 items-center">
                    <div
                      className={`w-2 h-2 rounded-full bg-netral-5
                      }`}
                    />
                    <div>
                      <p>Belum Tersedia</p>
                    </div>
                  </div>
                </td>
              )
            )}
            {/* <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
          
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TableReview = ({ data = [] }) => {
  return (
    <div className="w-full">
      <table className="mt-[36px] w-full hidden lg:block">
        <thead>
          <tr className="text-left">
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              No.
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Nama Peserta
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Email
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Rating
            </th>
            <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
              Ulasan
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr key={i}>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {i + 1}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.name}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.email || el.phoneNumber}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.rating}
              </td>
              <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                {el.ulasan}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResponsiveDataList data={data} />
    </div>
  );
};
