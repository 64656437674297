import { useCurriculum } from "../../../../pages/new/course/[course-id]/step6/Curriculum";
import { Icon } from "../../../independent/image";
import EditOrRemoveButton from "../../new-course/edit-or-remove-button";


export default function SavedChapter({ chapterIndex,isSession }) {
  const { chapters, setChapters } = useCurriculum();

  const previewInfo = (materialIndex) => {
    const quiz = chapters[chapterIndex].materials[materialIndex].quiz;
    const lesson = chapters[chapterIndex].materials[materialIndex].lesson;
    if (lesson) {
      const fileType = lesson.file.type.split("/")[1];
      const info = { title: lesson.title, icon: "", text: "" };
      if (fileType === "pdf") {
        info.icon = "insert_drive_file-netral-1";
        info.text = "Document";
      }
      if (fileType === "mp4") {
        info.icon = "slow_motion_video-netral-1";
        info.text = "01:00";
      }
      return info;
    }
    if (quiz) {
      const questionLength =
        chapters[chapterIndex].materials[materialIndex].quiz.questions.length;
      return {
        title: quiz.title,
        icon: "format_list_numbered-netral-1",
        text: `${questionLength} Pertanyaan`,
      };
    }
  };

  const editChapter = () => {
    chapters[chapterIndex].isSaved = false;
    setChapters([...chapters]);
  };

  const removeChapter = () => {
    chapters.splice(chapterIndex, 1);
    setChapters([...chapters]);
  };

  return (
    <div className="pt-6 pl-[25px] pr-[60px] pb-[49px] bg-netral-2/60 flex space-x-6">
      <h6 className="text-lg font-semibold whitespace-nowrap">
      {isSession ? "Sesi" : "Bagian"} {chapterIndex + 1}:
      </h6>
      <div className="w-full relative">
        <div className="absolute right-0 top-0">
          <EditOrRemoveButton edit={editChapter} remove={removeChapter} />
        </div>
        <div className="space-y-3">
          <h4 className="text-netral-10 text-xl font-semibold">
            {chapters[chapterIndex].title}
          </h4>
          <p className="text-netral-10">{chapters[chapterIndex].description}</p>
        </div>
        <div className="mt-6 space-y-6">
          {chapters[chapterIndex].materials.map((material, materialIndex) => (
            <div
              key={materialIndex}
              className="rounded p-6 bg-primary-100/40 border border-netral-6 flex items-center justify-between"
            >
              <div className="flex space-x-4">
                <div className="w-[68px] h-[68px] grid place-items-center rounded bg-primary-700">
                  <Icon
                    name={previewInfo(materialIndex).icon}
                    className="w-[36px] h-[36px]"
                  />
                </div>
                <div className="space-y-4">
                  <h5 className="text-xl font-semibold text-netral-9">
                    {previewInfo(materialIndex).title}
                  </h5>
                  <p className="text-netral-7">
                    {previewInfo(materialIndex).text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
