import React from "react";

export default function OurInstructor() {
  return (
    <section className="my-[112px] flex items-center space-x-12 px-[135px]">
      <div className="w-1/2">
        <img
          src="/assets/image/Group 2362.png"
          alt="Group 2362.png"
          className="w-full h-auto object-contain"
        />
      </div>
      <div className="w-1/2 space-y-6">
        <h3 className="text-2xl font-semibold text-primary-900">
          Instruktur kami
        </h3>
        <p className="text-netral-9">
          Instruktur dari seluruh dunia mengajar jutaan peserta di Skills.id.
          Kami menyediakan alat dan keterampilan untuk mengajarkan hal yang Anda
          sukai.
        </p>
      </div>
    </section>
  );
}
