import React from "react";
import InputField from "../../InputField";
import UploadFile from "../../UploadFile";
import TextAreaField from "../../textAreaFiled";
import Labelform from "../../../atom/Label";
import RadioButton from "../../../independent/radio-button";
import Material from "../Material";

export default function SessionsLayout({ sessionIndex, data, setSessions }) {
  const changeValue = (key, value) => {
    data[sessionIndex][key] = value;
    setSessions([...data]);
  };

  const changeMaterialValue = (mIndex, key, value) => {
    data[sessionIndex]["subjects"][mIndex][key] = value;
    setSessions([...data]);
  };
  
  const changeMaterialPopup = (mIndex, key, value) => {
    data[sessionIndex]["subjects"][mIndex]['popup'][key] = value;
    setSessions([...data]);
  };

  const changeMaterialSelfPractice = (key, value) => {
    data[sessionIndex]['selfPractice'][key] = value;
    setSessions([...data]);
  };

  return (
    <div>
      <div>
        <p className="font-medium text-lg mb-4">
          Detail Sesi {sessionIndex + 1}
        </p>
        <div className="flex gap-4 flex-col">
          <InputField
            addLabel={true}
            required={true}
            labelValue={"Judul sesi"}
            classNameLabel={"text-[#616161] font-medium text-[14px]"}
            htmlFor={"judul-sesi"}
            inputId={"judul-sesi"}
            classNameInput={
              "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
            }
            placeholder={"Judul sesi"}
            type={"text"}
            value={data[sessionIndex].title}
            onChange={(e) => changeValue("title", e.target.value)}
          />
          <InputField
            addLabel={true}
            required={true}
            labelValue={"Deskripsi"}
            classNameLabel={"text-[#616161] font-medium text-[14px]"}
            htmlFor={"description"}
            inputId={"description"}
            classNameInput={
              "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
            }
            placeholder={"Deskripsi"}
            type={"text"}
            value={data[sessionIndex].description}
            onChange={(e) => changeValue("description", e.target.value)}
          />
        </div>
      </div>
      <hr className="my-[32px]" />
      {/* materi */}
      {data[sessionIndex].subjects.map((el, idx) => (
        <div key={idx}>
          {el.quiz ? (
            <div>
              <p className="font-semibold text-base mb-5">Kuis {idx + 1}</p>
              <div className="flex flex-col gap-4">
                <InputField
                  addLabel={true}
                  required={true}
                  labelValue={"Judul Materi"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                  htmlFor={"judul-materi"}
                  inputId={"judul-materi"}
                  classNameInput={
                    "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
                  }
                  placeholder={"Judul Materi"}
                  type={"text"}
                />
                <UploadFile
                  labelValue={"File Materi"}
                  required={true}
                  inputId={"video-materials"}
                  placeholderInput={"Pilih file materi"}
                />
                <TextAreaField
                  isRequired={true}
                  labelValue={"Pop Up Kuis"}
                  classNameTextArea={
                    "resize-none rounded-lg border-[#E0E0E0] placeholder:text-[#C2C2C2] focus:ring-[#9360A8]"
                  }
                  placeholder={"Pertanyaan"}
                  htmlFor={"Pertanyaan"}
                  idTextArea={"Pertanyaan"}
                  classNameLabel={"text-[#616161] font-medium text-[14px]"}
                />
                <div className="flex flex-col gap-2">
                  <Labelform
                    value="Jawaban Pertanyaan"
                    className={"text-[#616161] font-medium text-[14px] "}
                    isRequired={true}
                  />
                  <div className="flex gap-8">
                    <div className="flex gap-2">
                      <div className="w-5 h-5">
                        <RadioButton
                          // checked={checked === 1}
                          value="label"
                          // handleChange={() => setChecked(1)}
                        />
                      </div>
                      <p className="font-normal text-base">Benar</p>
                    </div>
                    <div className="flex gap-2">
                      <div className="w-5 h-5">
                        <RadioButton
                          // checked={checked === 2}
                          value="label2"
                          // handleChange={() => setChecked(2)}
                        />
                      </div>
                      <p className="font-normal text-base">Salah</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Material
              materialIndex={idx}
              data={el}
              handleChange={(key, value) =>
                changeMaterialValue(idx, key, value)
              }
              handleChangePopup={(key, value) =>
                changeMaterialPopup(idx, key, value)
              }
            />
          )}

          <hr className="my-[32px]" />
        </div>
      ))}
      {/* TPM(Tugas Praktek Mandiri)* */}
      <div>
        <p className="font-semibold text-base mb-5">
          TPM(Tugas Praktek Mandiri)*
        </p>
        <div className="flex flex-col gap-4">
          <UploadFile
            labelValue={"Panduan TPM"}
            required={true}
            inputId={"file-tpm"}
            placeholderInput={"Pilih file pandan TPM"}
            selectedFile={data[sessionIndex].selfPractice.file}
            handleChange={(val) => changeMaterialSelfPractice('file', val)}
          />
          <TextAreaField
            isRequired={true}
            labelValue={"Deskripsi"}
            classNameTextArea={
              "resize-none rounded-lg border-[#E0E0E0] placeholder:text-[#C2C2C2] focus:ring-[#9360A8]"
            }
            placeholder={"Deskripsi panduan"}
            htmlFor={"Deskripsi"}
            idTextArea={"Deskripsi"}
            classNameLabel={"text-[#616161] font-medium text-[14px]"}
            onChange={(e) => changeMaterialSelfPractice('description', e.target.value)}
            value={data[sessionIndex].selfPractice.description}
          />
        </div>
      </div>
    </div>
  );
}
