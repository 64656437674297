import { Icon } from "../image";

export default function TablePagination({ totalPage, changePage, page }) {
  const disabledPrev = +page <= 1;
  const disabledNext = +page >= totalPage;

  return (
    <div className="flex before:flex-1">
      <div className="flex flex-1 justify-center">
        <p className="text-sm text-netral-8">
          Page {page} / {totalPage}
        </p>
      </div>
      <div className="flex flex-1 items-center justify-end space-x-4">
        <button
          disabled={disabledPrev}
          onClick={() => changePage(page - 1)}
          type="button"
        >
          <Icon
            name="arrow-right"
            className={`${
              disabledPrev ? "text-netral-5" : "text-netral-10"
            } h-[14px] w-[14px] `}
          />
        </button>
        {paginationNum(page, totalPage, 3).map((el, i) => (
          <button
            key={i}
            className={
              page !== el
                ? "text-netral-5"
                : "cursor-not-allowed text-netral-10"
            }
            disabled={page === el}
            onClick={() => changePage(el)}
          >
            {el}
          </button>
        ))}
        <button
          disabled={disabledNext}
          onClick={() => changePage(page + 1)}
          type="button"
        >
          <Icon
            name="arrow-right"
            className={`${
              disabledPrev ? "text-netral-5" : "text-netral-10 "
            } h-[14px] w-[14px] rotate-180`}
          />
        </button>
      </div>
    </div>
  );
}

function paginationNum(page, totalPage, interval) {
  const result = [];

  if (page === totalPage && page > interval)
    for (let i = page - (interval - 1); i <= totalPage; i++) result.push(i);

  if (page >= interval && page !== totalPage)
    for (
      let i = page - Math.floor(interval / 2);
      i <= page + Math.floor(interval / 2);
      i++
    )
      result.push(i);

  if (page < interval)
    for (let i = 1; i <= totalPage; i++) {
      result.push(i);

      if (result.length >= interval) break;
    }

  return result;
}

TablePagination.defaultProps = {
  page: 1,
  totalPage: 1,
  changePage: (page) => alert("unhandle func\npage = " + page),
};
