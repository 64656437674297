import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";
import { Icon, Illustration } from "../../image";


export default function ModalOtp({ setModal, phoneNumber, verifyOtp }) {
  const mainRef = useRef();
  useOutsideClick(mainRef, () => {
    const confirm = window.confirm("exit?");
    if (confirm) setModal((crr) => crr && "");
  });

  const { otpLoading, otpMessage, otpStatus, registerLoading } = useSelector((state) => state.authReducer);

  const [code, setCode] = useState({ input1: "", input2: "", input3: "", input4: "", input5: "", input6: "" });

  const otpNumber = code.input1 + code.input2 + code.input3 + code.input4 + code.input5 + code.input6;

  const refs = { input1: useRef(), input2: useRef(), input3: useRef(), input4: useRef(), input5: useRef(), input6: useRef() };

  const handleChange = (e) => {
    if (e.target.value.match(/^[0-9]+$/) || e.target.value === "")
      setCode((crr) => ({ ...crr, [e.target.name]: e.target.value }));
  };

  const changeFocus = (e, num) => {
    //  prev focus
    if (num > 0 && e.key === "Backspace")
      refs[`input${num - 1}`].current.focus();
    //  next focus
    if (num < 6 && e.key.match(/^[0-9]+$/))
      refs[`input${num + 1}`].current.focus();
  };

  useEffect(() => {
    refs.input1.current.focus();
    return () =>
      setCode({ input1: "", input2: "", input3: "", input4: "", input5: "", input6: "" });
  }, [refs.input1]);

  const verify = (e) => {
    e.preventDefault();
    if (otpLoading) return;
    verifyOtp(otpNumber);
  };

  return (
    <div ref={mainRef} className="relative px-4 lg:-0 w-full flex justify-center">
      <form
        onSubmit={(e) => verify(e)}
        style={{
          boxShadow:
            "0px 8px 16px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
        }}
        className="px-4 lg:px-6 py-6 lg:py-8 rounded-lg bg-white space-y-4 lg:space-y-6 w-full lg:w-[438px]"
      >
        <Illustration
          name="OTP security"
          className="w-[100px] lg:w-[129px] h-[100px] lg:h-[129px] mx-auto"
        />
        <h4 className="text-lg lg:text-xl text-center font-semibold text-netral-10">
          Please Enter OTP
        </h4>
        <div className="space-y-2 text-center">
          <p className="text-netral-9 text-xs lg:text-sm">
            Please confirm your account by entering the OTP Code sent to
          </p>
          <p className="text-netral-9 text-xs lg:text-sm">
            **** - **** - {phoneNumber.substring(phoneNumber.length - 4)}
          </p>
        </div>
        <div className="flex items-center justify-center space-x-6">
          {[1, 2, 3, 4, 5, 6].map((num) => {
            const name = `input${num}`;
            return (
              <input
                key={num}
                ref={refs[name]}
                maxLength={1}
                name={name}
                value={code[name]}
                onChange={(e) => handleChange(e)}
                onKeyUp={(e) => changeFocus(e, num)}
                className="w-8 lg:w-[39px] h-8 lg:h-[45px] text-center text-lg lg:text-xl font-semibold text-netral-10 bg-netral-4 rounded"
                type="text"
              />
            );
          })}
        </div>
        <div className="flex items-center justify-between">
          <div className="space-y-2 lg:space-y-1">
            <p
              className={`${
                otpStatus ? "text-netral-9" : "text-red-500"
              } text-xs lg:text-sm`}
            >
              {otpMessage || "It may take a minute to receive your code"}
            </p>
            <div className="flex items-center space-x-[6px]">
              <p className="text-netral-9 text-xs lg:text-sm">Haven’t received?</p>
              {!registerLoading && (
                <button
                  type="button"
                  className="text-xs lg:text-sm font-semibold text-secondary-button-600 cursor-not-allowed"
                >
                  Resend Code
                </button>
              )}
            </div>
          </div>
          <button
            type="submit"
            disabled={otpLoading || otpNumber.length < 6}
            className={`${
              otpLoading || otpNumber.length < 6
                ? "bg-netral-5 text-netral-6 border-[#9E9E9E] cursor-not-allowed"
                : "bg-secondary-button-600 text-netral-1 border-transparent"
            } px-3 py-2 rounded font-semibold border`}
          >
            Verify
          </button>
        </div>
      </form>
      <button onClick={() => setModal("")} className="right-8 lg:right-4 top-4 absolute">
        <Icon name="close-netral-10" />
      </button>
    </div>
  );
}
