import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Filter from "../../components/dependent/course/filter";
import MobileFilter from "../../components/dependent/course/mobile-filter";
import Title from "../../components/dependent/course/title";
import CardCourse from "../../components/independent/card/card-course";
// import CardCourseFeatured from "../../components/independent/card/card-course-featured";
import CardCourseLg from "../../components/independent/card/card-course-lg";
// import { Icon } from "../../components/independent/image";
import LayoutHome from "../../components/independent/layout/layout-home";
import { _searchCourse } from "../../config/services/api/course";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const { search } = useLocation();

  useEffect(() => {
    _searchCourse(search).then((res) => {
      if (!res.status) return;
      setCourses(res.class);
    });
  }, [search]);

  return (
    <LayoutHome>
      <div className="pt-[82px] pb-[112px] lg:px-8 px-2">
        <Title />
        <div className="block lg:hidden">
          <MobileFilter />
        </div>
        <div className="mt-8 flex space-x-6">
          <div className="w-2/3 space-y-8 hidden lg:block">
            {/* <CardCourseFeatured /> */}
            {courses.map((course) => (
              <CardCourseLg
                key={course._id}
                data={course}
                isWebinar={course?.participant_type?.includes("WEBINAR")}
              />
            ))}
            {/* <div className="flex justify-center">
              <button>
                <Icon name="replay-netral-6" className="w-12 h-12" />
              </button>
            </div> */}
          </div>
          <div className="w-full justify-evenly lg:hidden flex flex-wrap m-5">
            {/* <CardCourseFeatured /> */}
            {courses?.map((course) => (
              <div className="w-1/2.5 my-2">
                <CardCourse
                  key={course._id}
                  course={course}
                  isWebinar={course?.participant_type?.includes("WEBINAR")}
                  fullWidth
                />
              </div>
            ))}
            {/* <div className="flex justify-center">
              <button>
                <Icon name="replay-netral-6" className="w-12 h-12" />
              </button>
            </div> */}
          </div>
          <Filter className="hidden lg:block " />
        </div>
      </div>
    </LayoutHome>
  );
};

export default Course;
