import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  _getRatingById,
  _getRatingByUserClass,
} from "../../../../config/services/api/rating";
import getLink from "../../../../constants/getLink";
import Stars from "../../../independent/stars/stars";
import { Icon } from "../../image";
import Modal from "../../modal";
import ModalRating from "../../modal/modal-rating";

export default function Review() {
  const [allRating, setAllRating] = useState({});
  const [userRating, setUserRating] = useState(null);
  const { token } = useSelector((state) => state.authReducer);
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState("");
  const [trigger, setTrigger] = useState(false);
  const { webinarId, courseId } = useParams();

  useEffect(() => {
    _getRatingById(
      token,
      webinarId || courseId,
      `page_number=${page}&page_size=${5}`
    )
      .then((res) => setAllRating(res))
      .catch((err) => console.log(err));
  }, [trigger]);

  useEffect(() => {
    _getRatingByUserClass(token, webinarId || courseId)
      .then((res) => setUserRating(res.result))
      .catch((err) => console.log(err));
  }, [trigger]);

  return (
    <>
      <Modal modal={modal}>
        {modal === "rating" && (
          <ModalRating
            setModal={setModal}
            isEdit={true}
            data={userRating}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
      </Modal>
      {userRating?.review && (
        <div className="bg-[#f4f4f4] px-[12px] py-[16px] flex justify-between items-center mb-4 space-x-2">
          <div className="flex space-x-3 border-b-2 border-[#EDEDED]">
            <img
              src={getLink(userRating?.profile_picture)}
              alt={"profile"}
              className="h-[50px] w-[50px] rounded-full"
            />
            <div className="w-full">
              <h6 className=" font-semibold text-netral-9">
                {userRating?.name}
              </h6>
              <div className="mt-1 flex items-center space-x-3">
                <Stars n={userRating?.rating} />
                <p className="text-sm text-netral-6">
                  {moment(userRating.updatedAt).fromNow()}
                </p>
              </div>
              <p className="mt-3 text-netral-9">{userRating.review}</p>
            </div>
          </div>
          <button type="button" onClick={() => setModal("rating")}>
            <Icon name="edit-netral-9" className="lg:w-10 lg:h-10 w-6 h-6" />
          </button>
        </div>
      )}
      <div>
        <h4 className="mb-4 pb-2 lg:text-lg font-semibold text-netral-10 border-b-2 border-[#EDEDED]">
          Semua Rating
        </h4>
        <div className="mt-3 space-y-6">
          {allRating.result &&
            allRating?.result.map((el, idx) => (
              <div
                className="flex space-x-3 pb-6 border-b-2 border-[#EDEDED]"
                key={idx}
              >
                <div>
                  <img
                    src={getLink(el.profile_picture)}
                    alt={"profile"}
                    className="h-[50px] w-[50px] rounded-full"
                  />
                </div>
                <div className="w-full">
                  <h6 className=" font-semibold text-netral-9">{el.name}</h6>
                  <div className="mt-1 flex items-center space-x-3">
                    <Stars n={el.rating} />
                    <p className="text-sm text-netral-6">
                      {moment(el.updatedAt).fromNow()}
                    </p>
                  </div>
                  <p className="mt-3 text-netral-9">{el.review}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
