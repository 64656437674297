import { useEffect, useState } from "react";
import { BigPlayButton, Player } from "video-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  SET_DELETE_NEW_COURSE_FILES,
  SET_DELETE_NEW_WEBINAR_FILES,
  SET_FINISHED_NAV,
  SET_SKILL_TEST,
  SET_WEBINAR_SKILL_TEST,
} from "../../../../../config/redux/types";
import {
  _getStreamLocalFile,
  _uploadFileNewCourse,
} from "../../../../../config/services/api/course";
import getVideoDuration from "../../../../../constants/getVideoDuration";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import { Icon } from "../../../../../components/independent/image";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import getLink from "../../../../../constants/getLink";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";

const SkillTestWebinar = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.authReducer);

  const newCourse = useSelector((state) => state.newWebinarReducer);
  const file = newCourse.skillTest;

  const setFile = (value) =>
    dispatch({ type: SET_WEBINAR_SKILL_TEST, payload: value });

  const [videoSrc, setVideoSrc] = useState("");

  const [loading, setLoading] = useState({ file: false });

  const changeLoading = (key, value) =>
    setLoading((crr) => ({ ...crr, [key]: value }));

  // const uploadFile = async (value) => {
  //   if (value?.type !== "video/mp4") {
  //     setFile(value);
  //     if (file.storage === "cloud")
  //       dispatch({ type: SET_DELETE_NEW_COURSE_FILES, payload: file.name });
  //     changeLoading("file", false);
  //   } else {
  //     changeLoading("file", true);

  //     let data = {};

  //     const duration = await getVideoDuration(value);

  //     if (duration) data = { duration };

  //     try {
  //       const result = await _uploadFileNewCourse(value, token);
  //       if (!result.status) return console.log("something went wrong", result);
  //       data = { ...data, ...result.data };
  //       setFile(data);
  //       // add to deleted file list
  //       if (file.storage === "cloud")
  //         dispatch({ type: SET_DELETE_NEW_COURSE_FILES, payload: file.name });
  //     } catch (error) {
  //       console.log("something went wrong", error);
  //     } finally {
  //       changeLoading("file", false);
  //     }
  //   }
  // };

  const uploadFile = async (key, file) => {
    let uploadedData = {};

    const acceptType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "video/mp4";

    if (!acceptType) return alert("format file tidak didukung");

    changeLoading("file", true);

    if (file?.type === "video/mp4") {
      const duration = await getVideoDuration(file);

      if (duration) uploadedData = { duration };
    }

    try {
      const uploadFileRes = await _uploadFileNewCourse(file, token);

      if (!uploadFileRes.status) alert(uploadFileRes.message);

      uploadedData = { ...uploadedData, ...uploadFileRes.data };

      setFile(uploadedData);

      // add to deleted file list
      if (file.storage === "cloud")
        dispatch({
          type: SET_DELETE_NEW_WEBINAR_FILES,
          payload: file.name,
        });
    } catch (error) {
      console.log("something went wrong", error);
    } finally {
      changeLoading(key, false);
    }
  };

  useEffect(() => {
    if (file?.storage === "cloud") {
      setVideoSrc(file.link);
    } else if (file?.storage === "local") {
      _getStreamLocalFile(token, file?.name)
        .then((src) => setVideoSrc(src))
        .catch((e) => console.log("error get src", e));
    }
  }, [token, file?.storage, file?.link, file?.name]);


  return (
    <LayoutNewWebinarStep4
      title="Uji Keterampilan"
      subTitle="Tentukan aturan kelas Anda. "
    >
      <p className="text-netral-8">
        Tambahkan video untuk menjelaskan bagaimana cara dan tujuan peserta
        mengikuti Uji Keterampilan kelas Anda
      </p>
      <div className="mt-6 space-x-6 flex items-end">
        <div className="w-[389px] h-[259px] bg-primary-100/40 flex items-center justify-center relative">
          {!videoSrc ? (
            <Icon name="image-black" className="w-[99px] h-[99px] absolute" />
          ) : (
            <Player playsInline aspectRatio="4:3" src={getLink(videoSrc)}>
              <BigPlayButton position="center" />
            </Player>
          )}
        </div>
        <div className="space-x-6 flex items-end">
          <InputTextLabel
            id="filename"
            text="Klik untuk unggah File atau Video (format file Mp4/pdf)"
            padding="px-3 py-2"
            value={file?.name}
            readOnly
          />
          <button
            disabled={loading.file}
            className={`${
              loading.file
                ? "text-white bg-netral-5 cursor-not-allowed"
                : "text-secondary-button-600 border border-secondary-button-600 interactive-button-color"
            } whitespace-nowrap relative top-1 font-semibold px-3 py-2 rounded`}
          >
            <input
              disabled={loading.file}
              type="file"
              // accept=".mp4"
              onChange={(e) => uploadFile("file", e.target.files[0])}
              className="w-full h-full absolute top-0 left-0 opacity-0"
            />
            <span>Unggah File</span>
          </button>
        </div>
      </div>
      <ChildComponent file={file}  />
    </LayoutNewWebinarStep4>
  );
};

export default SkillTestWebinar;

const ChildComponent = ({ file }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();


  return (
    <NextOrLaterButton
      active={!!file?.name}
      page={"skillTest"}
      next={() => {
        dispatch({ type: SET_WEBINAR_SKILL_TEST, payload: file });
        dispatch({ type: SET_FINISHED_NAV, payload: "skillTest" });
        push(`/new/webinar/${id}/step5/individual-test`);
      }}
    />
  );
};
