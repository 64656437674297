import React from "react";
import { Icon } from "../../independent/image";

export default function CheckBoxFilled({description}) {
  return (
    
    <div className="flex items-start gap-x-[8px]">
      <Icon name={"Checked"} className={"flex-shrink-0"} />
      <p> {description} </p>
    </div>
  );
}
