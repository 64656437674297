export default function Consultation({ data }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full rounded-lg overflow-hidden">
        <thead>
          <tr className="flex items-center space-x-2 px-2 py-2 lg:py-3 bg-netral-2 text-left">
            <th>
              <p className="w-10 text-xs lg:text-sm font-semibold text-netral-10">No</p>
            </th>
            <th>
              <p className="w-[150px] text-xs lg:text-sm font-semibold text-netral-10">
                Tanggal
              </p>
            </th>
            <th>
              <p className="w-[150px] text-xs lg:text-sm font-semibold text-netral-10">
                Jam Mulai
              </p>
            </th>
            <th>
              <p className="w-[150px] text-xs lg:text-sm font-semibold text-netral-10">
                Jam Berakhir
              </p>
            </th>
            <th className="w-full">
              <p className="text-xs lg:text-sm font-semibold text-netral-10">Url</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, i) => (
            <tr
              key={i}
              className="flex items-center space-x-2 px-2 py-3 text-left"
            >
              <td>
                <p className="w-10 text-xs lg:text-sm text-netral-10">{i + 1}</p>
              </td>
              <td>
                <p className="w-[150px] text-xs lg:text-sm text-netral-10">{el.date}</p>
              </td>
              <td>
                <p className="w-[150px] text-xs lg:text-sm text-netral-10">{el.start}</p>
              </td>
              <td>
                <p className="w-[150px] text-xs lg:text-sm text-netral-10">{el.end}</p>
              </td>
              <td className="w-full">
                <p className="text-xs lg:text-sm text-netral-10">{el.url}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
