import { Link } from "react-router-dom";

export default function FaqBanner() {
  return (
    <section className=" my-8 lg:my-[128px] space-y-4 lg:space-y-6 text-center">
      <h3 className="px-4 lg:px-[111px] text-lg lg:text-xl font-semibold text-netral-10">
        Punya Pertanyaan Seputar kartu Prakerja di Skills.ID ?<br />
        Lihat detail FAQ-nya disini
      </h3>
      <Link
        to="/faq"
        className="text-xs lg:text-base inline-block text-netral-1 font-semibold px-3 py-2 rounded bg-primary2-500 border border-transparent interactive-button-color"
      >
        Lihat Selengkanya
      </Link>
    </section>
  );
}
