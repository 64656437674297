import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SET_ALERT } from "../../../../config/redux/types";
import {
  _checkoutPrakerja,
  _redeemPrakerja,
} from "../../../../config/services/api/checkout";
import useQuery from "../../../../hooks/lib/useQuery";
import { ClipArt, Icon } from "../../../independent/image";
import Modal from "../../../independent/modal";
import RadioButton from "../../../independent/radio-button";
import "./style.css";

export default function ClaimVoucherMobile({ data }) {
  
  const query = useQuery();
  const voucher = query.get("voucher");
  const dispatch = useDispatch();
  const { replace, push } = useHistory();
  const { courseId, webinarId } = useParams();
  const { token, userData } = useSelector((state) => state.authReducer);
  const [prakerjaCode, setPrakerjaCode] = useState(voucher || "");
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorVoucherModal, setErrorVoucherModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");

  const filteredSchedule = data?.schedule?.filter(
    (el) =>
      moment(el.dateStart).add("days", 1).format("") >= moment().format("")
  );

  const formattedDate = filteredSchedule.map(
    (schedule) =>
      `${moment(schedule.dateStart).format("LL")} - ${moment(
        schedule.dateEnd
      ).format("LL")}`
  );

  const [selectedDate, setSelectedDate] = useState("");
  const dateToSend = formattedDate.findIndex((el) => el === selectedDate);
  let startDate = moment(filteredSchedule[dateToSend]?.dateStart).format("yy/MM/DD");
  let endDate = moment(filteredSchedule[dateToSend]?.dateEnd).format("yy/MM/DD");

  const idToSend = courseId || webinarId;

  const submit = (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!token) return replace("/login");
    setLoading(true);
    _checkoutPrakerja(
      token,
      idToSend,
      prakerjaCode,
      startDate,
      endDate,
      data?.schedule[0].start,
      data?.schedule[0].end
    )
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        setOpenModal(false);
        if (res.status) {
          // push("/student");
          setSuccessModal(true)
          // if (userData.role.includes("Assesor")) {
          // } else {
          //   setOpenModal(true);
          // }
        } else {
          setErrorVoucherModal(true);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorVoucherModal(true);
      })
      .finally(() => setLoading(false));
  };

  const redeem = (e) => {
    e.preventDefault();
    if (!token) return replace("/login");
    setLoading(true);
    _redeemPrakerja(
      token,
      idToSend,
      prakerjaCode,
      startDate,
      endDate,
      data?.schedule[0]?.start,
      data?.schedule[0]?.end,
      redeemCode
    )
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        setOpenModal(false);

        if (res.status) setSuccessModal(true);
        else {
          setErrorModal(res.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorModal(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <form
        onSubmit={(e) => submit(e)}
        style={{ boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.08)" }}
        className="lg:hidden rounded-lg px-2 py-4 space-y-4"
      >
        <p className="text-xs text-netral-7">
          Jika Anda sudah membeli kelas ini dan sudah memiliki kode voucher,
          silakan tukarkan langsung disini
        </p>
        <div className="space-y-2">
          <label
            htmlFor="prakerja-code"
            className="inline-block text-sm font-semibold text-netral-8"
          >
            Tukarkan Kode Voucher
          </label>
          {data.schedule && (
            <RadioButton
              text="Pilih Tanggal"
              setValue={setSelectedDate}
              value={selectedDate}
              values={formattedDate}
            />
          )}
          <input
            id="prakerja-code"
            className="w-full border border-netral-7 rounded px-3 py-2 text-sm"
            placeholder="Masukkan kode voucher"
            value={prakerjaCode}
            onChange={(e) => setPrakerjaCode(e.target.value)}
          />
          <div className="flex items-center space-x-[10px]">
            <input
              id="referral-code"
              type="checkbox"
              className="w-6 h-6 rounded border border-netral-8"
              defaultChecked={false}
            />
            <label htmlFor="referral-code" className="text-netral-7 text-sm">
              Saya punya kode referral
            </label>
          </div>
        </div>
        <button
          type="submit"
          disabled={isLoading || selectedDate === "" || prakerjaCode === ""}
          className={`${
            isLoading || prakerjaCode === "" || selectedDate === ""
              ? "bg-netral-5 text-netral-6 cursor-not-allowed"
              : "bg-primary-button-500 text-white"
          } w-full py-2 font-semibold rounded`}
        >
          Tukarkan Kode Voucher
        </button>
      </form>

      <Modal modal={openModal}>
        <form onSubmit={(e) => redeem(e)}>
          <div className="bg-white w-[300px] min-h-[270px] relative p-[20px]">
            <div className="absolute right-6 top-3">
              <button type="button" onClick={() => setOpenModal(false)}>
                <Icon name="close-netral-10" className="w-6 h-6" />
              </button>
            </div>
            <div className="flex flex-col text-center space-y-2">
              <p className="text-xl font-semibold">Redeem Code</p>
              <p className="text-sm">
                Masukan 12 karakter unit redeem code yang dapat kamu temukan di
                website{" "}
                <a
                  href="https://www.prakerja.go.id/"
                  className="underline text-primary-500"
                >
                  Prakerja.go.id
                </a>
              </p>
              <div className="py-5">
                <input
                  id="voucher"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="block w-full p-3 rounded border border-[#757575]"
                  placeholder="Redeem code"
                  autocomplete="off"
                />
              </div>
              <button
                disabled={!redeemCode}
                type={"submit"}
                className={`${
                  !redeemCode
                    ? "text-netral-6 bg-netral-5 cursor-not-allowed"
                    : "text-white bg-secondary-button-600 interactive-button-color"
                } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
              >
                Redeem sekarang
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal modal={successModal}>
        <div className="bg-white w-[300px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setSuccessModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"Success-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl">Berhasil Tukar Voucher</p>
              <p className="font-normal text-sm">
                Kode Voucher Anda telah berhasil ditukarkan
              </p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => push("/student")}
            >
              Masuk ke Kelas
            </button>
          </div>
        </div>
      </Modal>

      {/* Error redeem Modal */}
      <Modal modal={errorModal}>
        <div className="bg-white w-[300px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setErrorModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">{errorModal}</p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                setOpenModal(true);
                setErrorModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>

      {/* Error voucher Modal */}
      <Modal modal={errorVoucherModal}>
        <div className="bg-white w-[300px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setErrorVoucherModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">
                Kode voucher tidak ditemukan
              </p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                // setOpenModal(true);
                setErrorVoucherModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>

      <Modal modal={isLoading}>
        <div className="bg-white min-w-[305px] min-h-[130px] relative p-[32px]">
          <div id="modal-loading" data-backdrop="static">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="loading-spinner mb-4"></div>
                  <div>Loading</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
