export default function RadioButton({ value, setValue, values, text }) {
  return (
    <div className="space-y-2 w-full">
      <p className="text-netral-8 text-sm font-semibold">{text}</p>
      {values?.map((el, i) => (
        <div
          disabled={i === 0}
          key={i}
          value={value}
          className="flex justify-between border p-[8px] border-netral5-600 cursor-pointer rounded-[4px] items-center"
          onClick={() => setValue(el)}
        >
          <p>{el}</p>
          <input
            onChange={() => {}}
            type="checkbox"
            className="w-[20px] h-[20px] rounded-full"
            checked={value === el}
          />
        </div>
      ))}
    </div>
  );
}
