import { useCurriculum } from "../../../../pages/new/webinar/[webinar-id]/step5/Curriculum";
import { Icon } from "../../../independent/image";
import InputTextLabel from "../../../independent/input/input-text-label";
import Question from "../../new-course/question";

export default function FormQuiz({ chapterIndex, materialIndex }) {
  const { chapters, setChapters } = useCurriculum();

  const quiz = chapters[chapterIndex].materials[materialIndex].quiz;

  const addQuestion = () => {
    const defaultQuestion = {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    };
    chapters[chapterIndex].materials[materialIndex].quiz.questions.push(
      defaultQuestion
    );
    setChapters([...chapters]);
  };

  const deleteQuiz = () => {
    chapters[chapterIndex].materials[materialIndex].quiz = null;
    chapters[chapterIndex].materials[materialIndex].view = "MaterialOption";
    setChapters([...chapters]);
  };

  const setTitle = (value) => {
    chapters[chapterIndex].materials[materialIndex].quiz.title = value;
    setChapters([...chapters]);
  };

  const setChoiceValue = (questionIndex, targetIndex, value) => {
    chapters[chapterIndex].materials[materialIndex].quiz.questions[
      questionIndex
    ].multipleChoice[targetIndex] = value;
    setChapters([...chapters]);
  };

  const setInputValue = (questionIndex, key, value) => {
    chapters[chapterIndex].materials[materialIndex].quiz.questions[
      questionIndex
    ][key] = value;
    setChapters([...chapters]);
  };

  const deleteQuestion = (questionIndex) => {
    chapters[chapterIndex].materials[materialIndex].quiz.questions.splice(
      questionIndex,
      1
    );
    setChapters([...chapters]);
  };

  const submit = (e) => {
    e.preventDefault();
    chapters[chapterIndex].materials[materialIndex].view = "Review";
    setChapters([...chapters]);
  };

  return (
    <form
      onSubmit={(e) => submit(e)}
      className="py-4 px-6 rounded border border-netral-6 bg-white"
    >
      <h6 className="font-semibold text-lg text-netral-10">
        Kuis{" "}
        <span className="text-sm font-normal text-red-500">
          Minimal 5 pertanyaan*
        </span>
      </h6>
      <div className="mt-[18px] pb-6 border-b-2 border-b-netral-6">
        <InputTextLabel
          id="quiz-title"
          placeholder="Masukkan Judul Kuis"
          text="Judul Kuis"
          value={chapters[chapterIndex].materials[materialIndex].quiz.title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mt-6 space-y-2 inline-block">
        <label className="text-netral-8 font-medium text-sm">Jenis Kelas</label>
        <button
          type="button"
          className="flex items-center space-x-4 p-2 border-netral-7 border rounded"
        >
          <span className="text-netral-10">Pilihan Ganda</span>
          <Icon
            name="keyboard_arrow_down-netral-10"
            className="w-[22px] h-[22px]"
          />
        </button>
      </div>
      <div className="mt-3 space-y-3">
        {quiz.questions.map((question, questionIndex) => (
          <Question
            key={questionIndex}
            canItBeDeleted={quiz.questions.length > 5}
            question={question}
            questionIndex={questionIndex}
            setInputValue={(key, value) =>
              setInputValue(questionIndex, key, value)
            }
            setChoiceValue={(targetIndex, value) =>
              setChoiceValue(questionIndex, targetIndex, value)
            }
            deleteQuestion={() => deleteQuestion(questionIndex)}
          />
        ))}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={addQuestion}
            className="font-semibold text-netral-1 px-3 py-2 rounded bg-primary-500 border border-transparent interactive-button-color"
          >
            Tambah Pertanyaan
          </button>
        </div>
      </div>
      <div className="mt-6 flex space-x-5 items-center">
        <button
          type="submit"
          className="font-semibold text-secondary-button-600"
        >
          Simpan Kuis
        </button>
        <button
          type="button"
          onClick={deleteQuiz}
          className="font-semibold text-primary2-500"
        >
          Hapus
        </button>
      </div>
    </form>
  );
}
