import { useRef, useState } from "react";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";
import { Icon } from "../../image";

export default function InputDropdownLabel2({ text, values, value, setValue }) {
  const [isDropdownShown, setDropdownShown] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => isDropdownShown && setDropdownShown(false));
  return (
    <div className="space-y-2 w-full">
      <label
        htmlFor="course"
        className="inline-block text-sm text-netral-8 font-semibold"
      >
        {text}
      </label>
      <div className="relative">
        <button
          type="button"
          onClick={() => setDropdownShown(!isDropdownShown)}
          id="course"
          className={`${
            value === values[0] ? "text-netral-5" : "text-netral-10"
          } text-left p-3 rounded-md border border-[#757575] w-full flex items-center justify-between`}
        >
          <span className="whitespace-pre text-sm lg:text-base">{value || values[0]}</span>
          <Icon
            name="keyboard_arrow_down-netral-10"
            className="w-[22px] h-[22px]"
          />
        </button>
        {isDropdownShown && (
          <div
            ref={ref}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            className="absolute top-14 left-0 w-full bg-white z-10 p-3 space-y-2"
          >
            {values.map((value, i) => (
              <button
                key={i}
                type="button"
                disabled={i === 0}
                onClick={() => {
                  setDropdownShown(false);
                  setValue(value);
                }}
                className={`${
                  i === 0 ? "text-netral-4" : "text-netral-9"
                } w-full text-left font-semibold hover:text-primary-500`}
              >
                {value}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
