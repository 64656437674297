import { useHistory } from "react-router-dom";
import ButtonExit from "../../components/independent/button/button-exit";
import Footer from "../../components/independent/footer/footer";
import Header from "../../components/independent/header/header";
import { Icon, Logo } from "../../components/independent/image";
import NavHeaderDashboard from "../../components/independent/navigation/nav-header-dashboard";
import HeaderV2 from "../../components/independent/header/headerV2";
import NavHeaderDashboardV2 from "../../components/independent/navigation/nav-header-dashboard-v2";
import { useEffect, useState } from "react";

const New = () => {
  const { push } = useHistory();
  const isDisabled = true;
  const [selectedType, setSelectedType] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  const gradientColor =
    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)";

  const type = [
    {
      id: 1,
      image: "Teaching-gradient",
      desc: "Sebuah seminar online yang Anda inginkan",
      type: "Webinar",
    },
    {
      id: 2,
      image: "Video-gradient",
      desc: "Kursus offline dengan video ataupun presentasi online",
      type: "Kursus",
    },
    {
      id: 3,
      image: "Webinar-gradient",
      desc: "Kursus offline dengan video ataupun presentasi online",
      type: "Video",
    },
  ];
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const normalBorderStyle = {
    border: "3px solid",
    borderImage: gradientColor,
    borderImageSlice: 1,
    borderRadius: "8px",
    transition: "border-color 0.3s ease-in-out",
  };

  const hoverBorderStyle = {
    border: "3px solid transparent",
    borderImage: gradientColor,
    borderImageSlice: 1,
    borderRadius: "8px",
  };

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  useEffect(() => {
    if (selectedType) {
      setIsSelected(true);
    }
  }, [selectedType]);

  console.log(selectedType);
  const onContinue = () => {
    switch (selectedType) {
      case "Kursus":
        return push(`/new/course/${Date.now()}`, { state: selectedType });
      case "Video":
        return push(`/new/video/${Date.now()}`, { state: selectedType });
      default:
        return push(`/new/webinar/${Date.now()}`, { state: selectedType });
    }
  };

  return (
    <div className="flex flex-col sm:h-full md:h-screen h-full">
      <HeaderV2>
        <NavHeaderDashboardV2 />
      </HeaderV2>
      {/* <div className="h-screen overflow-auto pt-8">
        <div className="relative">
          <ButtonExit to="/instructor">Keluar</ButtonExit>
          <div className="px-[264px] space-y-3">
            <h2 className="text-2xl text-netral-7 font-medium">Jenis Event</h2>
            <p className="text-netral-6">
              Terlebih dahulu Anda harus menentukan jenis event yang ingin Anda
              buat
            </p>
          </div>
          <div className="mt-[42px] mb-[224px] flex justify-center space-x-8">
            <div
              onClick={() => push(`/new/webinar/${Date.now()}`)}
              className="bg-netral-2/60 rounded-lg border border-[#9E9E9E]/60 px-4 py-8 space-y-8 w-[271px] cursor-pointer"
            >
              <Icon
                name="Video Conference"
                className="w-[70px] h-[70px] mx-auto"
              />
              <h4 className="text-center text-xl font-semibold text-netral-9">
                Webinar
              </h4>
              <p className="text-netral-9 text-center">
                Buat sebuah seminar online yang Anda inginkan
              </p>
            </div>
            <div
              onClick={() => push(`/new/course/${Date.now()}`)}
              className="bg-netral-2/60 rounded-lg border border-[#9E9E9E]/60 px-4 py-8 space-y-8 w-[271px] cursor-pointer"
            >
              <Icon name="Tuition" className="w-[70px] h-[70px] mx-auto" />
              <h4 className="text-center text-xl font-semibold text-netral-9">
                Kursus
              </h4>
              <p className="text-netral-9 text-center">
                Buat kursus dengan video ataupun presentasi online
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex flex-col h-full px-[32px] py-[24px] ">
        <div className="flex gap-1" onClick={() => push("/instructor")}>
          <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
          <span
            style={{
              background:
                "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // "-webkit-background-clip": "text",
              // "-webkit-text-fill-color": "transparent",
            }}
            className="cursor-pointer text-[1rem] leading-[20px] font-bold"
          >
            Kembali
          </span>
        </div>

        <div className="flex flex-col items-center justify-center gap-4 h-full">
          <div className="lg:px-[264px]  text-center pt-[1.5rem] space-y-3 flex flex-col items-center w-full ">
            <h2 className="text-2xl text-[#404040] font-medium  ">
              Jenis Kelas
            </h2>
            <p className="text-[#9E9E9E]">
              Silahkan pilih jenis kelas yang ingin anda buat.
            </p>
          </div>
          <div className="flex flex-wrap items-center gap-[20px] gap-x-8 gap-y-4 pt-[27px] md:justify-center lg:gap-[20px]">
            {type.map((each, index) => (
              <div
                key={index}
                className={`relative md:w-[260px] h-[233px] rounded-lg bg-[#fff] shadow-shadow-header p-3 cursor-pointer transition-all duration-300 w-full ${
                  index === hoveredIndex ? "hovered-item" : ""
                }`}
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={handleMouseOut}
                onClick={() => handleTypeChange(each.type)}
              >
                <div className="relative">
                  <input
                    type="checkbox"
                    id={`checkbox-${each.type}`}
                    name="checkboxGroup"
                    value={each.type}
                    checked={selectedType === each.type}
                    onChange={() => handleTypeChange(each.type)}
                    className="hidden"
                  />

                  <label
                    htmlFor={`checkbox-${each.type}`}
                    style={{
                      borderColor:
                        selectedType === each.type ? "#9360A8" : "#E0E0E0",
                    }}
                    className="flex items-center justify-center  w-8 h-8 border  rounded-full cursor-pointer absolute top-1 right-1 transition-colors duration-300 ease-in-out"
                  >
                    <div
                      className={`w-4 h-4 rounded-full ${
                        selectedType === each.type ? "bg-[#9360A8]" : "bg-white"
                      } transform scale-0 ${
                        selectedType === each.type ? "scale-100" : ""
                      } transition-transform duration-300 ease-in-out`}
                    />
                  </label>
                </div>

                <div className="flex items-center justify-center h-full flex-col gap-6">
                  <Icon name={each.image} w={70} h={70} />
                  <div className="flex flex-col gap gap-[4px] items-center">
                    <span className="text-[#404040] font-bold">
                      {each.type}
                    </span>
                    <span className="text-center text-[#404040]">
                      {each.desc}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`flex items-center px-4 py-2 rounded-lg text-[1.2rem] font-semibold cursor-pointer gap-[5px]`}
            style={{
              background: isSelected ? gradientColor : "#E0E0E0",
              color: isSelected ? "#FFFFFF" : "#9E9E9E",
            }}
            onClick={onContinue}
          >
            Lanjutkan
            <Icon
              name={`${
                isSelected
                  ? "keyboard_arrow_right"
                  : "keyboard_arrow_right-disabled"
              }`}
              h={20}
              w={20}
              className="mt-[3px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
