import React, { useState } from "react";
import Labelform from "../../atom/Label";
import { Icon } from "../../independent/image";
import Input from "../../atom/Input";

export default function MultipleInput({
  items,
  label,
  htmlFor,
  id,
  placeholder,
  handleChange
}) {
  const [inputValue, setInputValue] = useState("");

  const onChange = (event) => {
    setInputValue(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      addItem();
    }
  };

  const addItem = () => {
    if (inputValue.trim() !== "") {
      handleChange([...items, inputValue.trim()])
      setInputValue("");
    }
  };

  const handleRemoveItems = (itemToRemove) => {
    const updatedItems = items.filter((item) => item !== itemToRemove);
    handleChange(updatedItems)
  };
  
  const [isActiveTagInput,setIsActiveTagInput] = useState(false)
  return (
    <label htmlFor={id}  className="flex flex-col gap-[8px] ">
      <Labelform
        value={label}
        isRequired={true}
        className={"text-[#616161] font-medium text-[14px]"}
        htmlFor={htmlFor}
      />
      <div
        className={`border ${isActiveTagInput ? " outline outline-2 outline-[#8660a8]  " :  "border-[#E0E0E0]"}  p-1 rounded-[8px]`} // input active => border blue
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          alignItems: "center",
          minWidth: "100%",
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className="h-[36px] flex gap-[5px] items-center  rounded-md p-[10px] bg-[#F4EFF6]"
          >
            {item}
            <Icon
              name="Close-Multiple-Input"
              w={20}
              className={"cursor-pointer"}
              h={20}
              onClick={() => handleRemoveItems(item)}
            />
          </div>
        ))}
        <Input   //isActive / focus
          id={id}
          type={"text"}
          className="border-none inline outline-none placeholder:text-[#C2C2C2] focus:ring-0"
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onFocus={()=>setIsActiveTagInput(true)}
          onBlur={()=>setIsActiveTagInput(false)}
          value={inputValue}
        />
      </div>
      <span className="text-[10px] -mt-1 text-[#9E9E9E]">
        Tekan enter{" "}
        <Icon name={"Enter-Line"} h={"7px"} w={"7.5px"} className={"inline"} />{" "}
        pada keyboard anda untuk menambahkan tag lain.
      </span>
    </label>
  );
}
