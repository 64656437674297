import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SET_ALERT } from "../../../../config/redux/types";
import {
  _checkoutPrakerja,
  _redeemPrakerja,
} from "../../../../config/services/api/checkout";
// import useCart from "../../../../hooks/data-management/useCart";

import useQuery from "../../../../hooks/lib/useQuery";
import { ClipArt, Icon, Logo } from "../../../independent/image";
import Modal from "../../../independent/modal";
import RadioButton from "../../../independent/radio-button";
import "./style.css";

export default function OrderSummary({ webinar }) {
  const query = useQuery();
  const voucher = query.get("voucher");
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div
      style={{ boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.08)" }}
      className="w-full px-3 py-6 space-y-6 rounded-lg"
    >
      {/* {isPrakerja && (
        <div className="flex">
          {tabs.map((tab, i) => (
            <button
              key={i}
              type="button"
              onClick={() => setActiveTab(tab)}
              className={`${
                tab === activeTab
                  ? "bg-primary-600 text-white rounded-full font-semibold"
                  : "text-netral-6"
              } w-1/2 py-2`}
            >
              {tab}
            </button>
          ))}
        </div>
      )}
      {activeTab === "Beli" && (
        <Beli
          webinar={webinar}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
      {activeTab === "Tukar Voucher" && (
        <TukarVoucher
          voucher={voucher}
          webinar={webinar}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )} */}
      <TukarVoucher
        voucher={voucher}
        webinar={webinar}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
}

// const Beli = ({ webinar, selectedDate, setSelectedDate }) => {
//   const { courseId } = useParams();
//   const { addToCart } = useCart();
//   return (
//     <div className="w-full px-3 py-6 space-y-6 rounded-lg">
//       <h4 className="text-2xl text-netral-9 font-semibold pb-2 border-b-2 border-[#EDEDED]">
//         IDR {Number(webinar?.pricing?.price).toLocaleString("id-ID")}
//       </h4>
//       {webinar.schedule && (
//         <RadioButton
//           text="Pilih Tanggal"
//           setValue={setSelectedDate}
//           value={selectedDate}
//           values={[
//             ...webinar?.schedule?.map(
//               (schedule) =>
//                 `${moment(schedule.dateStart).format("ll")} - ${moment(
//                   schedule.dateEnd
//                 ).format("ll")}`
//             ),
//           ]}
//         />
//       )}
//       {/* <InputDropdownLabel2
//     text="Pilih Tanggal"
//     setValue={setSelectedDate}
//     value={selectedDate}
//     values={[
//       "-- Pilh tanggal --",
//       ...webinar.schedule.map(
//         (schedule) =>
//           `${new Date(schedule.dateStart).toDateString()} - ${new Date(
//             schedule.dateEnd
//           ).toDateString()}`
//       ),
//     ]}
//   /> */}
//       <div className="space-y-2">
//         <p className="text-sm font-semibold text-netral-8">Waktu Webinar</p>
//         <div className="flex items-center space-x-2">
//           <Icon name="Time-primary-700" className="w-6 h-6" />
//           <p className="text-netral-9">
//             {!!webinar?.schedule?.length &&
//               `${webinar.schedule[0].start} - ${webinar.schedule[0].end}`}
//           </p>
//         </div>
//       </div>
//       {/* 2.3  */}
//       <div className="space-y-2">
//         <div className="flex items-center space-x-4">
//           <button
//             type="button"
//             onClick={() => addToCart(courseId)}
//             className="text-secondary-button-600 font-semibold flex-grow py-2 rounded border border-[#006A9D] interactive-button-color"
//           >
//             Tambah ke Keranjang
//           </button>
//           <button className="px-3 py-2 rounded border border-[#006A9D] interactive-button-color">
//             <Icon
//               name="Bookmark-secondary-button-600"
//               className="w-[17px] h-[22px]"
//             />
//           </button>
//         </div>
//         <Link
//           to={
//             selectedDate === ""
//               ? `${courseId}`
//               : `/checkout?items=${encodeURIComponent(
//                   JSON.stringify([courseId])
//                 )}`
//           }
//           className={
//             selectedDate === ""
//               ? "text-netral-6 bg-netral-5 cursor-not-allowed inline-block text-center font-semibold py-2 w-full rounded border border-transparent "
//               : "inline-block text-center text-white font-semibold py-2 w-full bg-secondary-button-600 rounded border border-transparent interactive-button-color"
//           }
//         >
//           Beli Sekarang
//         </Link>
//       </div>
//       {/* 2.4  */}
//     </div>
//   );
// };

const TukarVoucher = ({ voucher, webinar, setSelectedDate, selectedDate }) => {
  const dispatch = useDispatch();
  const { replace, push } = useHistory();
  const { webinarId } = useParams();
  const { token, userData } = useSelector((state) => state.authReducer);
  const [prakerjaCode, setPrakerjaCode] = useState(voucher || "");
  const [isLoading, setLoading] = useState(false);
  const filteredSchedule = webinar?.schedule?.filter(
    (el) =>
      moment(el.dateStart).add("days", 1).format("") >= moment().format("")
  );

  const formattedDate = filteredSchedule.map(
    (schedule) =>
      `${moment(schedule.dateStart).format("LL")} - ${moment(
        schedule.dateEnd
      ).format("LL")}`
  );

  const dateToSend = formattedDate.findIndex((el) => el === selectedDate);
  let startDate = moment(filteredSchedule[dateToSend]?.dateStart).format(
    "yy/MM/DD"
  );
  let endDate = moment(filteredSchedule[dateToSend]?.dateEnd).format(
    "yy/MM/DD"
  );
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorVoucherModal, setErrorVoucherModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");

  const redeem = (e) => {
    e.preventDefault();
    if (!token) return replace("/login");
    setLoading(true);
    _checkoutPrakerja(
      token,
      webinarId,
      prakerjaCode,
      startDate,
      endDate,
      webinar.schedule[0].start,
      webinar.schedule[0].end,
      redeemCode
    )
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        setOpenModal(false);
        if (res.status) setSuccessModal(true);
        else {
          setErrorModal(res.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorModal(true);
      })
      .finally(() => setLoading(false));
  };

  const submit = (e) => {
    e.preventDefault();
    if (!token) return replace("/login");
    setLoading(true);
    _checkoutPrakerja(
      token,
      webinarId,
      prakerjaCode,
      startDate,
      endDate,
      webinar?.schedule[0]?.start,
      webinar?.schedule[0]?.end
    )
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        if (res.status) {
          if (userData.role.includes("Assesor")) {
            push("/student");
          } else {
            setSuccessModal(true);
          }
        } else {
          setErrorVoucherModal(true);
        }
      })
      .catch((e) =>
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <form onSubmit={(e) => submit(e)}>
        <div className="space-y-4 mb-5">
          <h4 className="text-2xl text-netral-9 font-semibold pb-2 border-b-2 border-[#EDEDED]">
            IDR {Number(webinar?.pricing?.price).toLocaleString("id-ID")}
          </h4>
          {webinar.schedule && (
            <RadioButton
              text="Pilih Tanggal"
              setValue={setSelectedDate}
              value={selectedDate}
              values={formattedDate}
            />
          )}
          <div className="space-y-2">
            <p className="text-sm font-semibold text-netral-8">Waktu Webinar</p>
            <div className="flex items-center space-x-2">
              <Icon name="Time-primary-700" className="w-6 h-6" />
              <p className="text-netral-9">
                {!!webinar?.schedule?.length &&
                  `${webinar.schedule[0].start} - ${webinar.schedule[0].end}`}
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm text-netral-7">
          Jika Anda sudah membeli kelas ini dan sudah memiliki kode voucher,
          silakan tukarkan langsung disini
        </p>
        <div className="space-y-3">
          <div className="space-y-2">
            <label htmlFor="voucher" className="text-netral-8 font-semibold">
              Tukarkan Kode Voucher
            </label>
            <input
              id="voucher"
              value={prakerjaCode}
              onChange={(e) => setPrakerjaCode(e.target.value)}
              className="block w-full p-3 rounded border border-[#757575]"
              placeholder="Masukkan kode voucher"
            />
          </div>
          <div className="flex items-center space-x-2">
            <input
              className="h-[24px] w-[24px]"
              type="checkbox"
              id="referralCode"
            />
            <label htmlFor="referralCode" className="text-netral-7">
              Saya punya kode referral
            </label>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm text-netral-8">Belum punya kode voucher?</p>
          <p className="mt-2 font-semibold text-primary2-500">
            Dapatkan kode voucher di
          </p>
          <div className="mt-6 grid grid-cols-2 gap-3">
            {platforms.map((platform, i) => (
              <div key={i} className="flex items-center space-x-3">
                <Logo name={platform.logo} className="w-6 h-6" />
                <button
                  type="button"
                  className="p-1 text-sm font-semibold text-primary-500"
                >
                  {platform.text}
                </button>
              </div>
            ))}
          </div>
        </div>
        <button
          type="submit"
          // disabled={!prakerjaCode || isLoading || selectedDate === ""}
          className={`${
            !prakerjaCode || isLoading || selectedDate === ""
              ? "text-netral-6 bg-netral-5 cursor-not-allowed"
              : "text-white bg-secondary-button-600 interactive-button-color"
          } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
        >
          Tukarkan Kode Voucher
        </button>
      </form>

      {/* Redeem Modal */}
      <Modal modal={openModal}>
        <form onSubmit={(e) => redeem(e)}>
          <div className="bg-white w-[485px] min-h-[270px] relative p-[32px]">
            <div className="absolute right-6 top-3">
              <button type="button" onClick={() => setOpenModal(false)}>
                <Icon name="close-netral-10" className="w-6 h-6" />
              </button>
            </div>
            <div className="flex flex-col text-center space-y-2">
              <p className="text-xl font-semibold">Redeem Code</p>
              <p className="text-sm">
                Masukan 12 karakter unit redeem code yang dapat kamu temukan di
                website{" "}
                <a
                  href="https://www.prakerja.go.id/"
                  className="underline text-primary-500"
                >
                  Prakerja.go.id
                </a>
              </p>
              <div className="py-5">
                <input
                  id="voucher"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="block w-full p-3 rounded border border-[#757575]"
                  placeholder="Redeem code"
                  autocomplete="off"
                />
              </div>
              <button
                disabled={!redeemCode}
                type={"submit"}
                className={`${
                  !redeemCode
                    ? "text-netral-6 bg-netral-5 cursor-not-allowed"
                    : "text-white bg-secondary-button-600 interactive-button-color"
                } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
              >
                Redeem sekarang
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal modal={successModal}>
        <div className="bg-white min-w-[435px] min-h-[340px] relative p-[32px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setSuccessModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"Success-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl">
                Berhasil Tukar Kode Redeem
              </p>
              <p className="font-normal text-sm">
                Kode Redeem Anda telah berhasil ditukarkan
              </p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => push("/student")}
            >
              Masuk ke Kelas
            </button>
          </div>
        </div>
      </Modal>

      {/* Error redeem Modal */}
      <Modal modal={errorModal}>
        <div className="bg-white min-w-[435px] min-h-[340px] relative p-[32px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setErrorModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Redeem
              </p>
              <p className="font-normal text-sm">{errorModal}</p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                setOpenModal(true);
                setErrorModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>

      {/* Error voucher Modal */}
      <Modal modal={errorVoucherModal}>
        <div className="bg-white min-w-[435px] min-h-[340px] relative p-[32px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setErrorVoucherModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">
                Kode voucher tidak ditemukan
              </p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                // setOpenModal(true);
                setErrorVoucherModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>

      <Modal modal={isLoading}>
        <div className="bg-white min-w-[305px] min-h-[130px] relative p-[32px]">
          <div id="modal-loading" data-backdrop="static">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-body text-center">
                  <div className="loading-spinner mb-4"></div>
                  <div>Loading</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const platforms = [
  { logo: "bukalapak", text: "Bukalapak" },
  { logo: "pintaria", text: "Pintar" },
  { logo: "tokopedia", text: "Tokopedia" },
];
