import { Link } from "react-router-dom";
import { Illustration } from "../../../independent/image";

export default function SkillsIDBanner() {
  return (
    <section className="mt-7 lg:mt-[calc(7rem-2rem)] flex justify-between items-center">
      <div className="hidden lg:block ml-[32px] w-1/2 lg:mr-[86px]">
        <h4 className="text-primary-900 font-semibold text-2xl leading-[38.4.px]">
          Apa itu Skills.id
        </h4>
        <p className="mt-6 text-lg text-netral-10">
          Instruktur dari seluruh dunia mengajar jutaan peserta di Skills.id.
          Kami menyediakan alat dan keterampilan untuk mengajarkan hal yang Anda
          sukai.
        </p>
        <Link
          to="/about"
          style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
          className="inline-block mt-[60px] p-3 border border-secondary-button-600 text-secondary-button-600 hover:text-white rounded interactive-button-color"
        >
          Selengkapnya
        </Link>
      </div>
      <div
        style={{
          filter: "drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.32))",
          backdropFilter: "blur(40px)",
        }}
        className="flex items-center justify-between lg:block w-full lg:w-1/2 h-[205px] lg:h-[515px] bg-primary-900 px-4 lg:pt-[25.97px] lg:pb-[25.1px] lg:px-[34.09px] relative lg:rounded-r-xl"
      >
        <div
          style={{ backgroundImage: "url(/assets/clip-art/blur1.png)" }}
          className="absolute top-0 left-0 h-full w-1/2 lg:w-[381px] bg-cover"
        ></div>
        <div
          style={{ backgroundImage: "url(/assets/clip-art/blur2.png)" }}
          className="absolute bottom-0 right-0 h-[122px] lg:h-[218px] w-1/2 lg:w-[381px] bg-cover"
        ></div>
        <div className="h-[173px] lg:h-full w-1/2 lg:w-full lg:border-2 border-dashed border-white lg:py-[18.09px] lg:pl-[35.15px] lg:pr-[36.22px]">
          <Illustration name="skills.id" className="h-full w-full" />
        </div>
        <div className="lg:hidden w-1/2 ml-4">
          <h4 className="text-white font-semibold text-lg">
            Apa itu Skills.id ?
          </h4>
          <p className="mt-2 text-sm text-white">
            Instruktur dari seluruh dunia mengajar jutaan
          </p>
          <Link
            to="/about"
            style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
            className="inline-block mt-4 py-1 px-[31px] border border-white text-white rounded interactive-button-color"
          >
            Selengkapnya
          </Link>
        </div>
      </div>
    </section>
  );
}
