import { CHANGE_SIDEBAR, TOGGLE_SIDEBAR } from "../types";

const initialState = {
  isSidebarOpened: false,
};

export default function sidebarReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_SIDEBAR:
      return { ...state, isSidebarOpened: payload };
    case TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default:
      return state;
  }
}
