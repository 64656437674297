import { useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import { _switchRole } from "../../../../config/services/api/auth";
import useCart from "../../../../hooks/data-management/useCart";
import ButtonProfile from "../../button/button-profile";
import { Icon } from "../../image";

export default function NavHeaderDashboard() {
  const { isLogin, token, userData } = useSelector(
    (state) => state.authReducer
  );

  const { push } = useHistory();
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];

  const isInstructor = userData.role?.some((role) => role === "Instructor");

  const switchRole = () => {
    if (!isLogin) return push("/become-instructor");

    if (userData.role.length >= 2 && route === "student")
      return push("/instructor");

    if (userData.role.length >= 2 && route === "instructor")
      return push("/student");

    if (!isInstructor && route === "student") return push("/become-instructor");

    if (isInstructor && route === "student") return push("/instructor");

    if (!isInstructor && route === "instructor") return push("/student");

    _switchRole(token)
      .then((result) => {
        if (result.status)
          push(`/${route === "instructor" ? "student" : "instructor"}`);
      })
      .catch((e) => console.log("failed switch role", e));
  };

  const { cart } = useCart();

  return (
    <nav>
      <ul className="flex space-x-10 items-center text-primary-500 font-semibold">
        {route === "student" && (
          <>
            <li>
              <a href="#tes" className="hover:text-primary-600">
                Course
              </a>
            </li>
            <li>
              <a href="#tes" className="hover:text-primary-600">
                Category
              </a>
            </li>
            <li>
              <button
                onClick={switchRole}
                className="text-primary2-300 interactive-text-color"
              >
                {isInstructor ? "Instructor" : "Become Instructor"}
              </button>
            </li>
            <li>
              <Link to="/cart" className="relative">
                <Icon name="shopping_cart-primary-500" />
                {cart.length ? (
                  <span className="absolute -top-1 left-4 w-4 h-4 bg-red-600 rounded-full text-2xs grid place-items-center text-white font-semibold">
                    {cart.length}
                  </span>
                ) : (
                  <></>
                )}
              </Link>
            </li>
          </>
        )}
        {route === "instructor" && (
          <>
            <li>
              <button
                type="button"
                onClick={switchRole}
                className="text-primary2-500"
              >
                Switch to Student
              </button>
            </li>
            <li>
              <a href="#tes" className="hover:text-primary-600">
                <Icon name="notifications-primary-500" />
              </a>
            </li>
          </>
        )}
        <li>
          <ButtonProfile />
        </li>
      </ul>
    </nav>
  );
}
