import { useCurriculum } from "../../../../pages/new/webinar/[webinar-id]/step5/Curriculum";
import { ClipArt, Icon } from "../../../independent/image";

export default function MaterialOption({ chapterIndex, materialIndex }) {
  const options = [
    { name: "Pelajaran", clipArt: "Study", link: "FormLesson" },
    { name: "Kuis", clipArt: "Task", link: "FormQuiz" },
  ];

  const questions = [
    {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    },
    {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    },
    {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    },
    {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    },
    {
      name: "",
      multipleChoice: ["", "", "", ""],
      answer: "",
      reason: "",
    },
  ];

  const defaultQuiz = {
    title: "",
    type: "Pilihan Ganda",
    questions,
  };

  const deafultLesson = {
    title: "",
    file: null,
    additionalFile: null,
  };

  const { chapters, setChapters } = useCurriculum();

  const changeView = (value) => {
    chapters[chapterIndex].materials[materialIndex].view = value;
    if (value === "FormLesson")
      chapters[chapterIndex].materials[materialIndex].lesson = deafultLesson;
    if (value === "FormQuiz")
      chapters[chapterIndex].materials[materialIndex].quiz = defaultQuiz;
    setChapters([...chapters]);
  };

  return (
    <div className="flex items-center justify-center space-x-8">
      {options.map((activity, i) => (
        <div
          key={i}
          onClick={() => changeView(activity.link)}
          className="px-1 py-2 space-y-3 w-[111px] rounded bg-primary-700 cursor-pointer"
        >
          <ClipArt
            name={activity.clipArt}
            className="w-[50px] h-[50px] mx-auto"
          />
          <div className="flex justify-center">
            <button className="p-1 flex items-center space-x-[2px]">
              <Icon name="add-netral-1" className="w-6 h-6" />
              <span className="font-semibold text-netral-1">
                {activity.name}
              </span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
