import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import ButtonExit from "../../button/button-exit";
import Footer from "../../footer/footer";
import Header from "../../header/header";
// import { Icon } from "../../image";
import NavHeaderDashboard from "../../navigation/nav-header-dashboard";
import HeaderV2 from "../../header/headerV2";
import NavHeaderDashboardV2 from "../../navigation/nav-header-dashboard-v2";
import { Icon } from "../../image";
import "./index.css";

export default function LayoutNewVideo({ activeTab, children }) {
  // const [animationProgress, setAnimationProgress] = useState(0);
  const { pathname, state } = useLocation();
  const isStep5 = pathname.includes("step5");
  const all = useSelector((state) => state.newWebinarReducer);
  const { id } = useParams();

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAnimationProgress((prev) => (prev + 1) % 101);
  //   }, 20);

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {}, [activeTab]);

  const getGradientColor = (index) => {
    if (index === activeTab) {
      return "stepperGradient";
      // return "stepperGradient"
    } else if (index < activeTab) {
      return "stepperComplete";
    }
    else {
      return "stepperInComplete";
    }
  };

  const getTextColor = (index) => {
    if (index === activeTab) {
      return "#9360A8";
    } else if (index < activeTab) {
      return "#9360A8";
    }
    else {
      return "#C2C2C2";
    }
  };

  if (all._id && !isStep5) {
    return <Redirect to={`/new/webinar/${id}/step5`} />;
  } else {
    if (isStep5 && all.courseCategory === "") {
      return <Redirect to={`/new/webinar/${id}/`} />;
    }
  }

  return (
    <div
      // className="h-screen flex flex-col bg-slate-600"
      className="flex flex-col sm:h-full md:h-full h-full"
    >
      <HeaderV2>
        <NavHeaderDashboardV2 />
      </HeaderV2>
      <div className="pt-8 h-full ">
        <div className="w-full flex px-[32px] pb-[40px] gap-x-[6px]">
          {dataHeader.map((tab, index) => (
            <div className="w-full flex  flex-col gap-y-[5px]" key={index}>
              <div
                className="w-full h-[6px] rounded-[10px] "
                id={getGradientColor(index + 1)}
              />

              <div className="flex items-center gap-[5px]">
                {index + 1 < activeTab && (
                  <div className="flex justify-center bg-[#9360A8] rounded-full w-[20px] h-[20px]">
                    <Icon name="check" w={12} h={12} />
                  </div>
                )}
                <span
                  className={`text-[1rem] text-center max-[430px]:text-center max-[430px]:text-[12px] ${
                    index + 1 !== activeTab ? "max-sm:hidden" : ""
                  } `}
                  style={{ color: getTextColor(index + 1) }}
                >
                  {" "}
                  {tab}{" "}
                </span>
              </div>
            </div>
          ))}
        </div>

        <main className="">{children}</main>
      </div>
    </div>
  );
}

LayoutNewVideo.defaultProps = {
  activeTab: 1,
};

const dataHeader = [
  "Detail Kelas",
  "Kurikulum",
  "Bank Soal",
  "Uji Keterampilan",
  "Penetapan Harga",
  "Review",
];
