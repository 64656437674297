import {
  _getProfile,
  _login,
  _register,
  _updateProfile,
  _verficationOTP,
} from "../../services/api/auth";
import {
  CHANGE_LOGIN_LOADING,
  CHANGE_REGISTER_LOADING,
  CHANGE_LOGIN,
  SET_USER,
  SET_LOGIN_MESSAGE,
  SET_REGISTER_MESSAGE,
  CHANGE_REGISTER_STATUS,
  CHANGE_LOGIN_STATUS,
  SET_TOKEN,
  CHANGE_OTP_LOADING,
  SET_OTP_MESSAGE,
  CHANGE_OTP_STATUS,
} from "../types";

const backToTop = () => window.scroll({ top: 0, behavior: "smooth" });

export const register = (data, cb) => (dispatch) => {
  const { name, email,phonenumber, password, confirm_password, role } = data;

  if (email === "") {
    backToTop();
    return dispatch({
      type: SET_REGISTER_MESSAGE,
      payload: "email atau no whatsapp masih kosong",
    });
  }

  if (password !== confirm_password) {
    backToTop();
    return dispatch({
      type: SET_REGISTER_MESSAGE,
      payload: "password dont match",
    });
  }

  dispatch({ type: CHANGE_REGISTER_LOADING, payload: true });


  _register(name, email, phonenumber, password, role)
    .then((result) => {
      dispatch({ type: SET_REGISTER_MESSAGE, payload: result.message });
      dispatch({ type: CHANGE_REGISTER_STATUS, payload: result.status });
      if (!result.status) return;
      if (cb) cb(result.token);
    })
    .catch((error) => {
      dispatch({
        type: SET_REGISTER_MESSAGE,
        payload: "something went wrong",
      });
      dispatch({ type: CHANGE_REGISTER_STATUS, payload: false });
      console.log("error", error);
    })
    .finally(() => {
      dispatch({ type: CHANGE_REGISTER_LOADING, payload: false });
      backToTop();
    });
};

export const login =
  ({ email, password, isRemember }, redirect) =>
  async (dispatch) => {
    isRemember = true;

    if (!email) {
      backToTop();
      return dispatch({
        type: SET_LOGIN_MESSAGE,
        payload: "email atau no whatsapp masih kosong",
      });
    }

    dispatch({ type: CHANGE_LOGIN_LOADING, payload: true });

    _login(email, password)
      .then((result) => {
        dispatch({ type: SET_LOGIN_MESSAGE, payload: result.message });
        dispatch({ type: CHANGE_LOGIN_STATUS, payload: result.status });
        if (!result.status) return;
        if (isRemember) localStorage.setItem("token", result.token);
        dispatch({ type: SET_USER, payload: result.user });
        dispatch({ type: SET_TOKEN, payload: result.token });
        dispatch({ type: CHANGE_LOGIN, payload: true });
      })
      .catch((error) => {
        dispatch({
          type: SET_LOGIN_MESSAGE,
          payload: "something went wrong",
        });
        dispatch({ type: CHANGE_LOGIN_STATUS, payload: false });
        console.log("error", error);
      })
      .finally(() => {
        dispatch({ type: CHANGE_LOGIN_LOADING, payload: false });
        backToTop();
      });
  };

export const verificationOTP =
  ({ otpNumber, token }, cb) =>
  (dispatch) => {
    dispatch({ type: CHANGE_OTP_LOADING, payload: true });
    _verficationOTP(otpNumber, token)
      .then((result) => {
        dispatch({ type: SET_OTP_MESSAGE, payload: result.message });
        dispatch({ type: CHANGE_OTP_STATUS, payload: result.status });
        if (result.status) {
          dispatch({ type: SET_REGISTER_MESSAGE, payload: result.message });
          cb();
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_OTP_MESSAGE,
          payload: "something went wrong",
        });
        dispatch({ type: CHANGE_OTP_STATUS, payload: false });
        console.log("error", error);
      })
      .finally(() => {
        dispatch({ type: CHANGE_OTP_LOADING, payload: false });
        backToTop();
      });
  };

export const logout = (cb) => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: CHANGE_LOGIN, payload: false });
  dispatch({ type: SET_TOKEN, payload: "" });
  dispatch({
    type: SET_USER,
    payload: {
      role: [],
    },
  });
  if (cb) cb();
};

export const getProfile = (token, cb) => (dispatch) => {
  dispatch({ type: CHANGE_LOGIN_LOADING, payload: true });
  _getProfile(token)
    .then((res) => {
      if (res.code === 408) localStorage.removeItem("token");
      if (!res.status) return;
      dispatch({ type: SET_USER, payload: res.data });
      dispatch({ type: CHANGE_LOGIN, payload: true });
      if (cb) cb();
    })
    .catch((e) => console.log("something went wrong", e))
    .finally(() => dispatch({ type: CHANGE_LOGIN_LOADING, payload: false }));
};

export const updateProfile = (token, data) => async (dispatch) => {
  const { instagram, facebook, twitter, ...rest } = data;

  rest.socialMedia = { instagram, facebook, twitter };

  const updated = await _updateProfile(token, rest);

  alert(updated.message);

  if (!updated.status) return;

  dispatch(getProfile(token));
};
