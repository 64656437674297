import React from "react";
import InputField from "../../components/Molecule/InputField";
import { Icon } from "../../components/independent/image";

export default function OptionalInput({
  onClick,
  placeholder,
  onChange,
  value,
}) {
  return (
    <>
      <div className="relative">
        <InputField
          addLabel={false}
          required={false}
          classNameLabel={"text-[#616161] font-medium text-[14px]"}
          classNameInput={
            "rounded-xl border-[#E0E0E0] p-[12px] w-[95%] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
          }
          placeholder={placeholder}
          type={"text"}
          onChange={onChange}
          value={value}
        />
        <Icon
          name="Optional-input"
          w={20}
          className={
            "absolute max-[430px]:-right-2 right-0 bottom-0 cursor-pointer"
          }
          h={20}
          onClick={onClick}
        />
      </div>
    </>
  );
}
