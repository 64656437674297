import Input from "../../atom/Input";
import Labelform from "../../atom/Label";

export default function InputField ({
    addLabel,
    required,
    labelValue,
    type,
    hidden,
    inputName,
    classNameInput,
    placeholder,
    htmlFor,
    inputId,
    classNameLabel,
    ref,
    accept,
    value,
    onChange
}){
    return (
        <div className="flex flex-col gap-[8px]">
            {addLabel &&
            <Labelform isRequired={required} value={labelValue} className={classNameLabel} htmlFor={htmlFor} />
             }
            <Input value={value} type={type} isHidden={hidden} name={inputName} id={inputId} onChange={onChange}  placeholder={placeholder} className={classNameInput} ref={ref} accept={accept}/>
        
        </div>
    )
}