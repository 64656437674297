import iconSet from "./selection.json";
import IcoMoon from "react-icomoon";

export const Icon = ({
  name,
  h,
  w,
  className,
  onClick,
  id,
  onMouseOver,
  onMouseLeave,
  style,
}) => (
  <img
    width={w}
    height={h}
    alt={name}
    className={className}
    src={process.env.PUBLIC_URL + `/assets/icon/${name}.svg`}
    onClick={onClick}
    id={id}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    style={style}
  />
);

export const Icomoon = (props) => <IcoMoon iconSet={iconSet} {...props} />;

export const Logo = ({ name, h, w, className }) => (
  <img
    width={w}
    height={h}
    alt={name}
    className={className}
    src={`/assets/logo/${name}.png`}
  />
);

export const Avatar = ({ name, h, w }) => (
  <img
    width={w}
    height={h}
    alt={name}
    className="object-cover rounded-full cursor-pointer"
    src={`/assets/avatar/${name}.png`}
  />
);

export const Illustration = ({ name, h, w, className }) => (
  <img
    width={w}
    height={h}
    alt={name}
    className={className}
    src={`/assets/illustration/${name}.png`}
  />
);

export const ClipArt = ({ name, h, w, className }) => (
  <img
    width={w}
    height={h}
    alt={name}
    className={"object-cover " + className}
    src={`/assets/clip-art/${name}.png`}
  />
);

Icon.defaultProps = {
  h: 24,
  w: 24,
};

Illustration.defaultProps = {
  h: 64,
  w: 64,
};
