import {
  SET_CURRICULUM,
  SET_COURSE_CATEGORY,
  SET_COURSE_TITLE,
  SET_PARTICIPANT_TYPE,
  SET_COURSE_TYPE,
  SET_TARGET_PARTICIPANTS,
  SET_GROUP_OF_QUESTION,
  SET_SKILL_TEST,
  SET_COURSE_SETTING,
  SET_COURSE_HOMPAGE,
  SET_COURSE_PRICING,
  SET_INTERACTION_LINK,
  SET_FINISHED_NAV,
  SET_NEW_COURSE,
  SET_DELETE_NEW_COURSE_FILES,
  SET_COURSE_SCHEDULE,
  SET_COURSE_COMPETENCE,
  SET_COURSE_COMPETENCE_TITLE,
  SET_COURSE_DISCOUNT,
} from "../types";

// const groupOfQuestion = [];

export const initialNewcourseReducer = {
  courseTitle: "",
  courseCategory: "",
  participantType: "",
  classType: "",
  competenceUnit: [{
    title: "",
    competenceCode: "",
  }],
  competenceTitle:"",
  targetParticipants: [
    {
      title: "Tujuan Umum",
      description: "",
      min: 1,
      inputs: [
        {
          placeholder: "Tulis disini...",
          value: "",
        },
      ],
    },
    {
      title: "Tujuan Khusus",
      description: "",
      min: 4,
      inputs: [
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
      ],
    },
    {
      title: "Aspek Kompetensi",
      description:
        "Cantumkan keterampilan, pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta sebelum mengikuti kursus Anda. Jika tidak ada persyaratan, gunakan ruang ini sebagai peluang untuk lebih memudahkan pemula.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
    {
      title: "Untuk siapa kursus ini?",
      description:
        "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kursus Anda yang akan merasa konten kursus Anda bermanfaat. Ini akan membantu Anda menarik peserta yang tepat ke kursus Anda.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
  ],
  curriculum: [],
  groupOfQuestion: [],
  schedule: [],
  interactionLink: [],
  skillTest: {},
  setting: [
    {
      name: "Peserta diizinkan untuk mempercepat video pembelajaran",
      value: false,
    },
    {
      name: "Peserta dapat memilih mengikuti materi yang dia sukai tanpa harus mengikuti semua materi",
      value: false,
    },
    { name: "Option 3", value: false },
    { name: "Option 4", value: false },
  ],
  homepage: {
    title: "",
    description: "",
    tools: [],
    language: "Bahasa Indonesia",
    level: "Expert",
    category: "IT dan Pengembangan",
    mainLesson: "",
    thumbnail: {},
    intro: {},
    instructor: "",
  },
  pricing: { price: "", profit: 0.6 },
  finished: [],
  deleteFiles: [],
};

export default function newCourseReducer(
  state = initialNewcourseReducer,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_NEW_COURSE:
      return payload;
    case SET_COURSE_TITLE:
      return { ...state, courseTitle: payload };
    case SET_COURSE_COMPETENCE:
      return { ...state, competenceUnit: payload };
    case SET_COURSE_COMPETENCE_TITLE:
      return { ...state, competenceTitle: payload };
    case SET_COURSE_CATEGORY:
      return { ...state, courseCategory: payload };
    case SET_COURSE_SCHEDULE:
      return { ...state, schedule: payload };
    case SET_PARTICIPANT_TYPE:
      return { ...state, participantType: payload };
    case SET_COURSE_TYPE:
      return { ...state, classType: payload };
    case SET_TARGET_PARTICIPANTS:
      return { ...state, targetParticipants: payload };
    case SET_CURRICULUM:
      return { ...state, curriculum: payload };
    case SET_GROUP_OF_QUESTION:
      return { ...state, groupOfQuestion: payload };
    case SET_INTERACTION_LINK:
      return { ...state, interactionLink: payload };
    case SET_SKILL_TEST:
      return { ...state, skillTest: payload };
    case SET_COURSE_SETTING:
      return { ...state, setting: payload };
    case SET_COURSE_HOMPAGE:
      return { ...state, homepage: payload };
    case SET_COURSE_PRICING:
      return { ...state, pricing: payload };
    case SET_COURSE_DISCOUNT:
      return { ...state, discount: payload };
    case SET_FINISHED_NAV:
      const targetIndex = state.finished.findIndex((el) => el === payload);
      if (targetIndex !== -1) return state;
      state.finished.push(payload);
      return state;
    case SET_DELETE_NEW_COURSE_FILES:
      if (!state.deleteFiles) state.deleteFiles = [];
      state.deleteFiles.push(payload);
      return state;
    default:
      return state;
  }
}
