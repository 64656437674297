import { useState } from "react";
import Keamanan from "../../components/dependent/instructor-profile/Keamanan";
import Pembayaran from "../../components/dependent/instructor-profile/Pembayaran";
import ProfileTab from "../../components/dependent/instructor-profile/ProfileTab";
import Tabs from "../../components/dependent/instructor-profile/Tabs";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";

export default function Profile() {
  const [activeTab, setActiveTab] = useState("Profile");
  return (
    <LayoutDashboard>
      <div className="space-y-8">
        <div className="space-y-3">
          <h4 className="text-2xl font-semibold text-netral-7">
            Pengaturan Akun
          </h4>
          <p className="text-netral-6">
            Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat
          </p>
        </div>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        {activeTab === "Profile" && <ProfileTab />}
        {activeTab === "Keamanan" && <Keamanan/>}
        {activeTab === "Pembayaran" && <Pembayaran />}
      </div>
    </LayoutDashboard>
  );
}

const tabs = ["Profile","Keamanan", "Pembayaran"];
