import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import { Icon } from "../../../../../components/independent/image";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_GALLERY,
} from "../../../../../config/redux/types";
import {
  _getStreamLocalFile,
  _uploadFileNewCourse,
} from "../../../../../config/services/api/course";
import getLink from "../../../../../constants/getLink";

export default function Gallery() {
  const dispatch = useDispatch();
  const { gallery } = useSelector((state) => state.newWebinarReducer);
  const { token } = useSelector((state) => state.authReducer);

  const addImage = async (file) => {
    const result = await _uploadFileNewCourse(file, token);
    if (!result.status) return alert(result.message);
    result.data.storage = "local";
    gallery.push(result.data);
    dispatch({ type: SET_WEBINAR_GALLERY, payload: [...gallery] });
  };

  const deleteImage = (targetIndex) => {
    gallery.splice(targetIndex, 1);
    dispatch({ type: SET_WEBINAR_GALLERY, payload: [...gallery] });
  };

  return (
    <LayoutNewWebinarStep4
      title="Gallery"
      subTitle="Tetapkan judul kursus Anda"
    >
      <div className="space-y-6">
        <p className="text-netral-8">
          Tambahkan jadwal dan link untuk mengadakan webinar
        </p>
        <div className="grid grid-cols-5 gap-6">
          {gallery.map((img, i) => (
            <Image key={i} index={i} file={img} deleteImage={deleteImage} />
          ))}
          <button className="h-[179px] rounded-lg border-2 border-dashed border-netral-6 bg-netral-2 relative flex flex-col items-center justify-center space-y-6">
            <Icon name="add-netral-6" className="w-6 h-6" />
            <p className="text-sm text-netral-9">Tambahkan Gambar</p>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => addImage(e.target.files[0])}
              className="absolute w-full h-full opacity-0 inset-0"
            />
          </button>
        </div>
      </div>
      <ChildComponent data={gallery} />
    </LayoutNewWebinarStep4>
  );
}

const ChildComponent = ({ data }) => {
  // const { userData } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  // data.instructor = userData.name;

  // const isEveryInputFilled = () => {
  //   if (Object.values(data).every((input) => input)) return true;
  //   return false;
  // };
  return (
    <NextOrLaterButton
      active
      // active={isEveryInputFilled()}
      next={() => {
        dispatch({ type: SET_WEBINAR_GALLERY, payload: data });
        dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: "gallery" });
        push(`/new/webinar/${id}/step5/hompage`);
      }}
    />
  );
};

function Image({ index, file, deleteImage }) {
  const { token } = useSelector((state) => state.authReducer);
  const [imageSrc, setImageSrc] = useState("");
  useEffect(() => {
    if (file?.storage === "cloud") {
      setImageSrc(file?.link);
    } else if (file?.storage === "local") {
      _getStreamLocalFile(token, file?.name)
        .then((src) => setImageSrc(src))
        .catch((e) => console.log("error get src", e));
    }
  }, [token, file?.storage, file?.link, file?.name]);

  return (
    <div className="space-y-2">
      <div className="h-[179px] rounded-lg bg-netral-2 relative flex flex-col items-center justify-center space-y-6 overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src={getLink (imageSrc)}
          alt={imageSrc}
        />
      </div>
      <button
        type="button"
        onClick={() => deleteImage(index)}
        className="text-sm text-white w-full py-1 bg-secondary3-500 rounded-[2px]"
      >
        Hapus
      </button>
    </div>
  );
}
