export default function TabsCourse({ activeTab, setActiveTab, tabs }) {
  return (
    <div className="overflow-x-auto pb-2">
      <div className="mt-3 lg:mt-6 flex items-center space-x-[14px] lg:space-x-[57px] pl-4 lg:px-3 w-[500px] lg:w-auto">
        {tabs?.map((tab, i) => (
          <button
            key={i}
            onClick={() => setActiveTab(tab.text)}
            className={`${
              tab.text === activeTab
                ? "text-secondary-button-600"
                : "text-netral-6 border-transparent"
            } font-semibold text-xs lg:text-lg lg:leading-[28px] relative pb-2`}
          >
            <span>{tab.text}</span>
            <div
              className={`${
                tab.text === activeTab
                  ? "border-secondary-button-600"
                  : "border-transparent"
              } absolute left-0 bottom-0 w-full h-0 border-2 rounded-full`}
            ></div>
          </button>
        ))}
      </div>
    </div>
  );
}
