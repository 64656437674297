import React from "react";
import { Icon } from "../../independent/image";
import { useHistory } from "react-router-dom";

export default function BackButton({onClick}) {
  const { push } = useHistory();

  return (
    <div
      className="flex gap-1 items-center  cursor-pointer"
      onClick={onClick}
    >
      <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
      <span
        style={{
          background:
            "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
        className="cursor-pointer text-[1rem] leading-[20px] font-bold"
      >
        Kembali
      </span>
    </div>
  );
}
