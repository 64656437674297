import { Icon } from "../../../independent/image";

export default function VerifiedPhoneNumber() {
  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center h-12">
        <Icon name="check circle-primary-500" className="w-[25px] h-[25px]" />
      </div>
      <p className="text-netral-10">Nomor handphone terverifikasi</p>
    </div>
  );
}
