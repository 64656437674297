import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../../components/independent/button/button-primary";
import { Icon, Illustration } from "../../components/independent/image";
import Modal from "../../components/independent/modal";
import ModalEmailSent from "../../components/independent/modal/modal-email-sent";
import {
  _register,
  _updateProfile,
  _uploadFile,
  _uploadPhotoProfileToTemp,
} from "../../config/services/api/auth";

export const RegisterInstructorContext = createContext();

export default function RegisterInstructor({ children }) {
  const { push } = useHistory();
  const { isLogin, userData, token } = useSelector(
    (state) => state.authReducer
  );
  const [modal, setModal] = useState("");
  const [profile, setProfile] = useState({
    role: "Instructor",
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone_number: "",
    date_of_birth: "",
    domicile: "",
    headline: "",
    profile_picture: { name: "", link: "", type: "", file: null },
    npwp_number: "",
    npwp_name: "",
    npwp_address: "",
    npwp_picture: { name: "", link: "", type: "", file: null },
    tax_status: "PKP",
    bank_name: "Bank Mandiri",
    bank_city: "",
    bank_branch: "",
    bank_account: "",
    account_number: "",
    bio: "",
  });

  const [loading, setLoading] = useState(false);

  const setProfileInput = (e) =>
    setProfile({ ...profile, [e.target.name]: e.target.value });

  const setProfileInput2 = (key, value) =>
    setProfile({ ...profile, [key]: value });

  const setAndPreviewImage = (key, file) => {
    const link = URL.createObjectURL(file);
    setProfile({ ...profile, [key]: { file, link } });
  };

  const updateProfile = async () => {
    setLoading(true);
    try {
      if (profile.profile_picture.file) {
        const res = await _uploadPhotoProfileToTemp(
          token,
          profile.profile_picture.file
        );
        if (!res.status) return console.log("failed upload file", res);
        profile.profile_picture = res.data;
      }

      if (profile.npwp_picture.file) {
        const res = await _uploadPhotoProfileToTemp(
          token,
          profile.npwp_picture.file
        );
        if (!res.status) return console.log("failed upload file", res);
        profile.npwp_picture = res.data;
      }

      const res = await _updateProfile(token, {
        ...profile,
        role: "Instructor",
      });

      if (!res.status) return console.log("error update profile", res);

      setModal("success");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const register = async () => {
    setLoading(true);
    try {
      const uploadProfilePicture = await _uploadFile(
        profile.profile_picture.file
      );

      if (!uploadProfilePicture.status)
        return console.log(
          "gagal upload profile picture",
          uploadProfilePicture
        );

      profile.profile_picture = uploadProfilePicture.data;

      const uploadNpwpPicture = await _uploadFile(profile.npwp_picture.file);

      if (!uploadNpwpPicture.status)
        return console.log("gagal upload profile picture", uploadNpwpPicture);

      profile.npwp_picture = uploadNpwpPicture.data;

      const {
        name,
        email,
        password,
        role,
        confirm_password,
        phone_number,
        date_of_birth,
        domicile,
        headline,
        profile_picture,
        npwp_number,
        npwp_name,
        npwp_address,
        npwp_picture,
        tax_status,
        bank_name,
        bank_city,
        bank_branch,
        bank_account,
        account_number,
        bio,
      } = profile;

      const res = await _register(
        name,
        email,
        password,
        role,
        confirm_password,
        phone_number,
        date_of_birth,
        domicile,
        headline,
        profile_picture,
        npwp_number,
        npwp_name,
        npwp_address,
        npwp_picture,
        tax_status,
        bank_name,
        bank_city,
        bank_branch,
        bank_account,
        account_number,
        bio
      );

      if (!res.status) return console.log("cannot register", res);

      setModal("email verify");
    } catch (error) {
      console.log("error register", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isLogin) return;
    setProfile((profile) => ({ ...profile, ...userData }));
  }, [isLogin, userData]);

  return (
    <RegisterInstructorContext.Provider
      value={{
        profile,
        setProfileInput,
        setAndPreviewImage,
        setProfileInput2,
        register,
        updateProfile,
        loading,
      }}
    >
      <Modal modal={modal}>
        {modal === "email verify" && (
          <ModalEmailSent
            setModal={setModal}
            loading={loading}
            // register={register}
          />
        )}
        {modal === "success" && (
          <div className="fixed top-0 left-0 h-screen w-full bg-netral-10/50  flex items-center justify-center">
            <div className="bg-white w-[438px] rounded-lg">
              <div className="flex justify-end p-4">
                <button type="button" onClick={() => setModal("")}>
                  <Icon name="close-netral-10" className="w-6 h-6" />
                </button>
              </div>
              <div className="p-6 w-full h-full">
                <Illustration
                  name="Success"
                  className="w-[130px] h-[130px]  mx-auto"
                />
                <h4 className="text-xl font-semibold text-netral-10 mt-[23px] text-center">
                  Berhasil jadi Instruktur
                </h4>
                <p className="text-sm text-netral-9 mt-6 text-center">
                  Anda sekarang telah menjadi salah satu Instruktur di Skills.id
                </p>
                <p className="text-sm text-netral-9 mt-2 text-center">
                  Ayo mulai membuat kelas belajar
                </p>
                <div className="w-[115px] mx-auto">
                  <ButtonPrimary onClick={() => push("/instructor")}>
                    Buat Kursus
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
      {children}
    </RegisterInstructorContext.Provider>
  );
}
