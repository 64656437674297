export default function AlertTraditional({ status, className, children }) {
  return (
    <div className={className}>
      <div
        className={`${
          status ? "border-green-500 bg-green-100" : "border-red-500 bg-red-100"
        } py-[13px] px-4 border rounded flex items-center space-x-4`}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.8332 7.49996H9.1665V5.83329H10.8332V7.49996ZM10.8332 14.1666H9.1665V9.16663H10.8332V14.1666ZM9.99984 1.66663C8.90549 1.66663 7.82186 1.88217 6.81081 2.30096C5.79976 2.71975 4.8811 3.33358 4.10728 4.1074C2.54448 5.67021 1.6665 7.78982 1.6665 9.99996C1.6665 12.2101 2.54448 14.3297 4.10728 15.8925C4.8811 16.6663 5.79976 17.2802 6.81081 17.699C7.82186 18.1177 8.90549 18.3333 9.99984 18.3333C12.21 18.3333 14.3296 17.4553 15.8924 15.8925C17.4552 14.3297 18.3332 12.2101 18.3332 9.99996C18.3332 8.90561 18.1176 7.82198 17.6988 6.81093C17.28 5.79988 16.6662 4.88122 15.8924 4.1074C15.1186 3.33358 14.1999 2.71975 13.1889 2.30096C12.1778 1.88217 11.0942 1.66663 9.99984 1.66663Z"
            fill={status ? "rgb(20 83 45)" : "rgb(127 29 29)"}
          />
        </svg>
        <p
          className={`${
            status ? "text-green-900" : "text-red-900"
          } text-xs leading-[18px]`}
        >
          {children}
        </p>
      </div>
    </div>
  );
}
