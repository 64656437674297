import { Icon } from "../../../independent/image";

export default function Title() {
  return (
    <div className="space-y-3 mt-10 pb-3 border-b border-b-netral-5">
      <h3 className="text-2xl text-primary-900 font-semibold">
        Semua Kategory
      </h3>
      <p className="text-primary-900">Most purchased courses</p>
      <div className="flex items-center space-x-4">
        <Icon name="Group-netral-8" />
        <p className="text-netral-7">20.000+ Peserta</p>
      </div>
    </div>
  );
}
