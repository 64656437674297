import React from "react";
import { Link } from "react-router-dom";
import { ClipArt, Icon } from "../../image";
import LayoutHome from "../layout-home";

export default function LayoutRegisterInstrucor({ step = 1, children }) {
  return (
    <LayoutHome>
      <div className="min-h-screen mt-[80px]">
        <div className="pl-[36px] pt-[36px]">
          <Link to="/become-instructor" className="flex items-center space-x-2">
            <Icon name="arrow-back-secondary-button600" className="w-4 h-4" />
            <span className="text-secondary-button-600 font-semibold">
              Kembali
            </span>
          </Link>
        </div>
        <div className="px-[135px] mt-7 space-y-2 flex flex-col justify-center items-center">
          <ClipArt className="w-full" name="Polygon 5" />
          <h4 className="text-primary-500 text-xl font-semibold">Instruktur</h4>
          <p className="text-netral-8">Registration</p>
          <ClipArt className="w-full" name="Polygon 5" />
        </div>
        <div className="px-[135px] mt-8 space-y-8">
          <div className="flex justify-center items-center space-x-8">
            {steps.map((el, i) => (
              <div
                key={i}
                className={`${
                  i + 1 === step
                    ? "border-b-primary-500"
                    : "border-b-transparent"
                } py-2 border-b-4 space-y-2`}
              >
                <h4
                  className={`${
                    i + 1 === step ? "text-primary-500" : "text-netral-4"
                  } text-xl font-semibold`}
                >
                  {el.title}
                </h4>
                <p
                  className={`${
                    i + 1 === step ? "text-netral-6" : "text-netral-4"
                  } font-semibold`}
                >
                  {el.step}
                </p>
              </div>
            ))}
          </div>
          {children}
        </div>
      </div>
    </LayoutHome>
  );
}

const steps = [
  {
    title: "Buat Akun Instruktur",
    step: "Langkah Pertama",
  },
  {
    title: "Informasi Detail",
    step: "Langkah Kedua",
  },
];
