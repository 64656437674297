import { APIBaseURL } from "../../environment";

export const _register = (
  name,
  email,
  phone_number,
  password,
  role = "Student",
  confirm_password,
  date_of_birth,
  domicile,
  headline,
  profile_picture,
  npwp_number,
  npwp_name,
  npwp_address,
  npwp_picture,
  tax_status,
  bank_name,
  bank_city,
  bank_branch,
  bank_account,
  account_number,
  bio
) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    role,
    name,
    email,
    phoneNumber: phone_number,
    password,
    confirm_password,
    date_of_birth,
    domicile,
    headline,
    profile_picture,
    npwp_number,
    npwp_name,
    npwp_address,
    npwp_picture,
    tax_status,
    bank_name,
    bank_city,
    bank_branch,
    bank_account,
    account_number,
    bio,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/register`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _login = (email, password) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("email_or_phonenumber", email);
  urlencoded.append("password", password);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/login`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _verficationOTP = (otpNumber, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("otp", `${otpNumber}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/verifyPhoneNumber/${token}`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _switchRole = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/switch`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _getProfile = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/user/profile`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _updateProfile = (token, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  for (const key in data) {
    if (!data[key]) {
      delete data[key];
    }
  }

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/user/profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _uploadPhotoProfileToTemp = (token, file) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/user/uploadProfilePictureToTMP`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _forgotPassword = (emailOrPhonenumber) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("email_or_phoneNumber", emailOrPhonenumber);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/forgotPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _resetPassword = (token, password, confirmPassword) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("password", password);
  urlencoded.append("confirmPassword", confirmPassword);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/resetPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _verifyOtpForgotPassword = (otp, phoneNumber) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("otp", otp);
  urlencoded.append("phoneNumber", phoneNumber);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/verifyOtpForgotPassword`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _uploadFile = (file) => {
  const formdata = new FormData();
  formdata.append("file", file);

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/auth/uploadFile`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
