import React from "react";
import PromotionCode from "../promotion-code";
import VirtualAccountInfo from "../virtual-account-info";

export default function PaymentMethod({ setPayment, payment }) {
  return (
    <>
      <div className="space-y-4 lg:space-y-6">
        <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
          Metode Pembayaran
        </h5>
        {/* <div className="space-y-2 lg:space-y-4">
          <div className="p-[2px] flex items-center space-x-2 lg:space-x-[10px]">
            <input
              onChange={(e) => setPayment(e.target.id)}
              id="virtual-account"
              name="payment"
              type="radio"
              defaultChecked
              className="w-6 h-6 lg:w-8 lg:h-8 accent-primary-600"
            />
            <label
              htmlFor="virtual-account"
              className="text-[#616161] text-sm lg:text-base"
            >
              Permata Bank VA (verifikasi otomatis)
            </label>
          </div>
          {payment === "virtual-account" && <VirtualAccountInfo />}
        </div> */}
        <div className="space-y-2 lg:space-y-4">
          <div className="p-[2px] flex items-center space-x-2 lg:space-x-[10px]">
            <input
              onChange={(e) => setPayment(e.target.id)}
              id="bank"
              name="payment"
              type="radio"
              className="w-6 h-6 lg:w-8 lg:h-8 accent-primary-600"
            />
            <label
              htmlFor="bank"
              className="text-[#616161] text-sm lg:text-base"
            >
              Bank transfer BCA (verifikasi manual)
            </label>
          </div>
          {payment === "bank" && <VirtualAccountInfo />}
        </div>
      </div>
      {payment === "virtual-account" && <PromotionCode />}
    </>
  );
}
