import { APIBaseURL } from "../config/environment";

const getLink = (link) => {
  // if (link) {
  //   if (link.includes("https://skillsrevamp.com")) {
  //     return link;
  //   } else {
  //     return `${APIBaseURL}${link}`;
  //   }
  // }

  if (link) {
    if (link.includes("https://skills.id")) {
      return link;
    } else {
      return `${APIBaseURL}${link}`;
    }
  }
};

export default getLink;
