import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Logo } from "../../image";
import MobileHeader from "../header/MobileHeader";
import MobileHeaderLearn from "../header/MobileHeaderLearn";

export default function HeaderV2({ isBlue, className, children, ...etc }) {
  const { pathname } = useLocation();
  const bg = !isBlue ? "bg-netral-1" : "bg-primary-900 lg:bg-primary-900";
  const color = isBlue ? "text-netral-1" : "text-[#26255B]";

  const isLearnPage = pathname.split("/")[1] === "learn";
  const isStudentPage = pathname.split("/")[1] === "student";

  const hideMobileHeader = () => {
    if (isLearnPage) return true;
    if (isStudentPage) return true;
    return false;
  };

  return (
    <header className={`${className} w-full z-[1000] sticky top-0`}>
      <div
        style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)" }}
        className={`${bg}`}
      >
        <div className="hidden h-[72px] lg:h-[82px] lg:flex justify-between items-center px-4 lg:px-8 header space-x-10">
          <Link to="/" className="flex items-center space-x-3">
            <Logo name="skills-id" className="w-10 h-[50px] object-contain" />
            <h4 className={`text-xl ${color}`}>skills.id</h4>
          </Link>
          <div className="flex items-center">{children}</div>
        </div>
      </div>
      {(!hideMobileHeader() || isStudentPage) && (
        <MobileHeader isBlue={isBlue} />
      )}
      {isLearnPage && <MobileHeaderLearn {...etc} />}
      {/* {isStudentPage && <MobileHeaderStudent {...etc} />} */}
    </header>
  );
}
