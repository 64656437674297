export default function LoadingSyncModal() {
  return (
    <div className="bg-[#FFF] p-6 flex flex-col items-center">
      <img
        src="/assets/gif/loading.gif"
        alt="loading"
        className="w-[106px] h-[114px]"
      />
      <div className="text-center space-y-2 w-[381px]">
        <p className=" font-medium text-xl">
          Proses Penyimpanan Sedang Berlangsung
        </p>
        <p className=" font-normal text-sm text-[#404040] ">
          Mohon tunggu proses Penyimpanan hingga selesai, jangan tutup atau muat
          ulang halaman ini.
        </p>
      </div>
    </div>
  );
}
