import ButtonPrimary from "../../button/button-primary";
import { Icon } from "../../image";

export default function SuccessSyncModal({ handleClose }) {
  return (
    <div className="bg-[#FFF] p-6 flex flex-col items-center gap-[13px]">
      <Icon name="tick-circle" className="w-[104px] h-[104px]" />
      <div className="space-y-[8px] text-center w-[381px]">
        <p className="font-medium text-xl">Penyimpanan Berhasil</p>
        <p className="font-normal text-sm">Proses Penyimpanan telah selesai.</p>
      </div>
      <div className="w-[112px] -mt-8" onClick={handleClose}>
        <ButtonPrimary>Tutup</ButtonPrimary>
      </div>
    </div>
  );
}
