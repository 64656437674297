import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlogCard from "../../components/independent/card/card-blog";
import BlogCard2 from "../../components/independent/card/card-blog2";
import LayoutHome from "../../components/independent/layout/layout-home";
import { _getAllBlog, _getLatestBlog } from "../../config/services/api/blog";
import Pagination from "../../components/independent/new-pagination";

export default function BlogPage() {
  const { token } = useSelector((state) => state.authReducer);
  const [dataBlog, setDataBlog] = useState({
    status: true,
    totalData: 1,
    totalPage: 1,
    result: [],
  });
  const [latestBlog, setLatestBlog] = useState({
    status: true,
    result: [],
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    _getAllBlog(token, `page_number=${page}&page_size=9`)
      .then((res) => {
        if (res.status) {
          if (res.result.length > 0) {
            setDataBlog(res);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, token]);

  useEffect(() => {
    _getLatestBlog(token)
      .then((res) => {
        if (res.status) {
          if (res.result.length < 4) {
            setDataBlog(res);
          } else {
            setLatestBlog(res);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <LayoutHome>
      <div className="pt-[82px] pb-[112px] lg:px-[6.5rem] px-2">
        <div className="w-full flex justify-center mt-[16px] lg:mt-[32px] mb-[24px] lg:mb-[60px]">
          <p className="text-primary-900 font-extrabold text-[40px]">
            Blog Kami
          </p>
        </div>

        <div className={`${latestBlog.result.length > 0 ? "block" : "hidden"}`}>
          <div className="mb-[24px]">
            <p className="font-extrabold text-xl text-netral-10">
              Postingan Blog Terbaru
            </p>
          </div>
          <div className="space-y-[16px] lg:grid lg:grid-cols-2 lg:gap-x-[27px]">
            {latestBlog.result.length > 0 && (
              <div>
                <BlogCard data={latestBlog.result[0]} imageHeight="h-[295px]" />
              </div>
            )}
            <div className="lg:space-y-[8px] lg:block flex gap-x-[8px] overflow-scroll">
              {latestBlog.result.slice(1).map((el, idx) => (
                <div key={idx}>
                  <div className="hidden lg:block">
                    <BlogCard2 data={el} />
                  </div>
                  <div className="lg:hidden w-[185px]">
                    <BlogCard data={el} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className={`mt-[32px] lg:mt-[50px] ${
            dataBlog.result.length > 0 ? "block" : "hidden"
          }`}
        >
          <div className="mb-[24px]">
            <p className="font-extrabold text-xl text-netral-10">
              Semua Postingan
            </p>
          </div>
          <div className="w-full grid grid-cols-2 gap-x-2 gap-y-[16px] lg:gap-y-[32px] lg:gap-x-6 lg:grid-cols-3">
            {dataBlog.result.map((el, idx) => (
              <div key={idx} className="w-full">
                <BlogCard data={el} />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-[80px]">
          <Pagination
            total={dataBlog.totalData || 1}
            totalPage={dataBlog.totalPage || 1}
            selectedPage={page}
            setSelectedPage={setPage}
            siblingCount={1}
          />
        </div>
      </div>
    </LayoutHome>
  );
}
