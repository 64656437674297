import ButtonPrimary from "../../../independent/button/button-primary";
import { Illustration, Logo } from "../../../independent/image";

export default function Info() {
  return (
    <section
      style={{ backgroundImage: "url(/assets/clip-art/curly-line.png)" }}
      className="bg-no-repeat bg-left-bottom"
    >
      <div className="mx-auto my-8 lg:my-[128px]  space-x-4 px-4 lg:px-[135px]">
        <div className="w-full flex items-center justify-center">
          <Illustration name="prakerja" className="w-1/2 h-1/2" />
        </div>
        <div className="w-full">
          <div className="space-y-2">
            <Logo
              name="prakerja3"
              className="w-[81.38px] h-[24.96px] lg:w-[100.99px] lg:h-[31.68px]"
            />
            <h4 className="text-sm lg:text-xl font-semibold">
              Apa itu Prakerja ?
            </h4>
            <p className="text-2xs lg:text-base">
              Program Kartu Prakerja adalah program pengembangan kompetensi
              kerja dan kewirausahaan yang ditujukan untuk pencari kerja,
              pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau
              pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk
              pelaku usaha mikro dan kecil.{" "}
            </p>
          </div>
          <ol className="mt-1 lg:mt-6 space-y-1 lg:space-y-6 text-netral-9 text-2xs lg:text-base">
            <li>1. WNI (Warga Negara Indonesia) berusia 18 tahun ke atas.</li>
            <li>2. Tidak sedang menempuh pendidikan formal.</li>
            <li>
              3. Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau
              pekerja/buruh yang membutuhkan peningkatan kompetensi kerja,
              seperti pekerja/buruh yang dirumahkan dan pekerja bukan penerima
              upah, termasuk pelaku usaha mikro dan kecil.
            </li>
            <li>
              4. Bukan penerima bantuan sosial lainnya selama pandemi Covid-19.
            </li>
            <li>
              5. Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, ASN, Prajurit
              TNI, Anggota Polri, Kepala Desa, dan perangkat desa dan
              Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD.
            </li>
            <li>
              6. Maksimal 2 NIK (Nomor Induk Kependudukan) dalam 1 KK (Kartu
              Keluarga) yang menjadi Penerima Kartu Prakerja.
            </li>
            <li>
              7. Jika kamu lolos menjadi Penerima Kartu Prakerja, kamu akan
              mendapat bantuan pelatihan sebesar Rp3.500.000 (tiga juta lima
              ratus ribu Rupiah)
            </li>
            <li>
              8. Setelah menyelesaikan pelatihan, penerima Kartu Prakerja juga
              akan memperoleh insentif.
            </li>
          </ol>
          <p className="text-2xs lg:text-base mt-6">
            Insentif terdiri dari 2 (dua) jenis :
          </p>
          <ul className="mt-1 lg:mt-3 space-y-1 lg:space-y-6 text-netral-9 text-2xs lg:text-base">
            <li>
              - Insentif biaya mencari kerja sebanyak 1 (satu) kali sebesar
              Rp600.000 (enam ratus ribu Rupiah)
            </li>
            <li>
              - Insentif pengisian survei evaluasi sebesar Rp50.000 (lima puluh
              ribu Rupiah) yang diberikan paling banyak 2 (dua) kali.
            </li>
          </ul>
          <ButtonPrimary width="w-auto">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://dashboard.prakerja.go.id/daftar"
            >
              Daftar Sekarang
            </a>
          </ButtonPrimary>
        </div>
      </div>
    </section>
  );
}
