import { Link } from "react-router-dom";
import useScroll from "../../../../hooks/lib/useScroll";
import { Logo } from "../../image";
import MobileHeader from "../header/MobileHeader";

export default function HeaderTransparent({ className, children }) {
  const { scrollY } = useScroll();

  return (
    <header className={`${className} w-full z-10`}>
      <div
        // style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)" }}
        className={`${
          scrollY < 500 ? "bg-transparent" : "bg-white"
        } h-[72px] lg:h-[82px] hidden lg:flex justify-between items-center px-4 lg:px-8 header duration-700`}
      >
        <Link to="/" className="flex items-center space-x-3">
          <Logo name="skills-id" className="w-10 h-[50px] object-contain" />
          <h4 className="text-xl text-[#26255B]">skills.id</h4>
        </Link>
        <div className="flex items-center">{children}</div>
      </div>
      <MobileHeader isBlue />
    </header>
  );
}
