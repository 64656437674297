// import { Logo } from "../../../independent/image";

import { Logo } from "../../../independent/image";

export default function Hero() {
  return (
    <section className="flex lg:h-[494px] lg:flex-row flex-col w-full">
      <img
        src="/assets/image/Group 2940.png"
        alt="bg-hero"
        className="lg:w-[55%] w-full h-auto object-cover"
      />
      <div className="h-full lg:w-[45%] w-full flex flex-col">
        <div className="w-full h-1/2"></div>
        <div className="w-full h-1/2 ">
          <div className="flex items-center space-x-6 lg:flex-row flex-col">
            <Logo name="skills.id" className="w-auto h-8 object-contain" />
            <Logo name="image 15" className="w-auto h-8 object-contain" />
          </div>
          <div className="lg:block flex items-center  space-x-6 lg:flex-row flex-col">
            <h4 className="mt-3 font-semibold text-netral-10 text-2xl lg:w-[394px] lg:text-left text-center">
              Mau tukar voucher Prakerja ?
            </h4>
            <button className="mt-6 font-semibold text-netral-1 px-4 py-2 rounded-full bg-primary-500">
              Lihat Video
            </button>
          </div>
        </div>
      </div>
      {/* <div className="lg:hidden flex items-center justify-between">
        <Logo name="Logo 1 solid" className="w-[81px] h-[24.13px]" />
        <Logo name="prakerja" className="w-[72.56px] h-[23.17px]" />
      </div>
      <div className="w-full h-full flex items-end justify-end">
        <div className="space-y-2 lg:space-y-4">
          <h1 className="text-white font-semibold text-sm lg:text-3xl">
            Mau tukar voucher Prakerja ?
          </h1>
          <button
            style={{ filter: "drop-shadow(2px 8px 16px rgba(0, 0, 0, 0.32))" }}
            className="text-2xs lg:text-sm font-semibold p-1 lg:py-2 lg:px-4 rounded-full bg-primary-button-300 border border-transparent interactive-button-color"
          >
            Lihat Video
          </button>
        </div>
      </div> */}
    </section>
  );
}
