import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { register, verificationOTP } from "../config/redux/actions/authAction";
import {
  CHANGE_REGISTER_STATUS,
  SET_REGISTER_MESSAGE,
} from "../config/redux/types";
import LayoutAuth from "../components/independent/layout/layout-auth";
// import InstructoOrStudentTab from "../components/dependent/auth/instructor-or-student-tab";
import AlertTraditional from "../components/independent/alert/alert-traditional";
import ButtonPrimary from "../components/independent/button/button-primary";
import InputTextLabel from "../components/independent/input/input-text-label";
// import SocialMediaButton from "../components/dependent/auth/social-media-button";
import RegisterOrLoginButton from "../components/dependent/auth/register-or-login-button";
import Modal from "../components/independent/modal";
import ModalOtp from "../components/independent/modal/modal-otp";
import ModalEmailSent from "../components/independent/modal/modal-email-sent";

const Register = () => {
  const { push } = useHistory();

  const dispatch = useDispatch();
  const { registerLoading, registerMessage, registerStatus } = useSelector(
    (state) => state.authReducer
  );

  const [token, setToken] = useState("");
  const [modal, setModal] = useState("");
  const [form, setform] = useState({
    name: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm_password: "",
  });

  const onChange = (e) =>
    setform((crr) => ({ ...crr, [e.target.name]: e.target.value }));

  const registerFunc = useCallback(() => {
    const { name, email, phonenumber, password, confirm_password } = form;

    dispatch(
      register(
        {
          name,
          email,
          phonenumber,
          confirm_password,
          password,
          role: "Student",
        },
        (token) => {
          if (token) setToken(token);
          setModal("otp");
        }
      )
    );
  }, [dispatch, form]);

  const onSubmit = (e) => {
    if (e) e.preventDefault();
    if (registerLoading) return;
    registerFunc();
  };

  const verifyOtp = (otpNumber) => {
    dispatch(
      verificationOTP({ otpNumber, token }, () => {
        setModal("");
        setTimeout(() => {
          push("/login");
        }, 1500);
      })
    );
  };

  useEffect(() => {
    return () => {
      setform({ email_or_phonenumber: "", password: "", confirm_password: "" });
      dispatch({ type: SET_REGISTER_MESSAGE, payload: "" });
      dispatch({ type: CHANGE_REGISTER_STATUS, payload: false });
    };
  }, [dispatch]);

  return (
    <>
      <Modal modal={modal}>
        {modal === "email verify" && (
          <ModalEmailSent
            setModal={setModal}
            loading={registerLoading}
            register={registerFunc}
          />
        )}
        {modal === "otp" && (
          <ModalOtp
            setModal={setModal}
            phoneNumber={form.phonenumber}
            verifyOtp={verifyOtp}
          />
        )}
      </Modal>
      <LayoutAuth>
        {registerMessage && (
          <AlertTraditional className="mb-4" status={registerStatus}>
            {registerMessage}
          </AlertTraditional>
        )}
        {/* <InstructoOrStudentTab /> */}
        <form onSubmit={(e) => onSubmit(e)} className="w-full mb-6">
          <h3 className="text-lg lg:text-xl font-semibold">Silahkan daftar</h3>
          <div className="mt-6 space-y-3 lg:space-y-2 w-full">
            <InputTextLabel
              id="name"
              name="name"
              text="Nama"
              placeholder="Masukkan nama sesuai KTP"
              onChange={(e) => onChange(e)}
              value={form.name}
            />
            <InputTextLabel
              id="email_or_phonenumber"
              name="email"
              text="Email"
              placeholder="Masukkan Email Anda"
              onChange={(e) => onChange(e)}
              value={form.email}
            />
            <InputTextLabel
              id="email_or_phonenumber"
              name="phonenumber"
              text="Nomor Whatsapp"
              placeholder="Masukkan Nomor Whatsapp Anda"
              onChange={(e) => onChange(e)}
              value={form.phonenumber}
            />
            <InputTextLabel
              isPassword
              id="password"
              name="password"
              text="Password"
              placeholder="Masukkan Password Anda"
              value={form.password}
              onChange={(e) => onChange(e)}
            />
            <p className="text-red-500">
              * Minimal 8 digit, huruf besar dan angka
            </p>
            <InputTextLabel
              isPassword
              id="confirm_password"
              name="confirm_password"
              text="Confirm password"
              placeholder="Confirm password"
              value={form.confirm_password}
              onChange={(e) => onChange(e)}
            />
            <p></p>
          </div>
          <ButtonPrimary type="submit" disable={registerLoading}>
            {!registerLoading ? "Daftar" : "Loading..."}
          </ButtonPrimary>
          {/* <p className="text-netral-7 my-6">Atau daftar dengan</p>
          <SocialMediaButton/> */}
          <RegisterOrLoginButton />
        </form>
      </LayoutAuth>
    </>
  );
};

export default Register;
