import { APIBaseURL } from "../../environment";

export const _deleteItemFromCart = (token, id) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/cart/deleteCartById/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getCart = (token) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/cart/classListById`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _addToCart = (token, id, dataToSend) => {
  const myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(dataToSend);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/cart/addToCart/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
