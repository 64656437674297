import { Icon } from "../../../independent/image";
import PinMaps from "../../../independent/maps";

export default function CourseLocation({ course }) {
  return (
    <div
      style={{ boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.08)" }}
      className="w-full px-6 py-3 space-y-4 rounded-lg"
    >
      <p className="text-netral-8 text-sm">Lokasi Kursus</p>
      {/* export default function PinMaps({ lat, lng, mapHeight, mapWidth }) { */}
      {course?.location?.latitude && course?.location?.longitude ? (
        <PinMaps
          lat={+course.location.latitude}
          lng={+course.location.longitude}
          mapHeight={"250px"}
          mapWidth={"24vw"}
        />
      ) : null}
      <div className="flex space-x-3 items-start">
        {/* name, h, w, className */}
        <Icon h={"20px"} w={"20px"} name={"PIN_POINT"} />
        <p className="text-netral-9 text-xs">{course?.location?.address}</p>
      </div>
    </div>
  );
}
