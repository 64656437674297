/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import FilePreview from "../../../components/dependent/learn-course/file-preview";
import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
import Quiz from "../../../components/dependent/learn-course/quiz";
import Finish from "../../../components/dependent/learn-course/quiz/Finish";
import First from "../../../components/dependent/learn-course/quiz/First";
import PrevOrNextController from "../../../components/dependent/learn-course/quiz/PrevOrNextController";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import {
  postIndividualTest,
  postMaterialProgress,
  postQuizProgress,
} from "../../../config/redux/actions/courseAction";
import getLink from "../../../constants/getLink";
// import WebinarMaterial from "./WebinarMaterial";
import { ClipArt, Icon, Logo } from "../../../components/independent/image";
import PreviewFullScreen from "../../../components/dependent/learn-course/file-preview/PreviewFullScreen";
import getRangeDate from "../../../constants/getRangeDate";
import moment from "moment";
import Modal from "../../../components/independent/modal";
import { _verifyPrakerja } from "../../../config/services/api/courseProgress";
import { SET_ALERT } from "../../../config/redux/types";
const PDFJS = window.pdfjsLib;

const reduceHour = (hour, reduce) => {
  let time = new Date();
  if (hour) {
    const hourArr = hour?.split(":");
    time.setHours(+hourArr[0], +hourArr[1], 0);

    time.setHours(time.getHours() - reduce);
    let formattedTime = time.toTimeString().slice(0, 5);

    return formattedTime;
  }
};

const Material = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { title, courseId } = useParams();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pdf, setPdf] = useState("");
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState("");
  const [width, setWidth] = useState(0);
  const [images, setImages] = useState([]);
  const [height, setHeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const location = useLocation();

  async function showPdf(url) {
    try {
      setPdfRendering(true);
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      // document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  const { token, userData } = useSelector((state) => state.authReducer);

  const {
    enrollment,
    participantProgress,
    classType,
    participantType,
    detailProgress,
    individualTest,
    individualTestInstruction,
  } = useSelector((state) => state.courseReducer);

  const chapterStr = title.split("-")[0];
  const materialStr = title.split("-")[1];
  const chapterNumber = Number(chapterStr.split("chapter")[1]);
  const materialNumber = Number(materialStr?.split("material")[1]);

  const material =
    enrollment.chapters[chapterNumber - 1]?.materials[materialNumber - 1];

  let views = [{ name: "first" }, { name: "finish" }];

  if (material?.question?.length) {
    views = [{ name: "first" }, ...material.question, { name: "finish" }];
  }

  const [activeView, setActiveView] = useState(0);
  const [files, setFiles] = useState(null);

  // handle video
  const [currentTime, setCurrentTime] = useState(0);

  // handle quiz
  const course = useSelector((state) => state.courseReducer);

  const [data, setData] = useState([]);
  const [score, setScore] = useState(0);

  const addQuizProgress = () =>
    dispatch(postQuizProgress(token, courseId, data));

  useEffect(() => {
    setData([]);
  }, [chapterNumber, materialNumber]);

  const handleData = (value, idx) => {
    const index = activeView - 1;

    data[index] = {
      chapterIndex: chapterNumber - 1,
      materialIndex: materialNumber - 1,
      questionIndex: idx,
      participantAnswer: value,
      questionName:
        enrollment.chapters[chapterNumber - 1].materials[materialNumber - 1]
          .question[index].name,
    };

    setData([...data]);
  };

  const someDataUndefined = () => data.some((data) => !data) || !data.length;

  useEffect(() => {
    getLink(material?.file?.link) && showPdf(getLink(material?.file?.link));
  }, [material?.file?.link]);

  // console.log(
  //   getLink(
  //     individualTestInstruction?.find(
  //       (el, idx) => +el.chapterIndex === chapterNumber
  //     )?.link
  //   )
  // );

  useEffect(() => {
    getLink(
      individualTestInstruction?.find(
        (el, idx) => +el.chapterIndex === chapterNumber - 1
      )?.link
    ) &&
      showPdf(
        getLink(
          individualTestInstruction.find(
            (el, idx) => +el.chapterIndex === chapterNumber - 1
          )?.link
        )
      );
  }, [chapterNumber, individualTestInstruction]);

  useEffect(() => {
    if (!participantProgress?.chapters?.length) return;

    const progress = participantProgress.chapters.find(
      (el) =>
        el.chapterIndex === chapterNumber - 1 &&
        el.materialIndex === materialNumber - 1
    );

    if (!progress) return;
    if (progress.type !== "Pilihan Ganda") return;

    if (progress.isCompleted) {
      setActiveView(views.length - 1);
      setScore(progress.score);
    }

    return () => setActiveView(0);
  }, [
    chapterNumber,
    materialNumber,
    participantProgress?.chapters,
    views.length,
  ]);

  useEffect(() => {
    if (
      individualTest?.find((el) => +el.chapterIndex === chapterNumber - 1)
        ?.isCompleted
    ) {
      setFiles({
        ...individualTest?.find((el) => +el.chapterIndex === chapterNumber - 1),
        name: individualTest?.find(
          (el) => +el.chapterIndex === chapterNumber - 1
        ).name,
      });
    } else {
      setFiles();
    }
  }, [chapterNumber, individualTest]);

  const date = getRangeDate(
    moment(course?.learner_schedule?.startDate).format("YYYY-MM-DD"),
    course?.learner_schedule?.endDate
  );

  const materialLength =
    course?.enrollment?.chapters[chapterNumber - 1]?.materials.length;

  const chaptersLength = course?.enrollment?.chapters.length;

  // handle next
  const nextActive = () => {
    // if (userData?.role?.includes("Assesor")) return true;
    if (
      !course?.participantType?.includes("WEBINAR") &&
      material?.type !== "Pilihan Ganda"
    ) {
      if (materialLength === materialNumber) {
        if (
          course.attendances.find((el) => el.day === chapterNumber + 1)
            ?.isPresent ||
          chapterNumber === chaptersLength
        ) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (
      course?.participantType?.includes("WEBINAR") &&
      material?.type === "Pilihan Ganda"
    ) {
      const lastView = activeView === views.length - 1;

      if (materialLength === materialNumber) {
        if (
          moment().format("YYYY-MM-DD") >=
          moment(date[chapterNumber]).format("YYYY-MM-DD")
        ) {
          return lastView && score >= 80;
        } else {
          return false;
        }
      } else {
        return lastView && score >= 80;
      }
    }

    if (material?.type === "Pilihan Ganda") {
      const lastView = activeView === views.length - 1;
      if (materialLength === materialNumber) {
        if (
          moment().format("YYYY-MM-DD") ===
          moment(date[chapterNumber]).format("YYYY-MM-DD")
        ) {
          if (
            moment().format("HH:MM") >
            reduceHour(course?.learner_schedule?.endHour, 1)
          ) {
            return lastView && score >= 80;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (
        moment().format("YYYY-MM-DD") >=
        moment(date[chapterNumber]).format("YYYY-MM-DD")
      ) {
        return lastView && score >= 80;
      } else {
        return false;
      }
    }

    if (course?.participantType?.includes("WEBINAR")) {
      if (
        materialLength + 1 === materialNumber &&
        moment().format("YYYY-MM-DD") >
          moment(date[chapterNumber]).format("YYYY-MM-DD")
      ) {
        return true;
      } else if (
        materialLength + 1 === materialNumber &&
        moment().format("YYYY-MM-DD") ===
          moment(date[chapterNumber]).format("YYYY-MM-DD")
      ) {
        if (
          moment().format("HH:MM") >
          reduceHour(course.learner_schedule.endHour, 1)
        ) {
          return true;
        } else {
          return false;
        }
      } else if (
        materialLength + 1 === materialNumber &&
        moment().format("YYYY-MM-DD") ===
          moment(date[chapterNumber - 1]).format("YYYY-MM-DD")
      ) {
        return false;
      }
    }
    if (material?.type === "video/mp4") {
      const progress = participantProgress?.chapters.find(
        (el) =>
          el.chapterIndex === chapterNumber - 1 &&
          el.materialIndex === materialNumber - 1
      );
      if (progress?.isCompleted) return true;

      return currentTime >= material.file.duration - 3;
      // return true
    } else if (material?.type === "Pilihan Ganda") {
      const lastView = activeView === views.length - 1;
      return lastView && score >= 80;
    }
    return true;
  };

  const prev = () => {
    if (chapterNumber === 1 && materialNumber === 1) {
      push(`/learn/${courseId}/schedule`);
    } else if (chapterNumber !== 1 && materialNumber === 1) {
      push(`/learn/${courseId}/chapter${chapterNumber - 1}-material1`);
    } else {
      push(
        `/learn/${courseId}/chapter${chapterNumber}-material${
          materialNumber - 1
        }`
      );
    }
  };

  const isWebinar = participantType?.includes("WEBINAR");

  const navigate = () => {
    if (isWebinar) {
      if (
        enrollment.chapters.length === chapterNumber &&
        enrollment.chapters[chapterNumber - 1]?.materials.length + 1 ===
          materialNumber
      ) {
        push(`/learn/${courseId}/post-test`);
      } else if (
        !isWebinar &&
        enrollment.chapters.length === chapterNumber &&
        enrollment.chapters[chapterNumber - 1]?.materials.length ===
          materialNumber
      ) {
        push(`/learn/${courseId}/chapter${chapterNumber + 1}-material1`);
      } else if (
        materialNumber ===
        enrollment.chapters[chapterNumber - 1]?.materials.length
      ) {
        push(
          `/learn/${courseId}/chapter${chapterNumber}-material${
            materialNumber + 1
          }`
        );
      } else {
        push(
          `/learn/${courseId}/chapter${chapterNumber}-material${
            materialNumber + 1
          }`
        );
      }
    } else {
      if (
        enrollment.chapters.length === chapterNumber &&
        enrollment.chapters[chapterNumber - 1]?.materials.length ===
          materialNumber
      ) {
        push(`/learn/${courseId}/post-test`);
      } else if (
        materialNumber ===
        enrollment.chapters[chapterNumber - 1]?.materials.length
      ) {
        push(`/learn/${courseId}/chapter${chapterNumber + 1}-material1`);
      } else {
        push(
          `/learn/${courseId}/chapter${chapterNumber}-material${
            materialNumber + 1
          }`
        );
      }
    }
  };

  const next = () => {
    const newData = {
      chapterIndex: chapterNumber - 1,
      date: new Date(Date.now()).toISOString(),
      isCompleted: true,
      materialIndex: materialNumber - 1,
      record: [],
      score: 100,
      type: "",
    };

    if (material.type === "application/pdf") {
      dispatch(
        postMaterialProgress(token, courseId, participantProgress.chapters, {
          ...newData,
          type: "application/pdf",
        })
      );
    }
    if (material.type === "video/mp4") {
      dispatch(
        postMaterialProgress(token, courseId, participantProgress.chapters, {
          ...newData,
          type: "video/mp4",
        })
      );
    }
    navigate();
  };

  const nextIndividualTest = () => {
    // (token, files, chapterIndex, progressId, classId)
    dispatch(
      postIndividualTest(
        token,
        files,
        chapterNumber - 1,
        detailProgress._id,
        courseId
      )
    );
    if (enrollment.chapters.length === chapterNumber) {
      push(`/learn/${courseId}/post-test`);
    } else {
      push(`/learn/${courseId}/chapter${chapterNumber + 1}-material1`);
    }
  };

  // const uploadIndividualTest = () => {
  //   // (token, files, chapterIndex, progressId, classId)
  //   dispatch(
  //     postIndividualTest(
  //       token,
  //       files,
  //       chapterNumber - 1,
  //       detailProgress._id,
  //       courseId
  //     )
  //   );
  // };

  // const prevIndividualTest = () => {
  //   push(
  //     `/learn/${courseId}/chapter${chapterNumber}-material${materialNumber - 1}`
  //   );
  // };

  // if(classType === "Offline Class" || participantType?.includes("WEBINAR")) {
  //   return <WebinarMaterial/>
  // }
  const [isLoading, setLoading] = useState(false);

  const redeem = (e) => {
    e.preventDefault();
    setLoading(true);
    // token, sequence,redeemCode,email,class_id
    _verifyPrakerja(
      token,
      chapterNumber,
      redeemCode,
      userData.email,
      course._id
    )
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        // setOpenModal(false);

        if (res.code) {
          localStorage.setItem(
            "prakerjaPayload",
            JSON.stringify({
              token,
              sequence: chapterNumber,
              redeemCode,
              email: userData.email,
              class_id: course._id,
            })
          );
          localStorage.setItem("callbackTo", location.pathname);
          // setSuccessModal(true);
          if (res.response.url) {
            window.open(res.response.url);
          }
          if (res?.response?.data?.attendance_status === 1) {
            setOpenModal(false);
          }
        } else {
          setOpenModal(false);
          setErrorModal(res.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorModal(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (
      materialNumber === 1 &&
      classType === "Online Class" &&
      !userData?.role?.includes("Assesor")
    ) {
      const data = JSON.parse(localStorage.getItem("prakerjaPayload"));
      if (data) {
        _verifyPrakerja(
          data.token,
          chapterNumber,
          data.redeemCode,
          data.email,
          data.class_id
        )
          .then((res) => {
            // dispatch({
            //   type: SET_ALERT,
            //   payload: { status: res.status, message: res.message },
            // });

            if (res?.response?.data?.attendance_status === 1) {
              setOpenModal(false);
            } else {
              setOpenModal(true);
            }
          })
          .catch((e) => {
            dispatch({
              type: SET_ALERT,
              payload: { status: false, message: e },
            });
            setOpenModal(false);
            setErrorModal(true);
          })
          .finally(() => setLoading(false));
      } else {
        setOpenModal(true);
      }
      // console.log("new");
    }
  }, [chapterNumber, classType, materialNumber]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const dataIndividual = useMemo(() => {
    return individualTestInstruction?.find(
      (el) => +el.chapterIndex === chapterNumber - 1
    );
  }, [chapterNumber, individualTestInstruction]);

  if (
    enrollment.chapters[chapterNumber - 1]?.materials?.length <
      materialNumber &&
    participantType.includes("WEBINAR")
  )
    return (
      <LayoutLearnCourse>
        <div
          style={{
            background:
              "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
          }}
          className={` border border-primary-300 rounded-lg `}
        >
          <FilePreview
            type={dataIndividual?.type}
            storage={material?.file?.storage}
            link={getLink(dataIndividual?.link)}
            setCurrentTime={setCurrentTime}
            pdfRendering={pdfRendering}
            pageRendering={pageRendering}
            images={images}
          />
        </div>
        <PrevOrNextButton
          prevActive={true}
          nextActive={nextActive()}
          prev={prev}
          next={nextIndividualTest}
        />
        <div className="mt-4 mb-8">
          <div className="mb-4 pb-3 border-b border-netral-3 ">
            <p className="text-lg font-medium leading-5">Deskripsi Tugas</p>
          </div>
          <div>
            <p className="text-sm font-normal leading-5 whitespace-pre-wrap">
              {dataIndividual?.description}
            </p>
          </div>
        </div>
        <div>
          <div className="mb-4 pb-3 border-b border-netral-3 ">
            <p className="text-lg font-medium leading-5">Upload File</p>
          </div>
          {!files ? (
            <div className="space-y-[64px]">
              <div className="space-y-[15px]">
                <div className="text-center w-full pt-[20px] pb-[20px] space-y-[5px] rounded-lg border border-dashed border-[#12A89D] bg-netral-1">
                  <Icon
                    name="cloud-computing 1-primary-600"
                    className="w-12 h-12 mx-auto"
                  />
                  <p className="text-sm text-netral-10 font-medium">
                    Drag and Drop File Disini
                  </p>
                  <p className="text-xs text-[#818080] font-light">
                    Mendukung semua jenis file dan video (MP4)
                  </p>
                  <p className="text-xs text-[#818080] font-semibold">Atau</p>
                  <div className="relative">
                    <input
                      // multiple
                      // accept="video/*"
                      type="file"
                      onChange={(e) => {
                        setFiles(...e.target.files);
                        dispatch(
                          postIndividualTest(
                            token,
                            ...e.target.files,
                            chapterNumber - 1,
                            detailProgress._id,
                            courseId
                          )
                        );
                      }}
                      className="absolute top-0 left-0 h-full w-full opacity-0"
                    />
                    <button className="cursor-pointer font-bold text-[#12A89D] text-[13px] px-5 py-[7px] border-[#12A89D] border rounded-[5px]">
                      Browse files
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex border border-netral-5 rounded-lg p-3">
              <div className="w-full flex gap-[10px] items-center">
                <Icon name="Docs-Icon" className="h-[24px] w-[24px] " />
                <div>
                  <p className="text-sm font-medium">{files?.name}</p>
                  <p className="text-xs font-normal">{files?.size}</p>
                </div>
              </div>
              <div>
                <div className="relative cursor-pointer">
                  <input
                    // multiple
                    // accept="video/*"
                    type="file"
                    onChange={(e) => {
                      setFiles(...e.target.files);
                      dispatch(
                        postIndividualTest(
                          token,
                          ...e.target.files,
                          chapterNumber - 1,
                          detailProgress._id,
                          courseId
                        )
                      );
                    }}
                    className="absolute top-0 left-0 h-full w-full opacity-0"
                  />
                  <button
                    type="button"
                    className="p-1 text-[#12A89D] w-[100px] border rounded-md border-[#12A89D]"
                  >
                    Ganti File
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {files?.feedback && (
          <div>
            <div className="mt-8 pb-3 mb-4 border-b-2 border-netral-3">
              <p className="text-netral-9 text-xl font-medium">
                Nilai dan Feedback
              </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-x-4 items-center lg:items-start gap-y-2">
              <div>
                <div className="h-[152px] w-[152px] border-[26px] border-[#CFFAE7] flex items-center justify-center ">
                  <p className="text-[#42CAB4] text-[60px] font-medium">
                    {files?.score}
                  </p>
                </div>
              </div>
              <div>
                <p className="font-medium text-xl text-netral-10 mb-[13px]">
                  Feedback
                </p>
                <p className="font-base font-normal text-netral-9">
                  {files?.feedback}
                </p>
              </div>
            </div>
          </div>
        )}
      </LayoutLearnCourse>
    );

  return (
    <LayoutLearnCourse>
      <div className="bg-netral-1 border border-netral-3 w-full p-[16px] mb-6 -mt-4">
        <div
          className="flex items-center justify-between p-[15px] bg-secondary2-100"
          onClick={() => window.open(course.webinarLink)}
        >
          <div className="flex items-center space-x-3 cursor-pointer">
            <Icon name={"camera"} className="w-[20px] h-[10px]" />
            <p className="break-all">{course.webinarLink}</p>
          </div>
          <div>
            {/* <Icon
                    name={"two_square"}
                    className="w-[16px] h-[16px] cursor-pointer"
                    onClick={() => {
                      copyToClipboard(course.webinarLink);
                    }}
                  /> */}
          </div>
        </div>
      </div>
      {material?.type === "Pilihan Ganda" ? (
        <div
          style={{
            background:
              "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
          }}
          className="min-h-[247px] lg:h-[471px] border border-primary-300 rounded-lg flex items-center relative"
        >
          {views[activeView]?.name === "first" && (
            <First setActiveView={setActiveView} title="Kuis" />
          )}
          {views[activeView]?.name !== "first" &&
            views[activeView]?.name !== "finish" && (
              <>
                <PrevOrNextController
                  activeView={activeView}
                  setActiveView={setActiveView}
                  views={views}
                  addProgress={addQuizProgress}
                  isCompleted={!someDataUndefined()}
                />
                <Quiz
                  activeView={activeView}
                  views={views}
                  handleData={handleData}
                  value={data[activeView - 1]?.participantAnswer || false}
                />
              </>
            )}
          {views[activeView]?.name === "finish" && (
            <Finish
              score={score}
              title="Kuis"
              next={() => {
                setActiveView(0);
                setData([]);
              }}
              buttonText="Ulangi Kuis"
            />
          )}
        </div>
      ) : (
        <>
          {isFullScreen ? (
            <PreviewFullScreen
              type={material?.type}
              storage={material?.file?.storage}
              link={getLink(material?.file?.link)}
              setCurrentTime={setCurrentTime}
              setIsFullScreen={setIsFullScreen}
              pdfRendering={pdfRendering}
              pageRendering={pageRendering}
              images={images}
            />
          ) : (
            <div className="relative">
              <FilePreview
                type={material?.type}
                storage={material?.file?.storage}
                link={getLink(material?.file?.link)}
                setCurrentTime={setCurrentTime}
                pdfRendering={pdfRendering}
                pageRendering={pageRendering}
                images={images}
              />
              <div
                className="absolute z-30 top-5 right-5 bg-netral-3 p-1 shadow-md cursor-pointer"
                onClick={() => setIsFullScreen(true)}
              >
                <Logo name={"fullScreen"} className={"w-[30px] h-[30px]"} />
              </div>
            </div>
          )}
        </>
      )}

      <Modal modal={openModal}>
        <form onSubmit={(e) => redeem(e)}>
          <div className="bg-white w-[300px] lg:w-[485px] min-h-[270px] relative p-[20px]">
            <div className="flex flex-col text-center space-y-2">
              <p className="text-xl font-semibold">Redeem Code</p>
              <p className="text-sm">
                Masukan 12 karakter unit redeem code yang dapat kamu temukan di
                website{" "}
                <a
                  href="https://www.prakerja.go.id/"
                  className="underline text-primary-500"
                >
                  Prakerja.go.id
                </a>
              </p>
              <div className="py-5">
                <input
                  id="voucher"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="block w-full p-3 rounded border border-[#757575]"
                  placeholder="Redeem code"
                  autocomplete="off"
                />
              </div>
              <button
                disabled={!redeemCode}
                type={"submit"}
                className={`${
                  !redeemCode
                    ? "text-netral-6 bg-netral-5 cursor-not-allowed"
                    : "text-white bg-secondary-button-600 interactive-button-color"
                } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
              >
                Redeem sekarang
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal modal={successModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setSuccessModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"Success-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl">Berhasil Tukar Voucher</p>
              <p className="font-normal text-sm">
                Kode Voucher Anda telah berhasil ditukarkan
              </p>
            </div>
            {/* <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => setSuccessModal(false)}
            >
              Lanjut Belajar
            </button> */}
          </div>
        </div>
      </Modal>

      {/* Error redeem Modal */}
      <Modal modal={errorModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">{errorModal}</p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                setOpenModal(true);
                setErrorModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>
      <PrevOrNextButton
        prevActive={true}
        nextActive={nextActive()}
        prev={prev}
        next={next}
      />
    </LayoutLearnCourse>
  );
};

export default Material;
