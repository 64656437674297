import { useEffect, useState } from "react";
import { BigPlayButton, Player } from "video-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  SET_DELETE_NEW_COURSE_FILES,
  SET_DELETE_NEW_WEBINAR_FILES,
  SET_FINISHED_NAV,
  SET_SKILL_TEST,
  SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION,
  SET_WEBINAR_SKILL_TEST,
} from "../../../../../config/redux/types";
import {
  _getStreamLocalFile,
  _uploadFileNewCourse,
} from "../../../../../config/services/api/course";
import getVideoDuration from "../../../../../constants/getVideoDuration";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import { Icon } from "../../../../../components/independent/image";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import getLink from "../../../../../constants/getLink";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import InputTextareaLabel from "../../../../../components/independent/input/input-textarea-label";

const IndividualTestInstructor = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.authReducer);

  const newCourse = useSelector((state) => state.newWebinarReducer);
  
  const file = newCourse.skillTest;
  const [dataToSend, setDataToSend] = useState(
    newCourse.individualTestInstruction
  );

  const [videoSrc, setVideoSrc] = useState("");

  const [loading, setLoading] = useState({ file: false });

  const changeLoading = (key, value) =>
    setLoading((crr) => ({ ...crr, [key]: value }));

  // const uploadFile = async (value) => {
  //   if (value?.type !== "video/mp4") {
  //     setFile(value);
  //     if (file.storage === "cloud")
  //       dispatch({ type: SET_DELETE_NEW_COURSE_FILES, payload: file.name });
  //     changeLoading("file", false);
  //   } else {
  //     changeLoading("file", true);

  //     let data = {};

  //     const duration = await getVideoDuration(value);

  //     if (duration) data = { duration };

  //     try {
  //       const result = await _uploadFileNewCourse(value, token);
  //       if (!result.status) return console.log("something went wrong", result);
  //       data = { ...data, ...result.data };
  //       setFile(data);
  //       // add to deleted file list
  //       if (file.storage === "cloud")
  //         dispatch({ type: SET_DELETE_NEW_COURSE_FILES, payload: file.name });
  //     } catch (error) {
  //       console.log("something went wrong", error);
  //     } finally {
  //       changeLoading("file", false);
  //     }
  //   }
  // };

  const uploadFile = async (key, file, index) => {
    let uploadedData = {};

    const acceptType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf" ||
      file.type === "video/mp4";

    if (!acceptType) return alert("format file tidak didukung");

    changeLoading("file", true);

    if (file?.type === "video/mp4") {
      const duration = await getVideoDuration(file);

      if (duration) uploadedData = { duration };
    }


    try {
      const uploadFileRes = await _uploadFileNewCourse(file, token);

      if (!uploadFileRes.status) alert(uploadFileRes.message);

      uploadedData = { ...uploadedData, ...uploadFileRes.data };

      setDataToSend((prev) =>
        prev.map((el, idx) => (idx === index ? { ...el, ...uploadedData } : el))
      );

      // add to deleted file list
      if (file.storage === "cloud")
        dispatch({
          type: SET_DELETE_NEW_WEBINAR_FILES,
          payload: file.name,
        });
    } catch (error) {
      console.log("something went wrong", error);
    } finally {
      changeLoading(key, false);
    }
  };

  const handleChangeDescription = (value, index) => {
    setDataToSend((prev) =>
      prev.map((el, idx) =>
        idx === index ? { ...el, description: value } : el
      )
    );
  };

  useEffect(() => {
    if (file?.storage === "cloud") {
      setVideoSrc(file.link);
    } else if (file?.storage === "local") {
      _getStreamLocalFile(token, file?.name)
        .then((src) => setVideoSrc(src))
        .catch((e) => console.log("error get src", e));
    }
  }, [token, file?.storage, file?.link, file?.name]);


  return (
    <LayoutNewWebinarStep4
      title="Uji Keterampilan"
      subTitle="Tentukan aturan kelas Anda. "
    >
      <div className="flex flex-col gap-[20px]">
        {dataToSend.map((el, idx) => (
          <div key={idx} className="flex gap-[54px] bg-netral-2 p-4">
            <div className="shrink-0">
              <p>TPM Sesi {idx + 1} : </p>
            </div>
            <div className="bg-netral-1 w-full p-4 border border-netral-6 rounded space-y-4">
              <div className="flex items-center space-x-6 w-full">
                <div className="space-y-1 w-full">
                  <InputTextLabel
                    text="File Materi"
                    id={`lesson-file-${el.chapterIndex}`}
                    placeholder="Tambahkan file materi"
                    value={el?.name}
                    readOnly
                    required
                  />
                  <p className="text-xs text-netral-9">
                    Klik untuk unggah document atau video ( format file Pdf atau
                    Mp4)
                  </p>
                </div>
                <button
                  disabled={loading.file}
                  type="button"
                  className={`${
                    loading.file
                      ? "text-white bg-netral-5 cursor-not-allowed"
                      : "text-secondary-button-600 border border-secondary-button-600 interactive-button-color"
                  } whitespace-nowrap relative top-1 font-semibold px-3 py-2 rounded`}
                >
                  <input
                    disabled={loading.file}
                    id={`lesson-file-${el.chapterIndex}`}
                    type="file"
                    accept=".mp4,.pdf"
                    className="absolute left-0 top-0 w-full h-full opacity-0"
                    onChange={(e) => uploadFile("file", e.target.files[0], idx)}
                  />
                  {loading.file ? "Uploading..." : "Unggah File"}
                </button>
              </div>
              <InputTextareaLabel
                text="Deskripsi"
                // id="description"
                placeholder="Tambahkan desrkipsi panduan tugas praktik mandiri"
                onChange={(e) => handleChangeDescription(e.target.value, idx)}
                value={el.description}
              />
            </div>
          </div>
        ))}
      </div>
      <ChildComponent file={dataToSend} />
    </LayoutNewWebinarStep4>
  );
};

export default IndividualTestInstructor;

const ChildComponent = ({ file }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      active={true}
      page={"individualTest"}
      next={() => {
        dispatch({ type: SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION, payload: file });
        dispatch({ type: SET_FINISHED_NAV, payload: "individualTest" });
        push(`/new/webinar/${id}/step5/hompage`);
      }}
    />
  );
};
