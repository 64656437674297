import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import {
  _uploadFileToTmp,
  _uploadTransferReceipt,
} from "../../../../config/services/api/checkout";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";
import { Icon } from "../../../independent/image";

export default function PaymentEvidence({ setModal, getDetailPayment }) {
  const { checkoutId } = useParams()
  const { token } = useSelector((state) => state.authReducer);
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  
  const ref = useRef();
  useOutsideClick(ref, () => {
    setModal((crr) => crr && "");
  });

  const upload = async () => {
    try {
      setLoading(true);

      const resTmp = await _uploadFileToTmp(token, file);

      if (!resTmp.status) return alert(resTmp.message);

      const resCloud = await _uploadTransferReceipt(token, checkoutId, resTmp.data.name);

      if (!resCloud.status) return alert(resCloud.message);

      // alert("berhasil upload");
      
      setModal("");
      
      getDetailPayment();
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      ref={ref}
      className="mx-4 lg:w-[438px] p-4 lg:p-6 space-y-4 lg:space-y-6 bg-white rounded-lg lg:mx-auto"
    >
      <h4 className="text-lg lg:text-xl font-semibold text-netral-10 pb-4 lg:pb-6 border-b lg:border-b-2 border-b-netral-4 text-center">
        Upload Bukti Bayar
      </h4>
      <p className="text-sm text-netral-9">
        Silakan upload bukti pembayaran kelas Anda disini
      </p>
      {!file ? (
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(211, 226, 255, 0.13) 0%, rgba(211, 226, 255, 0.51) 100%)",
          }}
          className="rounded-lg border border-dashed border-secondary2-500 flex flex-col space-y-6 lg:space-y-8 justify-center items-center p-4 lg:p-8"
        >
          <Icon
            name="cloud-upload-secondary2-400"
            className="w-8 h-8 lg:w-[61px] lg:h-[61px]"
          />
          <button
            className="text-xs lg:text-base relative px-3 py-2 rounded bg-secondary-button-600 text-white interactive-button-color border border-transparent overflow-hidden"
            type="button"
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
              className="absolute top-0 left-0 h-full w-full opacity-0"
            />
            Browse Files
          </button>
          <p className="text-xs text-netral-9">
            Klik untuk unggah files ( format file JPG atau PNG)
          </p>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="mx-auto w-[225px] rounded-lg border border-dashed border-secondary2-200 p-4 space-y-4">
            <img
              className="h-[249px] w-full object-contain"
              src={URL.createObjectURL(file)}
              alt={file.name}
            />
            <div className="p-1 flex justify-center">
              <button
                className="text-secondary3-500 font-semibold text-sm relative overflow-hidden"
                type="button"
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setFile(e.target.files[0])}
                  className="absolute top-0 left-0 h-full w-full opacity-0"
                />
                Change Image
              </button>
            </div>
          </div>
          <div className="text-center">
            <button
              disabled={isLoading}
              className={`${
                isLoading
                  ? "bg-netral-5 text-netral-6"
                  : "bg-secondary-button-600 text-white interactive-button-color"
              } px-3 py-2 rounded border border-transparent overflow-hidden lg:text-base text-xs`}
              type="button"
              onClick={upload}
            >
              {isLoading ? "uploading..." : "Upload FIles"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
