import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useHistory } from "react-router-dom";
import { logout } from "../../../../config/redux/actions/authAction";
import getLink from "../../../../constants/getLink";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";

export default function ButtonProfile({ isBlue }) {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const ref = useRef(null);
  const [isDropdownShown, setDropdownShown] = useState(false);
  useOutsideClick(ref, () => isDropdownShown && setDropdownShown(false));

  const { userData } = useSelector((state) => state.authReducer);

  const dispatch = useDispatch();

  const smallColor = !isBlue ? "text-netral-8" : "text-primary-button-200";
  const color = !isBlue
    ? "text-primary-500 border-primary-500"
    : "text-netral-1 border-[#C4C4C4]";

  const links1 = [
    {
      name: "Dashboard",
      link:
        (userData.role?.length && userData.role[0].toLowerCase()) || "student",
    },
    {
      name: "My Course",
      link: "student",
    },
    {
      name: "Notification",
      link: "student",
    },
    {
      name: "Wishlist",
      link: "student/wishlist",
    },
    {
      name: "Cart",
      link: "cart",
    },
  ];

  const links2 = [
    {
      name: "Profile",
      link: "student/setting",
    },
    {
      name: "Setting",
      link: "student/setting",
    },
  ];

  const role = () => {
    if (userData.role?.length === 1) return userData.role[0];
    else {
      const isInstructorRoute =
        pathname.split("/")[1] === "instructor" ||
        pathname.split("/")[1] === "new";
      if (isInstructorRoute) return "Instructor";
      else return "Student";
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setDropdownShown(!isDropdownShown)}
        className={`${color} flex justify-center items-center space-x-3 border rounded h-[60px] px-3 overflow-hidden`}
      >
        <div>
          <img
            src={getLink(userData.profile_picture?.link)}
            alt={getLink(userData.profile_picture?.name)}
            className="w-[38px] h-[38px] rounded-full"
          />
        </div>
        {/* <Avatar name="unsplash_EZ4TYgXPNWk" h={38} w={38} /> */}
        <div className="flex flex-col justify-start">
          <p className="text-left">{userData.name.split(" ")[0]}</p>
          <span className={`${smallColor} text-sm text-left`}>as {role()}</span>
        </div>
      </button>
      {/* dropdown */}
      {isDropdownShown && (
        <div
          ref={ref}
          className="bg-white rounded p-3 absolute top-16 right-0 w-[252px]"
        >
          {/* 1 */}
          <div className="flex items-center space-x-2 pb-2">
            <div>
              <div className="w-[56px] h-[56px] rounded-full overflow-hidden">
                <img
                  src={getLink(userData.profile_picture?.link)}
                  alt={userData.profile_picture?.name}
                  className="h-full w-full"
                />
              </div>
            </div>
            <div className="space-y-1 flex flex-col">
              <p className="text-sm font-semibold text-primary-700">
                {userData.name}
              </p>
              {userData.email && (
                <span className="text-netral-6 text-xs">{userData.email}</span>
              )}
            </div>
          </div>
          {/* 2 */}
          <div className="py-3 border-t border-[#E0E0E0]">
            <ul className="space-y-2">
              {links1.map((link, i) => (
                <li key={i}>
                  <Link
                    to={"/" + link.link}
                    className="text-primary-800 text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {/* 3  */}
          {userData?.role[0] === "instructor" && (
            <div className="py-3 border-t border-[#E0E0E0]">
              <button className="text-primary-800 text-sm">
                Become Instructor
              </button>
            </div>
          )}
          {/* 4  */}
          <div className="pt-3 border-t border-[#E0E0E0]">
            <ul className="space-y-2">
              {links2.map((link, i) => (
                <li key={i}>
                  <Link
                    to={"/" + link.link}
                    className="text-primary-800 text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
              <button
                type="button"
                onClick={() =>
                  dispatch(
                    logout(() => {
                      setDropdownShown(false);
                      push("/login");
                    })
                  )
                }
                className="text-primary-800 text-sm"
              >
                Logout
              </button>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
