import { Icon } from "../../image";

export default function InputTextLabelIcon({ icon, id, text, ...rest }) {
  return (
    <div className="space-y-2 font-medium w-full">
      <label className="text-xs lg:text-sm text-netral-8" htmlFor={id}>
        {text}
      </label>
      <div className="relative">
        <input
          {...rest}
          id={id}
          type="text"
          className="w-full text-sm lg:text-base border rounded-md pr-2 lg:pr-3 lg:p py-2 lg:py-3 pl-11 border-[#757575] shadow-sm"
        />
        <div className="h-full w-max absolute left-3 top-0 flex items-center">
          <Icon name={icon} className="w-[22px] h-[22px]" />
        </div>
      </div>
    </div>
  );
}
