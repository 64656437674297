import { Link } from "react-router-dom";

export default function OrderSummary({ total, checkout }) {
  return (
    <div className="hidden lg:block">
      <div className="w-[286px] px-3 py-6">
        <p className="text-lg text-netral-6">Total</p>
        <h4 className="mt-4 text-2xl font-semibold text-netral-10">
          Rp {total}
        </h4>
        <Link
          to={`/checkout?items=${checkout}`}
          className="mt-6 inline-block text-center w-full py-2 font-semibold text-netral-1 rounded bg-secondary-button-600"
        >
          Lanjutkan ke Pembayaran
        </Link>
      </div>
    </div>
  );
}
