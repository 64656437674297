import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Illustration } from "../../components/independent/image";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
import { SET_ALERT } from "../../config/redux/types";
import { _getIncomeData } from "../../config/services/api/income";

const Income = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);
  const [data, setData] = useState(dummyResponse);

  useEffect(() => {
    _getIncomeData(token)
      .then((res) => {
        if (!res.status) throw new Error(res.message).message;

        setData(res);
      })
      .catch((errorMessage) =>
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: errorMessage },
        })
      );
  }, [token, dispatch]);

  return (
    <LayoutDashboard>
      <div
        style={{
          background:
            "linear-gradient(90.79deg, rgba(13, 141, 144, 0.87) 7.52%, rgba(13, 141, 144, 0.48) 111.62%)",
        }}
        className="w-full py-7 pl-[31px] pr-[82px] flex justify-between relative"
      >
        <div className="space-y-[5px] w-[258px] relative">
          <h1 className="text-[36px] text-white font-extrabold">
            Total Income
          </h1>
          <p className="text-white text-sm">
            Berikut merupakan total pendapatan dari kelas yang telah terjual
          </p>
        </div>
        <Illustration
          name="Na_Nov_17 [Converted] 1"
          className="w-[188px] h-[137px]"
        />
        <div
          style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)" }}
          className="p-[15px] bg-white rounded-[5px] w-[322px] absolute -bottom-1/2 left-1/2 -translate-x-1/2 translate-y-0"
        >
          <div className="space-y-[10px] px-[5px]">
            <p className="text-sm font-medium text-netral-10">
              Current Balance
            </p>
            <h2 className="text-2xl font-bold text-netral-10">
              Rp{data.total_income.toLocaleString("id-ID")}
            </h2>
          </div>
          <div className="mt-5 rounded-[5px] bg-[#CCEAEB] flex space-x-[10px] py-[9px] pl-[10px] pr-8">
            <div>
              <Icon
                name="information (2) 1.-primary-500"
                className="w-[18px] h-[18px]"
              />
            </div>
            <p className="text-[11px] text-primary-800">
              Total pendapatan belum termasuk potongan sesuai MOU yang telah di
              sepakati
            </p>
          </div>
        </div>
      </div>
      <div className="mt-[145px] pl-6 pr-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">
            Class List Sold
          </h3>
          <p className="text-netral-6">Daftar kursus yang telah dan jual</p>
        </div>
        <table className="mt-[30px] w-full">
          <thead>
            <tr className="flex w-full space-x-[23px] pb-3 border-b border-b-netral-8">
              <th className="text-sm text-netral-10 font-semibold text-left">
                <h6 className="w-5">No</h6>
              </th>
              <th className="text-sm text-netral-10 font-semibold text-left w-full">
                <h6>Kelas</h6>
              </th>
              <th className="text-sm text-netral-10 font-semibold text-center w-full">
                <h6>Terjual</h6>
              </th>
              <th className="text-sm text-netral-10 font-semibold text-right w-full">
                <h6>Harga Kelas</h6>
              </th>
              <th className="text-sm text-netral-10 font-semibold text-right w-full">
                <h6>Pendapatan</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.detail.map((el, i) => (
              <tr key={i} className="flex w-full space-x-[23px] py-3">
                <td className="text-sm text-netral-9 text-left">
                  <p className="w-5">{i + 1}</p>
                </td>
                <td className="text-sm text-netral-9 text-left w-full">
                  <p>{el.courseTitle}</p>
                </td>
                <td className="text-sm text-netral-9 text-center w-full">
                  <p>{el.sold}</p>
                </td>
                <td className="text-sm text-netral-9 text-right w-full">
                  <p>{Number(el.class_price).toLocaleString("id-ID")}</p>
                </td>
                <td className="text-sm text-netral-9 text-right w-full">
                  <p>{el.income_each_class.toLocaleString("id-ID")}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LayoutDashboard>
  );
};

export default Income;

let dummyResponse = {
  status: true,
  total_income: 0,
  detail: [],
};
