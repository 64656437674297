import { ClipArt } from "../../../independent/image";

export default function Facility() {
  return (
    <section className="lg:pt-[41px] lg:pb-[22.95px] relative">
      <div
        style={{
          background:
            "linear-gradient(97.13deg, rgba(211, 226, 255, 0) 5.83%, rgba(211, 226, 255, 0.8) 100%)",
          backdropFilter: "blur(16px)",
        }}
        className="pt-4 pb-4 lg:pt-10 lg:pb-8 space-y-6 lg:space-y-10"
      >
        <h3 className="lg:text-2xl font-semibold text-netral-10 text-center">
          Keuntungan mengikuti pelatihan di Skills.id
        </h3>
        <div className="lg:w-[805px] mx-auto grid place-items-center grid-cols-3 gap-y-[60px]">
          {facilites.map((facility, i) => (
            <div
              key={i}
              className="flex flex-col space-y-3 items-center w-[90px] lg:w-[183px]"
            >
              <ClipArt
                name={facility.clipArt}
                className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]"
              />
              <p className="text-xs lg:text-sm font-semibold text-netral-9 text-center">
                {facility.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      <ClipArt
        name="triangle-outline-shadow"
        className="absolute -top-4 lg:top-0 right-[15.68px] lg:right-[136.68px] w-[32.38px] h-[31.86px] lg:w-[90.32px] lg:h-[88.86px]"
      />
      <ClipArt
        name="square-outline-shadow"
        className="absolute -bottom-3 lg:bottom-0 left-[36px] w-[22px] h-[22.01px] lg:w-[59.93px] lg:h-[59.95px]"
      />
    </section>
  );
}

const facilites = [
  { text: "Instruktur Terpercaya", clipArt: "Teacher" },
  {
    text: "Materi pembelajaran dengan beragam topik",
    clipArt: "20",
  },
  {
    text: "Ada kuis untuk mengetahui pemahamanmu terhadap setiap materi",
    clipArt: "Group 2219",
  },
  {
    text: "Tes akhir untuk menguji pemahamanmu terhadap materi",
    clipArt: "score",
  },
  {
    text: "Sertifikat sebagai bukti kamu telah menyelesaikan pelatihan",
    clipArt: "Achievement",
  },
  { text: "Kelas konsultasi bersama para tutor", clipArt: "Video Chat" },
];
