import { Illustration } from "../../../independent/image";

export default function First({ title, setActiveView }) {
  return (
    <div className="text-center w-full">
      <Illustration
        name="Online learning concept"
        className="w-[93px] h-[76px] lg:w-[214px] lg:h-[214px] mx-auto"
      />
      <h5 className="mt-6 lg:mt-8 lg:text-xl text-black font-semibold">
        {title}
      </h5>
      <p className="mt-1 lg:mt-4 mb-4 lg:mb-[31px] text-xs lg:text-base">
        Silakan kerjakan {title} terlebih dahulu sebelum melanjutkan materi
        pembelajaran
      </p>
      <button
        type="button"
        onClick={() => setActiveView(1)}
        className="text-white font-semibold px-3 py-2 bg-secondary-button-600 rounded interactive-button-color border border-transparent text-xs lg:text-base"
      >
        Mulai {title}
      </button>
    </div>
  );
}
