import { useRef } from "react";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";

export default function ModalCheckPaymentStatus({ setModal }) {
  const ref = useRef();
  useOutsideClick(ref, () => {
    setModal((crr) => crr && "");
  });
  
  return (
    <div ref={ref} className="bg-white w-[500px] mx-auto rounded p-8">
      <p className="text-netral-9 text-lg">
        Admin sedang memeriksa bukti pembayaran yang telah diupload
      </p>
    </div>
  );
}
