// import { _getCourse } from "../../../../config/services/api/course";

import moment from "moment";
import { useHistory } from "react-router-dom";
import getLink from "../../../../constants/getLink";

export default function PurchaseDetails({ items }) {
  const { push } = useHistory();

  const deleteFromCheckout = (targetIndex) => {
    items.splice(targetIndex, 1);
    const route = encodeURIComponent(JSON.stringify(items._id));
    push(`/checkout?items=${route}`);
  };

  // console.log(items);

  return (
    <div className="space-y-4 lg:space-y-6 w-full">
      <h5 className="lg:text-xl font-semibold text-netral-10">
        Detail Pembelian
      </h5>
      {items.map((item, i) => (
        <div key={i} className="flex space-x-2 lg:space-x-6 items-center">
          <div>
            <img
              className="w-[100px] h-[91px] bg-neutral-800 rounded object-contain"
              src={getLink(item.thumbnail)}
              alt={item.thumbnail}
            />
          </div>
          <div className="w-full">
            <div className="flex flex-wrap lg:flex-nowrap items-end lg:items-center lg:justify-between">
              <h5 className="text-lg lg:text-xl font-semibold text-netral-10 mr-3 lg:mr-0">
                {item.title}
              </h5>
              <p className="text-netral-8 lg:block text-sm lg:text-base">
                {new Date(Date.now()).toLocaleString()}
              </p>
            </div>
            <p className="mt-[2px] lg:mt-1 text-netral-8">
              Oleh {item.instructor || ""}
            </p>
            <p className="mt-[2px] lg:mt-1 text-netral-8">
              Jadwal :{" "}
              {`${moment(item?.learner_schedule?.dateStart).format(
                "Do"
              )} - ${moment(item?.learner_schedule?.dateEnd).format(
                "Do MMMM YYYY"
              )}`}{" "}
              |{" "}
              {`${item?.learner_schedule?.startHour} - ${item?.learner_schedule?.endHour}`}
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center mt-1 lg:mt-2 space-x-2">
                {item?.discount && item?.discount > 0 ? (
                  <div className="flex items-center space-x-2">
                    <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
                      {item?.discount}%
                    </p>
                    <p className="text-secondary-button-600 line-through text-base font-semibold">
                      {parseInt(item.priceBeforeDiscount).toLocaleString(
                        "id-ID"
                      )}
                    </p>
                  </div>
                ) : null}
                <h6 className=" lg:text-lg font-semibold text-primary-500">
                  Rp {Number(item.price).toLocaleString("id-ID")}
                </h6>
              </div>
              <button
                type="button"
                onClick={() => deleteFromCheckout(i)}
                className="text-sm font-semibold text-secondary3-600"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
