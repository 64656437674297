import getLink from "../../../../constants/getLink";
import { Icon } from "../../../independent/image";

export default function Instructor({ data }) {
  const reviews = [
    {
      icon: "groups-primary-500",
      name: `${data.totalStudent} Peserta didik`,
    },
    {
      icon: "menu_book-primary-500",
      name: `${data.totalCourse} Kursus`,
    },

    // {
    //   icon: "Star-primary-500",
    //   name: `${data.totalCourse} Kursus`,
    // },
  ];
  return (
    <>
      <div className="space-y-2 lg:space-y-3">
        <div className="space-y-1">
          <h5 className="text-sm lg:text-xl font-semibold text-netral-10">
            {data.name}
          </h5>
          <p className="text-netral-6 text-xs lg:text-base">{data.job}</p>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <img
              className="w-[63px] h-[63px] lg:w-20 lg:h-20 rounded-full"
              alt="profile"
              src={getLink(data?.profile_picture)}
            />
          </div>
          <ul className="space-y-2">
            {reviews?.map((review, i) => (
              <div key={i} className="flex items-center space-x-1">
                <Icon
                  name={review?.icon}
                  className="w-4 lg:w-[22px] h-4 lg:h-[22px]"
                />
                <p className="text-netral-9 text-xs lg:text-sm">
                  {review?.name}
                </p>
              </div>
            ))}
          </ul>
        </div>
        <p className="text-netral-9 whitespace-pre-line text-sm lg:text-base">
          {data?.description}
        </p>
      </div>
      <div className="lg:pb-[50px]"></div>
    </>
  );
}
