import { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { BigPlayButton, ControlBar, Player } from "video-react";
import getLink from "../../../../constants/getLink";
const PDFJS = window.pdfjsLib;

export default function FilePreview({
  type,
  storage,
  link,
  setCurrentTime,
  pdfRendering,
  pageRendering,
  images,
}) {
  const [video, setVideo] = useState(null);

  const handleStateChange = (state) => setVideo(state);

  useEffect(() => {
    if (storage === "youtube") return;
    if (type !== "video/mp4") return;
    if (!video || !link || !setCurrentTime) return;
    
    if (!video?.getState()) return;

    video?.subscribeToStateChange(handleStateChange);
    const { player } = video.getState();
    setCurrentTime(player.currentTime);
  }, [video, link, setCurrentTime, type, storage]);

  return (
    <div
      className={`h-[247px] lg:h-[471px] rounded-lg flex items-center ${
        pdfRendering || pageRendering ? "bg-primary-200/60" : "bg-netral-4"
      } justify-center relative overflow-auto`}
    >
      {type === "video/mp4" && storage === "youtube" && (
        <>
          <div className="absolute top-0 left-0 w-full h-[70px]"></div>
          <div className="absolute bottom-0 left-0 w-full h-[155px]"></div>
          <YouTube
            videoId={link}
            opts={{
              height: "100%",
              width: "100%",
              playerVars: { controls: 0, autoplay: 0, fs: 0, disablekb: 1 },
            }}
            className="h-full w-full bg-secondary-button-400"
            onEnd={(e) => setCurrentTime(Math.ceil(e.target.getDuration()))}
          />
        </>
      )}
      {type === "video/mp4" && storage !== "youtube" && (
        <>
          <Player
            ref={(player) => (video = player)}
            fluid={false}
            id="tes-current-time"
            width="100%"
            height="100%"
            src={link}
            // src="https://res.cloudinary.com/side/video/upload/v1638436888/t9lu0h0km0pkpzny0jyb.mp4"
          >
            <ControlBar disableDefaultControls={false} />
            <BigPlayButton position="center" />
          </Player>
          {/* <button type="button" onClick={getCurrentTime}>
            get current time
          </button> */}
        </>
      )}
      {type === "application/pdf" && (
        <>
          {/* <iframe
            title="Iframe Example"
            src={
              link +
              "#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0"

              // "https://skillsrevamp.com/api/class/stream/368f29.1673572533681_Commercial_Invoice_Template.pdf"
            }
            // width="100%"
            // height="100%"
            onContextMenu={(e) => e.preventDefault()}
            type="application/pdf"
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
            }}
          /> */}
          {pdfRendering || pageRendering ? (
            <p>loading...</p>
          ) : (
            <div className="flex flex-col space-y-10 absolute top-0 p-6">
              {images.map((el, idx) => (
                <div key={idx}>
                  <img src={el} alt="pdf" />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
