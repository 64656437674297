import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InputDateLabel from "../../components/independent/input/input-date-label";
import InputFilePreview from "../../components/independent/input/input-file-preview";
import InputNumberLabel from "../../components/independent/input/input-number-label";
import InputTextLabel from "../../components/independent/input/input-text-label";
import InputTextareaLabel from "../../components/independent/input/input-textarea-label";
import LayoutRegisterInstrucor from "../../components/independent/layout/layout-register-instrucor";
import { RegisterInstructorContext } from "./RegisterInstructor";

const Profile = () => {
  const { isLogin } = useSelector((state) => state.authReducer);
  const { profile, setProfileInput, setAndPreviewImage } = useContext(
    RegisterInstructorContext
  );

  return (
    <LayoutRegisterInstrucor step={1}>
      <main
        style={{ boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.08)" }}
        className="bg-netral-2 rounded-xl p-6"
      >
        <h4 className="text-xl text-netral-10 font-semibold">
          Buat Akun Instruktur
        </h4>
        <p className="text-netral-9 mt-3">
          Informasi ini merupakan informasi akun untuk instruktur Skills.id.
        </p>
        <div className="mt-6 space-y-3">
          <InputTextLabel
            id="name"
            name="name"
            placeholder="Masukkan nama instruktur Anda"
            text="Nama Instruktur"
            value={profile.name}
            onChange={setProfileInput}
          />
          {!isLogin && (
            <>
              <InputTextLabel
                id="email"
                name="email"
                placeholder="Masukkan email Anda"
                text="Email (Email akan digunakan untuk email akun dan notifikasi)"
                value={profile.email}
                onChange={setProfileInput}
              />
              <InputTextLabel
                id="password"
                name="password"
                placeholder="Masukkan Password Anda"
                text="Password"
                isPassword
                value={profile.password}
                onChange={setProfileInput}
              />
              <InputTextLabel
                id="confirm_password"
                name="confirm_password"
                placeholder="Masukkan Password Anda"
                text="Confirm Password"
                isPassword
                value={profile.confirm_password}
                onChange={setProfileInput}
              />
            </>
          )}
          <InputNumberLabel
            id="phone_number"
            name="phone_number"
            placeholder="Masukkan nomor telepon Anda"
            text="Nomor Instruktur"
            value={profile.phone_number}
            onChange={setProfileInput}
          />
          <div className="flex items-center space-x-6">
            <InputDateLabel
              text="Tanggal Lahir"
              id="date_of_birth"
              name="date_of_birth"
              value={profile.date_of_birth}
              onChange={setProfileInput}
            />
            <InputTextLabel
              id="domicile"
              name="domicile"
              text="Domisili"
              placeholder="Masukkan tempat tinggal Anda"
              value={profile.domicile}
              onChange={setProfileInput}
            />
          </div>
          <InputTextLabel
            id="headline"
            name="headline"
            text="Headline"
            placeholder="Masukkan pekerjaan Anda saat ini"
            value={profile.headline}
            onChange={setProfileInput}
          />
          <InputTextareaLabel
            id="bio"
            name="bio"
            placeholder="Ceritakan biografi dan pengalaman Anda"
            text="Bio"
            value={profile.bio}
            onChange={setProfileInput}
          />
          <div className="space-y-2">
            <label className="text-sm font-semibold text-netral-8">
              Foto Profile
            </label>
            <InputFilePreview
              type="image"
              value={profile.profile_picture}
              src={profile.profile_picture.link}
              setValue={(file) => setAndPreviewImage("profile_picture", file)}
            />
          </div>
        </div>
      </main>
      <div className="flex items-center justify-end space-x-6 mt-8 pb-[112px]">
        <Link
          to="/"
          style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
          className="text-center text-primary-500 font-semibold py-[11.5px] w-[207px] bg-white rounded border border-primary-500"
        >
          Batal
        </Link>
        <Link
          to="/become-instructor/detail"
          style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)" }}
          className="text-center text-white font-semibold py-[11.5px] w-[207px] bg-primary-500 rounded border border-primary-500"
        >
          Lanjutkan
        </Link>
      </div>
    </LayoutRegisterInstrucor>
  );
};

export default Profile;
