import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InputDropdownLabel from "../../components/independent/input/input-dropdown-label";
import InputFilePreview from "../../components/independent/input/input-file-preview";
import InputNumberLabel from "../../components/independent/input/input-number-label";
import InputTextLabel from "../../components/independent/input/input-text-label";
import LayoutRegisterInstrucor from "../../components/independent/layout/layout-register-instrucor";
import getLink from "../../constants/getLink";
import { RegisterInstructorContext } from "./RegisterInstructor";

const Detail = () => {
  const { isLogin } = useSelector((state) => state.authReducer);

  const {
    profile,
    setProfileInput,
    setAndPreviewImage,
    setProfileInput2,
    register,
    updateProfile,
    loading,
  } = useContext(RegisterInstructorContext);

  return (
    <LayoutRegisterInstrucor step={2}>
      <main
        style={{ boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.08)" }}
        className="bg-netral-2 rounded-xl p-6"
      >
        <h4 className="text-xl text-netral-10 font-semibold">
          Informasi Detail
        </h4>
        <p className="text-netral-9 mt-3">
          Informasi ini akan digunakan dalam proses pembayaran
        </p>
        <div className="mt-6 space-y-3">
          <InputNumberLabel
            id="npwp_number"
            name="npwp_number"
            placeholder="Masukkan nomor NPWP Anda"
            text="Nomor NPWP"
            value={profile.npwp_number}
            onChange={setProfileInput}
          />
          <InputTextLabel
            id="npwp_name"
            name="npwp_name"
            placeholder="Masukkan nama NPWP Anda"
            text="Nama NPWP"
            value={profile.npwp_name}
            onChange={setProfileInput}
          />
          <InputTextLabel
            id="npwp_address"
            name="npwp_address"
            placeholder="Masukkan alamat NPWP Anda"
            text="Alamat NPWP "
            value={profile.npwp_address}
            onChange={setProfileInput}
          />
          <div className="space-y-2">
            <label className="text-sm font-semibold text-netral-8">
              Scan NPWP
            </label>
            <InputFilePreview
              type="image"
              value={profile.npwp_picture}
              src={profile.npwp_picture?.link}
              setValue={(file) => setAndPreviewImage("npwp_picture", file)}
            />
          </div>
          <InputDropdownLabel
            values={["PKP"]}
            value={profile.tax_status}
            setValue={(value) => setProfileInput2(profile.tax_status, value)}
          >
            Status Pajak
          </InputDropdownLabel>
          <InputDropdownLabel
            values={["Bank Mandiri", "BCA", "BRI", "Permata Bank"]}
            value={profile.bank_name}
            setValue={(value) => setProfileInput2(profile.bank_name, value)}
          >
            Nama Bank
          </InputDropdownLabel>
          <InputTextLabel
            id="bank_city"
            name="bank_city"
            placeholder="Dimana kota bank Anda"
            text="Kota Cabang"
            value={profile.bank_city}
            onChange={setProfileInput}
          />
          <InputTextLabel
            id="bank_branch"
            name="bank_branch"
            placeholder="Cabang Bank (Untuk email konfirmasi pembayaran) "
            text="Cabang Bank (Untuk email konfirmasi pembayaran)"
            value={profile.bank_branch}
            onChange={setProfileInput}
          />
          <InputTextLabel
            id="bank_account"
            name="bank_account"
            placeholder="Masukkan nama rekening Bank Anda"
            text="Nama Rekening"
            value={profile.bank_account}
            onChange={setProfileInput}
          />
          <InputNumberLabel
            id="account_number"
            name="account_number"
            placeholder="Masukkan nomor rekening Anda"
            text="Nomor Rekening"
            value={profile.account_number}
            onChange={setProfileInput}
          />
        </div>
      </main>
      <div className="flex items-center justify-end space-x-6 mt-8 pb-[112px]">
        <Link
          to="/become-instructor/profile"
          style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
          className="text-center text-primary-500 font-semibold py-[11.5px] w-[207px] bg-white rounded border border-primary-500"
        >
          Sebelumnya
        </Link>
        <button
          disabled={loading}
          type="button"
          onClick={!isLogin ? register : updateProfile}
          style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)" }}
          className="text-center text-white font-semibold py-[11.5px] w-[207px] bg-primary-500 rounded border border-primary-500"
        >
          Submit
        </button>
      </div>
    </LayoutRegisterInstrucor>
  );
};

export default Detail;
