import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Icon } from "../../../../components/independent/image";
import LayoutNewVideo from "../../../../components/independent/layout/layout-new-video";
import QuizCard from "../../../../components/Molecule/Card/QuizCard";
import EditableQuizCard from "../../../../components/Molecule/Card/EditableQuizCard";

import SaveToDraft from "../../../../components/Molecule/_video/SaveToDraftButton";

export default function Step3() {
  const { id } = useParams();
  const { push } = useHistory();
  const { courseTitle } = useSelector((state) => state.newWebinarReducer);

  const gradientColor =
    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)";

  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([
    {
      label:
        "Torem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos?",
      options: [
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, lorem. 1",
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, lorem. 2",
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, lorem. 3",
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, lorem. 4",
      ],
      answer:
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, lorem.",
    },
  ]);

  const handleAddQuestions = () => {
    const quizDefault = {
      label: "",
      options: ["", "", "", ""],
      answer:null
    };
    setQuestions((prev) => [...prev, quizDefault]);
  };

  const handleAnswer = (index, num) => {
    setQuestions((prev) =>
      prev.map((el, idx) => (idx === index ? { ...el, answer: num } : el))
    );
  };

  return (
    <LayoutNewVideo activeTab={3}>
      <SaveToDraft />
      <div className="flex items-center px-[32px] justify-between gap-x-[170px]">
        <div className="flex-1 flex  justify-center flex-col w-full pr-[200px]"></div>
      </div>
      <div
        className="w-full flex gap-8 justify-center"
        style={{ fontFamily: "poppins" }}
      >
        <div className="w-[596px] -mt-7 mb-[128px]">
          <h1 className="text-[24px] font-semibold">Bank Soal</h1>
          <p className="text-[16px] font-medium text-[#757575]">
            Buat dan tambahkan soal sebagai test akhir pada pembelajaran kelas
            yang telah dilaksanakan.
          </p>
          <hr className="my-[32px] bg-[#E0E0E0]" />
          {/* start form */}
          <div className="mb-8">
            {questions.map((el, idx) => (
              <div>
                <EditableQuizCard
                  index={idx + 1}
                  questions={el.label}
                  options={el.options}
                  answer={el.answer}
                  handleAnswer={(answer) => handleAnswer(idx, answer)}
                />
              </div>
            ))}
          </div>

          <div className="w-full flex items-center justify-between">
            <div
              className="flex gap-1 items-center  cursor-pointer"
              onClick={() => push(`/new/video/${id}/step2`)}
            >
              <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
              <span
                style={{
                  background:
                    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="cursor-pointer text-[1rem] leading-[20px] font-bold"
              >
                Kembali
              </span>
            </div>

            <div
              className={`flex items-center px-4 py-2 rounded-lg text-[1rem] font-semibold cursor-pointer gap-[5px] text-[#fff]`}
              style={{
                background: gradientColor,
              }}
              onClick={() => {
                // dispatch({ type: SET_WEBINAR_TITLE, payload: title });
                push(`/new/video/${id}/step3`);
              }}
            >
              Lanjutkan
              <Icon
                name="keyboard_arrow_right"
                h={20}
                w={20}
                className="mt-[3px]"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutNewVideo>
  );
}
