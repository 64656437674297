import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
import Quiz from "../../../components/dependent/learn-course/quiz";
import Finish from "../../../components/dependent/learn-course/quiz/Finish";
import First from "../../../components/dependent/learn-course/quiz/First";
import PrevOrNextController from "../../../components/dependent/learn-course/quiz/PrevOrNextController";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import { postPosttestProgress } from "../../../config/redux/actions/courseAction";
import { _verifyPrakerja } from "../../../config/services/api/courseProgress";
import { SET_ALERT } from "../../../config/redux/types";
import Modal from "../../../components/independent/modal";
import { ClipArt, Icon } from "../../../components/independent/image";

const PostTest = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { title, courseId } = useParams();
  const params = useParams();

  const { token, userData } = useSelector((state) => state.authReducer);
  const { enrollment, participantProgress, classType } = useSelector(
    (state) => state.courseReducer
  );
  const classes = useSelector((state) => state.courseReducer);
  const [openModal, setOpenModal] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const course = useSelector((state) => state.courseReducer);

  let views = [{ name: "first" }, { name: "finish" }];

  if (enrollment?.posttest?.length)
    views = [{ name: "first" }, ...enrollment.posttest, { name: "finish" }];

  const [activeView, setActiveView] = useState(0);
  const [data, setData] = useState([]);

  const handleData = (value) => {
    const index = activeView - 1;
    const questionIndex = enrollment.posttest[index].questionIndex;
    data[index] = { questionIndex, participantAnswer: value };
    setData([...data]);
  };

  const addPosttestProgress = () =>
    dispatch(postPosttestProgress(token, courseId, data));

  const someDataUndefined = () => data.some((data) => !data) || !data.length;

  useEffect(() => {
    if (!participantProgress?.posttest.length) return;
    if (participantProgress?.posttest.every((el) => el.isCompleted))
      setActiveView(views.length - 1);
  }, [views.length, participantProgress?.posttest]);

  const redeem = (e) => {
    e.preventDefault();
    // token, sequence,redeemCode,email,class_id
    setLoading(true);
    _verifyPrakerja(token, 999, redeemCode, userData.email, course._id)
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        // setOpenModal(false);

        if (res.code) {
          localStorage.setItem(
            "prakerjaPayload",
            JSON.stringify({
              token,
              sequence: 999,
              redeemCode,
              email: userData.email,
              class_id: course._id,
            })
          );
          localStorage.setItem("callbackTo", location.pathname);
          // setSuccessModal(true);
          if (res.response.url) {
            window.open(res.response.url);
          } else {
            setOpenModal(false);
          }
        } else {
          setOpenModal(false);
          setErrorModal(res.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorModal(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (classes.classType === "Offline Class") {
      setOpenModal(false);
    } else {
      const data = JSON.parse(localStorage.getItem("prakerjaPayload"));
      if (userData?.role?.includes("Assesor")) {
        setOpenModal(false);
      } else if (data) {
        setRedeemCode(data?.redeemCode)
        setLoading(true);
        _verifyPrakerja(
          data?.token,
          999,
          data?.redeemCode,
          data?.email,
          data?.class_id
        )
          .then((res) => {
            if (res?.response?.data?.attendance_status === 1) {
              setOpenModal(false);
            } else {
              setOpenModal(true);
            }
          })
          .catch((e) => {
            dispatch({
              type: SET_ALERT,
              payload: { status: false, message: e },
            });
            setOpenModal(false);
            setErrorModal(true);
          })
          .finally(() => setLoading(false));
      } else {
        setOpenModal(true);
      }
    }
  }, []);

  return (
    <LayoutLearnCourse>
      <div
        style={{
          background:
            "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
        }}
        className="min-h-[247px] lg:h-[471px] border border-primary-300 rounded-lg flex items-center relative"
      >
        {views[activeView]?.name === "first" && (
          <First setActiveView={setActiveView} title="Post Test" />
        )}
        {views[activeView]?.name !== "first" &&
          views[activeView]?.name !== "finish" && (
            <>
              <PrevOrNextController
                activeView={activeView}
                setActiveView={setActiveView}
                views={views}
                addProgress={addPosttestProgress}
                isCompleted={!someDataUndefined()}
              />
              <Quiz
                activeView={activeView}
                views={views}
                handleData={handleData}
                value={data[activeView - 1]?.participantAnswer || false}
              />
            </>
          )}
        {views[activeView]?.name === "finish" && (
          <Finish
            score={Math.max.apply(
              0,
              participantProgress?.posttest?.map((el) => +el.score)
            )}
            next={() => {
              setActiveView(0);
              setData([]);
            }}
            title="Post Test"
            buttonText="Ulangi Post Test"
          />
        )}
      </div>
      <PrevOrNextButton
        prevActive={true}
        prev={() => {
          const lastChapter = enrollment?.chapters.length;
          const lastMaterial =
            enrollment?.chapters[lastChapter - 1].materials.length;

          push(
            `/learn/${courseId}/chapter${lastChapter}-material${lastMaterial}`
          );
        }}
        nextActive={
          activeView === views.length - 1 &&
          Math.max.apply(
            0,
            participantProgress?.posttest?.map((el) => +el.score)
          ) > 59
        }
        next={() => push(`/learn/${courseId}/skill-test`)}
      />
      <div className="mt-6 space-y-2">
        <h4 className="text-xl font-semibold text-netral-10 pb-2 border-b-2 border-netral-6">
          Riwayat Post Test
        </h4>
        <table className="w-full rounded-lg overflow-hidden">
          <thead>
            <tr className="flex items-center space-x-2 px-2 py-3 bg-netral-2 text-left">
              <th>
                <p className="w-10 text-sm font-semibold text-netral-10">No</p>
              </th>
              <th className="w-full">
                <p className="text-sm font-semibold text-netral-10">Tanggal</p>
              </th>
              <th className="w-full">
                <p className="text-sm font-semibold text-netral-10">Nilai</p>
              </th>
              <th className="w-full">
                <p className="text-sm font-semibold text-netral-10">
                  Jawaban Benar
                </p>
              </th>
              <th className="w-full">
                <p className="text-sm font-semibold text-netral-10">
                  Jawaban Salah
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {participantProgress?.posttest.map((el, i) => (
              <tr
                key={i}
                className="flex items-center space-x-2 px-2 py-3 text-left"
              >
                <td>
                  <p className="w-10 text-sm text-netral-10">{i + 1}</p>
                </td>
                <td className="w-full">
                  <p className="text-sm text-netral-10">
                    {new Date(el.date).toLocaleString()}
                  </p>
                </td>
                <td className="w-full">
                  <p className="text-sm text-netral-10">{el.score}</p>
                </td>
                <td className="w-full">
                  <p className="text-sm text-netral-10">{el.rightAnswer}</p>
                </td>
                <td className="w-full">
                  <p className="text-sm text-netral-10">{el.wrongAnswer}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal modal={openModal}>
        <form onSubmit={(e) => redeem(e)}>
          <div className="bg-white w-[300px] lg:w-[485px] min-h-[270px] relative p-[20px]">
            <div className="flex flex-col text-center space-y-2">
              <p className="text-xl font-semibold">Redeem Code</p>
              <p className="text-sm">
                Masukan 12 karakter unit redeem code yang dapat kamu temukan di
                website{" "}
                <a
                  href="https://www.prakerja.go.id/"
                  className="underline text-primary-500"
                >
                  Prakerja.go.id
                </a>
              </p>
              <div className="py-5">
                <input
                  id="voucher"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="block w-full p-3 rounded border border-[#757575]"
                  placeholder="Redeem code"
                  autocomplete="off"
                />
              </div>
              <button
                disabled={!redeemCode || loading}
                type={"submit"}
                className={`${
                  !redeemCode || loading
                    ? "text-netral-6 bg-netral-5 cursor-not-allowed"
                    : "text-white bg-secondary-button-600 interactive-button-color"
                } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
              >
                Redeem sekarang
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal modal={successModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setSuccessModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"Success-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl">Berhasil Tukar Voucher</p>
              <p className="font-normal text-sm">
                Kode Voucher Anda telah berhasil ditukarkan
              </p>
            </div>
            {/* <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => setSuccessModal(false)}
            >
              Lanjut Belajar
            </button> */}
          </div>
        </div>
      </Modal>

      {/* Error redeem Modal */}
      <Modal modal={errorModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">{errorModal}</p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                setOpenModal(true);
                setErrorModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>
    </LayoutLearnCourse>
  );
};

export default PostTest;
