import { Icon } from "../../image";

export default function Stars2({ n, size }) {
  let stars = [];
  for (let i = 1; i <= 5; i++) {
    n > i - 1 && stars.push("Star 1");
  }

  return (
    <div className="flex items-center space-x-1">
      {stars.map((star, i) => (
        <Icon key={i} name={star} className={`${size}`} />
      ))}
    </div>
  );
}

Stars2.defaultProps = {
  size: "w-[18px] h-[18px]",
};
