import React, { useState } from "react";
import { Icon } from "../../independent/image";
import Labelform from "../../atom/Label";

export default function Dropdown({ dropdownItems, placeholder, labelValue,handleChange,value }) {
  const [isOpenDropdownLanguage, setIsOpenDropdownLanguage] = useState(false);

  const handleCheckedItem = (item, index) => {
    handleChange(item)
    setIsOpenDropdownLanguage(false);
  };
  return (
    <>
      <div className="flex flex-col items-start relative">
        <Labelform
          value={labelValue}
          className={"text-[14px] text-[#616161] mb-[8px]"}
          isRequired={true}
        />
        <select
          name=""
          onClick={() => setIsOpenDropdownLanguage((e) => !e)}
          className={`rounded-xl border-[#E0E0E0] w-full focus:ring-[#9360A8] ${
            value ? "text-[black]" : "text-[#C2C2C2]"
          } cursor-pointer`}
        >
          <option value="" hidden>
            {value || placeholder}
          </option>
        </select>
        {isOpenDropdownLanguage && (
          <div className="bg-white rounded-2xl p-5 z-50 flex flex-col absolute top-20 gap-[5px] cursor-pointer shadow-lg w-[273px] max-[430px]:w-[170px] max-h-[266px] overflow-auto mt-[10px]">
            {dropdownItems.map((item, index) => (
              <div
                key={index}
                className={`hover:bg-slate-100 relative ms-3 border-b border-[#EDEDED] py-[8px] ${
                  value === item && "active-item"
                }`}
                onClick={() => handleCheckedItem(item, index)}
              >
                {item}
                {value === item && (
                  <Icon
                    name={"Checked"}
                    className={"flex absolute max-[430px]:-right-2  right-0 top-[8px]"}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
