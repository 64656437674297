import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import ButtonExit from "../../button/button-exit";
import Footer from "../../footer/footer";
import Header from "../../header/header";
// import { Icon } from "../../image";
import NavHeaderDashboard from "../../navigation/nav-header-dashboard";

export default function LayoutNewCourse({ activeTab, children }) {
  const { pathname } = useLocation();
  const isStep6 = pathname.includes("step6");
  const all = useSelector((state) => state.newCourseReducer);
  const { id } = useParams();

  if (all._id && !isStep6) {
    return <Redirect to={`/new/course/${id}/step6`} />;
  } else {
    if (isStep6 && all.classType === "") {
      return <Redirect to={`/new/course/${id}`} />;
    }
  }

  return (
    <div className="h-screen flex flex-col">
      <Header>
        <NavHeaderDashboard />
      </Header>
      <div className="pt-8 h-full overflow-auto">
        <div className="relative">
          {!isStep6 && <ButtonExit to="/instructor">Keluar</ButtonExit>}
          <ul className="px-[264px] flex justify-between items-center">
            {tabs.map((tab, i) => (
              <li
                key={i}
                className={`${
                  activeTab === i + 1
                    ? "border-[#12A89D]"
                    : "border-transparent"
                } p-2 space-y-2 border-b-4 `}
              >
                <h4
                  className={`${
                    activeTab === i + 1 ? "text-primary-500" : "text-netral-4"
                  } text-xl font-semibold`}
                >
                  {tab}
                </h4>
                <p
                  className={`${
                    activeTab === i + 1 ? "text-netral-6" : "text-netral-4"
                  } font-semibold`}
                >
                  {i + 1} dari 6
                </p>
              </li>
            ))}
          </ul>
        </div>
        <main>{children}</main>
        <Footer className="mt-[112px]" />
      </div>
    </div>
  );
}

LayoutNewCourse.defaultProps = {
  activeTab: 1,
};

const tabs = [
  "Langkah 1",
  "Langkah 2",
  "Langkah 3",
  "Langkah 4",
  "Langkah 5",
  "Langkah 6",
];
