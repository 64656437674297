import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CardCourse4 from "../../components/independent/card/card-course4";
// import InputDropdownLabel from "../../components/independent/input/input-dropdown-label";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
import { _getCoursesInstructor } from "../../config/services/api/course";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icon } from "../../components/independent/image";
import CardCourse from "../../components/independent/card/card-course";
import CardCourseDashboard from "../../components/independent/card/card-course-dashboard";

const Courses = () => {
  const { token } = useSelector((state) => state.authReducer);
  const [courses, setCourses] = useState([]);
  const { push } = useHistory();
  const gradientColor =
    "linear-gradient(137deg, rgb(147, 96, 168) 30.99%, rgb(70, 183, 233) 150.87%)";

  useEffect(() => {
    const getCourses = async () => {
      try {
        const result = await _getCoursesInstructor(token);
        if (!result.status) return console.log(result.message);
        setCourses(result.class.reverse());
      } catch (error) {
        console.log("error get instructor courses", error);
      }
    };
    getCourses();
  }, [token]);
  return (
    <LayoutDashboard>
      <div className="space-y-8 mt-[calc(68px-32px)]">
        {/* <div
          style={{ boxShadow: "2px 4px 16px rgba(0, 0, 0, 0.2)" }}
          className="bg-white w-[700px] h-[168px] mx-auto rounded-lg flex items-center justify-between p-8"
        >
          <p>Langsung ke Pembuatan Kursus</p>
          <Link
            to="/new"
            className="inline-block text-center px-3 py-2 bg-secondary-button-600 text-white rounded interactive-button-color"
          >
            Buat Kursus Anda
          </Link>
        </div> */}
        <div className="flex justify-between">
          {/* <div className="space-y-3">
            <h4 className="text-2xl font-semibold text-netral-7">
              List Course
            </h4>
            <p className="text-netral-6">
              Daftar kursus yang sudah pernah dibuat
            </p>
          </div> */}
          {/* <div className="flex space-x-6">
            <InputDropdownLabel>level</InputDropdownLabel>
            <InputDropdownLabel>type</InputDropdownLabel>
          </div> */}
        </div>
        {/* <div className="grid grid-cols-4 gap-6">
          {courses.map((course, i) => (
            <CardCourse4 key={i} course={course} />
          ))}
        </div> */}
      </div>

      <div className="w-full flex flex-col gap-[2rem]">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col gap-2">
            <h4 className="text-2xl font-semibold text-netral-7">
              List Course
            </h4>
            <p className="text-netral-6">
              Daftar kursus yang sudah pernah dibuat
            </p>
          </div>

          <div
            style={{ background: gradientColor }}
            className={`flex items-center rounded-lg py-[0.5rem] px-[1rem] cursor-pointer text-white space-x-[6px] mt-auto`}
            onClick={() => push("/new")}
          >
            <Icon name="add-netral-1" w={16} h={16} />
            <span className="text-[1rem] leading-[20px] font-bold ">
              Tambah Kelas
            </span>
          </div>
        </div>

        <div
          // className="grid grid-cols-3 gap-6"
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6"
        >
          {courses?.map((course, i) => (
            <CardCourse4 key={i} course={course} />
          ))}
        </div>
      </div>
    </LayoutDashboard>
  );
};

export default Courses;
