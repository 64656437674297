import Labelform from "../../atom/Label";
import TextArea from "../../atom/TextArea";

export default function TextAreaField({classNameTextArea,
    placeholder,
    labelValue,
    isRequired,
    classNameLabel,
    htmlFor,
    idTextArea,
    cols,
    rows,
    ref,
    onChange,
    value
}) {
  return (
    <div className="flex flex-col gap-[8px]">
        <Labelform htmlFor={htmlFor} value={labelValue} className={classNameLabel} isRequired={isRequired} />
        <TextArea cols={cols} rows={rows} className={classNameTextArea} ref={ref} placeholder={placeholder} value={value} id={idTextArea} onChange={onChange}/>
    </div>
  )
}
