import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
import Quiz from "../../../components/dependent/learn-course/quiz";
import Finish from "../../../components/dependent/learn-course/quiz/Finish";
import First from "../../../components/dependent/learn-course/quiz/First";
import PrevOrNextController from "../../../components/dependent/learn-course/quiz/PrevOrNextController";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import { postPretestProgress } from "../../../config/redux/actions/courseAction";
// import { SET_PRETEST_PROGRESS } from "../../../config/redux/types";

const PreTest = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { courseId } = useParams();

  const { token } = useSelector((state) => state.authReducer);
  const { enrollment, participantProgress } = useSelector(
    (state) => state.courseReducer
  );

  let views = [{ name: "first" }, { name: "finish" }];

  if (enrollment?.pretest?.length)
    views = [{ name: "first" }, ...enrollment.pretest, { name: "finish" }];

  const [data, setData] = useState([]);
  const [activeView, setActiveView] = useState(0);

  const handleData = (value) => {
    const index = activeView - 1;
    const questionIndex = enrollment.pretest[index].questionIndex;
    data[index] = { questionIndex: questionIndex, participantAnswer: value };
    setData([...data]);
  };

  const addPretestProgress = () =>
    dispatch(postPretestProgress(token, courseId, data));

  const someDataUndefined = () => data.some((data) => !data) || !data.length;

  useEffect(() => {
    if (!participantProgress?.pretest.length) return;
    if (
      participantProgress?.pretest.some((el) => el.isCompleted) ||
      participantProgress?.pretest[participantProgress.pretest.length - 1]
        .isCompleted
    )
      setActiveView(views.length - 1);
  }, [views.length, participantProgress?.pretest]);

  return (
    <LayoutLearnCourse>
      <div
        style={{
          background:
            "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
        }}
        className="min-h-[247px] lg:h-[471px] border border-primary-300 rounded-lg flex items-center relative"
      >
        {views[activeView]?.name === "first" && (
          <First setActiveView={setActiveView} title="Pre Test" />
        )}
        {views[activeView]?.name !== "first" &&
          views[activeView]?.name !== "finish" && (
            <>
              <PrevOrNextController
                activeView={activeView}
                setActiveView={setActiveView}
                views={views}
                addProgress={addPretestProgress}
                isCompleted={!someDataUndefined()}
              />
              <Quiz
                activeView={activeView}
                views={views}
                handleData={handleData}
                value={data[activeView - 1]?.participantAnswer || false}
              />
            </>
          )}
        {views[activeView]?.name === "finish" && (
          <Finish
            title="Pre Test"
            score={
              participantProgress?.pretest[
                participantProgress.pretest.length - 1
              ]?.score
            }
            next={() => push(`/learn/${courseId}/schedule`)}
          />
        )}
      </div>
      <PrevOrNextButton
        prevActive={false}
        nextActive={activeView === views.length - 1}
        next={() => push(`/learn/${courseId}/schedule`)}
      />
    </LayoutLearnCourse>
  );
};

export default PreTest;
