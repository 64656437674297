import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import InputDropdownLabel2 from "../../../../components/independent/input/input-dropdown-label2";
import LayoutNewCourse from "../../../../components/independent/layout/layout-new-course";
import { SET_PARTICIPANT_TYPE } from "../../../../config/redux/types";

const Step5 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { participantType } = useSelector((state) => state.newCourseReducer);
  const [type, setType] = useState(participantType || types[0]);


  return (
    <LayoutNewCourse activeTab={5}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">Jenis Peserta</h3>
          <p className="text-netral-6">
            Siapa yang sasaran utama peserta kursus Anda
          </p>
        </div>
        <InputDropdownLabel2
          text="Jenis Peserta"
          values={types}
          value={type}
          setValue={setType}
        />
        <PrevOrNextButton
          active={type !== types[0]}
          prev={() => push(`/new/course/${id}/step4`)}
          next={() => {
            push(`/new/course/${id}/step6`);
            dispatch({ type: SET_PARTICIPANT_TYPE, payload: type });
          }}
        />
      </div>
    </LayoutNewCourse>
  );
};

export default Step5;

const types = ["Pilih Kategori", "Umum", "Prakerja"];
