// import InputDropdownLabel from "../../components/independent/input/input-dropdown-label";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "../../components/independent/image";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
import Modal from "../../components/independent/modal";
import TableTransaction from "../../components/independent/table/table-transaction";
import { _getUserTransaction } from "../../config/services/api/transaction";

export default function Transaction() {
  const [selectedTransaction, setSelectedTransaction] =
    useState(defaultTransaction);
  const [showDetail, setShowDetail] = useState("");
  const { token } = useSelector((state) => state.authReducer);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await _getUserTransaction(token);
        if (!res.status) throw new Error(res.message);
        setData(res.transaction);
      } catch (error) {
        alert(error);
      }
    };
    getData();
  }, [token]);

  return (
    <>
      <Modal modal={showDetail}>
        <div className="flex justify-center">
          <div className="relative lg:w-auto w-full px-4 lg:p-0">
            <button
              type="button"
              onClick={() => setShowDetail("")}
              className="absolute top-4 right-4"
            >
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
            <div className="bg-white lg:w-[644px] rounded-lg p-6 space-y-6">
              <h4 className="text-center font-semibold text-netral-10 text-xl">
                Detail Pembayaran
              </h4>
              <div className="space-y-3 pb-3 border-b-2 border-[#E0E0E0]">
                <p className="font-semibold text-netral-10">Tanggal</p>
                <p className="text-netral-9">
                  {new Date(
                    selectedTransaction.transaction_date || 0
                  ).toLocaleDateString()}
                </p>
              </div>
              <div className="space-y-3">
                <p className="font-semibold text-netral-10">
                  Kelas yang dibeli
                </p>
                <div className="space-y-2">
                  <div className="space-y-1">
                    <div className="flex items-center justify-between">
                      <p className="text-netral-9">
                        {selectedTransaction.class_title}
                      </p>
                      <p className="text-netral-9">
                        Rp.{" "}
                        {Number(selectedTransaction.price || 0).toLocaleString(
                          "id-ID"
                        )}
                      </p>
                    </div>
                    <p className="text-netral-6 text-sm">
                      Oleh {selectedTransaction.instructor_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <p className="font-semibold text-netral-10">Total Tagihan</p>
                  <p className="text-netral-9">
                    Rp. {Number(selectedTransaction.price).toLocaleString("id-ID")}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-netral-9">Discount</p>
                  <p className="text-netral-9">
                    {selectedTransaction.discount}%
                  </p>
                </div>
                <div className="flex items-center justify-between pt-3 border-t-2 border-[#E0E0E0]">
                  <p className="font-semibold text-secondary3-600">
                    Total Bayar
                  </p>
                  <p className="font-semibold text-secondary3-600">
                    Rp.{" "}
                    {Number(selectedTransaction.total_payment).toLocaleString(
                      "id-ID"
                    )}
                  </p>
                </div>
              </div>
              <div className="space-y-3">
                <p className="font-semibold text-netral-10">
                  Metode Pembayaran
                </p>
                <div className="flex items-center justify-between">
                  <p className="text-netral-9">
                    {selectedTransaction.payment_method}
                  </p>
                  {/* <p className="text-netral-9">
                    Rp.{" "}
                    {Number(selectedTransaction.total_payment).toLocaleString(
                      "id-ID"
                    )}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <LayoutDashboard>
        <div className="space-y-8">
          <div className="space-y-3 text-primary-900">
            <h4 className="text-2xl font-semibold">Transaksi</h4>
            <p>Daftar transaksi</p>
          </div>
          {/* <div className="flex items-end justify-end space-x-6">
          <InputDropdownLabel>level</InputDropdownLabel>
          <InputDropdownLabel>type</InputDropdownLabel>
        </div> */}
          {data.map((el, i) => (
            <TableTransaction
              data={el}
              key={i}
              showDetail={() => {
                setShowDetail("open modal");
                setSelectedTransaction(el);
              }}
            />
          ))}
        </div>
      </LayoutDashboard>
    </>
  );
}

const defaultTransaction = {
  class_id: "",
  class_title: "",
  class_thumbnail: "",
  participant_type: "",
  payment_method: "",
  instructor_name: "",
  invoice: "",
  price: "",
  level: "",
  transaction_date: "",
  status_payment: "",
  discount: 0,
  total_payment: "",
};
