import { Logo } from "../../independent/image";

export default function OurParthners() {
  return (
    <section className="py-8 px-[135px] space-y-8">
      <h3 className="text-netral-10 font-semibold text-2xl text-center">Mitra Kami</h3>
      <div className="flex items-center justify-center space-x-6">
        {partners.map((partner, i) => (
          <a key={i} href={partner.href}>
            <Logo name={partner.logo} />
          </a>
        ))}
      </div>
    </section>
  );
}

const partners = [
  { logo: "image 32", href: "https://www.tokopedia.com/" },
  { logo: "image 33", href: "https://pintar.co/" },
  { logo: "image 34", href: "https://www.bukalapak.com/" },
  { logo: "image 35", href: "https://www.sekolah.mu/" },
  { logo: "image 36", href: "https://pijarmahir.id/" },
];
