import getLink from "../../../../constants/getLink";
import { Icon } from "../../../independent/image";

export default function Gallery({ gallery }) {
  return (
    <div className="space-y-3">
      <h4 className="text-xl font-semibold text-netral-10">Gallery</h4>
      <div className="grid grid-cols-4 gap-6">
        {gallery.map((el, i) => (
          <div key={i} className="h-[179px] rounded-lg bg-black/50">
            <img
              src={getLink(el.link)}
              alt={el.name}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
      <button className="py-1 lg:p-1 flex space-x-2 items-center">
        <span className="font-semibold text-primary-500 text-xs lg:text-base">
          Tampilkan lebih banyak
        </span>
        <Icon
          name="arrow_forward_ios-primary-500"
          className="rotate-90 w-4 h-4 lg:w-6 lg:h-6"
        />
      </button>
    </div>
  );
}
