import React, { useEffect } from "react";
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditOrRemoveButton from "../../../../../components/dependent/new-course/edit-or-remove-button";
import ButtonPlus from "../../../../../components/independent/button/button-plus";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import {
  SET_COURSE_SCHEDULE,
  SET_FINISHED_NAV,
} from "../../../../../config/redux/types";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import InputTimeLabel from "../../../../../components/independent/input/input-time-label";
import InputDateLabel from "../../../../../components/independent/input/input-date-label";
import { Icon } from "../../../../../components/independent/image";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";

export default function CourseSchedule() {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.newCourseReducer.schedule);
  const classType = useSelector((state) => state.newCourseReducer.classType);
  // const [localSchedule, setlocalSchedule] = useState(schedule);

  const saveSchadule = (value) => {
    // setlocalSchedule(value);
    dispatch({ type: SET_COURSE_SCHEDULE, payload: value });
  };

  const addQuestion = () => {
    let temp = [...schedule];
    const defaultQuestion = {
      address: "",
      dateStart: "",
      dateEnd: "",
      locationUrl: "",
      isSaved: false,
    };
    temp.push(defaultQuestion);
    saveSchadule(temp);
  };

  const setChoiceValue = (targetIndex, key, value) => {
    const temp = [...schedule];
    temp[targetIndex][key] = value;
    saveSchadule(temp);
  };

  const setInputValue = (key, value) => {
    const temp = schedule.map((el) => ({ ...el, [key]: value }));
    saveSchadule(temp);
  };

  const deleteInput = (inputIndex) => {
    const temp = [...schedule];
    temp.splice(inputIndex, 1);
    saveSchadule(temp);
  };

  console.log(schedule);

  useEffect(() => {
    if (schedule.length > 0) {
      const findCoords = /@(.*),(.*),/;
      const value = schedule[0]?.locationUrl;
      if (value.match(findCoords)) {
        const lat = value.match(findCoords)[1];
        const lng = value.match(findCoords)[2];
        setInputValue("location", { latitude: lat, longitude: lng });
      } else {
        setInputValue("location", { latitude: 0, longitude: 0 });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule[0]?.locationUrl]);

  // (value) => {
  //   const findCoords = /@(.*),(.*),/;
  //   if (value.match(findCoords)) {
  //     const lat = value.match(findCoords)[1];
  //     const lng = value.match(findCoords)[2];
  //     setInputValue("location", { latitude: lat, longitude: lng });
  //     console.log(lat, lng);
  //   } else {
  //     setInputValue("location", { latitude: 0, longitude: 0 });
  //   }

  return (
    <LayoutNewCourseStep4
      title="Jadwal Kursus"
      subTitle="Tetapkan beberapa jadwal kursus anda"
    >
      <main>
        <div className="space-y-3 mb-8">
          <p className="text-netral-8">
            Tambahkan jadwal untuk mengadakan kursus
          </p>
        </div>
        <div>
          {!schedule.length ? (
            <ButtonPlus type="button" onClick={addQuestion}>
              Tambahkan Pertanyaan
            </ButtonPlus>
          ) : !schedule[0]?.isSaved ? (
            <div className="py-6 flex flex-col items-center bg-netral-2/60">
              <div className="w-[711px] px-6 py-4 bg-white border border-netral-6 rounded space-y-6">
                <h5 className="text-lg text-netral-10 font-semibold">
                  Jadwal Kursus
                </h5>
                <div className="space-y-3">
                  <InputTextLabel
                    text="Alamat Kursus"
                    id="title"
                    placeholder="Alamat"
                    onChange={(e) => setInputValue("address", e.target.value)}
                    value={schedule[0].address}
                  />
                  <InputTextLabel
                    text="Titik Lokasi"
                    id="title"
                    placeholder="Google maps url"
                    onChange={(e) => {
                      // setCoordinate(e.target.value);
                      setInputValue("locationUrl", e.target.value);
                    }}
                    value={schedule[0].locationUrl}
                  />
                  <div>
                    {schedule.map((val, index) => (
                      <div key={index}>
                        <div className="flex justify-between gap-5">
                          <div className="flex gap-2 w-3/5">
                            <InputDateLabel
                              text="Tanggal Mulai"
                              id="date"
                              onChange={(e) => {
                                const data =
                                  schedule[index].dateStart.split(",");
                                data[0] = e.target.value;
                                setChoiceValue(
                                  index,
                                  "dateStart",
                                  data.join(",")
                                );
                              }}
                              value={val.dateStart.split(",")[0]}
                            />
                            <InputDateLabel
                              text="Tanggal Berakhir"
                              id="date"
                              onChange={(e) => {
                                const data = schedule[index].dateEnd.split(",");
                                data[0] = e.target.value;
                                setChoiceValue(
                                  index,
                                  "dateEnd",
                                  data.join(",")
                                );
                              }}
                              value={val.dateEnd.split(",")[0]}
                            />
                          </div>
                          <div className="flex gap-2 w-2/5">
                            <InputTimeLabel
                              text="Jam Mulai"
                              id="date"
                              onChange={(e) => {
                                const date = e.target.value
                                  .split(".")
                                  .join(":");
                                const data =
                                  schedule[index].dateStart.split(",");
                                data[1] = date;
                                setChoiceValue(
                                  index,
                                  "dateStart",
                                  data.join(",")
                                );
                              }}
                              value={val.dateStart.split(",")[1]}
                            />
                            <InputTimeLabel
                              text="Jam Berakhir"
                              id="date"
                              onChange={(e) => {
                                const date = e.target.value
                                  .split(".")
                                  .join(":");
                                const data = schedule[index].dateEnd.split(",");
                                data[1] = date;
                                setChoiceValue(
                                  index,
                                  "dateEnd",
                                  data.join(",")
                                );
                              }}
                              value={val.dateEnd.split(",")[1]}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => deleteInput(index)}
                          className="pt-3 px-1 pb-5 flex items-center space-x-2 "
                        >
                          <Icon
                            name="delete_outline-secondary-3-500"
                            className="w-5 h-5"
                          />
                          <span className="text-secondary3-500 text-sm font-semibold">
                            Hapus Jadwal
                          </span>
                        </button>
                      </div>
                    ))}
                  </div>
                  <div></div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={addQuestion}
                      className="font-semibold text-netral-1 px-3 py-2 rounded bg-primary-500 border border-transparent interactive-button-color"
                    >
                      Tambah Jadwal
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-[711px] mt-8 flex space-x-6 items-center justify-end">
                <button
                  type="button"
                  className="text-secondary2-500 font-semibold"
                  onClick={() => saveSchadule([])}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // setSaved(true);
                    saveSchadule(
                      schedule.map((el) => ({ ...el, isSaved: true }))
                    );
                  }}
                  className={`${"bg-secondary2-500 text-white interactive-button-color"} font-semibold rounded py-2 px-3 border border-transparent`}
                >
                  Simpan
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-5">
              <div className="flex justify-end">
                <EditOrRemoveButton
                  edit={() =>
                    saveSchadule(
                      schedule.map((el) => ({ ...el, isSaved: false }))
                    )
                  }
                  remove={() => {
                    saveSchadule(
                      schedule.map((el) => ({ ...el, isSaved: false }))
                    );
                    saveSchadule([]);
                  }}
                />
              </div>
              <div>
                <p className="text-netral-8 text-xs">Alamat Kursus</p>
                <p className="text-netral-10 text-sm">{schedule[0]?.address}</p>
              </div>
              <div className="max-w-full">
                <p className="text-netral-8 text-xs">Titik Lokasi</p>
                <p className="text-netral-10 text-sm">
                  {schedule[0]?.locationUrl?.latitude} ,
                  {schedule[0]?.locationUrl?.longitude}
                </p>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="flex px-2 py-3 bg-netral-2">
                    <th>
                      <p className="w-10 text-sm font-semibold text-netral-10 text-left">
                        No
                      </p>
                    </th>
                    <th className="">
                      <p className="w-[300px] text-sm font-semibold text-netral-10 text-left">
                        Tanggal Mulai
                      </p>
                    </th>
                    <th className="">
                      <p className="w-[300px] text-sm font-semibold text-netral-10 text-left">
                        Tanggal Berakhir
                      </p>
                    </th>
                    <th>
                      <p className="w-[200px] text-sm font-semibold text-netral-10 text-left">
                        Jam Mulai
                      </p>
                    </th>
                    <th>
                      <p className="w-[200px] text-sm font-semibold text-netral-10 text-left">
                        Jam Berakhir
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.map((data, scheduleIndex) => (
                    <tr key={scheduleIndex} className="flex px-2 py-3">
                      <td>
                        <p className="w-10 text-sm text-netral-10">
                          {scheduleIndex + 1}
                        </p>
                      </td>
                      <td>
                        <p className="w-[300px] text-sm text-netral-10">
                          {moment(data.dateStart).format("LL")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[300px] text-sm text-netral-10">
                          {moment(data.dateEnd).format("LL")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[200px] text-sm text-netral-10">
                          {moment(data.dateStart).format("LT")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[200px] text-sm text-netral-10">
                          {moment(data.dateEnd).format("LT")}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
      <ChildComponent
        isSaved={schedule[0]?.isSaved}
        schedule={schedule}
        classType={classType}
      />
    </LayoutNewCourseStep4>
  );
}

const ChildComponent = ({ isSaved, schedule, classType }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { push } = useHistory();

  return (
    <NextOrLaterButton
      active={isSaved}
      page={"schedule"}
      next={() => {
        dispatch({ type: SET_COURSE_SCHEDULE, payload: schedule });
        dispatch({ type: SET_FINISHED_NAV, payload: "schedule" });
        // push(`/new/course/${id}/step5/interaction`);
        push(
          classType === "Workshop"
            ? `/new/course/${id}/step6/hompage`
            : `/new/course/${id}/step6/skill-test`
        );
      }}
    />
  );
};
