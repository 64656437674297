import { Link } from "react-router-dom";
// import { APIBaseURL } from "../../../../config/environment";
import getLink from "../../../../constants/getLink";
import { ClipArt, Icon } from "../../image";

export default function CardCourse3({
  course,
  link,
  status = false,
  handleCreateProgress,
  handleLearn,
}) {
  return (
    <div className="w-full lg:p-2 bg-netral-2 rounded-lg overflow-hidden flex flex-col justify-between">
      <div>
        <div className="min-h-[267px] lg:min-h-[267px] bg-netral-10 rounded-t relative">
          <img
            src={getLink(course?.class.thumbnail)}
            alt={course?.class.thumbnail}
            className="w-full h-auto object-fill"
          />
          <span className="absolute top-2 left-3 rounded bg-secondary2-500 text-netral-2 text-sm px-2 py-1">
            {course?.class.level}
          </span>
          <span
            style={{ boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.3)" }}
            className="absolute bottom-2 left-[8.5px] rounded bg-secondary-300 text-netral-10 text-xs lg:text-sm px-2 py-1 font-semibold"
          >
            {course?.class.classType === "Offline Class"
              ? "Kursus Offline"
              : course?.class.participantType.includes("WEBINAR")
              ? "Webinar"
              : course?.class?.classType === "Workshop"
              ? "Workshop"
              : " Video"}
          </span>
          <ClipArt
            name="label prakerja"
            className="absolute bottom-2 right-[8.5px]"
          />
        </div>
        <div className="mt-6 space-y-1">
          <h5 className="text-primary-900 text-lg lg:text-xl font-semibold">
            {course?.class.title}
          </h5>
          <p className="text-netral-5 text-xs lg:text-sm">
            Oleh {course?.class.intructor}
          </p>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-center justify-between space-x-2">
          <div
            style={{ width: `${course.progress.percentage || 0}%` }}
            className="h-2 lg:h-3 rounded bg-primary-500"
          ></div>
          <p className="text-primary-500 text-sm lg:text-base">
            {course.progress.percentage}%
          </p>
        </div>
        <div className="mt-1 flex items-center justify-between">
          <div className="flex items-center space-x-2 px-3 py-2">
            <Icon name="menu_book-netral-8" className="h-5 w-5 lg:w-7 lg:h-7" />
            <p className="text-netral-10 text-sm lg:text-base">
              {course.progress.completedMaterial}
            </p>
          </div>
          {/* <div className="flex items-center space-x-2 px-3 py-2">
            <Icon
              name="ondemand_video-netral-8"
              className="h-5 w-5 lg:w-7 lg:h-7"
            />
            <p className="text-netral-10 text-sm lg:text-base">
              {course.progress.watchedVideo}
            </p>
          </div> */}
        </div>
        <div className="mt-4">
          {status ? (
            <button
              onClick={() => handleLearn(course)}
              className="text-sm lg:text-base inline-block text-center font-semibold text-white bg-secondary-button-600 rounded py-2 w-full interactive-button-color"
            >
              Lanjutkan belajar
            </button>
          ) : (
            <button
              onClick={() => handleCreateProgress(course.class)}
              className="text-sm lg:text-base inline-block text-center font-semibold text-white bg-secondary-button-600 rounded py-2 w-full interactive-button-color"
            >
              Mulai belajar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
