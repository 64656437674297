import { Icon } from "../../../independent/image";

export default function EditOrRemoveButton({ edit, remove }) {
  return (
    <div className="flex space-x-5 items-center">
      <button
        type="button"
        onClick={edit}
        className="flex items-center space-x-2 p-1"
      >
        <Icon name="edit-netral-9" className="w-5 h-5" />
        <span className="text-netral-10 font-semibold">Edit</span>
      </button>
      <button
        type="button"
        onClick={remove}
        className="flex items-center space-x-2 p-1"
      >
        <Icon name="delete_outline-secondary-3-500" className="w-5 h-5" />
        <span className="text-secondary3-500 font-semibold">Hapus</span>
      </button>
    </div>
  );
}
