export default function SaveOrCancelButton({ active, save, cancel }) {
  return (
    <div className="flex space-x-6 items-center justify-end">
      <button
        type="button"
        onClick={cancel}
        className="text-secondary2-500 font-semibold"
      >
        Cancel
      </button>
      <button
        type="button"
        disabled={!active}
        onClick={save}
        className={`${
          active
            ? "bg-secondary2-500 interactive-button-color"
            : "bg-netral-5 cursor-not-allowed"
        } text-white font-semibold rounded py-2 px-3  border border-transparent`}
      >
        Simpan
      </button>
    </div>
  );
}
