import { useHistory } from "react-router-dom";
import ButtonPrimary from "../../components/independent/button/button-primary";
import LayoutAuth from "../../components/independent/layout/layout-auth";

const Success = () => {
  const { push } = useHistory();
  return (
    <LayoutAuth>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          push("/login");
        }}
        className="space-y-8"
      >
        <div className="space-y-3">
          <h4 className="text-xl font-semibold text-netral-10">
            Reset Password
          </h4>
          <p className="text-netral-7">
            Password kamu telah berhasil diubah. Silakan login kembali untuk
            masuk akun Skill.id Anda
          </p>
        </div>
        <ButtonPrimary type="submit">Login Sekarang</ButtonPrimary>
      </form>
    </LayoutAuth>
  );
};

export default Success;
