import React, { useState } from "react";
import RadioButton from "../../../atom/RadioButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    ["bold", "italic"],
  ],
};

export default function EditableQuizCard({
  questions,
  options,
  showAnswer = null,
  index,
  answer,
  handleAnswer,
  handleDelete
}) {
  const [value, setValue] = useState("");

  return (
    <div className="flex flex-col gap-6 p-6 border border-netral-4 rounded-lg">
      <p className="font-semibold text-base text-netral-9">Soal {index}</p>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
      />
      <div className="flex flex-col gap-4">
        {options.map((el, idx) => (
          <div key={idx} className="flex gap-2 items-center">
            <div className="h-5 w-5 shrink-0">
              <RadioButton
                checked={answer === el}
                value={idx}
                handleChange={() => handleAnswer(el)}
              />
            </div>
            <p className="font-normal text-sm text-netral-9">{el}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
