import {
  SET_DELETE_NEW_WEBINAR_FILES,
  SET_NEW_WEBINAR,
  SET_WEBINAR_CATEGORY,
  SET_WEBINAR_CURRICULUM,
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_GALLERY,
  SET_WEBINAR_GROUP_OF_QUESTION,
  SET_WEBINAR_HOMAPAGE,
  SET_WEBINAR_PARTICIPANT_TYPE,
  SET_WEBINAR_PRICING,
  SET_WEBINAR_SCHEDULE,
  SET_WEBINAR_TARGET_PARTICIPANTS,
  SET_WEBINAR_TITLE,
  SET_WEBINAR_SKILL_TEST,
  SET_WEBINAR_COMPETENCE,
  SET_WEBINAR_COMPETENCE_TITLE,
  SET_WEBINAR_DISCOUNT,
  SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION,
} from "../types";

export const initialNewWebinarState = {
  courseTitle: "",
  courseCategory: "",
  participantType: "",
  competenceUnit: [{
    title: "",
    competenceCode: "",
  }],
  competenceTitle:"",
  targetParticipants: [
    {
      title: "Tujuan Umum",
      description: "",
      min: 1,
      inputs: [{ placeholder: "tulis disini...", value: "" }],
    },
    {
      title: "Tujuan Khusus",
      description: "",
      min: 4,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Estimasi jadwal dan Anggaran Proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Identifikasi dan kelola risiko proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Selesaikan studi kasus untuk mengelola proyek",
          value: "",
        },
        { placeholder: "tulis disini...", value: "" },
      ],
    },
    {
      title: "Aspek Kompetensi",
      description:
        "Cantumkan keterampilan, pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta sebelum mengikuti kursus Anda. Jika tidak ada persyaratan, gunakan ruang ini sebagai peluang untuk lebih memudahkan pemula.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
    {
      title: "Untuk siapa kursus ini?",
      description:
        "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kursus Anda yang akan merasa konten kursus Anda bermanfaat. Ini akan membantu Anda menarik peserta yang tepat ke kursus Anda.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
  ],
  schedule: [{ url: "", dateStart: ",", dateEnd: "," }],
  curriculum: [],
  groupOfQuestion: [],
  gallery: [],
  homepage: {
    title: "",
    description: "",

    language: "",
    level: "",
    category: "",
    mainLesson: "",
    thumbnail: {
      // name: "label prakerja.png",
      // link: "http://122.50.7.190:5000/api/class/stream/label prakerja.png",
      // type: "image/png",
    },
    intro: {
      // duration: 86,
      // name: "videoplayback.mp4",
      // link: "http://122.50.7.190:5000/api/class/stream/videoplayback.mp4",
      // type: "video/mp4",
    },
    instructor: "",
  },
  skillTest: {},

  pricing: { price: "", profit: 0.6 },
  finished: [
    // "targetParticipants",
    // "curriculum",
    // "groupOfQuestion",
    // "interactionLink",
    // "skillTest",
    // "setting",
    // "homepage",
    // "pricing",
    // "review",
  ],
  deleteFiles: [],
};

// const initialState = {
//   courseTitle: "Fundamental css",
//   courseCategory: "IT dan Pengembangan",
//   participantType: "webinar",
//   targetParticipants: [
//     {
//       title: "Apa yang akan dipelajari oleh peserta dalam kursus Anda?",
//       description:
//         "Anda harus memasukkan setidaknya 4 tujuan atau hasil pembelajaran yang dapat diharapkan peserta setelah menyelesaikan kursus Anda.",
//       min: 4,
//       inputs: [
//         {
//           placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
//           value: "a",
//         },
//         {
//           placeholder: "Contoh: Estimasi jadwal dan Anggaran Proyek",
//           value: "b",
//         },
//         {
//           placeholder: "Contoh: Identifikasi dan kelola risiko proyek",
//           value: "c",
//         },
//         {
//           placeholder: "Contoh: Selesaikan studi kasus untuk mengelola proyek",
//           value: "d",
//         },
//         { placeholder: "tulis disini...", value: "e" },
//       ],
//     },
//     {
//       title: "Apa saja persyaratan atau prasyarat untuk mengikuti kursus Anda?",
//       description:
//         "Cantumkan keterampilan, pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta sebelum mengikuti kursus Anda. Jika tidak ada persyaratan, gunakan ruang ini sebagai peluang untuk lebih memudahkan pemula.",
//       min: 1,
//       inputs: [
//         {
//           placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
//           value: "Peran dan tanggung jawab manajer proyek",
//         },
//       ],
//     },
//     {
//       title: "Untuk siapa kursus ini?",
//       description:
//         "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kursus Anda yang akan merasa konten kursus Anda bermanfaat. Ini akan membantu Anda menarik peserta yang tepat ke kursus Anda.",
//       min: 1,
//       inputs: [
//         {
//           placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
//           value: "Peran dan tanggung jawab manajer proyek",
//         },
//       ],
//     },
//   ],
//   schedule: [
//     {
//       date: "2022-03-04",
//       end: "21:50",
//       start: "21:50",
//       url: "https://us02web.zoom.us/j/88153761557?pwd=N0QzZlAyK3BXVUs1Rkl2VGpBNm5Rdz09",
//     },
//   ],
//   gallery: [],
//   homepage: {
//     title: "judul kursus",
//     description: "deskripsi kursus",
//     age: "18",
//     maxParticipant: "10",
//     language: "bahasa indonesia",
//     level: "expert",
//     category: "IT dan Pengembangan",
//     mainLesson: "Javascript",
//     thumbnail: {
//       name: "label prakerja.png",
//       link: "http://122.50.7.190:5000/api/class/stream/label prakerja.png",
//       type: "image/png",
//     },
//     intro: {
//       duration: 86,
//       name: "videoplayback.mp4",
//       link: "http://122.50.7.190:5000/api/class/stream/videoplayback.mp4",
//       type: "video/mp4",
//     },
//     instructor: "ALFIRMAN EJHA PAHLEPI",
//   },
//   pricing: { price: "123123", profit: 0.6 },
//   finished: [
//     // "targetParticipants",
//     // "curriculum",
//     // "groupOfQuestion",
//     // "interactionLink",
//     // "skillTest",
//     // "setting",
//     // "homepage",
//     // "pricing",
//     // "review",
//   ],
//   deleteFiles: [],
// };

export default function newWebinarReducer(
  state = initialNewWebinarState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_NEW_WEBINAR:
      return payload;
    case SET_WEBINAR_TITLE:
      return { ...state, courseTitle: payload };
    case SET_WEBINAR_COMPETENCE:
      return { ...state, competenceUnit: payload };
    case SET_WEBINAR_COMPETENCE_TITLE:
      return { ...state, competenceTitle: payload };
    case SET_WEBINAR_CATEGORY:
      return { ...state, courseCategory: payload };
    case SET_WEBINAR_PARTICIPANT_TYPE:
      return { ...state, participantType: payload };
    case SET_WEBINAR_TARGET_PARTICIPANTS:
      return { ...state, targetParticipants: payload };
    case SET_WEBINAR_SCHEDULE:
      return { ...state, schedule: payload };
    case SET_WEBINAR_SKILL_TEST:
      return { ...state, skillTest: payload };
    case SET_WEBINAR_CURRICULUM:
      return { ...state, curriculum: payload };
    case SET_WEBINAR_GROUP_OF_QUESTION:
      return { ...state, groupOfQuestion: payload };
    case SET_WEBINAR_GALLERY:
      return { ...state, gallery: payload };
    case SET_WEBINAR_HOMAPAGE:
      return { ...state, homepage: payload };
    case SET_WEBINAR_PRICING:
      return { ...state, pricing: payload };
    case SET_WEBINAR_DISCOUNT:
      return { ...state, discount: payload };
    case SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION:
      return { ...state, individualTestInstruction: payload };
    case SET_WEBINAR_FINISHED_NAV:
      const targetIndex = state.finished.findIndex((el) => el === payload);
      if (targetIndex !== -1) return state;
      state.finished.push(payload);
      return state;
    case SET_DELETE_NEW_WEBINAR_FILES:
      if (!state.deleteFiles) state.deleteFiles = [];
      state.deleteFiles.push(payload);
      return state;
    default:
      return state;
  }
}
