import SearchInput from "./SearchInput";
import Carousel from "./Carousel";

export default function Hero() {
  return (
    <section className="lg:h-[609px] relative bg-contain lg:bg-cover bg-hero ">
      <div className="flex items-center justify-between h-full max-w-[1536px] mx-auto 2xl:px-0">
        <div className="w-1/2 lg:mt-24 ml-10 hidden lg:flex flex-col justify-center">
          <h2 className="text-2xl text-netral-10 font-semibold leading-[160%]">
            Temukan berbagai Kursus menarik di
            <span className="text-[#335C67]"> Skills.</span>
            <span className="text-primary2-500">id</span>
          </h2>
          <p className="my-10 text-xl font-normal leading-[160%] text-primary-900">
            Temukan berbagai kelas menarik mulai dari level pemula hingga
            professional
          </p>
          <SearchInput />
          <div className="mt-10 grid grid-cols-4 gap-4">
            {grids.map((grid, i) => (
              <div
                key={i}
                className="h-[71px] rounded-lg bg-gradient-to-b from-[#0089B7] to-[#001624] flex flex-col justify-center items-center space-y-px"
              >
                <p className="text-lg font-semibold text-secondary-100">
                  {grid.value}
                </p>
                <span className="text-sm text-netral-1 font-light">
                  {grid.text}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[72px] lg:mt-24 lg:h-[481px] lg:ml-[76px] w-full lg:w-1/2">
          <Carousel />
        </div>
      </div>
    </section>
  );
}

const grids = [
  { text: "Kursus", value: "5.000+" },
  { text: "Pemula", value: "3000+" },
  { text: "Menengah", value: "1.500+" },
  { text: "Pro", value: "5.000+" },
];
