export default function InputTimeLabel({ id, text, ...rest }) {
  return (
    <div className="space-y-2 font-medium w-full">
      <label className="text-xs lg:text-sm text-netral-8" htmlFor={id}>
        {text}
      </label>
      <div className="relative flex">
        <input
          className="w-full text-sm lg:text-base border rounded-md p-2 lg:p-3 border-[#757575] shadow-sm"
          type="time"
          id={id}
          {...rest}
        />
      </div>
    </div>
  );
}
