import Banner from "../../components/dependent/become-instructor/Banner";
import Facilities from "../../components/dependent/become-instructor/Facilities";
import Hero from "../../components/dependent/become-instructor/Hero";
import OurInstructor from "../../components/dependent/become-instructor/OurInstructor";
import OurParthners from "../../components/dependent/become-instructor/OurParthners";
import Tutorial from "../../components/dependent/become-instructor/Tutorial";
import LayoutHome from "../../components/independent/layout/layout-home";

const BecomeInstructor = () => {
  return (
    <LayoutHome>
      <div className="min-h-screen mt-[80px]">
        <Hero />
        <Facilities />
        <Tutorial />
        <OurInstructor />
        <OurParthners />
        <Banner />
      </div>
    </LayoutHome>
  );
};

export default BecomeInstructor;
