import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";

export default function Instructor() {
  const { userData } = useSelector((state) => state.authReducer);
  const isInstructor = userData?.role?.some((role) => role === "Instructor");

  return (
    <LayoutDashboard>
      <div className="space-y-8">
        <div className="space-y-3 text-primary-900">
          <h4 className="text-2xl font-semibold">
            {isInstructor ? "Instructor" : "Become Instructor"}
          </h4>
          <p>Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat</p>
        </div>
        <div className="flex items-center space-x-[20px]">
          <div className="w-full h-[437.23px] relative overflow-hidden flex items-center">
            <div className="w-[395.17px] h-[331.21px] border border-dashed border-[#12A89D] rounded-xl"></div>
            <div
              style={{
                backgroundImage: "url('/assets/illustration/instructors.png')",
              }}
              className="w-full h-full bg-contain bg-no-repeat absolute top-0 left-6"
            ></div>
          </div>
          <div className="w-full">
            <h4 className="text-primary-900 font-semibold text-2xl leading-[38.4.px]">
              {isInstructor ? "Instructor" : "Menjadi Instructor"}
            </h4>
            <p className="mt-[14.4px] text-netral-7">
              Instruktur dari seluruh dunia mengajar jutaan peserta di
              Skills.id. Kami menyediakan alat dan keterampilan untuk
              mengajarkan hal yang Anda sukai.
            </p>
            <Link
              to="/instructor"
              style={{
                boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)",
              }}
              className="inline-block mt-[27.92px] px-3 py-[9px] border border-transparent bg-secondary-button-600 text-white rounded interactive-button-color"
            >
              {isInstructor ? "Dasboard Instructor" : "Mulai Mengajar Hari Ini"}
            </Link>
          </div>
        </div>
      </div>
    </LayoutDashboard>
  );
}
