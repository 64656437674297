import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SET_NEW_COURSE } from "../../../../config/redux/types";
import { _getCourseInstructor } from "../../../../config/services/api/course";

const initialNewcourseReducer = {
  courseTitle: "",
  courseCategory: "",
  participantType: "",
  classType: "",
  competenceUnit: [{
    title: "",
    competenceCode: "",
  }],
  competenceTitle:"",
  targetParticipants: [
    {
      title: "Tujuan Umum",
      description: "",
      min: 1,
      inputs: [
        {
          placeholder: "Tulis disini...",
          value: "",
        },
      ],
    },
    {
      title: "Tujuan Khusus",
      description: "",
      min: 4,
      inputs: [
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
        {
          placeholder: "Tulis disini...",
          value: "",
        },
      ],
    },
    {
      title: "Aspek Kompetensi",
      description:
        "Cantumkan keterampilan, pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta sebelum mengikuti kursus Anda. Jika tidak ada persyaratan, gunakan ruang ini sebagai peluang untuk lebih memudahkan pemula.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
    {
      title: "Untuk siapa kursus ini?",
      description:
        "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kursus Anda yang akan merasa konten kursus Anda bermanfaat. Ini akan membantu Anda menarik peserta yang tepat ke kursus Anda.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
  ],
  curriculum: [],
  groupOfQuestion: [],
  schedule: [],
  interactionLink: [],
  skillTest: {},
  setting: [
    {
      name: "Peserta diizinkan untuk mempercepat video pembelajaran",
      value: false,
    },
    {
      name: "Peserta dapat memilih mengikuti materi yang dia sukai tanpa harus mengikuti semua materi",
      value: false,
    },
    { name: "Option 3", value: false },
    { name: "Option 4", value: false },
  ],
  homepage: {
    title: "",
    description: "",
    tools: [],
    language: "Bahasa Indonesia",
    level: "Expert",
    category: "IT dan Pengembangan",
    mainLesson: "",
    thumbnail: {},
    intro: {},
    instructor: "",
  },
  pricing: { price: "", profit: 0.6 },
  finished: [],
  dicount:0,
  deleteFiles: [],
};

const NewCourse = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const result = await _getCourseInstructor(id, token);
        if (!result.status) return console.log("something went wrong", result);
        dispatch({ type: SET_NEW_COURSE, payload: result.class });
      } catch (error) {
        console.log("api error", error);
      }
    };
    getCourse();
    return () =>
      dispatch({ type: SET_NEW_COURSE, payload: initialNewcourseReducer });
  }, [id, token, dispatch]);

  return children;
};

export default NewCourse;
