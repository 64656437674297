import { Link } from "react-router-dom";
import { Icon } from "../../image";

export default function ButtonExit({ to, children }) {
  return (
    <Link
      to={to}
      className="absolute top-0 right-8 px-3 py-2 flex items-center space-x-3 rounded border border-secondary-button-600"
    >
      <span className="text-secondary-button-600 font-semibold">
        {children}
      </span>
      <Icon name="arrow_right_alt-primary-500" className="w-[22px] h-[22px]" />
    </Link>
  );
}
