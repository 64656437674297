import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
// import { Logo } from "../../../independent/image";
// import { Link } from "react-router-dom";

SwiperCore.use([Pagination]);

export default function Carousel() {
  return (
    <div className="w-full h-full mt-[10px] lg:mt-0">
      <Swiper {...settings} className="mySwiper w-full  h-full lg:rounded-l-xl">
        <SwiperSlide className="relative flex items-center justify-end cursor-grab active:cursor-grabbing rounded-xl overflow-hidden shadow-xl odd:w-[340px] even:w-[340px] odd:h-[181px] even:h-[181px] lg:odd:w-[596px] lg:even:w-[596px] lg:odd:h-[450px] lg:even:h-[450px]">
          {/* width: 593px;
height: 449px; */}

          <div
            style={{
              backgroundImage: "url(/assets/image/carousel1.png)",
            }}
            className="w-full h-full bg-cover relative"
          >
            <div className="absolute top-[55%] right-[5%]">
              <a
                href="https://bit.ly/Penukaranskillsxprakerja"
                target="_blank"
                rel="noreferrer"
                // style={{
                //   filter: "drop-shadow(0px 12px 20px rgba(0, 0, 0, 0.3))",
                // }}
                className="inline-block text-2xs lg:text-base mt-1 lg:mt-6 text-black font-semibold bg-netral-1 border border-netral-1 py-1 px-[6px] lg:py-2 lg:px-4 rounded-[50px]"
              >
                Lihat Video
              </a>
            </div>
          </div>
          {/* <div className="absolute inline-block top-0 left-0 h-full w-full">
            <div
              style={{
                backgroundImage: "url(/assets/image/unsplash_4-EeTnaC1S4.jpg)",
              }}
              className="w-full h-full bg-cover"
            ></div>
            <div className="bg-gradient-to-t from-black absolute bottom-0 left-0 h-1/2 w-full opacity-50"></div>
          </div>
          <div className="flex justify-between items-center z-10">
            <Logo
              name="skills.id"
              className="w-[81px] lg:w-[223px] h-[24px] lg:h-[60px]"
            />
            <Logo
              name="prakerja"
              className="w-[81px] lg:w-[223px] h-[24px] lg:h-[60px]"
            />
          </div>
          <div className="flex flex-col items-end z-10">
            <h5 className="text-netral-1 text-sm lg:text-2xl">
              Mau tukar voucher Prakerja ?
            </h5>
           
              Lihat Video
            </a>
          </div> */}
        </SwiperSlide>
        <SwiperSlide className="lg:p-6 py-3 px-[6px] relative flex flex-col justify-between cursor-grab active:cursor-grabbing rounded-xl overflow-hidden shadow-xl odd:w-[340px] even:w-[340px] odd:h-[181px] even:h-[181px] lg:odd:w-[596px] lg:even:w-[596px] lg:odd:h-[450px] lg:even:h-[450px]">
          <div
            style={{
              backgroundImage: "url(/assets/image/carousel2.png)",
            }}
            className="w-full h-full bg-cover"
          ></div>
          {/* <div className="absolute inline-block top-0 left-0 h-full w-full">
            <div className="bg-gradient-to-t bg-black absolute bottom-0 left-0 h-full w-full opacity-30"></div>
          </div>
          <div className="flex justify-between items-center z-10">
            <Logo
              name="skills.id"
              className="w-[81px] lg:w-auto h-[24px] lg:h-[60px] object-contain"
            />
          </div>
          <div className="flex flex-col items-end z-10">
            <div className="w-full">
              <h5 className="text-netral-1 text-lg lg:text-2xl">Webinar</h5>
              <div className="my-[6px] lg:my-4 w-full h-0 border lg:border-2 border-[#E0E0E0] rounded-full"></div>
              <p className="text-sm lg:text-xl text-netral-1">
                Daftarkan diri kamu dan dapatkan Promo khusus{" "}
                <span className="text-secondary-200 font-semibold">
                  Selama Pandemi
                </span>
              </p>
            </div>
            <Link
              to="/register"
              style={{
                filter: "drop-shadow(0px 12px 20px rgba(0, 0, 0, 0.3))",
              }}
              className="inline-block text-2xs lg:text-base interactive-bg-color mt-1 lg:mt-6 text-black font-semibold bg-secondary-200 border border-secondary-200 py-1 px-[6px] lg:py-2 lg:px-4 rounded-[50px] interactive-button-color"
            >
              Daftar Sekarang
            </Link>
          </div> */}
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

const settings = {
  loop: true,
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 4,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    1024: {
      centeredSlides: false,
      spaceBetween: 25,
      loop: true,
    },
    100: {
      loop: false,
    },
  },
};
