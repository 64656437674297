import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import { Icon } from "../../../../components/independent/image";
import LayoutNewCourse from "../../../../components/independent/layout/layout-new-course";
import { SET_COURSE_COMPETENCE } from "../../../../config/redux/types";

const Step3 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { competenceUnit } = useSelector((state) => state.newCourseReducer);
  const dispatch = useDispatch();
  const [competence, setCompetence] = useState(competenceUnit);

  const handleChange = (value, index, key) => {
    const temp = [...competence];
    temp[index][key] = value;
    setCompetence([...temp]);
  };

  const handleAdd = () => {
    const temp = {
      title: "",
      competenceCode: "",
    };
    setCompetence([...competence, temp]);
  };

  const handleDelete = (inputIndex) => {
    const temp = [...competence];
    temp.splice(inputIndex, 1);
    setCompetence(temp);
  };

  return (
    <LayoutNewCourse activeTab={3}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">
            Unit Kompetensi
          </h3>
          <p className="text-netral-6">Pilih kode dan judul kompetensi</p>
        </div>
        <div className="">
          <div className="space-y-5">
            {competence.map((el, idx) => (
              <div className="relative">
                <div className="flex w-full justify-between space-x-3">
                  <div className="w-1/2 space-y-2">
                    <label
                      htmlFor="code"
                      className="inline-block text-sm text-netral-8 font-semibold"
                    >
                      Kode Unit
                    </label>
                    <input
                      id="code"
                      value={el.competenceCode}
                      onChange={(e) =>
                        handleChange(e.target.value, idx, "competenceCode")
                      }
                      className="p-3 w-full rounded border border-[#757575] w-full placeholder:text-netral-5"
                      placeholder="Masukan kode kompetensi"
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <label
                      htmlFor="title"
                      className="inline-block text-sm text-netral-8 font-semibold"
                    >
                      Judul Kompetensi
                    </label>
                    <input
                      id="title"
                      value={el.title}
                      onChange={(e) =>
                        handleChange(e.target.value, idx, "title")
                      }
                      className="p-3 w-full rounded border border-[#757575] w-full placeholder:text-netral-5"
                      placeholder="Masukan judul kompetensi"
                    />
                  </div>
                </div>
                {idx !== 0 && (
                  <button
                    className="absolute top-11 -right-10"
                    type="button"
                    onClick={() => {
                      competence.splice(idx, 1);
                      setCompetence([...competence]);
                    }}
                  >
                    <Icon
                      name="delete_outline-secondary-3-500"
                      className="w-6 h-6"
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="mt-7">
            <button
              type="button"
              onClick={() => handleAdd()}
              className="p-1 space-x-2 flex items-center"
            >
              <Icon name="add-primary-500" className="w-6 h-6" />
              <span className="text-primary-500">Tambahkan lebih banyak</span>
            </button>
          </div>
        </div>
        <PrevOrNextButton
          prev={() => push(`/new/course/${id}/step2`)}
          next={() => {
            dispatch({ type: SET_COURSE_COMPETENCE, payload: competence });
            push(`/new/course/${id}/step4`);
          }}
          active={true}
        />
      </div>
    </LayoutNewCourse>
  );
};

export default Step3;
