import React from "react";
import InputField from "../../InputField";
import UploadFile from "../../UploadFile";
import TextAreaField from "../../textAreaFiled";
import Labelform from "../../../atom/Label";
import RadioButton from "../../../atom/RadioButton";

export default function Material({
  materialIndex,
  data,
  handleChange,
  handleChangePopup,
}) {
  return (
    <div>
      <p className="font-semibold text-base mb-5">Materi {materialIndex + 1}</p>
      <div className="flex flex-col gap-4">
        <InputField
          addLabel={true}
          required={true}
          labelValue={"Judul Materi"}
          classNameLabel={"text-[#616161] font-medium text-[14px]"}
          htmlFor={"judul-materi"}
          inputId={"judul-materi"}
          classNameInput={
            "rounded-xl border-[#E0E0E0] p-[12px] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
          }
          placeholder={"Judul Materi"}
          type={"text"}
          value={data.title}
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <UploadFile
          labelValue={"File Materi"}
          required={true}
          inputId={"video-materials"}
          placeholderInput={"Pilih file materi"}
          selectedFile={data.file}
          handleChange={(val) => handleChange("file", val)}
          type={data?.file?.type ?  "video" : "image"}
          //   type={"video"}
          //   accept={"video/*"}
        />
        {/* <UploadFile
          labelValue={"File Materi"}
          required={true}
          inputId={"video-materials"}
          placeholderInput={"Pilih file materi"}
          value={data.additional}
          handleChange={(val) => handleChange("additional", val)}
        /> */}
        <TextAreaField
          isRequired={true}
          labelValue={"Pop Up Kuis"}
          classNameTextArea={
            "resize-none rounded-lg border-[#E0E0E0] placeholder:text-[#C2C2C2] focus:ring-[#9360A8]"
          }
          placeholder={"Pertanyaan"}
          htmlFor={"Pertanyaan"}
          idTextArea={"Pertanyaan"}
          classNameLabel={"text-[#616161] font-medium text-[14px]"}
          value={data.popup.label}
          onChange={(e) => handleChangePopup("label", e.target.value)}
        />
        <div className="flex flex-col gap-2">
          <Labelform
            value="Jawaban Pertanyaan"
            className={"text-[#616161] font-medium text-[14px] "}
            isRequired={true}
          />
          <div className="flex gap-8">
            <div className="flex gap-2">
              <div className="w-5 h-5">
                <RadioButton
                  checked={data.popup.answer === "benar"}
                  value="label"
                  handleChange={() => handleChangePopup("answer", "benar")}
                />
              </div>
              <p className="font-normal text-base">Benar</p>
            </div>
            <div className="flex gap-2">
              <div className="w-5 h-5">
                <RadioButton
                  checked={data.popup.answer === "salah"}
                  value="label2"
                  handleChange={() => handleChangePopup("answer", "salah")}
                />
              </div>
              <p className="font-normal text-base">Salah</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
