import { Link } from "react-router-dom";
import { Icon, Logo } from "../../image";

export default function Footer({ className }) {
  return (
    <footer className={`${className} text-white bg-black p-4 lg:p-8`}>
      <Link to="/" className="flex items-center space-x-3">
        <Logo name="skills-id" className="w-10 h-[50px] object-contain" />
        <h4 className={`text-xl text-netral-1`}>skills.id</h4>
      </Link>
      <nav className="mt-4 lg:mt-8 flex lg:space-x-20 items-center justify-between lg:justify-start">
        <ul className="lg:flex-1 flex space-x-4 lg:space-x-[43px]">
          {navs.map((nav, i) => (
            <li key={i}>
              <Link
                to={nav.link}
                className="text-primary-100 hover:text-white text-sm lg:text-lg font-semibold whitespace-nowrap"
              >
                {nav.text}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex-1 hidden lg:block"></div>
        <ul className="lg:flex-1 flex items-center space-x-3 lg:space-x-8">
          {socialMedias.map((el, i) => (
            <li key={i}>
              <a href={el.link} target="_blank" rel="noreferrer">
                <Icon
                  name={el.icon + "-netral-1"}
                  className="w-6 h-6 lg:h-8 lg:w-8"
                />
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="my-4 lg:my-14">
        {/* <p className="lg:text-lg font-semibold">
          Langganan berita <span className="text-primary-500">Skills.</span>
          <span className="text-primary2-500">id</span>
        </p>
        <div className="mt-2 lg:mt-4 flex items-center space-x-1 lg:space-x-[17px]">
          <input
            placeholder="Masukkan email Anda"
            style={{ boxShadow: "1px 4px 25px rgba(0, 0, 0, 0.1)" }}
            className="bg-white rounded-sm lg:rounded w-[126px] lg:w-[279px] p-1 lg:p-2 text-xs"
          />
          <button
            style={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.16)" }}
            className="text-xs lg:text-base rounded-sm lg:rounded bg-secondary-button-600 text-white p-1 lg:p-0 lg:py-2 lg:px-3 font-semibold"
          >
            Subscribe
          </button>
        </div> */}
      </div>
      <div className="flex space-x-4 lg:space-x-20">
        <div className="flex-1">
          <h3 className="lg:text-xl">Contact</h3>
          <p className="text-2xs lg:text-base mt-2 lg:mt-4">
            PT. Global Retail Bersama
          </p>
          <p className="text-2xs lg:text-base mt-1 lg:mt-3">
            Jl. Kyai Maja No.7, RT.4/RW.1, Kramat Pela, Kec. Kby. Baru, Kota
            Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130
          </p>
          <p className="text-2xs lg:text-base mt-2 lg:mt-3 flex items-center space-x-1">
            <Icon name="phone-netral-1" />
            <span>+62 811-828-6996</span>
          </p>
          <p className="text-2xs lg:text-base mt-1 lg:mt-3 flex items-center space-x-2">
            <Icon name="mail_outline-netral-1" />
            <span>info@skills.id</span>
          </p>
        </div>
        <div className="flex-1">
          <h3 className="lg:text-xl">Category</h3>
          <ul className="grid grid-cols-2 gap-x-1 lg:gap-x-3 lg:gap-y-4 mt-1 lg:mt-4">
            {categories.map((category, i) => (
              <li key={i}>
                <p
                  // href={category.link}
                  className="text-2xs lg:text-base text-primary-100 hover:text-white"
                >
                  {category.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1">
          <h3 className="lg:text-xl">Branch</h3>
          <ul className="grid grid-cols-2 gap-x-1 lg:gap-x-3 lg:gap-y-4 mt-1 lg:mt-4">
            {branches.map((branch, i) => (
              <li key={i}>
                <p
                  // href={branch.link}clea
                  className="text-2xs lg:text-base text-primary-100 hover:text-white"
                >
                  {branch.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

const navs = [
  { text: "Home", link: "/" },
  { text: "Privacy", link: "/privacy-and-policy" },
  { text: "Term", link: "/term-and-condition" },
  { text: "FAQ", link: "/faq" },
  { text: "About Us", link: "/about" },
];

const categories = [
  { text: "Music", link: "#tes" },
  { text: "Music", link: "#tes" },
  { text: "Art and Craft", link: "#tes" },
  { text: "Art and Craft", link: "#tes" },
  { text: "Food and Beverages", link: "#tes" },
  { text: "Food and Beverages", link: "#tes" },
  { text: "Design", link: "#tes" },
  { text: "Design", link: "#tes" },
  { text: "Photography", link: "#tes" },
  { text: "Photography", link: "#tes" },
  { text: "Sport", link: "#tes" },
  { text: "Sport", link: "#tes" },
];

const branches = [
  { text: "Jakarta", link: "#tes" },
  { text: "Bali", link: "#tes" },
  { text: "Tangerang", link: "#tes" },
  { text: "Surabaya", link: "#tes" },
  { text: "Bekasi", link: "#tes" },
  { text: "Bandung", link: "#tes" },
  { text: "Medan", link: "#tes" },
];

const socialMedias = [
  { icon: "Instagram", link: "https://www.instagram.com/skillsidofficial/" },
  { icon: "Facebook", link: "https://www.facebook.com/skillsidofficial" },
  {
    icon: "YouTube",
    link: "https://www.youtube.com/channel/UClRWyHRLYqyRtrUNpnBRLig",
  },
  { icon: "Twitter", link: "https://twitter.com/skills_id" },
];
