import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import EditOrRemoveButton from "../../../../../components/dependent/new-course/edit-or-remove-button";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import SaveOrCancelButton from "../../../../../components/dependent/new-course/save-or-cancel-button";
import { Icon } from "../../../../../components/independent/image";
import InputDateLabel from "../../../../../components/independent/input/input-date-label";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import InputTimeLabel from "../../../../../components/independent/input/input-time-label";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import {
  SET_FINISHED_NAV,
  SET_INTERACTION_LINK,
} from "../../../../../config/redux/types";

const Interaction = () => {
  const [isSaved, setSaved] = useState(false);
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.newCourseReducer.interactionLink);
  
  const setDatas = (value) => {
    dispatch({ type: SET_INTERACTION_LINK, payload: value });
  };

  const addData = () => {
    const defaultData = {
      date: "",
      end: "",
      start: "",
      url: "",
    };
    datas.push(defaultData);
    setDatas([...datas]);
  };

  const deleteData = (targetIndex) => {
    datas.splice(targetIndex, 1);
    setDatas([...datas]);
  };

  const setInputValue = (targetIndex, key, value) => {
    datas[targetIndex][key] = value;
    setDatas([...datas]);
  };

  return (
    <LayoutNewCourseStep4
      title="Interaksi dengan Peserta"
      subTitle="Tentukan aturan kelas Anda. "
    >
      <p className="text-netral-8">
        Tambahkan link beberapa link yang dapat diakses oleh peserta agar Anda
        dan para peserta dapat berinteraksi secara langsung
      </p>
      {!isSaved ? (
        <div className="mt-6 py-6 px-[79px] space-y-6 bg-netral-2/60 ">
          <div className="px-6 py-4 bg-white rounded border border-netral-6">
            <h6 className="text-netral-10 font-semibold text-lg">
              Link Interaksi
            </h6>
            {datas.map((data, i) => (
              <div key={i} className="mt-6 space-y-2">
                <div className="grid grid-cols-3 gap-x-6 gap-y-3">
                  <InputDateLabel
                    id="birthday"
                    text="Tanggal Lahir"
                    value={data.date}
                    onChange={(e) => setInputValue(i, "date", e.target.value)}
                  />
                  <InputTimeLabel
                    type="time"
                    id="start"
                    text="Jam mulai"
                    value={data.start}
                    onChange={(e) => setInputValue(i, "start", e.target.value)}
                  />
                  <InputTimeLabel
                    type="time"
                    id="end"
                    text="Jam berakhir"
                    value={data.end}
                    onChange={(e) => setInputValue(i, "end", e.target.value)}
                  />
                  <div className="col-span-3">
                    <InputTextLabel
                      id="url"
                      text="Masukkan url"
                      placeholder="Masukkan url"
                      value={data.url}
                      onChange={(e) => setInputValue(i, "url", e.target.value)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => deleteData(i)}
                  className="p-1 flex items-center space-x-2"
                >
                  <Icon
                    name="delete_outline-secondary-3-500"
                    className="w-5 h-5"
                  />
                  <span className="text-secondary3-500 text-sm font-semibold">
                    Hapus Link
                  </span>
                </button>
              </div>
            ))}
            <div className="mt-5 flex justify-end">
              <button
                type="button"
                onClick={addData}
                className="font-semibold text-white px-3 py-2 bg-primary-500 rounded"
              >
                Tambah Link
              </button>
            </div>
          </div>
          <SaveOrCancelButton
            active={true}
            save={() => setSaved(true)}
            cancel={() => setDatas([])}
          />
        </div>
      ) : (
        <div className="mt-6">
          <div className="flex justify-end">
            <EditOrRemoveButton
              edit={() => setSaved(false)}
              remove={() => setDatas([])}
            />
          </div>
          <table className="mt-5 w-full rounded-lg overflow-hidden">
            <thead>
              <tr className="w-full px-2 py-3 flex space-x-2 items-center bg-netral-2">
                <th>
                  <p className="w-10 text-sm font-semibold text-netral-10 text-left">
                    No
                  </p>
                </th>
                <th className="w-full">
                  <p className="text-sm font-semibold text-netral-10 text-left">
                    Tanggal
                  </p>
                </th>
                <th className="w-full">
                  <p className="text-sm font-semibold text-netral-10 text-left">
                    Jam Mulai
                  </p>
                </th>
                <th className="w-full">
                  <p className="text-sm font-semibold text-netral-10 text-left">
                    Jam Berakhir
                  </p>
                </th>
                <th>
                  <p className="w-[368px] text-sm font-semibold text-netral-10 text-left">
                    Url
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {datas.map((data, i) => (
                <tr
                  key={i}
                  className="w-full px-2 py-3 flex space-x-2 items-center"
                >
                  <th>
                    <p className="w-10 text-sm text-netral-10 text-left font-normal">
                      {i + 1}
                    </p>
                  </th>
                  <th className="w-full">
                    <p className="text-sm text-netral-10 text-left font-normal">
                      {data.date}
                    </p>
                  </th>
                  <th className="w-full">
                    <p className="text-sm text-netral-10 text-left font-normal">
                      {data.start}
                    </p>
                  </th>
                  <th className="w-full">
                    <p className="text-sm text-netral-10 text-left font-normal">
                      {data.end}
                    </p>
                  </th>
                  <th>
                    <p className="w-[368px] text-sm text-netral-10 text-left font-normal">
                      {data.url}
                    </p>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ChildComponent datas={datas} isSaved={isSaved} />
    </LayoutNewCourseStep4>
  );
};

export default Interaction;

const ChildComponent = ({ isSaved, datas }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();


  return (
    <NextOrLaterButton
      active={isSaved}
      page={"interactionLink"}
      next={() => {
        dispatch({ type: SET_INTERACTION_LINK, payload: datas });
        dispatch({ type: SET_FINISHED_NAV, payload: "interactionLink" });
        push(`/new/course/${id}/step6/skill-test`);
      }}
    />
  );
};
