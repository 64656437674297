import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import { Icon } from "../../../../../components/independent/image";
// import InputDropdownLabel2 from "../../../../../components/independent/input/input-dropdown-label2";
import InputNumberLabel from "../../../../../components/independent/input/input-number-label";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
  SET_WEBINAR_PRICING,
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_DISCOUNT,
} from "../../../../../config/redux/types";

const Pricing = () => {
  const dispatch = useDispatch();
  const { pricing, discount } = useSelector((state) => state.newWebinarReducer);
  const setPricing = (value) =>
    dispatch({ type: SET_WEBINAR_PRICING, payload: value });

  const setDiscount = (value) =>
    dispatch({ type: SET_WEBINAR_DISCOUNT, payload: value });

  return (
    <LayoutNewWebinarStep4
      title="Penetapan Harga"
      subTitle="Tetapkan harga kursus Anda"
    >
      <div className="mt-8 flex items-start justify-between">
        <div className="flex flex-col space-y-[14px] w-full">
          {/* <div className="w-max">
            <InputDropdownLabel2 text="Mata Uang" values={["IDR"]} />
          </div> */}
          <div className="flex space-x-3">
            <div className="w-[50%]">
              <InputNumberLabel
                text="Harga Kursus"
                id="pricing"
                value={pricing.price}
                onChange={(e) =>
                  setPricing({ ...pricing, price: e.target.value })
                }
              />
            </div>
            <div className="w-[50%]">
              <div className="space-y-2 font-medium w-full">
                <label
                  className="text-xs lg:text-sm text-netral-8"
                  htmlFor={"Diskon"}
                >
                  Diskon
                </label>
                <div className="relative flex border border-[#757575] h-[50px] shadow-sm rounded-md">
                  <input
                    className="w-full text-sm lg:text-base  p-2 lg:p-3 border-none"
                    type="number"
                    id={"Diskon"}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                  <div className=" h-[48px] w-[40px] flex items-center justify-center bg-primary-100 rounded-r-md">
                    <p>%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-[286px]">
            <InputNumberLabel
              text="Harga Kursus"
              id="pricing"
              value={pricing.price}
              onChange={(e) =>
                setPricing({ ...pricing, price: e.target.value })
              }
            />
          </div> */}

          <div className="flex space-x-[10px] bg-[#CCEAEB] rounded-[5px] py-[9px] pr-[26px] pl-[10px] w-[286px]">
            <Icon
              name="information (2) 1.-primary-500"
              className="w-[18px] h-[18px]"
            />
            <p className="text-[11px] text-primary-800">
              Total pendapatan belum termasuk potongan sesuai MOU yang telah di
              sepakati
            </p>
          </div>
        </div>
      </div>
      <ChildComponent pricing={pricing} />
    </LayoutNewWebinarStep4>
  );
};

export default Pricing;

const ChildComponent = ({ pricing }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      active={!isNaN(parseInt(pricing.price))}
      page={"pricing"}
      next={() => {
        dispatch({ type: SET_WEBINAR_PRICING, payload: pricing });
        dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: "pricing" });
        push(`/new/webinar/${id}/step5/competence-unit`);
      }}
    />
  );
};

// const infos = [
//   "Tatap Muka dan Webinar 80% pengajar , 20% Skills.id dari harga kelas yang Anda masukkan",
//   "Kelas video 70% pengajar, 30% Skills.id dari harga kelas yang Anda masukkan",
//   "Kelas video Prakerja 60% pengajar, 40% Skills.id dari harga kelas yang Anda masukkan",
// ];
