import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { _getCertificate } from "../../../config/services/api/course";
import ReactToPdf from "react-to-pdf";
import { SET_ALERT } from "../../../config/redux/types";
import QRCode from "react-qr-code";
import moment from "moment";

export default function DetailCertificate() {
  const dispatch = useDispatch();
  const ref1 = useRef();
  const ref2 = useRef();
  const { replace } = useHistory();
  const { courseId } = useParams();
  const { token, userData } = useSelector((state) => state.authReducer);
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    if (!token) return;
    _getCertificate(token, courseId)
      .then((res) => {
        if (!res.status) {
          dispatch({
            type: SET_ALERT,
            payload: { status: false, message: res.message },
          });
          replace("/student/certificate");
        }
        setData(res);
      })
      .catch((e) => console.log("error get certificate", e));
  }, [courseId, token, replace, dispatch]);

  return data.course_title ? (
    <div className="relative w-full h-full">
      <div className="space-y-8 flex flex-col items-center pt-10 absolute z-[99999] bg-netral-1">
        <div className="space-y-2">
          <ReactToPdf
            targetRef={ref1}
            filename="certificate.pdf"
            options={{ orientation: "landscape" }}
          >
            {({ toPdf }) => (
              <button
                onClick={toPdf}
                className="rounded-full bg-netral-4 hover:bg-netral-5 text-netral-10 px-4"
              >
                Download
              </button>
            )}
          </ReactToPdf>
          <div
            ref={ref1}
            style={{ fontFamily: "Helvetica" }}
            className="h-[calc(2380px/3)] w-[calc(3408px/3)] bg-contain bg-no-repeat relative "
          >
            {courseId === "63c4467395916094ed779226" ? (
              <img
                src={`/assets/certificate/kelulusan_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }_dudika.jpeg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            ) : courseId === "63c8f181de772832733326e5" ? (
              <img
                src={`/assets/certificate/kelulusan_analisis_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }.jpg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            ) : (
              <img
                src={`/assets/certificate/kelulusan_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }.jpeg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            )}
            {/* <img
              src={`/assets/certificate/kelulusan_${
                data.vendor === "test" ? "tokopedia" : data.vendor
              }${data.predicate === "Sangat Baik" ? "_baik" : ""}_${
                courseId === "63c4467395916094ed779226" ? "dudika" : ""
              }.jpeg`}
              alt="cert"
              className="w-full h-full absolute top-0 left-0 "
            /> */}
            <h4
              className={`text-lg text-netral-10 italic font-bold absolute ${
                data.predicate === "Sangat Baik" ? "top-[350px]" : "top-[360px]"
              } left-[300px] w-[680px] text-center`}
            >
              {data.course_title}
            </h4>
            <h1
              className={`text-2xl text-netral-10 italic font-black absolute  ${
                data.predicate === "Sangat Baik" ? "top-[400px]" : "top-[450px]"
              } left-[300px] w-[680px] text-center`}
            >
              {data.certificate_name}
            </h1>
            <h6 className="text-netral-10 italic absolute top-[530px] left-[300px] w-[680px] text-center">
              {moment(data.certificate_generate_date).format(
                "dddd , Do MMMM YYYY"
              )}
            </h6>
            <div className="relative top-[550px] left-[200px] w-[150px]">
              <QRCode
                style={{
                  height: 150,
                  width: 150,
                  maxWidth: "100%",
                }}
                value={`https://skills.id/certificate?courseId=${courseId}&studentId=${userData._id}`}
                viewBox={`0 0 256 256`}
              />
              <p className="text-netral-10 text-center my-1 font-medium">
                No.{new Date(data.certificate_generate_date).getTime()}
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <ReactToPdf
            targetRef={ref2}
            filename="score.pdf"
            options={{ orientation: "landscape" }}
          >
            {({ toPdf }) => (
              <button
                onClick={toPdf}
                className="rounded-full bg-netral-4 hover:bg-netral-5 text-netral-10 px-4"
              >
                Download
              </button>
            )}
          </ReactToPdf>
          <div
            ref={ref2}
            style={{ fontFamily: "Helvetica" }}
            className="h-[calc(2380px/3)] w-[calc(3408px/3)] bg-contain bg-no-repeat relative"
          >
            <img
              src={`/assets/certificate/daftar_penilaian${
                data.vendor === "Umum" ? "_umum" : ""
              }.jpeg`}
              alt="cert"
              className="w-full h-full absolute top-0 left-0 "
            />
            <div className="relative top-[200px] flex justify-center">
              <p className="text-netral-10 text-center text-lg w-[700px] whitespace-pre-wrap">
                {data.competenceTitle}
              </p>
            </div>
            <div className="relative top-[280px] flex-col flex items-center">
              {data.competenceUnit.map((el, idx) => (
                <p className="text-netral-10 text-center text-lg ">
                  <span
                    className="font-bold
              "
                  >
                    {idx + 1}. {el.competenceCode}
                  </span>{" "}
                  - {el.title}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );
}

const defaultData = {
  course_title: "",
  certificate_name: "",
  finished_date: "",
  vendor: "",
  certificateType: "",
  certificate_generate_date: "",
  competenceUnit: {
    competenceCode: "",
    title: "",
  },
  competenceTitle: "",
  nilai: [],
};
