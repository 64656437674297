import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import { _getCourses } from "../../../../config/services/api/course";
// import { _getWebinars } from "../../../../config/services/api/webinar";
import CardCourse from "../../../independent/card/card-course";
// import CardWebinar from "../../../independent/card/card-webinar";
import { Icon } from "../../../independent/image";

SwiperCore.use([Navigation]);

export default function WorkshopList() {
  const [workshop, setWorkshop] = useState([]);

  useEffect(() => {
    _getCourses()
      .then((res) => {
        if (res.status)
          setWorkshop(res.class.filter((el) => el.classType === "Workshop"));

      })
      .catch((e) => console.log("cannot get workshop", e));
  }, []);

  // For Testing Only
  // useEffect(() => {
  //   _getCourses()
  //     .then((res) => {
  //       if (res.status) {
  //         const data = res.class.filter((el) => el.classType === "Workshop");
  //         setWorkshop(
  //           data.map((el, idx) => ({ ...el, discount: idx === 1 ? 0 : 50 }))
  //         );
  //       }
  //     })
  //     .catch((e) => console.log("cannot get workshop", e));
  // }, []);

  return (
    <section className="mt-8 lg:mt-[calc(7rem-2rem)]">
      <div className="text-netral-10 pl-4 pr-4 lg:pl-3 lg:pr-[51px]">
        <div className="mt-2 lg:mt-3 flex items-center justify-between">
          <h2 className="text-lg lg:text-2xl font-semibold leading-[40px]">
            Kelas Workshop
          </h2>
          <Link
            to="/course?type=Umum"
            className="text-xs lg:text-base text-primary2-500 font-semibold space-x-1 lg:space-x-[10px] flex items-center"
          >
            <span>Lihat semua</span>
            <Icon name="arrow_right_alt-primary-2-500" />
          </Link>
        </div>
      </div>
      <div className="m-4 lg:mt-[20px] lg:flex ">
        <div className="w-full ">
          <Swiper {...settings} className="mySwiper h-full">
            {workshop.map((webinar) => (
              <SwiperSlide
                key={webinar._id}
                className="lg:p-8 odd:w-[137px] even:w-[137px] lg:odd:w-[270px] lg:even:w-[270px]"
              >
                <CardCourse course={webinar} isPrakerja={false} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

const settings = {
  slidesPerView: "auto",
  spaceBetween: 16,
  pagination: false,
  navigation: true,
  breakpoints: {
    1024: {
      spaceBetween: 40,
    },
  },
};
