import React from "react";
import { Icon } from "../../independent/image";

export default function CheckBox({
  checked,
  description,
  onClickChecked,
  onClickCheckbox,
}) {
  return (
    <div className="flex w-full gap-[16px]">
      <div className="flex-shrink-0 cursor-pointer w-.5/5">
        {checked ? (
          <Icon name={"Checked_Box"} onClick={onClickChecked} />
        ) : (
          <Icon name={"Check_Box_Outline"} onClick={onClickCheckbox} />
        )}
      </div>
      <p className="flex-grow text-[16px] font-normal text-[#404040] ">
        {description}
      </p>
    </div>
  );
}
