import { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { BigPlayButton, ControlBar, Player } from "video-react";
import getLink from "../../../../constants/getLink";
import { Logo } from "../../../independent/image";
const PDFJS = window.pdfjsLib;

export default function PreviewFullScreen({
  type,
  storage,
  link,
  setCurrentTime,
  setIsFullScreen,
  pdfRendering,
  pageRendering,
  images,
}) {
  const [video, setVideo] = useState(null);
 
  const handleStateChange = (state) => setVideo(state);

  useEffect(() => {
    if (storage === "youtube") return;
    if (type !== "video/mp4") return;
    if (!video || !link || !setCurrentTime) return;
    if (!video?.getState()) return;

    video?.subscribeToStateChange(handleStateChange);
    const { player } = video.getState();
    setCurrentTime(player.currentTime);
  }, [video, link, setCurrentTime, type, storage]);

  return (
    <div className="absolute h-[100vh] w-[100vw] z-20 top-0 left-0 bg-netral-4 overflow-scroll">
      <div
        className="fixed z-30 top-5 right-5 bg-netral-3 p-1 shadow-md cursor-pointer"
        onClick={() => setIsFullScreen(false)}
      >
        <Logo name={"fullScreen"} className={"w-[30px] h-[30px]"} />
      </div>
      <div>
        {pdfRendering || pageRendering ? (
          <p>loading...</p>
        ) : (
          <div className="flex flex-col space-y-10 absolute top-0 p-6 w-[100vw] items-center">
            {images.map((el, idx) => (
              <div key={idx}> 
                <img src={el} alt="pdf" />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
