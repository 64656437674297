import PrakerjaCourses from "../components/dependent/home/prakerja-courses";
import ClaimVoucherBanner from "../components/dependent/prakerja/claim-voucher-banner";
import Facility from "../components/dependent/prakerja/facility";
import FaqBanner from "../components/dependent/prakerja/faq-banner";
import Hero from "../components/dependent/prakerja/hero";
import HowToParticipate from "../components/dependent/prakerja/how-to-participate";
import Info from "../components/dependent/prakerja/info";
import PrakerjaPartners from "../components/dependent/prakerja/prakerja-partners";
import Footer from "../components/independent/footer/footer";
import HeaderTransparent from "../components/independent/header/header-transparent";
import NavHeader from "../components/independent/navigation/nav-header";

const Prakerja = () => {
  return (
    <>
      <HeaderTransparent className="fixed top-0">
        <NavHeader />
      </HeaderTransparent>
      <main className="mt-[72px] lg:mt-0">
        <Hero />
        <ClaimVoucherBanner />
        <Info />
        <Facility />
        <HowToParticipate />
        <PrakerjaCourses />
        <PrakerjaPartners />
        <FaqBanner />
      </main>
      <Footer />
    </>
  );
};

export default Prakerja;
