import { Link } from "react-router-dom";
import getLink from "../../../../constants/getLink";
import shortPrice from "../../../../constants/shortPrice";
// import { Icon } from "../../image";

export default function CardCourseLg({ data, isWebinar }) {
  const title = data.title.split(" ").join("-").split("/").join("-");

  // console.log(isWebinar)

  // console.log(data)
  return (
    <div className="flex items-center space-x-6 px-6 py-4 rounded-lg bg-netral-2/50">
      <div
        style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.3)" }}
        className="w-1/3 h-full overflow-hidden rounded bg-black/50 relative"
      >
        <span className="absolute top-[8.74px] left-[9.05px] px-2 py-1 rounded bg-secondary2-100 text-sm text-netral-10">
          {data.level}
        </span>
        <span className="absolute bottom-[11.33px] left-[6.29px] px-2 py-1 rounded bg-secondary-300 text-sm text-netral-10 font-semibold">
          {data.classType === "Offline Class"
            ? "Kursus Offline"
            : isWebinar
            ? "Webinar"
            : data?.classType === "Workshop"
            ? "Workshop"
            : "Video"}
        </span>
        <img
          src={getLink(data.thumbnail)}
          alt={data.thumbnail}
          className="w-full h-[284px] object-fill"
        />
      </div>
      <div className="w-2/3 space-y-4">
        <div className="space-y-2">
          <h5 className="text-lg text-primary2-700">{data.category}</h5>
          <h4 className="text-xl font-semibold text-primary-900">
            {data.title}
          </h4>
          <p className="text-sm text-netral-6 pb-2 border-b border-b-netral-5">
            Oleh {data.instructor}
          </p>
          <div className="flex items-center justify-between">
            <div className="space-y-6">
              {/* <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-[10.85px]">
                  <Icon name="remove_red_eye-netral-8" className="w-6 h-6" />
                  <h6 className="font-semibold text-netral-10 text-sm">5.3k</h6>
                </div>
                <div className="flex items-center space-x-[10.85px]">
                  <Icon name="Group-netral-8" className="w-6 h-6" />
                  <h6 className="font-semibold text-netral-10 text-sm">66k</h6>
                </div>
                <div className="flex items-center space-x-[10.85px]">
                  <Icon name="star_rate-netral-8" className="w-6 h-6" />
                  <h6 className="font-semibold text-netral-10 text-sm">
                    {data.rating?.totalRating}
                  </h6>
                </div>
              </div> */}
              <Link
                to={
                  isWebinar
                    ? `/webinar/${title}/${data._id}`
                    : `/course/${title}/${data._id}`
                }
                className="inline-block text-center text-secondary2-600 py-2 w-[179px] rounded border border-secondary2-600 interactive-button-color"
              >
                Beli
              </Link>
            </div>
            <div>
              {data?.discount && data?.discount > 0 ? (
                <div>
                  <div className="flex items-center space-x-2">
                    <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
                      {data?.discount}%
                    </p>
                    <p className="text-secondary-button-600 line-through text-base font-semibold">
                      Rp{parseInt(data?.price || "0").toLocaleString("id-ID")}
                    </p>
                  </div>
                </div>
              ) : null}
              <h3 className="text-2xl font-semibold text-primary-700">
                Rp{" "}
                {parseInt(
                  data?.price - (data?.price * data?.discount) / 100 || "0"
                ).toLocaleString("id-ID")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
