import MaterialOption from "../material-option";
import FormLesson from "../form-lesson";
import FormQuiz from "../form-quiz";
import Review from "../review";
import { useCurriculum } from "../../../../pages/new/course/[course-id]/step6/Curriculum";
import SaveOrCancelButton from "../../new-course/save-or-cancel-button";
import InputTextLabel from "../../../independent/input/input-text-label";
import ButtonPlus from "../../../independent/button/button-plus";
import InputTextareaLabel from "../../../independent/input/input-textarea-label";

export default function Chapter({ chapterIndex, isSession }) {
  const { chapters, setChapters } = useCurriculum();

  const addMaterial = () => {
    chapters[chapterIndex].materials.push({
      lesson: null,
      quiz: null,
      view: "MaterialOption",
    });
    setChapters([...chapters]);
  };

  const changeValue = (key, value) => {
    chapters[chapterIndex][key] = value;
    setChapters([...chapters]);
  };

  const deleteChapter = () => {
    chapters.splice(chapterIndex, 1);
    setChapters([...chapters]);
  };

  const isAllMaterialSaved = () => {
    if (!chapters[chapterIndex].title) return false;
    if (!chapters[chapterIndex].description) return false;

    const isAllMaterialOnReview = chapters[chapterIndex].materials.every(
      (material) => material.view === "Review"
    );
    if (isAllMaterialOnReview) return true;

    return false;
  };

  const saveChapter = () => {
    chapters[chapterIndex].isSaved = true;
    setChapters([...chapters]);
  };

  return (
    <div className="pt-6 pl-[25px] pr-[60px] pb-[49px] bg-netral-2/60 flex space-x-6">
      <h6 className="text-lg font-semibold whitespace-nowrap">
        {isSession ? "Sesi" : "Bagian"} {chapterIndex + 1}:
      </h6>
      <div className="w-full">
        <div className="space-y-4">
          <InputTextLabel
            text="Judul Bagian"
            id="title"
            placeholder="Masukkan Judul Bagian"
            onChange={(e) => changeValue("title", e.target.value)}
            value={chapters[chapterIndex].title}
          />
          <InputTextareaLabel
            text="Deskripsi"
            id="description"
            placeholder="Masukkan Tujuan belajar materi ini"
            onChange={(e) => changeValue("description", e.target.value)}
            value={chapters[chapterIndex].description}
          />
        </div>
        <div className="mt-6 space-y-6">
          {chapters[chapterIndex].materials.map((material, materialIndex) => (
            <Material
              key={materialIndex}
              material={material}
              materialIndex={materialIndex}
              chapterIndex={chapterIndex}
            />
          ))}
          <ButtonPlus type="button" onClick={addMaterial}>
            Tambah Materi
          </ButtonPlus>
          <div className="mt-8">
            <SaveOrCancelButton
              active={isAllMaterialSaved()}
              save={saveChapter}
              cancel={deleteChapter}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const Material = ({ material, materialIndex, chapterIndex }) => (
  <div>
    {material.view === "MaterialOption" && (
      <MaterialOption
        materialIndex={materialIndex}
        chapterIndex={chapterIndex}
      />
    )}
    {material.view === "FormLesson" && (
      <FormLesson chapterIndex={chapterIndex} materialIndex={materialIndex} />
    )}
    {material.view === "FormQuiz" && (
      <FormQuiz chapterIndex={chapterIndex} materialIndex={materialIndex} />
    )}
    {material.view === "Review" && (
      <Review chapterIndex={chapterIndex} materialIndex={materialIndex} />
    )}
  </div>
);
