import { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Icon, Logo } from "../../components/independent/image";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
import {
  _getPrakerjaCardNumber,
  _postPrakerjaCardNumber,
} from "../../config/services/api/prakerja";

export default function Prakerja() {
  const { token } = useSelector((state) => state.authReducer);
  const [isEdit, setEdit] = useState(false);
  const [prakerjaCardNumber, setPrakerjaCardNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const getPrakerjaCardNumber = useCallback(async () => {
    try {
      setLoading(true);

      const res = await _getPrakerjaCardNumber(token);

      if (!res.status) throw new Error(res.message);

      setPrakerjaCardNumber(res.prakerjaCardNumber);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const postPrakerjaCardNumber = async () => {
    try {
      setLoading(true);

      const res = await _postPrakerjaCardNumber(token, prakerjaCardNumber);

      if (!res.status) throw new Error(res.message);

      getPrakerjaCardNumber();
    } catch (error) {
      alert(error);

      setLoading(false);
    } finally {
      setEdit(false);
    }
  };

  useEffect(() => {
    if (!isEdit) return;
    inputRef.current.focus();
  }, [isEdit]);

  useEffect(() => {
    getPrakerjaCardNumber();
  }, [getPrakerjaCardNumber]);

  return (
    <LayoutDashboard>
      <div>
        <div className="space-y-3 text-primary-900">
          <h4 className="text-2xl font-semibold">Prakerja</h4>
          <p>Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat</p>
        </div>
        <div className="mt-[30px] flex justify-between items-center px-6 py-4 rounded-lg bg-netral-3/40">
          <div className="flex items-center space-x-[60px]">
            <Logo name="prakerja2" className="w-[262px] h-[68px]" />
            <div className="space-y-3">
              <p className="text-netral-7 font-semibold">No Kartu</p>
              {!isEdit ? (
                <h5 className="text-netral-10 text-xl font-semibold">
                  {prakerjaCardNumber}
                </h5>
              ) : (
                <input
                  ref={inputRef}
                  type="number"
                  value={prakerjaCardNumber}
                  onChange={(e) => setPrakerjaCardNumber(e.target.value)}
                  className="text-netral-10 text-xl font-semibold bg-transparent w-full"
                />
              )}
            </div>
          </div>
          <div className="flex space-x-6">
            {!isEdit ? (
              <button type="button" onClick={() => setEdit(true)}>
                <Icon name="edit-netral-9" className="w-6 h-6" />
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={postPrakerjaCardNumber}
              >
                <Icon name="check-primary-500" className="w-6 h-6" />
              </button>
            )}
            <button>
              <Icon name="delete_outline-secondary-3-500" className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </LayoutDashboard>
  );
}
