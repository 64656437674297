// import { Icon } from "../../components/independent/image";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";

export default function Referral() {
  return (
    <LayoutDashboard>
      <div>
        <div className="space-y-3 text-primary-900">
          <h4 className="text-2xl font-semibold">Referral</h4>
          <p>Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat</p>
        </div>
        <p className="text-xl font-semibold text-netral-6">coming soon</p>
        {/* <div className="mt-10">
          <div className="flex space-x-[37px] items-center">
            <div className="w-[273px] h-[125px] rounded-lg bg-netral-2/60 flex space-x-6">
              <div className="flex items-center ml-4">
                <Icon name="Get Cash-primary-600" className="h-20 w-20" />
              </div>
              <div className="flex flex-col justify-between py-3">
                <div className="space-y-2">
                  <p className="text-netral-8">Kode Referral Saya</p>
                  <h6 className="text-netral-10 font-semibold text-xl mt-2">
                    HDXXLO
                  </h6>
                </div>
                <button className="flex space-x-2 px-1">
                  <span className="text-primary-500 font-semibold">
                    Salin Kode
                  </span>
                  <Icon
                    name="content_copy-primary-600"
                    className="h-[22px] w-[22px]"
                  />
                </button>
              </div>
            </div>
            <div className="w-[389px] h-[125px] rounded-lg bg-netral-2/60 flex space-x-6">
              <div className="flex items-center ml-4">
                <Icon name="Wallet-primary-600" className="h-20 w-20" />
              </div>
              <div className="flex flex-col justify-between py-3">
                <div className="space-y-2">
                  <p className="text-netral-8">Jumlah Saldo</p>
                  <h6 className="text-netral-10 font-semibold text-xl mt-2">
                    Rp. 0
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[56px]">
          <p className="text-netral-9">
            Halo Sobat Skills ! Mau dapat untung lebih banyak di Skills.ID?
            Sebarkan saja Referral Code kamu, ajak kawan-kawanmu untuk register
            dan ikuti salah satu workshop di Skills.ID menggunakan referral code
            dari kamu :<br />
            <br />
            Setelah kawanmu melakukan pembayaran dengan referral code kamu, maka
            otomatis kawanmu akan mendapatkan saldo sebesar Rp. 50.000.- dan
            saldo referral code kamu pun akan bertambah sebesar Rp. 50.000.-.
            Ayo dapatkan saldo referral code sebanyak-banyaknya dan ikuti
            workshop suka-sukamu!
          </p>
        </div> */}
      </div>
    </LayoutDashboard>
  );
}
