export default function PromotionCode() {
  return (
    <div>
      <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
        Kode Promosi
      </h5>
      <p className="mt-2 lg:mt-3 text-netral-10 text-sm lg:text-base">
        Jika Anda memiliki kode promosi, silakan masukkan kode Anda untuk
        mendapatkan ekstra diskon kelas
      </p>
      <div className="mt-4 lg:mt-6 lg:space-y-2">
        <label className="text-netral-8 font-semibold hidden lg:inline-block">
          Kode Promosi
        </label>
        <div className="flex items-center space-x-4">
          <input
            className="bg-white border border-[#757575] rounded w-full py-2 px-3 lg:py-3 placeholder:text-netral-5 text-sm lg:text-base"
            placeholder="Masukkan Kode Promosi"
          />
          <button className="text-sm lg:text-base text-netral-6 font-semibold px-[5.5px] py-2 lg:px-3 lg:py-3 bg-netral-5 border border-[#9E9E9E] rounded whitespace-nowrap">
            Tukar Kode
          </button>
        </div>
      </div>
    </div>
  );
}
