import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import { Icon } from "../../../../../components/independent/image";
import InputTextareaLabel from "../../../../../components/independent/input/input-textarea-label";

import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_TARGET_PARTICIPANTS,
} from "../../../../../config/redux/types";

const TargetParticipants = () => {
  const dispatch = useDispatch();
  const { targetParticipants } = useSelector(
    (state) => state.newWebinarReducer
  );

  const save = () =>
    dispatch({
      type: SET_WEBINAR_TARGET_PARTICIPANTS,
      payload: [...targetParticipants],
    });

  const setInput = (targetIndex, inputIndex, value) => {
    targetParticipants[targetIndex].inputs[inputIndex].value = value;
    save();
  };

  const addNewInput = (targetIndex) => {
    targetParticipants[targetIndex].inputs.push({
      placeholder: "tulis disini...",
    });
    save();
  };

  const deleteInput = (targetIndex, inputIndex) => {
    targetParticipants[targetIndex].inputs.splice(inputIndex, 1);
    save();
  };

  return (
    <LayoutNewWebinarStep4
      title="Sasaran Peserta"
      subTitle="Tetapkan kriteria peserta webinar Anda"
    >
      <div className="space-y-8">
        <div className="space-y-4">
          <div className="space-y-2">
            <h5 className="text-lg font-semibold text-netral-9">
              Tujuan Pelatihan/Webinar
            </h5>
            <p className="text-netral-8">
              Deskripsikan dan sebutkan tujuan webinar yang dilaksanakan, tujuan
              webinar terdiri dari tujuan umum dan tujuan khusus
            </p>
          </div>
        </div>
        {targetParticipants.length &&
          targetParticipants.map((target, targetIndex) => (
            <section key={targetIndex}>
              <h6 className="text-lg font-semibold text-netral-9">
                {target.title}
              </h6>
              <p className="mt-2 text-netral-8">{target.description}</p>
              <div className="mt-6 space-y-3 w-[699px]">
                {target.inputs.map((input, inputIndex) =>
                  target.title !== "Tujuan Umum" ? (
                    <div key={inputIndex} className="relative">
                      <input
                        className="w-full border border-[#757575] rounded px-2 py-3 placeholder:text-netral-5"
                        value={input.value}
                        onChange={(e) =>
                          setInput(targetIndex, inputIndex, e.target.value)
                        }
                        placeholder={input.placeholder}
                      />
                      {inputIndex + 1 > target.min && (
                        <button
                          type="button"
                          onClick={() => deleteInput(targetIndex, inputIndex)}
                          className="absolute -right-14 top-0 h-full flex items-center"
                        >
                          <Icon
                            name="delete_outline-secondary-3-500"
                            className="w-[30px] h-[30px]"
                          />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div key={inputIndex} className="relative">
                      <InputTextareaLabel
                        className="w-full border border-[#757575] rounded px-2 py-3 placeholder:text-netral-5"
                        value={input.value}
                        onChange={(e) =>
                          setInput(targetIndex, inputIndex, e.target.value)
                        }
                        placeholder={input.placeholder}
                      />
                    </div>
                  )
                )}
              </div>
              {target.title !== "Tujuan Umum" && (
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => addNewInput(targetIndex)}
                    className="p-1 space-x-2 flex items-center"
                  >
                    <Icon name="add-primary-500" className="w-6 h-6" />
                    <span className="text-primary-500">
                      Tambahkan lebih banyak
                    </span>
                  </button>
                </div>
              )}
            </section>
          ))}
      </div>
      <ChildComponent value={targetParticipants} />
    </LayoutNewWebinarStep4>
  );
};

export default TargetParticipants;

const ChildComponent = ({ value }) => {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const isEveryInputFilled = () => {
    let inputs = [];
    value.forEach((target) => {
      inputs.push(...target.inputs);
    });
    if (inputs.some((input) => !input.value)) return false;
    return true;
  };

  return (
    <NextOrLaterButton
      active={isEveryInputFilled()}
      page={"targetParticipants"}
      next={() => {
        dispatch({ type: SET_WEBINAR_TARGET_PARTICIPANTS, payload: value });
        dispatch({
          type: SET_WEBINAR_FINISHED_NAV,
          payload: "targetParticipants",
        });
        push(`/new/webinar/${id}/step5/schedule`);
      }}
    />
  );
};
