import { APIBaseURL } from "../../environment";

export const _checkoutPrakerja = (
  token,
  courseId,
  voucherCode,
  startDate,
  endDate,
  startHour,
  endHour
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json',);

  const urlencoded = new URLSearchParams();
  urlencoded.append("class_id", courseId);
  urlencoded.append("voucherCode", voucherCode);
  if (startDate && endDate) {
    urlencoded.append("startDate", startDate);
    urlencoded.append("endDate", endDate);
    urlencoded.append("startHour", startHour);
    urlencoded.append("endHour", endHour);
  }
  const data = {
    voucherCode,
    class_id: courseId,
    startDate,
    endDate,
    startHour,
    endHour,
  };

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/prakerja/voucher`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _redeemPrakerja = (
  token,
  courseId,
  voucherCode,
  startDate,
  endDate,
  startHour,
  endHour,
  code
) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("class_id", courseId);
  urlencoded.append("voucher_code", voucherCode);
  urlencoded.append("code", code);
  if (startDate && endDate) {
    urlencoded.append("startDate", startDate);
    urlencoded.append("endDate", endDate);
    urlencoded.append("startHour", startHour);
    urlencoded.append("endHour", endHour);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/redeem`, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(JSON.parse(result)))
      .catch((error) => reject(error));
  });
};

export const _checkout = (token, paymentMethod, totalPrice, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  console.log(totalPrice, "price from post");

  const raw = JSON.stringify({
    // ["6255277252a00dc9c07d7d3f"],
    payment_method: paymentMethod, // "Transfer Bank BCA",
    total_price: totalPrice, // "100000",
    class: data,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/checkout`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getPaymentDetail = (token, paymentId) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/byId/${paymentId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _getAllTransaction = (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/all`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _uploadFileToTmp = (token, file) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/uploadToTmp`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const _uploadTransferReceipt = (token, paymentId, filename) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("id_payment", paymentId);
  urlencoded.append("filename", filename);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/sale/uploadToCloud`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
