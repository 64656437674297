import { Illustration } from "../../independent/image";

export default function Tutorial() {
  return (
    <section
      style={{
        background:
          "linear-gradient(98.1deg, rgba(18, 168, 157, 0) 34.12%, rgba(18, 168, 157, 0.06) 81.59%, rgba(18, 168, 157, 0.13) 100%)",
        // transform: "matrix(-1, 0, 0, 1, 0, 0)",
        backdropFilter: "blur(16px)",
        mixBlendMode: "normal",
      }}
      className="p-8 space-y-8"
    >
      <h3 className="text-center text-netral-10 text-2xl font-semibold">
        Bagaimana caranya ?
      </h3>
      <div className="flex items-center justify-center space-x-6">
        {tutorials.map((tutorial, i) => (
          <div
            key={i}
            className={`${tutorial.textAlign} p-8 rounded-3xl border border-netral-4 bg-white w-[321px] h-[405px]`}
            style={{ transform: "matrix(-1, 0, 0, 1, 0, 0);" }}
          >
            <div className="w-[225px] h-[160px] mx-auto">
              <Illustration
                name={tutorial.illustration}
                className="h-full w-full"
              />
            </div>
            <h4 className="text-xl mt-6 font-semibold text-netral-10">
              {tutorial.title}
            </h4>
            <p className="mt-2 text-sm text-netral-9">{tutorial.body}</p>
          </div>
        ))}
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
}

const tutorials = [
  {
    textAlign: "text-left",
    illustration: "Group 2856",
    title: "Buat Kurikulum",
    body: "Lacus sed posuere nulla augue etiam suspendisse malesuada condimentum neque. Et nibh lacinia pretium, sit enim, facilisis. Arcu, id quis amet, varius mollis pellentesque morbi nunc dolor. Sit habitant tortor sit ",
  },
  {
    textAlign: "text-center",
    illustration: "undraw_video_files_fu10 1",
    title: "Upload video",
    body: "Lacus sed posuere nulla augue etiam suspendisse malesuada condimentum neque. Et nibh lacinia pretium, sit enim, facilisis. Arcu, id quis amet, varius mollis",
  },
  {
    textAlign: "text-right",
    illustration: "undraw_online_learning_re_qw08 1",
    title: "Terbitkan Kursus",
    body: "Lacus sed posuere nulla augue etiam suspendisse malesuada condimentum neque. Et nibh lacinia pretium, sit enim, facilisis. Arcu, id quis amet, varius mollis",
  },
];
