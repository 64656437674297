import { useEffect, useRef, useState } from "react";
import { Icon } from "../../independent/image";
import InputField from "../InputField";
import { convertToMb } from "../../../constants/ConvertByteToMb";
import "../../../pages/new/video/loading-upload-file.css";
import "./index.css"
import formatVideoDuration from "../../../constants/FormatVideoDuration";
export default function UploadFile({
  inputId,
  htmlFor,
  labelValue,
  required,
  placeholderInput,
  handleChange,
  selectedFile,
  onClick,
  type,
  accept
}) {
  
  const handleRemoveFile = async () => {
    handleChange("");

    const inputFile = document.getElementById(inputId);
    if (inputFile) {
      inputFile.value = "";
      inputFile.disabled = true;
    }
  };
  const inputFile = document.getElementById(inputId);
  useEffect(() => {
    if (selectedFile === null && inputFile) {
      inputFile.disabled = false;
    }
  }, [selectedFile, inputFile]);
  const [progressWidth, setProgressWidth] = useState(1);
  useEffect(() => {
    if (selectedFile) {
      setProgressWidth(100);
    } else {
      setProgressWidth(1);
    }
  }, [selectedFile]);

  const [isLoading, setIsLoading] = useState(false);
  setTimeout(() => {
    if (selectedFile) {
      setIsLoading(true);
    }
  }, 3000);
  const [duration,setDuration] = useState()
  const handleInputFileChange = (event) => {
    const inputFile = document.getElementById(inputId);
    inputFile.disabled = false;

    const file = event.target.files[0];

    if (file) {
      handleChange(file);
      const inputFile = document.getElementById(inputId);
      inputFile.disabled = false;
      if (inputFile) {
        inputFile.placeholder = file.name;
        inputFile.disabled = false;
      }
      if(type==="video"){
        handleVideoChange(file)
      }
    }

  };
  // video

  const videoRef = useRef(null);

  const handleVideoChange = (file) => {

    if (file) {
      const videoURL = URL.createObjectURL(file);

      videoRef.current.src = videoURL;
      videoRef.current.load();

      videoRef.current.onloadedmetadata = () => {
        const duration = videoRef.current.duration;
        setDuration(duration)
      };
    }
  };

  //
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="flex flex-col gap-[8px] mb-[20px]">
      <InputField
        accept={accept}
        addLabel={true}
        labelValue={labelValue}
        hidden={true}
        required={required}
        htmlFor={htmlFor}
        onChange={handleInputFileChange}
        inputId={inputId}
        type={"file"}
        classNameLabel={"text-[#616161] font-medium text-[14px]"}
      />
      <video ref={videoRef} style={{ display: "none" }} controls />
      <label
        htmlFor={ selectedFile ? "" : inputId}
        className={`h-[${selectedFile ? "70px" : "60px"}] w-full border ${
          selectedFile ? "cursor-text" : "cursor-pointer"
        } rounded-xl max-[430px]:h-[130px] flex flex-col  ${
          selectedFile ? "text-[#404040] max-[430px]:h-[70px]" : "text-[#C2C2C2]"
        } max-[430px]:block hover:shadow-sm transition active:shadow-none`}
      >
        <div className="flex flex-2 max-[430px]:block gap-[16px] max-[430px]:w-full max-[430px]:truncate truncate transition-[.4s] active:translate-y-[0] py-[16px] relative px-5">
        <div className={`max-[430px]:text-center max-[430px]:flex max-[430px]:items-center max-[430px]:justify-center ${selectedFile ? "max-[430px]:absolute top-5 " : "" }`}>
          <Icon
            name={
              !selectedFile
                ? "cloud_upload"
                : isVisible
                ? "Visibility" 
                :  type === "video" ? "Slow_Motion_Video"
                : "Image-Upload-File"
            }
            w={selectedFile ? 28 : 20}
            h={selectedFile ? 28 : 20}
            className={`cursor-pointer ${selectedFile ? "md:mt-2" : "mt-1" }`}
            id={"upload-image-hover"}
            onMouseOver={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={ selectedFile ? onClick : null}
          />

          </div>
          <span className={`overflow-hidden max-[430px]:truncate whitespace-nowrap text-ellipsis max-[430px]:overflow-hidden max-[430px]:whitespace-nowrap max-[430px]:text-ellipsis w-[100%] h-[50px] max-[430px]:items-center max-[430px]:pr-3 flex flex-col gap-[6px] ${selectedFile ? "-mt-1" : "" } `}>
            {selectedFile
              ? <span className="truncate max-[430px]:truncate max-[430px]:w-[60%] w-full "> {selectedFile.name} </span>
              : placeholderInput}

            {selectedFile && !isLoading ? (
              <div className={`bg-[#D5C0DD] w-[100%] max-[430px]:absolute max-[430px]:bottom-8 max-[430px]:w-[50%] rounded-sm h-[6px]`}>
                <div
                  className={`bg-[#A87EB9] w-[${progressWidth}%] rounded-sm h-[6px] transition-all ease-out`}
                ></div>
              </div>
            ) : (
              selectedFile && (
                <p className="text-[14px] -mt-1 max-[430px]:-mt-2 text-[#757575]">
                  { type === "image" ? (convertToMb(selectedFile.size) + " mb") : formatVideoDuration(duration) }
                </p>
              )
            )}
          </span>

          {selectedFile ? (

            <div className="flex gap-[12px] max-[430px]:absolute max-[430px]:-right-5 max-[430px]:top-5  px-4">
              <Icon
                name={"Delete-Outline"}
                onClick={handleRemoveFile}
                className={"cursor-pointer"}
              />
              <label htmlFor={inputId} className="flex w-[42px] mt-[0.30rem] h-[22px]">
              <Icon name={"Edit-File-Upload"} className={"cursor-pointer"} />
              </label>
            </div>

          ) : (
            <div className={` max-[430px]:static max-[430px]:items-center max-[430px]:justify-center max-[430px]:-mt-3  : flex items-center absolute top-2 right-0 pr-[8px]`}>
              <div className=" max-[430px]:w-[100%] w-[125px] h-[42px] css-border-gradient flex items-center justify-center">

                <span
                  style={{
                    background:
                      "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Choose Files
                </span>
              </div>
            </div>

          )}
        </div>
      </label>
    </div>
  );
}
