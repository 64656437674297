import { useDispatch, useSelector } from "react-redux";
import { CHANGE_SIDEBAR } from "../../../../config/redux/types";
import getLink from "../../../../constants/getLink";
import { Icon } from "../../image";

export default function MobileHeaderLearn({ setSideNavOpened }) {
  const bg = "bg-netral-1";
  const menu = "menu-netral-10";

  const { userData } = useSelector((state) => state.authReducer);
  const course = useSelector((state) => state.courseReducer);
  const dispatch = useDispatch();

  return (
    <div
      style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)" }}
      className={`${bg} py-2 flex items-center px-4 header lg:hidden space-x-[31px]`}
    >
      <div>
        <button type="button" onClick={() => setSideNavOpened(true)}>
          <Icon name={menu} className="w-8 h-8" />
        </button>
      </div>
      <div className="w-full">
        <p className="text-sm text-netral-9 font-semibold">{course.title}</p>
        <div className="flex items-center justify-between space-x-2">
          <div
            style={{ width: `${course?.participantProgress?.percentage || 0}%` }}
            className="h-0 border-4 border-primary-500 rounded-full"
          ></div>
          <span className="text-xs text-primary-500 font-semibold">{course?.participantProgress?.percentage || 0}%</span>
        </div>
        <div className="mt-1 flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <Icon name="menu_book-netral-8" className="w-4 h4" />
            <p className="text-netral-8 text-xs">s</p>
          </div>
          <div className="flex items-center space-x-2">
            <Icon name="ondemand_video-netral-8" className="w-4 h4" />
            <p className="text-netral-8 text-xs">s</p>
          </div>
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => dispatch({ type: CHANGE_SIDEBAR, payload: true })}
          className="w-10 h-10 rounded-full overflow-hidden"
        >
          <img
            src={getLink( userData.profile_picture?.link)}
            alt={userData.profile_picture?.name}
            className="h-full w-full"
          />
        </button>
      </div>
    </div>
  );
}
