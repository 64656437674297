import { Icon } from "../../image";

export default function ButtonPlus({ children, ...rest }) {
  return (
    <button {...rest} className="p-1 space-x-2 flex items-center">
      <Icon name="add-primary-500" className="w-6 h-6" />
      <span className="text-primary-500 whitespace-nowrap">{children}</span>
    </button>
  );
}
