import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import { Icon } from "../../../../../components/independent/image";
// import InputDropdownLabel2 from "../../../../../components/independent/input/input-dropdown-label2";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
    SET_WEBINAR_COMPETENCE,
    SET_WEBINAR_COMPETENCE_TITLE,
    SET_WEBINAR_FINISHED_NAV
//   SET_COURSE_PRICING,
//   SET_FINISHED_NAV,
} from "../../../../../config/redux/types";

const WebinarCompetenceUnit = () => {
  const dispatch = useDispatch();
  const { competenceUnit, competenceTitle } = useSelector((state) => state.newWebinarReducer);
  const [competence, setCompetence] = useState(competenceUnit);
  const [title, setTitle] = useState(competenceTitle);

  const handleChange = (value, index, key) => {
    const temp = [...competence];
    temp[index][key] = value;
    setCompetence([...temp]);
    dispatch({ type: SET_WEBINAR_COMPETENCE, payload: competence });
  };


  const handleChangeTitle = (value) => {
    setTitle(value);
    dispatch({ type: SET_WEBINAR_COMPETENCE_TITLE, payload: value });
  };


  const handleAdd = () => {
    const temp = {
      title: "",
      competenceCode: "",
    };
    setCompetence([...competence, temp]);
  };

  // const handleDelete = (inputIndex) => {
  //   const temp = [...competence];
  //   temp.splice(inputIndex, 1);
  //   setCompetence(temp);
  // };

  return (
    <LayoutNewWebinarStep4
      title="Unit Kompetensi"
      subTitle="Pilih kode dan unit kompetensi"
    >
         <div className="w-[80%]">
         <div className="w-1/2 mb-6 space-y-2">
          <label
            htmlFor="title"
            className="inline-block text-sm text-netral-8 font-semibold"
          >
            Judul Kompetensi
          </label>
          <textarea
            id="title"
            value={title}
            onChange={(e) => handleChangeTitle(e.target.value)}
            className="p-3 w-full rounded border border-[#757575] w-full placeholder:text-netral-5"
            placeholder="Masukan judul kompetensi"
          />
        </div>
          <div className="space-y-5">
            {competence.map((el, idx) => (
              <div className="relative">
                <div className="flex w-full justify-between space-x-3">
                  <div className="w-1/2 space-y-2">
                    <label
                      htmlFor="code"
                      className="inline-block text-sm text-netral-8 font-semibold"
                    >
                      Kode Unit
                    </label>
                    <input
                      id="code"
                      value={el.competenceCode}
                      onChange={(e) =>
                        handleChange(e.target.value, idx, "competenceCode")
                      }
                      className="p-3 w-full rounded border border-[#757575] w-full placeholder:text-netral-5"
                      placeholder="Masukan kode kompetensi"
                    />
                  </div>
                  <div className="w-1/2 space-y-2">
                    <label
                      htmlFor="title"
                      className="inline-block text-sm text-netral-8 font-semibold"
                    >
                      Judul Unit Kompetensi
                    </label>
                    <input
                      id="title"
                      value={el.title}
                      onChange={(e) =>
                        handleChange(e.target.value, idx, "title")
                      }
                      className="p-3 w-full rounded border border-[#757575] w-full placeholder:text-netral-5"
                      placeholder="Masukan judul Unit kompetensi"
                    />
                  </div>
                </div>
                {idx !== 0 && (
                  <button
                    className="absolute top-11 -right-10"
                    type="button"
                    onClick={() => {
                      competence.splice(idx, 1);
                      setCompetence([...competence]);
                    }}
                  >
                    <Icon
                      name="delete_outline-secondary-3-500"
                      className="w-6 h-6"
                    />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="mt-7">
            <button
              type="button"
              onClick={() => handleAdd()}
              className="p-1 space-x-2 flex items-center"
            >
              <Icon name="add-primary-500" className="w-6 h-6" />
              <span className="text-primary-500">Tambahkan lebih banyak</span>
            </button>
          </div>
        </div>
      <ChildComponent competenceUnit={competence} />
    </LayoutNewWebinarStep4>
  );
};

export default WebinarCompetenceUnit;

const ChildComponent = ({ competenceUnit }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      page={"competenceUnit"}
      active={true}
      next={() => {
        dispatch({ type: SET_WEBINAR_COMPETENCE, payload: competenceUnit });
        dispatch({ type:SET_WEBINAR_FINISHED_NAV, payload: "competenceUnit" });
        push(`/new/webinar/${id}/step5/review`);
      }}
    />
  );
};