import React, { useState } from "react";
import { Icon } from "../../independent/image";
import InputFilled from "../InputFilled";

export default function Accordion({session}) {
    const [isOpen,setIsOpen] = useState(false)

  return (
    
    <div className={`h-[56px] ${isOpen && "h-[300px]"} overflow-hidden flex w-full border rounded-lg py-[16px] px-[24px] justify-between cursor-pointer transition-[1s]`} onClick={()=>setIsOpen(!isOpen)}>
      <h1 className="font-semibold">{session}</h1>
      <div>
        <Icon
            name={"keyboard_arrow_down-netral-10"}
            className={`${isOpen && "rotate-[180deg]"} transition-[.5s] block`}
        />
      </div>
      <div className="block">

        <InputFilled label={"Judul Sesi"} inputFill={"Introduction UI/UX Design"} />

      </div>
    </div>
  );
}
