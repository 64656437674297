export default function Announcement() {
  return (
    <div className="space-y-[18px] lg:space-y-4 text-center">
      <h4 className="text-lg lg:text-xl font-semibold text-netral-9">Announcement</h4>
      <p className="text-netral-9 lg:w-[700px] mx-auto text-xs lg:text-base">
        Instruktur belum menambahkan pengumuman apa pun ke kursus ini.
        Pengumuman berfungsi untuk memberi tahu Anda tentang pembaruan atau
        penambahan pada kursus.
      </p>
    </div>
  );
}
