import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <section className="pt-8 px-[135px] flex items-center space-x-[103px]">
      <div className="w-1/2 h-auto">
        <img
          src="/assets/image/Group 2378.png"
          alt="bg"
          className="h-full w-full"
        />
      </div>
      <div className="w-1/2">
        <h3 className="text-2xl font-semibold text-primary-900">
          Menjadi Instuktur
        </h3>
        <p className="mt-6 text-lg text-netral-10">
          Instruktur dari seluruh dunia mengajar jutaan peserta di Skills.id.
          Kami menyediakan alat dan keterampilan untuk mengajarkan hal yang Anda
          sukai.
        </p>
        <Link
          to="/become-instructor/term-and-condition"
          style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)" }}
          className="inline-block mt-8 text-white font-semibold p-3 bg-primary-600 rounded"
        >
          Mulai Mengajar
        </Link>
      </div>
    </section>
  );
}
