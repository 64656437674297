import moment from "moment";
import { Link } from "react-router-dom";
import getLink from "../../../../constants/getLink";

export default function CardCourse5({ course, deleteItemFromCart }) {
  return (
    <div className="lg:p-3 lg:flex items-end justify-between lg:border border-netral-6 rounded space-y-2 lg:space-y-0">
      <div className="flex items-center space-x-2 lg:space-x-4">
        <div>
          <div className="w-[88px] h-[88px] rounded bg-black/80">
            <img
              src={getLink(course?.thumbnail)}
              alt={course?.thumbnail}
              className="w-full h-full object-cover overflow-hidden"
            />
          </div>
        </div>
        <div>
          <h4 className="text-lg lg:text-xl font-semibold text-netral-9">
            {course?.title}
          </h4>
          <p className="mt-1 text-netral-8">Oleh {course?.instructor}</p>
          <p className="mt-1 text-netral-8">
            Jadwal :{" "}
            {`${moment(course?.learner_schedule?.dateStart).format(
              "Do"
            )} - ${moment(course?.learner_schedule?.dateEnd).format(
              "Do MMMM YYYY"
            )}`}{" "}
            |{" "}
            {`${course?.learner_schedule?.startHour} - ${course?.learner_schedule?.endHour}`}
          </p>
          <div className="flex items-center space-x-2">

          {course?.discount && course?.discount > 0 ? (
            <div className="flex items-center space-x-2">
              <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
                {course?.discount}%
              </p>
              <p className="text-secondary-button-600 line-through text-base font-semibold">
                {parseInt(course?.priceBeforeDiscount).toLocaleString("id-ID")}
              </p>
            </div>
          ) : null}
          <h5 className="lg:text-lg font-semibold text-primary-500">
            Rp {course?.price.toLocaleString("id-ID")}
          </h5>
          </div>
        </div>
      </div>
      <div className="space-x-4">
        <Link
          to={`/checkout?items=${encodeURIComponent(
            JSON.stringify([course._id])
          )}`}
          className="text-sm font-semibold text-secondary-button-600"
        >
          Beli Sekarang
        </Link>
        <button
          type="button"
          onClick={() => deleteItemFromCart(course?._id)}
          className="text-sm font-semibold text-secondary3-500"
        >
          Hapus
        </button>
      </div>
    </div>
  );
}
