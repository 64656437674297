import ButtonProfile from "../../button/button-profile";
import { Icon } from "../../image";

export default function NavHeaderLearn({ course }) {

  // console.log(course);

  return (
    <nav>
      <ul className="flex space-x-8 items-center text-primary-500 font-semibold">
        <li>
          <h6 className="text-netral-9 text-lg font-semibold">
            {course?.title}
          </h6>
        </li>
        <li>
          <div className="w-[360px]">
            <p className="text-netral-8 font-semibold text-sm">
              Progress Kelas
            </p>
            <div className="mt-[2px] flex items-center justify-between space-x-2">
              <div
                style={{ width: `${course?.participantProgress?.percentage || 0}%` }}
                className="h-0 border-4 border-primary-500 rounded-full duration-500"
              ></div>
              <span className="text-xs text-primary-500 font-semibold">
                {course.participantProgress?.percentage}%
              </span>
            </div>
            <div className="mt-1 flex items-center space-x-[18px]">
              <div className="flex items-center space-x-2">
                <Icon name="menu_book-netral-8" className="w-4 h4" />
                <p className="text-netral-8 text-xs">{course.participantProgress?.completedMaterial}</p>
              </div>
              <div className="flex items-center space-x-2">
                <Icon name="ondemand_video-netral-8" className="w-4 h4" />
                <p className="text-netral-8 text-xs">{course.participantProgress?.watchedVideo}</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <ButtonProfile />
        </li>
      </ul>
    </nav>
  );
}
