import { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import ButtonPlus from "../../../../../components/independent/button/button-plus";
import Chapter from "../../../../../components/dependent/webinar-curriculum/chapter";
import SavedChapter from "../../../../../components/dependent/webinar-curriculum/saved-chapter";
import {
  SET_WEBINAR_CURRICULUM,
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION,
} from "../../../../../config/redux/types";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";

const CurriculumContext = createContext();

export const useCurriculum = () => useContext(CurriculumContext);

const WebinarCurriculum = () => {
  const dispatch = useDispatch();
  const chapters = useSelector((state) => state.newWebinarReducer.curriculum);

  const setChapters = (value) => {
    dispatch({ type: SET_WEBINAR_CURRICULUM, payload: value });
  };

  const addChapter = () => {
    const defaultChapter = {
      title: "",
      description: "",
      curriculumDate: "",
      materials: [{ lesson: null, quiz: null, view: "MaterialOption" }],
      isSaved: false,
    };
    chapters.length
      ? setChapters([...chapters, defaultChapter])
      : setChapters([defaultChapter]);
  };

  return (
    <CurriculumContext.Provider value={{ chapters, setChapters }}>
      <LayoutNewWebinarStep4
        title="Kurikulum"
        subTitle="Tetapkan dan susun materi webinar anda"
      >
        <main>
          <div className="space-y-3">
            <p className="text-netral-8">
              Masukan materi webinar anda pada setiap sesinya.
            </p>
            {/* <p className="text-netral-8">
              Jika Anda berniat menawarkan kursus secara gratis, total durasi
              konten video untuk kursus tidak boleh melebihi 2 jam.
            </p> */}
          </div>
          {!chapters.length && (
            <div className="mt-8">
              <ButtonPlus type="button" onClick={addChapter}>
                Tambahkan Bab
              </ButtonPlus>
            </div>
          )}
          {chapters.length ? (
            <>
              <div className="mt-8 space-y-8">
                {chapters.map((chapter, i) =>
                  !chapter.isSaved ? (
                    <Chapter key={i} chapterIndex={i} isSession={true} />
                  ) : (
                    <SavedChapter key={i} chapterIndex={i} />
                  )
                )}
              </div>
              <div className="mt-8">
                <ButtonPlus type="button" onClick={addChapter}>
                  Tambahkan Bagian
                </ButtonPlus>
              </div>
            </>
          ) : null}
          <ChildComponent />
        </main>
      </LayoutNewWebinarStep4>
    </CurriculumContext.Provider>
  );
};

export default WebinarCurriculum;

const ChildComponent = () => {
  const { chapters } = useCurriculum();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();
  const instructionTpm = useSelector(
    (state) => state.newWebinarReducer.individualTestInstruction
  );

  const isEveryInputFilled = () => {
    let bool = true;
    if (!chapters.length) return false;
    chapters.forEach((chapter) => {
      if (!chapter.description) bool = false;
      if (!chapter.isSaved) bool = false;
      if (!chapter.title) bool = false;
      if (!bool) return;
    });
    return bool;
  };

  const indivdualChapters = () => {
    const instruction = [];
    for (let i = 0; i < chapters.length; i++) {
      const tpm = instructionTpm[i];
      if (+tpm?.chapterIndex === i) {
        instruction.push(tpm);
      } else {
        instruction.push({ name: "", description: "", chapterIndex: i });
      }
    }
    return instruction
  };
  // console.log(indivdualChapters());

  // const indivdualChapters = chapters.map((el, idx) => ({
  // name: "",
  // description: "",
  // chapterIndex: idx,
  // }));


  const next = () => {
    dispatch({ type: SET_WEBINAR_CURRICULUM, payload: chapters });
    dispatch({
      type: SET_WEBINAR_NEW_INDIVIDUAL_INSTRUCTION,
      payload: indivdualChapters(),
    });
    dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: "curriculum" });
    push(`/new/webinar/${id}/step5/group-of-question`);
  };
  return (
    <NextOrLaterButton
      active={isEveryInputFilled()}
      next={next}
      page={"curriculum"}
    />
  );
};
