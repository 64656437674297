// lib
import { Route, Switch } from "react-router-dom";

// routing
import PublicRoute from "./PublicRoute";
import StudentRoute from "./StudentRoute";
import InstructorRoute from "./InstructorRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import AuthenticationRoute from "./AuthenticationRoute";

// landing page
import Home from "../pages/Home";
import Prakerja from "../pages/Prakerja";
import About from "../pages/About";
import Faq from "../pages/Faq";
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy";
import TermAndCondition from "../pages/TermAndCondition";

// auth
import Register from "../pages/Register";
import Login from "../pages/Login";
import Success from "../pages/reset-password/Success";
import CreateNewPassword from "../pages/reset-password/CreateNewPassword";
import ResetPassword from "../pages/reset-password";

// course
import Course from "../pages/course";
import CourseDetail from "../pages/course/[course-id]";

// webinar
import WebinarDetail from "../pages/webinar/[webinar-id]";

// cart
import Cart from "../pages/Cart";

// checkout
import Checkout from "../pages/checkout";
import CheckoutDetail from "../pages/checkout/[checkout-id]";

// learn course
import LearnCourse from "../pages/learn/[course-id]/LearnCourse";
import PreTest from "../pages/learn/[course-id]/PreTest";
import PostTest from "../pages/learn/[course-id]/PostTest";
import LearnSchedule from "../pages/learn/[course-id]/LearnSchedule";
import TakeSkillTest from "../pages/learn/[course-id]/SkillTest";
import Material from "../pages/learn/[course-id]/Material";

// student
import StudentCourses from "../pages/student/Courses";
import Wishlist from "../pages/student/Wishlist";
import Transaction from "../pages/student/Transaction";
import Certificate from "../pages/student/certificate";
import DetailCertificate from "../pages/student/certificate/[courseId]";
import StudentPrakerja from "../pages/student/Prakerja";
import Setting from "../pages/student/Setting";
import Referral from "../pages/student/Referral";
import Instructor from "../pages/student/Instructor";

// instructor
import Communication from "../pages/instructor/Communication";
import InstructorCourses from "../pages/instructor/Courses";
import Performance from "../pages/instructor/Performance";
import Profile from "../pages/instructor/Profile";

// new
import New from "../pages/new";

// new course
import NewCourse from "../pages/new/course/[course-id]/NewCourse";
import Step1 from "../pages/new/course/[course-id]/Step1";
import Step2 from "../pages/new/course/[course-id]/Step2";
import Step4 from "../pages/new/course/[course-id]/Step4";
import Step5 from "../pages/new/course/[course-id]/Step5";
import TargetParticipants from "../pages/new/course/[course-id]/step6/TargetParticipants";
import Curriculum from "../pages/new/course/[course-id]/step6/Curriculum";
import GroupOfQuestion from "../pages/new/course/[course-id]/step6/GroupOfQuestion";
import Interaction from "../pages/new/course/[course-id]/step6/Interaction";
import SkillTest from "../pages/new/course/[course-id]/step6/SkillTest";
import Hompage from "../pages/new/course/[course-id]/step6/Hompage";
import CourseSetting from "../pages/new/course/[course-id]/step6/Setting";
import Pricing from "../pages/new/course/[course-id]/step6/Pricing";
import Review from "../pages/new/course/[course-id]/step6/Review";

// new webinar
import NewWebinar from "../pages/new/webinar/[webinar-id]/NewWebinar";
import WebinarStep1 from "../pages/new/webinar/[webinar-id]/Step1";
import WebinarStep2 from "../pages/new/webinar/[webinar-id]/Step2";
import WebinarStep3 from "../pages/new/webinar/[webinar-id]/Step3";
import WebinarStep4 from "../pages/new/webinar/[webinar-id]/Step4";
import WebinarTargetParticipants from "../pages/new/webinar/[webinar-id]/step5/TargetParticipants";
import Schedule from "../pages/new/webinar/[webinar-id]/step5/Schedule";
import Gallery from "../pages/new/webinar/[webinar-id]/step5/Gallery";
import WebinarHompage from "../pages/new/webinar/[webinar-id]/step5/Hompage";
import WebinarPricing from "../pages/new/webinar/[webinar-id]/step5/Pricing";
import WebinarReview from "../pages/new/webinar/[webinar-id]/step5/Review";

// new video
import NewVideo from "../pages/new/video/[video-id]/NewVideo";
import VideoStep1 from "../pages/new/video/[video-id]/Step1";
import VideoStep2 from "../pages/new/video/[video-id]/Step2";
import VideoStep3 from "../pages/new/video/[video-id]/Step3Video";
import VideoStep6 from "../pages/new/video/[video-id]/Step6Video";

// import VideoStep3 from "../pages/new/webinar/[webinar-id]/Step3";
// import VideoStep4 from "../pages/new/webinar/[webinar-id]/Step4";
// import VideoTargetParticipants from "../pages/new/webinar/[webinar-id]/step5/TargetParticipants";
// import VideoHompage from "../pages/new/webinar/[webinar-id]/step5/Hompage";
// import VideoPricing from "../pages/new/webinar/[webinar-id]/step5/Pricing";
// import VideoReview from "../pages/new/webinar/[webinar-id]/step5/Review";

// become instructor
import BecomeInstructor from "../pages/become-instructor";
import BecomeInstructorTermAndCondition from "../pages/become-instructor/TermAndCondition";
import BecomeInstructorProfile from "../pages/become-instructor/Profile";
import Detail from "../pages/become-instructor/Detail";
import RegisterInstructor from "../pages/become-instructor/RegisterInstructor";
import Dashboard from "../pages/instructor/dashboard";
import DetailCourse from "../pages/instructor/dashboard/[course-id]";
import DetailStudent from "../pages/instructor/dashboard/[course-id]/[studentId]";
import Upload from "../pages/Upload";
import Income from "../pages/instructor/Income";
import AddAnnouncement from "../pages/instructor/Communication/AddAnnouncement";
import CourseSchedule from "../pages/new/course/[course-id]/step6/CourseSchedule";
import WebinarCurriculum from "../pages/new/webinar/[webinar-id]/step5/Curriculum";
import skillTestWebinar from "../pages/new/webinar/[webinar-id]/step5/SkillTest";
import WebinarGroupOfQuestion from "../pages/new/webinar/[webinar-id]/step5/GroupOfQuestion";
// import WebinarMaterial from "../pages/learn/[course-id]/WebinarMaterial";
import Step3 from "../pages/new/course/[course-id]/Step3";
import StudentCertificate from "../pages/certificate/[certificateId]";
import CompetenceUnit from "../pages/new/course/[course-id]/step6/CompetenceUnit";
import WebinarCompetenceUnit from "../pages/new/webinar/[webinar-id]/step5/CompetenceUnit";
import IndividualTest from "../pages/instructor/dashboard/[course-id]/IndividualTest";
import Information from "../pages/learn/[course-id]/Information";
import BlogPage from "../pages/blog";
import BlogDetail from "../pages/blog/[blog-id]";
import CallbackPrakerja from "../pages/CallbackPrakerja";
import IndividualTestInstructor from "../pages/new/webinar/[webinar-id]/step5/IndividualTestInstruction";
import ReflectiveJournal from "../pages/learn/[course-id]/ReflectiveJournal";

const Routes = () => {
  return (
    <Switch>
      {/* auth  */}
      <AuthenticationRoute path="/login" component={Login} />
      <AuthenticationRoute path="/register" component={Register} />
      <AuthenticationRoute
        path="/reset-password/"
        component={ResetPassword}
        exact
      />
      <AuthenticationRoute
        path="/reset-password/create-new-password/:token"
        component={CreateNewPassword}
      />
      <AuthenticationRoute path="/reset-password/success" component={Success} />
      {/* <Route path="/success-register" component={SuccessRegister} /> */}
      <Route path="/certificate" component={StudentCertificate} />

      {/* landing page  */}
      <PublicRoute path="/" component={Home} exact />
      <PublicRoute path="/about" component={About} />
      <PublicRoute path="/prakerja" component={Prakerja} />
      <PublicRoute path="/faq" component={Faq} />
      <PublicRoute path="/privacy-and-policy" component={PrivacyAndPolicy} />
      <PublicRoute path="/term-and-condition" component={TermAndCondition} />
      <PublicRoute path="/callback-prakerja" component={CallbackPrakerja} />

      {/* Blog */}˝
      <PublicRoute path="/blog/:blogId" component={BlogDetail} />
      <PublicRoute path="/blog" component={BlogPage} />

      {/* become instructor */}
      <Route path="/become-instructor">
        <RegisterInstructor>
          <Switch>
            <PublicRoute
              path="/become-instructor"
              component={BecomeInstructor}
              exact
            />
            <PublicRoute
              path="/become-instructor/term-and-condition"
              component={BecomeInstructorTermAndCondition}
            />
            <PublicRoute
              path="/become-instructor/profile"
              component={BecomeInstructorProfile}
            />
            <PublicRoute path="/become-instructor/detail" component={Detail} />
          </Switch>
        </RegisterInstructor>
      </Route>

      {/* course  */}
      <PublicRoute path="/course" component={Course} exact />
      <PublicRoute
        path="/course/:courseTitle/:courseId"
        component={CourseDetail}
      />

      {/* webinar  */}
      <PublicRoute
        path="/webinar/:webinarTitle/:webinarId"
        component={WebinarDetail}
      />

      {/* cart  */}
      <AuthenticatedRoute path="/cart" component={Cart} />

      {/* blog */}

      {/* checkout  */}
      <AuthenticatedRoute path="/checkout" component={Checkout} exact />
      <AuthenticatedRoute
        path="/checkout/:checkoutId"
        component={CheckoutDetail}
      />

      {/* learn course  */}
      <Route path="/learn/:courseId">
        <LearnCourse>
          <Switch>
            <StudentRoute
              path="/learn/:courseId/info"
              component={Information}
            />
            <StudentRoute
              path="/learn/:courseId/pre-test"
              component={PreTest}
            />
            <StudentRoute
              path="/learn/:courseId/post-test"
              component={PostTest}
            />
            <StudentRoute
              path="/learn/:courseId/schedule"
              component={LearnSchedule}
            />
            <StudentRoute
              path="/learn/:courseId/skill-test"
              component={TakeSkillTest}
            />
            <StudentRoute
              path="/learn/:courseId/reflective-journal"
              component={ReflectiveJournal}
            />
            <StudentRoute path="/learn/:courseId/:title" component={Material} />
          </Switch>
        </LearnCourse>
      </Route>

      {/* student  */}
      <StudentRoute path="/student" component={StudentCourses} exact />
      <StudentRoute path="/student/wishlist" component={Wishlist} />
      <StudentRoute path="/student/transaction" component={Transaction} />
      <StudentRoute path="/student/prakerja" component={StudentPrakerja} />
      <StudentRoute path="/student/setting" component={Setting} />
      <StudentRoute path="/student/referral" component={Referral} />
      <StudentRoute path="/student/instructor" component={Instructor} />
      <StudentRoute path="/student/certificate" component={Certificate} exact />
      <StudentRoute
        path="/student/certificate/:courseId"
        component={DetailCertificate}
      />

      {/* instructor  */}
      <InstructorRoute path="/instructor" component={InstructorCourses} exact />
      <InstructorRoute
        path="/instructor/communication"
        component={Communication}
        exact
      />
      <InstructorRoute
        path="/instructor/communication/add-announcement"
        component={AddAnnouncement}
      />
      <InstructorRoute path="/instructor/income" component={Income} />
      <InstructorRoute path="/instructor/Performance" component={Performance} />
      <InstructorRoute path="/instructor/profile" component={Profile} />

      {/* instructor dashboard */}
      <InstructorRoute
        path="/instructor/dashboard"
        component={Dashboard}
        exact
      />
      <InstructorRoute
        path="/instructor/dashboard/:courseId"
        component={DetailCourse}
        exact
      />
      <InstructorRoute
        path="/instructor/dashboard/:courseId/:studentId"
        component={DetailStudent}
        exact
      />
      <InstructorRoute
        path="/instructor/dashboard/:courseId/:studentId/individual-test"
        component={IndividualTest}
        exact
      />

      {/* new */}
      <InstructorRoute path="/new" component={New} exact />

      {/* new course */}
      <Route path="/new/course/:id">
        <NewCourse>
          <Switch>
            <InstructorRoute path="/new/course/:id" component={Step1} exact />
            <InstructorRoute path="/new/course/:id/step2" component={Step2} />
            <InstructorRoute path="/new/course/:id/step3" component={Step3} />
            <InstructorRoute path="/new/course/:id/step4" component={Step4} />
            <InstructorRoute path="/new/course/:id/step5" component={Step5} />
            <InstructorRoute
              path="/new/course/:id/step6"
              component={TargetParticipants}
              exact
            />
            <InstructorRoute
              path="/new/course/:id/step6/curriculum"
              component={Curriculum}
            />
            <InstructorRoute
              path="/new/course/:id/step6/group-of-question"
              component={GroupOfQuestion}
            />
            <InstructorRoute
              path="/new/course/:id/step6/course-schedule"
              component={CourseSchedule}
            />
            <InstructorRoute
              path="/new/course/:id/step6/interaction"
              component={Interaction}
            />
            <InstructorRoute
              path="/new/course/:id/step6/skill-test"
              component={SkillTest}
            />
            <InstructorRoute
              path="/new/course/:id/step6/setting"
              component={CourseSetting}
            />
            <InstructorRoute
              path="/new/course/:id/step6/hompage"
              component={Hompage}
            />
            <InstructorRoute
              path="/new/course/:id/step6/pricing"
              component={Pricing}
            />
            <InstructorRoute
              path="/new/course/:id/step6/competence-unit"
              component={CompetenceUnit}
            />
            <InstructorRoute
              path="/new/course/:id/step6/review"
              component={Review}
            />
          </Switch>
        </NewCourse>
      </Route>

      {/* new webinar */}
      <Route path="/new/webinar/:id">
        <NewWebinar>
          <Switch>
            <InstructorRoute
              path="/new/webinar/:id"
              component={WebinarStep1}
              exact
            />
            <InstructorRoute
              path="/new/webinar/:id/step2"
              component={WebinarStep2}
            />
            <InstructorRoute
              path="/new/webinar/:id/step3"
              component={WebinarStep3}
            />
            <InstructorRoute
              path="/new/webinar/:id/step4"
              component={WebinarStep4}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5"
              component={WebinarTargetParticipants}
              exact
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/schedule"
              component={Schedule}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/curriculum"
              component={WebinarCurriculum}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/group-of-question"
              component={WebinarGroupOfQuestion}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/gallery"
              component={Gallery}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/skill-test"
              component={skillTestWebinar}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/individual-test"
              component={IndividualTestInstructor}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/hompage"
              component={WebinarHompage}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/pricing"
              component={WebinarPricing}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/competence-unit"
              component={WebinarCompetenceUnit}
            />
            <InstructorRoute
              path="/new/webinar/:id/step5/review"
              component={WebinarReview}
            />
          </Switch>
        </NewWebinar>
      </Route>

      {/* new Video */}
      <Route path="/new/video/:id">
        <NewVideo>
          <Switch>
            <InstructorRoute
              path="/new/video/:id"
              component={VideoStep1}
              exact
            />
            <InstructorRoute
              path="/new/video/:id/step2"
              component={VideoStep2}
            />
            <InstructorRoute
              path="/new/video/:id/step3"
              component={VideoStep3}
            />
            <InstructorRoute
              path="/new/video/:id/step6"
              component={VideoStep6}
            />
          </Switch>
        </NewVideo>
      </Route>

      <Route path="/upload" component={Upload} />
    </Switch>
  );
};

export default Routes;
