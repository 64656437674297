import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import LayoutNewWebinar from "../../../../components/independent/layout/layout-new-webinar";
import { SET_WEBINAR_TITLE } from "../../../../config/redux/types";
import { Icon } from "../../../../components/independent/image";
import LabelDetailClass from "../../../../components/atom/LabelDetailClass";
import InputFormClassDetail from "../../../../components/atom/InputDetailClass";
// import InputDetailClassField from "../../../../components/Molecule/InputDetailClassField";
import InputField from "../../../../components/Molecule/InputField";
import Labelform from "../../../../components/atom/Label";
import TextArea from "../../../../components/atom/TextArea";
import TextAreaField from "../../../../components/Molecule/textAreaFiled";
import Input from "../../../../components/atom/Input";
import React from "react";
import useMultiSelectInput from "../../../../hooks/useMultipleValue";
// import InputFormClassDetail from "../../../../components/atom/input-detail-class";
// import LabelDetailClass from "../../../../components/atom/label-detail-class-webinar";

const Step1 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { courseTitle } = useSelector((state) => state.newWebinarReducer);
  const [title, setTitle] = useState(courseTitle);

  const gradientColor =
    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)";

  const dispatch = useDispatch();

  const {
    handleInputChange,
    handleInputKeyDown,
    handleRemoveItem,
    selectedItems,
  } = useMultiSelectInput(); // hooks multiple input
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-undef
  const textareaRef = useRef();

  // Gunakan useEffect untuk menghitung tinggi textarea saat items berubah
  useEffect(() => {
    if (textareaRef.current) {
      const textareaHeight = textareaRef.current.scrollHeight;
      // Sesuaikan nilai offset atau padding sesuai kebutuhan
      const offset = 10;
      textareaRef.current.style.height = textareaHeight + offset + "px";
    }
  }, [items]);

  const addItem = (e) => {
    e.preventDefault();
    const newItem = prompt("Masukkan item baru:");
    if (newItem) {
      setItems([...items, newItem]);
    }
  };
  // dropdown menu

  // Dropdown 1
  const [isOpenDropdownCategory, setIsOpenDropdownCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [activeItemCategory, setActiveItemCategory] = useState(null);
  const dropdownItemsCategory = [
    "Category A",
    "Category B",
    "Category C",
    "Category D",
  ];

  const handleCategorySelect = (category, index) => {
    setSelectedCategory(category);
    setActiveItemCategory(index);
    setIsOpenDropdownCategory(false);
  };

  // Dropdown 2
  const [isOpenDropdownLanguage, setIsOpenDropdownLanguage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [activeItemLanguage, setActiveItemLanguage] = useState(null);
  const dropdownItemsLanguage = [
    "Language A",
    "Language B",
    "Language C",
    "Language D",
  ];

  const handleLanguageSelect = (language, index) => {
    setSelectedLanguage(language);
    setActiveItemLanguage(index);
    setIsOpenDropdownLanguage(false);
  };
  // Dropdown 3
  const [isOpenTypeParticipants, setIsOpenTypeParticipants] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState("");
  const [activeItemParticipants, setActiveItemParticipants] = useState(null);
  const dropdownItemsParticipants = [
    "peserta A",
    "peserta B",
    "peserta C",
    "peserta D",
  ];

  const handleParticipantsSelect = (category, index) => {
    setSelectedParticipants(category);
    setActiveItemParticipants(index);
    setIsOpenTypeParticipants(false);
  };

  // Dropdown 4
  const [isOpenLevel, setIsOpenLevel] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [activeItemsLevel, setActiveItemLevel] = useState(null);
  const dropdownItemsLevel = ["level A", "level B", "level C", "level D"];

  const handleLevelselect = (category, index) => {
    setSelectedLevel(category);
    setActiveItemLevel(index);
    setIsOpenLevel(false);
  };
  // end dropdwon menu

  // optional input

  // get input file name
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFile = document.getElementById("thumbnail");
  
  useEffect(() => {
    if (selectedFile === null && inputFile) {
      inputFile.disabled = false;
    }
  }, [selectedFile,inputFile]);

  const inputFileRef = useRef(null);

  const handleInputFileChange = (event) => {
    const inputFile = document.getElementById("thumbnail");
    inputFile.disabled = false;

    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const inputFile = document.getElementById("thumbnail");
      inputFile.disabled = false;
      if (inputFile) {
        inputFile.placeholder = file.name;
        inputFile.disabled = false;
      }
    }
  };
  const handleRemoveFile = async () => {
    // if (inputFileRef.current) {
    //   inputFileRef.current.value = null;
    // }

    // Reset the state and clear the input element
    setSelectedFile(null);

    const inputFile = document.getElementById("thumbnail");
    if (inputFile) {
      inputFile.value = ""; // Clear the input value
      inputFile.disabled = true; // Disable the input
    }
    // inputFile.disabled = false
  };

  // end get input file name
  const createOptionalInput = (index) => (
    <div key={index} className="relative">
      <InputField
        addLabel={false}
        required={false}
        labelValue={"Tujuan Khusus"}
        classNameLabel={"text-[#616161] font-medium text-[14px]"}
        htmlFor={`tujuan-khusus-${index}`}
        inputId={`tujuan-khusus-${index}`}
        classNameInput={
          "rounded-xl border-[#E0E0E0] p-[12px] w-[95%] placeholder:text-[#C2C2C2] focus:ring-[#9360A8] "
        }
        placeholder={"Tujuan Khusus"}
        type={"text"}
      />
      <Icon
        name="Optional-input"
        w={20}
        className={"absolute right-0 top-0 cursor-pointer"}
        h={20}
        onClick={() => handleDeleteFormClick(index)}
      />
    </div>
  );

  const [optionalInputs, setOptionalInputs] = useState([
    createOptionalInput(0),
  ]);

  const handleDeleteFormClick = (index) => {
    setOptionalInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  };

  const handleAddInputOption = () => {
    setOptionalInputs((prevInputs) => [
      ...prevInputs,
      createOptionalInput(prevInputs.length),
    ]);
  };

  // const [addedInput,setIsaAddedInput] = useState([optionalInput])
  // end optional input
  return (
    <LayoutNewWebinar activeTab={1}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">Judul Webinar</h3>
          <p className="text-netral-6">Tetapkan judul webinar Anda</p>
        </div>
        <div className="space-y-2">
          <label
            htmlFor="title"
            className="inline-block text-sm text-netral-8 font-semibold"
          >
            Judul Webinar
          </label>
          <input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="p-3 rounded border border-[#757575] w-full placeholder:text-netral-5"
            placeholder="Masukkan Judul Webinar Anda"
          />
        </div>
        <PrevOrNextButton
          prev={() => push("/new")}
          next={() => {
            dispatch({ type: SET_WEBINAR_TITLE, payload: title });
            push(`/new/webinar/${id}/step2`);
          }}
          active={title.length}
        />
      </div>
    </LayoutNewWebinar>
  );
};

export default Step1;
