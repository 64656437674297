import React, { useEffect } from "react";
import { _verifyPrakerja } from "../config/services/api/courseProgress";
import { useDispatch } from "react-redux";
import { SET_ALERT } from "../config/redux/types";
import { useHistory } from "react-router-dom";
import { ClipArt, Logo } from "../components/independent/image";

export default function CallbackPrakerja() {
  const dispatch = useDispatch();

  const to = localStorage.getItem("callbackTo");

  const { push } = useHistory();
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("prakerjaPayload"));
    if (data) {
      _verifyPrakerja(
        data.token,
        data.sequence,
        data.redeemCode,
        data.email,
        data.class_id
      )
        .then((res) => {
          // dispatch({
          //   type: SET_ALERT,
          //   payload: { status: res.status, message: res.message },
          // });
          if (res?.response?.data?.attendance_status === 1) {
            // push(to);
          }
        })
        .catch((e) => {
          dispatch({
            type: SET_ALERT,
            payload: { status: false, message: e },
          });
          //   setErrorModal(true);
        });
      // .finally(() => setLoading(false));
    } else {
      // setOpenModal(true);
    }
  }, []);

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="w-[300px] lg:w-[400px] flex flex-col justify-center gap-[18px]">
        <div className="flex justify-between items-end">
          <Logo name={"skills.id"} h={50} className="h-[50px]" />
          <Logo name={"new-prakerja"} h={50} className="h-[29px]" />
        </div>
        <div className="flex justify-center">
          <ClipArt name={"done-vector"} className="h-[220px]" />
        </div>
        <div className="text-netral-9 font-semibold text-base text-center">
          <p>Reedem Code Berhasil di Input</p>
        </div>
        <div className="inline-flex justify-center">
          <div
            className={`flex items-center px-4 py-2 rounded-lg text-[1rem]  font-semibold cursor-pointer gap-[5px] text-[#fff] `}
            style={{
              background:
                "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
            }}
            onClick={() => push(to)}
          >
            Kembali Ke Skills.id
          </div>
        </div>
      </div>
    </div>
  );
}
