import { combineReducers } from "redux";
import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import courseReducer from "./courseReducer";
import newCourseReducer from "./newCourseReducer";
import newWebinarReducer from "./newWebinarReducer";
import cartReducer from "./cartReducer";
import alertReducer from "./alertReducer";
import newVideoReducer from "./newVideoReducer";

const rootReducer = combineReducers({
  authReducer,
  sidebarReducer,
  newCourseReducer,
  newWebinarReducer,
  courseReducer,
  cartReducer,
  alertReducer,
  newVideoReducer
});

export default rootReducer;
