import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardCourse from "../../components/independent/card/card-course";
// import CardWishlist from "../../components/independent/card/card-wishlist";
// import InputDropdownLabel from "../../components/independent/input/input-dropdown-label";
// import InputSearchDropdown from "../../components/independent/input/input-search-dropdown";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
import { _getWishlist } from "../../config/services/api/wishlist";

export default function Wishlist() {
  const { token } = useSelector((state) => state.authReducer);
  const [wishlist, setWishlist] = useState([]);

  useEffect(() => {
    _getWishlist(token).then((res) => {
      if (!res.status) throw new Error(res.message);
      setWishlist(res.class);
    });
  }, [token]);

  return (
    <LayoutDashboard>
      <div className="space-y-8">
        <div className="space-y-3 text-primary-900">
          <h4 className="text-2xl font-semibold">Wishlist</h4>
          <p>Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat</p>
        </div>
        {/* <div className="flex items-end justify-end space-x-6">
          <InputSearchDropdown />
          <InputDropdownLabel>level</InputDropdownLabel>
          <InputDropdownLabel>type</InputDropdownLabel>
        </div> */}
        <div className="grid grid-cols-4 gap-6">
          {wishlist.map((el, i) => (
            <CardCourse key={i} course={el} />
          ))}
          {/* <CardWishlist /> */}
        </div>
      </div>
    </LayoutDashboard>
  );
}
