import { Link } from "react-router-dom";

export default function BottomNavMobile({ navigateToCheckout, price }) {
  return (
    <div className="lg:hidden bg-netral-2 fixed bottom-0 left-0 w-full px-4 py-6 flex items-center justify-between">
      <div className="space-y-3">
        <p className="text-netral-9">Total Harga</p>
        <h4 className="text-netral-10 font-semibold text-xl">
          IDR {Number(price).toLocaleString("id-ID")}
        </h4>
      </div>
      <div className="flex items-center space-x-2">
        <button type="button" className="p-1 text-secondary-button-600">
          Batal
        </button>
        <Link
          to={`/checkout?items=${navigateToCheckout}`}
          className="inline-block text-center text-white font-semibold px-3 py-2 rounded bg-secondary-button-600"
        >
          Ke Pembayaran
        </Link>
      </div>
    </div>
  );
}
