import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Icon } from "../../image";
import LogoutButton from "./LogoutButton";

export default function SideNavDashboard({
  isSideNavOpened,
  setSideNavOpened,
}) {
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];
  const currentPath = pathname.replace(`/${route}`, "");
  const [activeLink, setActiveLink] = useState(currentPath);
  const { userData } = useSelector((state) => state.authReducer);
  const isInstructor = userData?.role?.some((role) => role === "Instructor");
  const gradientColor =
    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)";

  const navigations = {
    instructor: [
      {
        text: "Kursus",
        icon: "Training",
        link: "",
      },
      {
        text: "Income",
        icon: "income",
        link: "/income",
      },
      {
        text: "Komunikasi",
        icon: "List View",
        link: "/communication",
      },
      {
        text: "Dashboard",
        icon: "Favorite Cart",
        link: "/dashboard",
      },
      // {
      //   text: "Performa",
      //   icon: "Favorite Cart",
      //   link: "/performance",
      // },
      {
        text: "Profile",
        icon: "Account",
        link: "/profile",
      },
    ],
    student: [
      {
        text: "Kelas saya",
        icon: "Book",
        link: "",
      },
      {
        text: "Wishlist",
        icon: "Mark List",
        link: "/wishlist",
      },
      {
        text: "Transaksi",
        icon: "Basket",
        link: "/transaction",
      },
      {
        text: "Sertifikat",
        icon: "Certificate",
        link: "/certificate",
      },
      {
        text: "Kartu Prakerja",
        icon: "credit card",
        link: "/prakerja",
      },
      {
        text: "Pengaturan Akun",
        icon: "Profile",
        link: "/setting",
      },
      {
        text: "Referral",
        icon: "Benefit",
        link: "/referral",
      },
      {
        text: isInstructor ? "Instructor" : "Become Instructor",
        icon: "Teacher",
        link: "/instructor",
      },
    ],
  };

  useEffect(() => {
    if (pathname) setActiveLink(currentPath);
  }, [pathname, currentPath]);

  return (
    <aside
      onClick={() => setSideNavOpened(false)}
      className={`${
        isSideNavOpened
          ? "translate-x-0"
          : "translate-x-[-100vw] lg:translate-x-0"
      } fixed top-0 left-0 lg:static lg:bg-white z-10 duration-500 w-full h-full lg:w-auto`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="p-6 lg:p-8 w-5/6 lg:w-[318px] h-full bg-netral-2 lg:bg-[#F8F9FA] overflow-y-auto"
      >
        <Link to="/" className="flex items-center space-x-2">
          <Icon
            name="arrow_back-secondary-2-800"
            className="w-4 h-4 lg:w-auto lg:h-auto"
          />
          <span className="font-semibold font-DM-Sans text-primary-800 leading-[22.4px] text-sm lg:text-base">
            Kembali ke Home
          </span>
        </Link>
        <nav className="mt-10 lg:mt-[60px] w-max ">
          <ul className="space-y-5 lg:space-y-[36px]">
            {navigations[route].map((nav, i) => (
              <li key={i}>
                <NavLink
                  exact={true}
                  to={`/${route}${nav.link}`}
                  className="flex items-center space-x-3 text-netral-8 px-4"
                  activeClassName="text-primary-600 bg-netral-1 rounded-xl py-3 shadow-md"
                >
                  <div
                    // className={`${
                    //   nav.link === activeLink ? "bg-primary-600" : "bg-white"
                    // } w-[40px] h-[40px] grid place-items-center rounded-xl`}
                    style={{
                      background: `${
                        nav.link === activeLink ? gradientColor : "bg-white"
                      }`,
                    }}
                    className={` w-[40px] h-[40px] grid place-items-center rounded-xl`}
                  >
                    <Icon
                      name={`${nav.icon}-${
                        nav.link === activeLink ? "netral-1" : "netral-10"
                      }`}
                      className="h-6 w-6 overflow-hidden"
                    />
                  </div>
                  <span className="text-base font-semibold">{nav.text}</span>
                </NavLink>
              </li>
            ))}
            <li>
              <LogoutButton />
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
