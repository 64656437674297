import {
  CHANGE_LOGIN,
  CHANGE_LOGIN_LOADING,
  CHANGE_REGISTER_LOADING,
  SET_LOGIN_MESSAGE,
  SET_REGISTER_MESSAGE,
  CHANGE_LOGIN_STATUS,
  CHANGE_REGISTER_STATUS,
  SET_USER,
  SET_TOKEN,
  CHANGE_OTP_LOADING,
  SET_OTP_MESSAGE,
  CHANGE_OTP_STATUS,
} from "../types";

const initialState = {
  token: localStorage.getItem("token") || "",
  isLogin: false,
  userData: { role: [] },
  loginLoading: false,
  registerLoading: false,
  otpLoading: false,
  loginMessage: "",
  registerMessage: "",
  otpMessage: "",
  loginStatus: false,
  registerStatus: false,
  otpStatus: true,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TOKEN:
      return { ...state, token: payload };
    case CHANGE_LOGIN:
      return { ...state, isLogin: payload };
    case SET_USER:
      return { ...state, userData: payload };
    case CHANGE_LOGIN_LOADING:
      return { ...state, loginLoading: payload };
    case CHANGE_REGISTER_LOADING:
      return { ...state, registerLoading: payload };
    case CHANGE_OTP_LOADING:
      return { ...state, otpLoading: payload };
    case SET_LOGIN_MESSAGE:
      return { ...state, loginMessage: payload };
    case SET_REGISTER_MESSAGE:
      return { ...state, registerMessage: payload };
    case SET_OTP_MESSAGE:
      return { ...state, otpMessage: payload };
    case CHANGE_LOGIN_STATUS:
      return { ...state, loginStatus: payload };
    case CHANGE_REGISTER_STATUS:
      return { ...state, registerStatus: payload };
    case CHANGE_OTP_STATUS:
      return { ...state, otpStatus: payload };
    default:
      return state;
  }
}
