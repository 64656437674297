import { useSelector } from "react-redux";
import { useLocation, Redirect, Route } from "react-router-dom";

function InstructorRoute({ component: Component, ...restOfProps }) {
  const { isLogin, userData } = useSelector((state) => state.authReducer);

  const isInstructor = userData.role?.some((role) => role === "Instructor");

  const { pathname } = useLocation();

  const visit = encodeURIComponent(pathname);

  return (
    <Route
      {...restOfProps}
      render={(props) => {
        if (!isLogin) return <Redirect to={`/login?continue=${visit}`} />;

        if (!isInstructor) return <Redirect to="/" />;

        return <Component {...props} />;
      }}
    />
  );
}

export default InstructorRoute;
