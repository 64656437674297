import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import { SET_WEBINAR_TITLE } from "../../../../config/redux/types";
import { Icomoon, Icon } from "../../../../components/independent/image";
import LayoutNewVideo from "../../../../components/independent/layout/layout-new-video";
import Tabs from "../../../../components/Molecule/Tab";
import TabHeader from "../../../../components/Molecule/Tab";
import SaveToDraft from "../../../../components/Molecule/_video/SaveToDraftButton";
import InputField from "../../../../components/Molecule/InputField";
import TextAreaField from "../../../../components/Molecule/textAreaFiled";
import RadioButton from "../../../../components/atom/RadioButton";
import Labelform from "../../../../components/atom/Label";
import UploadFile from "../../../../components/Molecule/UploadFile";
import SessionsLayout from "../../../../components/Molecule/_video/SessionsLayout";

const Step2 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { curriculum } = useSelector((state) => state.newVideoReducer);
  const dispatch = useDispatch();

  const gradientColor =
    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)";

  const [sessions, setSessions] = useState(curriculum.sessions)

  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([0]);
  console.log(sessions);
  // const [subjects, setSubjects] = useState([
  //   {
  //     title: "",
  //     description: "",
  //     content: "",
  //     additional: "",
  //     selfPractice: "",
  //     quiz: [{}],
  //   },
  // ]);
  


  return (
    <LayoutNewVideo activeTab={2}>
      <SaveToDraft />
      <div className="flex items-center px-[32px] justify-between gap-x-[170px]">
        <div className="flex-1 flex  justify-center flex-col w-full pr-[200px]"></div>
      </div>
      <div
        className="w-full flex gap-8 justify-center"
        style={{ fontFamily: "poppins" }}
      >
        <div className="w-[596px] -mt-7 mb-[128px]">
          <h1 className="text-[24px] font-semibold">Kurikulum Kelas</h1>
          <p className="text-[16px] font-medium text-[#757575]">
            Isi dan lengkapi data kurikulum untuk materi pembelajaran di dalam
            kelas video.
          </p>
          <hr className="mt-[32px] bg-[#E0E0E0]" />
          {/* start form */}
          <div className="relative mb-[32px]">
            <div className="sticky top-[80px] z-50 pt-[32px] bg-netral-1">
              <TabHeader
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTabs={setTabs}
                tabs={tabs}
              />
            </div>
            <div className="flex gap-[14px] w-[150%]">
              <div className="bg-yellow border rounded-b-xl border-netral-40 border-t-0 p-8 w-[596px]">
                {sessions.map((el,idx) => (
                  <SessionsLayout sessionIndex={idx} data={sessions} setSessions={setSessions}/>
                ))}
              </div>
              <div className="sticky top-[306px] mt-[156px] h-11">
                <div className="z-10 border border-netral-4 px-[10px] py-[17px] rounded-lg flex items-center flex-col gap-[16px]">
                  <div className="">
                    <Icomoon
                      icon={"slow_motion_video"}
                      className={
                        "cursor-pointer h-[24px] w-[24px] text-netral-7 hover:text-new-primary-base"
                      }
                    />
                  </div>
                  <div>
                    <Icomoon
                      icon={"playlist_add_check"}
                      className={
                        "cursor-pointer h-[24px] w-[24px] text-netral-7"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between">
            <div
              className="flex gap-1 items-center  cursor-pointer"
              onClick={() => push(`/new/video/${id}`)}
            >
              <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
              <span
                style={{
                  background:
                    "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                className="cursor-pointer text-[1rem] leading-[20px] font-bold"
              >
                Kembali
              </span>
            </div>

            <div
              className={`flex items-center px-4 py-2 rounded-lg text-[1rem] font-semibold cursor-pointer gap-[5px] text-[#fff]`}
              style={{
                background: gradientColor,
              }}
              onClick={() => {
                // dispatch({ type: SET_WEBINAR_TITLE, payload: title });
                push(`/new/video/${id}/step3`);
              }}
            >
              Lanjutkan
              <Icon
                name="keyboard_arrow_right"
                h={20}
                w={20}
                className="mt-[3px]"
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutNewVideo>
  );
};

export default Step2;
