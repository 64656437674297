import React from "react";
import { Avatar, Icon, Logo } from "../../image";
import { Link } from "react-router-dom";
import { _switchRole } from "../../../../config/services/api/auth";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

export default function NavHeaderDashboardV2({ isBlue }) {
  const color = isBlue ? "text-netral-1" : "text-[#26255B]";

  const { isLogin, token, userData } = useSelector(
    (state) => state.authReducer
  );

  const { push } = useHistory();
  const { pathname } = useLocation();
  const route = pathname.split("/")[1];

  const isInstructor = userData.role?.some((role) => role === "Instructor");

  const switchRole = () => {
    if (!isLogin) return push("/become-instructor");

    if (userData.role.length >= 2 && route === "student")
      return push("/instructor");

    if (userData.role.length >= 2 && route === "instructor")
      return push("/student");

    if (!isInstructor && route === "student") return push("/become-instructor");

    if (isInstructor && route === "student") return push("/instructor");

    if (!isInstructor && route === "instructor") return push("/student");

    _switchRole(token)
      .then((result) => {
        if (result.status)
          push(`/${route === "instructor" ? "student" : "instructor"}`);
      })
      .catch((e) => console.log("failed switch role", e));
  };

  return (
    <nav
    // className={`w-full z-50  shadow-shadow-header`}
    >
      <div
      //   className={`flex items-center justify-between p-[32px] bg-[#fff]`}
      >
        {route === "instructor" && (
          <div className="flex items-center gap-[32px]">
            <div
              style={{
                background:
                  "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                backgroundClip: "text",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="cursor-pointer text-[1rem] leading-[20px] font-bold"
              onClick={switchRole}
            >
              Switch to Student
            </div>

            <Icon
              name="ShoppingCart"
              w={24}
              h={24}
              className={"cursor-pointer"}
            />
            <Icon
              name="notifications"
              w={24}
              h={24}
              className={"cursor-pointer"}
            />
            <div className="flex items-center gap-[4px] cursor-pointer">
              <Avatar name="avatar-default" w={38} h={38} />
              <Icon name="keyboard_arrow_down" w={24} h={24} />
            </div>
          </div>
        )}

        {route === "student" && (
          <div className="flex items-center gap-[32px]">
            <div className="cursor-pointer">Course</div>
            <div className="cursor-pointer">Category</div>
            <div
              style={{
                background:
                  "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                backgroundClip: "text",
                "-webkit-background-clip": "text",
                "-webkit-text-fill-color": "transparent",
              }}
              className="cursor-pointer text-[1rem] leading-[20px] font-bold"
              onClick={switchRole}
            >
              Switch to Instructor
            </div>

            <Icon
              name="ShoppingCart"
              w={24}
              h={24}
              className={"cursor-pointer"}
            />
            <Icon
              name="notifications"
              w={24}
              h={24}
              className={"cursor-pointer"}
            />
            <div className="flex items-center gap-[4px] cursor-pointer">
              <Avatar name="avatar-default" w={38} h={38} />
              <Icon name="keyboard_arrow_down" w={24} h={24} />
            </div>
          </div>
        )}

        {/* <div className="flex items-center gap-[4px] cursor-pointer">
          <Avatar name="avatar-default" w={38} h={38} />
          <Icon name="keyboard_arrow_down" w={24} h={24} />
        </div> */}
      </div>
    </nav>
  );
}
