export default function CheckoutVirtualAccountInfo({ payment }) {
  return (
    <div className="bg-primary-100/40 rounded-lg p-4 space-y-4">
      <h6 className="text-lg font-semibold text-netral-10 pb-3 border-b border-[#C2C2C2]">
        Pembayaran melalui{" "}
        {payment === "virtual-account" && "Virtual Account Permata Bank"}
        {payment === "bank" && "Bank Transfer BCA"}
      </h6>
      <div className="flex items-center space-x-3">
        <div className="rounded-full w-[6px] h-[6px] bg-primary2-600"></div>
        <p className="text-netral-9">
          Dapatkan <span className="font-semibold">Kode Pembayaran</span>{" "}
          setelah klik “Bayar Sekarang”
        </p>
      </div>
      <div className="flex items-center space-x-3">
        <div className="rounded-full w-[6px] h-[6px] bg-primary2-600"></div>
        <p className="text-netral-9">
          <span className="font-semibold">Tidak Disarankan</span> bayar melalui
          bank lain agar transaksi dapat diproses tanpa kendala
        </p>
      </div>
    </div>
  );
}
