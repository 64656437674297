import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { _getRatingById } from "../../../../config/services/api/rating";
import getLink from "../../../../constants/getLink";
import Stars from "../../../independent/stars/stars";

export default function Reviews({ data }) {
  const [allRating, setAllRating] = useState({});
  const { token } = useSelector((state) => state.authReducer);
  const { webinarId, courseId } = useParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    _getRatingById(
      token,
      webinarId || courseId,
      `page_number=${page}&page_size=${5}`
    )
      .then((res) => setAllRating(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="flex justify-between space-x-2 lg:space-x-10">
        {/* 1  */}
        <div>
          <div className="flex items-center space-x-[9px]">
            <h3 className="text-xl lg:text-2xl font-semibold text-secondary3-900">
              {allRating.averageRating?.toFixed(1)}
            </h3>
            <div className="space-y-1 lg:space-y-2">
              <div className="flex items-center space-x-1 lg:space-x-2">
                <Stars
                  n={allRating.averageRating}
                  className="w-3 h-3 lg:w-5 lg:h-5"
                />
              </div>
              <p className="text-secondary3-400 text-sm lg:text-base">
                {allRating?.totalData} Ulasan
              </p>
            </div>
          </div>
        </div>
        {/* 2  */}
        <div className="space-y-[10px] lg:space-y-[19px] flex-grow flex flex-col justify-center">
          <div className="w-full h-[11px] bg-netral-4">
            <div
              style={{ width: `${allRating?.percentage?.five}%` }}
              className="bg-primary-700 h-full"
            ></div>
          </div>
          <div className="w-full h-[11px] bg-netral-4">
            <div
              style={{ width: `${allRating?.percentage?.four}%` }}
              className="bg-primary-700 h-full"
            ></div>
          </div>
          <div className="w-full h-[11px] bg-netral-4">
            <div
              style={{ width: `${allRating?.percentage?.three}%` }}
              className="bg-primary-700 h-full"
            ></div>
          </div>
        </div>
        {/* 3  */}
        <div>
          <div className="space-y-2 w-max">
            <div className="flex items-center justify-between space-x-1 lg:space-x-[5px]">
              <Stars n={5} className="w-3 h-3 lg:w-5 lg:h-5" />
              <p className="text-primary-600 text-xs lg:text-base">
                {allRating?.percentage?.five}%
              </p>
            </div>
            <div className="flex items-center justify-between space-x-[5px]">
              <Stars n={4} className="w-3 h-3 lg:w-5 lg:h-5" />
              <p className="text-primary-600 text-xs lg:text-base">
                {allRating?.percentage?.four}%
              </p>
            </div>
            <div className="flex items-center justify-between space-x-[5px]">
              <Stars n={3} className="w-3 h-3 lg:w-5 lg:h-5" />
              <p className="text-primary-600 text-xs lg:text-base">
                {allRating?.percentage?.three}%
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h4 className="lg:text-2xl font-semibold text-netral-10">Review</h4>
        <div className="mt-3 space-y-6">
          {allRating.result &&
            allRating?.result.map((el, idx) => (
              <div
                className="flex space-x-3 pb-6 border-b-2 border-[#EDEDED]"
                key={idx}
              >
                <div>
                  <img
                    src={getLink(el.profile_picture)}
                    alt={"profile"}
                    className="h-[50px] w-[50px] rounded-full"
                  />
                </div>
                <div className="w-full">
                  <h6 className="text-lg font-semibold text-netral-9">
                    {el.name}
                  </h6>
                  <div className="mt-1 flex items-center space-x-3">
                    <Stars n={el.rating} />
                    <p className="text-sm text-netral-6">
                      {moment(el.updatedAt).fromNow()}
                    </p>
                  </div>
                  <p className="mt-3 text-netral-9">{el.review}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
