export default function ButtonPrimary({
  width,
  disable,
  margin = "mt-6",
  marginL = "mt-8",
  children,
  ...rest
}) {
  return (
    <button
      {...rest}
      disabled={disable}
      className={`${
        !disable
          ? "bg-secondary-button-600 text-white interactive-button-color"
          : "bg-netral-5 text-netral-8 cursor-not-allowed"
      } ${width} ${margin} px-3 lg:${marginL} py-1 lg:py-2 rounded-md font-semibold text-sm lg:text-base border border-transparent`}
    >
      {children}
    </button>
  );
}

ButtonPrimary.defaultProps = {
  width: "w-full",
};
