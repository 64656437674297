import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaymentEvidence from "../../components/dependent/checkout/payment-evidence";
// import { Link } from "react-router-dom";
import Footer from "../../components/independent/footer/footer";
import Header from "../../components/independent/header/header";
import { Icon } from "../../components/independent/image";
import Modal from "../../components/independent/modal";
import ModalCheckPaymentStatus from "../../components/independent/modal/modal-check-payment-status";
import ModalPaymentDetai from "../../components/independent/modal/modal-payment-detail";
import NavHeader from "../../components/independent/navigation/nav-header";
import { _getPaymentDetail } from "../../config/services/api/checkout";
import useCountDown from "../../hooks/lib/useCountDown";
import Success from "./Success";
// import useQuery from "../../hooks/lib/useQuery";
// import Success from "./Success";

const CheckoutDetail = () => {
  const { checkoutId } = useParams();
  const { token } = useSelector((state) => state.authReducer);

  const [modal, setModal] = useState("");
  const [data, setData] = useState({});

  const getDetailPayment = () => {
    _getPaymentDetail(token, checkoutId).then((res) => {
      if (!res.status) {
        return console.log("cannot get payment detail", res);
      }
      setData(res.data);
    });
  };

  useEffect(() => {
    getDetailPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, checkoutId]);

  return data.status_payment !== "paid" ? (
    <>
      <Modal modal={modal}>
        {modal === "payment-detail" && (
          <ModalPaymentDetai setModal={setModal} data={data} />
        )}
        {modal === "payment-evidence" && (
          <PaymentEvidence
            setModal={setModal}
            getDetailPayment={getDetailPayment}
          />
        )}
        {modal === "check-payment-status" && (
          <ModalCheckPaymentStatus setModal={setModal} />
        )}
      </Modal>
      <Header className="fixed top-0">
        <NavHeader />
      </Header>
      <>
        <section className="px-4 lg:px-[135px] pt-6">
          <div className="mt-[72px] lg:mt-[88px] space-y-2 lg:space-y-3 pb-3 lg:pb-6 border-b-2 border-[#E0E0E0]">
            <h4 className="text-xl lg:text-2xl font-semibold text-netral-10">
              {data.status_payment === "waiting for payment" &&
                "Segera lakukan pembayaran"}
              {data.status_payment === "waiting for verification" &&
                "Berhasil mengupload bukti pembayaran"}
            </h4>
            <p className="text-netral-9 text-sm lg:text-base">
              {data.status_payment === "waiting for payment" &&
                "Selesaikan pembayaran Anda dalam waktu 48 jam. Setelah pembayaran diselesaikan, butuh waktu hingga 30 menit untuk mengirimkan kursus ke akun Skills.id Anda. Setelah kursus dikirim, Anda akan menerima notifikasi melalui email."}
              {data.status_payment === "waiting for verification" &&
                "Bukti transfer telah diupload, Silahkan tunggu sampai admin memverifikasi pesanan anda"}
            </p>
          </div>
          <div>
            <h5 className="text-lg lg:text-xl font-semibold text-netral-10 text-center mt-3 lg:mt-6">
              {data.status_payment === "waiting for payment" &&
                "Selesaikan pembayaran dalam"}
              {data.status_payment === "waiting for verification" &&
                "Silahkan tunggu sampai admin memverifikasi pesanan anda"}
            </h5>
            {data.status_payment === "waiting for payment" && (
              <>
                <CountdownDate targetDate={data.expires_date || 0} />
                <p className="font-semibold text-netral-7 text-center mt-6 text-sm lg:text-base">
                  Batas Akhir pembayaran
                </p>
                <h6 className="lg:text-lg font-semibold text-netral-10 text-center mt-3 lg:mt-4">
                  { moment(data.expires_date || 0).format('Do MMMM YYYY')}
                </h6>
              </>
            )}
          </div>
          <div className="mt-8 lg:w-[596px] mx-auto rounded-lg bg-netral-2 p-3 lg:px-4 lg:py-6 space-y-6">
            <div className="pb-3 lg:pb-6 border-b-2 border-[#E0E0E0]">
              <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
                {data.payment_method}
              </h5>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex flex-col space-y-2">
                <p className="text-sm lg:text-base text-netral-9">
                  Nomor Rekening Bank BCA
                </p>
                <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
                  {data.number_bank_account}
                </h5>
              </div>
              <div className="flex items-center space-x-2 p-1">
                <Icon
                  name="content_copy-secondary-button-600"
                  className="w-5 h-5 lg:w-[22px] lg:h-[22px]"
                />
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(data.number_bank_account)
                      .then(() => alert("nomor rekening berhasil disalin"))
                      .catch((e) => alert(e));
                  }}
                  className="font-semibold text-primary-600 text-sm lg:text-base"
                >
                  Salin
                </button>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <p className="text-sm lg:text-base text-netral-9">Atas Nama</p>
              <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
                {data.name_account}
              </h5>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex flex-col space-y-2">
                <p className="text-sm lg:text-base text-netral-9">
                  Total Pembayaran
                </p>
                <h5 className="text-lg lg:text-xl font-semibold text-netral-10">
                  Rp {data?.total_price?.toLocaleString("id-ID")}
                </h5>
              </div>
              <div className="p-1">
                <button
                  type="button"
                  onClick={() => setModal("payment-detail")}
                  className="font-semibold text-primary-600 text-sm lg:text-base"
                >
                  Lihat Detail
                </button>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button
              type="button"
              onClick={() => {
                if (data.status_payment === "waiting for payment") {
                  setModal("payment-evidence");
                }
                if (data.status_payment === "waiting for verification") {
                  getDetailPayment();
                  setModal("check-payment-status");
                }
              }}
              className="font-semibold text-white px-3 py-2 bg-primary2-500 rounded interactive-button-color border border-transparent text-sm lg:text-base"
            >
              {data.status_payment === "waiting for payment" &&
                "Saya sudah bayar"}
              {data.status_payment === "waiting for verification" &&
                "Check Status Pembayaran"}
            </button>
          </div>
        </section>
        {/* <section className="mt-[112px] mb-[calc(112px-2rem)]">
          <OtherCourse />
        </section> */}
        <Footer className="mt-20" />
      </>
    </>
  ) : (
    <Success />
  );
};

export default CheckoutDetail;

const CountdownDate = ({ targetDate }) => {
  const expiresDate = new Date(targetDate).getTime();
  const { hours, minutes, seconds } = useCountDown(expiresDate);
  const isExpired = hours < 0 && minutes < 0 && seconds < 0;

  return (
    <h6 className="lg:text-lg font-semibold text-secondary3-500 text-center mt-3 lg:mt-4">
      {isExpired ? "Expired" : `${hours}:${minutes}:${seconds}`}
    </h6>
  );
};
