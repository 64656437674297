import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getStudentCourse } from "../../../config/redux/actions/courseAction";

export default function LearnCourse({ children }) {
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const { token } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(getStudentCourse(token, courseId, replace));
  }, [courseId, token, dispatch, replace]);

  useEffect(() => {
    if (!pathname) return;

    const learnStr = localStorage.getItem("learn");

    if (!learnStr)
      return localStorage.setItem(
        "learn",
        JSON.stringify({ [courseId]: { lastVisited: pathname } })
      );

    let learn = JSON.parse(learnStr);

    learn = { ...learn, [courseId]: { lastVisited: pathname } };

    localStorage.setItem("learn", JSON.stringify(learn));
  }, [pathname, courseId]);

  return children;
}
