import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { logout } from "../../../../config/redux/actions/authAction";
import { CHANGE_SIDEBAR } from "../../../../config/redux/types";
import getLink from "../../../../constants/getLink";
import { Icon } from "../../image";

export default function SideNavMobile() {
  const { push } = useHistory();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { isLogin, userData } = useSelector((state) => state.authReducer);
  const { isSidebarOpened } = useSelector((state) => state.sidebarReducer);
  const dispatch = useDispatch();

  const toggleNav = (targetIndex) => {
    targetIndex === activeDropdown
      ? setActiveDropdown(null)
      : setActiveDropdown(targetIndex);
  };

  const userNavigation = useMemo(() => {
    if (userData?.role?.includes("Instructor")) {
      return instructorNavs
    } else {
      return userNavs;
    }
  }, [userData]);

  const userNavigation2 = useMemo(() => {
    if (userData?.role?.includes("Instructor")) {
      return instructorNavs2
    } else {
      return userNavs2;
    }
  }, [userData]);


  return (
    <>
      {isSidebarOpened && (
        <div
          onClick={() => dispatch({ type: CHANGE_SIDEBAR, payload: false })}
          className="fixed top-0 left-0 w-screen h-screen bg-netral-10/50 z-[9999]"
        ></div>
      )}
      <aside
        className={`${
          isSidebarOpened ? "translate-x-0" : "translate-x-[-100vw]"
        } bg-white w-5/6 h-screen lg:hidden fixed top-0 left-0 duration-500 z-[9999] overflow-x-hidden overflow-y-auto`}
      >
        <div className="bg-primary-900/80 p-4 space-y-4">
          <div className="text-right">
            <button
              type="button"
              onClick={() => dispatch({ type: CHANGE_SIDEBAR, payload: false })}
            >
              <Icon name="close-netral-10" className="h-8 w-8 invert" />
            </button>
          </div>
          {!isLogin ? (
            <Link
              to="/login"
              className="text-lg font-semibold text-netral-1 block"
            >
              Login dan Register
            </Link>
          ) : (
            <div className="flex space-x-4">
              <div>
                <div className="w-[83px] h-[83px] rounded-full overflow-hidden">
                  <img
                    src={getLink(userData.profile_picture?.link)}
                    alt={userData.profile_picture?.name}
                    className="h-full w-full"
                  />
                </div>
              </div>
              <div className="w-full">
                <h5 className="text-lg font-semibold text-netral-1">{`${userData.name}`}</h5>
                <p className="text-sm text-netral-1 mt-1">Student</p>
                <button className="px-3 py-2 rounded border border-netral-1 text-netral-1 font-semibold text-sm mt-[11px]">
                  Lihat Profile
                </button>
              </div>
            </div>
          )}
        </div>
        <nav className="px-4">
          {isLogin && (
            <>
              <ul className="pt-2 pb-3 border-b border-b-netral-6 space-y-3">
                {userNavigation.map((nav, i) => (
                  <li key={i}>
                    <Link to={nav.link} className="inline-block text-netral-9">
                      {nav.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <ul className="pt-2 pb-3 border-b border-b-netral-6 space-y-3">
                {userNavigation2.map((nav, i) => (
                  <li key={i}>
                    <Link to={nav.link} className="inline-block text-netral-9">
                      {nav.name}
                    </Link>
                  </li>
                ))}
                <li>
                  <button
                    className="text-netral-9"
                    type="button"
                    onClick={() => dispatch(logout())}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </>
          )}
          <ul className="py-6 space-y-6">
            {navs.map((nav, navIndex) => (
              <li key={navIndex}>
                <button
                  type="button"
                  onClick={() => {
                    if (nav.sub.length) toggleNav(navIndex);
                    else push(nav.link);
                  }}
                  className={`${
                    nav.color || "text-netral-10"
                  } text-lg font-semibold flex items-center space-x-2`}
                >
                  <span>{nav.name}</span>
                  {nav.sub.length ? (
                    <Icon
                      name="keyboard_arrow_down-netral-10"
                      className={`${
                        activeDropdown === navIndex && "rotate-180"
                      } w-6 h-6 duration-200`}
                    />
                  ) : (
                    <></>
                  )}
                </button>
                {nav.sub.length ? (
                  <ul
                    className={`${
                      activeDropdown === navIndex ? "h-[204px]" : "h-0"
                    } ml-[38px] mt-4 space-y-4 overflow-hidden duration-200`}
                  >
                    {nav.sub.map((subNav, subIndex) => (
                      <li key={subIndex}>
                        <button
                          type="button"
                          onClick={() => push(subNav.link)}
                          className="text-netral-10 text-lg font-semibold"
                        >
                          {subNav.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </>
  );
}

const userNavs = [
  { name: "Dashboard", link: "/student" },
  { name: "Wishlist", link: "/wishlist" },
  { name: "Kelas Saya", link: "/student" },
  { name: "Notifikasi", link: "/student" },
  { name: "Keranjang", link: "/cart" },
  {
    name: "Sertifikat",
    link: "/student/certificate",
    color: undefined,
    sub: [],
  },
];

const instructorNavs = [
  { name: "Dashboard", link: "/instructor/dashboard" },
  { name: "Kursus", link: "/instructor" },
  { name: "Income", link: "/instructor/income" },
  { name: "Communication", link: "/instructor/communication" },
];

const userNavs2 = [
  { name: "Profile", link: "/student/setting" },
  { name: "Setting", link: "/student/wishlist" },
];

const instructorNavs2 = [
  { name: "Profile", link: "/instructor/profile" },
  { name: "Setting", link: "/instructor/wishlist" },
];

const navs = [
  {
    name: "Prakerja",
    link: "/prakerja",
    color: "text-primary-500",
    sub: [],
  },
  {
    name: "Kategori",
    link: undefined,
    color: undefined,
    sub: [
      {
        name: "Semua",
        link: "/course",
      },
      {
        name: "Design",
        link: "/course",
      },
      {
        name: "Pengembangan dan IT",
        link: "/course",
      },
      {
        name: "Business",
        link: "/course",
      },
      {
        name: "Marketing",
        link: "/course",
      },
    ],
  },
  {
    name: "Webinar",
    link: "/course",
    color: undefined,
    sub: [],
  },

  {
    name: "Mengajar di Skills.id",
    link: "/instructor",
    color: "text-primary2-500",
    sub: [],
  },
  {
    name: "Tanya Jawab",
    link: "/faq",
    color: undefined,
    sub: [],
  },
  {
    name: "Tentang kami",
    link: "/about",
    color: undefined,
    sub: [],
  },
  {
    name: "Artikel",
    link: "/article",
    color: undefined,
    sub: [],
  },
];
