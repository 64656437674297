import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useMultiQuery from "../../../../hooks/lib/useMultiQuery";
import useQuery from "../../../../hooks/lib/useQuery";
import InputDropdownLabel from "../../../independent/input/input-dropdown-label";
import Stars2 from "../../../independent/stars/stars2";

export default function Filter({ className }) {
  let { pathname, search } = useLocation();
  const { push } = useHistory();
  const query = useQuery();
  const levelQuery = query.get("level");
  const typeQuery = query.get("type");

  const [level, setLevel] = useState(levelQuery || "---Pilih Level---");
  const [type, setType] = useState(levelQuery || "---Pilih Tipe---");

  const navigateSearch = (key, value) => {
    const newQuery = `${key}=${value}`;
    if (value === "all") return push("/course");
    if (!search) return push(`/course?${newQuery}&`);
    if (search.includes(value)) {
      const removedSearch = search.replace(`${newQuery}&`, "");
      push("/course" + removedSearch);
      return;
    }
    push(`/course${search}${newQuery}&`);
  };

  const categoriesQuery = useMultiQuery("category");
  const durationsQuery = useMultiQuery("duration");
  const ratingsQuery = useMultiQuery("rating");

  const isChecked = (key, value) => {
    if (!search && value === "all") return true;
    if (key === "category") return categoriesQuery.includes(value);
    if (key === "duration") return durationsQuery.includes(value);
    if (key === "rating") return ratingsQuery.includes(value);
    return false;
  };

  return (
    <div className={`w-1/3 space-y-8 ${className}`}>
      <div className="flex items-center space-x-6">
        <InputDropdownLabel
          value={level}
          setValue={(value) => {
            setLevel(value);
            if (!search) pathname += `?level=${value}&`;
            else {
              if (search.includes("level")) {
                const replaced = search.replace(
                  `level=${levelQuery}`,
                  `level=${value}`
                );
                pathname += replaced;
              } else {
                pathname += search + "level=" + value + "&";
              }
            }
            push(pathname);
          }}
          values={["---Pilih Level---", "Beginner", "Intermediete", "Expert"]}
        >
          Level
        </InputDropdownLabel>
        <InputDropdownLabel
          value={type}
          setValue={(value) => {
            setType(value);
            if (!search) pathname += `?type=${value}&`;
            else {
              if (search.includes("type")) {
                const replaced = search.replace(
                  `type=${typeQuery}`,
                  `type=${value}`
                );
                pathname += replaced;
              } else {
                pathname += search + "type=" + value + "&";
              }
            }
            push(pathname);
          }}
          values={[
            "---Pilih Tipe---",
            "Umum",
            "Prakerja",
            "Umum dan Prakerja",
            "webinar",
          ]}
        >
          Type
        </InputDropdownLabel>
      </div>
      <ul className="space-y-8">
        {filters.map((filter, filterIndex) => (
          <li key={filterIndex}>
            <h4 className="text-xl font-semibold text-netral-8 pb-2 border-b border-b-netral-5">
              {filter.title}
            </h4>
            <ul className="mt-2 space-y-4">
              {filter.checks.map((check, checkIndex) => (
                <li
                  key={checkIndex}
                  className="pl-1 py-[2px] space-x-5 flex items-center"
                >
                  <input
                    id={check.id}
                    type="checkbox"
                    checked={isChecked(filter.key, check.search)}
                    onChange={(e) => navigateSearch(filter.key, check.search)}
                    className="w-6 h-6"
                  />
                  {check.label ? (
                    <label htmlFor={check.id} className="text-lg text-netral-9">
                      {check.label}
                    </label>
                  ) : (
                    <label htmlFor={"star-" + check.star}>
                      <Stars2 n={check.star} size="w-6 h-6" />
                    </label>
                  )}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

const filters = [
  {
    title: "Kategori",
    key: "category",
    checks: [
      { label: "Semua", id: "All", search: "all" },
      { label: "Sales", id: "sales", search: "Sales" },
      {
        label: "IT/Sofware",
        id: "development",
        search: "IT/Sofware",
      },
      { label: "Jurnalistik", id: "jurnalistik", search: "Jurnalistik" },
      { label: "Bisnis", id: "business", search: "Bisnis" },
      { label: "Communication", id: "communication", search: "Communication" },
      { label: "Design", id: "design", search: "Design" },
      { label: "Language", id: "language", search: "Language" },
      {
        label: "Marketing",
        id: "marketing",
        search: "Marketing",
      },
      {
        label: "Seni/Kerajinan",
        id: "art",
        search: "Seni/Kerajinan",
      },
      {
        label: "Lifestyle",
        id: "Lifestyle",
        search: "Lifestyle",
      },
    ],
  },
  {
    title: "Durasi Video",
    key: "duration",
    checks: [
      { label: "0 - 1 Jam", id: "0-1", search: "1" },
      { label: "1 - 3 Jam", id: "1-3", search: "2" },
      { label: "3 - 6 Jam", id: "3-6", search: "3" },
      { label: "6 - 9 Jam", id: "6-9", search: "4" },
      { label: "9 - 12 Jam", id: "9-12", search: "5" },
    ],
  },
  {
    title: "Peringkat",
    key: "rating",
    checks: [
      { star: 5, id: "star-5", search: "1" },
      { star: 4, id: "star-4", search: "2" },
      { star: 3, id: "star-3", search: "3" },
      { star: 2, id: "star-2", search: "4" },
      { star: 1, id: "star-1", search: "5" },
    ],
  },
];
