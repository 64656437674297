export default function Details({
  action,
  isVerified,
  price,
  discount,
  isLoading,
}) {
  return (
    <aside className="lg:w-1/3">
      <div
        // style={{ boxShadow: "0px 8px 60px rgba(0, 0, 0, 0.1)" }}
        className="w-full bg-netral-2 lg:bg-white rounded-lg px-2 py-2 lg:px-3 lg:py-4 shadow-md lg:shadow-xl"
      >
        <div className="w-full h-full">
          <div className="pb-2 lg:pb-3 border-b-2 border-[#EDEDED]">
            <h5 className="text-netral-10 font-semibold text-lg lg:text-xl">
              Rincian
            </h5>
          </div>
          <div className="py-4 lg:py-6 space-y-[6px] border-b-2 border-[#616161]">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <p className="w-[65px] text-netral-8 text-sm lg:text-base">
                  Subtotal
                </p>
                <p className="text-netral-10 font-semibold text-xs">x1</p>
              </div>
              <p className="text-netral-10 font-semibold text-sm lg:text-base">
                Rp {Number(price).toLocaleString("id-ID")}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <p className="w-[65px] text-netral-8 text-sm lg:text-base">
                  Discount
                </p>
                <p className="text-netral-10 font-semibold text-xs">
                  {discount}%
                </p>
              </div>
              <p className="text-netral-10 font-semibold text-sm lg:text-base">
                {price * discount}
              </p>
            </div>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <p className="text-netral-8 text-sm lg:text-base">
              Total Seluruhnya
            </p>
            <h6 className="lg:text-lg font-semibold text-netral-10">
              Rp {Number(price).toLocaleString("id-ID")}
            </h6>
          </div>
          <div className="mt-4 lg:mt-8 space-y-2 lg:space-y-[5px]">
            <p className="text-netral-9 text-2xs">
              Skills.id diwajibkan oleh hukum untuk menagih pajak transaksi yang
              berlaku untuk pembelian yang dilakukan dalam yurisdiksi tertentu.
            </p>
            <p className="text-netral-9 text-2xs">
              Dengan menyelesaikan pembelian, Anda menyetujui{" "}
              <span className="font-semibold">Ketentuan Layanan</span> ini.
            </p>
          </div>
          <div className="mt-[22px] space-y-2 hidden lg:block">
            <button
              type="button"
              disabled={isLoading}
              onClick={action}
              className={`${
                isLoading
                  ? "text-netral-6 bg-netral-5"
                  : "text-white bg-secondary-button-600"
              } inline-block text-center font-semibold w-full py-2 rounded border border-transparent interactive-button-color`}
            >
              {isVerified ? "Bayar Sekarang" : "Lanjutkan ke Pembayaran"}
            </button>
            <button className="text-secondary-button-600 font-semibold w-full py-2 bg-white rounded border border-[#006A9D] interactive-button-color">
              Batal
            </button>
          </div>
        </div>
      </div>
    </aside>
  );
}
