import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  SET_FINISHED_NAV,
  SET_NEW_COURSE,
  SET_NEW_WEBINAR,
  SET_WEBINAR_FINISHED_NAV,
} from "../../../../config/redux/types";
import {
  _createCourse,
  _deleteFileFromCloud,
  _updateCourse,
} from "../../../../config/services/api/course";

export default function NextOrLaterButton({ active, next, page }) {
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const { push } = useHistory();
  const { token } = useSelector((state) => state.authReducer);
  const newCourse = useSelector((state) => state.newCourseReducer);
  const newWebinar = useSelector((state) => state.newWebinarReducer);
  const dispatch = useDispatch();

  const deleteFilesFromCloud = async () => {
    if (!newCourse.deleteFiles) return;
    if (!newCourse.deleteFiles.length) return;

    const files = newCourse.deleteFiles.map((el) =>
      _deleteFileFromCloud(token, el)
    );
    try {
      const results = await Promise.all(files);
      console.log("deleted", results);
    } catch (error) {
      console.log("error delete files", error);
    }
  };

  const deleteFilesFromCloudWebinar = async () => {
    if (!newWebinar.deleteFiles) return;
    if (!newWebinar.deleteFiles.length) return;

    const files = newWebinar.deleteFiles.map((el) =>
      _deleteFileFromCloud(token, el)
    );
    try {
      const results = await Promise.all(files);
      console.log("deleted", results);
    } catch (error) {
      console.log("error delete files", error);
    }
  };

  const saveLater = () => {
    newCourse.homepage.thumbnail.storage = "cloud";
    newCourse.homepage.intro.storage = "cloud";
    newCourse.skillTest.storage = "cloud";
    newCourse.curriculum.forEach((chapter) => {
      chapter.materials.forEach((material) => {
        if (material.lesson?.file) material.lesson.file.storage = "cloud";
        if (material.lesson?.additionalFile)
          material.lesson.additionalFile.storage = "cloud";
      });
    });

    setLoading(true);

    const { instructor_id, deleteFiles, ...data } = newCourse;

    const lastVisited = pathname.split("/")[5] ? pathname.split("/")[5] : "";

    if (!newCourse._id) {
      _createCourse({ ...data, lastVisited }, token)
        .then((res) => {
          console.log("create course res", res);
          if (!res.status) return alert("something went wrong");
          deleteFilesFromCloud();
          push("/instructor");
        })
        .catch((e) => console.log("error create course", e))
        .finally(() => setLoading(false));
    } else {
      _updateCourse(token, newCourse._id, { ...data, lastVisited }).then(
        (res) => {
          console.log("update course res", res);
          if (!res.status) return alert("something went wrong", res);
          deleteFilesFromCloud();
          push("/instructor");
        }
      );
    }
  };

  const saveCourse = () => {
    newCourse.homepage.thumbnail.storage = "cloud";
    newCourse.homepage.intro.storage = "cloud";
    newCourse.skillTest.storage = "cloud";
    newCourse.curriculum.forEach((chapter) => {
      chapter.materials.forEach((material) => {
        if (material.lesson?.file) material.lesson.file.storage = "cloud";
        if (material.lesson?.additionalFile)
          material.lesson.additionalFile.storage = "cloud";
      });
    });
    setLoading(true);
    dispatch({ type: SET_FINISHED_NAV, payload: page });
    const { instructor_id, deleteFiles, ...data } = newCourse;
    const lastVisited = pathname.split("/")[5] ? pathname.split("/")[5] : "";
    // console.log(new)

    if (!newCourse._id) {
      _createCourse({ ...data, lastVisited }, token)
        .then((res) => {
          console.log("create course res", res);
          if (!res.status) return alert("something went wrong");
          deleteFilesFromCloud();
          dispatch({ type: SET_NEW_COURSE, payload: res.newClass });
        })
        .catch((e) => console.log("error create course", e))
        .finally(() => {
          setLoading(false);
          next();
        });
    } else {
      _updateCourse(token, newCourse._id, { ...data, lastVisited })
        .then((res) => {
          console.log("update course res", res);
          if (!res.status) return alert("something went wrong", res);
          deleteFilesFromCloud();
        })
        .catch((e) => console.log("error create course", e))
        .finally(() => {
          setLoading(false);
          next();
        });
    }
  };

  const saveLaterWebinar = () => {
    newWebinar.homepage.thumbnail.storage = "cloud";
    newWebinar.homepage.intro.storage = "cloud";
    newWebinar.gallery.forEach((image) => {
      image.storage = "cloud";
    });

    setLoading(true);

    const { instructor_id, deleteFiles, ...data } = newWebinar;

    const lastVisited = pathname.split("/")[5];
    if (!newWebinar._id) {
      _createCourse({ ...data, lastVisited }, token)
        .then((res) => {
          console.log("create course res", res);
          if (!res.status) return alert("something went wrong");
          deleteFilesFromCloudWebinar();
        })
        .catch((e) => console.log("error create webinar", e))
        .finally(() => setLoading(false));
    } else {
      _updateCourse(token, newWebinar._id, { ...data, lastVisited }).then(
        (res) => {
          console.log("update webinar res", res);
          if (!res.status) return alert("something went wrong", res);
          deleteFilesFromCloudWebinar();
        }
      );
    }
  };

  const saveWebinar = () => {
    newWebinar.homepage.thumbnail.storage = "cloud";
    newWebinar.homepage.intro.storage = "cloud";
    newWebinar.gallery.forEach((image) => {
      image.storage = "cloud";
    });
    setLoading(true);
    dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: page });

    const { instructor_id, deleteFiles, ...data } = newWebinar;
    const lastVisited = pathname.split("/")[5];
    if (!newWebinar._id) {
      _createCourse({ ...data, lastVisited }, token)
        .then((res) => {
          console.log("create course");
          if (!res.status) return alert("something went wrong");
          deleteFilesFromCloudWebinar();
          dispatch({ type: SET_NEW_WEBINAR, payload: res.newClass });
        })
        .catch((e) => console.log("error create webinar", e))
        .finally(() => {
          setLoading(false);
          next();
        });
    } else {
      _updateCourse(token, newWebinar._id, { ...data, lastVisited })
        .then((res) => {
          console.log("update webinar");
          if (!res.status) return alert("something went wrong", res);
          deleteFilesFromCloudWebinar();
        })
        .catch((e) => console.log("error create webinar", e))
        .finally(() => {
          setLoading(false);
          next();
        });
    }
  };

  const isWebinar = pathname.split("/")[2] === "webinar";

  return !isWebinar
    ? (
        <div className="my-[112px] flex items-center space-x-6">
          <button
            type="button"
            onClick={saveCourse}
            disabled={!active || isLoading}
            className={`${
              !active || isLoading
                ? "bg-netral-5 cursor-not-allowed"
                : "bg-secondary-button-600 interactive-button-color"
            } text-white px-3 py-2 rounded border border-transparent`}
          >
            Simpan dan Lanjutkan
          </button>

          <button
            type="button"
            disabled={isLoading}
            onClick={saveLater}
            className="rounded py-2 px-3 border border-secondary-button-600 font-semibold text-secondary-button-600 interactive-button-color"
          >
            Lanjutkan nanti
          </button>
        </div>
      )
    : (
        <div className="my-[112px] flex items-center space-x-6">
          <button
            type="button"
            onClick={() => {
              saveWebinar();
            }}
            disabled={!active || isLoading}
            className={`${
              !active || isLoading
                ? "bg-netral-5 cursor-not-allowed"
                : "bg-secondary-button-600 interactive-button-color"
            } text-white px-3 py-2 rounded border border-transparent`}
          >
            Simpan dan Lanjutkan
          </button>

          <button
            type="button"
            disabled={isLoading}
            onClick={saveLaterWebinar}
            className="rounded py-2 px-3 border border-secondary-button-600 font-semibold text-secondary-button-600 interactive-button-color"
          >
            Lanjutkan nanti
          </button>
        </div>
      );
}
