import React from "react";
import { Link, useHistory } from "react-router-dom";
import getLink from "../../../../constants/getLink";
import ButtonPrimary from "../../button/button-primary";

export default function BlogCard3({ data, imageHeight = "h-[195px]" }) {
  const { _id, author, createdAt, image, content, title } = data;
  const { history } = useHistory();

  return (
    <Link
      className="space-y-[8px] h-full w-full cursor-pointer"
      to={(`/blog/${_id}`)}
    >
      <div className="w-full overflow-hidden">
        <img
          alt="banner"
          className={`${imageHeight} w-full transition duration-500 ease-in-out hover:scale-125`}
          src={getLink(image)}
        />
      </div>
      <div>
        <div className="space-y-[8px]">
          <p className="text-sm text-netral-6">
            {author} • {createdAt}
          </p>
          <p className="text-base font-semibold text-netral-10 line-clamp-1">
            {title}
          </p>
          <p className="text-xs text-netral-7 line-clamp-2">{content}</p>
        </div>
      </div>
      <div className="block lg:hidden">
        <Link to={`/blog/${_id}`}>
          <ButtonPrimary
            type="submit"
            width="w-max"
            margin="mt-[8px]"
            marginL="mt-4"
          >
            Baca Selengkapnya
          </ButtonPrimary>
        </Link>
      </div>
    </Link>
  );
}
