import { useState } from "react";
import { Icon } from "../../../independent/image";


export default function Syllabus({ data }) {
  const defaultDatas = data.chapters.map((chapter) => ({
    ...chapter,
    isShown: false,
  }));

  const [datas, setDatas] = useState(defaultDatas);

  const changeShownData = (targetIndex) => {
    datas[targetIndex].isShown = !datas[targetIndex].isShown;
    setDatas([...datas]);
  };


  return (
    <div className="space-y-4 pb-[calc(42px-16px)]">
      <p className="text-xs text-netral-8 flex items-center space-x-2">
        <span>{data.totalChapters} Bab</span>
        <span>{data.totalMaterials} Materi</span>
        <span>{data.totalDuration} total durasi</span>
      </p>
      <div>
        {datas.map((elData, i) => (
          <div
            key={i}
            onClick={() => changeShownData(i)}
            className="bg-white rounded border border-[#EDEDED] cursor-pointer"
          >
            <div
              className={`${
                elData.isShown && "border-b border-b-netral-4"
              } flex justify-between items-center mx-2 lg:mx-[16.05px] py-2 lg:py-3`}
            >
              <div className="space-y-1">
                <h6 className="lg:text-lg text-netral-10 font-semibold">
                  {elData.name}
                </h6>
                <div className="flex items-center space-x-[8.02px]">
                  <p className="text-xs lg:text-sm text-netral-7">
                    {elData.totalMaterials} Materi
                  </p>
                  <p className="text-xs lg:text-sm text-netral-7">{elData.description}</p>
                </div>
              </div>
              <button>
                <Icon
                  name="keyboard_arrow_down-netral-10"
                  className={`w-6 h-6 duration-100 ${
                    elData.isShown && "rotate-180"
                  }`}
                />
              </button>
            </div>

            <div
              className={`${
                elData.isShown ? "h-max py-3" : "h-0"
              } mx-2 lg:mx-[16.05px] space-x-1 lg:space-y-3 overflow-hidden duration-100`}
            >
              {elData.material?.map((el, i) => (
                <div key={i} className="flex items-center space-x-1 lg:space-x-3">
                  <Icon
                    name={
                      (el.type === "Pilihan Ganda" &&
                        "format_list_numbered-netral-10") ||
                      (el.type === "video/mp4" && "ondemand_video-netral-8") ||
                      (el.type === "application/pdf" &&
                        "insert_drive_file-netral-10")
                    }
                    className="w-6 lg:w-[46px] h-6 lg:h-[46px]"
                  />
                  <div className="space-y-1">
                    <p className="text-xs lg:text-base font-semibold text-netral-10">{el.name}</p>
                    <p className="text-2xs lg:text-sm text-netral-7">{el.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// const defaultDatas = [
//   {
//     chapter: "Bab 1",
//     lesson: "20 Materi",
//     duration: "2 Jam 54 Menit",
//     materials: [
//       {
//         type: "video/mp4",
//         name: "Video Perkenalan",
//         description: "01:00 Menit",
//       },
//       {
//         type: "application/pdf",
//         name: "Presentasi Perkenalan",
//         description: "5 Halaman",
//       },
//     ],
//     isShown: false,
//   },
//   {
//     chapter: "Bab 2",
//     lesson: "20 Materi",
//     duration: "2 Jam 54 Menit",
//     materials: [],
//     isShown: false,
//   },
//   {
//     chapter: "Bab 3",
//     lesson: "20 Materi",
//     duration: "2 Jam 54 Menit",
//     materials: [],
//     isShown: false,
//   },
//   {
//     chapter: "Bab 4",
//     lesson: "20 Materi",
//     duration: "2 Jam 54 Menit",
//     materials: [],
//     isShown: false,
//   },
// ];

// {
//   totalRating:0,
//   numberOfReviews:0
//   total5Star:0,
//   total4star:0,
//   reviews:[
//     {
//       name:"",
//       rating:0,
//       updatedAt:"",
//       comment:""
//     }
//   ]
// }
