import { BigPlayButton, ControlBar, Player } from "video-react";
import getLink from "../../../../constants/getLink";
import { Icon } from "../../image";

export default function TableClassic({ data, ths, tds, deleteAction }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full rounded-lg overflow-hidden">
        <thead>
          <tr className="flex items-center space-x-2 px-2 py-3 bg-netral-2 text-left">
            <th>
              <p className="w-10 text-sm font-semibold text-netral-10">No</p>
            </th>
            {ths.map((th, i) => (
              <th key={i} className="w-max min-w-[110px] lg:min-w-[200px]">
                <p className="text-sm font-semibold text-netral-10">{th}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((el, i) => {
            el.uploadAt = new Date(el.uploadAt).toDateString();
            return (
              <tr
                key={i}
                className="flex items-center space-x-2 px-2 py-3 text-left"
              >
                <td>
                  <div className="w-10 text-sm text-netral-10">{i + 1}</div>
                </td>
                {tds.map((td, tdIndex) => (
                  <td
                    key={tdIndex}
                    className="overflow-hidden w-max min-w-[110px] lg:min-w-[142px]"
                  >
                    {td === "link" ? (
                      <div className="w-[100px] h-[80px] lg:w-[142px] lg:h-[101px] bg-[#0A0A0A]/60 rounded overflow-hidden">
                        <Player
                          fluid={false}
                          width="100%"
                          height="100%"
                          src={getLink( el[td])}
                        >
                          <ControlBar disableDefaultControls={true} />
                          <BigPlayButton
                            position="center"
                            className="scale-[.25] lg:scale-50"
                          />
                        </Player>
                      </div>
                    ) : (
                      <p className="text-sm text-netral-10 ">{el[td]}</p>
                    )}
                  </td>
                ))}
                <td className="w-max min-w-[60px] lg:min-w-[142px]">
                  <button
                    type="button"
                    onClick={() => deleteAction(el.name)}
                    className="p-1 flex items-center space-x-2"
                  >
                    <Icon
                      name="delete_outline-secondary-3-500"
                      className="w-5 h-5"
                    />
                    <span className="text-secondary3-500 text-sm font-semibold">
                      Hapus
                    </span>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
