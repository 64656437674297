import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _updateProfile } from "../../../config/services/api/auth";
import ButtonPrimary from "../../independent/button/button-primary";
import InputDropdownLabel2 from "../../independent/input/input-dropdown-label2";
import InputNumberLabel from "../../independent/input/input-number-label";
import InputTextLabel from "../../independent/input/input-text-label";

export default function Pembayaran() {
  const { token, userData } = useSelector((state) => state.authReducer);
  const [form, setForm] = useState({
    account_number: "",
    bank_account: "",
    bank_name: "--Pilih Bank--",
    npwp_number: "",
    npwp_name: "",
    npwp_address: "",
  });

  const changeForm = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
    _updateProfile(token, form).then((res) => alert(res.message));
  };

  useEffect(() => {
    if (userData) {
      setForm({
        account_number: userData.account_number || "",
        bank_account:  userData.bank_account || "",
        bank_name:  userData.bank_name || "",
        npwp_number:  userData.npwp_number || "",
        npwp_name:  userData.npwp_name || "",
        npwp_address:  userData.npwp_address || "",
      });
    }
  }, [userData]);

  return (
    <form onSubmit={(e) => submit(e)} className="space-y-8">
      <div className="space-y-6">
        <div>
          <h5 className="text-lg font-semibold text-netral-9">Rekening</h5>
          <p className="mt-2 text-sm text-netral-6">
            Silakan isi data rekening Anda sesuai dengan buku tabungan
          </p>
          <div className="mt-3 grid grid-cols-2 gap-x-6 gap-y-3">
            <InputNumberLabel
              id="account_number"
              text="Nomor Rekening Bank"
              placeholder="Masukkan Nomor Rekening Bank Anda"
              value={form.account_number}
              onChange={(e) => changeForm(e)}
            />
            <InputDropdownLabel2
              id="bank"
              text="Nama Bank"
              values={["--Pilih Bank--", "Permata Bank", "BCA", "BRI"]}
              value={form.bank_name}
              setValue={(value) => setForm({ ...form, bank: value })}
            />
            <InputTextLabel
              id="bank_account"
              text="Nama Pemilik Rekening"
              placeholder="Masukkan Nama Pemilik Rekening"
              value={form.bank_account}
              onChange={(e) => changeForm(e)}
            />
          </div>
        </div>
        <div>
          <h5 className="text-lg font-semibold text-netral-9">NPWP</h5>
          <p className="mt-2 text-sm text-netral-6">
            Silakan isi data rekening Anda sesuai dengan data NPWP Anda
          </p>
          <div className="mt-3 grid grid-cols-2 gap-x-6 gap-y-3">
            <InputNumberLabel
              id="npwp_number"
              text="Nomor NPWP"
              placeholder="Masukkan Nomor NPWP Anda"
              value={form.npwp_number}
              onChange={(e) => changeForm(e)}
            />
            <InputTextLabel
              id="npwp_name"
              text="Nama NPWP"
              placeholder="Masukkan Nama Pemilik Rekening"
              value={form.npwp_name}
              onChange={(e) => changeForm(e)}
            />
            <InputTextLabel
              id="npwp_address"
              text="Alamat NPWP"
              placeholder="Masukkan Alamat NPWP anda"
              value={form.npwp_address}
              onChange={(e) => changeForm(e)}
            />
          </div>
        </div>
      </div>
      <ButtonPrimary type="submit" width="w-max">
        Simpan
      </ButtonPrimary>
    </form>
  );
}
