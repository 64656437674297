import ButtonPrimary from "../../independent/button/button-primary";
import InputTextLabelButton from "../../independent/input/input-text-label-button";

export default function Keamanan() {
  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <InputTextLabelButton
          id="email"
          text="Email"
          placeholder="Masukkan Email Anda"
          buttonText="Ganti Email"
        />
        <InputTextLabelButton
          id="password"
          text="Password"
          placeholder="Masukkan Password Anda"
          buttonText="Ganti Password"
          isPassword
        />
      </div>
      <ButtonPrimary width="w-max">Simpan</ButtonPrimary>
    </div>
  );
}
