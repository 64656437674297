import { APIBaseURL } from "../../environment";

export const _upload = (files) => {
  if (!files || !files.length) throw new Error("404");

  const formdata = new FormData();

  files.forEach((file) => formdata.append("file", file));

  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/admin/uploadFile`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
