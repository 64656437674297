export default function TextArea({
    className,
    placeholder,
    rows,
    name,
    cols,
    id,
    ref,
    value,
    onChange
}) {
  return (
    <textarea name={name} id={id} cols={cols?cols:"15"} rows={rows?rows:"3"} ref={ref} className={className} placeholder={placeholder} onChange={onChange} value={value}>
    </textarea>
  )
}
