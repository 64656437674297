
const getRangeDate = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate)
  currentDate.setUTCDate(currentDate.getUTCDate());
  end.setUTCDate(end.getUTCDate() + 1);

  while (currentDate <= new Date(end)) {
    dateArray.push(new Date(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }

  return dateArray;
};

export default getRangeDate;
