import { useEffect, useRef, useState } from "react";
import useQuery from "../../hooks/lib/useQuery";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { _getCertificate } from "../../../config/services/api/course";
import { Document, Page, View, Text, PDFViewer } from "@react-pdf/renderer";
import ReactToPdf from "react-to-pdf";
import { _getCertificateForPublic } from "../../config/services/api/course";
import { SET_ALERT } from "../../config/redux/types";
import QRCode from "react-qr-code";
import moment from "moment";

var opts = {
  errorCorrectionLevel: "H",
  type: "image/jpeg",
  quality: 0.3,
  margin: 1,
  color: {
    dark: "#010599FF",
    light: "#FFBF60FF",
  },
};

const StudentCertificate = () => {
  const dispatch = useDispatch();
  const ref1 = useRef();
  const ref2 = useRef();
  const query = useQuery();
  const [data, setData] = useState(defaultData);
  const courseId = query.get("courseId");
  const studentId = query.get("studentId");
  const { token } = useSelector((state) => state.authReducer);
  // const [qr, setQr] = useState("");

  // QRCode.toDataURL("text", opts, function (err, url) {
  //   if (err) throw err;

  //   setQr(url);
  // });

  // console.log(qr);

  useEffect(() => {
    _getCertificateForPublic(courseId, studentId)
      .then((res) => {
        if (!res.status) {
          dispatch({
            type: SET_ALERT,
            payload: { status: false, message: res.message },
          });
        }
        setData(res);
      })
      .catch((e) => console.log("error get certificate", e));
  }, [courseId, token, dispatch, studentId]);
  
  return data.course_title ? (
    <div className="relative w-full h-full">
      <div className="space-y-8 flex flex-col items-center pt-10 absolute z-[99999] bg-netral-1">
        <div className="space-y-2">
          <ReactToPdf
            targetRef={ref1}
            filename="certificate.pdf"
            options={{ orientation: "landscape" }}
          >
            {({ toPdf }) => (
              <button
                onClick={toPdf}
                className="rounded-full bg-netral-4 hover:bg-netral-5 text-netral-10 px-4"
              >
                Download
              </button>
            )}
          </ReactToPdf>
          <div
            ref={ref1}
            style={{ fontFamily: "Helvetica" }}
            className="h-[calc(2380px/3)] w-[calc(3408px/3)] bg-contain bg-no-repeat relative"
          >
            {courseId === "63c4467395916094ed779226" ? (
              <img
                src={`/assets/certificate/kelulusan_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }_dudika.jpeg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            ) : courseId === "63c8f181de772832733326e5" ? (
              <img
                src={`/assets/certificate/kelulusan_analisis_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }.jpg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            ) : (
              <img
                src={`/assets/certificate/kelulusan_${data.vendor}${
                  data.predicate === "Sangat Baik" ? "_baik" : ""
                }.jpeg`}
                alt="cert"
                className="w-full h-full absolute top-0 left-0 "
              />
            )}
            <h4
              className={`text-lg text-netral-10 italic font-bold absolute ${
                data.predicate === "Sangat Baik" ? "top-[350px]" : "top-[360px]"
              } left-[300px] w-[680px] text-center`}
            >
              {data.course_title}
            </h4>
            <h1
              className={`text-2xl text-netral-10 italic font-black absolute  ${
                data.predicate === "Sangat Baik" ? "top-[400px]" : "top-[430px]"
              } left-[300px] w-[680px] text-center`}
            >
              {data.certificate_name}
            </h1>
            <h6 className="text-netral-10 italic absolute top-[530px] left-[300px] w-[680px] text-center">
              {moment(data.certificate_generate_date).format(
                "dddd , Do MMMM YYYY"
              )}
            </h6>
            <div className="relative top-[550px] left-[200px] w-[150px]">
              <QRCode
                style={{
                  height: 150,
                  width: 150,
                  maxWidth: "100%",
                }}
                value={`https://skills.id/certificate?courseId=${courseId}&studentId=${studentId}`}
                viewBox={`0 0 256 256`}
              />
              <p className="text-netral-10 text-center my-1 font-medium">
                No.{data.certificate_generate_date}
              </p>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <ReactToPdf
            targetRef={ref2}
            filename="score.pdf"
            options={{ orientation: "landscape" }}
          >
            {({ toPdf }) => (
              <button
                onClick={toPdf}
                className="rounded-full bg-netral-4 hover:bg-netral-5 text-netral-10 px-4"
              >
                Download
              </button>
            )}
          </ReactToPdf>
          <div
            ref={ref2}
            style={{ fontFamily: "Helvetica" }}
            className="h-[calc(2380px/3)] w-[calc(3408px/3)] bg-contain bg-no-repeat relative"
          >
            <img
              src={`/assets/certificate/daftar_penilaian${
                data.vendor === "Umum" ? "_umum" : ""
              }.jpeg`}
              alt="cert"
              className="w-full h-full absolute top-0 left-0 "
            />
            <div className="relative top-[160px] flex justify-center">
              <p className="text-netral-10 text-center text-lg w-[700px] whitespace-pre-wrap">
                {data.competenceTitle}
              </p>
            </div>
            <div className="relative top-[200px] flex-col flex items-center">
              {data.competenceUnit.map((el, idx) => (
                <p className="text-netral-10 text-center text-lg ">
                  <span
                    className="font-bold
              "
                  >
                    {idx + 1}. {el.competenceCode}
                  </span>{" "}
                  - {el.title}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );

  //   return (
  //     <PDFViewer style={{ width: window.innerWidth, height: "100vh" }}>
  //       <Document orientation="landscape">
  //         <Page size="A4">
  //           <View>
  //             <Text>ada</Text>
  //           </View>
  //         </Page>
  //       </Document>
  //     </PDFViewer>
  //   );
};

const defaultData = {
  course_title: "",
  certificate_name: "",
  finished_date: "",
  vendor: "",
  certificateType: "",
  certificate_generate_date: "",
  competenceUnit: {
    competenceCode: "",
    title: "",
  },
  competenceTitle: "",
  nilai: [],
};

export default StudentCertificate;
