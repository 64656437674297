import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SET_NEW_VIDEO, SET_NEW_WEBINAR } from "../../../../config/redux/types";
import { _getCourseInstructor } from "../../../../config/services/api/course";

export const initialNewVideoState = {
  typeCourse: "",
  detail: {
    generalInformation: {
      title: "",
      category: "",
      language: "",
      isPrakerja: "",
      level: "",
      synopsis: "",
      description: "",
      goals: "",
      particularGoals: [""],
      tools: [],
    },
    unitCompetency: {
      competencyUnit: [{ code: "", value: "" }],
      competencyGoals: [
        { type: "KOGNITIF", value: "", id: 1 },
        { type: "PSIKOMOTOR", value: "", id: 2 },
        { type: "AFEKTIF", value: "", id: 3 },
      ],
    },
    regulations: [""],
    audienceTargets: [],
    rules: [""],
  },
  curriculum: {
    sessions: [
      {
        title: "",
        description: "",
        subjects: [
          {
            title: "",
            popup: {
              label: "",
              answer: "",
            },
            file: '',
            additional: '',
          },
          {
            title: "",
            quiz: [
              {
                label: "",
                option: ["", ""],
                answer: "",
              },
            ],
          },
        ],
        selfPractice: {
          description: "",
          file:''
        },
      },
    ],
  },
  questionBank: [
    {
      label: "",
      option: ["", "", "", ""],
      answer: "",
    },
  ],
  competencyTestDescription: "",
  price: "",
  types: [""],
};

const NewVideo = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const result = await _getCourseInstructor(id, token);
        if (!result.status) return;
        dispatch({ type: SET_NEW_VIDEO, payload: result.class });
      } catch (error) {
        console.log("api error", error);
      }
    };
    getCourse();
    return () =>
      dispatch({ type: SET_NEW_VIDEO, payload: initialNewVideoState });
  }, [id, token, dispatch]);

  return children;
};

export default NewVideo;
