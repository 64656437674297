import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import useCart from "../../../../hooks/data-management/useCart";
import ButtonProfile from "../../button/button-profile";
import { Icon } from "../../image";

export default function NavHeader({ isBlue }) {
  const { push } = useHistory();
  const { isLogin, loginLoading, userData } = useSelector(
    (state) => state.authReducer
  );
  const ulText = isBlue ? "text-netral-1" : "text-primary-900";
  const linkColor = isBlue
    ? "text-netral-1 border-netral-1 "
    : "border-primary-900";

  const shoppingCartIconColor = isBlue
    ? "shopping_cart-netral-1"
    : "shopping_cart-primary-500";

  const becomeInstructor = () => {
    userData.role.some((role) => role === "Instructor")
      ? push("/instructor")
      : push("/become-instructor");
  };

  const { cart } = useCart();

  const isInstructor = userData.role?.some((el) => el === "Instructor");

  return (
    <nav>
      <ul className={`${ulText} flex space-x-8 items-center font-semibold`}>
        <li>
          <Link
            to="/prakerja"
            className={`text-netral-9 bg-secondary-300 px-3 py-1 rounded text-base font-medium interactive-button-color`}
          >
            Prakerja
          </Link>
        </li>
        {/* <li>
          <Link to="/blog" className="interactive-text-color">
            Blog
          </Link>
        </li> */}
        <li>
          <Link to="/faq" className="interactive-text-color">
            Tanya Jawab
          </Link>
        </li>
        <li>
          <Link to="/course" className="interactive-text-color">
            Kategori
          </Link>
        </li>
        <li>
          <Link to="/course" className="interactive-text-color">
            Webinar
          </Link>
        </li>
        <li>
          <button
            onClick={becomeInstructor}
            className="text-primary2-300 interactive-text-color"
          >
            {isInstructor ? "Instructor" : "Mengajar di Skills.id"}
          </button>
        </li>
        {isLogin && (
          <li>
            <Link to="/cart" className="relative">
              <Icon name={shoppingCartIconColor} />
              {cart.length ? (
                <span className="absolute -top-1 left-4 w-4 h-4 bg-red-600 rounded-full text-2xs grid place-items-center text-white font-semibold">
                  {cart.length}
                </span>
              ) : (
                <></>
              )}
            </Link>
          </li>
        )}
        <li>
          {!isLogin ? (
            !loginLoading && (
              <Link
                to="/login"
                className={`${linkColor} border rounded px-4 py-2 interactive-button-color`}
              >
                Register/login
              </Link>
            )
          ) : (
            <ButtonProfile isBlue={isBlue} />
          )}
        </li>
      </ul>
    </nav>
  );
}
