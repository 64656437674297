import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import { Icon } from "../../image";

export default function SideNavLearnMenu({ enrollment, isSideNavOpened }) {
  const { participantProgress } = useSelector((state) => state.courseReducer);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { courseId } = useParams();
  const [navigations, setNavigations] = useState([]);

  const handleClick = (nav, targetIndex) => {
    if (!nav.active && nav.link) return;
    if (nav.link) push(nav.link);
    else {
      navigations[targetIndex].isShown = !navigations[targetIndex].isShown;
      setNavigations([...navigations]);
    }
  };

  useEffect(() => {
    const activeMaterial = (chapterIndex, materialIndex) => {
      if (
        chapterIndex === 0 &&
        materialIndex === 0 &&
        participantProgress?.pretest?.some((pretest) => pretest.isCompleted)
      )
        return true;

      if (!participantProgress?.chapters.length) return;

      const targetIndex = participantProgress?.chapters?.findIndex((el) => {
        return (
          el.chapterIndex === chapterIndex && el.materialIndex === materialIndex
        );
      });

      if (targetIndex === -1 || targetIndex === 0) return;

      if (
        participantProgress?.chapters[targetIndex - 1]?.type ===
          "Pilihan Ganda" &&
        participantProgress?.chapters[targetIndex - 1].score >= 80
      ) {
        return true;
      }

      // if (
      //   p
      //   return trarticipantProgress?.chapters[targetIndex - 1].type ===
      //     "Pilihan Ganda" &&
      //   participantProgress?.chapters[targetIndex].score >= 80
      // ) {ue;
      // }

      if (
        participantProgress?.chapters[targetIndex - 1]?.isCompleted &&
        participantProgress?.chapters[targetIndex - 1]?.type !== "Pilihan Ganda"
      )
        return true;

      return false;
    };

    const activeSkillTest = participantProgress?.posttest?.some(
      (posttest) => posttest.isCompleted
    );

    if (enrollment) {
      const enroll = enrollment.chapters.map((chapter, chapterIndex) => ({
        // ...chapter,
        title: `Bab ${chapterIndex + 1}: ${chapter.title}`,
        isShown: false,
        links: chapter.materials.map((material, materialIndex) => ({
          ...material,
          link: `/learn/${courseId}/chapter${chapterIndex + 1}-material${
            materialIndex + 1
          }`,
          active: activeMaterial(chapterIndex, materialIndex),
        })),
      }));

      setNavigations([
        {
          title: "Pretest",
          link: `/learn/${courseId}/pre-test`,
          active: true,
        },
        ...enroll,
        {
          title: "Post Test",
          link: `/learn/${courseId}/post-test`,
          active:
            participantProgress?.chapters[
              participantProgress.chapters.length - 1
            ]?.isCompleted,
        },
        {
          title: "Uji Keterampilan",
          link: `/learn/${courseId}/skill-test`,
          active: activeSkillTest,
        },
      ]);
    }
  }, [enrollment, courseId, participantProgress]);

  return (
    <aside
      className={`${
        isSideNavOpened
          ? "translate-x-0"
          : "translate-x-[-100vw] lg:translate-x-0"
      }  fixed top-0 left-0 lg:static lg:bg-white lg:block z-10 h-screen lg:h-full w-5/6 lg:w-max duration-500`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="p-6 lg:p-8 pr-0 w-full lg:w-[318px] h-full bg-[#F5F5F5] lg:bg-primary-100/30 overflow-y-auto overflow-x-hidden"
      >
        <Link to="/student" className="flex items-center space-x-2">
          <Icon
            name="arrow_back-secondary-2-800"
            className="w-5 h-5 lg:w-auto lg:h-auto"
          />
          <span className="text-sm lg:text-base font-semibold lg:font-DM-Sans text-primary-700 leading-[22.4px]">
            Kembali ke Dashboard
          </span>
        </Link>
        <nav className="mt-5 w-full ml-1">
          <ul>
            {navigations.map((nav, i) => (
              <li
                key={i}
                className="lg:py-3 border-b border-[#E0E0E0] space-y-2 lg:space-y-4"
              >
                <button
                  type="button"
                  onClick={() => handleClick(nav, i)}
                  className={`${
                    nav.link && pathname === nav.link && "bg-primary-200/40"
                  } p-3 lg:p-4 w-full text-netral-10 font-semibold rounded-l flex justify-between items-center text-left`}
                >
                  <p className="lg:w-[214px] text-sm lg:text-base">
                    {nav.title}
                  </p>
                  {!nav.link ? (
                    <Icon
                      name="arrow_back_ios-netral-10"
                      className={`w-[18px] h-[18px] lg:w-6 lg:h-6 duration-100 ${
                        nav.isShown && "rotate-180"
                      }`}
                    />
                  ) : (
                    <></>
                  )}
                </button>
                <div
                  className={`${
                    nav.isShown ? "py-px" : "h-0"
                  } flex flex-col space-y-1 px-4 overflow-hidden duration-100`}
                >
                  {nav.links?.map((link, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        if (!link?.active) return;
                        push(link?.link || "/");
                      }}
                      className={`${
                        link.link === pathname && "bg-primary-200/40"
                      } text-left py-1 pl-7 space-y-2 rounded ${
                        !link.active && "cursor-not-allowed"
                      }`}
                    >
                      <p
                        className={`${
                          link.active ? "text-netral-10" : "text-netral-5"
                        } text-sm `}
                      >
                        {link.name}
                      </p>
                      <div className="flex items-center space-x-2">
                        <Icon
                          name={
                            (link.type === "video/mp4" &&
                              "play_circle_outline-netral-10") ||
                            (link.type === "application/pdf" &&
                              "folder_open-netral-10") ||
                            "format_list_numbered-netral-10"
                          }
                          className="w-[20px] h-[20px]"
                        />
                        <p
                          className={`${
                            link.active ? "text-netral-10" : "text-netral-5"
                          } text-xs`}
                        >
                          {link.description}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
