import { Icon } from "../../independent/image";
import Modal from "../../independent/modal";

export const ModalPreview = ({ name, alt, setIsOpen, isOpen, fileName, type }) => {
  return (
    <Modal modal={isOpen} >
        <div className="w-[50%]">
              <div className="absolute text-white gap-x-3 flex top-10 left-20">
                <Icon
                  className={" cursor-pointer"}
                  onClick={setIsOpen}
                  name={"Arrow_Back_Fill"}
                />
                <Icon name={`${type === "image" ?  "Photo_Preview" : type === 'kuis' ? "Format_List_Numbered" : ""}`} /> 
                { type === "image" ? fileName : type === "kuis" ? "kuis" : ""}
              </div>
              <img src={name} alt={alt} />
            </div>
    </Modal>
  );
};
