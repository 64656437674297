import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FilePreview from "../../../components/dependent/learn-course/file-preview";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
// import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
// import TableClassic from "../../../components/independent/table/table-classic";
// import InputFilePreview2 from "../../../components/independent/input/input-file-preview2";
import {
  deleteVideoSkillTest,
  postSkilltestProgress,
} from "../../../config/redux/actions/courseAction";
import Modal from "../../../components/independent/modal";
import ModalCheckIdentity from "../../../components/independent/modal/modal-check-identity";
import { Icon, Illustration } from "../../../components/independent/image";
import { BigPlayButton, ControlBar, Player } from "video-react";
import getLink from "../../../constants/getLink";
import { useEffect } from "react";
import ModalRating from "../../../components/independent/modal/modal-rating";
import { _getRatingByUserClass } from "../../../config/services/api/rating";
const PDFJS = window.pdfjsLib;

const SkillTest = () => {
  const { webinarId, courseId } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);
  const { enrollment, participantProgress, classType, participantType } =
    useSelector((state) => state.courseReducer);
  const [files, setFiles] = useState([]);
  const { userData } = useSelector((state) => state.authReducer);
  const [pdf, setPdf] = useState("");
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState("");
  const [width, setWidth] = useState(0);
  const [images, setImages] = useState([]);
  const [height, setHeight] = useState(0);
  const [userRating, setUserRating] = useState({});

  async function showPdf(url) {
    try {
      setPdfRendering(true);
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      // document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    getLink(enrollment?.skillTest?.link) &&
      showPdf(getLink(enrollment?.skillTest?.link));
  }, [enrollment?.skillTest?.link]);

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      setWidth(viewport.width);
      setHeight(viewport.height);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  // const [videoFile, setVideoFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState("");

  const upload = () => {
    setLoading(true);
    dispatch(
      postSkilltestProgress(
        token,
        courseId,
        participantProgress?._id,
        files,
        () => {
          setLoading(false);
          setFiles([]);
        }
      )
    );
  };

  const disabledFinishClass = () => {
    if (userData?.role?.includes("Assesor")) return false;
    if (
      !Boolean(files.length) &&
      !Boolean(participantProgress?.skilltest.length)
    )
      return true;
    return false;
  };

  useEffect(() => {
    _getRatingByUserClass(token, webinarId || courseId)
      .then((res) => setUserRating(res.result))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Modal modal={modal}>
        {modal === "check-identity" && (
          <ModalCheckIdentity
            setModal={setModal}
            isRating={userRating.rating}
          />
        )}
        {modal === "rating" && <ModalRating setModal={setModal} />}
      </Modal>
      <LayoutLearnCourse>
        <div className="space-y-4">
          {/* {classType === "Online Class" ||
            !participantType?.includes(
              "WEBINAR" && (
                <>
                </>
              )
              )} */}
          <FilePreview
            type={enrollment?.skillTest?.type}
            storage={enrollment?.skillTest?.file?.storage}
            link={getLink(enrollment?.skillTest?.file?.link)}
            //  setCurrentTime={setCurrentTime}
            pdfRendering={pdfRendering}
            pageRendering={pageRendering}
            images={images}
          />
          {/* <PrevOrNextButton
            nextText="Selesaikan Kelas"
            prevActive={true}
            nextActive={true}
            prev={() => push(`/learn/${courseId}/post-test`)}
            next={() => setModal("check-identity")}
          /> */}

          <div>
            <h4 className="lg:text-xl font-semibold text-netral-10 pb-2 border-b lg:border-b-2 border-b-netral-6">
              Upload file Uji Keterampilan
            </h4>
            <div className="mt-2 bg-netral-2 rounded-lg p-2 lg:p-4">
              <ul className="ml-4 space-y-2">
                <li className="list-disc text-netral-10 text-xs lg:text-base">
                  Berkas yang di upload akan dinilai oleh facilitator.
                </li>
                <li className="list-disc text-netral-10 text-xs lg:text-base">
                  Berkas dapat di pecah menjadi beberapa bagian. Total akumulasi
                  ukuran file maksimal 150mb.
                </li>
                <li className="list-disc text-netral-10 text-xs lg:text-base">
                  Digunakan 0 MB, Sisa 150 MB
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-[15px]">
            <p className="font-semibold text-netral-10">Upload File</p>
            <div className="text-center w-full pt-[10px] pb-[19px] space-y-[5px] rounded-lg border border-dashed border-[#12A89D]">
              <Icon
                name="cloud-computing 1-primary-600"
                className="w-12 h-12 mx-auto"
              />
              <p className="text-sm text-netral-10 font-medium">
                Drag and Drop File Disini
              </p>
              <p className="text-xs text-[#818080] font-light">
                Mendukung semua jenis file dan video (MP4)
              </p>
              <p className="text-xs text-[#818080] font-semibold">Atau</p>
              <div className="relative">
                <input
                  multiple
                  // accept="video/*"
                  type="file"
                  onChange={(e) => setFiles([...files, ...e.target.files])}
                  className="absolute top-0 left-0 h-full w-full opacity-0"
                />
                <button className="font-bold text-[#12A89D] text-[13px] px-5 py-[7px] border-[#12A89D] border rounded-[5px]">
                  Browse files
                </button>
              </div>
            </div>
          </div>

          <div className="space-y-[10px]">
            {new Array(...files).map((file, i) => (
              <div
                key={i}
                className="border border-[#E9ECEC] rounded-lg py-[10px] px-[15px] flex items-center justify-between"
              >
                <div className="flex items-center space-x-[10px]">
                  <Icon name="document 1-primary-500" className="w-6  h-6" />
                  <div className="space-y-[3px]">
                    <p className="font-medium text-netral-10 text-[13px]">
                      {file.name}
                    </p>
                    <p className="text-xs text-[#818080]">{file.size}</p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    const arr = [...files];
                    arr.splice(i, 1);
                    setFiles(arr);
                  }}
                >
                  <Icon
                    name="delete_outline-secondary-3-500"
                    className="h-6 w-6"
                  />
                </button>
              </div>
            ))}
          </div>
          {new Array(...files).length ? (
            <div className="flex items-center justify-end space-x-[15px]">
              <button
                type="button"
                onClick={() => setFiles([])}
                disabled={isLoading}
                className="text-[13px] font-medium text-[#12A89D] px-[15px] py-[7px] rounded-[5px] border border-[#12A89D]"
              >
                Hapus semua
              </button>
              <button
                type="button"
                onClick={upload}
                disabled={isLoading}
                className={`${
                  !isLoading
                    ? "text-netral-1 border-[#12A89D] bg-[#12A89D]"
                    : "text-netral-6 border-transparent bg-netral-5 cursor-not-allowed"
                } text-[13px] font-medium px-[15px] py-[7px] rounded-[5px] border`}
              >
                {isLoading ? "Uploading..." : " Upload files"}
              </button>
            </div>
          ) : (
            <></>
          )}
          {/* <InputFilePreview2
            file={videoFile}
            setFile={setVideoFile}
            upload={upload}
            isLoading={isLoading}
          /> */}
          <TableClassic
            data={participantProgress?.skilltest.map((el) => ({
              ...el,
              score: participantProgress.skilltest_score,
            }))}
            ths={ths}
            tds={tds}
            deleteAction={(name) =>
              dispatch(
                deleteVideoSkillTest(
                  token,
                  name,
                  participantProgress._id,
                  courseId
                )
              )
            }
          />
        </div>
        <div className="mt-[80px] flex items-center justify-between py-[9px] px-[20px] bg-netral-2 rounded">
          <div className="flex items-center space-x-[27px]">
            <Illustration
              name={"winning_concept"}
              className="h-[97px] w-[117px] lg:block hidden"
            />
            <div>
              <p className="font-bold text-xl">Selesaikan Kelas</p>
              <p className="text-sm">
                Untuk dapat mencetak sertifikat pastikan kamu telah
              </p>
              <p className="text-sm">
                menyelesaikan kuis, post tes dan uji keterampilan
              </p>
            </div>
          </div>
          <button
            type="button"
            disabled={disabledFinishClass()}
            onClick={() => setModal("check-identity")}
            className={`
          ${
            Boolean(files.length) || Boolean(participantProgress?.skilltest)
              ? "bg-secondary2-800 rounded-full px-[16px] py-[8px] text-netral-1 cursor-pointer"
              : "bg-[#AFAED5] rounded-full px-[16px] py-[8px] text-netral-1"
          }
          `}
          >
            Cetak Sertifikat
          </button>
        </div>
      </LayoutLearnCourse>
    </>
  );
};

function TableClassic({ data, ths, tds, deleteAction }) {
  // const formatTitle = (title = "") => {
  //   if (title.length < 25) return title;
  //   return title.substring(0, 25) + " ...";
  // };

  return (
    <div className="overflow-x-auto">
      <table className="w-full rounded-lg overflow-hidden">
        <thead>
          <tr className="flex items-center space-x-2 px-2 py-3 bg-netral-2 text-left">
            <th>
              <p className="w-10 text-sm font-semibold text-netral-10">No</p>
            </th>
            {ths.map((th, i) => (
              <th key={i} className="w-max min-w-[110px] lg:min-w-[200px]">
                <p className="text-sm font-semibold text-netral-10">{th}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((el, i) => {
            el.uploadAt = new Date(el.uploadAt).toDateString();
            return (
              <tr
                key={i}
                className="flex items-center space-x-2 px-2 py-3 text-left"
              >
                <td>
                  <div className="w-10 text-sm text-netral-10">{i + 1}</div>
                </td>
                {tds.map((td, tdIndex) => (
                  <td
                    key={tdIndex}
                    className="overflow-hidden w-max min-w-[110px] lg:min-w-[200px]"
                  >
                    {td === "link" ? (
                      <div className="w-[100px] h-[80px] lg:w-[142px] lg:h-[101px] bg-[#0A0A0A]/60 rounded overflow-hidden">
                        <Player
                          fluid={false}
                          width="100%"
                          height="100%"
                          src={getLink(el[td])}
                        >
                          <ControlBar disableDefaultControls={true} />
                          <BigPlayButton
                            position="center"
                            className="scale-[.25] lg:scale-50"
                          />
                        </Player>
                      </div>
                    ) : td === "feedback" ? (
                      <div className="">
                        <p className="text-sm text-netral-10  w-[400px] lg:w-[402px]">
                          {el[td]}
                        </p>
                      </div>
                    ) : (
                      <div className="overflow-hidden truncate w-[110px] lg:w-[142px]">
                        <p className="truncate text-sm text-netral-10 ">
                          {el[td]}
                        </p>
                      </div>
                    )}
                  </td>
                ))}
                <td className="w-max min-w-[60px] lg:min-w-[142px]">
                  <button
                    type="button"
                    onClick={() => deleteAction(el.name)}
                    className="p-1 flex items-center space-x-2"
                  >
                    <Icon
                      name="delete_outline-secondary-3-500"
                      className="w-5 h-5"
                    />
                    <span className="text-secondary3-500 text-sm font-semibold">
                      Hapus
                    </span>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default SkillTest;

const ths = ["Nama File", "Ukuran", "Tanggal Upload", "score", "Feedback", ""];
const tds = ["name", "size", "uploadAt", "score", "feedback"];
