import { Icon } from "../../image";

export default function Stars({ n, size }) {
  let stars = [];
  for (let i = 1; i <= 5; i++) {
    n < i
      ? stars.push("Star-outline-secondary3-400")
      : stars.push("Star-secondary3-400");
  }

  return (
    <div className="flex items-center space-x-[2px]">
      {stars.map((star, i) => (
        <Icon key={i} name={star} className={`${size}`} />
      ))}
    </div>
  );
}

Stars.defaultProps = {
  size: "w-[18px] h-[18px]",
};
