import { ClipArt } from "../../independent/image";

export default function Facilities() {
  return (
    <div className="my-[112px] px-4 lg:px-[135px] flex justify-between space-x-10">
      {facilities.map((facility, i) => (
        <div
          key={i}
          className="w-1/3 lg:flex-none flex flex-col lg:flex-row items-center lg:items-start space-y-[14px] lg:space-y-0 space-x-3"
        >
          <div>
            <div className="rounded-xl w-[76px] h-[76px] flex items-center justify-center">
              <ClipArt
                name={facility.clipArt}
                className="h-full w-full object-contain"
              />
            </div>
          </div>
          <div className="lg:space-y-1">
            <h6 className="text-netral-10 font-semibold text-sm lg:text-lg text-center lg:text-left">
              {facility.title}
            </h6>
            <p className="hidden lg:block text-sm text-netral-7">
              {facility.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

const facilities = [
  {
    title: "Kreativitas Mengajar",
    description:
      "Gunakan kreativitasmu mengajar dengan menggunakan semua tool Skill.id",
    clipArt: "creativity",
  },
  {
    title: "Inspirasi peserta",
    description:
      "Berikan semua pengalamanmu untuk menginspirasi semua peserta didik",
    clipArt: "bookinhand",
  },
  {
    title: "Dapatkan imbalanmu",
    description: "Bagi hasil dengan Skills.id dengan transparan",
    clipArt: "salarymail",
  },
];
