export default function InputDropdownLabel({
  value,
  setValue,
  values,
  children,
}) {
  return (
    <div className="space-y-2 w-full">
      <p className="text-netral-8 text-sm font-semibold">{children}</p>
      <select
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="border border-[#757575] rounded p-2 w-full"
      >
        {values?.map((value, i) => (
          <option disabled={i === 0} key={i} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
