import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SET_ALERT } from "../../../../config/redux/types";
import {
  _getCourseByVoucher,
  _getCourseByVoucherNew,
} from "../../../../config/services/api/course";
import { ClipArt } from "../../../independent/image";

export default function ClaimVoucherBanner() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [input, setInput] = useState("");
  const [isLoading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (isLoading || !input) return;
    setLoading(true);
    if (input.includes("VID")) {
      console.log("object");
      _getCourseByVoucherNew(input)
        .then((res) => {
          if (!res.status)
            return dispatch({
              type: SET_ALERT,
              payload: { status: res.status, message: res.message },
            });

            window.location.replace(
              `https://live.skills.id/class/${res.data._id}`
            );
          // push(
          //   `/course/${res?.data?.title
          //     ?.split(" ")
          //     .join("-")
          //     .split("/")
          //     .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
          //     input
          //   )}`
          // );
        })
        .catch((e) => {
          dispatch({
            type: SET_ALERT,
            payload: { status: false, message: e },
          });
        })
        .finally(() => setLoading(false));
    } else {
      _getCourseByVoucher(input)
        .then((res) => {
          if (!res.status)
            return dispatch({
              type: SET_ALERT,
              payload: { status: res.status, message: res.message },
            });
          if (res.data.participantType.includes("WEBINAR")) {
            push(
              `/webinar/${res?.data?.classTitle
                ?.split(" ")
                .join("-")
                .split("/")
                .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
                input
              )}`
            );
          } else {
            push(
              `/course/${res?.data?.classTitle
                ?.split(" ")
                .join("-")
                .split("/")
                .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
                input
              )}`
            );
          }
          // push(
          //   `/course/${res?.data?.title
          //     ?.split(" ")
          //     .join("-")
          //     .split("/")
          //     .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
          //     input
          //   )}`
          // );
        })
        .catch((e) => {
          dispatch({
            type: SET_ALERT,
            payload: { status: false, message: e },
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <section className="my-[26px] lg:my-[59px] flex items-center max-w-[1010px] mx-auto">
      <form
        onSubmit={(e) => submit(e)}
        className="flex lg:flex-row flex-col items-center lg:items-center space-x-8 w-full"
      >
        <ClipArt
          name="Travel Tickets"
          className="w-[83px] h-[83px] lg:w-[176px] lg:h-[176px]"
        />
        <div className="space-y-2 lg:space-y-3 w-full">
          <div className="space-y-2 lg:space-y-4">
            <h3 className="text-sm lg:text-xl text-netral-10 font-semibold">
              Sudah punya kode voucher Prakerja Skills.id
            </h3>
            <p className="text-xs lg:text-base text-primary-900">
              Segera tukarkan kode voucher kamu disini
            </p>
          </div>
          <div className="flex lg:flex-row flex-col space-y-2 items-center lg:space-x-8 space-y-2 lg:space-y-0">
            <input
              type="text"
              onChange={(e) => setInput(e.target.value)}
              placeholder="Masukkan kode voucher Prakerja Anda"
              className="p-2 lg:px-3 lg:py-[15px] border border-primary-800 rounded-xl w-[300px] lg:w-full text-xs lg:text-lg"
            />
            <button
              disabled={isLoading || !input}
              type="submit"
              className={`${
                !isLoading
                  ? "text-white bg-primary2-500 interactive-button-color"
                  : "text-netral-6 bg-netral-5 cursor-not-allowed"
              } font-semibold px-3 py-2 lg:py-[18px] text-xs lg:text-base rounded-xl whitespace-nowrap border border-transparent duration-200`}
            >
              Tukarkan Sekarang
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
