// import { Link } from "react-router-dom";
// import useCart from "../../../../hooks/data-management/useCart";
// import { Icon } from "../../../independent/image";

export default function BottomNavMobile({ id, price, data }) {
  // const { addToCart } = useCart();
  return (
    <div className="lg:hidden bg-netral-2 fixed bottom-0 left-0 w-full px-4 py-6 flex items-center justify-between">
      <div className="space-y-3">
        <p className="text-netral-9">Total Harga</p>
        {data?.discount && data?.discount > 0 ? (
          <div className="flex items-center space-x-2">
            <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
              {data?.discount}%
            </p>
            <p className="text-secondary-button-600 line-through text-base font-semibold">
              {Number(price).toLocaleString("id-ID")}
            </p>
          </div>
        ) : null}
        <h4 className="text-netral-10 font-semibold text-xl">
          IDR
          {parseInt(
            data?.price - ((data?.price * data?.discount) / 100 || 0)
          ).toLocaleString("id-ID")}
        </h4>
      </div>
      {/* <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={() => addToCart(id)}
          className="px-3 py-2 rounded border border-secondary-button-600"
        >
          <Icon
            name="add_shopping_cart-secondary-button-600"
            className="w-[22px] h-[22px]"
          />
        </button>
        <Link
          to={`/checkout?items=${encodeURIComponent(JSON.stringify([id]))}`}
          className="inline-block text-center text-white font-semibold px-3 py-2 rounded bg-secondary-button-600"
        >
          Beli Sekarang
        </Link>
      </div> */}
    </div>
  );
}
