import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BigPlayButton, Player } from "video-react";
import ButtonBack from "../../../../components/independent/button/button-back";
import { ClipArt, Icon } from "../../../../components/independent/image";
import LayoutDashboard from "../../../../components/independent/layout/layout-dashboard";
import {
  _detailIndividualTest,
  _detailSkilltest,
  _evaluationIndividualTest,
  _evaluationSkilltest,
  _syncIndividualTest,
} from "../../../../config/services/api/instructor";
import getLink from "../../../../constants/getLink";
import Input from "../../../../components/atom/Input";
import Modal from "../../../../components/independent/modal";
import ButtonPrimary from "../../../../components/independent/button/button-primary";
import SuccessSyncModal from "../../../../components/independent/modal/modal-success-sync";
import LoadingSyncModal from "../../../../components/independent/modal/modal-loading-sync";

const IndividualTest = () => {
  const { goBack } = useHistory();
  const { courseId, studentId } = useParams();
  const { token } = useSelector((state) => state.authReducer);
  const [data, setData] = useState(defaultData);
  const [loadingSync, setLoadingSync] = useState(false);
  const [successSync, setSuccessSync] = useState(false);

  const getData = async () => {
    const res = await _detailIndividualTest(token, courseId, studentId);

    if (!res.status) return alert("something went wrong");

    setData({
      ...res.response,
      individualTest: res.response.individualTest.map((el) => ({
        ...el,
        notes: el.feedback || "",
        syncronized: el.feedback ? true : false,
      })),
    });
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, studentId, courseId]);

  const handleChange = (index, key, value) => {
    setData((prev) => ({
      ...prev,
      individualTest: prev.individualTest.map((el, idx) =>
        el.chapterIndex === index ? { ...el, [key]: value } : el
      ),
    }));
  };

  const handleSave = async (index) => {
    const selected = data.individualTest[index];
    const dataToSend = {
      individual_test: [
        {
          chapterIndex: +selected.chapterIndex,
          score: selected.score,
          notes: selected.notes,
        },
      ],
    };
    const res = await _evaluationIndividualTest(
      token,
      data.progrress_id,
      dataToSend
    );
    if (res.status) {
      getData();
    } else {
      alert(res.message);
    }
  };

  const handleSync = async (index) => {
    const selected = data.individualTest[index];
    const dataToSend = {
      chapterIndex: +selected.chapterIndex + 1,
      classId: courseId,
      progressId: data.progrress_id,
      type: "tpm",
    };
    setLoadingSync(true);
    const res = await _syncIndividualTest(token, dataToSend);
    setLoadingSync(false);
    if (res.status) {
      setSuccessSync(true);
      setData((prev) => ({
        ...prev,
        individualTest: prev.individualTest.map((el, idx) =>
          idx === index
            ? {
                ...el,
                syncronized: true,
              }
            : el
        ),
      }));
    } else {
      alert(res.message);
    }
  };

  return (
    <LayoutDashboard>
      <ButtonBack onClick={goBack} m={0}>
        Kembali
      </ButtonBack>
      <h3 className="text-2xl text-netral-10 font-normal mt-[14px] border-b pb-4 border-netral-5">
        Penilaian Tugas Praktek Mandiri
      </h3>
      <div className="mt-[29px] flex items-center justify-between">
        <div>
          <p className="text-base text-netral-8">Nama Murid</p>
          <p className="text-lg text-netral-10 ">{data.user}</p>
        </div>
      </div>
      <div className="mt-[24px] space-y-6">
        {data.individualTest.map((el, idx) => (
          <div key={idx}>
            <SessionsLayout
              individualData={el}
              handleChange={handleChange}
              handleSave={() => handleSave(el.chapterIndex)}
              handleSync={handleSync}
            />
          </div>
        ))}
      </div>
      <Modal modal={loadingSync}>
        <LoadingSyncModal />
      </Modal>
      <Modal modal={successSync}>
        <SuccessSyncModal handleClose={() => setSuccessSync(false)} />
      </Modal>
    </LayoutDashboard>
  );
};

export default IndividualTest;

const SessionsLayout = ({
  individualData,
  handleChange,
  handleSave,
  handleSync,
}) => {
  const borderGradient = "2px solid #9360A8";
  return (
    <div
      style={{ boxShadow: " 0px 5px 12px 0px rgba(128, 128, 128, 0.30)" }}
      className="rounded-xl p-3"
    >
      <div className="mb-3 flex justify-between">
        <p className="text-base font-semibold">
          TPM Sesi {+individualData.chapterIndex + 1}
        </p>
        {individualData.isCompleted && (
          <Icon
            name={
              !individualData.syncronized ? "cloud-sync" : "cloud-checkmark"
            }
            className={"w-[24px] h-[24px] cursor-pointer"}
            onClick={() => {
              if (!individualData.syncronized) {
                handleSync(+individualData.chapterIndex);
              }
            }}
          />
        )}
      </div>
      {individualData.isCompleted ? (
        <div className="grid grid-cols-1 lg:grid-cols-[40%,60%] ">
          <div
            style={{ boxShadow: "0px 2px 4px 3px rgba(143, 143, 143, 0.25)" }}
            className="rounded"
          >
            <div className="h-full py-4 px-2 flex flex-col justify-between gap-3 lg:gap-0">
              <div className="w-full space-y-3 flex flex-col items-center justify-center h-full">
                <div>
                  <Icon name="Docs-icon" className="w-[100%] h-[100%] m-0" />
                </div>
                <p className="text-netral-8 text-sm">{individualData?.name}</p>
              </div>
              <div className="w-full">
                <button
                  className="rounded-lg py-3 text-sm w-full leading-[20px] font-normal mt-auto"
                  style={{
                    border: borderGradient,
                    background:
                      "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                    backgroundClip: "text",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                  }}
                  onClick={() => window.open(getLink(individualData.link))}
                >
                  Lihat File
                </button>
              </div>
            </div>
          </div>
          <div className="space-y-3 p-3">
            <div className="space-y-2">
              <div className="flex gap-1">
                <p className="font-medium text-sm text-netral-8">
                  Feedback<span className="text-[#D1421A]">*</span>
                </p>
                {!individualData.syncronized && (
                  <Icon name={"danger"} className="w-[20px] h-[20px]" />
                )}
              </div>
              <textarea
                onChange={(e) => {
                  if (e.target.value.length < 251) {
                    handleChange(
                      individualData.chapterIndex,
                      "notes",
                      e.target.value
                    );
                  }
                }}
                value={individualData.notes || ""}
                className="w-full h-[134px] p-3 rounded-lg border border-[#E9ECEC] resize-none disabled:bg-[#EDEDED] disabled:border-[#9E9E9E]"
                placeholder="Feedback"
                disabled={!individualData.syncronized}
              ></textarea>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-1">
                <p className="text-netral-8 ">Skor</p>
                <input
                  onChange={(e) => {
                    if (+e.target.value && +e.target.value < 101) {
                      handleChange(
                        individualData.chapterIndex,
                        "score",
                        +e.target.value
                      );
                    } else if (e.target.value === "") {
                      handleChange(individualData.chapterIndex, "score", 0);
                    }
                  }}
                  value={individualData.score || ""}
                  className="w-[72px] h-[42px] rounded-lg border border-netral-5 disabled:bg-[#EDEDED] disabled:border-[#9E9E9E]"
                  placeholder="0-100"
                  disabled={!individualData.syncronized}
                />
              </div>
              <div>
                <div
                  className={`flex items-center px-4 py-2 rounded-lg text-[1rem] font-semibold cursor-pointer gap-[5px] text-[#fff]`}
                  style={{
                    background:
                      "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                  }}
                  onClick={() => handleSave()}
                >
                  Simpan
                </div>
                {/* <button onClick={}>button</button> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <Icon name={"no-file"} className="w-[180px] h-[180px]" />
          <p className="text-netral-5 font-normal text-sm">
            Belum ada tugas yang dikumpulkan peserta
          </p>
        </div>
      )}
    </div>
  );
};

const defaultData = {
  user: "",
  progrress_id: "",
  individualTest: [],
};
