import { useLocation } from "react-router-dom";

export default function useMultiQuery(query) {
  const { search } = useLocation();

  const searchArr = search.substring(1).split(query);

  let multiQuery = [];

  searchArr.forEach((querySearch) => {
    const decoded = decodeURIComponent(querySearch);
    const removeEqual = decoded.replace("=", "");
    const removeAnd = removeEqual.replace("&", "");
    if (querySearch) multiQuery.push(removeAnd);
  });

  return multiQuery;
}
