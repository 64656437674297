import React, { useState } from "react";
import { Icon } from "../../independent/image";
import { convertToMb } from "../../../constants/ConvertByteToMb";
import formatVideoDuration from "../../../constants/FormatVideoDuration";
export default function UploadFileFilled({
  selectedFileName,
  fileSize,
  label,
  type,
  duration,
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="flex flex-col gap-y-[8px]">
      <h1 className="text-[14px] text-[#616161] font-medium"> {label} </h1>

      <div className="h-[70px] flex gap-x-4 w-full border border-[#E0E0E0] p-2 pr-8 rounded-lg">
        <div className={"flex flex-col justify-center p-2 h-full"}>
          <Icon
            name={`${
              isVisible
                ? "Visibility"
                : type === "video"
                ? "Slow_Motion_Video"
                : "Image-Upload-File"
            }`}
            className={" cursor-pointer"}
            h={"28.8px"}
            w={"28.8px"}
            onMouseOver={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
          />
        </div>
        <div className="flex flex-col gap-[6px] truncate w-full font-normal">
          <h1 className="text-[16px] truncate"> {selectedFileName} </h1>
          <p className="text-[14px] text-[#757575] ">
            {" "}
            {type === "video"
              ? formatVideoDuration(duration)
              : convertToMb({ fileSize } + " mb")}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
