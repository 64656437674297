import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SET_NEW_WEBINAR } from "../../../../config/redux/types";
import { _getCourseInstructor } from "../../../../config/services/api/course";

const initialNewWebinarState = {
  individualTestInstruction: [],
  courseTitle: "",
  courseCategory: "",
  participantType: "",
  competenceUnit: [
    {
      title: "",
      competenceCode: "",
    },
  ],
  competenceTitle: "",
  targetParticipants: [
    {
      title: "Tujuan Umum",
      description: "",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
    {
      title: "Tujuan Khusus",
      description: "",
      min: 4,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Estimasi jadwal dan Anggaran Proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Identifikasi dan kelola risiko proyek",
          value: "",
        },
        {
          placeholder: "Contoh: Selesaikan studi kasus untuk mengelola proyek",
          value: "",
        },
        { placeholder: "tulis disini...", value: "" },
      ],
    },
    {
      title: "Aspek Kompetensi",
      description:
        "Cantumkan keterampilan, pengalaman, alat, atau peralatan yang sebaiknya dimiliki peserta sebelum mengikuti kursus Anda. Jika tidak ada persyaratan, gunakan ruang ini sebagai peluang untuk lebih memudahkan pemula.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
    {
      title: "Untuk siapa kursus ini?",
      description:
        "Tuliskan deskripsi yang jelas dari sasaran peserta untuk kursus Anda yang akan merasa konten kursus Anda bermanfaat. Ini akan membantu Anda menarik peserta yang tepat ke kursus Anda.",
      min: 1,
      inputs: [
        {
          placeholder: "Contoh: Peran dan tanggung jawab manajer proyek",
          value: "",
        },
      ],
    },
  ],
  schedule: [{ url: "", dateStart: ",", dateEnd: "," }],
  curriculum: [],
  groupOfQuestion: [],
  gallery: [],
  skillTest: {},
  homepage: {
    title: "",
    description: "",

    language: "",
    level: "",
    category: "",
    mainLesson: "",
    thumbnail: {
      // name: "label prakerja.png",
      // link: "http://122.50.7.190:5000/api/class/stream/label prakerja.png",
      // type: "image/png",
    },
    intro: {
      // duration: 86,
      // name: "videoplayback.mp4",
      // link: "http://122.50.7.190:5000/api/class/stream/videoplayback.mp4",
      // type: "video/mp4",
    },
    instructor: "",
  },
  pricing: { price: "", profit: 0.6 },
  discount: 0,
  finished: [
    // "targetParticipants",
    // "curriculum",
    // "groupOfQuestion",
    // "interactionLink",
    // "skillTest",
    // "setting",
    // "homepage",
    // "pricing",
    // "review",
  ],
  deleteFiles: [],
};

const NewWebinar = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const result = await _getCourseInstructor(id, token);
        if (!result.status) return;
        dispatch({ type: SET_NEW_WEBINAR, payload: result.class });
      } catch (error) {
        console.log("api error", error);
      }
    };
    getCourse();
    return () =>
      dispatch({ type: SET_NEW_WEBINAR, payload: initialNewWebinarState });
  }, [id, token, dispatch]);

  return children;
};

export default NewWebinar;
