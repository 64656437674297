import { Icon } from "../../../independent/image";

export default function Question({
  canItBeDeleted,
  question,
  questionIndex,
  setInputValue,
  setChoiceValue,
  deleteQuestion,
}) {
  return (
    <div className="space-y-3">
      <div className="space-y-2">
        <label className="text-netral-8 font-medium text-sm">Pertanyaan</label>
        <textarea
          className="h-[101px] rounded border border-netral-7 w-full p-3"
          value={question.name}
          onChange={(e) => setInputValue("name", e.target.value)}
        ></textarea>
      </div>
      <div className="space-y-2">
        <label className="text-netral-8 font-medium text-sm">Jawaban</label>
        <div className="space-y-3">
          <p className="text-sm text-secondary2-500">
            Checklist jawaban yang benar
          </p>
          {question.multipleChoice.map((choice, i) => (
            <div key={i} className="flex space-x-3">
              <div>
                <input
                  type="radio"
                  name={"choice" + questionIndex}
                  className="w-8 h-8"
                  onChange={(e) => setInputValue("answer", i + 1)}
                  checked={i + 1 === question.answer}
                />
              </div>
              <div className="space-y-3 w-full">
                <input
                  type="text"
                  className="rounded border border-netral-7 p-3 w-full"
                  placeholder="Tambahkan Jawaban"
                  onChange={(e) => setChoiceValue(i, e.target.value)}
                  value={choice}
                />
                {choice && i + 1 === question.answer && (
                  <input
                    type="text"
                    className="rounded border border-netral-7 p-3 w-full"
                    placeholder="Tambahkan Jawaban"
                    onChange={(e) => setInputValue("reason", e.target.value)}
                    value={question.reason}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {canItBeDeleted && (
        <div className="flex justify-end">
          <button
            type="button"
            onClick={deleteQuestion}
            className="flex items-center space-x-2 p-1"
          >
            <Icon name="delete_outline-secondary-3-500" />
            <span className="text-sm text-secondary3-500">
              Hapus Pertanyaan
            </span>
          </button>
        </div>
      )}
    </div>
  );
}
