import { useEffect, useState } from "react";
import Header from "../../header/header";
import NavHeaderLearn from "../../navigation/nav-header-learn";
import SideNavLearnMenu from "../../side-navigation/side-nav-learn-menu";
import Overview from "./Overview";
import Instructor from "./Instructor";
import Consultation from "./Consultation";
import Announcement from "./Announcement";
import Footer from "../../footer/footer";
import { useSelector } from "react-redux";
// import ConsultantInfo from "./ConsultantInfo";
import SideNavLearnMenuWebinar from "../../side-navigation/side-nav-learn-menu-webinar";
import SideNavLearnMenuWorkshop from "../../side-navigation/side-nav-learn-menu-workshop";
import { Icon } from "../../image";
import Review from "./Review";
import { useLocation } from "react-router-dom";

export default function LayoutLearnCourse({ children }) {
  const course = useSelector((state) => state.courseReducer);
  const [activeTab, setActiveTab] = useState("Overview");
  const [isSideNavOpened, setSideNavOpened] = useState(false);
  const { pathname } = useLocation();

  const TabView = () => {
    switch (activeTab) {
      case "Overview":
        return <Overview data={course.overview} />;
      case "Instruktur":
        return <Instructor data={course.instructor} />;
      case "Sesi Konsultasi":
        return <Consultation data={course.consultation} />;
      case "Pengumuman":
        return <Announcement />;
      case "Review":
        return <Review />;
      default:
        return <Overview />;
    }
  };

  if (pathname.split("/").pop() === "info") {
    return (
      <div className="h-screen flex flex-col">
        <Header setSideNavOpened={setSideNavOpened}>
          <NavHeaderLearn course={course} />
        </Header>
        <div
          className="h-full overflow-hidden"
          onClick={() => setSideNavOpened(false)}
        >
          <div className="lg:flex h-full">
            <div className="lg:h-full">
              {course?.participant_type?.includes("WEBINAR") ||
              course?.classType === "Offline Class" ? (
                <SideNavLearnMenuWebinar
                  enrollment={course.enrollment}
                  isSideNavOpened={isSideNavOpened}
                  type={
                    course?.participant_type?.includes("WEBINAR")
                      ? "Webinar"
                      : "Kursus"
                  }
                />
              ) : course?.classType === "Workshop" ? (
                <SideNavLearnMenuWorkshop
                  isSideNavOpened={isSideNavOpened}
                  type={"Workshop"}
                />
              ) : (
                <SideNavLearnMenu
                  enrollment={course.enrollment}
                  isSideNavOpened={isSideNavOpened}
                />
              )}
            </div>
            <main className=" w-full h-full  overflow-auto">
              <div className="lg:my-8 py-8 px-4 lg:px-6">{children}</div>
            </main>
          </div>
          <Footer className="mt-20" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-screen flex flex-col">
        <Header setSideNavOpened={setSideNavOpened}>
          <NavHeaderLearn course={course} />
        </Header>
        <div
          className="h-full overflow-hidden"
          onClick={() => setSideNavOpened(false)}
        >
          <div className="lg:flex h-full">
            <div className="lg:h-full">
              {course?.participant_type?.includes("WEBINAR") ||
              course?.classType === "Offline Class" ? (
                <SideNavLearnMenuWebinar
                  enrollment={course.enrollment}
                  isSideNavOpened={isSideNavOpened}
                  type={
                    course?.participant_type?.includes("WEBINAR")
                      ? "Webinar"
                      : "Kursus"
                  }
                />
              ) : course?.classType === "Workshop" ? (
                <SideNavLearnMenuWorkshop
                  isSideNavOpened={isSideNavOpened}
                  type={"Workshop"}
                />
              ) : (
                <SideNavLearnMenu
                  enrollment={course.enrollment}
                  isSideNavOpened={isSideNavOpened}
                />
              )}
            </div>
            <main className=" w-full h-full  overflow-auto">
              <div className="w-full px-4 lg:px-6 pt-2 flex bg-[#E2F6FF] space-x-2 lg:space-x-4 py-2 lg:py-4 items-center lg:sticky lg:top-0 z-[2]">
                <Icon name={"info"} className="w-[40px] h-[40px]" />
                <div>
                  <p className="text-netral-9 text-[18px] font-bold">
                    Informasi
                  </p>
                  <p className="text-netral-10 text-sm f">
                    PENTING! SELESAIKAN KUIS SEKARANG! Syarat nilai kuis minimal
                    80 untuk dapat mengikuti sesi berikutnya.
                  </p>
                </div>
              </div>
              <div className="lg:my-8 py-8 px-4 lg:px-6">
                {children}
                {/* <ConsultantInfo /> */}
                <div className="overflow-x-auto lg:py-10 py-5">
                  <div className="flex items-center border-b-2 space-x-3 lg:space-x-10 w-max">
                    {tabs.map((tab, i) => (
                      <button
                        key={i}
                        type="button"
                        onClick={() => setActiveTab(tab)}
                        className={`${
                          tab === activeTab
                            ? "text-netral-8 border-b-2 border-netral-8 font-semibold"
                            : "text-netral-6"
                        } relative lg:top-[2px] pb-2 lg:pb-3 text-sm lg:text-base`}
                      >
                        {tab}
                      </button>
                    ))}
                  </div>
                </div>
                <TabView />
              </div>
            </main>
          </div>
          <Footer className="mt-20" />
        </div>
      </div>
    );
  }
}

const tabs = [
  "Overview",
  "Instruktur",
  "Sesi Konsultasi",
  "Pengumuman",
  "Review",
];
