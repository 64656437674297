import { Icon } from "../../image";

export default function ModalAlert({ message, removeAlert }) {
  return (
    <div className="w-[436px] rounded-lg bg-white p-4 mx-auto">
      <div className="flex justify-end">
        <button type="button" onClick={removeAlert}>
          <Icon name="close-netral-10" className="w-6 h-6" />
        </button>
      </div>
      <Icon
        name="Cancel-primary2-500"
        className="w-[130px] h-[130px] mx-auto"
      />
      <p className="text-sm text-secondary3-500 mt-3 text-center">{message}</p>
    </div>
  );
}
