import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useParams,
  NavLink,
} from "react-router-dom";
import { Icon } from "../../image";
import LayoutNewCourse from "../layout-new-course";

export default function LayoutNewCourseStep4({ title, subTitle, children }) {
  const { finished } = useSelector((state) => state.newCourseReducer);
  const { id } = useParams();
  const defaultNavs = useNavs();
  const [navs, setNavs] = useState(defaultNavs);

  const toggleShowNav = (targetIndex) => {
    navs[targetIndex].show = !navs[targetIndex].show;
    setNavs([...navs]);
  };

  // if (all._id && !isStep5) {
  //   return <Redirect to={`/new/course/${id}/step4`} />;
  // } else {
  //   if (isStep5 && all.courseTitle === "") {
  //     return <Redirect to={`/instructor`} />;
  //   }
  // }

  return (
    <LayoutNewCourse activeTab={6}>
      <div className="p-8 pb-0 flex space-x-6">
        <div>
          <div className="w-[286px] space-y-8">
            {navs.map((nav, navIndex) => (
              <div key={navIndex} className="flex flex-col">
                <div
                  onClick={() => toggleShowNav(navIndex)}
                  className="p-4 space-x-4 flex items-center bg-primary-200/40 rounded cursor-pointer"
                >
                  <p className="font-semibold text-primary-900 w-full">
                    {nav.name}
                  </p>
                  <button>
                    <Icon
                      name="arrow_back_ios-netral-10"
                      className={`w-6 h-6 duration-200 ${
                        nav.show && "rotate-180"
                      }`}
                    />
                  </button>
                </div>
                {nav.links.map((link, linkIndex) => (
                  <div
                    key={linkIndex}
                    className={`${
                      nav.show ? "h-8 mt-4" : "h-0"
                    } overflow-hidden duration-200 flex items-center justify-between`}
                  >
                    <NavLink
                      key={linkIndex}
                      to={link.link}
                      exact={link.link === `/new/course/${id}/step6`}
                      className="text-netral-10 text-sm text-left pl-8 border-l-4 border-transparent"
                      activeClassName="border-[#000000]"
                    >
                      {link.name}
                    </NavLink>
                    {finished.some((el) => el === link.key) && (
                      <Icon
                        name={"check circle-primary-500"}
                        className="h-[15px] w-[15px] mr-5"
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div className="space-y-3 pb-3 border-b border-[#C2C2C2] mb-8">
            <h4 className="text-2xl text-netral-7 font-semibold">{title}</h4>
            <p className="text-netral-6">{subTitle}</p>
          </div>
          {children}
        </div>
      </div>
    </LayoutNewCourse>
  );
}

// const listOfKey = [
//   "targetParticipants",
//   "curriculum",
//   "groupOfQuestion",
//   "interactionLink",
//   "skillTest",
//   "setting",
//   "homepage",
//   "pricing",
//   "review",
// ];

const useNavs = () => {
  const { id } = useParams();
  const { classType } = useSelector((state) => state.newCourseReducer);
  if (classType === "Workshop") {
    return [
      {
        name: "Rencanakan kursus Anda",
        show: true,
        links: [
          {
            key: "targetParticipants",
            name: "Sasaran peserta",
            link: `/new/course/${id}/step6`,
          },
        ],
      },
      {
        name: "Buat konten Anda",
        show: true,
        links: [
          {
            key: "schedule",
            name: "Jadwal Kursus",
            link: `/new/course/${id}/step6/course-schedule`,
          },
          // {
          //   key: "interactionLink",
          //   name: "Link Interaksi",
          //   link: `/new/course/${id}/step6/interaction`,
          // },
          // {
          //   key: "setting",
          //   name: "Aturan Kelas",
          //   link: `/new/course/${id}/step6/setting`,
          // },
        ],
      },
      {
        name: "Terbitkan kursus Anda",
        show: true,
        links: [
          {
            key: "homepage",
            name: "Halaman Awal Kursus",
            link: `/new/course/${id}/step6/hompage`,
          },
          {
            key: "pricing",
            name: "Penetapan Harga",
            link: `/new/course/${id}/step6/pricing`,
          },
        ],
      },
      {
        name: "Tahap Akhir",
        show: true,
        links: [
          {
            key: "review",
            name: "Review",
            link: `/new/course/${id}/step6/review`,
          },
        ],
      },
    ];
  }
  if (classType === "Offline Class") {
    return [
      {
        name: "Rencanakan kursus Anda",
        show: true,
        links: [
          {
            key: "targetParticipants",
            name: "Sasaran peserta",
            link: `/new/course/${id}/step6`,
          },
        ],
      },
      {
        name: "Buat konten Anda",
        show: true,
        links: [
          {
            key: "curriculum",
            name: "Kurikulum",
            link: `/new/course/${id}/step6/curriculum`,
          },
          {
            key: "groupOfQuestion",
            name: "Bank Soal",
            link: `/new/course/${id}/step6/group-of-question`,
          },
          {
            key: "schedule",
            name: "Jadwal Kursus",
            link: `/new/course/${id}/step6/course-schedule`,
          },
          // {
          //   key: "interactionLink",
          //   name: "Link Interaksi",
          //   link: `/new/course/${id}/step6/interaction`,
          // },
          {
            key: "skillTest",
            name: "Uji Keterampilan",
            link: `/new/course/${id}/step6/skill-test`,
          },
          // {
          //   key: "setting",
          //   name: "Aturan Kelas",
          //   link: `/new/course/${id}/step6/setting`,
          // },
        ],
      },
      {
        name: "Terbitkan kursus Anda",
        show: true,
        links: [
          {
            key: "homepage",
            name: "Halaman Awal Kursus",
            link: `/new/course/${id}/step6/hompage`,
          },
          {
            key: "pricing",
            name: "Penetapan Harga",
            link: `/new/course/${id}/step6/pricing`,
          },
        ],
      },
      {
        name: "Tahap Akhir",
        show: true,
        links: [
          {
            key: "competenceUnit",
            name: "Unit Kompetensi",
            link: `/new/course/${id}/step6/competence-unit`,
          },
          {
            key: "review",
            name: "Review",
            link: `/new/course/${id}/step6/review`,
          },
        ],
      },
    ];
  } else {
    return [
      {
        name: "Rencanakan kursus Anda",
        show: true,
        links: [
          {
            key: "targetParticipants",
            name: "Sasaran peserta",
            link: `/new/course/${id}/step6`,
          },
        ],
      },
      {
        name: "Buat konten Anda",
        show: true,
        links: [
          {
            key: "curriculum",
            name: "Kurikulum",
            link: `/new/course/${id}/step6/curriculum`,
          },
          {
            key: "groupOfQuestion",
            name: "Bank Soal",
            link: `/new/course/${id}/step6/group-of-question`,
          },
          {
            key: "interactionLink",
            name: "Link Interaksi",
            link: `/new/course/${id}/step6/interaction`,
          },
          {
            key: "skillTest",
            name: "Uji Keterampilan",
            link: `/new/course/${id}/step6/skill-test`,
          },
          {
            key: "setting",
            name: "Aturan Kelas",
            link: `/new/course/${id}/step6/setting`,
          },
        ],
      },
      {
        name: "Terbitkan kursus Anda",
        show: true,
        links: [
          {
            key: "homepage",
            name: "Halaman Awal Kursus",
            link: `/new/course/${id}/step6/hompage`,
          },
          {
            key: "pricing",
            name: "Penetapan Harga",
            link: `/new/course/${id}/step6/pricing`,
          },
        ],
      },
      {
        name: "Tahap Akhir",
        show: true,
        links: [
          {
            key: "competenceUnit",
            name: "Unit Kompetensi",
            link: `/new/course/${id}/step6/competence-unit`,
          },
          {
            key: "review",
            name: "Review",
            link: `/new/course/${id}/step6/review`,
          },
        ],
      },
    ];
  }
};
