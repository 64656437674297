import { useState } from "react";
import { useHistory } from "react-router-dom";
import AlertTraditional from "../../components/independent/alert/alert-traditional";
import ButtonPrimary from "../../components/independent/button/button-primary";
import InputTextLabel from "../../components/independent/input/input-text-label";
import LayoutAuth from "../../components/independent/layout/layout-auth";
import Modal from "../../components/independent/modal";
import ModalOtp from "../../components/independent/modal/modal-otp";
import {
  _verifyOtpForgotPassword,
  _forgotPassword,
} from "../../config/services/api/auth";

const ResetPassword = () => {
  const { push } = useHistory();
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState("");
  const [modal, setModal] = useState("");

  const forgotPassword = async () => {
    setLoading(true);
    try {
      const res = await _forgotPassword(input);
      setAlert(res.message);
      if (res.status && !isNaN(input)) setModal("otp");
    } catch (error) {
      setAlert("internal server error");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = (otp) => {
    setLoading(true);
    _verifyOtpForgotPassword(otp, input)
      .then((res) => {
        setAlert(res.message);
        if (res.status)
          push(`/reset-password/create-new-password/${res.token}`);
      })
      .catch((e) => setAlert("internal server error"))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {modal === "otp" && (
        <Modal modal={modal}>
          <ModalOtp
            setModal={setModal}
            phoneNumber={input}
            verifyOtp={(otp) => verifyOtp(otp)}
          />
        </Modal>
      )}
      <LayoutAuth>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            forgotPassword();
          }}
          className="space-y-8"
        >
          {alert && (
            <AlertTraditional status="success" className="mb-4">
              {alert}
            </AlertTraditional>
          )}

          <div className="space-y-3">
            <h4 className="text-xl font-semibold text-netral-10">
              Reset Password
            </h4>
            <p className="text-netral-7">
              Silakan ketik email kamu dan kami akan mengirimkan link untuk
              me-reset password mu
            </p>
          </div>
          <div className="space-y-6">
            <InputTextLabel
              id="email"
              text="Email"
              placeholder="Silakan ketik email kamu"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            <ButtonPrimary disable={loading} type="submit">
              Reset password
            </ButtonPrimary>
          </div>
        </form>
      </LayoutAuth>
    </>
  );
};

export default ResetPassword;
