import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../footer/footer";
import Header from "../../header/header";
import NavHeaderDashboard from "../../navigation/nav-header-dashboard";
import SideNavDashboard from "../../side-navigation/side-nav-dashboard";
import NavHeaderDashboardV2 from "../../navigation/nav-header-dashboard-v2";
import HeaderV2 from "../../header/headerV2";

export default function LayoutDashboard({ children }) {
  const { pathname } = useLocation();
  const isIncome = pathname.split("/")[2] === "income";
  const [isSideNavOpened, setSideNavOpened] = useState(false);
  return (
    <div className="h-screen overflow-auto flex flex-col">
      <Header setSideNavOpened={setSideNavOpened}>
        <NavHeaderDashboard />
      </Header>

      {/* <HeaderV2 setSideNavOpened={setSideNavOpened}>
        <NavHeaderDashboardV2 />
      </HeaderV2> */}
      <div
      // className="h-full overflow-hidden"
      >
        {/* mb-6 lg:mb-8 */}
        <div className="lg:flex h-full">
          <SideNavDashboard
            isSideNavOpened={isSideNavOpened}
            setSideNavOpened={setSideNavOpened}
          />
          <main
            className={`${
              !isIncome && "px-4 lg:px-6 py-6 lg:py-8"
            } w-screen overflow-auto `}
          >
            {children}
          </main>
        </div>
        <Footer />
      </div>
    </div>
  );
}
