import { useHistory } from "react-router-dom";
// import { APIBaseURL } from "../../../../config/environment";
import getLink from "../../../../constants/getLink";
import { Logo } from "../../image";
// import { Icon } from "../../image";
// import DEFAULT_COURSES from "../../../../../public/assets/image/default-courses.png";

export default function CourseCategory2({ course }) {
  const { push } = useHistory();
  const isWebinar = ["WEBINAR UMUM", "WEBINAR PRAKERJA"].some(
    (el) => el === course.participantType
  );

  let isFinished;
  let to;

  if (!isWebinar) {
    if (course.classType === "Offline Class") {
      isFinished = course.finished?.length === 7;
    } else {
      isFinished = course.finished?.length === 9;
    }
    to = `/new/course/${course._id}/step6/`;
  } else {
    isFinished = course.finished?.length === 6;
    to = `/new/webinar/${course._id}/step5/`;
  }

  const src = getLink(course.homepage?.thumbnail?.link) || "";
  const borderGradient = "2px solid #9360A8";

  return (
    <div
      onClick={() => {
        !isFinished && course.lastVisited
          ? push(to + course.lastVisited || "")
          : push(to);
      }}
      className="cursor-pointer flex flex-col justify-between space-y-3"
    >
      <div className="space-y-2">
        <div className="w-full h-[220px] rounded-[12px] overflow-hidden">
          <img
            src={src}
            // src={getLink(src)}
            alt={src}
            className="h-full w-full object-fill"
          />
        </div>
        <h4 className="text-xl font-semibold">
          {course?.courseTitle || course?.webinarTitle}
        </h4>
      </div>
      <div>
        <div className="flex gap-[4px] flex-wrap">
          <span className="text-netral-10 rounded bg-secondary-300 text-2xs lg:text-sm py-1 px-2">
            {course.homepage?.level}
          </span>
          <span className="py-1 px-2  text-netral-10 rounded bg-secondary-300 text-2xs lg:text-sm font-semibold break-normal">
            {course?.classType === "Online Class"
              ? "Video"
              : isWebinar
              ? "Webinar"
              : course?.classType === "Workshop"
              ? "workshop"
              : "Kursus Offline"}
          </span>
        </div>
        <div className="flex items-center justify-between pt-[8px]">
          <h6 className="text-secondary2-600 font-semibold">
            {course.statusPublish}
          </h6>
        </div>
        <div className="mt-2 flex items-center justify-between">
          <p className="text-sm text-netral-8">
            {new Date(course.createdAt).toDateString()}
          </p>
          <div className="flex items-center space-x-[5px]">
            <Logo name="customer" w={18} h={18} />
            <p className="text-sm text-netral-10">
              {course?.total_student || 0}
            </p>
          </div>
        </div>
        {/* <button className="mt-5 rounded bg-[#006A9D] w-full py-3 text-xs text-white font-semibold interactive-button-color">
          Detail Kelas
        </button> */}

        <button
          // className="mt-5 rounded bg-[#006A9D] w-full py-3 text-xs text-white font-semibold interactive-button-color"
          className="rounded-lg py-3 text-[1.2rem] w-full mt-5 leading-[20px] font-extrabold"
          style={{
            border: borderGradient,
            background:
              "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
            backgroundClip: "text",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        >
          Detail Kelas
        </button>
      </div>

      {/* <div
        style={{ filter: "drop-shadow(2px 8px 16px rgba(0, 0, 0, 0.08))" }}
        className="relative h-[183px] rounded-xl"
      >
        <img src={src} alt={src} className="object-cover w-full h-full" />
      </div>
      <p className="mt-3 text-sm lg:text-lg font-semibold">
        {course?.courseTitle || course?.webinarTitle}
      </p>
      {isFinished ? (
        <span className="text-xs lg:text-base mt-2 text-netral-7 font-medium">
          {course?.total_student || 0} Peserta
        </span>
      ) : (
        <button type="button" className="flex items-center space-x-2">
          <span className="text-base text-secondary-button-600 font-semibold">
            Selesaikan Kelas
          </span>
          <Icon
            name="arrow_right_alt-secondary-button-600"
            className="w-6 h-6"
          />
        </button>
      )} */}
    </div>
  );
}
