import { useHistory } from "react-router-dom";
import getLink from "../../../../constants/getLink";
import ButtonPrimary from "../../button/button-primary";
import { Icon } from "../../image";

export default function CardCourseDashboard({ data }) {
  const { push } = useHistory();
  return (
    <div
      style={{ filter: "drop-shadow(2px 6px 16px rgba(0, 0, 0, 0.1))" }}
      className="w-full min-w-[190px] flex flex-col justify-between"
    >
      <div>
        <div className="w-full h-[220px] rounded-lg bg-slate-700 relative overflow-hidden">
          <img
            src={getLink(data?.thumbnail)}
            alt={data.thumbnail}
            className="w-full h-full object-cover"
          />
          <span className="absolute top-2 left-2 py-1 px-2 text-sm rounded-lg bg-secondary2-100">
            {data.level}
          </span>
          <span
            style={{ boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.3)" }}
            className="absolute bottom-2 left-2 py-1 px-2 text-sm rounded bg-secondary-300 font-semibold"
          >
           { data.participantType.includes("WEBINAR") ? "Webinar" : "Kursus"}
          </span>
        </div>
        <h4 className="text-xl font-semibold mt-2">{data.title}</h4>
        <div className="flex items-center justify-between  mt-2">
          <p className="text-netral-8 text-sm">
            {new Date(data.createdAt).toLocaleString("id-ID")}
          </p>
          <div className="flex items-center space-x-2">
            <Icon name="people-netral-10" className="w-5 h-5" />
            <p className="text-sm text-netral-10">{data.price}</p>
          </div>
        </div>
      </div>
      <ButtonPrimary
        type="button"
        onClick={() =>
          push(
            `/instructor/dashboard/${data._id}`
          )
        }
      >
        Lihat Detail
      </ButtonPrimary>
    </div>
  );
}

CardCourseDashboard.defaultProps = {
  _id: "",
  course_type: "",
  thumbnail: "",
  level: "",
  title: "",
  createdAt: "2022-07-05T07:31:02.646Z",
  price: "0",
};
