import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { _editRating, _rating } from "../../../../config/services/api/rating";
import { Icon } from "../../image";
import Stars from "../../stars/stars";

export default function ModalRating({
  setModal,
  isEdit = false,
  data,
  trigger,
  setTrigger,
}) {
  const { push } = useHistory();
  const { courseId } = useParams();
  const { userData, token } = useSelector((state) => state.authReducer);
  const [form, setForm] = useState({ review: "", rating: 0 });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmitRating = async () => {
    setLoading(true);
    try {
      const res = await _rating(
        token,
        userData._id,
        courseId,
        form.rating,
        form.review
      );
      if (!res.status) return setErrorMessage(res.message);

      push("/student/certificate");
    } catch (error) {
      console.log("error post verify certifcate name", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) setForm(data);
  }, [data]);

  const handleEditRating = async () => {
    setLoading(true);
    try {
      const res = await _editRating(token, form._id, form.rating, form.review);
      if (!res.status) return setErrorMessage(res.message);
      //   await loading();
      setTrigger(!trigger);
      setModal("");
    } catch (error) {
      console.log("error post verify certifcate name", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[438px] bg-white p-4 rounded-lg mx-auto">
      <div className="pb-2 border-b-2 border-b-netral-4 space-y-2">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">Rating</h5>
          <button type="button" onClick={() => setModal("")}>
            <Icon name="close-netral-10" className="w-6 h-6" />
          </button>
        </div>
        <p className="text-sm text-netral-9">
          Beri rating untuk kelas ini, anda bisa memberikan 1-5 bintang
        </p>
      </div>
      <div className="mt-6 space-y-4">
        <div className="flex space-x-2 justify-center">
          {/* <Stars n={3} className="w-3 h-3 lg:w-5 lg:h-5" /> */}
          {[1, 2, 3, 4, 5].map((el, idx) => (
            <div key={idx} onClick={() => setForm({ ...form, rating: el })}>
              <Icon
                name={
                  el <= form.rating
                    ? "Star-secondary3-400"
                    : "Star-outline-secondary3-400"
                }
                className="cursor-pointer h-[50px] w-[50px]"
              />
            </div>
          ))}
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-xs font-semibold text-netral-8">
            Ulasan
          </label>
          <textarea
            onChange={(e) => setForm({ ...form, review: e.target.value })}
            value={form.review}
            type="text"
            placeholder="Masukan deskripsi ulasan anda"
            className="py-[13px] px-2 border border-neutral-700 rounded text-sm text-netral-10 block w-full"
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button
          disabled={loading}
          onClick={isEdit ? handleEditRating : handleSubmitRating}
          type="button"
          className="border border-secondary-button-600 rounded px-3 py-2 font-semibold text-white bg-secondary-button-600"
        >
          Simpan
        </button>
      </div>
    </div>
  );
}
