import { Icon } from "../../components/independent/image";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";

const Performance = () => {
  return (
    <LayoutDashboard>
      <div className="space-y-8 mt-[calc(40px-32px)]">
        <div className="space-y-3">
          <h4 className="text-2xl font-semibold text-netral-7">Performa</h4>
          <p className="text-netral-6">
            Daftar kursus yang sudah pernah dibuat
          </p>
        </div>
        <div className="space-y-6">
          <div className="flex items-center border-b-2 space-x-[34.79px] w-max">
            <button
              type="button"
              className="relative top-[2px] font-semibold text-primary-600 pb-3 border-b-2 border-primary-600"
            >
              Ikhtisar
            </button>
            <button
              type="button"
              className="relative top-[2px] text-netral-6 pb-3"
            >
              Peserta
            </button>
            <button
              type="button"
              className="relative top-[2px] text-netral-6 pb-3"
            >
              Rating dan Ulasan
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <div className="bg-netral-2 border border-[#9E9E9E] rounded-lg flex items-center justify-between px-8 py-4">
            <Icon name="Training-primary-600" className="w-[82px] h-[82px]" />
            <div className="flex flex-col justify-between items-center">
              <p className="text-lg text-netral-8">Workshop</p>
              <h6 className="text-netral-10 text-2xl font-semibold">12</h6>
            </div>
          </div>
          <div className="bg-netral-2 border border-[#9E9E9E] rounded-lg flex items-center justify-between px-8 py-4">
            <Icon
              name="User Groups-primary-600"
              className="w-[82px] h-[82px]"
            />
            <div className="flex flex-col justify-between items-center">
              <p className="text-lg text-netral-8">Peserta</p>
              <h6 className="text-netral-10 text-2xl font-semibold">123456</h6>
            </div>
          </div>
          <div className="bg-netral-2 border border-[#9E9E9E] rounded-lg flex items-center justify-between px-8 py-4">
            <Icon name="Thumbs Up-primary-600" className="w-[82px] h-[82px]" />
            <div className="flex flex-col justify-between items-center">
              <p className="text-lg text-netral-8">Review</p>
              <h6 className="text-netral-10 text-2xl font-semibold">1233</h6>
            </div>
          </div>
        </div>
      </div>
    </LayoutDashboard>
  );
};

export default Performance;
