import { useState } from "react";

export default function useMultipleSelectInput() {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      addItem();
    }
  };

  const addItem = () => {
    if (inputValue.trim() !== "") {
      setSelectedItems([...selectedItems, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedItems = selectedItems.filter((item) => item !== itemToRemove);
    setSelectedItems(updatedItems);
  };
  return {handleInputChange,handleInputKeyDown,handleRemoveItem,selectedItems}
}
