import { Icon } from "../../../independent/image";

export default function PrevOrNextButton({
  prev,
  prevActive,
  next,
  nextActive,
  nextText = " Selanjutnya",
}) {
  return (
    <div className="mt-4 flex items-center justify-between">
      <button
        type="button"
        disabled={!prevActive}
        onClick={prev}
        className="flex items-center space-x-2"
      >
        <Icon
          name={
            !prevActive
              ? "arrow_back_ios-netral-5"
              : "arrow_forward_ios-primary-500"
          }
          className={`${
            prevActive && "rotate-180"
          } w-[18px] h-[18px] lg:w-6 lg:h-6`}
        />
        <span
          className={`${
            !prevActive ? "text-netral-5" : "text-primary-500"
          } text-sm lg:text-base font-semibold`}
        >
          Sebelumnya
        </span>
      </button>
      <button
        type="button"
        disabled={!nextActive}
        onClick={next}
        className="flex items-center space-x-2"
      >
        <span
          className={`${
            nextActive ? "text-primary-500" : "text-netral-5"
          } text-sm lg:text-base font-semibold`}
        >
          {nextText}
        </span>
        <Icon
          name={
            nextActive
              ? "arrow_forward_ios-primary-500"
              : "arrow_back_ios-netral-5"
          }
          className={`${
            !nextActive && "rotate-180"
          } w-[18px] h-[18px] lg:w-6 lg:h-6`}
        />
      </button>
    </div>
  );
}
