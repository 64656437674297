import React from "react";
import { Icon } from "../../independent/image";
import { useHistory, useParams } from "react-router-dom";

export default function NextButton({title,onClick}) {
  const { push } = useHistory();
  const { id } = useParams();
  return (
    <div
      className={`flex items-center px-4 py-2 rounded-lg text-[1rem]  font-semibold cursor-pointer gap-[5px] text-[#fff] `}
      style={{
        background: "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
      }}
      onClick={onClick}
    >
      {title}
      <Icon name="keyboard_arrow_right" h={20} w={20} className="mt-[3px]" />
    </div>
  );
}
