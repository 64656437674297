import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import { Icon } from "../../image";
import LayoutNewWebinar from "../layout-new-webinar";

export default function LayoutNewWebinarStep4({ title, subTitle, children }) {
  const { finished } = useSelector((state) => state.newWebinarReducer);
  // const { pathname } = useLocation();
  // const all = useSelector((state) => state.newWebinarReducer);
  const { id } = useParams();
  const defaultNavs = useNavs();
  // const isStep4 = pathname.includes("step5");

  const [navs, setNavs] = useState(defaultNavs);

  const toggleShowNav = (targetIndex) => {
    navs[targetIndex].show = !navs[targetIndex].show;
    setNavs([...navs]);
  };

  // if (!all._id) {
  //   if ((isStep4 && all.courseTitle === "")) {
  //     return <Redirect to={`/new/webinar/${id}`} />;
  //   }
  // }


  return (
    <LayoutNewWebinar activeTab={5}>
      <div className="p-8 pb-0 flex space-x-6">
        <div>
          <div className="w-[286px] space-y-8">
            {navs.map((nav, navIndex) => (
              <div key={navIndex} className="flex flex-col">
                <div
                  onClick={() => toggleShowNav(navIndex)}
                  className="p-4 space-x-4 flex items-center bg-primary-200/40 rounded cursor-pointer"
                >
                  <p className="font-semibold text-primary-900 w-full">
                    {nav.name}
                  </p>
                  <button>
                    <Icon
                      name="arrow_back_ios-netral-10"
                      className={`w-6 h-6 duration-200 ${
                        nav.show && "rotate-180"
                      }`}
                    />
                  </button>
                </div>
                {nav.links.map((link, linkIndex) => (
                  <div
                    key={linkIndex}
                    className={`${
                      nav.show ? "h-8 mt-4" : "h-0"
                    } overflow-hidden duration-200 flex items-center justify-between`}
                  >
                    <NavLink
                      key={linkIndex}
                      to={link.link}
                      exact={link.link === `/new/webinar/${id}/step5`}
                      className="text-netral-10 text-sm text-left pl-8 border-l-4 border-transparent"
                      activeClassName="border-[#000000]"
                    >
                      {link.name}
                    </NavLink>
                    {finished.some((el) => el === link.key) && (
                      <Icon
                        name="check circle-primary-500"
                        className="h-[15px] w-[15px] mr-5"
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div className="space-y-3 pb-3 border-b border-[#C2C2C2] mb-8">
            <h4 className="text-2xl text-netral-7 font-semibold">{title}</h4>
            <p className="text-netral-6">{subTitle}</p>
          </div>
          {children}
        </div>
      </div>
    </LayoutNewWebinar>
  );
}

const useNavs = () => {
  const { id } = useParams();
  return [
    {
      name: "Rencanakan webinar Anda",
      show: true,
      links: [
        {
          key: "targetParticipants",
          name: "Sasaran peserta",
          link: `/new/webinar/${id}/step5`,
        },
      ],
    },
    {
      name: "Buat konten Anda",
      show: true,
      links: [
        {
          key: "schedule",
          name: "Jadwal dan Link Webinar",
          link: `/new/webinar/${id}/step5/schedule`,
        },
        {
          key: "curriculum",
          name: "Kurikulum",
          link: `/new/webinar/${id}/step5/curriculum`,
        },
        {
          key: "groupOfQuestion",
          name: "Bank Soal",
          link: `/new/webinar/${id}/step5/group-of-question`,
        },
        {
          key: "skillTest",
          name: "Uji Keterampilan",
          link: `/new/webinar/${id}/step5/skill-test`,
        },
        {
          key: "individualTest",
          name: "Tugas Praktik Mandiri",
          link: `/new/webinar/${id}/step5/individual-test`,
        },
      ],
    },
    {
      name: "Terbitkan webinar Anda",
      show: true,
      links: [
        {
          key: "homepage",
          name: "Halaman Awal Webinar",
          link: `/new/webinar/${id}/step5/hompage`,
        },
        {
          key: "pricing",
          name: "Penetapan Harga",
          link: `/new/webinar/${id}/step5/pricing`,
        },
      ],
    },
    {
      name: "Tahap Akhir",
      show: true,
      links: [
        {
          key: "competenceUnit",
          name: "Unit Kompetensi",
          link: `/new/webinar/${id}/step5/competence-unit`,
        },
        {
          key: "review",
          name: "Review",
          link: `/new/webinar/${id}/step5/review`,
        },
      ],
    },
  ];
};
