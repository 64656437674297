import { ClipArt } from "../../../independent/image";

export default function HowToParticipate() {
  return (
    <section
      style={{ backgroundImage: "url(/assets/clip-art/curly-line2.png)" }}
      className="my-[43px] lg:my-[135px] bg-no-repeat bg-right relative"
    >
      <div className="px-4 lg:px-[135px]">
        <h3 className="lg:text-2xl font-semibold text-netral-10 text-center">
          Cara Mengikuti Kartu Prakerja
        </h3>
        <div className="mt-[25px] lg:mt-[56px] grid grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
          {cards.map((card) => (
            <div
              key={card.num}
              className="rounded-xl border border-secondary2-200 px-3 h-[182px] lg:h-[188px] relative"
              style={{
                backdropFilter: "blur(16px)",
                background:
                  "linear-gradient(290.19deg, rgba(211, 226, 255, 0.85) 0%, rgba(211, 226, 255, 0.24) 100%)",
              }}
            >
              <ClipArt
                name={card.clipArt}
                className="w-[28px] h-[28px] lg:w-[50px] lg:h-[50px] mx-auto mt-2 lg:mt-4"
              />
              <div className="mt-1 lg:mt-4 space-y-1 lg:space-y-2 text-center">
                <h6 className="font-semibold text-netral-10 text-sm lg:text-base">
                  {card.title}
                </h6>
                <p className="text-xs lg:text-sm text-netral-9">
                  {card.content}
                </p>
              </div>
              <span className="absolute top-0 left-3 -translate-y-1/2 w-8 h-8 flex items-center justify-center text-netral-1 bg-primary-500 rounded-full text-sm">
                {card.num}
              </span>
            </div>
          ))}
        </div>
      </div>
      <ClipArt
        name="triangle-outline-shadow"
        className="absolute top-0 left-4 w-[34.56px] h-[34px] lg:w-[90.32px] lg:h-[88.86px] scale-x-[-1]"
      />
      <ClipArt
        name="square-outline-shadow"
        className="absolute bottom-16 -right-2 lg:right-[136.68px] w-[39.99px] h-[40px] lg:w-[59.93px] lg:h-[59.95px]"
      />
    </section>
  );
}

const cards = [
  {
    num: 1,
    clipArt: "Group 2233",
    title: "Cara Mengikuti Kartu Prakerja",
    content:
      "Masuk ke situs Prakerja.go.id dan buat akun dengan data diri Anda. Tunggu verifikasi pendaftaran yang telah dikirimkan via email.",
  },
  {
    num: 2,
    clipArt: "Frame 2232",
    title: "Seleksi",
    content:
      "Ikuti tes motivasi & kemampuan dasar untuk bisa mengikuti seleksi gelombang dan tunggu pengumuman hasilnya.",
  },
  {
    num: 3,
    clipArt: "Online Courses",
    title: "Cara Mengikuti Kartu Prakerja",
    content:
      "Pilih pelatihan di website Skills.id dan pilih kelas berlabel Prakerja yang diinginkan, dan klik Beli Kelas.",
  },
  {
    num: 4,
    clipArt: "elearning",
    title: "Ikuti Pelatihan",
    content: "Kembali ke Skills.id lalu masukkan tukarkan kode voucher Anda.",
  },
  {
    num: 5,
    clipArt: "top rated",
    title: "Beri Ulasan dan Rating",
    content:
      "Berikan ulasan dan rating terhadap pelatihan Anda di dashboard Prakerja anda.",
  },
  {
    num: 6,
    clipArt: "wallet",
    title: "Insentif Biaya Mencari Kerja",
    content:
      "Dapatkan Insentif biaya mencari kerja sebanyak 1 (satu) kali sebesar Rp600.000 (enam ratus ribu Rupiah) setelah menyelesaikan pelatihan.",
  },
  {
    num: 7,
    clipArt: "Survey",
    title: "Insentif Pengisian Survei Evaluasi",
    content:
      "Insentif pengisian survei evaluasi sebesar Rp50.000 (lima puluh ribu Rupiah) yang diberikan paling banyak 2 (dua) kali.",
  },
];
