import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import LayoutNewCourse from "../../../../components/independent/layout/layout-new-course";
import { SET_COURSE_TITLE } from "../../../../config/redux/types";

const Step1 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const { courseTitle } = useSelector((state) => state.newCourseReducer);
  const all = useSelector((state) => state.newCourseReducer);
  const [title, setTitle] = useState(courseTitle);
  const dispatch = useDispatch();

  return (
    <LayoutNewCourse activeTab={1}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">Judul Course</h3>
          <p className="text-netral-6">Tetapkan judul kursus Anda</p>
        </div>
        <div className="space-y-2">
          <label
            htmlFor="title"
            className="inline-block text-sm text-netral-8 font-semibold"
          >
            Judul Kursus
          </label>
          <input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="p-3 rounded border border-[#757575] w-full placeholder:text-netral-5"
            placeholder="Masukkan Judul Kursus Anda"
          />
        </div>
        <PrevOrNextButton
          prev={() => push("/new")}
          next={() => {
            dispatch({ type: SET_COURSE_TITLE, payload: title });
            push(`/new/course/${id}/step2`);
          }}
          active={title?.length}
        />
      </div>
    </LayoutNewCourse>
  );
};

export default Step1;
