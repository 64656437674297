import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { SET_ALERT } from "../../../../config/redux/types";
import {} from "../../../../config/services/api/checkout";
import {
  _addToWishlist,
  _getWishlist,
  _removeFromWishlist,
} from "../../../../config/services/api/wishlist";
import useCart from "../../../../hooks/data-management/useCart";

import { Icon } from "../../../independent/image";
import RadioButton from "../../../independent/radio-button";

export default function OrderSummary({ course }) {
  const [selectedDate, setSelectedDate] = useState("");

  return (
    <div
      style={{ boxShadow: "0px 8px 50px rgba(0, 0, 0, 0.08)" }}
      className="w-full px-3 py-6 space-y-6 rounded-lg"
    >
      {/* 2.1 */}
      <Beli
        price={course?.price}
        course={course}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </div>
  );
}

const Beli = ({ price = 0, course, selectedDate, setSelectedDate }) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);
  const { courseId } = useParams();
  const { addToCart } = useCart();
  const [isWihslisted, setWihslisted] = useState(false);

  const getWishlist = useCallback(async () => {
    try {
      setLoading(true);

      const res = await _getWishlist(token);

      if (!res.status) throw new Error(res.message);

      const found = res.class.some((course) => course._id === courseId);

      if (!found) setWihslisted(false);
      else setWihslisted(true);
    } catch ({ message }) {
      // dispatch({
      //   type: SET_ALERT,
      //   payload: { status: false, message: JSON.stringify(message) },
      // });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, dispatch, courseId]);

  const toggleWishlist = async () => {
    try {
      setLoading(true);
      const res = !isWihslisted
        ? await _addToWishlist(token, courseId)
        : await _removeFromWishlist(token, courseId);

      if (!res.status) throw new Error(res.message);

      getWishlist();
    } catch ({ message }) {
      dispatch({
        type: SET_ALERT,
        payload: { status: false, message: JSON.stringify(message) },
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    getWishlist();
  }, [getWishlist]);

  return (
    <>
      {/* 2.2 */}

      <div className="space-y-4 mb-5">
        {course?.discount && course?.discount > 0 ? (
          <div className="flex items-center space-x-2">
            <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
              {course?.discount}%
            </p>
            <p className="text-secondary-button-600 line-through text-base font-semibold">
              Rp{parseInt(course?.price || "0").toLocaleString("id-ID")}
            </p>
          </div>
        ) : null}
        <h4 className="text-2xl text-netral-9 font-semibold pb-2 border-b-2 border-[#EDEDED]">
          {parseInt(
            course?.price - ((course?.price * course?.discount) / 100 || 0)
          ).toLocaleString("id-ID")}
        </h4>
        {course?.schedule && (
          <RadioButton
            text="Pilih Tanggal"
            setValue={setSelectedDate}
            value={selectedDate}
            values={[
              ...course?.schedule?.map(
                (schedule) =>
                  `${moment(schedule.dateStart).format(
                    "Do MMMM YYYY"
                  )} - ${moment(schedule.dateEnd).format("Do MMMM YYYY")}`
              ),
            ]}
          />
        )}
        <div className="space-y-2">
          <p className="text-sm font-semibold text-netral-8">waktu Kelas</p>
          <div className="flex items-center space-x-2">
            <Icon name="Time-primary-700" className="w-6 h-6" />
            <p className="text-netral-9">
              {!!course?.schedule?.length &&
                `${course?.schedule[0].start} - ${course?.schedule[0].end}`}
            </p>
          </div>
        </div>
      </div>
      {/* 2.3  */}
      <div className="space-y-2">
        <div className="flex items-center space-x-4">
          <button
            type="button"
            disabled={isLoading}
            onClick={() =>
              addToCart(
                courseId,
                course?.schedule[
                  course?.schedule
                    ?.map(
                      (schedule) =>
                        `${moment(schedule?.dateStart).format(
                          "Do MMMM YYYY"
                        )} - ${moment(schedule?.dateEnd).format(
                          "Do MMMM YYYY"
                        )}`
                    )
                    .findIndex((el) => el === selectedDate)
                ]
              )
            }
            className="text-secondary-button-600 font-semibold flex-grow py-2 rounded border border-[#006A9D] interactive-button-color"
          >
            Tambah ke Keranjang
          </button>
          <button
            type="button"
            onClick={toggleWishlist}
            className={`${
              isWihslisted
                ? "border-secondary-button-400 bg-secondary-button-400"
                : "interactive-button-color"
            } px-3 py-2 rounded border border-[#006A9D]`}
          >
            <Icon
              name="Bookmark-secondary-button-600"
              className="w-[17px] h-[22px]"
            />
          </button>
        </div>
        <Link
          onClick={() =>
            addToCart(
              courseId,
              course?.schedule[
                course?.schedule
                  ?.map(
                    (schedule) =>
                      `${moment(schedule?.dateStart).format(
                        "Do MMMM YYYY"
                      )} - ${moment(schedule?.dateEnd).format("Do MMMM YYYY")}`
                  )
                  .findIndex((el) => el === selectedDate)
              ]
            )
          }
          to={`/checkout?items=${encodeURIComponent(
            JSON.stringify([course?._id])
          )}&selectedDate=${course?.schedule
            ?.map(
              (schedule) =>
                `${moment(schedule?.dateStart).format(
                  "Do MMMM YYYY"
                )} - ${moment(schedule?.dateEnd).format("Do MMMM YYYY")}`
            )
            .findIndex((el) => el === selectedDate)}`}
          className={`${
            selectedDate === ""
              ? "text-netral-6 bg-netral-5 cursor-not-allowed"
              : " text-white bg-secondary-button-600"
          } inline-block text-center font-semibold py-2 w-full rounded border border-transparent interactive-button-color`}
        >
          Beli Sekarang
        </Link>
      </div>
      {/* 2.4  */}
      <button className="flex items-center space-x-[8.18px]">
        <Icon name="share-netral-9" className="w-6 h-6" />
        <p className="text-netral-9">Bagikan kelas</p>
      </button>
    </>
  );
};
