import {
  SET_CHAPTERS_MATERIAL_PROGRESS,
  SET_COURSE,
  SET_COURSE_PROGRESS,
  SET_POSTTEST_PROGRESS,
  SET_PRETEST_PROGRESS,
  SET_SKILLTEST_PROGRESS,
} from "../types";

const initialState = {
  title: "",
  progress: "",
  materialStatus: "",
  videoStatus: "",
  enrollment: {
    pretest: {},
    chapters: [],
    postTest: {},
    skillTest: {},
  },
  overview: {},
  instructor: {},
  consultant: [],
  announcement: "",
  disscussion: [],
  participantProgress: {
    pretest: [
      {
        isCompleted: false,
        record: [],
        score: 0,
        date: "",
        rightAnswer: 0,
        wrongAnswer: 0,
      },
    ],
    posttest: [],
    chapters: [],
    skilltest: [],
  },
};

export default function courseReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_COURSE:
      return { ...payload };
    case SET_COURSE_PROGRESS:
      return { ...state, participantProgress: payload };
    case SET_PRETEST_PROGRESS:
      return {
        ...state,
        participantProgress: { ...state.participantProgress, pretest: payload },
      };
    case SET_POSTTEST_PROGRESS:
      return {
        ...state,
        participantProgress: {
          ...state.participantProgress,
          posttest: payload,
        },
      };
    case SET_CHAPTERS_MATERIAL_PROGRESS:
      return {
        ...state,
        participantProgress: {
          ...state.participantProgress,
          chapters: payload,
        },
      };
    case SET_SKILLTEST_PROGRESS:
      return {
        ...state,
        participantProgress: {
          ...state.participantProgress,
          skilltest: payload,
        },
      };
    default:
      return state;
  }
}
