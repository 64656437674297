import { ClipArt } from "../../../independent/image";

export default function Facility({ isOffline, isPrakerja }) {
  return (
    <div className="mt-6 lg:mt-8 px-4 lg:px-[135px] flex justify-between">
      {isOffline !== "Offline Class" &&
        isOffline !== "Workshop" &&
        facilities.map((facility, i) => (
          <div
            key={i}
            className="w-1/3 lg:flex-none flex flex-col lg:flex-row items-center lg:items-start space-y-[14px] lg:space-y-0 space-x-3"
          >
            <div>
              <div className="border border-netral-5 rounded-xl w-12 h-12 flex items-center justify-center">
                <ClipArt
                  name={facility.clipArt}
                  className="w-[26.67px] h-[26.67px]"
                />
              </div>
            </div>
            <div className="lg:space-y-1">
              <h6 className="text-netral-10 font-semibold text-sm lg:text-base text-center lg:text-left">
                {facility.title}
              </h6>
              <p className="hidden lg:block text-sm text-netral-7">
                {facility.description}
              </p>
            </div>
          </div>
        ))}
      {(isOffline === "Offline Class" || isOffline === "Workshop") &&
        isPrakerja &&
        offlinePrakerjaClassFacilities.map((facility, i) => (
          <div
            key={i}
            className="w-1/3 lg:flex-none flex flex-col lg:flex-row items-center lg:items-start space-y-[14px] lg:space-y-0 space-x-3"
          >
            <div>
              <div className="border border-netral-5 rounded-xl w-12 h-12 flex items-center justify-center">
                <ClipArt
                  name={facility.clipArt}
                  className="w-[26.67px] h-[26.67px]"
                />
              </div>
            </div>
            <div className="lg:space-y-1">
              <h6 className="text-netral-10 font-semibold text-sm lg:text-base text-center lg:text-left">
                {facility.title}
              </h6>
              <p className="hidden lg:block text-sm text-netral-7">
                {facility.description}
              </p>
            </div>
          </div>
        ))}
      {(isOffline === "Offline Class" || isOffline === "Workshop") &&
        !isPrakerja &&
        offlineClassFacilities.map((facility, i) => (
          <div
            key={i}
            className="w-1/3 lg:flex-none flex flex-col lg:flex-row items-center lg:items-start space-y-[14px] lg:space-y-0 space-x-3"
          >
            <div>
              <div className="border border-netral-5 rounded-xl w-12 h-12 flex items-center justify-center">
                <ClipArt
                  name={facility.clipArt}
                  className="w-[26.67px] h-[26.67px]"
                />
              </div>
            </div>
            <div className="lg:space-y-1">
              <h6 className="text-netral-10 font-semibold text-sm lg:text-base text-center lg:text-left">
                {facility.title}
              </h6>
              <p className="hidden lg:block text-sm text-netral-7">
                {facility.description}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
}

const facilities = [
  {
    title: "Tenggat waktu fleksibel",
    description: "Atur ulang tenggat waktu sesuai dengan jadwal Anda",
    clipArt: "calendar-96 1",
  },
  {
    title: "Prakerja",
    description: "Kelas ini dapat diikuti peserta pemilik kartu Prakerja",
    clipArt: "Card Payment",
  },
  {
    title: "Total waktu",
    description:
      "Waktu yang dibutuhkan dalam menyelesaikan kelas sekitar 48 Jam",
    clipArt: "time-90 1",
  },
];

const offlinePrakerjaClassFacilities = [
  {
    title: "waktu fleksibel",
    description: "Pilih waktu dengan jadwal anda",
    clipArt: "calendar-96 1",
  },
  {
    title: "Prakerja",
    description: "Kelas ini dapat diikuti peserta pemilik kartu Prakerja",
    clipArt: "Card Payment",
  },
];

const offlineClassFacilities = [
  {
    title: "waktu fleksibel",
    description: "Pilih waktu dengan jadwal anda",
    clipArt: "calendar-96 1",
  },
];
