import React from 'react'

export default function InputFilled({label,inputFill}) {
  return (
    <div className="flex flex-col gap-y-[8px]">
                <p className="text-[#616161] text-[14px] font-medium"> {label} </p>
                <p className="text-[#404040] text-[16px] font-normal"> {inputFill} </p>
      </div>
  )
}
