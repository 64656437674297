import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import InputDropdownLabel2 from "../../../../components/independent/input/input-dropdown-label2";
import LayoutNewCourse from "../../../../components/independent/layout/layout-new-course";
import { SET_COURSE_CATEGORY } from "../../../../config/redux/types";

const Step2 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { courseCategory } = useSelector((state) => state.newCourseReducer);
  const [category, setCategory] = useState(courseCategory || categories[0]);

  return (
    <LayoutNewCourse activeTab={2}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">
            Kategori Course
          </h3>
          <p className="text-netral-6">Tetapkan kategori kursus Anda</p>
        </div>
        <InputDropdownLabel2
          text="categories"
          values={categories}
          value={category}
          setValue={setCategory}
        />
        <PrevOrNextButton
          active={category !== categories[0]}
          prev={() => push(`/new/course/${id}`)}
          next={() => {
            push(`/new/course/${id}/step3`);
            dispatch({ type: SET_COURSE_CATEGORY, payload: category });
          }}
        />
      </div>
    </LayoutNewCourse>
  );
};

export default Step2;

const categories = [
  "Pilih Kategori",
  "Sales",
  "IT/Sofware",
  "Jurnalistik",
  "Manajemen",
  "Bisnis",
  "Communication",
  "Marketing",
  "Design",
  "Language",
  "Seni/Kerajinan",
  "Lifestyle"
];
