import React from "react";

export default function RadioButton({value, handleChange, checked}) {
  return (
    <div className="relative w-full h-full">
      <input
        type="checkbox"
        id={`checkbox-${value}`}
        name="checkboxGroup"
        value={value}
        checked={checked}
        onChange={handleChange}
        className="hidden"
      />

      <label
        htmlFor={`checkbox-${value}`}
        style={{
          borderColor: checked ? "#9360A8" : "#E0E0E0",
        }}
        className="flex items-center justify-center  w-full h-full border rounded-full cursor-pointer transition-colors duration-300 ease-in-out p-[3px]"
      >
        <div
          className={` w-full h-full rounded-full ${
            checked ? "bg-[#9360A8]" : "bg-white"
          } transform scale-0 ${
            checked ? "scale-100" : ""
          } transition-transform duration-300 ease-in-out`}
        />
      </label>
    </div>
  );
}
