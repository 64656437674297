export default function Tabs({ tabs, activeTab, setActiveTab }) {
  return (
    <div className="flex items-center border-b-2 space-x-[49px] w-3/4">
      {tabs.map((tab, i) => (
        <button
          key={i}
          type="button"
          onClick={() => setActiveTab(tab)}
          className={`${
            activeTab === tab
              ? "font-semibold text-primary-600 border-b-2 border-primary-600"
              : "text-netral-6"
          } relative top-[2px] pb-3`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
}
