import { useHistory } from "react-router-dom";
import BottomNavMobile from "../components/dependent/cart/bottom-nav-mobile";
import OrderSummary from "../components/dependent/cart/order-summary";
import Title from "../components/dependent/cart/title";
import ButtonBack from "../components/independent/button/button-back";
import CardCourse5 from "../components/independent/card/card-course5";
import Footer from "../components/independent/footer/footer";
import Header from "../components/independent/header/header";
import { Icon } from "../components/independent/image";
import NavHeader from "../components/independent/navigation/nav-header";
import useCart from "../hooks/data-management/useCart";

// import OtherCourse from "../../../components/organism/OtherCourse";

const Cart = () => {
  const { push } = useHistory();
  const { cart, deleteItemFromCart, getTotalItems } = useCart();

  const navigateToCheckout = () => {
    if (!cart.length) return null;
    const itemsId = cart.map((item) => item._id);
    return encodeURIComponent(JSON.stringify(itemsId));
  };

  return (
    <>
      <Header className="fixed top-0">
        <NavHeader />
      </Header>
      <div className="pt-[82px]">
        <ButtonBack type="button" onClick={() => push("/")}>
          Kembali ke Home
        </ButtonBack>
        <section className="px-4 lg:px-[135px] mt-6 lg:mt-9">
          <div className="pb-3 lg:pb-4 border-b-2 border-[#E0E0E0] flex space-x-3 lg:space-x-6 items-center">
            <Icon name="Cart" className="w-14 h-14 lg:w-20 lg:h-20" />
            <Title />
          </div>
          <div className="lg:flex items-center lg:space-x-6 mt-6 lg:mt-8">
            <div className="w-full space-y-4">
              <h5 className="text-sm lg:text-lg text-netral-7">
                {cart.length} Kursus dalam keranjang
              </h5>
              {cart.map((course, i) => (
                <CardCourse5
                  key={i}
                  course={course}
                  deleteItemFromCart={deleteItemFromCart}
                />
              ))}
            </div>
            {cart.length > 0 && (
              <OrderSummary
                total={getTotalItems().toLocaleString("id-ID")}
                checkout={navigateToCheckout()}
              />
            )}
          </div>
        </section>
        {/* <section className="mt-[112px] mb-[calc(112px-2rem)]">
          <OtherCourse />
        </section> */}
        <div className="mt-[112px] hidden lg:block">
          <Footer />
        </div>
      </div>
      <BottomNavMobile
        price={getTotalItems()}
        navigateToCheckout={navigateToCheckout()}
      />
    </>
  );
};

export default Cart;
