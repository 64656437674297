import { useState } from "react";
import { Icon } from "../../image";

export default function InputTextLabel({ id, text, isPassword, ...rest }) {
  const [isPasswordShown, setPasswordShown] = useState(!isPassword);

  const togglePassword = () =>
    isPasswordShown ? setPasswordShown(false) : setPasswordShown(true);

  return (
    <div className="space-y-2 font-medium w-full">
      <label className="text-xs lg:text-sm text-netral-8" htmlFor={id}>
        {text}
      </label>
      <div className="relative flex">
        <input
          className="w-full text-sm lg:text-base border rounded-md p-2 lg:p-3 border-[#757575] shadow-sm"
          type={isPasswordShown ? "text" : "password"}
          id={id}
          {...rest}
        />
        {isPassword && (
          <div className="flex items-center absolute right-2 lg:right-3 h-full mr-auto">
            <button
              type="button"
              onClick={togglePassword}
              className="hover:bg-netral-3 active:bg-netral-4 p-1 rounded-full"
            >
              {isPasswordShown ? (
                <Icon name="eye-primary-500" h={20} w={20} />
              ) : (
                <Icon name="eye-off-primary-500" h={20} w={20} />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
