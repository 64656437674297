import { ClipArt } from "../../../independent/image";

export default function Facility() {
  return (
    <section className="mt-6 lg:mt-[152px]">
      <div className="flex mx-auto max-w-[1010px] items-center justify-center space-x-[33px] lg:space-x-[76px]">
        {cards.map((card, i) => (
          <div
            key={i}
            style={{
              filter: "drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.16))",
            }}
            className="lg:h-[278px] rounded-xl lg:bg-netral-2 lg:px-6 lg:pb-8 flex flex-col"
          >
            <div className="flex justify-center lg:block">
              <div
                style={{
                  filter: "drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.12))",
                }}
                className="rounded-2xl p-2 bg-primary-button-100 lg:relative bottom-10 inline-block"
              >
                <ClipArt
                  name={card.clipArt}
                  className="h-[42px] lg:h-[64px] w-[42px] lg:w-[64px]"
                />
              </div>
            </div>
            <div className="mt-[14px] lg:mt-0 flex-grow flex flex-col justify-between">
              <p className="text-sm lg:text-xl font-semibold text-center text-netral-10">
                {card.title}
              </p>
              <p className="hidden lg:block text-lg leading-7 text-netral-8">
                {card.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const cards = [
  {
    clipArt: "Certificate",
    title: "Sertifikat Digital",
    description: "Atur ulang tenggat waktu sesuai dengan jadwal Anda",
  },
  {
    clipArt: "Card Payment",
    title: "Peserta Prakerja",
    description: "Atur ulang tenggat waktu sesuai dengan jadwal Anda",
  },
  {
    clipArt: "Accept or Decline Yellow Classes Man Call",
    title: "Akses Kelas Selamanya",
    description: "Atur ulang tenggat waktu sesuai dengan jadwal Anda",
  },
];
