import { useMemo, useState } from "react";
import { Icon } from "../../../independent/image";

export default function Overview({ data }) {
  const [showAll, setShowAll] = useState(false);

  const filterText = useMemo(() => {
    if (showAll) {
      return data.description;
    } else {
      return data.description.split("\n\n")[0];
    }
  }, [data.description, showAll]);

  return (
    <>
      {/* 1.2  */}
      <div className="space-y-2 lg:space-y-3">
        <h5 className="text-netral-10 lg:text-xl font-semibold">Deskripsi</h5>
        <p className="text-netral-9 whitespace-pre-wrap text-xs lg:text-base">
          {filterText}
        </p>
        {showAll ? (
          <button
            className="py-1 lg:p-1 flex space-x-2 items-center"
            onClick={() => setShowAll(false)}
          >
            <span className="font-semibold text-primary-500 text-xs lg:text-base">
              Tampilkan lebih sedikit
            </span>
            <Icon
              name="arrow_forward_ios-primary-500"
              className="-rotate-90 w-4 h-4 lg:w-6 lg:h-6"
            />
          </button>
        ) : (
          <button
            className="py-1 lg:p-1 flex space-x-2 items-center"
            onClick={() => setShowAll(true)}
          >
            <span className="font-semibold text-primary-500 text-xs lg:text-base">
              Tampilkan lebih banyak
            </span>
            <Icon
              name="arrow_forward_ios-primary-500"
              className="rotate-90 w-4 h-4 lg:w-6 lg:h-6"
            />
          </button>
        )}
      </div>
      {/* 1.3  */}
      {data?.generalPurpose[0] !== "" && (
        <div className="space-y-2 lg:space-y-3">
          <h5 className="lg:text-xl font-semibold text-netral-10">
            Tujuan Umum:
          </h5>
          {data?.generalPurpose?.map((el, i) => (
            <p
              className="text-netral-9 whitespace-pre-wrap text-xs lg:text-base"
              key={i}
            >
              {el}
            </p>
          ))}
          <div className="grid grid-cols-2 gap-x-3 lg:gap-x-6 gap-y-1 lg:gap-y-3"></div>
        </div>
      )}
      {data?.specificPurpose[0] !== "" && (
        <div className="space-y-2 lg:space-y-3">
          <h5 className="lg:text-xl font-semibold text-netral-10">
            Tujuan Khusus:
          </h5>
          <div className="grid grid-cols-2 gap-x-3 lg:gap-x-6 gap-y-1 lg:gap-y-3">
            {data?.specificPurpose?.map((el, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon
                  name="check-primary-500"
                  className="w-4 h-4 lg:w-6 lg:h-6"
                />
                <p className="text-netral-9 text-xs lg:text-base">{el}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 1.4  */}
      {data?.competenceAspect[0] !== "" && (
        <div className="space-y-2 lg:space-y-3">
          <h5 className="lg:text-xl font-semibold text-netral-10">
            Aspek Kompetensi:
          </h5>
          <div className="grid grid-cols-2 gap-x-3 lg:gap-x-6 gap-y-1 lg:gap-y-3">
            {data.competenceAspect.map((el, i) => (
              <div key={i} className="flex items-center space-x-2">
                <Icon
                  name="check-primary-500"
                  className="w-4 h-4 lg:w-6 lg:h-6"
                />
                <p className="text-netral-9 text-xs lg:text-base">{el}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* 1.5  */}
      {data?.targetParticipants[0] !== "" && (
        <div className="space-y-2 lg:space-y-3 lg:pb-[109px]">
          <h5 className="lg:text-xl font-semibold text-netral-10">
            Target Peserta:
          </h5>
          <div className="w-max grid lg:grid-cols-2 gap-x-3 lg:gap-x-6 gap-y-1 lg:gap-y-2">
            {data.targetParticipants.map((el, i) => (
              <div key={i}>
                <span className="text-xs lg:text-base inline-block text-primary-500 px-3 py-1 rounded-[50px] border border-[#12A89D]">
                  {el}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

// const description =
//   "Halo Sobat Skills!\n\nKini bisnis donat dan berbagai variasinya (bentuk, topping, isian / filling) menjadi salah satu bisnis kuliner yang ditekuni oleh banyak usahawan kecil sampai menengah, mulai dari Ibu Rumah Tangga sampai ke pelaku usaha yang serius dan sukses. Donat merupakan cemilan ataupun menu santap pagi atau siang yang bisa dinikmati sepanjang masa dan selalu menjadi favorit di hampir setiap anggota keluarga, sehingga bisnis donat merupakan bisnis yang menjanjikan peluang bagus untuk dijalankan.";
