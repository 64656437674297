import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../config/redux/actions/authAction";
import {
  _updateProfile,
  _uploadPhotoProfileToTemp,
} from "../../../config/services/api/auth";
import getLink from "../../../constants/getLink";
import InputDateLabel from "../../independent/input/input-date-label";
import InputFilePreview from "../../independent/input/input-file-preview";
import InputNumberLabel from "../../independent/input/input-number-label";
import InputTextLabel from "../../independent/input/input-text-label";
import InputTextLabelIcon from "../../independent/input/input-text-label-icon";
import InputTextareaLabel from "../../independent/input/input-textarea-label";

export default function ProfileTab() {
  const dispatch = useDispatch();
  const { token, userData } = useSelector((state) => state.authReducer);
  const [isUploading, setUploading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    date_of_birth: "",
    headline: "",
    phone_number: "",
    address: "",
    instagram: "",
    facebook: "",
    twitter: "",
    profile_picture: { link: "", filename: "", type: "image" },
  });

  const changeForm = (e) => setForm({ ...form, [e.target.id]: e.target.value });

  const uploadPhotoProfile = async (file) => {
    try {
      setUploading(true);
      const result = await _uploadPhotoProfileToTemp(token, file);

      if (!result.status) return alert(result.message);

      const data = { profile_picture: result.data };

      const updated = await _updateProfile(token, data);

      if (!updated.status) return alert(updated.message);

      setForm({ ...form, profile_picture: result.data });

      alert("upload berhasil");
    } catch (error) {
      alert("something went wrong");
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    dispatch(updateProfile(token, form));
  };


  useEffect(() => {
    if (userData) {
      setForm({
        name: userData.name || "",
        date_of_birth: userData.date_of_birth || "",
        headline: userData.headline || "",
        phone_number: userData.phone_number || "",
        address: userData.address || "",
        bio: userData.bio || "",
        instagram: userData.socialMedia?.instagram || "",
        facebook: userData.socialMedia?.facebook || "",
        twitter: userData.socialMedia?.twitter || "",
        profile_picture: userData?.profile_picture || {
          link: "",
          filename: "",
          type: "image",
        },
        role: userData.role,
      });
    }
  }, [userData]);

  return (
    <form onSubmit={(e) => submit(e)} className="space-y-6 text-netral-8">
      <div className="w-1/2">
        <InputTextLabel
          id="name"
          text="Nama Sesuai KTP"
          placeholder="Masukkan Nama Sesuai KTP"
          value={form.name}
          onChange={(e) => changeForm(e)}
        />
      </div>
      <div className="flex items-center space-x-6">
        <InputDateLabel
          id="date_of_birth"
          text="Tanggal Lahir"
          value={form.date_of_birth}
          onChange={(e) => changeForm(e)}
        />
        <InputTextLabel
          id="headline"
          text="Pekerjaan"
          value={form.headline}
          onChange={(e) => changeForm(e)}
        />
        <InputNumberLabel
          id="phone_number"
          text="Nomor Handphone"
          placeholder="Masukkan Nomor Handphone"
          value={form.phone_number}
          onChange={(e) => changeForm(e)}
        />
      </div>
      <div className="w-[541px]">
        <InputTextareaLabel
          id="domicile"
          text="Alamat"
          placeholder="Masukkan alamat Anda"
          value={form.domicile}
          onChange={(e) => changeForm(e)}
        />
      </div>
      <div className="w-[541px]">
        <InputTextareaLabel
          id="bio"
          text="Biografi"
          placeholder="bio"
          value={form.bio}
          onChange={(e) => changeForm(e)}
        />
      </div>
      <div className="flex items-center space-x-6">
        <InputTextLabelIcon
          text="Instagram"
          id="instagram"
          icon="Instagram-black"
          placeholder="Masukkan Instagram Anda"
          value={form.instagram}
          onChange={(e) => changeForm(e)}
        />
        <InputTextLabelIcon
          text="Facebook"
          id="facebook"
          icon="Facebook-black"
          placeholder="Masukkan Facebook Anda"
          value={form.facebook}
          onChange={(e) => changeForm(e)}
        />
        <InputTextLabelIcon
          text="Twitter"
          id="twitter"
          icon="Twitter-black"
          placeholder="Masukkan Twitter Anda"
          value={form.twitter}
          onChange={(e) => changeForm(e)}
        />
      </div>
      <InputFilePreview
        type="image"
        loading={isUploading}
        value={form.profile_picture}
        setValue={(value) => uploadPhotoProfile(value)}
        src={getLink(form.profile_picture.link) || ""}
      />
      <div className="flex">
        <button
          type="submit"
          className="mt-2 rounded bg-secondary-button-600 px-3 py-2 text-white font-semibold interactive-button-color border border-transparent"
        >
          Simpan
        </button>
      </div>
    </form>
  );
}
