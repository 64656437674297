import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SET_CART } from "../../config/redux/types";
import { _addToCart, _deleteItemFromCart, _getCart } from "../../config/services/api/cart";

export default function useCart() {
  const { replace } = useHistory()
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.authReducer);
  const { cart } = useSelector((state) => state.cartReducer);
  const token = localStorage.getItem('token')
  // console.log(token);
  const getTotalItems = () =>
    cart.length
      ? cart
          .map((item) => item.price)
          .reduce((prev, crr) => parseInt(prev) + parseInt(crr))
      : 0;

  const getCart = useCallback(async () => {
    try {
      const res = await _getCart(token);
      // console.log(res)
      if (res.status) dispatch({ type: SET_CART, payload: res.class });
    } catch (error) {
      console.log("error", error);
    }
  }, [token, dispatch]);

  const addToCart = (id,data) => {
    if(!isLogin) return replace("/login")
    _addToCart(token, id,data)
      .then((res) => {
        if (res.status) getCart();
      })
      .catch((e) => console.log("error", e));
  };

  const deleteItemFromCart = async (id) => {
    try {
      const res = await _deleteItemFromCart(token, id);
      if (res.status) await getCart();
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    if(!isLogin) return
    getCart();
  }, [getCart, isLogin]);

  return { cart, addToCart, deleteItemFromCart, getTotalItems };
}
