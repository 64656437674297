import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CHANGE_SIDEBAR } from "../../../../config/redux/types";
import getLink from "../../../../constants/getLink";
import { Icon, Logo } from "../../image";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function MobileHeader({ isBlue }) {
  const bg = !isBlue ? "bg-netral-1" : "bg-primary-900 lg:bg-primary-900";
  const logo = !isBlue ? "Logo 2" : "Logo 1 solid";
  const menu = !isBlue ? "menu-netral-10" : "menu-netral-1";
  const [input, setInput] = useState("");
  const { push } = useHistory();

  // const { isLogin, userData } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  return (
    <div
      style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.12)" }}
      className={`${bg} h-[72px] flex justify-between items-center px-4 header lg:hidden`}
    >
      <div className="flex items-center space-x-2">
        {/* {!isLogin && ( */}
        <button
          type="button"
          onClick={() => dispatch({ type: CHANGE_SIDEBAR, payload: true })}
        >
          <Icon name={menu} className="w-8 h-8" />
        </button>
        {/* )} */}
        <Link to="/">
          <Logo name={logo} className="w-[107px] h-auto object-contain" />
        </Link>
      </div>
      <div className="flex items-center space-x-6">
        <form
          className="relative w-[127px]"
          onSubmit={(e) => {
            e.preventDefault();
            push(`/course?dasar=${input}`);
          }}
        >
          <input
            style={{ boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.12)" }}
            type="text"
            className="py-1 px-2 pr-8 rounded-[2px] bg-white placeholder:text-netral-5 text-xs w-full"
            placeholder="Temukan kursus"
          />
          <button type="button" className="h-full absolute top-0 right-2">
            <Icon name="search-netral-4" className="w-4 h-4" />
          </button>
        </form>
        {/* {isLogin && (
          <button
            type="button"
            onClick={() => dispatch({ type: CHANGE_SIDEBAR, payload: true })}
            className="w-10 h-10 rounded-full overflow-hidden"
          >
            <img
              src={getLink (userData.profile_picture?.link)}
              alt={userData.profile_picture?.name}
              className="h-full w-full"
            />
          </button>
        )} */}
      </div>
    </div>
  );
}
