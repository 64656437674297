import { Illustration } from "../../../independent/image";

export default function Finish({ score, next, title, buttonText }) {
  return (
    <div className="h-full w-full p-3 lg:pt-6 lg:pb-8 flex flex-col items-center">
      {title !== "Post Test" && score > 79 ? (
        <h4 className="lg:text-xl font-semibold">
          Selamat Anda telah menyelesaikan {title}
        </h4>
      ) : title === "Post Test" && score > 59 ? (
        <h4 className="lg:text-xl font-semibold">
          Selamat Anda telah menyelesaikan {title}
        </h4>
      ) : (
        <div className="flex flex-col items-center">
          <h4 className="lg:text-xl font-semibold text-center">
            Wah, kurang sedikit lagi kamu bisa melewati {title === "Post Test" ? "Post Test" : "kuis"} ini!
          </h4>
          <h4 className="lg:text-xl font-semibold text-center">
            Coba ulang lagi ya!
          </h4>
        </div>
      )}

      <div className="mt-2 lg:mt-3 flex items-center space-x-3 lg:space-x-4">
        <p className="text-netral-9 text-xs lg:text-base">Nilai Anda adalah</p>
        <h4 className="lg:text-xl font-semibold text-primary2-500">
          {score || 0}
        </h4>
      </div>
      {title !== "Post Test" && score > 79 ? (
        <Illustration
          name="community"
          className="w-[100px] h-[81px] lg:w-[294px] lg:h-[220px] mt-6 lg:mt-8"
        />
      ) : title === "Post Test" && score > 59 ? (
        <Illustration
          name="community"
          className="w-[100px] h-[81px] lg:w-[294px] lg:h-[220px] mt-6 lg:mt-8"
        />
      ) : (
        <Illustration
          name="quiz_error"
          className="w-[100px] h-[81px] lg:w-[294px] lg:h-[220px] mt-6 lg:mt-8"
        />
      )}
      <p className="text-xs lg:text-sm text-netral-9 lg:mt-3">
        {title !== "Post Test" && score > 79 ? `Silakan lanjut ke materi berikutnya` : ""}
      </p>
      <button
        type="button"
        onClick={next}
        className="mt-3 lg:mt-6 px-3 py-2 bg-secondary-button-600 rounded border border-transparent interactive-button-color font-semibold text-white text-xs lg:text-base"
      >
        {buttonText}
      </button>
    </div>
  );
}

Finish.defaultProps = {
  buttonText: "Lanjutkan Belajar",
};
