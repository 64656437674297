import { APIBaseURL } from "../../environment";

export const _getWebinars = () => {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/publish/webinar`)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((e) => reject(e));
  });
};

export const _getWebinar = (id) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseURL}/api/class/webinarId/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
