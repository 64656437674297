import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import {
  SET_COURSE_SETTING,
  SET_FINISHED_NAV,
} from "../../../../../config/redux/types";

const Setting = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.newCourseReducer.setting);

  const setSettings = (value) =>
    dispatch({ type: SET_COURSE_SETTING, payload: value });

  return (
    <LayoutNewCourseStep4
      title="Aturan Kelas"
      subTitle="Tentukan aturan kelas Anda. "
    >
      <p className="text-netral-8">
        JIka tipe peserta Anda adalah peserta umum, Anda dapat membuat aturan
        kelas minimal untuk peserta yang mengikuti kelas Anda{" "}
      </p>
      <div className="mt-[44px] px-[25px] py-[34px] pb-[247px] rounded bg-netral-2 space-y-3">
        {settings.map((setting, i) => (
          <div key={i} className="p-[2px] flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-8 h-8 accent-primary-600"
              checked={setting.value}
              onChange={(e) => {
                settings[i].value = e.target.checked;
                setSettings([...settings]);
              }}
            />
            <label className="text-netral-8">{setting.name}</label>
          </div>
        ))}
      </div>
      <ChildComponent settings={settings} />
    </LayoutNewCourseStep4>
  );
};

export default Setting;

const ChildComponent = ({ settings }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      active={true}
      page={"setting"}
      next={() => {
        dispatch({ type: SET_COURSE_SETTING, payload: settings });
        dispatch({ type: SET_FINISHED_NAV, payload: "setting" });
        push(`/new/course/${id}/step6/hompage`);
      }}
    />
  );
};
