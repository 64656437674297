import Header from "../../header/header";
import { Logo } from "../../image";
import NavHeader from "../../navigation/nav-header";

export default function LayoutAuth({ children }) {
  return (
    <div className="h-screen flex flex-col">
      <Header>
        <NavHeader />
      </Header>
      <div className="overflow-auto h-full">
        <div className="px-[59px] lg:px-0 lg:flex lg:before:w-1/2 lg:before:bg-login-02 lg:before:bg-no-repeat lg:before:bg-right-top lg:before:bg-contain">
          <div className="h-full lg:w-1/2 flex flex-col items-center mt-8">
            {/* <Logo
              name="Logo 2"
              className="w-[181px] h-[49px] mx-auto mt-6 lg:hidden"
            /> */}
            <main className="lg:pl-[91px] lg:pr-[135px] pl-2 pr-2 w-full mt-6 lg:min-h-[calc(100vh-(82px+24px))]">
              {children}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
