import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardCertificate from "../../../components/independent/card/card-certificate";
// import InputDropdownLabel from "../../../components/independent/input/input-dropdown-label";
import LayoutDashboard from "../../../components/independent/layout/layout-dashboard";
import {
  _getCertificate,
  _getCoursesStudent,
} from "../../../config/services/api/course";
import { _verifyCertificateName } from "../../../config/services/api/profile";
import { useHistory, useParams } from "react-router-dom";
import { Icon } from "../../../components/independent/image";
import Modal from "../../../components/independent/modal";
import { SET_ALERT } from "../../../config/redux/types";

export default function Certificate() {
  const { token } = useSelector((state) => state.authReducer);
  const [courses, setCourses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { push } = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getCourse = () => {
      _getCoursesStudent(token)
        .then((res) => {
          if (!res.status) return console.log(res);
          setCourses(res.data);
        })
        .catch((e) => console.log("error get student courses", e));
    };
    getCourse();
  }, [token]);
  const handleCheckCertificate = (course) => {
    if (!course.progress.percentage < 100) {
      _getCertificate(token, course.class._id)
        .then((res) => {
          if (res.status) {
            push(`/student/certificate/${course.class._id}`);
          }
          if (res.message === "Anda Belum Melakukan Verifkasi Nama") {
            setOpenModal(course.class._id);
          } else if (!res.status) {
            dispatch({
              type: SET_ALERT,
              payload: { status: false, message: res.message },
            });
          }
        })
        .catch((e) => console.log("error get certificate", e));
    } else {
      push(`/student/certificate/${course.class._id}`);
    }
  };

  return (
    <LayoutDashboard>
      <div className="space-y-8">
        <div className="space-y-3 text-primary-900">
          <h4 className="text-2xl font-semibold">Sertifikat</h4>
          <p>Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat</p>
        </div>
        {/* <div className="flex items-end justify-end space-x-6">
          <InputDropdownLabel>level</InputDropdownLabel>
        </div> */}
        <div className="grid grid-cols-1 lg:grid-cols-3  gap-8">
          {courses.map((course, i) => (
            <CardCertificate
              key={i}
              data={course.class}
              handleOpenCertificate={() => handleCheckCertificate(course)}
            />
          ))}
        </div>
      </div>
      <Modal modal={openModal}>
        <ModalCheckIdentity
          setModal={setOpenModal}
          courseId={openModal}
          handleCheckCertificate={() => handleCheckCertificate(openModal)}
        />
      </Modal>
    </LayoutDashboard>
  );
}

function ModalCheckIdentity({ setModal, courseId }) {
  const { push } = useHistory();
  const { userData, token } = useSelector((state) => state.authReducer);
  const [form, setForm] = useState({ name: userData.name });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verify = async () => {
    setLoading(true);
    try {
      const res = await _verifyCertificateName(
        token,
        courseId,
        form.name.toUpperCase()
      );
      if (res.status) {
        push(`/student/certificate/${courseId}`);
      }
      setErrorMessage(res.message);
      setModal(false);
    } catch (error) {
      console.log("error post verify certifcate name", error);
      setModal(false);
    } finally {
      setLoading(false);
      setModal(false);
    }
  };

  return (
    <div className="w-[438px] bg-white p-4 rounded-lg mx-auto">
      <div className="pb-2 border-b-2 border-b-netral-4 space-y-2">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-netral-10">
            Cek Indentitas Anda
          </h5>
          <button type="button" onClick={() => setModal("")}>
            <Icon name="close-netral-10" className="w-6 h-6" />
          </button>
        </div>
        <p className="text-sm text-netral-9">
          Silakan cek kembali identitas Anda untuk ditampilkan di sertifikat
          Anda
        </p>
      </div>
      <div className="mt-6 space-y-4">
        <div className="space-y-2">
          <label htmlFor="name" className="text-xs font-semibold text-netral-8">
            Nama Akun Skills.id
          </label>

          <input
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            value={form.name}
            type="text"
            className="py-[13px] px-2 border border-neutral-700 rounded text-sm text-netral-10 block w-full"
          />
        </div>
      </div>
      <div className="mt-8 flex justify-end">
        <button
          disabled={loading}
          onClick={verify}
          type="button"
          className="border border-secondary-button-600 rounded px-3 py-2 font-semibold text-white bg-secondary-button-600"
        >
          Simpan
        </button>
      </div>
    </div>
  );
}
