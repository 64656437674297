export default function getVideoDuration(file) {
  return new Promise((resolve, reject) => {
    if (file?.name && file?.type === "video/mp4") {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(parseInt(media.duration));
      };
      reader.readAsDataURL(file);
    } else reject(false);
  });
}
