import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import { SET_WEBINAR_FINISHED_NAV } from "../../../../../config/redux/types";
import {
  _createCourse,
  _deleteFileFromCloud,
  _updateCourse,
} from "../../../../../config/services/api/course";

const Review = () => {
  // const { datas } = useData();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const newWebinar = useSelector((state) => state.newWebinarReducer);
  const { token } = useSelector((state) => state.authReducer);
  const [uelas, setUelas] = useState([
    {
      text: "Saya sudah meninjau kembali kelas yang telah dibuat",
      value: false,
    },
    {
      text: "Saya sudah menyetujui segala persyaratan dalam pembuatan kelas",
      value: false,
    },
  ]);

  const deleteFilesFromCloud = async () => {
    if (!newWebinar.deleteFiles) return;
    if (!newWebinar.deleteFiles.length) return;

    const files = newWebinar.deleteFiles.map((el) =>
      _deleteFileFromCloud(token, el)
    );
    try {
      const results = await Promise.all(files);
      console.log("deleted", results);
    } catch (error) {
      console.log("error delete files", error);
    }
  };

  const save = () => {
    newWebinar.homepage.thumbnail.storage = "cloud";
    newWebinar.homepage.intro.storage = "cloud";
    newWebinar.gallery.forEach((image) => {
      image.storage = "cloud";
    });

    dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: "review" });

    const { instructor_id, deleteFiles, ...data } = newWebinar;

    if (!newWebinar._id) {
      _createCourse(data, token)
        .then((res) => {
          if (!res.status) return alert(res.message);
          deleteFilesFromCloud();
          push("/instructor");
        })
        .catch((e) => console.log("error", e));
    } else {
      _updateCourse(token, newWebinar._id, data).then((res) => {
        if (!res.status) return alert("something went wrong", res);
        deleteFilesFromCloud();
        push("/instructor");
      });
    }
  };

  const active = () => {
    if (uelas.some((uela) => !uela.value)) return false;
    if (newWebinar.finished.length < 6) return false;
    return true;
  };

  return (
    <LayoutNewWebinarStep4 title="Review" subTitle="Tetapkan judul kursus Anda">
      {/* <div className="space-y-6 pb-3 border-b border-netral-5">
        {datas.map((data1, i1) => (
          <div key={i1} className="space-y-4">
            <h4 className="text-xl text-netral-10">{data1.name}</h4>
            <div className="space-y-3">
              {data1.datas.map((data2, i2) => (
                <div key={i2} className="ml-16 space-y-2">
                  <p className="text-netral-10 font-semibold">{data2.name}</p>
                  <div className="space-y-1">
                    {data2.datas.map((data3, i3) => (
                      <div key={i3} className="ml-6">
                        <p className="text-sm text-netral-10">{data3.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div> */}
      <div className="space-y-6 pb-6 border-b border-b-netral-5">
        <div className="space-y-4">
          <h4 className="text-xl font-semibold text-netral-10">
            Sasaran Peserta
          </h4>
          {newWebinar.targetParticipants.map((el, i) => (
            <div key={i} className="ml-20 space-y-3">
              <h6 className="font-semibold text-netral-10">{el.title}</h6>
              <div className="ml-10 space-y-2">
                {el.inputs.map((el2, i2) => (
                  <p key={i2} className="text-sm text-netral-10">
                    {el2.value}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="space-y-4">
          <h4 className="text-xl font-semibold text-netral-10">
            Jadwal Webinar
          </h4>
          <div className="space-y-4">
            <div className="space-y-1">
              <p className="text-xs text-netral-8">Alamat Kursus</p>
              <a
                href={newWebinar.schedule[0].url}
                target="_blank"
                rel="noreferrer"
                className="text-[#345AF3] text-sm font-semibold hover:underline"
              >
                {newWebinar.schedule[0].url}
              </a>
            </div>
            <div className="space-y-1">
              <p className="text-xs text-netral-8">Tanggal Webinar</p>
              <p className="text-sm font-semibold text-netral-10">{`${moment(
                newWebinar.schedule[0]?.dateStart?.split(",")[0]
              ).format("LL")}-${moment(
                newWebinar.schedule[
                  newWebinar.schedule.length - 1
                ].dateEnd?.split(",")[0]
              ).format("LL")}`}</p>
            </div>
            <div className="flex items-center space-x-[131px]">
              <div className="space-y-1">
                <p className="text-xs text-netral-8">Jam Mulai</p>
                {newWebinar.schedule.map((date, i) => (
                  <p key={i} className="text-sm font-semibold text-netral-10">
                    {date.dateStart.split(",")[1]}
                  </p>
                ))}
              </div>
              <div className="space-y-1">
                <p className="text-xs text-netral-8">Jam Berakhir</p>
                {newWebinar.schedule.map((date, i) => (
                  <p key={i} className="text-sm font-semibold text-netral-10">
                    {date.dateEnd.split(",")[1]}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <h4 className="text-xl font-semibold text-netral-10">
            Penetapan Harga
          </h4>
          <h6 className="ml-20 font-semibold text-netral-10">
            Yang Anda dapatkan dari webinar ini adalah Rp{" "}
            {(Number(newWebinar.pricing.price) || 0).toLocaleString("id-ID")}
          </h6>
        </div>
      </div>
      <div className="mt-8 space-y-6">
        {uelas.map((uela, i) => (
          <div key={i} className="p-[2px] space-x-[10px] flex items-center">
            <input
              id={"uela" + i}
              type="checkbox"
              className="w-8 h-8"
              value={uela.value}
              onChange={(e) => {
                uelas[i].value = e.target.checked;
                setUelas([...uelas]);
              }}
            />
            <label htmlFor={"uela" + i} className="text-netral-10">
              {uela.text}
            </label>
          </div>
        ))}
      </div>
      <div className="my-[112px] flex items-center space-x-6">
        <button
          type="button"
          onClick={save}
          disabled={!active()}
          className={`${
            active()
              ? "bg-secondary-button-600 interactive-button-color"
              : "bg-netral-5 cursor-not-allowed"
          } text-white px-3 py-2 rounded border border-transparent`}
        >
          Terbitkan Kelas
        </button>
      </div>
    </LayoutNewWebinarStep4>
  );
};

export default Review;

// const useData = () => {
//   const newWebinar = useSelector((state) => state.newWebinarReducer);
//   const targetParticipants = newWebinar.targetParticipants.map((target) => ({
//     name: target.title,
//     datas: target.inputs.map((input) => ({
//       name: input.value,
//     })),
//   }));

//   const schedule = newWebinar.schedule.map(() => ({
//     name: `${newWebinar.schedule.length} Link Interaksi`,
//     datas: [],
//   }));

//   const pricing = [
//     {
//       name: `Yang Anda dapatkan dari kursus ini adalah IDR ${(
//         newWebinar.pricing.price * newWebinar.pricing.profit
//       ).toLocaleString("id-ID")}`,
//       datas: [],
//     },
//   ];

//   const datas = [
//     { name: "Sasaran Peserta", datas: targetParticipants },

//     { name: "Jadwal dan Link Webinar", datas: schedule },

//     { name: "Penetapan Harga", datas: pricing },
//   ];
//   return { datas };
// };
