import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/independent/header/header";
import NavHeader from "../../../components/independent/navigation/nav-header";
import Footer from "../../../components/independent/footer/footer";
import Hero from "../../../components/dependent/course-detail/hero";
import Facility from "../../../components/dependent/course-detail/facility";
import Overview from "../../../components/dependent/course-detail/overview";
import Reviews from "../../../components/dependent/course-detail/reviews";
import PrakerjaOrderSummary from "../../../components/dependent/webinar-detail/prakerja-order-summary";
import Instructor from "../../../components/dependent/course-detail/instructor";
import Tabs from "../../../components/independent/tabs/tabs";
import BottomNavMobile from "../../../components/dependent/course-detail/bottom-nav-mobile";
import ClaimVoucherMobile from "../../../components/dependent/course-detail/claim-voucher-mobile";
import Gallery from "../../../components/dependent/webinar-detail/gallery";
import { _getWebinar } from "../../../config/services/api/webinar";
import WebinarOrderSummary from "../../../components/dependent/webinar-detail/order-summary";
import useExitPrompt from "../../../hooks/lib/usePreventReload";

// import OtherCourse from "../../../components/organism/OtherCourse";

const WebinarDetail = () => {
  const { webinarId } = useParams();
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  useEffect(() => {
    setShowExitPrompt(true);
  }, []);

  const tabs = ["Overview", "Instruktur", "Gallery", "Ulasan"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [webinar, setWebinar] = useState({
    instructor: {},
    schedule: [],
  });

  useEffect(() => {
    _getWebinar(webinarId)
      .then((res) => setWebinar(res.class))
      .catch((e) => console.log("error getCourse", e));
  }, [webinarId]);

  return (
    <>
      <Header className="fixed top-0" isBlue>
        <NavHeader isBlue />
      </Header>
      <main className="pb-[200px] lg:pb-0 pt-[68px] lg:pt-[82px]">
        <Hero course={webinar} isWebinar={true} />
        <Facility isOffline={true} />
        <section className="mt-4 lg:mt-8 px-4 lg:px-[135px] space-y-6 lg:space-y-0 lg:flex lg:space-x-6">
          <div className="lg:w-2/3">
            <div className="bg-netral-2/50 rounded-lg p-4 space-y-3 lg:space-y-6">
              <Tabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
              {activeTab === "Overview" && webinar.overview && (
                <Overview data={webinar.overview} />
              )}
              {activeTab === "Instruktur" && (
                <Instructor data={webinar.instructor} />
              )}
              {activeTab === "Gallery" && <Gallery gallery={webinar.galerry} />}
              {activeTab === "Ulasan" && <Reviews data={webinar.rating} />}
            </div>
          </div>
          {webinar.type === "WEBINAR PRAKERJA" ? (
            <aside className="hidden lg:block lg:w-1/3">
              <PrakerjaOrderSummary webinar={webinar} />
            </aside>
          ) : (
            <aside className="hidden lg:block lg:w-1/3">
              <WebinarOrderSummary webinar={webinar} />
            </aside>
          )}
          <ClaimVoucherMobile data={webinar} />
        </section>
        {/* <section className="mt-20">
          <OtherCourse />
        </section> */}
      </main>
      <Footer className="hidden lg:block mt-20" />
      <BottomNavMobile
        id={webinarId}
        data={{
          price: webinar?.pricing?.price,
          discount: webinar?.pricing?.discount || 0,
        }}
        price={webinar?.pricing?.price}
      />
    </>
  );
};

export default WebinarDetail;
