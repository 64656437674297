import { Logo } from "../../../independent/image";

export default function Tools() {
  return (
    <div className="flex flex-wrap gap-6 pb-[calc(225px-16px)]">
      <div className="w-[108px] h-[124px] lg:h-[172px] pt-2 lg:pt-6 px-3 bg-white rounded space-y-4 lg:space-y-6">
        <Logo name="Figma" className="w-[60px] h-[60px] mx-auto" />
        <p className="text-netral-10 font-semibold text-center text-xs lg:text-base">Figma</p>
      </div>
      <div className="w-[108px] h-[124px] lg:h-[172px] pt-2 lg:pt-6 px-3 bg-white rounded space-y-4 lg:space-y-6">
        <Logo name="Microsoft PowerPoint" className="w-[60px] h-[60px] mx-auto" />
        <p className="text-netral-10 font-semibold text-center text-xs lg:text-base">MS Powerpoint</p>
      </div>
    </div>
  );
}
