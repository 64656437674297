import { useHistory } from "react-router-dom";
import ButtonBack from "../components/independent/button/button-back";
import { Illustration } from "../components/independent/image";
import LayoutHome from "../components/independent/layout/layout-home";

const PrivacyAndPolicy = () => {
  const { push } = useHistory();

  return (
    <LayoutHome>
      <div className="pt-[72px] lg:pt-[82px] pb-[112px] px-4 lg:px-8">
        <ButtonBack type="button" onClick={() => push("/")} m="mt-8">
          Kembali
        </ButtonBack>
        <div className="mt-8 px-6 py-4 flex space-x-2 lg:space-x-6 items-center bg-primary-900 rounded">
          <div>
            <Illustration
              name="Question"
              className="lg:w-[101px] h-[106px] lg:h-[142px] object-contain"
            />
          </div>
          <div className="w-full space-y-2 lg:space-y-3">
            <h4 className="text-lg lg:text-2xl font-semibold text-netral-1">
              Privacy and Policy
            </h4>
            <p className="text-netral-1 text-xs lg:text-base">
              Untuk memudahkan para Sobat Skills.ID, disini kami sudah merangkum
              beberapa pertanyaan yang sering ditanyakan. Kami berharap FAQ ini
              bisa membantu Sobat Skills dalam menjawab pertanyaan nya.
            </p>
          </div>
        </div>
        <div className="mt-8 lg:mt-14 space-y-6 w-full">
          {data.map((el, dataIndex) => (
            <div className="space-y-3" key={dataIndex}>
              <div
                style={{ boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.08)" }}
                className="w-full flex items-center justify-between p-2 lg:px-6 lg:py-4 bg-netral-2 rounded"
              >
                <h4 className="lg:text-xl text-netral-10 font-semibold text-left">
                  {el.title}
                </h4>
              </div>
              <p className="px-2 lg:px-6 whitespace-pre-line text-netral-10 text-xs lg:text-base">
                {el.body}
              </p>
            </div>
          ))}
        </div>
      </div>
    </LayoutHome>
  );
};

export default PrivacyAndPolicy;

const data = [
  {
    title: "Privacy Policy for SKILLS ID",
    body: `At SKILLS ID, accessible from https://skills.id, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by SKILLS ID and how we use it.\n\nIf you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.`,
  },
  {
    title: "Log Files",
    body: `SKILLS ID follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.`,
  },
  {
    title: "Privacy Policies",
    body: `You may consult this list to find the Privacy Policy for each of the advertising partners of SKILLS ID. Our Privacy Policy was created with the help of the Free Privacy Policy Generator and the Privacy Policy Generator Online.\n\nThird-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on SKILLS ID, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.\n\nNote that SKILLS ID has no access to or control over these cookies that are used by third-party advertisers.`,
  },
  {
    title: "Third Party Privacy Policies",
    body: `SKILLS ID's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.\n\nYou can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?`,
  },
  {
    title: "Children's Information",
    body: `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.\n\nSKILLS ID does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`,
  },
  {
    title: "Online Privacy Policy Only",
    body: `This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in SKILLS ID. This policy is not applicable to any information collected offline or via channels other than this website.`,
  },
  {
    title: "Consent",
    body: `By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.`,
  },
];
