import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import useQuery from "../hooks/lib/useQuery";
import { _checkCoursesHasVideo } from "../config/services/api/course";

function AuthenticationRoute({ component: Component, ...restOfProps }) {
  const { pathname } = useLocation();


  const { replace } = useHistory();

  const { isLogin, userData,token } = useSelector((state) => state.authReducer);

  const query = useQuery();
  const visit = query.get("continue");

  const checkHasVideo = async () => {
    try {
      const res = await _checkCoursesHasVideo(token);
      if (res.status) {
        window.location.replace("https://live.skills.id/student");
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (userData.role.length !== 0) {
      if (userData.role[0] === "Student") {
      }
    }
    checkHasVideo()
  }, []);

  useEffect(() => {
    const route = () => {
      if (userData.role.length === 1) {
        if (userData.role[0] === "Student") return "/";
        else if (userData.role[0] === "Instructor") return "/instructor";
      } else return "/";
    };

    if (!isLogin) return;
    if (visit) replace(visit);
    else replace(route());
  }, [visit, isLogin, replace, userData.role, pathname]);

  return (
    <Route {...restOfProps} render={(props) => <Component {...props} />} />
  );
}

export default AuthenticationRoute;
