import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/independent/image";
import InputDropdownLabel2 from "../../../components/independent/input/input-dropdown-label2";
import InputTextLabel from "../../../components/independent/input/input-text-label";
import InputTextareaLabel from "../../../components/independent/input/input-textarea-label";
import LayoutDashboard from "../../../components/independent/layout/layout-dashboard";
import getLink from "../../../constants/getLink";

const AddAnnouncement = () => {
  const [courseTitle, setCourseTitle] = useState("");
  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState("");

  const publish = () => {
    console.log(courseTitle.announcementTitle, content, file, image);
  };

  return (
    <LayoutDashboard>
      <div className="flex items-center space-x-2">
        <Link
          to="/instructor/communication"
          className="inline-block text-[#9E9E9E] font-semibold"
        >
          Komunikasi
        </Link>
        <p className="text-[#9E9E9E] font-semibold">/</p>
        <p className="font-semibold text-netral-10">Tambah Pengumuman</p>
      </div>
      <div className="mt-8 space-y-6">
        <InputDropdownLabel2
          text="Judul Kursus"
          values={["", "UI UX Designer", "IT & Pengembangan", "Bisnis"]}
          value={courseTitle}
          setValue={(value) => setCourseTitle(value)}
        />
        <InputTextLabel
          text="Judul Pengumuman"
          value={announcementTitle}
          onChange={(e) => setAnnouncementTitle(e.target.value)}
        />
        <InputTextareaLabel
          text="Content"
          value={content}
          height="149px"
          onChange={(e) => setContent(e.target.value)}
        />
        <div className="relative overflow-hidden inline-block">
          <input
            accept="image/*"
            type="file"
            className="cursor-pointer absolute top-0 left-0 w-full h-full opacity-0"
            onChange={(e) => {
              const file = e.target.files[0];
              setFile(file);
              setImage(URL.createObjectURL(file));
            }}
          />
          {!image ? (
            <button className="flex flex-col items-center justify-center space-y-6 w-[183px] h-[179px] rounded-lg border-2 border-dashed border-netral-6 bg-netral-2">
              <Icon name="add-netral-9" className="h-6 w-6" />
              <p className="text-netral-9 text-sm w-[151px] text-center">
                Tambahkan Gambar/ Video
              </p>
            </button>
          ) : (
            <img
              className="w-[183px] h-[179px] rounded-lg object-cover"
              src={getLink(image)}
              alt={image}
            />
          )}
        </div>
        <div className="flex items-center space-x-3">
          <button
            type="button"
            onClick={publish}
            className="rounded bg-secondary-button-600 py-2 px-3 text-white font-semibold border border-transparent"
          >
            Publish
          </button>
          <button className="rounded bg-white py-2 px-3 text-secondary-button-600 font-semibold border border-secondary-button-600">
            Simpan di Draft
          </button>
        </div>
      </div>
    </LayoutDashboard>
  );
};

export default AddAnnouncement;
