import BackButton from "../../../../components/Molecule/BackButton";
import CheckBoxFilled from "../../../../components/Molecule/ChekboxFilled";
import DropdownFilled from "../../../../components/Molecule/DropdownFilled";
import LineSeperator from "../../../../components/Molecule/LineSeperator";
import NextButton from "../../../../components/Molecule/NextButton";
import InputFilled from "../../../../components/Molecule/InputFilled";
import SaveToDraft from "../../../../components/Molecule/SaveDraft";
import UploadFile from "../../../../components/Molecule/UploadFile";
import Syllabus from "../../../../components/dependent/course-detail/syllabus";
import { Icon } from "../../../../components/independent/image";
import LayoutNewVideo from "../../../../components/independent/layout/layout-new-video";
import UploadFileFilled from "../../../../components/Molecule/UploadFileFilled";
import Accordion from "../../../../components/Molecule/Accordion";

const step6 = () => {
  return (
    <LayoutNewVideo activeTab={6}>
      <SaveToDraft />
      <div className="w-full flex pb-5 gap-8 justify-center px-10 md:px-0 md:mt-8 lg:-mt-0 md:bg-transparent">
        <div className="max-w-[596px] max-[360px]:min-w-[360px] min-[800px]:mt-2 min-[1280px]:-mt-16 max-[430px]:min-w-[420px] max-[376px]:min-w-[340px] max-[430px]:px-[26px] max-[390px]:px-[26px] -mt-16">
          <div className="max-[430px]:flex max-[430px]:flex-col max-[430px]:gap-[8px]">
            <h1 className="text-[24px] max-[430px]:text-[20px] font-semibold">
              Review
            </h1>
            <p className="text-[16px] font-medium text-[#757575]">
              Silahkan tinjau kembali kelas yang akan anda buat, dan simpan bila
              anda telah yakin semua telah diisi dengan benar.
            </p>
          </div>
          <LineSeperator
            required={false}
            headerTagLine={"Thumbnail dan Video Pengenalan Kelas"}
          />
          <div className="flex flex-col gap-y-[20px]">
            <UploadFileFilled
              type={"image"}
              label={"Thumbnail"}
              selectedFileName={"Thumbnail UI/UX"}
              fileSize={""}
            />
            <UploadFileFilled
              type={"video"}
              label={"Video Pengenalan"}
              selectedFileName={"Thumbnail UI/UX"}
              duration={""}
            />
          </div>

          <LineSeperator headerTagLine={"Informasi Umum"} />

          <div className="flex flex-col gap-y-[28px]">
            <InputFilled label={"Judul Kelas"} inputFill={"UI/UX Design"} />

            <div className="w-full grid grid-cols-2 gap-y-[28px]">
              <DropdownFilled
                dropdownLabel1={"Kategori Kelas"}
                dropdownValue1={"SMK"}
                dropdownLabel2={"Jenis Kelas"}
                dropdownValue2={"Intermediate"}
              />
              <DropdownFilled
                dropdownLabel1={"Jenis Peserta"}
                dropdownValue1={"SMK"}
                dropdownLabel2={"Level"}
                dropdownValue2={"Intermediate"}
              />
            </div>
            <InputFilled label={"Bahasa"} inputFill={"Indonesia"} />
            <InputFilled
              label={"Pemebelajaran Utama"}
              inputFill={"Mempelajari seluruh rangkaian UI/UX Design"}
            />
            <InputFilled
              label={"Deskripsi"}
              inputFill={
                "Mempelajari seluruh rangkaian UI/UX Design ipsum dolor sit amet consectetur adipisicing elit. Labore porro quaerat provident earum, "
              }
            />
            <InputFilled
              label={"Tujuan Umum"}
              inputFill={
                "Meningkatkan dan mempersiapkan skill bagi UI/UX Designer"
              }
            />

            <div className="flex flex-col gap-y-[8px]">
              <p className="text-[#616161] text-[14px] font-medium">
                {" "}
                Tujuan Khusus{" "}
              </p>
              <CheckBoxFilled
                description={
                  "Peserta memahami hakikat dan konsep design thinking "
                }
              />
              <CheckBoxFilled
                description={
                  "Peserta memahami hakikat dan konsep design thinking "
                }
              />
            </div>
            <div className="flex flex-col gap-y-[8px]">
              <p className="text-[#616161] text-[14px] font-medium">
                Tools Pembelajaran{" "}
              </p>
              <CheckBoxFilled description={"Peserta  "} />
              <CheckBoxFilled description={"Peserta  "} />
            </div>
          </div>

          <LineSeperator headerTagLine={"Kurikulum"} />
          <div className="">

            <Accordion session={"sesi 1"} />

          </div>


          <br />
          <br />
          <div className="flex flex-col gap-y-[10px]">
            <h1>Bank Soal</h1>
            <div className="w-full h-[64px] p-[12px] border rounded-lg">
              <div className={"flex gap-x-[16px] h-full w-full"}>
                <div className="min-w-[40px] min-h-[40px] flex justify-center rounded-md bg-[#9360A8]">
                  <Icon name={"Format_List_Numbered"} />
                </div>
                <div className=" flex items-center max-[430px]:text-[14px] max-[430px]:font-normal w-[50%]">
                  <h1 className="font-semibold">35 soal di buat </h1>
                </div>
                <div className={`flex justify-end w-[70%] cursor-pointer`}>
                  <div className=" w-[111px] h-[42px] max-[430px]:h-[36px] css-border-gradient flex items-center justify-center">
                    <span
                      style={{
                        background:
                          "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Lihat Soal
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <LineSeperator headerTagLine={"Uji Keterampilan"} />
          <UploadFileFilled
            type={"video"}
            label={"Video Uji Keterampilan"}
            selectedFileName={"thumbnail UI/UX Design"}
            duration={""}
          />

          <LineSeperator headerTagLine={"Penetapan Harga"} />
          <div className="flex flex-col gap-y-[12px]">
            <div className="flex justify-between ">
              <p className="text-[#9E9E9E]">Harga Kelas</p>
              <p className="text-[#232323] text-[20px]">Rp 324.000</p>
            </div>
            <div className="flex justify-between ">
              <p className="text-[#9E9E9E]">Diskon Potongan</p>

              <p className="text-[#232323] text-[20px]"> 3% <span className="text-[#9E9E9E]">  (Rp 27.000) </span> </p>
            </div>
            <div className="w-full h-[1px] bg-[#232323]"/>

            <div className="flex justify-between ">
              <p className="text-[#9E9E9E]">Total</p>
              <p className="text-[#232323] font-medium text-[24px]">Rp 297.000</p>
            </div>

          </div>
          <div className="flex items-center justify-between mt-5">
            <BackButton />
            <NextButton title={"Terbitkan Kelas"} />
          </div>
        </div>
      </div>
    </LayoutNewVideo>
  );
};
export default step6;
