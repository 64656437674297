import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { _getCourseByVoucher } from "../../../../config/services/api/course";
import { ClipArt } from "../../../independent/image";
import { SET_ALERT } from "../../../../config/redux/types";


export default function ClaimVoucherBanner() {
  const { push } = useHistory();
  const [input, setInput] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const submit = (e) => {
    e.preventDefault();

    if (isLoading || !input) return;
    setLoading(true);
    _getCourseByVoucher(input)
      .then((res) => {
        if (!res.status)
          return dispatch({
            type: SET_ALERT,
            payload: { status: res.status, message: res.message },
          });
        if (res.data.participantType.includes("WEBINAR")) {
          push(
            `/webinar/${res?.data?.classTitle
              ?.split(" ")
              .join("-")
              .split("/")
              .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
              input
            )}`
          );
        } else{
          push(
            `/course/${res?.data?.classTitle
              ?.split(" ")
              .join("-")
              .split("/")
              .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
              input
            )}`
          );
        }
        // push(
        //   `/course/${res?.data?.title
        //     ?.split(" ")
        //     .join("-")
        //     .split("/")
        //     .join("-")}/${res.data.classId}?voucher=${encodeURIComponent(
        //     input
        //   )}`
        // );
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className="my-[26px] lg:my-[59px] px-4 lg:px-[135px] flex items-center">
      <form
        onSubmit={(e) => submit(e)}
        className="flex lg:items-center space-x-8 w-full"
      >
        <ClipArt
          name="Travel Tickets"
          className="w-[83px] h-[83px] lg:w-[176px] lg:h-[176px]"
        />
        <div className="space-y-2 lg:space-y-3 w-full">
          <div className="space-y-2 lg:space-y-4">
            <h3 className="text-sm lg:text-xl text-netral-10 font-semibold">
              Sudah punya kode voucher Prakerja Skills.id
            </h3>
            <p className="text-xs lg:text-base text-primary-900">
              Segera tukarkan kode voucher kamu disini
            </p>
          </div>
          <div className="lg:flex items-center lg:space-x-8 space-y-2 lg:space-y-0">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Masukkan kode voucher Prakerja Anda"
              className="p-2 lg:px-3 lg:py-[15px] border border-primary-800 rounded-xl w-full text-xs lg:text-lg"
            />
            <button
              type="submit"
              disabled={isLoading || !input}
              className="text-white font-semibold px-3 py-2 lg:py-[18px] text-xs lg:text-base rounded-xl bg-primary2-500 whitespace-nowrap interactive-button-color border border-transparent duration-200"
            >
              Tukarkan Sekarang
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}
