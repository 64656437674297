import React from "react";
import { Icon } from "../../../independent/image";

export default function SaveToDraft() {
  return (
    <div
      className="flex gap-1 items-center cursor-pointer  sticky top-[120px] px-[32px]"
      // onClick={() => push("/instructor")}

      // save to draft function
      // onClick={{ }}
    >
      <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
      <span
        style={{
          background:
            "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",

          // "-webkit-background-clip": "text",
          // "-webkit-text-fill-color": "transparent",
        }}
        className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
      >
        Simpan Draft
      </span>
    </div>
  );
}
