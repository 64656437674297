import { Icon } from "../../image";

export default function ButtonBack({ m, disable, children, ...rest }) {
  return (
    <button
      {...rest}
      disabled={disable}
      className={`${m} hidden lg:flex p-1 items-center space-x-2`}
    >
      <Icon name="arrow_back-primary-600" />
      <span className="text-primary-600 font-medium">{children}</span>
    </button>
  );
}

ButtonBack.defaultProps = {
  m: "ml-8 mt-6",
};
