import { Link } from "react-router-dom";
import { ClipArt, Icon } from "../../image";
import Stars2 from "../../stars/stars2";
// import { APIBaseURL } from "../../../../config/environment";
import getLink from "../../../../constants/getLink";

export default function CardCourse({
  course,
  isWebinar,
  isPrakerja = true,
  fullWidth = false,
}) {
  const formatTitle = (title = "") => {
    if (title.length < 20) return title;
    return title.substring(0, 20) + " ...";
  };
  const title = course?.title?.split(" ").join("-").split("/").join("-");
  // console.log(course)

  return (
    <Link
      to={
        isWebinar
          ? `/webinar/${title}/${course?._id}`
          : `/course/${title}/${course?._id}`
      }
      className={`${
        fullWidth ? "w-full" : "w-[137px]"
      } lg:w-[270px] lg:h-[441px] flex flex-col justify-between`}
    >
      <div>
        <div
          style={{ filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.12))" }}
          className={`relative ${
            fullWidth ? "h-[224px]" : "h-[124px]"
          } lg:h-[220px] rounded-xl overflow-hidden bg-slate-100`}
        >
          <img
            src={getLink(course?.thumbnail)}
            alt={course?.thumbnail}
            className="object-fill w-full h-full"
          />
        </div>
        <div className="mt-1 lg:mt-2 space-y-[4px]">
          <div className=" flex items-center justify-between">
            <p className="text-primary2-600 lg:leading-[20px] text-xs lg:text-sm font-semibold lg:font-normal">
              {course?.category}
            </p>
            {/* <p className="hidden lg:block text-lg font-semibold text-secondary3-900 interactive-text-color">
          Rp {course?.price.toString().slice(0, 3)}K
        </p> */}
          </div>
          <div className="flex gap-[4px] flex-wrap">
            <span className="text-netral-10 rounded bg-secondary-300 text-2xs lg:text-sm py-1 px-2">
              {course?.level}
            </span>
            <span className="py-1 px-2  text-netral-10 rounded bg-secondary-300 text-2xs lg:text-sm font-semibold break-normal">
              {course?.classType === "Online Class"
                ? "Video"
                : isWebinar
                ? "Webinar"
                : course?.classType === "Workshop"
                ? "workshop"
                : "Kursus Offline"}
            </span>
          </div>
          <p className="text-sm lg:text-lg font-semibold mt-1 text-primary-900">
            {formatTitle(course?.title)}
          </p>
        </div>

        <small className="text-2xs lg:text-sm text-netral-6">
          {course?.instructor}
        </small>
      </div>
      <div>
        <div className="hidden lg:flex items-center justify-between">
          {course?.discount && course?.discount > 0 ? (
            <div>
              <div className="flex items-center space-x-2">
                <p className="bg-netral-4 rounded py-[4px] px-[8px] text-netral-7 text-sm">
                  {course?.discount}%
                </p>
                <p className="text-secondary-button-600 line-through text-base font-semibold">
                  Rp{parseInt(course?.price || "0").toLocaleString("id-ID")}
                </p>
              </div>
              <p className="text-lg font-semibold text-netral-10">
                Rp{" "}
                {parseInt(
                  course?.price - (course?.price * course?.discount) / 100 ||
                    "0"
                ).toLocaleString("id-ID")}
              </p>
            </div>
          ) : (
            <p className="text-lg font-semibold text-netral-10">
              Rp {parseInt(course?.price || "0").toLocaleString("id-ID")}
            </p>
          )}
          {isPrakerja && (
            <ClipArt name="label prakerja" className="w-[72px] h-[29px]" />
          )}
        </div>
        <div className="mt-2 hidden lg:flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Stars2 n={5} />
            <p className="text-sm text-netral-8">(5)</p>
          </div>
          <div className="flex items-center space-x-2">
            <Icon name="groups-netral-9" className="w-[18px] h-[18px]" />
            <p className="text-netral-8 text-sm">{course?.student || 0}</p>
          </div>
        </div>
        <button
          style={{ boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.16)" }}
          className="mt-2 text-lg font-semibold hidden lg:inline-block bg-primary-button-500 rounded text-netral-1 w-full py-2 interactive-button-color border border-transparent"
        >
          Beli
        </button>
        <p className="mt-1 lg:hidden font-semibold text-secondary3-900 interactive-text-color">
          Rp {parseInt(course?.price || "0").toLocaleString("id-ID")}
        </p>
      </div>
    </Link>
  );
}
