// import Condition from "../components/dependent/about/condition";
// import Hero from "../components/dependent/about/hero";
// import Intro from "../components/dependent/about/intro";
// import OurActivities from "../components/dependent/about/our-activities";
// import OurLocation from "../components/dependent/about/our-location";
// import FaqBanner from "../components/dependent/prakerja/faq-banner";
// import PrakerjaPartners from "../components/dependent/prakerja/prakerja-partners";
import LayoutHome from "../components/independent/layout/layout-home";

const About = () => {
  return (
    <LayoutHome>
      <div className="pt-[72px] lg:pt-[82px] pb-[100px]">
        <div className="pt-[48px] flex justify-center">
          <img
            src={`/assets/image/struktur-organisasi.png`}
            className="w-[75vw]"
            alt="struktur"
          />
        </div>
        <div className="flex flex-col items-center">
          <div className="w-[75vw] pt-[32px] space-y-[24px]">
            <p className="text-2xl font-semibold">
              Selamat Datang di{" "}
              <span className="text-[#9066AC]">Skills.id!</span>
            </p>
            <div className="space-y-[20px]">
              <p>
                Sebagai negara kepulauan dengan penduduk yang tersebar di
                seluruh wilayah pelosok nusantara, yang terpisahkan pulau dan
                bentang samudera yang luas. Kehadiran platform pembelajaran
                digital seperti Skills.id menjadi solusi yang efektif dalam
                pemerataan kesempatan belajar. Karena Skills.id memiliki LMS
                (Learning Management System) sebuah system pembelajaran mandiri
                yang membantu kamu untuk mengakses pelatihan-pelatihan skills di
                manapun dan kapanpun.
              </p>
              <p>
                Skills.id merupakan platform belajar digital yang mempertemukan
                edukator untuk berbagi ilmu dengan learner yang ingin belajar,
                sekaligus sebagai kelas pelatihan program kartu prakerja.
                Keberadaan skills.id adalah dalam rangka peningkatan
                ketrampilan, dan kompetensi sumber daya manusia khususnya
                angkatan kerja Indonesia menuju Indonesia Emas.{" "}
              </p>
              <p>
                Untuk dapat mencetak manusia Indonesia yang produktif,
                professional dan berdaya saing tentu memerlukan proses
                peningkatan skills (upskilling) melalui proses pelatihan secara
                berkesinambungan serta proses sertifikasi kompetensi yang sesuai
                dengan kebutuhan industri pada masa kini dan masa yang akan
                datang.{" "}
              </p>
              <p>
                Dengan didukung ratusan educator professional yang berasal dari
                beragam bidang industri dan akademis menjadikan Skills.id
                sebagai Lembaga Pelatihan yang kredibel dan terpercaya. Sehingga
                masyarakat dapat lebih leluasa memilih ragam pelatihan sesuai
                dengan minat dan kebutuhan mereka.{" "}
              </p>
              <p>
                Skills.id dibawah naungan PT Global Retail Bersama merupakan
                salah satu ekosistem bisnis Permata Indonesia sebuah entitas
                penyedia jasa tenaga alih daya terbesar di Indonesia yang
                senantiasa memberikan kesempatan bekerja seluas-luasnya bagi
                setiap angkatan kerja di Tanah Air.
              </p>
              <p>Mari wujudkan mimpimu bersama Skills.id</p>
              <p>Upgrade Your Skills Accelerate Your Career</p>
            </div>
          </div>
        </div>
        {/* <Hero />
        <Intro />
        <OurActivities />
        <Condition />
        <OurLocation />
        <PrakerjaPartners />
        <FaqBanner /> */}
      </div>
    </LayoutHome>
  );
};

export default About;
