import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymentMethod from "../../components/dependent/checkout/payment-method";
import Verfication from "../../components/dependent/checkout/verification";
import ButtonBack from "../../components/independent/button/button-back";
import Footer from "../../components/independent/footer/footer";
import Header from "../../components/independent/header/header";
import BottomNavMobile from "../../components/independent/layout/layout-checkout/BottomNavMobile";
import Details from "../../components/independent/layout/layout-checkout/Details";
import PurchaseDetails from "../../components/independent/layout/layout-checkout/PurchaseDetails";
import Title from "../../components/independent/layout/layout-checkout/Title";
import Modal from "../../components/independent/modal";
import ModalOtp from "../../components/independent/modal/modal-otp";
import NavHeader from "../../components/independent/navigation/nav-header";
import { _checkout } from "../../config/services/api/checkout";
import useCart from "../../hooks/data-management/useCart";
// import useQuery from "../../hooks/lib/useQuery";
// import useCart from "../../hooks/data-management/useCart";

const Checkout = () => {
  const { token } = useSelector((state) => state.authReducer);
  const { push } = useHistory();
  const [modal, setModal] = useState("");
  const [activeView, setActiveView] = useState("payment-method");
  const [payment, setPayment] = useState("virtual-account");
  const [isVerified, setVerified] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const { cart } = useCart();

  // const query = useQuery();
  // const itemsStr = query.get("items");

  const [items, setItems] = useState([]);

  let price = 0;
  let discount = 0;

  if (items.length)
    price = items.map((item) => +item.price).reduce((prev, crr) => prev + crr);

    console.log(price);

  useEffect(() => {
    setItems(cart);
  }, [cart]);
  // console.log(items);
  // useEffect(() => {
  //   if (!itemsStr) return;

  //   const itemsId = JSON.parse(itemsStr);

  //   if (!itemsId.length) return;

  //   const fetchById = itemsId.map((itemId) => _getCourse(itemId));

  //   Promise.all(fetchById)
  //     .then((res) => setItems(res.map((el) => el.class)))
  //     .catch((e) => {
  //       alert("cannot get items");
  //       console.log(e);
  //     });

  //   _getCourse();
  // }, [itemsStr]);

  const verifyOtp = (otpNumber) => {
    setVerified(true);
    setModal("");
  };

  const action = async () => {
    if (activeView === "payment-method") setActiveView("verification");
    if (activeView === "verification") {
      if (!isVerified) return alert("verifikasi dibutuhkan!");
      if (payment === "bank") {
        setLoading(true);
        // const itemsId = JSON.parse(itemsStr);
        try {
          const res = await _checkout(
            token,
            "Transfer Bank BCA",
            price,
            items.map((el) => ({ ...el.learner_schedule, _id: el._id }))
          );
          if (!res.status) {
            console.log("res", res);
            return alert("checkout gagal");
          }
          // alert("checkout berhasil");

          push(`/checkout/${res.data.id_payment}`);
        } catch (error) {
          alert("something went wrong");
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      console.log("gagal");
    }
  };

  useEffect(() => {
    return () => {
      setModal("");
      setActiveView("payment-method");
      setPayment("virtual-account");
      setVerified(true);
      setLoading(false);
    };
  }, []);

  console.log(items);

  return (
    <>
      <Modal modal={modal} setModal={setModal}>
        {modal === "otp" && (
          <ModalOtp
            setModal={setModal}
            phoneNumber={"0812121212"}
            verifyOtp={verifyOtp}
          />
        )}
      </Modal>
      <div className="h-screen flex flex-col">
        <Header>
          <NavHeader />
        </Header>
        <div className="overflow-auto h-full">
          <main className="w-full">
            <div className="pb-[200px] lg:pb-0">
              <ButtonBack type="button" onClick={() => push("/")}>
                Kembali ke Home
              </ButtonBack>
              <section className="px-4 lg:px-[135px] mt-6 lg:mt-9">
                <Title />
                <div className="lg:flex space-y-6 lg:space-y-0 lg:space-x-6 mt-6 lg:mt-8 ">
                  <div className="space-y-6 lg:space-y-8 lg:w-2/3">
                    <PurchaseDetails items={items} />
                    {activeView === "payment-method" && (
                      <PaymentMethod
                        setPayment={setPayment}
                        payment={payment}
                      />
                    )}
                    {activeView === "verification" && (
                      <Verfication
                        isVerified={isVerified}
                        setModal={setModal}
                        payment={payment}
                      />
                    )}
                  </div>
                  <Details
                    action={action}
                    isVerified={isVerified}
                    price={price}
                    discount={discount}
                    isLoading={isLoading}
                  />
                </div>
              </section>
              {/* <section className="mt-[112px] mb-[calc(112px-2rem)]">
              <OtherCourse />
            </section> */}
            </div>
          </main>
          <Footer className="mt-20 hidden lg:block" />
        </div>
        <BottomNavMobile
          action={action}
          price={Number(price).toLocaleString("id-ID")}
          isVerified={isVerified}
        />
      </div>
    </>
  );
};

export default Checkout;

// const cart = [
//   {
//     _id: "62848a3c47c297189c2beca5",
//     title: "dasarr javascript",
//     category: "IT dan Pengembangan",
//     student: 1,
//     price: 5000000,
//     instructor: "pah",
//     thumbnail:
//       "http://122.50.7.190:5000/api/class/stream/7c7739.1652681407096_javascript-logo-transparent-logo-javascript-images-3.png",
//     rating: 5,
//     intro:
//       "http://122.50.7.190:5000/api/class/stream/7c7739.1652685027653_videoplayback.mp4",
//     level: "Beginner",
//     syllabus: {
//       totalChapters: 3,
//       totalMaterials: 4,
//       totalDuration: "1 Menit 26 Detik",
//       chapters: [
//         {
//           name: "judul bab 1",
//           totalMaterials: 1,
//           description: 0,
//           material: [
//             { name: "materi 1 bab 1", description: 0, type: "application/pdf" },
//           ],
//         },
//         {
//           name: "judul bab 2",
//           totalMaterials: 3,
//           description: "1 Menit 26 Detik",
//           material: [
//             {
//               name: "materi 1 bab 2",
//               description: "1 Menit 26 Detik",
//               type: "video/mp4",
//             },
//             { name: "materi 2 bab 2", description: 0, type: "Pilihan Ganda" },
//           ],
//         },
//         {
//           name: "judul bab 3",
//           totalMaterials: 4,
//           description: "1 Menit 26 Detik",
//           material: [
//             { name: "materi 1 bab 3", description: 0, type: "Pilihan Ganda" },
//           ],
//         },
//       ],
//     },
//     tools: ["Visual Studo Code"],
//     review: {
//       totalRating: 5,
//       number: 0,
//       total5star: 100,
//       total4star: 0,
//       total3star: 0,
//       total2star: 0,
//       total1star: 0,
//     },
//   },
// ];

// const courseDetail = [
//   {
//     _id: "62848a3c47c297189c2beca5",
//     title: "dasarr javascript",
//     category: "IT dan Pengembangan",
//     price: "5000000",
//     intro:
//       "http://122.50.7.190:5000/api/class/stream/7c7739.1652685027653_videoplayback.mp4",
//     rating: 5,
//     total_student: 1,
//     level: "Beginner",
//     overview: {
//       description: "deskripsi kursus",
//       generalPurpose: ["a", "b", "c", "d", "e"],
//       competenceAspect: ["Peran dan tanggung jawab manajer proyek"],
//       targetParticipants: ["Peran dan tanggung jawab manajer proyek"],
//     },
//     instructor: {
//       name: "pah",
//       job: "programmer",
//       numberOfStudent: 1000,
//       totalCourse: 10,
//       about: "Hello my name is pah",
//       rating: 5,
//     },
//     syllabus: {
//       totalChapters: 3,
//       totalMaterials: 4,
//       totalDuration: "1 Menit 26 Detik",
//       chapters: [
//         {
//           name: "judul bab 1",
//           totalMaterials: 1,
//           description: 0,
//           material: [
//             { name: "materi 1 bab 1", description: 0, type: "application/pdf" },
//           ],
//         },
//         {
//           name: "judul bab 2",
//           totalMaterials: 3,
//           description: "1 Menit 26 Detik",
//           material: [
//             {
//               name: "materi 1 bab 2",
//               description: "1 Menit 26 Detik",
//               type: "video/mp4",
//             },
//             { name: "materi 2 bab 2", description: 0, type: "Pilihan Ganda" },
//           ],
//         },
//         {
//           name: "judul bab 3",
//           totalMaterials: 4,
//           description: "1 Menit 26 Detik",
//           material: [
//             { name: "materi 1 bab 3", description: 0, type: "Pilihan Ganda" },
//           ],
//         },
//       ],
//     },
//     tools: ["Visual Studo Code"],
//     review: {
//       totalRating: 5,
//       total5star: "100 %",
//       total4star: "0 %",
//       total3star: "0 %",
//       total2star: "0 %",
//       total1star: "0 %",
//       recomended: "1 dari 1 pelajar merekomendasikan kelas ini",
//       learnerReview: [
//         {
//           _id: "6286fd5e403f37cd2acccd41",
//           name: "ari permata",
//           star: 5,
//           profile_picture:
//             "http://122.50.7.190:5000/api/class/stream/6281aa349d56e3745b1ec2f8_profile-picture",
//           review: "Kelas sangat menyenangkan",
//           likes: 1,
//           reply: 1,
//         },
//       ],
//     },
//   },
// ];
