import { Link } from "react-router-dom";
import getLink from "../../../../constants/getLink";
import ButtonPrimary from "../../button/button-primary";
import { ClipArt } from "../../image";

export default function CardCertificate({ data,handleOpenCertificate }) {
  const isWebinar = data.participantType.includes("WEBINAR");

  return (
    <div
      style={{ filter: "drop-shadow(2px 6px 16px rgba(0, 0, 0, 0.1))" }}
      className="w-full min-h-[360px] flex flex-col justify-between"
    >
      <div>
        <div className="w-full min-h-[220px] rounded-lg bg-slate-700 relative overflow-hidden">
          <img
            src={getLink(data.thumbnail)}
            alt={data.thumbnail}
            className="w-full h-full object-fill"
          />
          <span className="absolute top-2 left-2 py-1 px-2 text-sm rounded-lg bg-secondary2-100">
            {data.level}
          </span>
          <span
            style={{ boxShadow: "1px 2px 10px rgba(0, 0, 0, 0.3)" }}
            className="absolute bottom-2 left-2 py-1 px-2 text-sm rounded bg-secondary-300 font-semibold"
          >
            {data.classType === "Offline Class"
              ? "Kursus Offline"
              : isWebinar
              ? "Webinar"
              : data?.classType === "workshop" ? "workshop" : "Video"}
          </span>
        </div>
        <h4 className="text-xl font-semibold mt-2">{data.title}</h4>
        <div className="flex justify-between items-center mt-1">
          <p className="text-sm text-netral-6">Oleh {data.intructor}</p>
          <ClipArt
            name="label prakerja"
            className="w-[49.66px] h-5 object-contain"
          />
        </div>
      </div>
      <div onClick={() => handleOpenCertificate()}>
        <ButtonPrimary>Lihat Sertifikat</ButtonPrimary>
      </div>
    </div>
  );
}
