import React from "react";
import { Link } from "react-router-dom";
import getLink from "../../../../constants/getLink";
import ButtonPrimary from "../../button/button-primary";

export default function BlogCard({ data, imageHeight = "h-[195px]" }) {
  const { _id, author, createdAt, image, content, title } = data;
  return (
    <div className="space-y-[8px] h-full w-full">
      <div className="w-full overflow-hidden">
        <img
          alt="banner"
          className={`${imageHeight} w-full transition duration-500 ease-in-out hover:scale-125`}
          src={getLink(image)}
        />
      </div>
      <div>
        <div className="space-y-[8px]">
          <p className="text-sm text-netral-6">
            {author} • {createdAt}
          </p>
          <p className="text-base font-semibold text-netral-10">{title}</p>
          <p className="text-xs text-netral-7 line-clamp-3">{content}</p>
        </div>
        <div className="">
          <Link to={`/blog/${_id}`}>
            <ButtonPrimary
              type="submit"
              width="w-max"
              margin="mt-[8px]"
              marginL="mt-4"
            >
              Baca Selengkapnya
            </ButtonPrimary>
          </Link>
        </div>
      </div>
    </div>
  );
}
