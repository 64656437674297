import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_DELETE_NEW_COURSE_FILES } from "../../../../config/redux/types";
import { _uploadFileNewCourse } from "../../../../config/services/api/course";
import getVideoDuration from "../../../../constants/getVideoDuration";
import { useCurriculum } from "../../../../pages/new/course/[course-id]/step6/Curriculum";
import InputTextLabel from "../../../independent/input/input-text-label";
// import { SET_DELETE_NEW_COURSE_FILES } from "../../../config/redux/types";
// import { _uploadFileNewCourse } from "../../../config/services/api/course";
// import getVideoDuration from "../../../config/utils/getVideoDuration";
// import { useCurriculum } from "../../../pages/new/course/[course-id]/step4/Curriculum";
// import LabelInput2 from "../../atoms/LabelInput2";
// import LabelTextInput from "../../atoms/LabelTextInput";

export default function FormLesson({ materialIndex, chapterIndex }) {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authReducer);
  const { chapters, setChapters } = useCurriculum();

  const [loading, setLoading] = useState({
    file: false,
    additionalFile: false,
  });

  const changeLoading = (key, value) => {
    setLoading((crr) => ({ ...crr, [key]: value }));
  };

  const uploadFile = async (key, file) => {
    changeLoading(key, true);

    let uploadedData = {};

    const acceptType =
      file.type === "application/pdf" || file.type === "video/mp4";

    if (!acceptType) return alert("format file tidak didukung");

    if (file?.type === "video/mp4") {
      const duration = await getVideoDuration(file);

      if (duration) uploadedData = { duration };
    }

    try {
      const res = await _uploadFileNewCourse(file, token);

      if (!res.status) console.log("something went wrong", res);

      uploadedData = { ...uploadedData, ...res.data };

      inputChange(key, uploadedData);

      if (
        chapters[chapterIndex].materials[materialIndex].lesson[key].storage ===
        "cloud"
      )
        dispatch({
          type: SET_DELETE_NEW_COURSE_FILES,
          payload:
            chapters[chapterIndex].materials[materialIndex].lesson[key].name,
        });
    } catch (error) {
      console.log("uploadFile err", error);
    } finally {
      changeLoading(key, false);
    }
  };

  const inputChange = (key, value) => {
    chapters[chapterIndex].materials[materialIndex].lesson[key] = value;
    setChapters([...chapters]);
  };

  const deleteLesson = () => {
    chapters[chapterIndex].materials[materialIndex].lesson = null;
    chapters[chapterIndex].materials[materialIndex].view = "MaterialOption";
    setChapters([...chapters]);
  };

  const submit = (e) => {
    e.preventDefault();
    chapters[chapterIndex].materials[materialIndex].view = "Review";
    setChapters([...chapters]);
  };

  return (
    <form
      onSubmit={(e) => submit(e)}
      className="space-y-6 pl-6 pt-4 pb-[21px] pr-[15px] rounded border border-netral-6 bg-netral-1"
    >
      <div className="flex space-x-8 items-end">
        <div className="space-y-4 w-full">
          <div className="flex items-end space-x-6">
            <InputTextLabel
              text="Judul Materi"
              id={`lesson-title-${chapterIndex}-${materialIndex}`}
              placeholder="Masukkan judul materi"
              onChange={(e) => inputChange("title", e.target.value)}
              value={
                chapters[chapterIndex].materials[materialIndex].lesson.title
              }
              required
            />
            <button
              type="button"
              disabled
              className="opacity-0 whitespace-nowrap relative font-semibold text-secondary-button-600 px-3 py-2 rounded border border-secondary-button-600"
            >
              Unggah File
            </button>
          </div>
          <div className="flex items-center space-x-6">
            <div className="space-y-1 w-full">
              <InputTextLabel
                text="File Materi"
                id={`lesson-file-${chapterIndex}-${materialIndex}`}
                placeholder="Tambahkan file materi"
                value={
                  chapters[chapterIndex].materials[materialIndex].lesson.file
                    ?.name
                }
                readOnly
                required
              />
              <p className="text-xs text-netral-9">
                Klik untuk unggah document atau video ( format file Pdf atau
                Mp4)
              </p>
            </div>
            <button
              disabled={loading.file}
              type="button"
              className={`${
                loading.file
                  ? "text-white bg-netral-5 cursor-not-allowed"
                  : "text-secondary-button-600 border border-secondary-button-600 interactive-button-color"
              } whitespace-nowrap relative top-1 font-semibold px-3 py-2 rounded`}
            >
              <input
                disabled={loading.file}
                id={`lesson-file-${chapterIndex}-${materialIndex}`}
                type="file"
                accept=".mp4,.pdf"
                className="absolute left-0 top-0 w-full h-full opacity-0"
                onChange={(e) => uploadFile("file", e.target.files[0])}
              />
              {loading.file ? "Uploading..." : "Unggah File"}
            </button>
          </div>
          <div className="flex items-center space-x-6">
            <div className="space-y-1 w-full">
              <InputTextLabel
                text="Materi Penunjang (Optional)"
                id={`additional-${chapterIndex}-${materialIndex}`}
                placeholder="Tambahkan materi penunjang yang bisa didownload peserta"
                value={
                  chapters[chapterIndex].materials[materialIndex].lesson
                    .additionalFile?.name
                }
                readOnly
              />
              <p className="text-xs text-netral-9">
                Klik untuk unggah document atau video ( format file Pdf atau
                Mp4)
              </p>
            </div>
            <button
              disabled={loading.additionalFile}
              type="button"
              className={`${
                loading.additionalFile
                  ? "text-white bg-netral-5 cursor-not-allowed"
                  : "text-secondary-button-600 border border-secondary-button-600 interactive-button-color"
              } whitespace-nowrap relative top-1 font-semibold px-3 py-2 rounded`}
            >
              <input
                disabled={loading.additionalFile}
                id={`additional-${chapterIndex}-${materialIndex}`}
                type="file"
                className="absolute left-0 top-0 w-full h-full opacity-0"
                accept=".mp4,.pdf"
                onChange={(e) =>
                  uploadFile("additionalFile", e.target.files[0])
                }
              />
              Unggah File
            </button>
          </div>
        </div>
      </div>
      <div className="flex space-x-5 items-center">
        <button
          type="submit"
          className="font-semibold text-secondary-button-600"
        >
          Simpan Materi
        </button>
        <button
          type="button"
          onClick={deleteLesson}
          className="font-semibold text-primary2-500"
        >
          Hapus
        </button>
      </div>
    </form>
  );
}
