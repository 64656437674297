import { SET_ALERT, CLOSE_ALERT } from "../types";

const initialState = {
  status: false,
  message: "",
};

export default function alertReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ALERT:
      return payload;
    case CLOSE_ALERT:
      return initialState;
    default:
      return state;
  }
}
