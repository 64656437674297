import React from "react";

export default function DropdownFilled({
  dropdownLabel1,
  dropdownValue1,
  dropdownLabel2,
  dropdownValue2,
}) {
  return (
    <>
      <div className="w-[50%] flex flex-col gap-y-[8px]">
        <p className="text-[#616161] text-[14px] font-medium">
          {dropdownLabel1}{" "}
        </p>
        <p className="text-[#404040] text-[16px] font-normal">
          {dropdownValue1}{" "}
        </p>
      </div>

      <div className="w-[50%] flex flex-col gap-y-[8px]">
        <p className="text-[#616161] text-[14px] font-medium">
          {dropdownLabel2}{" "}
        </p>
        <p className="text-[#404040] text-[16px] font-normal">
          {dropdownValue2}{" "}
        </p>
      </div>
    </>
  );
}
