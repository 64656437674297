import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import { Icon } from "../../../../../components/independent/image";
// import InputDropdownLabel2 from "../../../../../components/independent/input/input-dropdown-label2";
import InputNumberLabel from "../../../../../components/independent/input/input-number-label";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import {
  SET_COURSE_DISCOUNT,
  SET_COURSE_PRICING,
  SET_FINISHED_NAV,
} from "../../../../../config/redux/types";

const Pricing = () => {
  const dispatch = useDispatch();
  const { pricing, classType, discount } = useSelector(
    (state) => state.newCourseReducer
  );
  const setPricing = (value) =>
    dispatch({ type: SET_COURSE_PRICING, payload: value });

  const setDiscount = (value) =>
    dispatch({ type: SET_COURSE_DISCOUNT, payload: value });

  // const total = () => {
  //   const price = parseInt(pricing.price);

  //   if (isNaN(price)) return 0;

  //   return (price * pricing.profit).toLocaleString("id-ID");
  // };

  return (
    <LayoutNewCourseStep4
      title="Penetapan Harga"
      subTitle="Tetapkan harga kursus Anda"
    >
      {/* <div className="p-4 bg-primary2-100 rounded-xl">
        <ul className="space-y-2">
          {infos.map((info, i) => (
            <li key={i} className="flex items-center space-x-4">
              <span className="bg-primary-900 rounded-full w-[9px] h-[9px]"></span>
              <span className="text-netral-10">{info}</span>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="mt-8 flex items-start justify-between">
        <div className="flex flex-col space-y-[14px] w-full">
          {/* <div className="w-max">
            <InputDropdownLabel2 text="Mata Uang" values={["IDR"]} />
          </div> */}
          <div className="flex space-x-3">
            <div className="w-[50%]">
              <InputNumberLabel
                text="Harga Kursus"
                id="pricing"
                value={pricing.price}
                onChange={(e) =>
                  setPricing({ ...pricing, price: e.target.value })
                }
              />
            </div>
            <div className="w-[50%]">
              <div className="space-y-2 font-medium w-full">
                <label
                  className="text-xs lg:text-sm text-netral-8"
                  htmlFor={"Diskon"}
                >
                  Diskon
                </label>
                <div className="relative flex border border-[#757575] h-[50px] shadow-sm rounded-md">
                  <input
                    className="w-full text-sm lg:text-base  p-2 lg:p-3 border-none"
                    type="number"
                    id={"Diskon"}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                  <div className=" h-[48px] w-[40px] flex items-center justify-center bg-primary-100 rounded-r-md">
                    <p>%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-[10px] bg-[#CCEAEB] rounded-[5px] py-[9px] pr-[26px] pl-[10px] w-[286px]">
            <Icon
              name="information (2) 1.-primary-500"
              className="w-[18px] h-[18px]"
            />
            <p className="text-[11px] text-primary-800">
              Total pendapatan belum termasuk potongan sesuai MOU yang telah di
              sepakati
            </p>
          </div>
        </div>
        {/* <div className="w-[389px]">
          <h3 className="text-xl font-semibold text-netral-10 pb-3 border-b border-b-netral-5">
            Rincian
          </h3>
          <div className="py-3 space-y-3 border-b border-b-netral-5">
            <p className="font-semibold text-netral-10">Kelas video Prakerja</p>
            <div className="flex items-center space-x-3">
              <div className="space-y-3 text-netral-9">
                <p>Pengajar</p>
                <p>Skills.id</p>
              </div>
              <div className="space-y-3 text-netral-10">
                <p>{pricing.profit * 100}%</p>
                <p>{100 - pricing.profit * 100}%</p>
              </div>
              <div className="space-y-3 text-netral-10">
                <p>x</p>
                <p>x</p>
              </div>
              <div className="space-y-3 text-netral-10">
                <p>
                  {isNaN(parseInt(pricing.price))
                    ? 0
                    : parseInt(pricing.price).toLocaleString("id-ID")}
                </p>
                <p>
                  {isNaN(parseInt(pricing.price))
                    ? 0
                    : parseInt(pricing.price).toLocaleString("id-ID")}
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-2 py-4">
            <div className="space-x-3 flex items-center">
              <h6 className="text-netral-10 font-semibold text-lg">Total:</h6>
              <h6 className="text-netral-10 font-semibold text-lg">
                {total()}
              </h6>
            </div>
            <p className="text-sm text-secondary3-500">
              Yang Anda dapatkan dari kursus ini adalah{" "}
              <span className="font-semibold">IDR {total()}</span>
            </p>
          </div>
        </div> */}
      </div>
      <ChildComponent pricing={pricing} classType={classType} />
    </LayoutNewCourseStep4>
  );
};

export default Pricing;

const ChildComponent = ({ pricing, classType }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      page={"pricing"}
      active={!isNaN(parseInt(pricing.price))}
      next={() => {
        dispatch({ type: SET_COURSE_PRICING, payload: pricing });
        // dispatch({ type: SET_COURSE_DISCOUNT, payload: discount });
        dispatch({ type: SET_FINISHED_NAV, payload: "pricing" });
        push(
          classType === "Workshop"
            ? `/new/course/${id}/step6/review`
            : `/new/course/${id}/step6/competence-unit`
        );
      }}
    />
  );
};

// const infos = [
//   "Tatap Muka dan Webinar 80% pengajar , 20% Skills.id dari harga kelas yang Anda masukkan",
//   "Kelas video 70% pengajar, 30% Skills.id dari harga kelas yang Anda masukkan",
//   "Kelas video Prakerja 60% pengajar, 40% Skills.id dari harga kelas yang Anda masukkan",
// ];
