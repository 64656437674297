import { useSelector } from "react-redux";
import Category from "../components/dependent/home/category";
import ClaimVoucherBanner from "../components/dependent/home/claim-voucher-banner";
// import CoursesByCategory from "../components/dependent/home/courses-by-category";
import Facility from "../components/dependent/home/facility";
import Hero from "../components/dependent/home/hero";
import InstructorBanner from "../components/dependent/home/instructor-banner";
import PrakerjaBanner from "../components/dependent/home/prakerja-banner";
import PrakerjaCourses from "../components/dependent/home/prakerja-courses";
import SkillsIDBanner from "../components/dependent/home/skills-id-banner";
// import Testimonials from "../components/dependent/home/testimonials";
import WebinarList from "../components/dependent/home/webinar-list";
import WorkshopList from "../components/dependent/home/workshop-courses";
// import { Icon } from "../components/independent/image";
import LayoutHome from "../components/independent/layout/layout-home";
// import Recommendation from "../components/sections/home/Recommendation";
// import { useSelector } from "react-redux";

const Home = () => {
  const { userData } = useSelector((state) => state.authReducer);
  const isInstructor = userData.role.some((role) => role === "Instructor");
  // console.log("userData",userData);
  return (
    <LayoutHome>
      <Hero />
      <ClaimVoucherBanner />
      <Facility />
      <Category />
      <PrakerjaBanner />
      <PrakerjaCourses />
      <WebinarList />
      <SkillsIDBanner />
      <WorkshopList />
      <div className="mb-[calc(8rem-2rem)]" />
      {/* <CoursesByCategory /> */}
      {/* <Recommendation
          title="Rekomendasi kursus sesuai dengan ketertarikan Anda"
          description="Kursus yang kami rekomendasikan sesuai dengan ketertarikan Anda"
        />
        <Recommendation
          title="Rekomendasi kursus sesuai dengan Pekerjaan Anda"
          description="Kursus yang kami rekomendasikan sesuai dengan ketertarikan Anda"
        />
        <Recommendation
          title="Rekomendasi kursus sesuai dengan History Anda"
          description="Kursus yang kami rekomendasikan sesuai dengan ketertarikan Anda"
        /> */}
      {!isInstructor && <InstructorBanner />}
      {/* <Testimonials /> */}
    </LayoutHome>
  );
};

export default Home;
