import Footer from "../../footer/footer";
import Header from "../../header/header";
import NavHeader from "../../navigation/nav-header";

export default function LayoutHome({ children }) {
  return (
    <div>
      <Header isBlue className="fixed top-0 w-full z-10">
        <NavHeader isBlue />
      </Header>
      <main className="w-full ">{children}</main>
      <Footer />
    </div>
  );
}
