import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BigPlayButton, Player } from "video-react";
import ButtonBack from "../../../../components/independent/button/button-back";
import { Icon } from "../../../../components/independent/image";
import LayoutDashboard from "../../../../components/independent/layout/layout-dashboard";
import {
  _detailSkilltest,
  _evaluationSkilltest,
  _syncIndividualTest,
} from "../../../../config/services/api/instructor";
import getLink from "../../../../constants/getLink";
import Modal from "../../../../components/independent/modal";
import LoadingSyncModal from "../../../../components/independent/modal/modal-loading-sync";
import SuccessSyncModal from "../../../../components/independent/modal/modal-success-sync";
import ButtonPrimary from "../../../../components/independent/button/button-primary";

const DetailStudent = () => {
  const { goBack } = useHistory();
  const { studentId, courseId } = useParams();
  const { token } = useSelector((state) => state.authReducer);
  const [data, setData] = useState(defaultData);
  const [skilltestScore, setSkilltestScore] = useState(0);
  const [successSync, setSuccessSync] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);

  const postEvaluationSkilltest = async () => {
    const feedbacks = data.skillstest.map((el, i) => ({
      file_index: i,
      file_feedback: el.feedback,
    }));

    const res = await _evaluationSkilltest(
      token,
      studentId,
      skilltestScore,
      feedbacks
    );

    if (res.status) goBack();
  };

  const active = () => {
    if (!skilltestScore) return false;
    if (data.skillstest.some((el) => !el.feedback)) return false;
    return true;
  };

  useEffect(() => {
    const getData = async () => {
      const res = await _detailSkilltest(token, studentId);

      if (!res.status) return alert("something went wrong");

      setData(res.detail);
    };

    getData();
  }, [token, studentId]);

  const handleSync = async () => {
    const dataToSend = {
      chapterIndex: 99,
      classId: courseId,
      progressId: studentId,
      type: "uk",
    };
    setLoadingSync(true);
    const res = await _syncIndividualTest(token, dataToSend);
    setLoadingSync(false);
    if (res.status) {
      setSuccessSync(true);
    } else {
      alert(res.message);
    }
  };

  return (
    <LayoutDashboard>
      <ButtonBack onClick={goBack} m={0}>
        Kembali
      </ButtonBack>
      <h3 className="text-2xl text-netral-10 font-semibold mt-[14px]">
        Penilaian Student
      </h3>
      <div className="mt-[45px] flex items-center justify-between">
        <div className="flex items-center space-x-[15px]">
          <div className="rounded-full h-[45px] w-[45px] overflow-hidden">
            <img
              className="h-full w-full object-cover bg-teal-700"
              src={getLink(data.thumbnail)}
              alt={data.thumbnail}
            />
          </div>
          <div>
            <h6 className="text-netral-10 font-semibold">{data.name}</h6>
            <p className="text-xs text-[#404040]">
              {data.email || data.phone_number}
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <div className="-mt-8">
            <ButtonPrimary onClick={() => handleSync()}>
              Sinkronisasi
            </ButtonPrimary>
          </div>
          <div>
            <button
              disabled={!active()}
              onClick={postEvaluationSkilltest}
              className={`${
                active()
                  ? "text-netral-1 bg-secondary-button-600"
                  : "text-netral-5 bg-netral-6 cursor-not-allowed"
              } font-semibold px-3 py-2 rounded`}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="mt-[30px]">
        <h5 className="text-[18px] font-bold text-netral-10">
          List Tugas Uji Keterampilan
        </h5>
        <div className="space-y-5 divide-y">
          {data.skillstest.map((el, i) => (
            <div key={i} className="flex pt-5 flex-col gap-y-5 lg:flex-row">
              {el.type !== "video/mp4" ? (
                <div className="lg:w-1/2 w-full flex gap-x-2 lg:block">
                  <div className="lg:w-[250px] w-1/3 h-[135px] border-2 border-netral-3 rounded-lg flex justify-center space-y-2 items-center flex-col">
                    <Icon name="Docs-icon" className="w-[53px] h-[53px] m-0" />
                    <button
                      className="text-primary-600 "
                      onClick={() => window.open(getLink(el?.link))}
                    >
                      Lihat File
                    </button>
                  </div>
                  <div>
                    <p className="mt-[15px] text-netral-10 font-medium">
                      {el?.name}
                    </p>
                    <p className="mt-[5px] text-netral-10 text-sm">
                      {new Date(el.uploadAt).toLocaleString("id-ID")}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="lg:w-1/2 w-full">
                  <div className="relative overflow-hidden rounded-lg w-[249px] h-[134px]">
                    <Player
                      fluid={false}
                      width="100%"
                      height="100%"
                      src={getLink(el.link)}
                      className="absolute top-0 left-0"
                    >
                      <BigPlayButton position="center" className="scale-50" />
                    </Player>
                  </div>
                  <p className="mt-[15px] text-netral-10 font-medium">
                    {el.name}
                  </p>
                  <p className="mt-[5px] text-netral-10 text-sm">
                    {new Date(el.uploadAt).toLocaleString("id-ID")}
                  </p>
                </div>
              )}

              <div className="w-full lg:w-1/2 space-y-[5px]">
                <p className="text-sm font-semibold text-netral-10">Catatan</p>
                <textarea
                  onChange={(e) => {
                    if (e.target.value.length < 251) {
                      let newData = data;
                      newData.skillstest[i].feedback = e.target.value;
                      setData({ ...newData });
                    }
                  }}
                  value={el.feedback || ""}
                  className="w-full h-[134px] p-3 rounded-lg border border-[#E9ECEC] resize-none"
                  placeholder="Masukan catatan untuk uji keterampilan ini..."
                ></textarea>
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="mt-[50px] text-netral-10">
        Setelah melihat hasil uji kompetensi student selanjutnya berikan nilai
        dari hasil kerja student dibawah ini
      </p>
      <div className="mt-[15px] rounded-[5px] border border-[#0D8D90] w-[235px]">
        <p className="py-[15px] text-netral-1 bg-[#0D8D90] text-center">
          Range 0 - 100
        </p>
        <input
          type="number"
          className="py-[15px] w-full text-center font-bold text-2xl"
          value={skilltestScore || data.score}
          onChange={(e) => {
            if (e.target.value < 0) return;
            setSkilltestScore(e.target.value);
          }}
        />
      </div>
      <Modal modal={loadingSync}>
        <LoadingSyncModal />
      </Modal>
      <Modal modal={successSync}>
        <SuccessSyncModal handleClose={() => setSuccessSync(false)} />
      </Modal>
    </LayoutDashboard>
  );
};

export default DetailStudent;

const defaultData = {
  _id: "0",
  name: "",
  score: "",
  thumbnail: "0",
  phone_number: "",
  skillstest: [],
};
