import { useState } from "react";
import { Icon } from "../components/independent/image";
import TableClassic from "../components/independent/table/table-classic";
import { _upload } from "../config/services/api/upload";

const Upload = () => {
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const upload = async () => {
    try {
      setLoading(true);
      const res = await _upload(files);
      if (!res.status) throw new Error("something went wrong").message;
      setFiles([]);
      setData([...data, ...res.data]);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-[1010px] mx-auto py-10 space-y-8">
      <div className="space-y-[15px]">
        <p className="font-semibold text-netral-10">Upload File</p>
        <div className="text-center w-full pt-[10px] pb-[19px] space-y-[5px] rounded-lg border border-dashed border-[#12A89D]">
          <Icon
            name="cloud-computing 1-primary-600"
            className="w-12 h-12 mx-auto"
          />
          <p className="text-sm text-netral-10 font-medium">
            Drag and Drop File Disini
          </p>
          <p className="text-xs text-[#818080] font-light">
            Mendukung semua jenis file, video (MP4)
          </p>
          <p className="text-xs text-[#818080] font-semibold">Atau</p>
          <div className="relative">
            <input
              multiple
              accept="video/*"
              type="file"
              onChange={(e) => setFiles([...files, ...e.target.files])}
              className="absolute top-0 left-0 h-full w-full opacity-0"
            />
            <button className="font-bold text-[#12A89D] text-[13px] px-5 py-[7px] border-[#12A89D] border rounded-[5px]">
              Browse files
            </button>
          </div>
        </div>
      </div>
      <div className="space-y-[10px]">
        {new Array(...files).map((file, i) => (
          <div
            key={i}
            className="border border-[#E9ECEC] rounded-lg py-[10px] px-[15px] flex items-center justify-between"
          >
            <div className="flex items-center space-x-[10px]">
              <Icon name="document 1-primary-500" className="w-6  h-6" />
              <div className="space-y-[3px]">
                <p className="font-medium text-netral-10 text-[13px]">
                  {file.name}
                </p>
                <p className="text-xs text-[#818080]">{file.size}</p>
              </div>
            </div>
            <button
              onClick={() => {
                const arr = [...files];
                arr.splice(i, 1);
                setFiles(arr);
              }}
            >
              <Icon name="delete_outline-secondary-3-500" className="h-6 w-6" />
            </button>
          </div>
        ))}
      </div>
      {new Array(...files).length ? (
        <div className="flex items-center justify-end space-x-[15px]">
          <button
            type="button"
            onClick={() => setFiles([])}
            disabled={isLoading}
            className="text-[13px] font-medium text-[#12A89D] px-[15px] py-[7px] rounded-[5px] border border-[#12A89D]"
          >
            Hapus semua
          </button>
          <button
            type="button"
            onClick={upload}
            disabled={isLoading}
            className={`${
              !isLoading
                ? "text-netral-1 border-[#12A89D] bg-[#12A89D]"
                : "text-netral-6 border-transparent bg-netral-5 cursor-not-allowed"
            } text-[13px] font-medium px-[15px] py-[7px] rounded-[5px] border`}
          >
            {isLoading ? "Uploading..." : " Upload files"}
          </button>
        </div>
      ) : (
        <></>
      )}
      <TableClassic tds={tds} ths={ths} data={data} />
    </div>
  );
};

export default Upload;

const ths = ["Berkas", "Ukuran", "Tanggal Upload", "Nama File"];
const tds = ["link", "size", "uploadAt", "name"];
