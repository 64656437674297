import React from "react";
import { Icon } from "../../independent/image";

export default function TabHeader({
  selectedTab,
  setSelectedTab,
  tabs,
  setTabs,
}) {
  const handleSelectTab = (idx) => {
    setSelectedTab(idx);
  };

  const handleAddTab = () => {
    setTabs((prev) => [...prev, prev.length + 1]);
  };

  return (
    <div className="flex border-b-[5px] border-new-primary-base border-solid items-end">
      {tabs.map((el, idx) => (
        <div
          style={{
            boxShadow: "3px 0px 5px 0px rgba(61, 61, 68, 0.30)",
            zIndex: tabs.length - idx + 10,
          }}
          onClick={() => handleSelectTab(idx)}
          className={`${
            idx === selectedTab
              ? `bg-new-primary-base h-[40px] z-[100]`
              : "bg-netral-2 h-[34px] hover:bg-netral-3 hover:z-1"
          } ${tabs.length > 6 && idx !== selectedTab ? "w-[34px] flex items-center justify-center" : "whitespace-nowrap px-[16px] py-[7px]"} rounded-t-xl cursor-pointer transition delay-250`}
        >
          <p
            className={
              idx === selectedTab
                ? "text-lg font-medium text-netral-1"
                : "text-base font-normal text-netral-5"
            }
          >
            {tabs.length < 6 || idx === selectedTab
              ? "Sesi " + (idx + 1)
              : idx + 1}
          </p>
        </div>
      ))}
      <div
        onClick={handleAddTab}
        className={
          "bg-netral-2 hover:bg-netral-3 hover:z-1 px-[16px] py-[7px] rounded-t-xl cursor-pointer transition delay-250 flex gap-[4px]"
        }
      >
        <Icon
          name={"add-new-primary"}
          w={"10px"}
          h={"10px"}
          className={"m-[3px]"}
        />
        {tabs.length < 6 && (
          <p className="text-xs font-normal text-new-primary-base">Tambah Sesi</p>
        )}
      </div>
    </div>
  );
}
