import { useRef } from "react";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";
import { Icon } from "../../image";

export default function ModalPaymentDetai({ setModal, data }) {
  const ref = useRef();
  useOutsideClick(ref, () => {
    setModal((crr) => crr && "");
  });

  const totalPrice = () =>
    data.class.length &&
    data.class
      ?.map((item) => item.price)
      .reduce((prev, crr) => Number(prev) + Number(crr));

  return (
    <div className="flex justify-center">
      <div ref={ref} className="relative lg:w-auto w-full px-4 lg:p-0">
        <button
          type="button"
          onClick={() => setModal("")}
          className="absolute top-4 right-4"
        >
          <Icon name="close-netral-10" className="w-6 h-6" />
        </button>
        <div className="bg-white lg:w-[644px] rounded-lg p-6 space-y-6">
          <h4 className="text-center font-semibold text-netral-10 text-xl">
            Detail Pembayaran
          </h4>
          <div className="space-y-3 pb-3 border-b-2 border-[#E0E0E0]">
            <p className="font-semibold text-netral-10">Tanggal</p>
            <p className="text-netral-9">
              {new Date(data.date || 0).toLocaleDateString()}
            </p>
          </div>
          <div className="space-y-3">
            <p className="font-semibold text-netral-10">Kelas yang dibeli</p>
            <div className="space-y-2">
              {data.class?.map((item, i) => (
                <div key={i} className="space-y-1">
                  <div className="flex items-center justify-between">
                    <p className="text-netral-9">{item.title}</p>
                    <p className="text-netral-9">
                      Rp. {Number(item.price || 0).toLocaleString("id-ID")}
                    </p>
                  </div>
                  <p className="text-netral-6 text-sm">
                    Oleh {item.instructor}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <p className="font-semibold text-netral-10">Total Tagihan</p>
              <p className="text-netral-9">
                Rp. {Number(totalPrice()).toLocaleString("id-ID")}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-netral-9">Discount</p>
              <p className="text-netral-9">0%</p>
            </div>
            <div className="flex items-center justify-between pt-3 border-t-2 border-[#E0E0E0]">
              <p className="font-semibold text-secondary3-600">Total Bayar</p>
              <p className="font-semibold text-secondary3-600">
                Rp. {Number(totalPrice()).toLocaleString("id-ID")}
              </p>
            </div>
          </div>
          <div className="space-y-3">
            <p className="font-semibold text-netral-10">Metode Pembayaran</p>
            <div className="flex items-center justify-between">
              <p className="text-netral-9">{data.payment_method}</p>
              <p className="text-netral-9">
                Rp. {Number(totalPrice()).toLocaleString("id-ID")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
