export default function Tabs({ tabs, active, setActive }) {
  return (
    <ul className="flex items-center border-b-2 space-x-6 lg:space-x-[49px]">
      {tabs.map((tab, i) => (
        <li key={i}>
          <button
            type="button"
            onClick={() => setActive(tab)}
            className={`${
              tab === active
                ? "text-primary-600 border-primary-600 border-b-2"
                : "text-netral-6"
            } relative top-[2px] font-semibold pb-3 text-xs lg:text-base`}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  );
}
