// import { Link } from "react-router-dom";
// import { Icon } from "../../../components/independent/image";
import LayoutDashboard from "../../../components/independent/layout/layout-dashboard";

const Communication = () => {
  return (
    <LayoutDashboard>
      <div className="space-y-8 mt-[calc(40px-32px)]">
        <div className="space-y-3">
          <h4 className="text-2xl font-semibold text-netral-7">Komunikasi</h4>
          <p className="text-netral-6">
            Daftar kursus yang sudah pernah dibuat
          </p>
        </div>
        <p className="text-xl font-semibold text-netral-6">coming soon</p>
        {/* <Link to="/instructor/communication/add-announcement" className="inline-block px-3 py-2 rounded-md bg-secondary-button-600 text-white font-semibold">
          Tambah Pengumuman
        </Link>
        <div className="space-y-6">
          <div className="flex justify-end">
            <div className="relative">
              <div className="absolute top-0 left-2 h-full flex items-center">
                <Icon name="filter_list-netral-7" className="w-6 h-6" />
              </div>
              <select className="w-[123px] rounded-xl border border-netral-5 py-[9px] pl-[36px] text-netral-5">
                <option>Filter</option>
              </select>
            </div>
          </div>
          <table className="w-full bg-netral-2/40 rounded-lg">
            <thead>
              <tr className="flex py-3 mx-3 text-sm font-semibold text-netral-10 w-full text-left">
                <th className="w-full">Nama Kelas</th>
                <th className="w-full">Pengumuman</th>
                <th className="w-full">Tanggal</th>
                <th className="">
                  <div className="w-6"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5, 6].map((el, i) => (
                <tr
                  key={i}
                  className="flex text-sm text-netral-9 w-full text-left  border-t border-t-netral-4 mx-3"
                >
                  <td className="w-full py-[10px]">UX Designer</td>
                  <td className="w-full py-[10px]">
                    Lorem ipsum dolor sit amet, consectetur adipisicing ...
                  </td>
                  <td className="w-full py-[10px]">15 Mei 2022</td>
                  <td className="h-full py-[10px] flex items-center">
                    <button className="w-6 h-6">
                      <Icon
                        name="delete_outline-secondary-3-500"
                        className="h-full w-full"
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
        {/* </div> */}
      </div>
    </LayoutDashboard>
  );
};

export default Communication;
