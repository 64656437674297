import moment from "moment";
// import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../components/dependent/learn-course/prev-or-next-button";
// import Quiz from "../../../components/dependent/learn-course/quiz";
// import Finish from "../../../components/dependent/learn-course/quiz/Finish";
// import First from "../../../components/dependent/learn-course/quiz/First";
// import PrevOrNextController from "../../../components/dependent/learn-course/quiz/PrevOrNextController";
import { Icon } from "../../../components/independent/image";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import PinMaps from "../../../components/independent/maps";
// import { postPretestProgress } from "../../../config/redux/actions/courseAction";
import getRangeDate from "../../../constants/getRangeDate";
// import { SET_PRETEST_PROGRESS } from "../../../config/redux/types";

const pointLayout = (num, text) => {
  return (
    <div className="flex gap-[8px]">
      <div className="bg-[#E2F6FF] p-[2px] w-[23px] h-[23px] rounded-[2px] flex items-center justify-center">
        <p className="font-bold text-[#000000]">{num}</p>
      </div>
      <div>
        <p>{text}</p>
      </div>
    </div>
  );
};

const Information = () => {
  const iconList = ["Camera_outline", "Speak", "Mute", "Raise", "Message"];
  const ruleList = [
    "Mengaktifkan kamera",
    "Berpartisipasi aktif pada saat diskusi",
    "Mengatur posisi mic dalam keadaan mute",
    "Menekan tombol Raise hand atau",
    "Menggunakan fitur chat jika ingin bertanya",
  ];
  return (
    <LayoutLearnCourse>
      <div
        style={{
          background:
            "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
        }}
        className=" border border-primary-300 rounded-lg p-[25px]"
      >
        <div className="bg-netral-1 border border-[#E9E9E9] rounded-[8px]">
          <div className="flex gap-[9px] items-center p-[24px]">
            <Icon name={"info"} className="w-[28px] h-[28px]" />
            <p className="text-xl text-netral-10 font-bold">Informasi Utama</p>
          </div>
          <div className="flex gap-[24px] px-[24px] pb-[24px] flex-col">
            <div className="space-y-1">
              <p className="text-xl text-netral-10 font-bold">
                Hi Sobat Skills.ID!
              </p>
              <p className="">
                Berikut ini adalah beberapa syarat, tata tertib & peraturan yang
                harus diikuti ya!
              </p>
            </div>
            <div className="space-y-[8px]">
              <p className="font-semibold text-netral-10 text-lg">Syarat :</p>
              <div className="space-y-[4px]">
                <div>{pointLayout(1, "Laptop yang memadai")}</div>
                <div>{pointLayout(2, "Jaringan internet yang stabil")}</div>
              </div>
            </div>
            <div className="space-y-[8px]">
              <p className="font-semibold text-netral-10 text-lg">
                Tata Tertib & Peraturan :
              </p>
              <div className="bg-[#E2F6FF] w-full flex gap-x-[8px] py-[8px] px-[12px] rounded-[6px]">
                {iconList.map((el, idx) => (
                  <div
                    key={idx}
                    className="bg-[#49B2E4] w-1/5  rounded-[4px] h-[60px] lg:h-[80px] flex items-center justify-center"
                  >
                    <Icon
                      name={el}
                      className=" w-[32px] h-[32px] lg:w-[50px] lg:h-[50px]"
                    />
                  </div>
                ))}
              </div>
              <div className="space-y-[8px]">
                {ruleList.map((el, idx) => (
                  <div key={idx}>{pointLayout(idx + 1, el)}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutLearnCourse>
  );
};

export default Information;
