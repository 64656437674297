import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../config/redux/actions/authAction";
import { SET_LOGIN_MESSAGE } from "../config/redux/types";
import LayoutAuth from "../components/independent/layout/layout-auth";
import AlertTraditional from "../components/independent/alert/alert-traditional";
import InputTextLabel from "../components/independent/input/input-text-label";
import ButtonPrimary from "../components/independent/button/button-primary";
// import SocialMediaButton from "../components/dependent/auth/social-media-button";
import RegisterOrLoginButton from "../components/dependent/auth/register-or-login-button";
import { Icon } from "../components/independent/image";
// import InputCheckboxLabel from "../components/independent/input/input-checkbox-label";

const Login = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { loginLoading, loginMessage, loginStatus } = useSelector(
    ({ authReducer }) => authReducer
  );
  const [form, setform] = useState({ email: "", password: "" });
  const [isRemember, setRemember] = useState(false);

  const onChange = (e) =>
    setform((crr) => ({ ...crr, [e.target.name]: e.target.value }));

  const onSubmit = (e) => {
    e.preventDefault();
    if (loginLoading) return;
    const { email, password } = form;
    dispatch(login({ email, password, isRemember }, (param) => push(param)));
  };

  useEffect(() => {
    return () => {
      setform({ email: "", password: "" });
      setRemember(false);
      dispatch({ type: SET_LOGIN_MESSAGE, payload: "" });
    };
  }, [dispatch]);

  return (
    <LayoutAuth>
      <form onSubmit={(e) => onSubmit(e)} className="w-full">
        {loginMessage && (
          <AlertTraditional status={loginStatus} className="mb-4">
            {loginMessage}
          </AlertTraditional>
        )}
        <h3 className="text-lg lg:text-xl font-semibold">
          Silahkan masuk ke akun Anda
        </h3>
        <div className="mt-4 lg:mt-6 space-y-3 lg:space-y-4">
          <div>
            <InputTextLabel
              id="email"
              name="email"
              text="Email atau Nomor Whatsapp"
              placeholder="Masukkan Email Anda atau Nomor Whatsapp"
              onChange={onChange}
              value={form.email}
            />
            <div className="mt-2 flex items-center gap-[4px]">
              <Icon name={'info_icon'} className="w-[20px] h-[20px]"/>
              <p className='lg:text-base text-sm'>
                Untuk login menggunakan nomor Whatsapp mohon sertakan 62, contoh
                : 628**********
              </p>
            </div>
          </div>

          <InputTextLabel
            isPassword
            id="password"
            name="password"
            text="Password"
            placeholder="Masukkan Password Anda"
            value={form.password}
            onChange={(e) => onChange(e)}
          />
          <div className="flex justify-end mt-2 lg:mt-4">
            <Link
              to="/reset-password"
              className="text-secondary3-500 text-sm lg:text-base"
            >
              Lupa password ?
            </Link>
          </div>
        </div>
        {/* <InputCheckboxLabel
          id="remember"
          name="remember"
          text="Remember me"
          value={isRemember}
          onChange={(e) => setRemember(e.target.checked)}
        /> */}
        <ButtonPrimary type="submit" disable={loginLoading}>
          {!loginLoading ? "Login" : "Loading..."}
        </ButtonPrimary>
        {/* <p className="text-netral-7 my-3 lg:my-8 font-medium">
          Atau masuk dengan
        </p>
        <SocialMediaButton /> */}
        <RegisterOrLoginButton />
      </form>
    </LayoutAuth>
  );
};

export default Login;
