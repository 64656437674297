import getLink from "../../../constants/getLink";
import { _getCourseStudent } from "../../services/api/course";
import {
  _addProgress,
  _deleteVideoSkillTest,
  _posttestProgress,
  _pretestProgress,
  _quizProgress,
  _updateSkilltest,
  _uploadIndividualTest,
  _uploadSkillTest,
} from "../../services/api/courseProgress";
import { SET_COURSE, SET_COURSE_PROGRESS } from "../types";

export const getStudentCourse = (token, courseId, replace) => (dispatch) => {
  _getCourseStudent(token, courseId)
    .then((res) => {
      if (!res.status) {
        replace("/student");
        alert(res.message);
        return;
      }

      dispatch({ type: SET_COURSE, payload: res.class });

      if (!res.class.detailProgress.pretest) {
        const pretest = res.class.enrollment.pretest.map(() => ({
          isCompleted: false,
          record: [],
          score: 0,
          date: "",
          rightAnswer: 0,
          wrongAnswer: 0,
        }));

        res.class.detailProgress.prestest = pretest;
      }

      if (
        !res.class.detailProgress.chapters ||
        !res.class.detailProgress.chapters.length
      ) {
        const chapters = [];

        res.class.enrollment.chapters.forEach((chapter, chapterIndex) => {
          chapter.materials.forEach((material, materialIndex) => {
            chapters.push({
              chapterIndex,
              materialIndex,
              date: "",
              isCompleted: false,
              record: [],
              score: 0,
              type: material.type,
            });
          });
        });

        res.class.detailProgress.chapters = chapters;
      }

      if (!res.class.detailProgress.posttest)
        res.class.detailProgress.posttest = [];

      if (!res.class.detailProgress.skilltest)
        res.class.detailProgress.skilltest = [];

      dispatch({
        type: SET_COURSE_PROGRESS,
        payload: res.class.detailProgress,
      });
    })
    .catch((e) => console.log("error get student course", e));
};

export const postPretestProgress =
  (token, courseId, data) => async (dispatch) => {
    try {
      const result = await _pretestProgress(token, courseId, data);

      if (!result.status) return alert(result.message);

      dispatch(getStudentCourse(token, courseId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    }
  };

export const postMaterialProgress =
  (token, courseId, chapters, data) => async (dispatch) => {
    const targetIndex = chapters.findIndex(
      (el) =>
        el.chapterIndex === data.chapterIndex &&
        el.materialIndex === data.materialIndex
    );

    if (targetIndex !== -1) {
      if (chapters[targetIndex].isCompleted) return;
      chapters[targetIndex] = data;
    } else chapters.push(data);

    try {
      const result = await _addProgress(token, {
        class_id: courseId,
        chapters: chapters,
      });

      // alert(result.message);

      if (!result.status) return;

      dispatch(getStudentCourse(token, courseId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    }
  };

export const postIndividualTest =
  (token, files, chapterIndex, progressId, classId) => async (dispatch) => {
    // (token, id, files, chapterIndex, progressId, classId)

    try {
      const result = await _uploadIndividualTest(
        token,
        files,
        chapterIndex,
        progressId,
        classId
      );

      // alert(result.message);

      if (!result.status) return;

      dispatch(getStudentCourse(token, classId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    }
  };

export const postQuizProgress = (token, courseId, data) => async (dispatch) => {
  try {
    const result = await _quizProgress(token, courseId, data);

    if (result.status) dispatch(getStudentCourse(token, courseId));
  } catch (error) {
    alert("something went wrong");

    console.log(error);
  }
};

export const postPosttestProgress =
  (token, courseId, data) => async (dispatch) => {
    try {
      const result = await _posttestProgress(token, courseId, data);

      if (result.status) dispatch(getStudentCourse(token, courseId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    }
  };

export const postSkilltestProgress =
  (token, courseId, progressId, files, cb) => async (dispatch) => {
    try {
      const upload = await _uploadSkillTest(token, courseId, files);

      // if (!upload.status) return alert(upload.message);

      // skilltest.push(upload.data);

      const date = new Date(Date.now()).toISOString();

      const result = await _updateSkilltest(
        token,
        progressId,
        date,
        upload.data
        // upload.data.map((el) => ({ ...el, link: "" + (el.link) }))
      );

      if (!result.status) return alert(result.message);

      dispatch(getStudentCourse(token, courseId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    } finally {
      cb();
    }
  };

export const deleteVideoSkillTest =
  (token, filename, progressId, courseId) => async (dispatch) => {
    try {
      const res = await _deleteVideoSkillTest(token, filename, progressId);

      if (!res.status) return alert(res.message);

      dispatch(getStudentCourse(token, courseId));
    } catch (error) {
      alert("something went wrong");

      console.log(error);
    }
  };
