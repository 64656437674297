import { useEffect } from "react";
// import Styles from "./pagination.module.scss";
import usePagination, { DOTS } from "./usePagination";

const Pagination = ({
  total,
  selectedPage,
  setSelectedPage,
  totalPage,
  siblingCount = 1,
  ...props
}) => {
  const paginationRange = usePagination({
    selectedPage,
    siblingCount,
    totalPage,
  });

  const onNext = () => {
    if (selectedPage < totalPage) {
      setSelectedPage(selectedPage + 1);
    }
  };

  const onPrevious = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === lastPage;

  return (
    <div>
      <div className="hidden lg:flex w-full justify-between ">
        <div
          onClick={onPrevious}
          className="border rounded px-4 py-2 border-secondary-button-600 cursor-pointer"
        >
          <p className="text-secondary-button-600">Sebelumnya</p>
        </div>
        <div className="flex gap-x-[16px] items-center">
          {paginationRange.map((each, idx) => {
            if (each === DOTS) {
              return <div key={idx}>&#8230;</div>;
            }
            return (
              <p
                key={idx}
                onClick={() => setSelectedPage(each)}
                className={`cursor-pointer ${
                  selectedPage === each
                    ? "py-2 px-3 bg-secondary-button-600 text-netral-1 rounded-lg"
                    : ""
                }`}
              >
                {each}
              </p>
            );
          })}
        </div>
        <div
          onClick={onNext}
          className="border rounded px-4 py-2 border-secondary-button-600 cursor-pointer"
        >
          <p className="text-secondary-button-600">Selanjutnya</p>
        </div>
      </div>
      <div className="block lg:hidden space-y-[16px]">
        <div className="flex gap-x-[16px] items-center w-full justify-center">
          {paginationRange.map((each, idx) => {
            if (each === DOTS) {
              return <div key={idx}>&#8230;</div>;
            }
            return (
              <p
                key={idx}
                onClick={() => setSelectedPage(each)}
                className={`cursor-pointer ${
                  selectedPage === each
                    ? "py-2 px-3 bg-secondary-button-600 text-netral-1 rounded-lg"
                    : ""
                }`}
              >
                {each}
              </p>
            );
          })}
        </div>
        <div className="flex w-full justify-between">
          <div
            onClick={onPrevious}
            className="border rounded px-4 py-2 border-secondary-button-600 cursor-pointer"
          >
            <p className="text-secondary-button-600">Sebelumnya</p>
          </div>

          <div
            onClick={onNext}
            className="border rounded px-4 py-2 border-secondary-button-600 cursor-pointer"
          >
            <p className="text-secondary-button-600">Selanjutnya</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
