import { useRef } from "react";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";
import { Icon, Illustration } from "../../image";

export default function ModalEmailSent({ setModal, register, loading }) {
  const ref = useRef();
  useOutsideClick(ref, () => setModal((crr) => crr && ""));

  return (
    <div ref={ref} className="relative w-full px-4 lg:px-0 flex justify-center">
      <div
        style={{
          boxShadow:
            "0px 8px 16px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
        }}
        className="px-4 lg:px-6 py-6 lg:py-8 rounded-lg bg-white space-y-4 lg:space-y-6 w-full lg:w-[438px]"
      >
        <Illustration
          name="New Messages"
          className="w-[100px] lg:w-[130px] h-[100px] lg:h-[130px] mx-auto"
        />
        <h4 className="text-lg lg:text-xl text-center font-semibold text-netral-10">
          Check Your E-Mail
        </h4>
        <div className="space-y-2 text-center">
          <p className="text-netral-9 text-xs lg:text-sm">
            We already have sent you verification e-mail.
          </p>
          <p className="text-netral-9 text-xs lg:text-sm">Please check it</p>
        </div>
        <div className="flex justify-center items-center space-x-6">
          <button
            type="button"
            disabled={loading}
            onClick={register}
            className={`${
              !loading
                ? "text-secondary-button-600 border border-[#006A9D]"
                : "bg-netral-5 text-netral-6 cursor-not-allowed"
            } w-full font-semibold px-3 py-2 rounded`}
          >
            {loading ? "resending..." : "Resend"}
          </button>
          <button
            onClick={() => setModal("")}
            className="w-full text-netral-1 bg-secondary-button-600 font-semibold px-3 py-2 rounded border border-secondary-button-600"
          >
            Ok
          </button>
        </div>
      </div>
      <button
        onClick={() => setModal("")}
        className="right-8 lg:right-4 top-4 absolute"
      >
        <Icon name="close-netral-10" />
      </button>
    </div>
  );
}
