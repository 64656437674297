import getLink from "../../../../constants/getLink";
import { Avatar, Icon } from "../../image";

export default function Instructor({ data }) {
  const socialMedias = [
    {
      username: data?.instagram,
      icon: "Instagram-colored",
      media: "instagram",
    },
    {
      username: data?.facebook,
      icon: "Facebook-colored",
      media: "facebook",
    },
    {
      username: data?.twitter,
      icon: "Twitter-colored",
      media: "twitter",
    },
    {
      username: data?.linkedin,
      icon: "Linkedin-colored",
      media: "linkedin",
    },
  ];

  // console.log(data)

  return (
    <div className="space-y-6 lg:space-y-8">
      <div className="space-y-2 lg:space-y-4">
        <h5 className="lg:text-xl font-semibold text-netral-9">Instruktur</h5>
        <div className="flex items-center space-x-2 lg:space-x-6">
          <div className="w-14 h-14 lg:w-[79px] lg:h-[79px] rounded-full bg-[#C4C4C4]">
            {/* <Avatar /> */}
            <img
              src={getLink(data.profilePicture)}
              alt={getLink(data.profilePicture)}
              className="w-[79px] h-[79px] rounded-full"
            />
          </div>
          <div className="space-y-2 lg:space-y-3">
            <h3 className="text-lg lg:text-2xl font-semibold text-netral-10">
              {data.name}
            </h3>
            <p className="text-netral-8 font-semibold text-sm lg:text-base">
              {data.job}
            </p>
          </div>
        </div>
        <p className="text-netral-9 whitespace-pre-wrap text-xs lg:text-base">
          {data.about}
          </p>
      </div>
      {/* <div className="space-y-2 lg:space-y-4">
        <h4 className="lg:text-xl font-semibold text-netral-9">Social Media</h4>
        <ul className="space-y-4">
          {socialMedias.map((el, i) => (
            <li key={i} className="flex space-x-3 items-center">
              <Icon name={el.icon} className="w-[22px] h-[22px] lg:w-6 lg:h-6" />
              <p className="text-netral-10 text-sm lg:text-base">{el.username}</p>
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}
