import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PrevOrNextButton from "../../../../components/dependent/new-course/prev-or-next-button";
import { Icon } from "../../../../components/independent/image";
// import InputDropdownLabel2 from "../../../../components/independent/input/input-dropdown-label2";
import LayoutNewCourse from "../../../../components/independent/layout/layout-new-course";
import { SET_COURSE_TYPE } from "../../../../config/redux/types";
import useOutsideClick from "../../../../hooks/lib/useOutsideClick";

const Step4 = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { classType } = useSelector((state) => state.newCourseReducer);
  const [state, setState] = useState(classType || types[0]);

  return (
    <LayoutNewCourse activeTab={4}>
      <div className="mt-12 mb-[244px] px-[264px] space-y-8">
        <div className="space-y-3">
          <h3 className="text-2xl font-medium text-netral-7">Jenis Kursus</h3>
          <p className="text-netral-6">
            Pilih jenis kursus anda, kami menyediakan pilihan kursus online atau
            offline
          </p>
        </div>
        <InputDropdownLabel2
          text="Jenis Kursus"
          value={state}
          setValue={setState}
        />
        <PrevOrNextButton
          active={state !== option[0]}
          prev={() => {
            push(`/new/course/${id}/step3`);
            dispatch({ type: SET_COURSE_TYPE, payload: state });
          }}
          next={() => {
            push(`/new/course/${id}/step5`);
            dispatch({ type: SET_COURSE_TYPE, payload: state });
          }}
        />
      </div>
    </LayoutNewCourse>
  );
};

const InputDropdownLabel2 = ({ text, value, setValue }) => {
  const [isDropdownShown, setDropdownShown] = useState(false);

  const ref = useRef();
  useOutsideClick(ref, () => isDropdownShown && setDropdownShown(false));
  return (
    <div className="space-y-2 w-full">
      <label
        htmlFor="course"
        className="inline-block text-sm text-netral-8 font-semibold"
      >
        {text}
      </label>
      <div className="relative">
        <button
          type="button"
          onClick={() => setDropdownShown(!isDropdownShown)}
          id="course"
          className={`${
            value === option[0] ? "text-netral-5" : "text-netral-10"
          } text-left p-3 rounded-md border border-[#757575] w-full flex items-center justify-between`}
        >
          <span className="whitespace-pre text-sm lg:text-base">
            {types[option.findIndex((el) => el === value)] || types[0]}
          </span>
          <Icon
            name="keyboard_arrow_down-netral-10"
            className="w-[22px] h-[22px]"
          />
        </button>
        {isDropdownShown && (
          <div
            ref={ref}
            style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            className="absolute top-14 left-0 w-full bg-white z-10 p-3 space-y-2"
          >
            {option.map((el, i) => (
              <button
                key={i}
                type="button"
                disabled={i === 0}
                onClick={() => {
                  setDropdownShown(false);
                  setValue(el);
                }}
                className={`${
                  i === 0 ? "text-netral-4" : "text-netral-9"
                } w-full text-left font-semibold hover:text-primary-500`}
              >
                {types[i]}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step4;

const option = ["Pilih Kategori", "Online Class", "Offline Class", "Workshop"];
const types = ["Pilih Kategori", "Video", "Kursus Offline", "Workshop"];
