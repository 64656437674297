export default function Title() {
  return (
    <div className="flex flex-col space-y-2">
      <h4 className="text-xl lg:text-2xl text-netral-10 font-semibold">
        Keranjang
      </h4>
      <p className="text-netral-9 text-sm lg:text-base">
        Silakan selesaikan proses pembelian kelas Anda
      </p>
    </div>
  );
}
