import { Link } from "react-router-dom";
import { BigPlayButton, Player } from "video-react";
import getLink from "../../../../constants/getLink";
import { ClipArt, Icon } from "../../../independent/image";

export default function Hero({ course, isWebinar }) {
  const isPrakerja = course.type?.includes("Prakerja");

  return (
    <section
      style={{
        background:
          "linear-gradient(96.93deg, #033A4F 0%, rgba(3, 58, 79, 0.81) 42.44%, rgba(3, 58, 79, 0.66) 70.99%, rgba(3, 58, 79, 0.6) 100%)",
      }}
      className="min-h-[252px] lg:min-h-[474px] p-4 lg:px-8 lg:py-6 space-y-4 lg:space-y-8"
    >
      <Link to="/" className="p-1 flex items-center space-x-2">
        <Icon name="arrow_back-primary-100" className="w-4 h-4 lg:w-6 lg:h-6" />
        <span className="font-semibold text-primary-100 text-xs lg:text-base">
          Kembali ke Home
        </span>
      </Link>
      <div className="lg:px-[135px] flex justify-between space-x-10">
        {/* 1  */}
        <div className="w-1/2 lg:w-max space-y-1 lg:space-y-4">
          {/* 1  */}
          <div className="flex items-center space-x-2 lg:space-x-4">
            <span className="text-netral-1 text-2xs lg:text-sm px-2 py-1 bg-secondary2-400 rounded">
              {course.level}
            </span>
            <span className="text-netral-10 text-2xs lg:text-sm px-2 py-1 bg-secondary-300 rounded font-semibold">
              {course.classType === "Online Class"
                ? "Video"
                : isWebinar
                ? "Webinar"
                : course?.classType === "Workshop" ? "workshop" : "Kursus Offline"}
            </span>
          </div>
          {/* 2  */}
          <div className="space-y-2 lg:space-y-6">
            <h3 className="lg:text-2xl text-netral-1 font-semibold">
              {course.title}
            </h3>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2 lg:space-x-4">
                <div className="flex space-x-[2px] lg:space-x-2">
                  {[1, 2, 3, 4, 5].map((el) => (
                    <Icon
                      key={el}
                      name="Star-secondary3-200"
                      className="w-4 h-4 lg:w-6 lg:h-6"
                    />
                  ))}
                </div>
                <span className="text-xs lg:text-lg font-semibold text-netral-1">
                  0
                </span>
              </div>
              {isPrakerja && (
                <ClipArt
                  name="label prakerja"
                  className="w-auto h-4 lg:h-[30px]"
                />
              )}
            </div>
          </div>
          {/* 3  */}
          <div className="hidden lg:flex items-center space-x-4">
            <div className="flex items-end space-x-1">
              <h6 className="text-lg font-semibold text-netral-1">0</h6>
              <p className="text-netral-1">Sudah terdaftar</p>
            </div>
            <div className="flex items-end space-x-1">
              <h6 className="text-lg font-semibold text-netral-1">{course.views}</h6>
              <p className="text-netral-1">Views</p>
            </div>
            <div className="flex items-end space-x-1">
              <h6 className="text-lg font-semibold text-netral-1">{course.totalReview}</h6>
              <p className="text-netral-1">Ulasan</p>
            </div>
          </div>
          {/* 4  */}
          <div>
            <div className="space-y-2 mt-4 lg:mt-0">
              <p className="text-sm font-semibold text-netral-1">Instruktur</p>
              <div className="flex items-center space-x-3">
                <div>
                  <div >
                    <img
                      className="flex rounded-full h-[44px] w-[44px] lg:h-[52px] lg:w-[52px]"
                      alt="profile"
                      src={getLink(course?.instructor?.profile_picture)}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <h6 className="font-semibold text-sm lg:text-lg text-netral-1">
                    {course.instructor.name}
                  </h6>
                  <p className="text-netral-1 text-xs lg:text-base">
                    {course.instructor.job}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 2  */}
        <div className="w-1/2 lg:w-auto space-y-3">
          <div
            style={{
              filter: "drop-shadow(4px 8px 16px rgba(0, 0, 0, 0.4))",
            }}
            className="w-full lg:w-[493px] h-[126px] lg:h-[344px] bg-[#0A0A0A99] rounded-lg relative overflow-hidden"
          >
            <Player
              fluid={false}
              width="100%"
              height="100%"
              src={getLink(course.intro)}
              className="absolute top-0 left-0"
            >
              <BigPlayButton
                position="center"
                className="scale-50 lg:scale-100"
              />
            </Player>
          </div>
          <div className="lg:hidden flex items-center justify-between text-white">
            <div className="space-y-1 text-center">
              <p className="text-xs font-semibold">0</p>
              <p className="text-2xs">Terdaftar</p>
            </div>
            <div className="space-y-1 text-center">
              <p className="text-xs font-semibold">0</p>
              <p className="text-2xs">Views</p>
            </div>
            <div className="space-y-1 text-center">
              <p className="text-xs font-semibold">0</p>
              <p className="text-2xs">Ulasan</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
