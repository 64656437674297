import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import LayoutNewCourseStep4 from "../../../../../components/independent/layout/layout-new-course-step4";
import { SET_FINISHED_NAV } from "../../../../../config/redux/types";
import {
  _createCourse,
  _deleteFileFromCloud,
  _updateCourse,
} from "../../../../../config/services/api/course";
import fancyTimeFormat from "../../../../../constants/fancyTimeFormat";
import moment from "moment";

const Review = () => {
  const { datas } = useData();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const newCourse = useSelector((state) => state.newCourseReducer);
  const { token } = useSelector((state) => state.authReducer);
  const [uelas, setUelas] = useState([
    {
      text: "Saya sudah meninjau kembali kelas yang telah dibuat",
      value: false,
    },
    {
      text: "Saya sudah menyetujui segala persyaratan dalam pembuatan kelas",
      value: false,
    },
  ]);

  const deleteFilesFromCloud = async () => {
    if (!newCourse.deleteFiles) return;
    if (!newCourse.deleteFiles.length) return;

    const files = newCourse.deleteFiles.map((el) =>
      _deleteFileFromCloud(token, el)
    );
    try {
      const results = await Promise.all(files);
      console.log("deleted", results);
    } catch (error) {
      console.log("error delete files", error);
    }
  };

  const save = () => {
    newCourse.homepage.thumbnail.storage = "cloud";
    newCourse.homepage.intro.storage = "cloud";
    newCourse.skillTest.storage = "cloud";
    newCourse.curriculum.forEach((chapter) => {
      chapter.materials.forEach((material) => {
        if (material.lesson?.file) material.lesson.file.storage = "cloud";
        if (material.lesson?.additionalFile)
          material.lesson.additionalFile.storage = "cloud";
      });
    });

    dispatch({ type: SET_FINISHED_NAV, payload: "review" });

    const { instructor_id, deleteFiles, ...data } = newCourse;

    if (!newCourse._id) {
      _createCourse(data, token)
        .then((res) => {
          if (!res.status) return alert("something went wrong", res);
          deleteFilesFromCloud();
          push("/instructor");
        })
        .catch((e) => console.log("error", e));
    } else {
      _updateCourse(token, newCourse._id, data).then((res) => {
        if (!res.status) return alert("something went wrong", res);
        deleteFilesFromCloud();
        push("/instructor");
      });
    }
  };

  const active = () => {
    if (uelas.some((uela) => !uela.value)) return false;
    if (newCourse.classType === "Online Class" && newCourse.finished.length < 8)
      return false;
    if (
      newCourse.classType === "Offline Class" &&
      newCourse.finished.length < 6
    )
      return false;
    return true;
  };

  const targetParticipants = newCourse.targetParticipants.map((target) => ({
    name: target.title,
    datas: target.inputs.map((input) => ({
      name: input.value,
    })),
  }));

  return (
    <LayoutNewCourseStep4 title="Review" subTitle="Tetapkan judul kursus Anda">
      <div className="space-y-6 pb-3 border-b border-netral-5">
        {(newCourse.classType === "Offline Class" ||
          newCourse.classType === "Workshop") && (
          <div>
            <div className="space-y-4 py-3">
              <h4 className="text-xl text-netral-10">Sasaran Peserta</h4>
              <div className="space-y-3">
                <p className="text-netral-10 font-semibold ml-16">
                  Tujuan Pelatihan/Webinar
                </p>
                {targetParticipants.map((data2, i2) => (
                  <div
                    key={i2}
                    className={`ml-16 space-y-2 ${i2 < 2 && "pl-6"}`}
                  >
                    <p className="text-netral-10 font-semibold">{data2.name}</p>
                    <div className="space-y-1">
                      {data2.datas.map((data3, i3) => (
                        <div key={i3} className="ml-6">
                          <p className="text-sm text-netral-10">{data3.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="space-y-4 py-3">
              <h4 className="text-xl text-netral-10">Jadwal Kursus</h4>
              <div>
                <p className="text-netral-8 text-xs">Alamat Kursus</p>
                <p className="text-netral-10 text-sm">
                  {newCourse.schedule[0]?.address}
                </p>
              </div>
              <div className="max-w-full">
                <p className="text-netral-8 text-xs">Titik Lokasi</p>
                <p className="text-netral-10 text-sm">
                  {newCourse.schedule[0]?.locationUrl}
                </p>
              </div>
              <table className="w-full">
                <thead>
                  <tr className="flex px-2 py-3 bg-netral-2">
                    <th>
                      <p className="w-10 text-sm font-semibold text-netral-10 text-left">
                        No
                      </p>
                    </th>
                    <th className="">
                      <p className="w-[300px] text-sm font-semibold text-netral-10 text-left">
                        Tanggal Mulai
                      </p>
                    </th>
                    <th className="">
                      <p className="w-[300px] text-sm font-semibold text-netral-10 text-left">
                        Tanggal Berakhir
                      </p>
                    </th>
                    <th>
                      <p className="w-[200px] text-sm font-semibold text-netral-10 text-left">
                        Jam Mulai
                      </p>
                    </th>
                    <th>
                      <p className="w-[200px] text-sm font-semibold text-netral-10 text-left">
                        Jam Berakhir
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newCourse.schedule.map((data, scheduleIndex) => (
                    <tr key={scheduleIndex} className="flex px-2 py-3">
                      <td>
                        <p className="w-10 text-sm text-netral-10">
                          {scheduleIndex + 1}
                        </p>
                      </td>
                      <td>
                        <p className="w-[300px] text-sm text-netral-10">
                          {moment(data.dateStart).format("LL")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[300px] text-sm text-netral-10">
                          {moment(data.dateEnd).format("LL")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[200px] text-sm text-netral-10">
                          {moment(data.dateStart).format("LT")}
                        </p>
                      </td>
                      <td>
                        <p className="w-[200px] text-sm text-netral-10">
                          {moment(data.dateEnd).format("LT")}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {newCourse.classType === "Online Class" &&
          datas.map((data1, i1) => (
            <div key={i1} className="space-y-4">
              <h4 className="text-xl text-netral-10">{data1.name}</h4>
              <div className="space-y-3">
                {data1.datas.map((data2, i2) => (
                  <div key={i2} className="ml-16 space-y-2">
                    <p className="text-netral-10 font-semibold">{data2.name}</p>
                    <div className="space-y-1">
                      {data2.datas.map((data3, i3) => (
                        <div key={i3} className="ml-6">
                          <p className="text-sm text-netral-10">{data3.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className="mt-8 space-y-6">
        {uelas.map((uela, i) => (
          <div key={i} className="p-[2px] space-x-[10px] flex items-center">
            <input
              id={"uela" + i}
              type="checkbox"
              className="w-8 h-8"
              value={uela.value}
              onChange={(e) => {
                uelas[i].value = e.target.checked;
                setUelas([...uelas]);
              }}
            />
            <label htmlFor={"uela" + i} className="text-netral-10">
              {uela.text}
            </label>
          </div>
        ))}
      </div>
      <div className="my-[112px] flex items-center space-x-6">
        <button
          type="button"
          onClick={save}
          disabled={!active()}
          className={`${
            active()
              ? "bg-secondary-button-600 interactive-button-color"
              : "bg-netral-5 cursor-not-allowed"
          } text-white px-3 py-2 rounded border border-transparent`}
        >
          Terbitkan Kelas
        </button>
      </div>
    </LayoutNewCourseStep4>
  );
};

export default Review;

const useData = () => {
  const newCourse = useSelector((state) => state.newCourseReducer);
  const targetParticipants = newCourse.targetParticipants.map((target) => ({
    name: target.title,
    datas: target.inputs.map((input) => ({
      name: input.value,
    })),
  }));
  const curriculum = [
    // {
    //   name: "Total Durasi",
    //   datas: [
    //     {
    //       name: formatTime(
    //         newCourse.curriculum
    //           .map((chapter) =>
    //             chapter.materials.map(
    //               (material) => material.lesson?.file?.duration || 0
    //             )
    //           )
    //           ?.flat()
    //           ?.reduce((prev, crr) => prev + crr)
    //       ),
    //     },
    //   ],
    // },
    ...newCourse.curriculum.map((chapter) => ({
      name: chapter.title,
      datas: chapter.materials.map((material) => {
        const data = { name: "" };
        if (material.lesson) {
          let info = "";
          const fileType = material.lesson.file.type.split("/")[1];
          if (fileType === "pdf") info = "Pdf File";
          if (fileType === "mp4")
            info = fancyTimeFormat(material.lesson.file?.duration || 0);
          data.name = `${material.lesson.title} (${info})`;
        }
        if (material.quiz)
          data.name = `${material.quiz.title} (${material.quiz.questions.length} Soal)`;
        return data;
      }),
    })),
  ];

  // if (!curriculum.length)
  //   curriculum.push([...curriculum, { name: "tes", datas: [{ name: "" }] }]);

  const groupOfQuestion = newCourse.groupOfQuestion.map((question, i) => ({
    name: `${i + 1}. ${question.name}`,
    datas: [],
  }));

  const interactionLink = [
    {
      name: `${newCourse.interactionLink.length} Link Interaksi`,
      datas: [],
    },
  ];

  const skillTest = [
    {
      name: `${newCourse.skillTest.name} (${fancyTimeFormat(
        newCourse.skillTest.duration
      )})`,
      datas: [],
    },
  ];

  const setting = newCourse.setting.map((el) => {
    const data = {
      name: el.name,
      datas: [],
    };
    if (!el.value) delete data.name;
    return data;
  });

  const pricing = [
    {
      name: `Yang Anda dapatkan dari kursus ini adalah IDR ${(
        newCourse.pricing.price * newCourse.pricing.profit
      ).toLocaleString("id-ID")}`,
      datas: [],
    },
  ];

  const datas = [
    { name: "Sasaran Peserta", datas: targetParticipants },
    { name: "Kurikulum", datas: curriculum },
    { name: "Bank Soal", datas: groupOfQuestion },
    { name: "Link Interaksi", datas: interactionLink },
    { name: "Uji Keterampilan", datas: skillTest },
    { name: "Aturan Kelas", datas: setting },
    { name: "Penetapan Harga", datas: pricing },
  ];
  return { datas };
};

// function formatTime(str) {
//   const time = fancyTimeFormat(str);
//   let result = [];
//   const timeArr = time.split(":");
//   for (let i = 0; i < timeArr.length; i++) {
//     if (i === 0) result.push((timeArr[i] += " Jam"));
//     if (i === 1) result.push(timeArr[i] + " Menit");
//     if (i === 2) result.push(timeArr[i] + " Detik");
//   }

//   return result.join(" ");
// }
