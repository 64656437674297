import { Icon } from "../../../independent/image";

export default function PrevOrNextController({
  activeView,
  setActiveView,
  views,
  addProgress,
  isCompleted,
}) {
  return (
    <>
      <button
        type="button"
        disabled={activeView < 2}
        onClick={() => {
          if (activeView < 2) return;
          setActiveView(activeView - 1);
        }}
        style={{ filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16))" }}
        className="absolute left-3 bottom-3 lg:bottom-1/2 lg:left-6 py-2 lg:py-[18px] bg-netral-2 rounded"
      >
        <Icon name="keyboard_arrow_left-netral-10" className="w-5 h-5 lg:w-6 lg:h-6" />
      </button>
      <button
        type="button"
        disabled={activeView >= views.length - 1}
        onClick={() => {
          if (activeView >= views.length - 1) return;
          if (activeView >= views.length - 2) {
            if (!isCompleted) return alert("Anda belum menyelesaikan kuis!");
            addProgress();
          }
          setActiveView(activeView + 1);
        }}
        style={{ filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16))" }}
        className="absolute right-3 bottom-3 lg:bottom-1/2 lg:right-6 py-2 lg:py-[18px] bg-netral-2 rounded"
      >
        <Icon
          name="keyboard_arrow_left-netral-10"
          className="w-5 h-5 lg:w-6 lg:h-6 rotate-180"
        />
      </button>
    </>
  );
}
