export default function BottomNavMobile({ action, price, isVerified }) {
  return (
    <div className="lg:hidden bg-netral-2 w-full px-4 py-6 flex items-center justify-between">
      <div className="space-y-3">
        <p className="text-netral-9">Total Harga</p>
        <h4 className="text-netral-10 font-semibold text-xl">IDR {price}</h4>
      </div>
      <div className="flex items-center space-x-2">
        <button
          type="button"
          // onClick={() => addToCart(courseId)}
          className="p1 rounded text-secondary-button-600 font-semibold"
        >
          Batal
        </button>
        <button
          type="button"
          onClick={action}
          className="inline-block text-center text-white font-semibold px-3 py-2 rounded bg-secondary-button-600"
        >
          {!isVerified ? "Ke Pembayaran" : "Bayar Sekarang"}
        </button>
      </div>
    </div>
  );
}
