import { Icon } from "../../../independent/image"

export default function PrevOrNextButton({ prev, next, active }) {
  const color = active ? "text-primary-500" : "text-netral-5";
  const icon = active
    ? "arrow_right_alt-primary-500"
    : "arrow_right_alt-netral-5";

  return (
    <div className="flex items-center justify-between">
      <button
        type="button"
        onClick={prev}
        className="p-1 space-x-2 flex items-centerd"
      >
        <Icon
          name="arrow_right_alt-primary-500"
          className="w-6 h-6 rotate-180"
        />
        <span className="text-primary-500 font-semibold">Sebelumnya</span>
      </button>
      <button
        type="button"
        disabled={!active}
        onClick={next}
        className="p-1 space-x-2 flex items-centerd"
      >
        <span className={`${color} font-semibold`}>Selanjutnya</span>
        <Icon name={icon} className="w-6 h-6" />
      </button>
    </div>
  );
}