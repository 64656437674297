import { Icon } from "../../independent/image";

export default function SaveToDraft (){
    return (
        <div className="flex items-center px-[32px]  lg:sticky top-0 : max-[430px]:pl-5 mb-10 md:mb-0 justify-between gap-x-[170px]">
        <div
          className="flex gap-1 items-center cursor-pointer pb-[40px] "
          // onClick={() => push("/instructor")}

          // save to draft function
          // onClick={{ }}
        >
          <Icon name="keyboard_arrow_left_gradient" w={20} h={20} />
          <span
            style={{
              background:
                "linear-gradient(137deg, #9360A8 24.99%, #46B7E9 125.87%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="cursor-pointer text-[1rem] leading-[20px] font-bold  "
          >
            Simpan Draft
          </span>
        </div>
      </div>
    )
}