import { Link } from "react-router-dom";
import { Icon } from "../../../independent/image";

export default function Category() {
  return (
    <section className="mt-6 lg:mt-[calc(7rem-2rem)] max-w-[1536px] mx-auto 2xl:px-0">
      <div className="text-netral-10 pl-4 pr-4 lg:pl-3 lg:pr-[51px]">
        <h2 className="text-lg lg:text-2xl font-semibold lg:leading-[40px]">
          Kategori Kelas
        </h2>
        <div className="mt-2 lg:mt-3 flex items-center justify-between">
          <p className="text-xs lg:text-base">
            Kategori kursus paling banyak dibeli
          </p>
          <Link
            to="/course"
            className="text-xs lg:text-base text-primary2-500 font-semibold space-x-1 lg:space-x-[10px] flex items-center"
          >
            <span>Lihat semua</span>
            <Icon name="arrow_right_alt-primary-2-500" />
          </Link>
        </div>
      </div>
      <div className="flex space-x-[10px] lg:space-x-6 mt-8 lg:px-[60px] px-4">
        {categories.map((category, i) => (
          <Link
            to={category.link}
            key={i}
            className="p-4 space-y-4 flex flex-col justify-center items-center bg-netral-2 border border-primary-100 rounded-lg w-full"
          >
            <Icon
              name={category.icon}
              className="w-12 h-12 lg:h-[64px] lg:w-[64px] text-primary-800"
            />
            <p className="text-xs lg:text-lg font-semibold text-netral-10 text-center">
              {category.name}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
}

const categories = [
  { name: "Design", icon: "art tools", link: "/course?category=Design&" },
  {
    name: "Communication",
    icon: "Communication",
    link: "/course?category=Communication",
  },
  { name: "Business", icon: "business", link: "/course?category=Bisnis&" },
  { name: "Marketing", icon: "Marketing", link: "/course?category=Marketing&" },
];
