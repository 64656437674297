export default function Quiz({ views, activeView, handleData, value }) {
  const totalQuiz = views.length - 2;
  return (
    <div className="w-full min-h-full p-3 lg:py-10 lg:px-16 space-y-2 lg:space-y-4">
      <h5 className="lg:text-lg font-semibold pb-2 lg:pb-4 border-b lg:border-b-2 border-netral-6">
        Soal {activeView} dari {totalQuiz}
      </h5>
      <p className="text-xs lg:text-base  whitespace-pre-wrap">
        {views[activeView]?.name}
      </p>
      <div className="flex items-center px-7 flex-wrap lg:flex-nowrap lg:space-x-6 space-y-2 ">
        {views[activeView]?.multipleChoice.map(
          (choice, choiceIndex) =>
            choice !== "" && (
              <div
                key={choiceIndex}
                className="space-x-2 flex items-center w-full"
              >
                <input
                  id={"ch1-mt1-choice1" + choiceIndex + 1}
                  checked={value === choiceIndex + 1}
                  name="choice"
                  type="radio"
                  value={choiceIndex + 1}
                  onChange={({ target }) =>
                    handleData(
                      Number(target.value),
                      views[activeView].questionIndex
                    )
                  }
                  className="w-5 h-5 lg:w-8 lg:h-8 accent-primary-600"
                />
                <label
                  htmlFor={"ch1-mt1-choice1" + choiceIndex + 1}
                  className="text-netral-8 p-[2px] text-xs lg:text-base"
                >
                  {choice}
                </label>
              </div>
            )
        )}
      </div>
    </div>
  );
}

// choice index
