import { useCurriculum } from "../../../../pages/new/webinar/[webinar-id]/step5/Curriculum";
import { Icon } from "../../../independent/image";

export default function Review({ chapterIndex, materialIndex }) {
  const { chapters, setChapters } = useCurriculum();

  const quiz = chapters[chapterIndex].materials[materialIndex].quiz;
  const lesson = chapters[chapterIndex].materials[materialIndex].lesson;

  const previewInfo = () => {
    if (lesson) {
      const fileType = lesson.file.type.split("/")[1];
      const info = { title: lesson.title, icon: "", text: "" };
      if (fileType === "pdf") {
        info.icon = "insert_drive_file-netral-1";
        info.text = "Document";
      }
      if (fileType === "mp4") {
        info.icon = "slow_motion_video-netral-1";
        info.text = "01:00";
      }
      return info;
    }
    if (quiz) {
      const questionLength =
        chapters[chapterIndex].materials[materialIndex].quiz.questions.length;
      return {
        title: quiz.title,
        icon: "format_list_numbered-netral-1",
        text: `${questionLength} Pertanyaan`,
      };
    }
  };

  const updateMaterial = () => {
    let updateFrom = "";
    if (chapters[chapterIndex].materials[materialIndex].lesson)
      updateFrom = "FormLesson";
    if (chapters[chapterIndex].materials[materialIndex].quiz)
      updateFrom = "FormQuiz";
    chapters[chapterIndex].materials[materialIndex].view = updateFrom;
    setChapters([...chapters]);
  };

  const deleteMaterial = () => {
    chapters[chapterIndex].materials.length > 1
      ? chapters[chapterIndex].materials.splice(materialIndex, 1)
      : chapters.splice(chapterIndex, 1);
    setChapters([...chapters]);
  };

  return (
    <div className="rounded p-6 bg-primary-100/40 border border-netral-6 flex items-center justify-between">
      <div className="flex space-x-4">
        <div className="w-[68px] h-[68px] grid place-items-center rounded bg-primary-700">
          <Icon name={previewInfo().icon} className="w-[36px] h-[36px]" />
        </div>
        <div className="space-y-4">
          <h5 className="text-xl font-semibold text-netral-9">
            {previewInfo()?.title}
          </h5>
          <p className="text-netral-7">{previewInfo().text}</p>
        </div>
      </div>
      <div className="flex space-x-5">
        <button
          type="button"
          onClick={deleteMaterial}
          className="p-1 flex items-center space-x-2"
        >
          <Icon name="delete_outline-secondary-3-500" className="w-6 h-6" />
          <p className="text-secondary3-500 font-semibold">Hapus</p>
        </button>
        <button
          type="button"
          onClick={updateMaterial}
          className="p-1 flex items-center space-x-2"
        >
          <Icon name="edit-netral-9" className="w-6 h-6" />
          <p className="text-netral-10 font-semibold">Edit</p>
        </button>
      </div>
    </div>
  );
}
