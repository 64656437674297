import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { _getCourse } from "../../../config/services/api/course";
import Header from "../../../components/independent/header/header";
import NavHeader from "../../../components/independent/navigation/nav-header";
import Footer from "../../../components/independent/footer/footer";
import Hero from "../../../components/dependent/course-detail/hero";
import Facility from "../../../components/dependent/course-detail/facility";
import Overview from "../../../components/dependent/course-detail/overview";
import Syllabus from "../../../components/dependent/course-detail/syllabus";
import Tools from "../../../components/dependent/course-detail/tools";
import Reviews from "../../../components/dependent/course-detail/reviews";
import OrderSummary from "../../../components/dependent/course-detail/order-summary";
import Instructor from "../../../components/dependent/course-detail/instructor";
import Tabs from "../../../components/independent/tabs/tabs";
import BottomNavMobile from "../../../components/dependent/course-detail/bottom-nav-mobile";
import ClaimVoucherMobile from "../../../components/dependent/course-detail/claim-voucher-mobile";
import PrakerjaOrderSummary from "../../../components/dependent/course-detail/prakerja-order-summary";
import CourseLocation from "../../../components/dependent/course-detail/course-location";

// import fancyTimeFormat from "../../../constants/fancyTimeFormat";

// import OtherCourse from "../../../components/organism/OtherCourse";

const CourseDetail = () => {
  const { courseId } = useParams();

  const tabs = ["Overview", "Instruktur", "Silabus", "Tools", "Ulasan"];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [course, setCourse] = useState({
    instructor: {},
  });

  useEffect(() => {
    _getCourse(courseId)
      .then((res) => setCourse(res.class))
      .catch((e) => console.log("error getCourse", e));
  }, [courseId]);

  return (
    <>
      <Header className="fixed top-0" isBlue>
        <NavHeader isBlue />
      </Header>
      <main className="pb-[200px] lg:pb-0 pt-[68px] lg:pt-[82px]">
        <Hero course={course} />
        <Facility
          isOffline={course.classType}
          isPrakerja={course.type === "Prakerja"}
        />
        <section className="mt-4 lg:mt-8 px-4 lg:px-[135px] space-y-6 lg:space-y-0 lg:flex lg:space-x-6">
          <div className="lg:w-2/3">
            <div className="bg-netral-2/50 rounded-lg p-4 space-y-3 lg:space-y-6">
              <Tabs tabs={tabs} active={activeTab} setActive={setActiveTab} />
              <div className={`${activeTab === "Overview" ? 'block' : 'hidden'}`}>
              </div>
              {activeTab === "Overview" && course.overview && (
                <Overview data={course.overview} />
              )}
              {activeTab === "Instruktur" && (
                <Instructor data={course?.instructor} />
              )}
              {activeTab === "Silabus" && <Syllabus data={course?.syllabus} />}
              {activeTab === "Tools" && <Tools />}
              {activeTab === "Ulasan" && <Reviews data={course?.review} />}
            </div>
          </div>
          <aside className="hidden lg:block lg:w-1/3">
            {course?.type === "Prakerja" ? (
              <PrakerjaOrderSummary course={course} />
            ) : (
              <OrderSummary course={course} />
            )}
            {course?.classType !== "video" && (
              <div className="pt-6">
                <CourseLocation course={course} />
              </div>
            )}
          </aside>
          <aside className=" lg:hidden lg:w-1/3">
            {course?.type === "Prakerja" ? (
              <ClaimVoucherMobile data={course} />
            ) : (
              <OrderSummary course={course} />
            )}
          </aside>
        </section>
        {/* <section className="mt-20">
          <OtherCourse />
        </section> */}
      </main>
      <Footer className="hidden lg:block mt-20" />
      <BottomNavMobile id={courseId} price={course.price} data={course}/>
    </>
  );
};

export default CourseDetail;
