import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../../../independent/image";

export default function SearchInput() {
  const [input, setInput] = useState("");
  const { push } = useHistory();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        push(`/course?dasar=${input}`);
      }}
      className="relative"
    >
      <input
        type="search"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Temukan kursus"
        style={{ boxShadow: "1px 4px 30px rgba(0, 0, 0, 0.3)" }}
        className="border pl-12 p-4 rounded-lg w-full text-base bg-white/50"
      />
      <button className="absolute top-0 left-4 h-full flex items-center">
        <Icon name="search-netral-6" />
      </button>
    </form>
  );
}
