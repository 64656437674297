import { useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonBack from "../components/independent/button/button-back";
import { Icon, Illustration } from "../components/independent/image";
import LayoutHome from "../components/independent/layout/layout-home";

const Faq = () => {
  const { push } = useHistory();
  const [activeTitle, setActiveTitle] = useState("");

  const toggleQuestion = (title) => {
    activeTitle === title ? setActiveTitle("") : setActiveTitle(title);
  };

  console.log(data);

  return (
    <LayoutHome>
      <div className="pt-[72px] lg:pt-[82px] pb-[112px] px-4 lg:px-8">
        <ButtonBack type="button" onClick={() => push("/")} m="mt-8">
          Kembali
        </ButtonBack>
        <div className="mt-8 px-6 py-4 flex space-x-2 lg:space-x-6 items-center bg-primary-900 rounded">
          <div>
            <Illustration
              name="Question"
              className="lg:w-[101px] h-[106px] lg:h-[142px] object-contain"
            />
          </div>
          <div className="w-full space-y-2 lg:space-y-3">
            <h4 className="text-lg lg:text-2xl font-semibold text-netral-1">
              Frequently Asked Question
            </h4>
            <p className="text-netral-1 text-xs lg:text-base">
              Untuk memudahkan para Sobat Skills.ID, disini kami sudah merangkum
              beberapa pertanyaan yang sering ditanyakan. Kami berharap FAQ ini
              bisa membantu Sobat Skills dalam menjawab pertanyaan nya.
            </p>
          </div>
        </div>
        <div className="mt-8 lg:mt-14 space-y-6 w-full">
          {data.map((el, i) => (
            <div className="space-y-3" key={i}>
              <button
                type="button"
                onClick={() => toggleQuestion(el.title)}
                style={{ boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.08)" }}
                className="w-full flex items-center space-x-2 justify-between p-2 lg:px-6 lg:py-4 bg-netral-2 rounded"
              >
                <h4 className="lg:text-xl text-netral-10 font-semibold text-left">
                  {el.title}
                </h4>
                <Icon
                  name="keyboard_arrow_down-netral-10"
                  className={`${
                    activeTitle === el.title && "rotate-180"
                  } w-[18px] lg:w-8 h-[18px] lg:h-8 duration-100`}
                />
              </button>
              {activeTitle === el.title && (
                <div>
                  <div className="space-y-6 px-2 lg:px-6">
                    <p className="text-netral-10 text-xs lg:text-base whitespace-pre-line">
                      {el.body}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </LayoutHome>
  );
};

export default Faq;

const data = [
  {
    title: "Bagaimana cara melakukan pembelian Pelatihan Kelas Prakerja ?",
    body: `Berikut langkah-langkah pembelian Pelatihan Kelas Prakerja
    - Kunjungi Digital platform seperti Tokopedia,PINTAR atau Bukalapak
    - Ketik "Kartu Prakerja" di kolom pencarian lalu pilih Lembaga Pelatihan “Skills.id”
    - Pilih pelatihan yang diminati kemudian pilih jadwal serta lokasi yang tersedia.
    - Masukan 16 digit nomor Kartu Prakerja dan lakukan verifikasi melalui SMS
    - Setelah transaksi berhasil, cek redemption code pada email peserta kartu prakerja atau dashboard kartu prakerja
    - akukan penukaran kode voucher dan redeem code di laman Skills.id
    - Setelah berhasil login di Skills.id, pada menu Beranda silakan masukkan kode voucher yang didapatkan dari Mitra Platform Prakerja pada kolom yang sudah disediakan, lalu klik Tukarkan sekarang
    - Lalu pilih jadwal pelatihan sesuai dengan yang kamu pilih pada platform digital (Tokopedia, Bukalapak, atau PINTAR) lalu tukarkan kode voucher
    - Akan muncul kolom kode redeem, silakan diinput dan “Redeem Sekarang”. Selesai!
    - Kelas akan dibuka 1 jam sebelum jadwal pelatihan dimulai.
      `,
  },
  {
    title: "Cara melihat kode voucher untuk peserta kartu Prakerja",
    body: `Berikut Langkah – Langkah untuk melihat kode voucher yang sudah kamu beli di Platform digital :
     Untuk melihat Kode Voucher Dari Tokopedia:
    
     1. Masuk ke aplikasi Tokopedia 
     2. Klik “Daftar Transaksi”
     3. Klik “Lihat Detail Transaksi”
     Kode voucher akan ada di detail pembelian.  
     Atau kamu juga bisa cek kode vouchernya di email yang dikirimkan oleh pihak Tokopedia.
    
     Untuk Melihat Kode Voucher Dari Bukalapak:
     1. Masuk ke aplikasi Bukalapak 
     2. Klik menu “ Transaksi”
     3. Klik Transaksi pembelian kelas Skills.id
     3. Scroll ke bawah dan cari kode kupon`,
  },
  {
    title: "Cara mengakses dan menyelesaikan pelatihan yang sudah dibeli :",
    body: (
      <div>
        <p>
          Untuk mengikuti pelatihan di Skills.ID kamu bisa mengikuti Langkah -
          Langkah berikut :
        </p>
        <p></p>
        <p>
          1.⁠ ⁠Silahkan klik link:{" "}
          <a
            href="https://skills.id/register"
            className="text-primary-600 underline"
          >
            https://skills.id/register
          </a>{" "}
          untuk melakukan registrasi akun di{" "}
          <a href="https://skills.id" className="text-primary-600 underline">
            skills.id
          </a>{" "}
          . Isi semua data dengan benar.
        </p>
        <p>
          2.⁠ ⁠Cek nomor whatsapp untuk kode OTP dan verifikasi akun. Lalu
          masukkan username dan password kamu, klik tombol log in.
        </p>
        <p>
          3.⁠ ⁠Setelah berhasil Log in, segera tukarkan kode voucher kamu di
          kolom yang telah disediakan. Kemudian klik tombol "Tukarkan Sekarang".
        </p>
        <p>
          4.⁠ ⁠Masukan kode voucher dari Mitra platform seperti Tokopedia,
          Pintar dan Bukalapak di kolom laman awal Skills.ID, lalu klik
          “Tukarkan sekarang” .
        </p>
        <p>
          5.⁠ ⁠Lalu pilih jadwal pelatihan sesuai dengan jadwal yang kamu pilih
          di Mitra Platform dan klik “Tukarkan kode Voucher”
        </p>
        <p>
          6.⁠ ⁠Lalu kamu akan diarahkan kehalaman kelas saya klik “Lanjutkan
          belajar” dan akan muncul permintaan untuk memasukan kode redeem yang
          bisa kamu temukan di dashboard prakerja dan klik “Redeem sekarang”.
        </p>
        <p>
          7.⁠ ⁠Setelah penukaran kode redeem berhasil dilakukan, kamu akan
          diarahkan untuk login ke akun prakerjamu untuk melakukan scan wajah,
          pastikan mengikuti instruksinya ya!
        </p>
        <p>
          Selesai, pelatihan bisa dimulai sesuai jadwal yang dipilih. Selamat
          mengikuti Pelatihan mu!
        </p>
        <p>
          Atau kamu bisa melihat video Langkah-langkah penukarannya melalui link
          berikut :{" "}
          <a
            className="text-primary-600 underline"
            href="https://bit.ly/Penukaranskillsxprakerja"
          >
            https://bit.ly/Penukaranskillsxprakerja
          </a>
        </p>
      </div>
    ),
  },
  {
    title: "Bagaimana cara mendapatkan Redeem code ?",
    body: `
    Cara mendapatkan kode redeem kamu bisa log in ke akun Prakerjamu lalu klik menu “Pelatihan” dan lihat “Riwayat pelatihan” lalu temukan kode redeem. Setelah itu input di kolom Redeem Code dan kamu berhasil menukarkan pelatihan mu. Pelatihan akan di buka 1 jam sebelum jadwal pelatihan kamu dimulai. 

    Untuk kelas luring kamu wajib melakukan scan QR code pada hari pertama pelatihan dan dilakukan langsung di lokasi pelatihan.

    Pelatihan akan dimulai dengan mengerjakan pretest dan diakhiri dengan pengerjaan uji keterampilan. Untuk kelas daring kehadiran sudah mencapai 80% dan kelas luring/ offline harus 100% untuk pelatihan dapat diselesaikan. 

    `,
  },
  {
    title: "Cara mengakses kelas, setelah berhasil penukaran :",
    body: `
    Untuk mengikuti pelatihan yang sudah berhasil kamu tukarkan, kamu bisa mengikuti Langkah- Langkah berikut :
    Klik Menu (=) > Nama > dashboard> kelas saya> Lanjutkan belajar > klik sesi / jadwal dan link zoom untuk mengikuti kelas zoom. Pastikan untuk mengikuti semua jadwal pelatihan untuk bisa menyelesaikan pelatihan dan mendapatkan insentif Prakerja. Jika ada kendala bisa langsung menghubungi Customer Service Skills.id
    `,
  },
  {
    title:
      "Jika saya tidak bisa mengikuti salah satu hari pelatihan, apakah boleh melakukan perubahan jadwal?",
    body: `
    Pelatihan luring wajib diikuti pada hari pertama dan hari kedua. Perubahan jadwal hanya boleh dilakukan maksimal H-3 sebelum hari pertama pelatihan dimulai. Permintaan perubahan jadwal bisa dilakukan melalui customer service Skills.id.

    Untuk terhubung dengan Customer service Skills setelah melakukan pembelian kelas di Skills.id, bisa dengan mengklik simbol Whatsapp yang ada di website Skills.id
    `,
  },
  {
    title: "Bagaimana cara cek invoice pelatihan yang sudah saya beli ?",
    body: `
    Untuk cek invoice, Silakan buka website Skills.ID => login => menu => Dashboard=> Transaksi. Jika setelah redeem, informasi transaksi tidak muncul, mohon segera hubungi kami di nomor whatsApp 08118286996 atau bit.ly/skills.id

    `,
  },
  {
    title:
      "Saya sudah melakukan pembelian kelas dan saldo Prakerja sudah terpotong, namun saya belum mendapatkan kode voucher.",
    body: `
    Kode voucher telah dikirim ke email Anda yang terdaftar di akun Mitra Platform Prakerja (Bukalapak, Tokopedia atau Pintaria) pastikan cek email bagian inbox, spam dan promosi. Jika Anda mendaftar menggunakan nomor handphone, silakan cek apakah sudah mendapatkan SMS kode voucher dari Mitra Platform Prakerja. Selain itu, Anda juga bisa cek kode voucher melalui menu Riwayat transaksi berhasil pada akun Mitra Platform Anda.
    Setelah mendapatkan kode voucher dan sudah dipastikan bahwa kelas yang Anda beli merupakan kelas dari Skills.id, silakan redeem dengan cara:
    
    1. Buka website skills.id
    2. Pilih Login apabila sebelumnya sudah mendaftar akun Skills.id atau pilih Daftar sekarang jika sebelumnya Anda belum pernah mendaftar akun Skills.id
    3. Setelah berhasil login, pada menu Beranda silakan masukkan kode voucher yang didapatkan dari Mitra Platform Prakerja pada kolom yang sudah disediakan, lalu klik Tukarkan sekarang
    4. Lalu klik jadwal sesuai dengan yang kamu pilih pada platform, lalu tukarkan kode voucher
    5. Akan muncul kolom kode redeem, silakan diinput dan “Redeem sekarang” 
    6. Setelah penukaran berhasil, klik “ lanjutkan belajar”untuk mengikuti kelasnya.

    `,
  },
  {
    title: "Bagaimana alur untuk mengikuti pelatihan daring ?",
    body: `
    Jika sudah melakukan pembelian kelas di Mitra Platform, lakukan penukaran kode voucher di Skills.id sesuai langkah-langkah yang bisa kamu temukan di poin Setelah melakukan penukaran kode voucher dan memasukkan kode redeem, ikuti langkah-langkah berikut.
    
    1. Masuk ke akun Skills.id melalui website skills.id 
    2. Masuk ke menu dashboard
    3. Klik “Lanjutkan belajar” lalu kerjakan pretest untuk bisa melihat jadwal webinar . Setelah klik jadwal webinar, nanti akan muncul link zoom beserta jadwal pelatihan yang bisa kamu ikuti. 
    4. Ikuti seluruh sesi dari hari pertama hingga hari terakhir. Pastikan tidak ada misi yang terlewat.

    Total durasi pelatihan daring adalah 15 jam, namun dalam pelaksanaanya akan terbagi dalam 5 hari. Dalam 1 (satu) hari peserta akan mengikuti sesi daring maksimal 3 jam. Pastikan untuk mengikuti seluruh rangkaian sesi webinar dari pertemuan pertama hingga terakhir.
    `,
  },
  {
    title: "Bagaimana mengikuti pelatihan secara luring/ offline ?",
    body: `
    Jika sudah melakukan pembelian kelas di Mitra Platform, lakukan penukaran kode voucher di Skills.id sesuai langkah-langkah yang bisa ditemukan di FaQ.

    Setelah berhasil menukarkan kode voucher, ikuti langkah-langkah berikut.

    - Datang ke lokasi pelatihan pada jam yang sudah dipilih saat melakukan pembelian di Mitra Platform.
    - Datangi Admin yang bertugas dan lakukan scan QR code. Cek QR code di Dashboard Prakerja.
    - Sebagai bukti kehadiran, Anda akan diminta untuk melakukan verifikasi wajah
    - Total durasi pelatihan luring adalah 15 jam, namun dalam pelaksanaannya akan terbagi menjadi 2 hari. Sehingga dalam 1 hari, peserta akan mengikuti sesi pelatihan maksimal 8 jam.
    - Pastikan untuk mengikuti seluruh rangkaian sesi pelatihan dari pertemuan pertama hingga terakhir.
    `,
  },
  {
    title: "Kapan saya bisa mendapatkan sertifikat?",
    body: `
    Jika sudah menyelesaikan pelatihan, verifikasi, dan cetak sertifikat di Skills.id. Kamu bisa menunggu sertifikat muncul di dashboard prakerja dalam 7 hari kerja (tidak termasuk hari libur & tanggal merah).

    Hasil pelatihan kamu kami laporkan ke pihak terkait pada H+1 selesainya pelatihan. Selanjutnya sertifikat akan diproses oleh pihak Marketplace (Tokopedia/Bukalapak/Pintar) ke pihak Prakerja.

    Jika dalam 7 hari kerja sertifikat masih belum muncul, silakan hubungi CS Marketplace (Tokopedia/Bukalapak/Pintar) karena Skills.id tidak memiliki wewenang untuk melaporkan langsung sertifikatnya ke pihak Prakerja.
    
    Jangan lupa untuk mengisi rating dan ulasan di dashboard Prakerja kamu, karena pengisian rating dan ulasan adalah salah satu syarat agar insentif bisa dicairkan. 
    Pastikan untuk melakukan pembelian pelatihan maksimal 15 hari sejak pelatihan dibeli. Mohon untuk sabar menunggu.
    `,
  },
  {
    title: "Kapan insentif bisa dicairkan ?",
    body: (
      <div>
        <p>
          Jika Anda sudah lolos menjadi Penerima Kartu Prakerja, insentif biaya
          mencari kerja bisa didapatkan setelah menyelesaikan Pelatihan yang
          ditandai dengan adanya sertifikat. Penyelesaian seluruh aktivitas
          pelatihan wajib segera diselesaikan setelah pelatihan hari terakhir.
        </p>
        <p>
          Pastikan juga setelah menyelesaikan kelas, Anda sudah melakukan
          beberapa hal berikut.
        </p>
        <p></p>
        <p>
          1. Telah memberikan ulasan (review) dan penilaian (rating) terhadap
          pelatihan di dashboard Prakerja
        </p>
        <p>
          2. Jika Penerima Kartu Prakerja mengikuti lebih dari satu pelatihan,
          insentif biaya mencari kerja hanya diberikan pada saat penyelesaian
          pelatihan yang pertama. Tidak ada insentif untuk pelatihan kedua dan
          seterusnya.
        </p>
        <p>
          3. Telah berhasil menyambungkan nomor rekening bank atau e-money di
          akun situs www.prakerja.go.id
        </p>
        <p>
          4. Nomor rekening bank atau e-money yang didaftarkan sudah divalidasi
          (menggunakan NIK yang sama dengan NIK terdaftar di Kartu Prakerja dan
          sudah KYC atau akun e-money sudah premium/upgrade) oleh
          bank/perusahaan e-money terkait.
        </p>
        <p></p>
        <p>Insentif pengisian survei evaluasi</p>
        <p></p>
        <p>
          Insentif survey akan diterima jika Penerima Kartu Prakerja telah
          mengisi survei evaluasi pada dashboard akun di situs
          www.prakerja.go.id untuk mengetahui evaluasi efektivitas Program Kartu
          Prakerja. Pastikan mengisi evaluasi dengan jujur ya.
        </p>
        <p>
          Apabila mengalami kendala terkait insentif, silakan hubungi Customer
          Service Prakerja melalui:
        </p>
        <br />
        <p>- Formulir pengaduan :</p>
        <p>
          {" "}
          <a
            className="text-primary-600 underline"
            href="https://bantuan.prakerja.go.id/hc/id/requests/new"
          >
            https://bantuan.prakerja.go.id/hc/id/requests/new
          </a>
        </p>
        <br />
        <p>- Call Center :</p>
        <p>08001503001 (Senin-Minggu 08.00-20.00 WIB)</p>
        <br />
        <p>- Fitur Live Chat :</p>
        <p>
          {" "}
          <a
            className="text-primary-600 underline"
            href="prakerja.go.id/livechat"
          >
            prakerja.go.id/livechat
          </a>
        </p>
        <br />
        <p>
          Anda juga bisa mengunjungi prakerja.go.id/tanya-jawab untuk informasi
          lebih lanjut.
        </p>
      </div>
    ),
  },
  {
    title: "Apa saja syarat untuk bisa mendapatkan insentif Prakerja ?",
    body: (
      <div>
        <p>
          {` Insentif atau biaya mencari kerja adalah bantuan dana yang diberikan oleh pemerintah bagi peserta Prakerja yang sudah menyelesaikan kelas pertama.
    Peserta Prakerja akan mendapatkan 2 jenis insentif, yaitu:

    -Insentif biaya mencari kerja sebesar Rp600.000,- (enam ratus ribu rupiah) sebanyak 1 (satu) kali
    -Insentif pengisian survei evaluasi sebesar Rp50.000,- (lima puluh ribu rupiah) per survei dengan total 2 survei.

    Kami informasikan apabila Anda sudah terverifikasi setidaknya menyelesaikan 1 kelas di dashboard Kartu Prakerja, maka selanjutnya proses pembayaran insentif dilakukan oleh pemerintah, bukan oleh lembaga pelatihan (Skills.id) ataupun Mitra Platform Prakerja (Bukalapak, Tokopedia dan Pintar).
    Pastikan beberapa hal berikut untuk mendapatkan insentif Prakerja:

    - Anda membeli kelas menggunakan Kartu Prakerja.
    - Melakukan pembelian kelas maksimal 15 hari kalender sejak pengumuman lolos gelombang
    - Menyelesaikan kelas yang sudah dibeli, dibuktikan dengan mendapatkan sertifikat dari Skills.id
    - Telah berhasil menyambungkan nomor rekening Bank atau e-wallet di akun website prakerja.go.id.
    - Nomor rekening Bank atau e-wallet yang didaftarkan telah tervalidasi oleh bank/perusahaan e-wallet yang bersangkutan.
    - Telah melakukan pengisian rating dan review pada kelas pelatihan di dashboard Prakerja Anda.

    Setelah menyelesaikan kelas pertama, Tim Skills.id akan melaporkan status penyelesaian Anda kepada Mitra Platform Prakerja tempat pembelian kelas. Selanjutnya, Mitra Platform yang akan menyampaikan data penyelesaian kelas Anda kepada Tim Prakerja.
    Tim Prakerja selanjutnya akan memverifikasi status penyelesaian kelas Anda dalam waktu 7 hari kerja (tidak termasuk hari libur atau tanggal merah). Setelah terverifikasi, Tim Prakerja akan mengirimkan dana insentif ke channel pembayaran yang sudah Anda pilih di dashboard Prakerja.
    Apabila mengalami kendala terkait insentif, silakan hubungi Customer Service Prakerja melalui:

`}
        </p>
        <p>Formulir pengaduan</p>
        <p>
          <a
            className="text-primary-600 underline"
            href="https://bantuan.prakerja.go.id/hc/id/requests/new"
          >
            🔗 https://bantuan.prakerja.go.id/hc/id/requests/new
          </a>
        </p>
        <br />
        <p>Call Center</p>
        <p>08001503001 (Senin-Minggu 08.00-20.00 WIB)</p>
        <br />
        <p>Fitur Live Chat</p>
        <p>
          <a
            className="text-primary-600 underline"
            href="prakerja.go.id/livechat"
          >
            {" "}
            🔗 prakerja.go.id/livechat
          </a>
        </p>
        <br />
        <p>
          Anda juga bisa mengunjungi prakerja.go.id/tanya-jawab untuk informasi
          lebih lanjut.
        </p>
      </div>
    ),
  },
];
