import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ClipArt, Icon } from "../../components/independent/image";
import LayoutHome from "../../components/independent/layout/layout-home";

const TermAndCondition = () => {
  return (
    <LayoutHome>
      <div className="min-h-screen mt-[80px]">
        <div className="pl-[36px] pt-[36px]">
          <Link to="/become-instructor" className="flex items-center space-x-2">
            <Icon name="arrow-back-secondary-button600" className="w-4 h-4" />
            <span className="text-secondary-button-600 font-semibold">
              Kembali
            </span>
          </Link>
        </div>
        <div className="px-[135px] mt-7 space-y-2 flex flex-col justify-center items-center">
          <ClipArt className="w-full" name="Polygon 5" />
          <h4 className="text-primary-500 text-xl font-semibold">Instruktur</h4>
          <p className="text-netral-8">Registration</p>
          <ClipArt className="w-full" name="Polygon 5" />
        </div>
        <div className="px-[135px] mt-8">
          <h3 className="text-2xl text-netral-10 font-semibold text-center">
            Term and Condition
          </h3>
          <div className="mt-8 space-y-6">
            {terms.map((el, i) => (
              <Fragment key={i}>
                <div className="flex items-center space-x-6">
                  <Icon name={el.icon} className="w-20 h-20" />
                  <div className="space-y-2">
                    <h4 className="text-xl font-semibold text-primary-800">
                      {el.title}
                    </h4>
                    <p className="text-sm text-netral-9">{el.body}</p>
                  </div>
                </div>
                <div className="w-3/4 h-0 border border-netral-4 mx-auto"></div>
              </Fragment>
            ))}
          </div>
        </div>
        <p className="mt-8 text-center text-netral-10 w-3/4 mx-auto">
          By clicking "I Agree", you are indicating that you have read and agree
          to the terms presented in the{" "}
          <span className="text-primary-600">
            Terms and Conditions agreement
          </span>{" "}
          specified.
        </p>
        <div className="flex items-center justify-center space-x-6 mt-8">
          <Link
            to="/"
            style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
            className="text-center text-primary-500 font-semibold py-[11.5px] w-[207px] bg-white rounded border border-primary-500"
          >
            Tidak Setuju
          </Link>
          <Link
            to="/become-instructor/profile"
            style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)" }}
            className="text-center text-white font-semibold py-[11.5px] w-[207px] bg-primary-500 rounded border border-primary-500"
          >
            Saya Setuju
          </Link>
        </div>
      </div>
    </LayoutHome>
  );
};

export default TermAndCondition;

const terms = [
  {
    icon: "Whitelist-primary700",
    title: "Approval",
    body: "It will take around fifteen working days for your application to be approved by our administrators. Only education institutions, communities that organize classes/sharing and private teachers will be approved.",
  },
  {
    icon: "Account-primary700",
    title: "Free Account",
    body: "All educators account, upon approval, will be free of charge. However, regularly login to skills.id is required to prevent inactive users. Any inactive accounts will be charged a yearly fee or will be removed permanently from our database.",
  },
  {
    icon: "ebook-primary700",
    title: "Workshops",
    body: "Only registered educator’s account is able to create classes and workshops. Please kindly email us at skills.id for any request regarding organizing your own workshops if you are not an educator.",
  },
];
