import { Logo } from "../../../independent/image";

export default function PrakerjaPartners() {
  return (
    <section className="px-6 lg:px-[135px] py-4 lg:py-8 space-y-4 lg:space-y-8 bg-secondary2-400">
      <h3 className="text-lg lg:text-2xl font-semibold text-netral-1 text-center">
        Temukan Pelatihan Skills.id di Mitra Prakerja Pilihanmu
      </h3>
      <ul className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-4">
        {partners.map((partner, i) => (
          <li key={i}>
            <a href={partner.href} target="_blank" rel="noreferrer">
              <Logo
                name={partner.logo}
                className="w-[162px] h-[69px] lg:w-[183px] lg:h-[70px]"
              />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
}

const partners = [
  { logo: "image 32", href: "https://www.tokopedia.com/" },
  { logo: "image 33", href: "https://pintar.co/" },
  { logo: "image 34", href: "https://www.bukalapak.com/" },
  { logo: "image 35", href: "https://www.sekolah.mu/" },
  { logo: "image 36", href: "https://pijarmahir.id/" },
];
