import { Link } from "react-router-dom";
import { ClipArt, Illustration } from "../../../independent/image";

export default function PrakerjaBanner() {
  return (
    <section className="mt-7 lg:mt-28">
      <div
        style={{
          backdropFilter: "blur(16px)",
          background:
            "linear-gradient(98.1deg, rgba(18, 168, 157, 0) 34.12%, rgba(18, 168, 157, 0.06) 81.59%, rgba(18, 168, 157, 0.13) 100%)",
        }}
        className="relative"
      >
        <div className="flex items-center space-x-6 rounded-xl pt-8 pb-[41px] mx-auto max-w-[1010px]">
          <ClipArt
            name="Polygons"
            className="absolute top-0 right-0 w-[34.18px] h-[33.23px] lg:w-auto lg:h-auto"
          />
          <div className="h-full w-1/2 border-2 border-primary-300 rounded-xl border-dashed">
            <Illustration name="image 27" className="w-full h-auto" />
          </div>
          <div className="w-1/2">
            <h3 className="lg:text-2xl font-semibold to-primary-900">
              Apa itu Prakerja
            </h3>
            <p className="text-netral-9 mt-6 text-xs lg:text-base">
              Program Kartu Prakerja adalah program pengembangan kompetensi
              kerja dan kewirausahaan yang ditujukan untuk pencari kerja,
              pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau
              pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk
              pelaku usaha mikro dan kecil.
            </p>
            <Link
              to="/prakerja"
              style={{
                filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))",
              }}
              className="text-[13px] lg:text-base mt-8 py-2 lg:py-3 px-[11.5px] rounded border border-primary-600 text-primary-600 inline-block"
            >
              Selengkapnya
            </Link>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          filter: "drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.32))",
          backdropFilter: "blur(40px)",
        }}
        className="flex items-center justify-between lg:block w-full lg:w-1/2 h-[205px] lg:h-[515px] bg-primary-900 px-4 lg:pt-[25.97px] lg:pb-[25.1px] lg:px-[34.09px] relative lg:rounded-r-xl"
      >
        <div
          style={{ backgroundImage: "url(/assets/clip-art/blur1.png)" }}
          className="absolute top-0 left-0 h-full w-1/2 lg:w-[381px] bg-cover"
        ></div>
        <div
          style={{ backgroundImage: "url(/assets/clip-art/blur2.png)" }}
          className="absolute bottom-0 right-0 h-[122px] lg:h-[218px] w-1/2 lg:w-[381px] bg-cover"
        ></div>
        <div className="h-[173px] lg:h-full w-1/2 lg:w-full lg:border-2 border-dashed border-white lg:py-[18.09px] lg:pl-[35.15px] lg:pr-[36.22px]">
          <Illustration name="prakerja" className="h-full w-full" />
        </div>
        <div className="lg:hidden w-1/2 ml-4">
          <h4 className="text-white font-semibold text-lg">
            Apa itu Prakerja ?
          </h4>
          <p className="mt-2 text-sm text-white">
            Instruktur dari seluruh dunia mengajar jutaan
          </p>
          <Link
            to="/prakerja"
            style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
            className="inline-block mt-4 py-1 px-[31px] border border-white text-white rounded interactive-button-color"
          >
            Selengkapnya
          </Link>
        </div>
      </div>
      <div className="hidden lg:block w-1/2 mr-[30px] lg:ml-[86px]">
        <h4 className="text-primary-900 font-semibold text-2xl leading-[38.4.px]">
          Apa itu Prakerja
        </h4>
        <p className="mt-6 text-lg text-netral-10">
          Instruktur dari seluruh dunia mengajar jutaan peserta di Skills.id.
          Kami menyediakan alat dan keterampilan untuk mengajarkan hal yang Anda
          sukai.
        </p>
        <Link
          to="/prakerja"
          style={{ filter: "drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15))" }}
          className="inline-block mt-[60px] p-3 border border-secondary-button-600 text-secondary-button-600 hover:text-white rounded interactive-button-color"
        >
          Selengkapnya
        </Link>
      </div> */}
    </section>
  );
}
