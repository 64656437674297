import { Icon } from "../../image";

export default function Title() {
  return (
    <div className="pb-3 lg:pb-4 border-b-2 border-[#E0E0E0] flex space-x-6 items-center">
      <Icon name="Add Cart" className="w-14 h-14 lg:w-20 lg:h-20" />
      <div className="flex flex-col space-y-2">
        <h4 className="text-xl lg:text-2xl text-netral-10 font-semibold">
          Checkout
        </h4>
        <p className="text-netral-9 text-sm lg:text-base">
          Silakan selesaikan proses pembelian kelas Anda
        </p>
      </div>
    </div>
  );
}
