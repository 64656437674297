import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import EditOrRemoveButton from "../../../../../components/dependent/new-course/edit-or-remove-button";
import NextOrLaterButton from "../../../../../components/dependent/new-course/next-or-later-button";
import SaveOrCancelButton from "../../../../../components/dependent/new-course/save-or-cancel-button";
import { Icon } from "../../../../../components/independent/image";
import InputDateLabel from "../../../../../components/independent/input/input-date-label";
import InputTextLabel from "../../../../../components/independent/input/input-text-label";
import InputTimeLabel from "../../../../../components/independent/input/input-time-label";
import LayoutNewWebinarStep4 from "../../../../../components/independent/layout/layout-new-webinar-step4";
import {
  SET_WEBINAR_FINISHED_NAV,
  SET_WEBINAR_SCHEDULE,
} from "../../../../../config/redux/types";

const Schedule = () => {
  const { schedule } = useSelector((state) => state.newWebinarReducer);
  const [data, setData] = useState(schedule);

  const [isSaved, setSaved] = useState(false);

  const handleChangeStart = (idx, value) => {
    const date = data[idx].dateStart.split(",");
    date[1] = value;
    return date.join(",");
  };

  const handleChangeEnd = (idx, value) => {
    const date = data[idx].dateEnd.split(",");
    date[1] = value;
    return date.join(",");
  };

  return (
    <LayoutNewWebinarStep4
      title="Jadwal dan Link Webinar"
      subTitle="Tentukan aturan kelas Anda. "
    >
      <p className="text-netral-8">
        Tambahkan jadwal dan link untuk mengadakan webinar
      </p>
      {!isSaved ? (
        <div className="mt-6 py-6 px-[79px] space-y-6 bg-netral-2/60 ">
          <div className="px-6 py-4 bg-white rounded border border-netral-6">
            <h6 className="text-netral-10 font-semibold text-lg">
              Jadwal dan Link Webinar
            </h6>
            <div className="space-y-3 mt-6">
              <InputTextLabel
                id="url"
                text="Link Webinar"
                placeholder="Masukkan url"
                value={data[0].url}
                onChange={(e) =>
                  setData(data.map((el) => ({ ...el, url: e.target.value })))
                }
              />
              <div className="flex justify-between">
                <h6 className="text-[18px] font-semibold text-netral-10 w-full">
                  Jam Webinar
                </h6>
                <div className="w-full flex items-center space-x-6">
                  <InputTimeLabel
                    type="time"
                    id="start"
                    text="Jam mulai"
                    value={data[0].dateStart.split(",")[1]}
                    onChange={(e) => {
                      setData(
                        data.map((el, idx) => ({
                          ...el,
                          dateStart: handleChangeStart(idx, e.target.value),
                        }))
                      );
                    }}
                  />
                  <InputTimeLabel
                    type="time"
                    id="end"
                    text="Jam berakhir"
                    value={data[0].dateEnd.split(",")[1]}
                    onChange={(e) => {
                      setData(
                        data.map((el,idx) => ({
                          ...el,
                          dateEnd: handleChangeEnd(idx, e.target.value),
                        }))
                      );
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <h6 className="text-[18px] font-semibold text-netral-10 w-full">
                  Tanggal Webinar
                </h6>
                <div className="w-full space-y-2">
                  {data.map((date, i) => (
                    <div className="relative" key={i}>
                      <div className="flex items-center space-x-6">
                        <InputDateLabel
                          id={"start_date-" + i}
                          text="Tanggal Mulai"
                          value={date.dateStart.split(",")[0]}
                          max={date.dateEnd.split(",")[0]}
                          onChange={(e) => {
                            const dateStartArr = date.dateStart.split(",");
                            dateStartArr[0] = e.target.value;
                            data[i].dateStart = dateStartArr.join(",");
                            // console.log([...data]);
                            setData([...data]);
                          }}
                        />
                        <InputDateLabel
                          id={"end_date-" + i}
                          text="Tanggal Berakhir"
                          value={date.dateEnd.split(",")[0]}
                          min={date.dateStart.split(",")[0]}
                          onChange={(e) => {
                            const dateEndtArr = date.dateEnd.split(",");
                            dateEndtArr[0] = e.target.value;
                            data[i].dateEnd = dateEndtArr.join(",");
                            setData([...data]);
                          }}
                        />
                      </div>
                      {i !== 0 && (
                        <button
                          className="absolute top-11 -left-10"
                          type="button"
                          onClick={() => {
                            data.splice(i, 1);
                            setData([...data]);
                          }}
                        >
                          <Icon
                            name="delete_outline-secondary-3-500"
                            className="w-6 h-6"
                          />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between before:w-full pb-3">
                <div className="w-full">
                  <button
                    className="flex items-center space-x-2"
                    type="button"
                    onClick={() => {
                      data.push({
                        url: data[0].url,
                        dateStart: data[0].dateStart.split(",")[1] + ",",
                        dateEnd: data[0].dateEnd.split(",")[1] + ",",
                      });
                      setData([...data]);
                    }}
                  >
                    <Icon name="add-primary-500" className="w-6 h-6" />
                    <p className="font-semibold text-primary-500">
                      Sesi Webinar
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SaveOrCancelButton
            active={true}
            save={() => setSaved(true)}
            cancel={() => setData([{ url: "", dateStart: "", dateEnd: "" }])}
          />
        </div>
      ) : (
        <div className="mt-6">
          <div className="flex justify-end">
            <EditOrRemoveButton
              edit={() => setSaved(false)}
              remove={() => {
                setSaved(false);
                setData([{ url: "", dateStart: "", dateEnd: "" }]);
              }}
            />
          </div>
          <div className="space-y-4">
            <div className="space-y-1">
              <p className="text-xs text-netral-8">Alamat Kursus</p>
              <a
                href={data[0].url}
                target="_blank"
                rel="noreferrer"
                className="text-[#345AF3] text-sm font-semibold hover:underline"
              >
                {data[0].url}
              </a>
            </div>
            <div className="space-y-1">
              <p className="text-xs text-netral-8">Jam Webinar</p>
              <p className="text-sm font-semibold text-netral-10">{`${
                data[0].dateStart.split(",")[1]
              }-${data[0].dateEnd.split(",")[1]}`}</p>
            </div>
            <div className="flex items-center space-x-[131px]">
              <div className="space-y-1">
                <p className="text-xs text-netral-8">Tanggal Mulai</p>
                {data.map((date, i) => (
                  <p key={i} className="text-sm font-semibold text-netral-10">
                    {date.dateStart.split(",")[0]}
                  </p>
                ))}
              </div>
              <div className="space-y-1">
                <p className="text-xs text-netral-8">Tanggal Berakhir</p>
                {data.map((date, i) => (
                  <p key={i} className="text-sm font-semibold text-netral-10">
                    {date.dateEnd.split(",")[0]}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <ChildComponent data={data} isSaved={isSaved} />
    </LayoutNewWebinarStep4>
  );
};

export default Schedule;

const ChildComponent = ({ isSaved, data }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { id } = useParams();

  return (
    <NextOrLaterButton
      active={isSaved}
      page={"schedule"}
      next={() => {
        dispatch({ type: SET_WEBINAR_SCHEDULE, payload: data });
        dispatch({ type: SET_WEBINAR_FINISHED_NAV, payload: "schedule" });
        push(`/new/webinar/${id}/step5/curriculum`);
      }}
    />
  );
};
