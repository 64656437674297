import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardCourseDashboard from "../../../components/independent/card/card-course-dashboard";
import { Icon } from "../../../components/independent/image";
import LayoutDashboard from "../../../components/independent/layout/layout-dashboard";
import { _dashboardInstructor } from "../../../config/services/api/instructor";

const Dashboard = () => {
  const { token } = useSelector((state) => state.authReducer);
  const [data, setData] = useState({
    total_class: 0,
    total_student: 0,
    total_review: 0,
    not_finish_class: 0,
    finish_class: 0,
    classes: [],
    learner: [],
  });

  useEffect(() => {
    if (!token) return;
    _dashboardInstructor(token).then((res) => {
      setData(res);
    });
  }, [token]);

  const dashboardData = [
    {
      name: "Total Kelas",
      value: `${data.total_class} kelas`,
      icon: "ebook-netral-1",
    },
    {
      name: "Total Learner",
      value: `${data.total_student} Learner`,
      icon: "Male Student-netral-1",
    },
    {
      name: "Total Review",
      value: `${data.total_review} Review`,
      icon: "ebook-netral-1",
    },
    {
      name: "Kelas Selesai",
      value: `${data.finish_class} Kelas`,
      icon: "Correct-netral-1",
    },
    {
      name: "Kelas belum Selesai",
      value: `${data.not_finish_class} Kelas`,
      icon: "woman-girl-avatar-reading-student-education-book store-netral-1",
    },
    {
      name: "Uji Keterampilan",
      value: `${data.totalSkillTest} Learner`,
      icon: "survey-netral-1",
    },
  ];

  return (
    <LayoutDashboard>
      <h4 className="text-2xl font-medium text-netral-7">Dashboard</h4>
      <p className="text-netral-6 mt-3">
        Daftar kursus yang sudah pernah dibuat
      </p>
      <div className="mt-8 grid grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-3">
        {dashboardData.map((el, i) => (
          <div
            key={i}
            className="border border-primary-200 rounded-xl p-3 flex items-center justify-between bg-[#F8F9FA]"
          >
            <div className="space-y-2">
              <p className="text-sm font-semibold text-netral-8">{el.name}</p>
              <h4 className="text-xl font-semibold text-netral-10">
                {el.value}
              </h4>
            </div>
            <div className="p-2 rounded-xl bg-primary-400">
              <Icon name={el.icon} className="w-8 h-8" />
            </div>
          </div>
        ))}
      </div>
      <h4 className="text-xl text-netral-9 font-semibold mt-10">Kelas Saya</h4>
      <div className="mt-6 grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data.classes.map((el, i) => (
          <CardCourseDashboard key={i} data={el} />
        ))}
      </div>
      <div className="hidden lg:block">
        <h4 className="text-xl mt-10 text-netral-9 font-semibold">Learner</h4>
        <div className="flex items-center justify-between mt-4">
          <div className="relative">
            <div className="absolute top-0 left-2 h-full flex items-center">
              <Icon name="search-netral-6" />
            </div>
            <input
              type="search"
              placeholder="Cari nama"
              className="pl-[42px] px-3 py-[9px] rounded-xl border border-netral-5 w-[342px]"
            />
          </div>
          <div className="flex items-center space-x-6">
            <div className="relative">
              <div className="absolute top-0 left-2 h-full flex items-center">
                <Icon name="filter_list-netral-7" className="w-6 h-6" />
              </div>
              <select className="w-[123px] rounded-xl border border-netral-5 py-[9px] pl-[36px] text-netral-5">
                <option>Filter</option>
              </select>
            </div>
            <button className="bg-primary-800 rounded px-3 py-2 text-netral-1 font-semibold">
              Export Data
            </button>
          </div>
        </div>
        <table className="mt-6 w-full">
          <thead>
            <tr className="text-left">
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                No.
              </th>
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                Nama Peserta
              </th>
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                Nama Kelas
              </th>
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                Kode Voucher
              </th>
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                Update Terbaru
              </th>
              <th className="text-sm font-semibold text-netral-10 pb-3 border-b border-b-netral-8">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data.learner.map((el, i) => (
              <tr key={i}>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  {i + 1}
                </td>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  {el.student_name}
                </td>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  {el.class_title}
                </td>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  {el.voucherCode}
                </td>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  {el.last_update}
                </td>
                <td className="text-sm text-netral-9 py-3 border-b border-b-netral-4">
                  <span className="px-1 py-[2px] rounded bg-primary-100 text-netral-10">
                    {el.status_finish ? "Sudah Selesai" : "Sedang Belajar"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LayoutDashboard>
  );
};

export default Dashboard;

// const defaultData = ;
