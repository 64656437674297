import getLink from "../../../../constants/getLink";
import { ClipArt } from "../../image";

export default function TableTransaction({ data, showDetail }) {
  return (
    <div className="rounded-lg bg-netral-3/40">
      <div className="px-6">
        <div className="flex items-center space-x-4 border-b-2 py-3 border-b-netral-8 h-full w-full">
          <img
            className="rounded w-[62px] h-[62px] object-fill"
            src={getLink( data.class_thumbnail)}
            alt="thumbnail"
          />
          <div className="space-y-3">
            <div className="flex items-center space-x-2">
              <h4 className="text-netral-9 text-xl font-semibold">
                {data.class_title}
              </h4>
              {data.participant_type === "Prakerja" && (
                <ClipArt name="label prakerja" className="w-[72px] h-[29px]" />
              )}
            </div>
            <p className="text-netral-9">Oleh {data.instructor_name}</p>
          </div>
        </div>
      </div>
      <div className="px-6 pt-6">
        <table className="w-full text-left">
          <thead className="text-netral-9 text-sm font-medium">
            <tr>
              <th className="pb-3 border-b border-[#E0E0E0]">Nomor Pesanan</th>
              <th className="pb-3 border-b border-[#E0E0E0]">Harga Kelas</th>
              <th className="pb-3 border-b border-[#E0E0E0]">Level Kelas</th>
              <th className="pb-3 border-b border-[#E0E0E0]">
                Tanggal Transaksi
              </th>
              <th className="pb-3 border-b border-[#E0E0E0]">
                Status Pembayaran
              </th>
            </tr>
          </thead>
          <tbody className="text-netral-10 font-semibold">
            <tr>
              <td className="py-2">{data.invoice}</td>
              <td className="py-2">
                Rp {parseInt(data.price).toLocaleString("id-ID")}
              </td>
              <td className="py-2">{data.level}</td>
              <td className="py-2">
                {new Date(data.transaction_date).toDateString("id-ID")}
              </td>
              <td className="py-2 text-secondary2-500">
                Peserta {data.payment_method}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} className="pt-12">
                <div className="border-t border-t-netral-6 py-3 flex justify-end items-center">
                  <div className="space-y-2">
                    <p className="text-netral-9 text-sm">Total Pembayaran :</p>
                    <p className="text-netral-10 font-semibold text-lg">
                      Rp {parseInt(data.total_payment).toLocaleString("id-ID")}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={showDetail}
                    className="ml-[30px] border border-secondary-button-600 text-secondary-button-600 font-semibold rounded px-3 py-2 hover:text-white interactive-button-color"
                  >
                    Detail Pembayaran
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
