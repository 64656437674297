import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <section
      style={{
        background:
          "linear-gradient(98.1deg, rgba(18, 168, 157, 0) 34.12%, rgba(18, 168, 157, 0.06) 81.59%, rgba(18, 168, 157, 0.13) 100%)",
        backdropFilter: "blur(16px)",
      }}
      className="mt-[112px] flex flex-col justify-center items-center py-10"
    >
      <h3 className="text-2xl text-netral-10 font-semibold">
        Jadilah instruktur hari ini
      </h3>
      <p className="mt-3 text-netral-10">
        Tunggu apalagi segera bergabung dengan kami dan motivasi seluruh peserta
      </p>
      <Link
        to="/become-instructor/term-and-condition"
        style={{ boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)" }}
        className="inline-block mt-8 text-white font-semibold p-3 bg-primary-600 rounded"
      >
        Mulai Mengajar
      </Link>
    </section>
  );
}
