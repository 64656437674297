import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BlogCard from "../../components/independent/card/card-blog";
import BlogCard3 from "../../components/independent/card/card-blog3";
import { Icon } from "../../components/independent/image";
import LayoutHome from "../../components/independent/layout/layout-home";
import { _getBlogById, _getLatestBlog } from "../../config/services/api/blog";
import getLink from "../../constants/getLink";

export default function BlogDetail() {
  const { token } = useSelector((state) => state.authReducer);
  const { blogId } = useParams();

  const [latestBlog, setLatestBlog] = useState({
    status: true,
    result: [],
  });
  const [data, setData] = useState({
    _id: "",
    image: null,
    title: "",
    content: "",
    author: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
  });

  useEffect(() => {
    _getLatestBlog(token)
      .then((res) => {
        if (res.status) {
          setLatestBlog(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    _getBlogById(token, blogId)
      .then((res) => {
        if (res.status) {
          setData(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [blogId, token]);

  return (
    <LayoutHome>
      <div className="pt-[110px] pb-[112px] space-y-[24px] lg:space-y-[32px] lg:px-[6.5rem] px-4">
        <div className="p-1 flex items-center space-x-2">
          <Link to="/blog">
            <Icon name="arrow_back" className="w-4 h-4 lg:w-6 lg:h-6" />
          </Link>
          <Link to="/blog">
            <span className="font-semibold text-secondary2-500 text-xs lg:text-base">
              List Blog
            </span>
          </Link>
          <p className="text-netral-6 text-xs lg:text-base">/ Baca blog</p>
        </div>
        <div className="w-full">
          <div className="w-full">
            <img src={getLink(data.image)} alt="thumbnail" className="w-full" />
          </div>
          <div className="space-y-[8px] overflow-scroll py-[24px] w-full lg:w-[70%]">
            <p className="text-sm text-netral-6">
              {data.author} • {data.createdAt}
            </p>
            <p className="text-lg lg:text-2xl font-semibold lg:font-bold text-netral-10">{data.title}</p>
          </div>
          <div className="flex gap-[32px] flex-col lg:flex-row">
            <div className="lg:w-[70%]">
              <p className="lg:text-base text-sm whitespace-pre-line">{data.content}</p>
            </div>
            <div className="lg:w-[30%] space-y-[16px]">
              <p className="font-semibold lg:font-extrabold text-lg lg:text-xl text-netral-10">
                Postingan Terbaru
              </p>
              <div className="flex flex-row lg:flex-col gap-y-[16px] gap-x-[8px] overflow-scroll">
                {latestBlog.result.slice(0,-1).map((el, idx) => (
                  <div key={idx} className="w-[185px] lg:w-full">
                    <BlogCard3 data={el} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutHome>
  );
}
