export default function VirtualAccountInfo() {
  return (
    <div className="bg-primary-100/40 rounded border border-[#C2C2C2] py-2 px-2 lg:py-6 lg:px-3">
      <p className="lg:font-semibold text-netral-10 text-sm lg:text-base">
        Catatan: Selesaikan pembayaran Anda dalam waktu 48 jam. Setelah
        pembayaran diselesaikan, butuh waktu hingga 30 menit untuk mengirimkan
        kursus ke akun Skills.id Anda. Setelah kursus dikirim, Anda akan
        menerima notifikasi melalui email.
      </p>
    </div>
  );
}
