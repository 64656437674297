import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import ButtonExit from "../../button/button-exit";
import Footer from "../../footer/footer";
import Header from "../../header/header";
// import { Icon } from "../../image";
import NavHeaderDashboard from "../../navigation/nav-header-dashboard";
import HeaderV2 from "../../header/headerV2";
import NavHeaderDashboardV2 from "../../navigation/nav-header-dashboard-v2";
import { Icon } from "../../image";

export default function LayoutNewWebinar({ activeTab, children }) {
  const [animationProgress, setAnimationProgress] = useState(0);
  const { pathname, state } = useLocation();
  const isStep5 = pathname.includes("step5");
  const all = useSelector((state) => state.newWebinarReducer);
  const { id } = useParams();

  const gradientColor = `linear-gradient(120deg, #9360A8 ${animationProgress}%, #46B7E9) ${animationProgress}%`;

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationProgress((prev) => (prev + 1) % 101);
    }, 20);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {}, [activeTab]);

  const getGradientColor = (index) => {
    if (index === activeTab) {
      return gradientColor;
    } else if (index < activeTab) {
      return "#9360A8";
    }
    // else if (activeTab === index - 1) {
    //   return "#9360A8";
    // } else if (activeTab === index + 1) {
    //   return "#C2C2C2";
    // }
    else {
      return "#C2C2C2";
    }
  };

  const getTextColor = (index) => {
    if (index === activeTab) {
      return "#9360A8";
    } else if (index < activeTab) {
      return "#9360A8";
    }
    // else if (activeTab === index - 1) {
    //   return "#9360A8";
    // } else if (activeTab === index + 1) {
    //   return "#C2C2C2";
    // }
    else {
      return "#C2C2C2";
    }
  };

  // console.log(activeTab);

  if (all._id && !isStep5) {
    return <Redirect to={`/new/webinar/${id}/step5`} />;
  } else {
    if (isStep5 && all.courseCategory === "") {
      return <Redirect to={`/new/webinar/${id}/`} />;
    }
  }

  return (
    <div
      // className="h-screen flex flex-col bg-slate-600"
      className="flex flex-col sm:h-full md:h-screen h-full"
    >
      <Header>
        <NavHeaderDashboard />
      </Header>
      {/* <HeaderV2>
        <NavHeaderDashboardV2 />
      </HeaderV2> */}
      <div className="pt-8 h-full overflow-auto">
        {/* <div className="w-full flex px-[32px] pb-[40px] gap-x-[6px]">
          {dataHeader.map((tab, index) => (
            <div className="w-full flex  flex-col gap-y-[5px]" key={index}>
              <div
                className="w-full h-[6px] rounded-[10px]"
                style={{
                  background: getGradientColor(index + 1),
                  // backgroundSize: "200% 200%",
                  transition: "background 0.1s linear",
                }}
              />
              <div className="flex items-center gap-[5px]">
                {index + 1 < activeTab && (
                  <div className="flex justify-center bg-[#9360A8] rounded-full w-[20px] h-[20px]">
                    <Icon name="check" w={12} h={12} />
                  </div>
                )}

                <span
                  style={{
                    color: getTextColor(index + 1),
                  }}
                  className="font-semibold text-[1rem]"
                >
                  {tab}
                </span>
              </div>
            </div>
          ))}
        </div> */}
        <div className="relative">
          {!isStep5 && <ButtonExit to="/instructor">Keluar</ButtonExit>}
          <ul className="px-[264px] flex justify-between items-center">
            {tabs.map((tab, i) => (
              <li
                key={i}
                className={`${
                  activeTab === i + 1
                    ? "border-[#12A89D]"
                    : "border-transparent"
                } p-2 space-y-2 border-b-4 `}
              >
                <h4
                  className={`${
                    activeTab === i + 1 ? "text-primary-500" : "text-netral-4"
                  } text-xl font-semibold`}
                >
                  {tab}
                </h4>
                <p
                  className={`${
                    activeTab === i + 1 ? "text-netral-6" : "text-netral-4"
                  } font-semibold`}
                >
                  {i + 1} dari 5
                </p>
              </li>
            ))}
          </ul>
        </div>
        <main className="">{children}</main>
        <Footer className="mt-[112px]" />
      </div>
    </div>
  );
}

LayoutNewWebinar.defaultProps = {
  activeTab: 1,
};

const tabs = ["Langkah 1", "Langkah 2", "Langkah 3", "Langkah 4", "Langkah 5"];

const dataHeader = [
  "Detail Kelas",
  "Kurikulum",
  "Bank Soal",
  "Uji Keterampilan",
  "Penetapan Harga",
  "Review",
];
