import { useDispatch } from "react-redux";
import { logout } from "../../../../config/redux/actions/authAction";

export default function LogoutButton() {
  const dispatch = useDispatch();
  return (
    <button
      onClick={() => dispatch(logout())}
      className="flex items-center space-x-3 text-netral-8 px-4"
    >
      <div className="w-[40px] h-[40px] grid place-items-center bg-white rounded-xl">
        <img
          className="h-[24px] w-[24px] object-cover overflow-hidden"
          src="/assets/icon/Export-netral-8.svg"
          alt="Export-netral-8.svg"
        />
      </div>
      <span className="text-base">Logout</span>
    </button>
  );
}
