import { Link } from "react-router-dom";
import Footer from "../../components/independent/footer/footer";
import Header from "../../components/independent/header/header";
import { Illustration } from "../../components/independent/image";
import NavHeader from "../../components/independent/navigation/nav-header";

const Success = () => {
  return (
    <>
      <Header className="fixed top-0">
        <NavHeader />
      </Header>
      <div className="pt-[82px]">
        <div className="flex flex-col items-center mt-[38px]">
          <Illustration
            name="Boy and girl giving high-five to each other"
            className="w-[181px] h-[181px] lg:w-[389px] lg:h-[389px]"
          />
          <h3 className="text-xl lg:text-2xl font-semibold text-netral-10 mt-6">
            Pembayaran Sukses
          </h3>
          <p className="text-netral-10 mt-3 lg:mt-6 text-sm lg:text-base">
            Terima kasih telah melakukan pembayaran.
          </p>
          <Link
            to="/student"
            className="text-sm font-semibold text-secondary-button-600 mt-6 lg:mt-8"
          >
            Masuk ke Kelas
          </Link>
        </div>
        <Footer className="mt-40" />
      </div>
    </>
  );
};

export default Success;
