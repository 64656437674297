import React, { useEffect, useState } from "react";
import LayoutLearnCourse from "../../../components/independent/layout/layout-learn-course";
import { ClipArt } from "../../../components/independent/image";
import { _verifyAndRating } from "../../../config/services/api/profile";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const ReflectiveJournalForm = ({
  reflectiveJournal,
  step,
  handleChangeReflective,
  setStep,
  handleNext,
  finished,
}) => {
  switch (step) {
    case 1:
      return (
        <div className="flex h-full w-full items-center flex-col py-[30px]">
          <ClipArt name={"cuate"} className="w-[190px] h-[190px]" />
          <div className="space-y-3 text-center mt-[31px] mb-[23px]">
            <p className="font-medium text-xl text-netral-10">
              Reflective Journal
            </p>
            <p className="whitespace-pre-wrap font-normal text-base ">
              {!finished
                ? ` Silahkan isi survey reflective journal berikut, agar kami dapat meningkatkan kualitas
              layanan kami kedepannya`
                : "Terima Kasih anda telah mengisi reflective journal ini."}
            </p>
          </div>
          <div>
            <button
              disabled={finished}
              type="button"
              onClick={() => setStep(2)}
              className={`px-3 py-2  rounded border border-transparent  font-semibold text-white text-xs lg:text-base ${
                finished
                  ? "bg-netral-5"
                  : "bg-secondary-button-600 interactive-button-color"
              }`}
            >
              Mulai Isi
            </button>
          </div>
        </div>
      );
    default:
      return (
        <div className="py-[40px] px-[21px]">
          <div className="border-b-2 border-netral-6 pb-[20px] mb-[20px]">
            <p className="font-medium text-lg text-netral-10">
              Reflective Journal
            </p>
          </div>
          <p className="text-base font-normal">
            {reflectiveJournal[step - 2]?.text}
          </p>
          <div className="mt-[20px]">
            <textarea
              value={reflectiveJournal[step - 2]?.answer}
              onChange={(e) => handleChangeReflective(e.target.value)}
              className="w-full h-[140px] rounded-lg border border-netral-4"
              placeholder="Jawaban anda"
            />
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={() => handleNext()}
              className=" px-3 py-2 bg-secondary-button-600 rounded border border-transparent interactive-button-color font-semibold text-white text-xs lg:text-base"
            >
              {step === 5 ? "Submit" : "Lanjut"}
            </button>
          </div>
        </div>
      );
  }
};

export default function ReflectiveJournal() {
  const [step, setStep] = useState(1);
  const { courseId } = useParams();
  const { userData, token } = useSelector((state) => state.authReducer);
  const progress = useSelector((state) => state.courseReducer);
  const [finished, setFinished] = useState(false);
  const [reflectiveJournal, setReflectiveJournal] = useState([
    {
      text: "Sebutkan 3 (tiga) pelajaran yang paling berkesan untuk Anda selama mengikuti pelatihan? ",
      answer: "",
    },
    {
      text: "Jelaskan hal yang ingin Anda terapkan setelah mengikuti pelatihan ini?",
      answer: "",
    },
    {
      text: "Berikan pendapatmu mengenai pengalaman belajar di kelas pelatihan ini dengan kami?",
      answer: "",
    },
    {
      text: "Berikan pendapatmu mengenai hal yang dapat diperbaiki dari pelatihan ini?",
      answer: "",
    },
  ]);
  const handleChangeReflective = (value) => {
    setReflectiveJournal((prev) =>
      prev.map((el, idx) => (idx === step - 2 ? { ...el, answer: value } : el))
    );
  };

  useEffect(() => {
    if (progress.reflectiveJournal) {
      setFinished(true);
    }
  }, []);

  const handleNext = async () => {
    if (step < 5) {
      setStep(step + 1);
    } else {
      try {
        const dataToSend = {
          user_id: userData._id,
          class_id: courseId,
          journals: reflectiveJournal,
        };
        const res = await _verifyAndRating(token, dataToSend);
        if (res.status) {
          setStep(1);
          setFinished(true);
        }
      } catch (error) {
        console.log("error post verify certifcate name", error);
      }
    }
  };

  console.log(progress);

  return (
    <LayoutLearnCourse>
      <div
        style={{
          background:
            "linear-gradient(285.12deg, rgba(207, 250, 231, 0.54) 0%, rgba(207, 250, 231, 0.26) 100%)",
        }}
        className="min-h-[247px] lg:h-[471px] border border-primary-300 rounded-lg relative"
      >
        <ReflectiveJournalForm
          step={step}
          setStep={setStep}
          reflectiveJournal={reflectiveJournal}
          handleChangeReflective={handleChangeReflective}
          handleNext={handleNext}
          finished={finished}
        />
      </div>
    </LayoutLearnCourse>
  );
}
