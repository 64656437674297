import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutDashboard from "../../components/independent/layout/layout-dashboard";
// import InputSearchDropdown from "../../components/independent/input/input-search-dropdown";
// import InputDropdownLabel from "../../components/independent/input/input-dropdown-label";
import CardCourse3 from "../../components/independent/card/card-course3";
// import CardScore from "../../components/independent/card/card-score";
import {
  _createCourseProgress,
  _getCoursesStudent,
} from "../../config/services/api/course";
import { useHistory, useLocation } from "react-router-dom";
import { _verifyPrakerja } from "../../config/services/api/courseProgress";
import { SET_ALERT } from "../../config/redux/types";
import Modal from "../../components/independent/modal";
import { ClipArt, Icon } from "../../components/independent/image";

const Courses = () => {
  const { token, userData } = useSelector((state) => state.authReducer);
  const [courses, setCourses] = useState([]);
  const { push } = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const [courseId, setCourseId] = useState("");

  const link = (courseId) => {
    const learnStr = localStorage.getItem("learn");

    if (!learnStr) return;

    const learn = JSON.parse(learnStr);

    if (!learn[courseId]) return;

    return learn[courseId].lastVisited;
  };

  const handleCreateProgress = (course) => {
    _createCourseProgress(token, course._id)
      .then((res) => {
        if (!res.status) return console.log(res);
        if (course?.participantType.includes("WEBINAR")) {
          // push(`/learn/${course._id}/info`);
        } else {
          push(`/learn/${course._id}/schedule`);
        }
      })
      .catch((e) => console.log("error get student courses", e));
  };

  useEffect(() => {
    const getCourse = () => {
      _getCoursesStudent(token)
        .then((res) => {
          if (!res.status) return console.log(res);
          setCourses(res.data);
        })
        .catch((e) => console.log("error get student courses", e));
    };
    getCourse();
  }, [token]);
  
  const redeem = (e) => {
    e.preventDefault();
    // token, sequence,redeemCode,email,class_id
    _verifyPrakerja(token, 1, redeemCode, userData.email, courseId)
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        // setOpenModal(false);

        if (res.code) {
          localStorage.setItem(
            "prakerjaPayload",
            JSON.stringify({
              token,
              sequence: 1,
              redeemCode,
              email: userData.email,
              class_id: courseId,
            })
          );
          localStorage.setItem("callbackTo", `/learn/${courseId}/info`);
          if (res?.response?.data?.status) {
            push(`/learn/${courseId}/info`);
            // console.log('success');
          }
          if (res.response.url) {
            window.open(res.response.url);
          }
          // setSuccessModal(true);
        } else {
          setOpenModal(false);
          setErrorModal(res.message);
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_ALERT,
          payload: { status: false, message: e },
        });
        setErrorModal(true);
      });
    // .finally(() => setLoading(false));
  };

  const handleLearn = (course) => {
    if (
      course.progress.percentage === 0 &&
      course?.class.participantType.includes("WEBINAR")
      && !userData?.role?.includes("Assesor")
    ) {
      setOpenModal(true);
      setCourseId(course.class._id);
    } else {
      push(
        `/learn/${course.class._id}/${
          course?.class.participantType.includes("WEBINAR")
            ? "info"
            : "schedule"
        }`
      );
    }
  };

  return (
    <LayoutDashboard>
      <div className="space-y-8">
        <div className="space-y-2 lg:space-y-3 text-primary-900">
          <h4 className="text-xl lg:text-2xl font-semibold">Kelas Saya</h4>
          <p className="text-sm lg:text-base">
            Silakan lanjutkan kelas yang Anda beli dan dapatkan sertifikat
          </p>
        </div>
        <div className="lg:flex items-end justify-end lg:space-x-6 space-y-3 lg:space-y-0">
          {/* <InputSearchDropdown />
          <div className="flex space-x-4 lg:space-x-6">
            <InputDropdownLabel>level</InputDropdownLabel>
            <InputDropdownLabel>type</InputDropdownLabel>
          </div> */}
        </div>
        <div className="flex justify-between">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-6">
            {courses.map((course, i) => (
              <CardCourse3
                key={i}
                course={course}
                link={link(course.class._id)}
                status={course.progress.status}
                handleCreateProgress={(id) => handleCreateProgress(id)}
                handleLearn={(course) => handleLearn(course)}
              />
            ))}
          </div>
        </div>
      </div>
      <Modal modal={openModal}>
        <form onSubmit={(e) => redeem(e)}>
          <div className="bg-white w-[300px] lg:w-[485px] min-h-[270px] relative p-[20px]">
            <div className="absolute right-6 top-3">
              <button type="button" onClick={() => setOpenModal(false)}>
                <Icon name="close-netral-10" className="w-6 h-6" />
              </button>
            </div>
            <div className="flex flex-col text-center space-y-2">
              <p className="text-xl font-semibold">Redeem Code</p>
              <p className="text-sm">
                Masukan 12 karakter unit redeem code yang dapat kamu temukan di
                website{" "}
                <a
                  href="https://www.prakerja.go.id/"
                  className="underline text-primary-500"
                >
                  Prakerja.go.id
                </a>
              </p>
              <div className="py-5">
                <input
                  id="voucher"
                  value={redeemCode}
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="block w-full p-3 rounded border border-[#757575]"
                  placeholder="Redeem code"
                  autocomplete="off"
                />
              </div>
              <button
                disabled={!redeemCode}
                type={"submit"}
                className={`${
                  !redeemCode
                    ? "text-netral-6 bg-netral-5 cursor-not-allowed"
                    : "text-white bg-secondary-button-600 interactive-button-color"
                } mt-6 font-semibold py-2 w-full rounded border border-transparent`}
              >
                Redeem sekarang
              </button>
            </div>
          </div>
        </form>
      </Modal>

      {/* Success Modal */}
      <Modal modal={successModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="absolute right-6 top-3">
            <button type="button" onClick={() => setSuccessModal(false)}>
              <Icon name="close-netral-10" className="w-6 h-6" />
            </button>
          </div>
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"Success-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl">Berhasil Tukar Voucher</p>
              <p className="font-normal text-sm">
                Kode Voucher Anda telah berhasil ditukarkan
              </p>
            </div>
            {/* <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => setSuccessModal(false)}
            >
              Lanjut Belajar
            </button> */}
          </div>
        </div>
      </Modal>

      {/* Error redeem Modal */}
      <Modal modal={errorModal}>
        <div className="bg-white w-[300px] lg:w-[485px] min-h-[340px] relative p-[20px]">
          <div className="flex flex-col items-center justify-between space-y-6">
            <ClipArt name={"error-redeem"} className="w-[130px]" />
            <div className="space-y-3 text-center">
              <p className="font-semibold text-xl text-[#DC0000]">
                Gagal Menukarkan Kode Voucher
              </p>
              <p className="font-normal text-sm">{errorModal}</p>
            </div>
            <button
              type="submit"
              className={`${"text-white bg-secondary-button-600 interactive-button-color"} font-semibold py-2 w-[140px] rounded border border-transparent`}
              onClick={() => {
                setOpenModal(true);
                setErrorModal(false);
              }}
            >
              Coba Lagi
            </button>
          </div>
        </div>
      </Modal>
    </LayoutDashboard>
  );
};

export default Courses;
